import React, { useState, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { Row, Col, Button, Image, Nav, Tab, Alert } from '@themesberg/react-bootstrap';

import request from "../apis/request";
import { baseURL, METHODS } from "../apis/utilities/constant";
import LoaderImage from "../assets/img/LoaderGraphic.svg";
import { decryptData } from "../utils/crypto";
import { EMAIL_REGEX } from "../AppConfig";

import UserWebsiteDigestComponent from "../components/UserWebsiteDigestComponent";
import UserWebcastDigestComponent from "../components/UserWebcastDigestComponent";
import UserTnfDigestComponent from "../components/UserTnfDigestComponent";
import AppContext from "../components/AppContext";

const DigestSubscriptionsPage = () => {
  const [t] = useTranslation();
  const contextData = useContext(AppContext);

  const [isRefresh, setIsRefresh] = useState(false);

  const [websiteTableData, setWebsiteTableData] = useState([]);
  const [webcastTableData, setWebcastTableData] = useState([]);
  const [appTableData, setAppTableData] = useState([]);
  const [tnfTableData, setTnfTableData] = useState([]);

  const [activeTab, setActiveTab] = useState("Website");
  const [blurLoader, setBlurLoader] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isAlertShown, setIsAlertShown] = useState(false);

  const [hasWebsiteAccess, setHasWebsiteAccess] = useState(false);
  const [hasTnfAccess, setHasTnfAccess] = useState(false);

  const [websiteRecipients, setWebsiteRecipients] = useState("");
  const [webcastRecipients, setWebcastRecipients] = useState("");
  const [IRRecipients, setIRRecipients] = useState("")

  const [webcastClientByCompanyId, setWebcastClientByCompanyId] = useState(false);

  const [invalidEmailError, setInvalidEmailError] = useState("")
  const profileData = JSON.parse(decryptData(localStorage.getItem("selectedCompanyData"), contextData.cryptoKey));

  //Check company has access of webcast client if not then show empty box
  useEffect(() => {
    getMenuByCompanyAndUser();
  }, [contextData.companyId])

  useEffect(() => {
    setIsRefresh(true);
    setWebsiteTableData([]);
    setWebcastTableData([]);
    setAppTableData([]);
    setTnfTableData([])
  }, [activeTab])

  const getMenuByCompanyAndUser = () => {
    request(baseURL + `/UserV2/GetMainMenuByClientUser?userId=${contextData.userId}&companyid=${contextData.companyId}`,
      METHODS.GET,
      {},
      {
        Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
      }
    )
      .then((response) => {
        setBlurLoader(false);
        let websiteAccess = response?.resultData?.menuDetails && response?.resultData?.menuDetails.filter(x => x.title == "Website");
        let appAccess = response?.resultData?.menuDetails && response?.resultData?.menuDetails.filter(x => x.title == "App");
        let WebcastingAccess = response?.resultData?.menuDetails && response?.resultData?.menuDetails.filter(x => x.title == "Webcasting");

        //for tnf
        let tnfAccess = response?.resultData?.menuDetails && response?.resultData?.menuDetails.filter(x => x.title === "IR Tools Analytics")
        setWebcastClientByCompanyId(WebcastingAccess.length > 0 && WebcastingAccess[0].isActive);
        setHasWebsiteAccess(websiteAccess.length > 0 && websiteAccess[0].isActive);

        //for tnf
        setHasTnfAccess(tnfAccess.length > 0 && tnfAccess[0].isActive)
        setActiveTab((websiteAccess.length > 0 && websiteAccess[0].isActive) ? "Website" : (WebcastingAccess.length > 0 && WebcastingAccess[0].isActive) ? "Webcast" : (appAccess.length > 0 && appAccess[0].isActive) ? "App" : "");
      })
      .catch((error) => {
        setBlurLoader(false);
        setWebcastClientByCompanyId(false);
      })
  }

  const onWebsiteItemChange = (updatedData) => {
    setWebsiteTableData(updatedData);
  };

  const onTnfItemChange = (updatedData) => {
    setTnfTableData(updatedData)
  }

  const onWebsiteHandleSubmit = () => {
    setInvalidEmailError("")
    var regexEmail = EMAIL_REGEX;
    let hasInvalidEmail;

    //validate emails if provided
    if (websiteRecipients) {
      let individualRecipient = websiteRecipients.split(",");
      hasInvalidEmail = individualRecipient.some((item, index) => {
        if (item && !regexEmail.test(item.trim()) || "") {
          setIsSuccess(false);
          setIsAlertShown(true);
          setInvalidEmailError("This email " + individualRecipient[index] + " is not valid one.")
          return true;
        }
        return false;
      })
    } else {
      setWebsiteRecipients("")
    }

    if (hasInvalidEmail) {
      return;
    }

    let tableData = websiteTableData;
    let reqObj = [];

    tableData && Array.isArray(tableData) && tableData.length > 0 && tableData.map((item) => {
      let obj = {
        userWebsiteId: item["WebsiteDigestTable.UserWebsiteId"],
        websiteName: item["WebsiteDigestTable.WebsiteName"],
        weekly: item["WebsiteDigestTable.Weekly"],
        monthly: item["WebsiteDigestTable.Monthly"],
        yearly: item["WebsiteDigestTable.Yearly"]
      }
      reqObj.push(obj);
    })

    if (tableData && Array.isArray(tableData) && tableData.length > 0 || websiteRecipients == "" || websiteRecipients !== "") {
      setBlurLoader(true);
      request(baseURL + `/User/SaveUserWebsiteDigestByUserId?CompanyId=${contextData.companyId}&recipient=${websiteRecipients}`,
        METHODS.POST,
        reqObj,
        {
          Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
        }
      )
        .then((response) => {
          setBlurLoader(false);
          if (response.resultData) {
            setWebsiteTableData([]);
            setIsSuccess(true);
            setIsAlertShown(true);
            setIsRefresh(!isRefresh);
          }
        })
        .catch((error) => {
          setBlurLoader(false);
        })
    }
  }

  const onTnfHandleSubmit = () => {
    setInvalidEmailError("")
    var regexEmail = EMAIL_REGEX;
    let hasInvalidEmail;

    //validate emails if provided
    if (IRRecipients) {
      let individualRecipient = IRRecipients.split(",");
      hasInvalidEmail = individualRecipient.some((item, index) => {
        if (item && !regexEmail.test(item.trim()) || "") {
          setIsSuccess(false);
          setIsAlertShown(true);
          setInvalidEmailError("This email " + individualRecipient[index] + " is not valid one.")
          return true;
        }
        return false;
      })
    } else {
      setIRRecipients("")
    }

    if (hasInvalidEmail) {
      return;
    }

    let tableData = tnfTableData;
    let reqObj = [];

    tableData && Array.isArray(tableData) && tableData.length > 0 && tableData.map((item) => {
      let obj = {
        userWebsiteId: item["WebsiteDigestTable.UserWebsiteId"],
        websiteName: item["WebsiteDigestTable.WebsiteName"],
        weekly: item["WebsiteDigestTable.Weekly"],
        biweekly: item["WebsiteDigestTable.Biweekly"],
        monthly: item["WebsiteDigestTable.Monthly"],
        yearly: item["WebsiteDigestTable.Yearly"]
      }

      reqObj.push(obj);
    })

    if (tableData && Array.isArray(tableData) && tableData.length > 0 || IRRecipients == "" || IRRecipients !== "") {
      setBlurLoader(true);
      request(baseURL + `/UserV2/SaveUserTnfDigestByUserId?CompanyId=${contextData.companyId}&Recipient=${IRRecipients}`,
        METHODS.POST,
        reqObj,
        {
          Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
        }
      )
        .then((response) => {
          setBlurLoader(false);
          if (response.resultData) {
            setTnfTableData([]);
            setIsSuccess(true);
            setIsAlertShown(true);
            setIsRefresh(!isRefresh);
          }
        })
        .catch((error) => {
          setBlurLoader(false);
        })
    }
  }

  const onWebcastItemChange = (updatedData) => {
    setWebcastTableData(updatedData);
  };

  const onWebcastHandleSubmit = () => {
    setInvalidEmailError("")
    var regexEmail = EMAIL_REGEX;
    let hasInvalidEmail;

    //validate emails if provided
    if (webcastRecipients) {
      let individualRecipient = webcastRecipients.split(",");
      hasInvalidEmail = individualRecipient.some((item, index) => {
        if (item && !regexEmail.test(item.trim()) || "") {
          setIsSuccess(false);
          setIsAlertShown(true);
          setInvalidEmailError("This email " + individualRecipient[index] + " is not valid one.")
          return true;
        }
        return false;
      })
    } else {
      setWebcastRecipients("")
    }

    if (hasInvalidEmail) {
      return;
    }

    let tableData = webcastTableData;
    let reqObj = [];

    if (tableData && Array.isArray(tableData) && tableData.length > 0) {
      let obj = {
        isAdhocActive: tableData[0]["WebcastDigestTable.Status"],
        isQuarterActive: tableData[1]["WebcastDigestTable.Status"]
      };
      reqObj.push(obj)
    }

    if (tableData && Array.isArray(tableData) && tableData.length > 0 || webcastRecipients == "" || webcastRecipients !== "") {
      setBlurLoader(true);
      request(baseURL + `/User/SaveUserWebcastDigestByUserId?CompanyId=${contextData.companyId}&UserId=${contextData.userId}&recipient=${webcastRecipients}`,
        METHODS.POST,
        reqObj,
        {
          Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
        }
      )
        .then((response) => {
          setBlurLoader(false);
          if (response.resultData) {
            setWebcastTableData([]);
            setIsSuccess(true);
            setIsAlertShown(true);
            setIsRefresh(!isRefresh);
          }
        })
        .catch((error) => {
          setBlurLoader(false);
        })
    }
  }

  const closeAlert = () => {
    setIsAlertShown(false);
  }

  return (
    <>
      {blurLoader && <div id='blurdiv' className="blurbackground">
        <div className={`preloader loader-center-align`}>
          <Image className="loader-element animate__animated animate__jackInTheBox" src={LoaderImage} height={40} data-seconds={30} />
        </div>
      </div>}
      <div className="ps-3">
        <Alert variant={isSuccess ? "success" : "danger"} show={isAlertShown}>
          <div className="d-flex justify-content-between mb-0">
            <div>
              <FontAwesomeIcon icon={faCheckCircle} className="me-2" />
              {invalidEmailError ? invalidEmailError : t("header.DigestSubscriptionSuccessMessage")}
            </div>
            <Button variant="close" onClick={() => { closeAlert() }} size="xs" />
          </div>
        </Alert>

        <Row className="mb-3">
          <Col lg={12}>
            <h2 className="h2 mb-0">{t("header.DigestSubscriptionTitle")}</h2>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            {
              hasWebsiteAccess || webcastClientByCompanyId || hasTnfAccess ?
                <Tab.Container activeKey={activeTab} defaultActiveKey="Website">
                  <Nav fill variant="pills" className="flex-column flex-sm-row tab webcast-subtab">
                    {hasWebsiteAccess && <Nav.Item className="pe-0">
                      <Nav.Link onClick={() => { setActiveTab("Website") }} eventKey="Website" className="mb-sm-3 mb-md-0">
                        {t("header.DigestSubscriptionWebsites")}
                      </Nav.Link>
                    </Nav.Item>}
                    {webcastClientByCompanyId && <Nav.Item className="pe-0">
                      <Nav.Link onClick={() => { setActiveTab("Webcast") }} eventKey="Webcast" className="mb-sm-3 mb-md-0">
                        {t("header.DigestSubscriptionWebcasts")}
                      </Nav.Link>
                    </Nav.Item>}

                    {hasTnfAccess && <Nav.Item className="pe-0">
                      <Nav.Link onClick={() => { setActiveTab("TNF") }} eventKey="TNF" className="mb-sm-3 mb-md-0">
                        {t("header.DigestSubscriptionIRTools")}
                      </Nav.Link>
                    </Nav.Item>}
                  </Nav>
                  <Tab.Content>
                    <Tab.Pane hidden={activeTab == "Website" ? false : true} eventKey="Website" className="py-2">
                      <Row className="mb-3 websitepane">
                        <Col lg={12}>
                          <UserWebsiteDigestComponent
                            websiteTableData={websiteTableData}
                            onItemChange={onWebsiteItemChange}
                            handleSubmitButtonClick={onWebsiteHandleSubmit}
                            setRecipients={setWebsiteRecipients}
                            recipient={websiteRecipients}
                            profileData={profileData}
                            companyId={JSON.parse(decryptData(localStorage.getItem("selectedCompanyData"), contextData.cryptoKey))["companyId"]}
                            userId={decryptData(localStorage.getItem("userId"), contextData.cryptoKey)}
                          />
                        </Col>
                      </Row>
                    </Tab.Pane>
                    {webcastClientByCompanyId &&
                      <Tab.Pane hidden={activeTab == "Webcast" ? false : true} eventKey="Webcast" className="py-2">
                        <Row className="mb-3 webcastpane">
                          <Col lg={12}>
                            <UserWebcastDigestComponent
                              webcastTableData={webcastTableData}
                              onItemChange={onWebcastItemChange}
                              handleSubmitButtonClick={onWebcastHandleSubmit}
                              setRecipients={setWebcastRecipients}
                              recipient={webcastRecipients}
                              companyId={JSON.parse(decryptData(localStorage.getItem("selectedCompanyData"), contextData.cryptoKey))["companyId"]}
                              userId={decryptData(localStorage.getItem("userId"), contextData.cryptoKey)}
                            />
                          </Col>
                        </Row>
                      </Tab.Pane>}
                    {hasTnfAccess &&
                      <Tab.Pane hidden={activeTab == "TNF" ? false : true} eventKey="TNF" className="py-2">
                        <Row className="mb-3 websitepane iranalytics-pane">
                          <Col lg={12}>
                            <UserTnfDigestComponent
                              tnfTableData={tnfTableData}
                              onItemChange={onTnfItemChange}
                              handleSubmitButtonClick={onTnfHandleSubmit}
                              setRecipients={setIRRecipients}
                              recipient={IRRecipients}
                              companyId={JSON.parse(decryptData(localStorage.getItem("selectedCompanyData"), contextData.cryptoKey))["companyId"]}
                              userId={decryptData(localStorage.getItem("userId"), contextData.cryptoKey)}
                            />
                          </Col>
                        </Row>
                      </Tab.Pane>
                    }
                  </Tab.Content>
                </Tab.Container>
                : <></>
            }
          </Col>
        </Row>
      </div>
    </>
  );
};

export default DigestSubscriptionsPage;
