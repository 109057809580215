import React from "react";
import PropTypes from 'prop-types';
import { isEmpty } from "../AppConfig";

const IframeComponent = ({
    srcName,
    customWidth,
    customHeight,
    customFrameBorder,
    shouldAllowFullScreen,
    customClass
}) => {

    return (
        <>
            {
                !isEmpty(srcName) &&
                    shouldAllowFullScreen ?
                    <iframe src={srcName} width={customWidth} height={customHeight} className={customClass} frameborder={customFrameBorder} allowFullScreen></iframe>
                    // <iframe width={customWidth} height={customHeight} frameborder="0" src={srcName} className={customClass} allowfullscreen></iframe>
                    :
                    <iframe width={customWidth} height={customHeight} frameborder={customFrameBorder} src={srcName} className={customClass}></iframe>
            }
        </>
    );

};

IframeComponent.propTypes = {
    srcName: PropTypes.string,
    customWidth: PropTypes.string,
    customHeight: PropTypes.string,
    customFrameBorder: PropTypes.string,
    shouldAllowFullScreen: PropTypes.bool,
    customClass: PropTypes.string

};
IframeComponent.defaultProps = {
    srcName: "",
    customWidth: "100%",
    customHeight: "460",
    customFrameBorder: "0",
    shouldAllowFullScreen: true,
    customClass: "report-container"
}

export default IframeComponent;