
import React, { useState, useEffect } from 'react';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import { useTranslation } from 'react-i18next';
import { isMobile } from "react-device-detect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { Row, Col, Button, Alert } from '@themesberg/react-bootstrap';

import { apiChartsPages, getPagesChart } from '../stubs/ComponentJson/Pages';
import { defaultLayout, defaultLayoutMobile } from "../utils/layoutConfig";

import DashboardItem from '../components/DashboardItem';
import ApiChartRenderer from './ApiChartRenderer';

const deserializeItem = (i) => ({
  ...i,
  layout: i.layout ? JSON.parse(i.layout) : {},
  summary: i.summary ? JSON.parse(i.summary) : {},
  layoutone: i.layoutone ? JSON.parse(i.layoutone) : {},
  layouttwo: i.layouttwo ? JSON.parse(i.layouttwo) : {},
  vizStateSecond: i.vizStateSecond ? JSON.parse(i.vizStateSecond) : {},
  vizState: i.vizState ? JSON.parse(i.vizState) : {},
  isResultShownFromApi: i.isResultShownFromApi ? true : false,
  chartType: i.chartType ? i.chartType : undefined,
  isSummeryShown: i.isSummeryShown ? i.isSummeryShown : false
});

const PagePerformanceChart = ({ selectedProfile }) => {
  const [t, i18n] = useTranslation();

  const [d3, setd3] = useState();
  const [chartPerformance, setChartPerformance] = useState([]);
  const [isRefresh, setIsRefresh] = useState(false);

  const [alertVisibleMessage, setAlertVisibleMessage] = useState(null);

  useEffect(() => {
    let dta = null;
    if (selectedProfile["WebsiteProfiles.ProfileId"]) {
      dta = getPagesChart()
      setChartPerformance(dta);
    }
  }, [selectedProfile]);

  const dashboardItem = (item) => (
    <div key={item.id} className={item.classname} data-grid={isMobile ? defaultLayoutMobile(item) : defaultLayout(item)}>
      {(item.isResultShownFromApi && item.chartType) ?
        <DashboardItem
          key={item.id}
          itemId={item.id}
          title={item.name}
          enableOption={item.enableOption}>
          <Row >
            <Col>
              <ApiChartRenderer setAlertVisibleMessage={setAlertVisibleMessage} isSummeryShown={item.isSummeryShown} services={apiChartsPages} chartType={item.chartType} itemId={item.id} cardProperty={Object.keys(item.layoutone).length > 0 ? item.layoutone : item.layout} legends={item.legends} isRefresh={isRefresh} setIsRefresh={setIsRefresh} setd3={setd3} cardName={item.cardName} />
            </Col>
          </Row>
        </DashboardItem> :
        <DashboardItem
          key={item.id}
          itemId={item.id}
          title={item.name}
          enableOption={item.enableOption}>
          <Row>
            <Col>
            </Col>
          </Row>
        </DashboardItem>}
    </div>
  );

  return (
    <>
      {alertVisibleMessage && (<div className="fade modal-backdrop show"></div>)}
      <Alert
        className="toast-custom"
        variant="success"
        show={alertVisibleMessage}
      >
        <div className="d-flex justify-content-between">
          <div>
            <FontAwesomeIcon icon={faCheckCircle} className="me-2" />
            {alertVisibleMessage}
          </div>
          <Button variant="close" size="xs" onClick={() => setAlertVisibleMessage(null)} />
        </div>
      </Alert>

      {chartPerformance &&
        <Row>
          <Col lg={12} className="pe-0" id="alertStatistics">
            <div className="d-inline-block p-3 w-100">
              {chartPerformance.map(deserializeItem).map(dashboardItem)}
            </div>
          </Col>
        </Row>
      }
    </>
  );
};

export default PagePerformanceChart;
