import React, { useState, useEffect, useContext, useRef } from 'react';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import moment from "moment";
import { useTranslation } from 'react-i18next';
import { Row, Col, Button, Tooltip, OverlayTrigger, Form } from '@themesberg/react-bootstrap';

import iconSprite from "../assets/Icon/svg-icn-sprite.svg";
import { getYearQuarter } from '../utils/common';

import DropdownWithIconComponent from "../components/DropdownWithIcons";
import AppContext from '../components/AppContext';
import ButtonComponent from "../components/Buttons";
import DateRangeComponent from './DateRangeComponent';
import ModalComponent from './VoltModal';

const CommonHeader = (props, { selectedWebsiteId, setSelectedWebsiteId }) => {
    const [t, i18n] = useTranslation();
    const contextData = useContext(AppContext);

    const [selectionsText, setSelectionsText] = useState([]);
    const [showFiltersMenu, setShowFiltersMenu] = useState(false);

    const [showtitle, setShowTitle] = useState(true);

    const [validStartDate, setValidStartDate] = useState(moment(new Date()).subtract(2, "years"));

    const [showCustomDatePicker, setShowCustomDatePicker] = useState(false);
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [datePickerValue, setDatePickerValue] = useState("");
    const [datePickerCompareValue, setDatePickerCompareValue] = useState("");
    const [selectedDateRangeOption, setSelectedDateRangeOption] = useState({ activeDateRangeOption: 1, selectedYear: getYearQuarter(true), selectedQuarter: getYearQuarter(false), quickSelect: 180, channel: props.channel });

    const [dateRange, setDateRange] = useState();
    const [dateCompareValue, setDateCompreValue] = useState("");
    const [resetDateRange, setResetDateRange] = useState(false);

    const filterRef = useRef(null);
    const iconRef = useRef(null);
    const customDateRangeRef = useRef(null);
    const isDemoClient = localStorage.getItem("isDemoClient");

    useEffect(() => {
        if (props.autoLoad) {
            if (props.channel === "website" || props.channel === "IR-tools-anaytics") {
                let startDate = moment(new Date()).subtract(180, 'days').format("MMM DD, YYYY");
                let endDate = moment(new Date()).subtract(1, 'day').format("MMM DD, YYYY");
                if (props.channel === "website" || props.channel === "IR-tools-anaytics") {
                    if (localStorage.getItem("SelectedDatePicker_" + props.channel)) {
                        let selectedDateRange = JSON.parse(localStorage.getItem("SelectedDatePicker_" + props.channel));
                        startDate = moment(selectedDateRange.startDate);
                        endDate = moment(selectedDateRange.endDate);
                    }
                }
                applyFilters(false, startDate, endDate);
            }
            else if (props.channel === "alert") {
                applyFilters(false, contextData.startDate, contextData.endDate);
            }
            else {
                applyFilters(false);
            }
        }
    }, [props.autoLoad]);

    useEffect(() => {
        if (contextData.userId > 0 && contextData.companyId > 0) {
            if (localStorage.getItem("IsPageRefresh") == "1") {
                if (localStorage.getItem("WebsiteListWebsiteId")) {
                    if (selectedWebsiteId) {
                        setSelectedWebsiteId(JSON.parse(localStorage.getItem("WebsiteListWebsiteId")));
                    }
                }
            }
        }
    }, [contextData.companyId]);

    useEffect(() => {
        if (contextData?.companyId > 0 && props.showDate) {
            let selectedDateRange = selectedDateRangeOption;
            let startDate = moment(new Date()).subtract(180, 'days').format("MMM DD, YYYY");
            let endDate = moment(new Date()).subtract(1, 'day').format("MMM DD, YYYY");
            let datePicker = moment(startDate).format("MMM DD, YYYY") + ` - ${moment(endDate).format("MMM DD, YYYY")}`;
            let previousEndDate = moment(endDate).subtract(1, 'years').format("MMM DD, YYYY");
            let previousStartDate = moment(startDate).subtract(1, 'years').format("MMM DD, YYYY");
            let datePickerCompare = `${previousStartDate} - ${previousEndDate}`;
            if (props.channel === "website" || props.channel === "IR-tools-anaytics") {
                if (localStorage.getItem("SelectedDatePicker_" + props.channel)) {
                    setLocalStorageDateRange(props.channel);
                }
                else {
                    setStartDate(startDate);
                    setEndDate(endDate);
                    setDatePickerValue(datePicker);
                    setDatePickerCompareValue(datePickerCompare);
                    selectedDateRange = { ...selectedDateRange, startDate: startDate, endDate: endDate, companyId: contextData.companyId, activeDateRangeOption: 1, selectedYear: getYearQuarter(true), selectedQuarter: getYearQuarter(false), quickSelect: 180, validStartDate: validStartDate }
                    setSelectedDateRangeOption(selectedDateRange);
                    localStorage.setItem("SelectedDatePicker_" + props.channel, JSON.stringify(selectedDateRange));
                    updateDatelabel(datePicker, datePickerCompare);
                }
            }
            else if (props.channel === "alert") {
                let alertDate = null;
                let selectedItem = props.dataSource[0]?.selectedItem;
                if (selectedItem?.["WebsiteList.AlertTrackingDate"] && selectedItem?.["WebsiteList.AlertTrackingDate"] !== "" && props.dataSource[0].data && props.dataSource[0].data.length > 0) {
                    if (props.dataSource[0].data) {
                        let data = props.dataSource[0].data;
                        if (data.find(x => x["WebsiteList.WebsiteName"] === selectedItem["WebsiteList.WebsiteName"])["WebsiteList.AlertTrackingDate"] !== selectedItem["WebsiteList.AlertTrackingDate"]) {
                            selectedItem["WebsiteList.AlertTrackingDate"] = data.find(x => x["WebsiteList.WebsiteName"] === selectedItem["WebsiteList.WebsiteName"])["WebsiteList.AlertTrackingDate"];
                            localStorage.setItem("alertWebsiteId", JSON.stringify(selectedItem));
                        }
                    }
                    alertDate = moment(props.dataSource[0]?.selectedItem["WebsiteList.AlertTrackingDate"]).add(1, "day");
                    const currentDate = moment();
                    const daysDifference = currentDate.diff(alertDate, 'days');
                    setValidStartDate(moment(alertDate));
                    if (daysDifference >= 180) {
                        startDate = moment(new Date()).subtract(180, 'days').format("MMM DD, YYYY");
                        selectedDateRange = { ...selectedDateRange, quickSelect: 180, activeDateRangeOption: 1, selectedYear: getYearQuarter(true), selectedQuarter: getYearQuarter(false) };
                    }
                    else if (daysDifference >= 90) {
                        startDate = moment(new Date()).subtract(90, 'days').format("MMM DD, YYYY");
                        selectedDateRange = { ...selectedDateRange, quickSelect: 90, activeDateRangeOption: 1, selectedYear: getYearQuarter(true), selectedQuarter: getYearQuarter(false) };
                    }
                    else if (daysDifference >= 30) {
                        startDate = moment(new Date()).subtract(30, 'days').format("MMM DD, YYYY");
                        selectedDateRange = { ...selectedDateRange, quickSelect: 30, activeDateRangeOption: 1, selectedYear: getYearQuarter(true), selectedQuarter: getYearQuarter(false) };
                    }
                    else if (daysDifference >= 7) {
                        startDate = moment(new Date()).subtract(7, 'days').format("MMM DD, YYYY");
                        selectedDateRange = { ...selectedDateRange, quickSelect: 7, activeDateRangeOption: 1, selectedYear: getYearQuarter(true), selectedQuarter: getYearQuarter(false) };
                    }
                    else {
                        if (endDate == moment(alertDate).subtract(1, 'days').format("MMM DD, YYYY")) {
                            startDate = moment(alertDate).subtract(1, 'days').format("MMM DD, YYYY");
                        } else if (moment(alertDate).subtract(1, 'days').format("MMM DD, YYYY") == moment(new Date).format("MMM DD, YYYY")) {
                            startDate = moment(alertDate).subtract(2, 'days').format("MMM DD, YYYY");
                        } else {
                            startDate = moment(alertDate).format("MMM DD, YYYY");
                        }
                        setValidStartDate(moment(startDate));
                        selectedDateRange = { ...selectedDateRange, quickSelect: 0, activeDateRangeOption: 3, selectedYear: getYearQuarter(true), selectedQuarter: getYearQuarter(false) };
                    }

                    datePicker = moment(startDate).format("MMM DD, YYYY") + ` - ${moment(endDate).format("MMM DD, YYYY")}`;
                    previousStartDate = moment(startDate).subtract(1, 'years').format("MMM DD, YYYY");
                    previousEndDate = moment(endDate).subtract(1, 'years').format("MMM DD, YYYY");
                    datePickerCompare = `${previousStartDate} - ${previousEndDate}`;
                    if (localStorage.getItem("SelectedDatePicker_alert") && moment(JSON.parse(localStorage.getItem("SelectedDatePicker_alert")).validStartDate).format("MMM DD, YYYY") === moment(validStartDate).format("MMM DD, YYYY")) {
                        setLocalStorageDateRange("alert");
                    }
                    else {
                        setStartDate(startDate);
                        setEndDate(endDate);
                        setDatePickerValue(datePicker);
                        setDatePickerCompareValue(datePickerCompare);
                        selectedDateRange = { ...selectedDateRange, startDate: startDate, endDate: endDate, companyId: contextData.companyId, validStartDate: validStartDate }
                        setSelectedDateRangeOption(selectedDateRange);
                        localStorage.setItem("SelectedDatePicker_alert", JSON.stringify(selectedDateRange));
                        updateDatelabel(datePicker, datePickerCompare);
                    }
                }
            }
        }
    }, [props.dataSource[0], resetDateRange]);

    useEffect(() => {
        if (showFiltersMenu || showCustomDatePicker) {
            document.addEventListener("click", handleClickOutside);
        } else {
            document.removeEventListener("click", handleClickOutside);
        }
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, [showFiltersMenu, showCustomDatePicker]);

    const createLabels = () => {
        let labelString = "";
        let labelValue = "";
        if (props.dataSource.length) {
            props.dataSource.map((element) => {
                setShowTitle(true);
                labelValue = "";
                if (element.isVisible) {
                    if (element.data !== null && element.data.length === 0) {
                        if (element.name.toLowerCase().includes("profile")) {
                            setShowTitle(false);
                        }
                        labelValue = " - ";
                    } else {
                        if (element.selectedItem[element.displayText] !== undefined) {
                            labelValue = element.selectedItem[element.displayText]
                        }
                        else if (element.data != null) {
                            let objectdetails = element.data[0];
                            labelValue = objectdetails["" + element.displayText];
                        }
                    }
                    if (element.selectedItem !== undefined) {
                        labelString += " " + element.label + ": " + labelValue + " | "
                    }
                }
            });
        }
        labelString = labelString.substring(0, labelString.length - 2)
        labelString = labelString.replace("undefined", " - ")
        setSelectionsText(labelString);
    }

    const toggleFiltersMenu = () => {
        setShowFiltersMenu(prevState => !prevState);
        setShowCustomDatePicker(false);
    };

    const handleClickOutside = (event) => {
        if (showFiltersMenu && filterRef.current && !filterRef.current.contains(event.target) && iconRef.current && !iconRef.current.contains(event.target)) {
            if (!showCustomDatePicker) {
                setShowFiltersMenu(false);
            }
        }
    };

    const setLocalStorageDateRange = (channel) => {
        let dateRangeOption = JSON.parse(localStorage.getItem("SelectedDatePicker_" + channel));
        let datePickerValue = moment(dateRangeOption.startDate).format("MMM DD, YYYY") + ` - ${moment(dateRangeOption.endDate).format("MMM DD, YYYY")}`;
        let previousStartDate = moment(dateRangeOption.startDate).subtract(1, 'years').format("MMM DD, YYYY");
        let previousEndDate = moment(dateRangeOption.endDate).subtract(1, 'years').format("MMM DD, YYYY");
        let datePickerCompareValue = `${previousStartDate} - ${previousEndDate}`;
        setStartDate(moment(dateRangeOption.startDate));
        setEndDate(moment(dateRangeOption.endDate));
        setDatePickerValue(datePickerValue);
        setDatePickerCompareValue(datePickerCompareValue);
        setSelectedDateRangeOption(dateRangeOption);
        updateDatelabel(datePickerValue, datePickerCompareValue);
    }

    const cancelFilters = function () {
        setTimeout(() => { setShowFiltersMenu(false) }, 100)
        if (props.cancelFilters() !== undefined) {
            props.cancelFilters()
        }
        setShowCustomDatePicker(false);
        let channel = "";
        if (props.channel !== undefined) {
            channel = props.channel;
        }
        if (props.showDate && localStorage.getItem("SelectedDatePicker_" + channel)) {
            setLocalStorageDateRange(channel);
        }
        else {
            setResetDateRange(!resetDateRange);
        }
    }

    const updateDatelabel = (datePicker, datePickerCompare) => {
        setDateRange(datePicker)
        setDateCompreValue(datePickerCompare);
    }

    const applyFilters = (setLocalStorage, start = startDate, end = endDate) => {
        createLabels();

        if (props.showDate) {
            updateDatelabel(datePickerValue, datePickerCompareValue);
            if (!moment(contextData.startDate).isSame(start)) {
                contextData.changeStartDate(start)
            }
            if (!moment(contextData.endDate).isSame(end)) {
                contextData.changeEndDate(end)
            }
            let channel = "";
            if (props.channel !== undefined) {
                channel = props.channel;
            }

            if (props.showDate && setLocalStorage) {
                localStorage.setItem("SelectedDatePicker_" + channel, JSON.stringify(selectedDateRangeOption));
            }
        }
        props.applyFilters(start, end);
        setTimeout(() => setShowFiltersMenu(false), 100);
    }

    const toggleCustomDateRange = () => {
        setShowCustomDatePicker(!showCustomDatePicker);
    }

    return (
        <>
            <Row className="align-items-center mt-2 mb-3">
                {props.title &&
                    <Col lg={12} xl={4}>
                        {showtitle &&
                            <h2 className="h2 mb-0 ps-3">
                                {props.title}
                            </h2>}
                    </Col>
                }
                <Col lg={12} xl={props.title ? 8 : 12} className="text-md-start text-lg-start text-xl-end position-relative">
                    <div className="edit-profile-container">
                        <div className="edit-profile-labels">
                            <span className="ps-3 me-3 web-analytics-date d-block" id="websiteWebsiteProfile">{selectionsText}</span>
                            {props.showDate &&
                                <><span className="ps-3 me-4 web-analytics-date" id="dateRange">{t("header.InsightDateLabel")}: {dateRange}</span><span className="pe-3 web-comp-date">{t("header.WOComparedToText")} {dateCompareValue}</span></>
                            }
                        </div>
                        <div className="slider-dropdown-tigger edit-profile-tigger icon-nav-list svg-icon-nav-list">
                            <span ref={iconRef} className="icon-nav-box ms-0" onClick={(e) => { toggleFiltersMenu(); }}>
                                <svg className="icon-nav interactive-icon-color">
                                    <title>{t("header.Icon_Tooltip_Filters")}</title>
                                    <use href={iconSprite + `#Edit`} />
                                </svg>
                            </span>
                        </div>
                    </div>
                    {showFiltersMenu &&
                        <Form ref={filterRef} onSubmit={(e) => { e.preventDefault() }} className="date-range-container">
                            <Row className="text-end">
                                <Col xs={12} sm={12} className="text-left">
                                    <h4 className="mt-4">{t("header.webcastViewersFiltersText")} <OverlayTrigger
                                        placement="right"
                                        trigger={['hover', 'focus']}
                                        overlay={
                                            <Tooltip>{t("header.filtersMessage")}</Tooltip>
                                        }>
                                        <Button variant="white" className="btn-help border-0 p-2">
                                            <i className="far fa-question-circle lg"></i>
                                        </Button>
                                    </OverlayTrigger></h4>
                                </Col>
                                <Col xs={12} sm={12} className="mb-1 position-relative">
                                    {
                                        props.dataSource.map((element) => {
                                            if (element.data !== null && element.data.length > 0) {
                                                if (element.isVisible) {
                                                    return (<div className="text-left pt-2"><span>{element.label}</span><DropdownWithIconComponent
                                                        dropdownData={element.data}
                                                        arrayValue={element.displayText}
                                                        selectedDropdown={element.selectedItem}
                                                        onChange={element.callback}
                                                        showLabel={false}
                                                        showIconInDropdownItem={false}
                                                        customClassName="full-width text-overflow"
                                                        isDropdownDisabled={isDemoClient}
                                                    /></div>)
                                                } else {
                                                    return <></>
                                                }
                                            }
                                        })
                                    }
                                    {props.showDate &&
                                        <div className="text-left pt-2">
                                            <span>{t("header.dateRange")}</span>
                                            <ButtonComponent
                                                onClick={() => toggleCustomDateRange()}
                                                isIcon={false}
                                                isPrimary={false}
                                                btnText={datePickerValue}
                                                btn_class={"btn-lg mb-3 filter-btn w-100"}
                                            />
                                        </div>
                                    }
                                    {showCustomDatePicker &&
                                        <ModalComponent
                                            className="daterange-popup"
                                            ref={customDateRangeRef}
                                            showCloseButton={true}
                                            open={showCustomDatePicker}
                                            handleClose={() => { setTimeout(() => { setShowCustomDatePicker(false) }, 10) }}
                                        >
                                            <DateRangeComponent
                                                startDate={startDate}
                                                setStartDate={setStartDate}
                                                endDate={endDate}
                                                setEndDate={setEndDate}
                                                setShowCustomDatePicker={setShowCustomDatePicker}
                                                setDatePickerValue={setDatePickerValue}
                                                setDatePickerCompareValue={setDatePickerCompareValue}
                                                validStartDate={validStartDate}
                                                selectedDateRangeOption={selectedDateRangeOption}
                                                setSelectedDateRangeOption={setSelectedDateRangeOption}
                                            />
                                        </ModalComponent>
                                    }
                                </Col>
                                <Col className="text-start" lg={12} xl={12}>
                                    <div className="d-block mt-4">
                                        <ButtonComponent
                                            onClick={() => applyFilters(true)}
                                            isIcon={false}
                                            isPrimary={true}
                                            btnText={t("header.AlertConfirmButtonOK")}
                                            btn_class={props.isApplyDisabled ? "btn-md mb-3 filter-btn disabled" : "btn-md mb-3 filter-btn"} //props.isAllpyDisabled is used to disable the ok button for manage event page, if there is no language for selected website.
                                        />
                                    </div>
                                    <div className="d-block">
                                        <ButtonComponent
                                            onClick={() => cancelFilters()}
                                            isIcon={false}
                                            isPrimary={false}
                                            btnText={t("header.AlertConfirmButtonCancel")}
                                            btn_class="btn-md mb-3 filter-btn"
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </Form>
                    }
                </Col>
            </Row>
        </>
    );
};

export default CommonHeader;