import React, { useState, useEffect } from 'react';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import { useTranslation } from 'react-i18next';
import { Row, Col, Button, Tooltip, OverlayTrigger, Form } from '@themesberg/react-bootstrap';

import iconSprite from "../../assets/Icon/svg-icn-sprite.svg";

import DropdownWithIconComponent from "../DropdownWithIcons";
import ButtonComponent from "../Buttons";

const WebcastFilters = (props) => {
  const [t, i18n] = useTranslation();

  const [showFiltersMenu, setShowFiltersMenu] = useState(false);

  const cancerFilters = function () {
    setShowFiltersMenu(false);
    if (props.cancelFilter !== undefined) {
      props.cancelFilter();
    }
  }

  const applyFilters = function () {
    props.applyFilters();
    setShowFiltersMenu(false);
  }

  const onClickOutsideListenerFilters = () => {
    setShowFiltersMenu(false)
    document.removeEventListener("click", onClickOutsideListenerFilters)
  }

  useEffect(() => {
    if (showFiltersMenu == true) {
      document.addEventListener('click', onClickOutsideListenerFilters);
    }
  }, [showFiltersMenu]);

  return (
    <>
      <div className="d-inline-block icon-nav icon-nav-filter ms-2">
        <div className="slider-dropdown-tigger d-inline-block filter-box-tigger icon-nav-list svg-icon-nav-list">
          <span className="icon-nav-box ms-0" onClick={(e) => { setShowFiltersMenu(!showFiltersMenu); e.stopPropagation(); }}>
            <svg className="icon-nav">
              <title>{t("header.Icon_Tooltip_Filters")}</title>
              <use href={iconSprite + `#Filter`} />
            </svg>
          </span>
        </div>
        {showFiltersMenu &&
          <Form className="date-range-container mt-2 filter-container webcast-viewers-filter-box" onMouseLeave={() => {
            document.addEventListener("click", onClickOutsideListenerFilters)
          }} onMouseEnter={() => { document.removeEventListener("click", onClickOutsideListenerFilters) }}>
            <Row className="text-end">
              <Col xs={12} sm={12} className="text-left">
                <h4 className="mt-4">{t("header.webcastViewersFiltersText")} <OverlayTrigger
                  placement="left"
                  trigger={['hover', 'focus']}
                  overlay={
                    <Tooltip>{t("header.filtersMessage")}</Tooltip>
                  }>
                  <Button variant="white" className="btn-help border-0 p-2">
                    <i className="far fa-question-circle lg"></i>
                  </Button>
                </OverlayTrigger></h4>
              </Col>
              <Col xs={12} sm={12} className="mb-1 position-relative">
                {
                  props.dataSource.map((element) => {
                    if (element.isVisible) {
                      return (<div className="text-left pt-2"><span className="d-block mb-1">{element.label}</span><DropdownWithIconComponent
                        dropdownData={element.data}
                        arrayValue={element.displayText}
                        selectedDropdown={element.selectedItem}
                        onChange={element.callback}
                        showLabel={false}
                        showIconInDropdownItem={false}
                        customClassName="full-width text-overflow"
                      /></div>)
                    } else {
                      return <></>
                    }
                  })
                }
              </Col>
              <Col className="text-start" lg={12} xl={12}>
                <div className="d-block mt-4">
                  <ButtonComponent
                    onClick={() => applyFilters()}
                    isIcon={false}
                    isPrimary={true}
                    btnText={t("header.WebcastFilterApplyButtonText")}
                    btn_class="btn-md mb-3 filter-btn"
                  />
                </div>
                <div className="d-block">
                  <ButtonComponent
                    onClick={() => cancerFilters()}
                    isIcon={false}
                    isPrimary={false}
                    btnText={t("header.WebcastFilterClearButtonText")}
                    btn_class="btn-md mb-3 filter-btn"
                  />
                </div>
              </Col>
            </Row>
          </Form>
        }
      </div>
    </>
  );
};

export default WebcastFilters;
