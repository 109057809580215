import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import moment from "moment";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { faCircle, faExternalLinkAlt, faEye, faDownload, faEdit, faPaperPlane, faPaperclip, faSearch, faSort, faSortDown, faSortUp, faTrash, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    Button,
    Card,
    Col,
    Form,
    FormCheck,
    Image,
    InputGroup,
    OverlayTrigger,
    Popover,
    Row,
    Table,
    Tooltip,
} from "@themesberg/react-bootstrap";
import { faTimesCircle } from "@fortawesome/free-regular-svg-icons";

import {
    BadgetsVarients,
    DEFAULT_TIMEOUT,
    OPERATION_TYPE,
    convertToInternationalFormat,
    customDurationIds,
    dispalyTableRow,
    intColumnWithComma,
    isEmpty,
    isGreaterThanMaxLength,
    isLessThanMinLength,
    optionalSummary,
    rowWithCommaInt,
    tbColumnHide,
    tbRowWithBadge,
    tbRowWithCheckBox,
    tbRowWithCheckBoxSubscribe,
    tbRowWithDateAndTime,
    tbRowWithDateAndTimeWidthGMT,
    tbRowWithDateOnly,
    tbRowWithDecimalNumber,
    tbRowWithDecimalNumberPercentage,
    tbRowWithDifferentDatabase,
    tbRowWithImage,
    tbRowWithIntegerNumber,
    tbRowWithLargeData,
    tbRowWithLargeDatawithToolTipLongText,
    tbRowWithLink,
    tbRowWithMonthOnly,
    tbRowWithMultiText,
    tbRowWithMultipleAction,
    tbRowWithPercentage,
    tbRowWithTimeOnly,
    tbRowWithToggle,
    tblRowWithReplaceData,
} from "../AppConfig";
import request from "../apis/request";
import { METHODS, baseURL } from "../apis/utilities/constant";
import iconSprite from "../assets/Icon/svg-icn-sprite.svg";
import LoaderImage from "../assets/img/LoaderGraphic.svg";
import imageUrl from "../assets/img/marker.svg";
import {
    calculateCommaSepValues,
    convertBase64,
    convertTranslatinString,
    monthName,
    msToHMS,
    repplaceData,
} from "../utils/common";
import { decryptData } from "../utils/crypto";

import BadgesComponent from "../components/Badges";
import AppContext from "../components/AppContext";
import Progress from "../components/Progress";
import Search from "../components/Search";
import QuestionModal from "../components/QuestionModel";
import DropdownWithIconsComponent from "../components/DropdownWithIcons";
import NotificationHistoryModal from "../components/NotificationHistory";
import PaginationComponent from "../components/Pagination";
import NotificationModal from "../components/NotificationsModal";

const TableComponent = ({
    handleEditCancel,
    onEditEvent,
    setTableDataForDelete,
    onDocumentDownload,
    onDocumentEdit,
    onDeleteDocument,
    apiResponseMaster,
    onSCReportItemClick,
    tableHeaderData,
    tableBodyData,
    showSearchTextBox,
    searchText,
    setSearchText,
    isRefresh,
    setIsRefresh,
    showPagination,
    onSortingChange,
    pageNo,
    setPageNo,
    items,
    setItems,
    onPageNoChange,
    showPaginationSummary,
    totalRecords,
    onItemChange,
    onSearchBoxChange,
    tableCaption,
    minimumRows,
    setShowReportDetailModal,
    setSelectedItem,
    cardName,
    title,
    downloadExcel,
    handlePlusButtonClick,
    deleteSubscriber,
    editSubscriber,
    onEventchange,
    checkedDocument,
    allDocumentChecked,
    setCheckDocument,
    setAllDocumentChecked,
    setIsMultiDeleteDisabled,
    openPopupEvent,
    GenerateDigestPdf,
    alertMessageDetails
}) => {

    const contextData = useContext(AppContext);

    const [tableHeader, setTableHeader] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [pagesData, setPageValue] = useState([]);
    const [historyData, setHistoryData] = useState({});
    const [aiRuleId, setAiRuleId] = useState(0);
    const [queryValue, setQueryValue] = useState("");
    const [searchWaterMark, setSearchWaterMark] = useState("Search Pages");
    const [searchCriteria, setSearchCriteria] = useState("Googleanalyticsdata.pagetitle");
    const [t, i18n] = useTranslation();
    localStorage.removeItem("dashboardItems");
    const [showLoader, setShowLoader] = useState(false);
    const [selectedCompany, setSelectedCompany] = useState({});
    const [selectedCompanyid, setSelectedCompanyid] = useState("");
    const [selectedCountryid, setSelectedCountryid] = useState("");
    const [blurLoader, setBlurLoader] = useState(false);
    const [formFields, setFormFields] = useState({
        companyName: "",
        country: {},
        notificationName: "",
        duration: "",
        threshold: {},
        customThreshold: "",
        customDurationStart: "",
        customDurationEnd: "",
        isCustomDuration: false,
    });
    const [formError, setFormError] = useState({
        companyName: "",
        country: "",
        notificationName: "",
        duration: "",
        threshold: "",
        customThreshold: "",
        customDurationStart: "",
        customDurationEnd: "",
        selectedCompany: "",
    });
    const [visitorFormFields, setVisitorFormFields] = useState({
        companyName: "",
        country: {},
        notificationName: "",
        duration: "",
        threshold: {},
        customThreshold: "",
        customDurationStart: "",
        customDurationEnd: "",
        isCustomDuration: false,
    });
    const [isFormSubmit, setIsFormSubmit] = useState(false);
    const [showError, setShowError] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showHistoryModal, setShowHistoryModal] = useState(false);
    const [showAutoFillForCompany, setAutoFillForCompany] = useState(false);
    const [isFormSubmittedSucessfully, setIsFormSubmittedSucessfully] = useState(false);
    const [showQuestionModal, setShowQuestionModal] = useState(false);
    const [questionModalContent, setQuestionModalContent] = useState({});
    const [isSuccess, setIsSuccess] = useState(false);
    const [watchlistProfileData, setwatchlistProfileData] = useState({});
    const [notificationData, setNotificationData] = useState({});
    const [watchlistIndex, setwatchlistIndex] = useState();
    const [tooltipPosition, setTooltipPosition] = useState("right")
    const [showViewLinks, setShowViewLinks] = useState({ id: "", isOpen: false })

    const quesStatus = [{ id: 2, statusName: "Pending" }, { id: 3, statusName: "Approved for All" }, { id: 4, statusName: "Approved for Presenter" }];


    const pivotConfigValuePages = {
        x: [
            "Googleanalyticsdata.pagetitle"
        ],
        y: [],
        fillMissingDates: true,
        joinDateRange: false,
    };

    useEffect(() => {
        const handleClick = (e) => {
            if (e.target?.id.includes("subsLinkButton"))
                return;
            handleViewLinkPopUp(e, "", false);
        };
        document.addEventListener('click', handleClick);

        return () => {
            document.removeEventListener('click', handleClick);
        };
    }, []);

    useEffect(() => {
        setQueryValue({
            dimensions: ["Googleanalyticsdata.pagetitle"],
            timeDimensions: [
                {
                    dimension: "Googleanalyticsdata.gadate",
                    dateRange: [`${contextData.startDate}`, `${contextData.endDate}`],
                },
            ],
            filters: [
                {
                    member: "Googleanalyticsdata.pagetitle",
                    operator: "contains",
                    values: [`${visitorFormFields.companyName}`],
                },
                {
                    member: "Googleanalyticsdata.profileid",
                    operator: "equals",
                    values: [`${contextData.selectedProfile["WebsiteProfiles.ProfileId"]}`],
                },],
        });
        setTableHeader(tableHeaderData);

        setTableData(tableBodyData);
        if (searchText != "") {
            formFields.companyName = searchText;
        }
    }, [
        tableBodyData,
        selectedCompanyid,
        selectedCountryid,
        historyData,
        isRefresh,
        isFormSubmit,
        searchText,
        tableHeaderData,
        formFields,
        visitorFormFields.companyName
    ]);

    const updateWatchlistNotificationToggle = () => {
        const updatedTableData = [...tableData];
        updatedTableData[watchlistIndex]["Watchlist.active"] = "0";
        setTableData(updatedTableData);
    }

    const rowClickEvent = (rowobj) => {
        if (openPopupEvent !== undefined) {
            openPopupEvent(rowobj);
        }
    }

    const handleViewLinkPopUp = (e, id, isOpen) => {
        e.stopPropagation();
        setShowViewLinks({ id, isOpen });
    }

    const updateFormFields = (val) => {
        setVisitorFormFields(val);
    };

    const onToggleChange = (event, data, index, type) => {
        const { checked } = event.target;
        let active = false;
        let executelogic = false;
        let notificationd = {}
        if (type == "Notification") {
            active = checked;
            executelogic = true;
            notificationd = {
                CompanyId: data.companyId,
                CountryId: data.countryId,
                DurationId: data.durationId,
                EmailAlert: 1,
                FromDate: data.fromDate,
                ToDate: data.toDate,
                IntellConfigId: data.intellConfigId,
                IsActive: active,
                NotificationName: data.notificationName,
                PageViewsCount: data.pageViewsCount,
                RuleId: data.ruleId,
                SMSAlert: 0
            }
        }
        else if (type == "WatchlistNotification") {
            if (data["Watchlist.active"] == true) {
                executelogic = true;
                notificationd = {
                    CompanyId: data.CompanyId,
                    CountryId: data.CountryId,
                    DurationId: data.durationId,
                    EmailAlert: 1,
                    FromDate: data.fromDate != null ? data.fromDate : "0001-01-01T00:00:00",
                    ToDate: data.toDate != null ? data.toDate : "0001-01-01T00:00:00",
                    IntellConfigId: data.intellConfigId,
                    IsActive: active,
                    NotificationName: data.notificationName,
                    PageViewsCount: data.pageViewsCount,
                    RuleId: data.ruleId,
                    SMSAlert: 0
                }
            }
            else {
                rowClickEvent(data);
            }
        }
        if (executelogic) {

            setShowLoader(true);
            const authAxios = axios.create({
                baseURL: `${process.env.REACT_APP_API_KEY}`,
                headers: {
                    'Authorization': `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`
                },
            });
            authAxios
                .post(
                    `${process.env.REACT_APP_API_KEY}/WebsiteAnalytics/SetNotification?companyid=` + contextData.companyId + `&packageid=` + 5,
                    notificationd
                )
                .then((response) => {

                    if (response.data?.resultData && response.data.resultData.ruleId > 0) {
                        const action = "Modify";
                        let index = -1
                        let newobj = {};
                        if (type == "Notification") {
                            index = tableBodyData.findIndex(function (item) { return item.companyId == notificationd.CompanyId && item.countryId == notificationd.CountryId });
                            newobj = Object.assign({}, tableBodyData[index]);
                            newobj["notifications.Active"] = active;
                            setIsRefresh(true);
                        }
                        else if (type == "WatchlistNotification") {
                            index = tableBodyData.findIndex(function (item) { return item.CompanyId == notificationd.CompanyId && item.CountryId == notificationd.CountryId });
                            newobj = Object.assign({}, tableBodyData[index]);
                            newobj["Watchlist.active"] = false;
                        }
                        onEventchange(newobj, index, action, "-Successmsg-" + t("header.Message_" + convertTranslatinString(response.data.resultData.message)));
                    } else {
                        onEventchange({}, 0, "", "-Errormsg-" + t("header.Something_went_wrong"));
                    }
                })
                .catch((error) => {
                    onEventchange({}, 0, "", "-Errormsg-" + t("header.Something_went_wrong"));
                });
        }
        // else {
        //   handleModalOpenButtonClick(event, null, data);
        // }
    };

    const onCheckBoxChange = (event, data, index) => {
        const { name, checked } = event.target;
        const updatedTableData = [...tableData];
        data[name] = checked == true ? 1 : 0;
        updatedTableData[index] = data;

        setTableData(updatedTableData);

        if (setItems) {
            setItems(updatedTableData);
        }

        onItemChange && onItemChange(updatedTableData);
    };

    const setWatchlist = (data) => {
        let watchlistData = {};
        watchlistData.ProfileId =
            contextData.selectedProfile["WebsiteProfiles.ProfileId"];
        watchlistData.CompanyId = data["Visitors.VisitorId"];
        watchlistData.CountryId = data["Visitors_country.Id"];
        const authAxios = axios.create({
            baseURL: `${process.env.REACT_APP_API_KEY}`,
            headers: {
                'Authorization': `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`
            },
        });
        if (data["Visitors.iswatchlist"] === "0") {
            authAxios
                .post(
                    `${process.env.REACT_APP_API_KEY}/Intelligence/SetIntelligenceWatchedVisitor?&userId=${contextData.userId}&companyid=${contextData.companyId}`,
                    watchlistData
                )
                .then((response) => {
                    setShowLoader(false);
                })
                .catch((error) => {
                    console.log("error", error);
                });
        } else {
            setShowQuestionModal(true);
            setwatchlistProfileData(watchlistData);
        }
    };

    //This method written for handle changing checkbox for document page only
    //NOTE: DO NOT USE FOR GENERIC PURPOSE
    const onCheckChangeDocument = (event, data, index) => {
        const { name, checked } = event.target;
        let updatedCheckDocument = checkedDocument;
        const updatedTableData = [...tableData];
        let checkedDocumentList = [];

        apiResponseMaster && Array.isArray(apiResponseMaster) && apiResponseMaster.length > 0 && apiResponseMaster.map((item, index) => {
            if (item["DocumentTable.IsDocumentOwner"]) {
                checkedDocumentList.push(item["DocumentTable.DocumentID"]);
            }
        })


        //NOTE: If data becomes undefined that means user checked all document checkbox
        if (data) {

            updatedTableData[index].isChecked = checked;

            if (checked) {
                updatedCheckDocument.push(data["DocumentTable.DocumentID"]);
            }
            else {
                let itemIndex = updatedCheckDocument.indexOf(data["DocumentTable.DocumentID"]);
                updatedCheckDocument.splice(itemIndex, 1);
            }
            setTableDataForDelete(apiResponseMaster);
            updatedTableData[index] = data;

        }
        else {
            if (checked) {
                updatedCheckDocument = [];
                apiResponseMaster && Array.isArray(apiResponseMaster) && apiResponseMaster.length > 0
                    && apiResponseMaster.map((item, index) => {
                        if (item["DocumentTable.IsDocumentOwner"]) {
                            updatedCheckDocument.push(item["DocumentTable.DocumentID"]);
                        }
                    })

                setTableDataForDelete(apiResponseMaster);
            }
            else {
                updatedCheckDocument = [];
                setTableDataForDelete([]);
            }

            setAllDocumentChecked(checked);
        }
        data && setAllDocumentChecked(updatedCheckDocument.length == checkedDocumentList.length ? true : false);

        setCheckDocument(updatedCheckDocument);
        setIsMultiDeleteDisabled(updatedCheckDocument.length > 0 ? true : false);
        setItems && setItems(updatedTableData);
        setTableData(updatedTableData);
    }

    const onCheckBoxChangeDocument = (event, data, index) => {
        if (data["Visitors.VisitorId"] !== undefined) {
            setWatchlist(data);
            setwatchlistIndex(index);
        }
        const { name, checked } = event.target;
        const updatedTableData = [...tableData];
        data[name] = checked === true ? "1" : "0";
        updatedTableData[index] = data;
        setItems(updatedTableData);
        setTableData(updatedTableData);
    };

    const sortData = (key, order, item) => {
        if (item.type === "number") {
            if (order === "asc") {
                tableData.sort((a, b) => b[key] - a[key])
            } else {
                tableData.sort((a, b) => a[key] - b[key])
            }
        }
        if (item.type === "string") {
            if (order === "asc") {

                tableData.sort((a, b) => b[key] !== null ? b[key].trim().localeCompare(a[key].trim()) : '')
            } else {
                tableData.sort((a, b) => a[key] !== null ? a[key].trim().localeCompare(b[key].trim()) : '')
            }
        }
        if (item.type === "time") {
            if (order === "asc") {
                tableData.sort((a, b) => new Date(a[key]) - new Date(b[key]))
            } else {
                tableData.sort((a, b) => new Date(b[key]) - new Date(a[key]))
            }
        }
    }

    const handleSorting = (event, data, indexOfData) => {
        event.preventDefault();
        const updateTableHeader = [...tableHeader];
        updateTableHeader.forEach((dta, idx) => {
            if (idx === indexOfData) {
                if (!dta.isSortAscAndDesc) dta.isSortDesc = !dta.isSortDesc;

                dta.isSortAscAndDesc = false;
                updateTableHeader[indexOfData] = data;
            }
            else {
                dta.isSortAscAndDesc = true;
                dta.isSortDesc = true;
            }
        });

        const sortKey = updateTableHeader[indexOfData].key;
        const sortOrder = updateTableHeader[indexOfData].isSortDesc
            ? "desc"
            : "asc";
        setTableHeader(updateTableHeader);
        onSortingChange && onSortingChange(sortKey, sortOrder, updateTableHeader[indexOfData]);
        let sortingString = { key: sortKey, sortOrder: sortOrder }
        localStorage.setItem("sorting", JSON.stringify(sortingString));
        sortData(sortKey, sortOrder, updateTableHeader[indexOfData]);
    };

    const renderTableHeader = (headerData) => (
        <thead className="thead-light">
            <tr>
                {headerData.length > 0 ? (
                    headerData.map((c, index) => createHeader(c, index))
                ) : (
                    <th> {t("header.NoDataFound")} </th>
                )}
            </tr>
        </thead>
    );

    const createHeader = (c, index) => {

        const tooltipLabels = [
            { labelName: "Industry", tooltipText: "header.IndustryLabelTooltip" },
            { labelName: "Country", tooltipText: "header.CountriesLabelTooltip" },
            { labelName: "Users", tooltipText: "header.UsersLabelTooltip" },
            { labelName: "Delivered", tooltipText: "header.archevedAlertsDelelivedTooltipText" },
            { labelName: "Open Rate", tooltipText: "header.alertsAnalyticsOpenRateTooltipText" },
            { labelName: "Click Through Rate", tooltipText: "header.alertsAnalyticsClickTroughRateTooltipText" },
            { labelName: "UnDelivered", tooltipText: "header.archivedAlertsUnDeliveredcountTooltipText" },
            { labelName: "Delivered Count", tooltipText: "header.archivedAlertsDeliveredcountTooltipText" },
            { labelName: "Report period", tooltipText: "header.serviceCenterReportPeriodTooltipText" },
            { labelName: "Time stamp", tooltipText: "header.serviceCenterTimestampTooltipText" },
            { labelName: "Sent", tooltipText: "header.notificationsSentTooltipText" },
            { labelName: "History", tooltipText: "header.notificationsViewHistoryTooltipText" },
            { labelName: "Domain", tooltipText: "header.domainTooltiopText" },
            { labelName: "Company Domains", tooltipText: "header.AlertdomainTooltiopText" },
            { labelName: "View Duration", tooltipText: "header.viewDurationTooltipText" },
            { labelName: "Device Type", tooltipText: "header.AlertDeviceTypeLabelTooltip" },
            { labelName: "Top Domains", tooltipText: "header.topDomainsTooltipText" },
            { labelName: "Search term", tooltipText: "header.searchTermTooltipText" },
            {
                labelName: "Top Categories", tooltipText: "header.InsightsTopCategoryHelpText"
            },
            {
                labelName: "Top Countries", tooltipText: "header.InsightsTopCountryHelpText"
            },
        ];
        let tooltipElement = "";
        tooltipLabels.forEach(function (element) {
            if (c.key == "title2") {
                element = { labelName: "Device Type", tooltipText: "header.webcastDeviceTypeTooltipText" };
            }
            if (c.key == "Country.TopCountries") {
                element = { labelName: "Top Countries", tooltipText: "header.AlertCountriesLabelTooltip" };
            }
            if (c.key === "DeviceType" || c.key === "CountryName") {
                element = {};
            }
            if (element.labelName === c.shortTitle) {
                tooltipElement = (
                    <OverlayTrigger
                        placement={tooltipPosition}
                        trigger={["hover", "focus"]}
                        overlay={
                            // <Tooltip>{t("header.VisitorAnalyticsTooltip")}</Tooltip>

                            <Tooltip>
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: t(element.tooltipText, {
                                            interpolation: { escapeValue: false },
                                        }),
                                    }}
                                ></div>
                            </Tooltip>
                        }
                    >
                        <Button onMouseMove={(e) => e.clientX > (window.screen.width - 300) ? setTooltipPosition("left") : setTooltipPosition("right")} variant="white" className="btn-help border-0 p-1">
                            <i className="far fa-question-circle lg"></i>
                        </Button>
                    </OverlayTrigger>
                );
            }
        });
        const isColumnHide = tbColumnHide.some((data) => data === c.key);
        if (!isColumnHide) {
            return (
                <>
                    {
                        c.key == "DocumentTable.DocumentID" ? <th key={c.key}>
                            <FormCheck type="checkbox" className="inbox-check me-2">
                                <FormCheck.Input
                                    id="message-1"
                                    name={c.rowKey}
                                    className="me-2"
                                    checked={allDocumentChecked}
                                    value={allDocumentChecked}
                                    onChange={(e) => onCheckChangeDocument(e, undefined, undefined)}
                                />

                            </FormCheck>
                        </th> :
                            <th key={c.key} className={c.key == "pagesCategory" ? "hide-mobile" : ""}>
                                {c.isSortable &&
                                    (c.isSortAscAndDesc ? (
                                        <a
                                            onClick={(event) => handleSorting(event, c, index)}
                                            className="me-1"
                                        >
                                            <FontAwesomeIcon icon={faSort} />
                                        </a>
                                    ) : c.isSortDesc ? (
                                        <a
                                            onClick={(event) => handleSorting(event, c, index)}
                                            className="me-1"
                                        >
                                            <FontAwesomeIcon icon={faSortUp} />
                                        </a>
                                    ) : (
                                        <a
                                            onClick={(event) => handleSorting(event, c, index)}
                                            className="me-1"
                                        >
                                            <FontAwesomeIcon icon={faSortDown} />
                                        </a>
                                    ))}
                                <span className="sorting-label">{c.shortTitle ? t("header.table_colume_" + convertTranslatinString(c.shortTitle.replace("Top Domainss", "Top Domains")), c.shortTitle.replace("Top Domainss", "Top Domains")) : c}{tooltipElement}</span>

                            </th>
                    }

                </>
            );
        }
    };

    const renderTableBody = (headerData, bodyData) => (
        <tbody>
            {headerData.length > 0 && bodyData.length > 0 ? (
                bodyData.map((row, index) => {
                    return (
                        <tr key={index}>
                            {headerData.map((c) => {
                                return renderTableBodyContent(row, c.key === undefined ? c : c.key, index, t);
                            })}
                        </tr>
                    );
                })
            ) : (
                <tr>
                    <td className="text-center" colspan={headerData.length}>
                        {" "}
                        {t("header.NoDataFound")}{" "}
                    </td>
                </tr>
            )}
            {renderOptionalSummary(headerData)}
        </tbody>
    );

    const changeEventAccessStatus = async (label, eventId, userId, row) => {
        const eventStatus = label === "Disable" ? "disabled" : "enabled";
        try {
            const response = await request(baseURL + `/Analytics/ChangeEventAccessStatus?EventAccessStatus=${eventStatus}&EventId=${eventId}&userId=${userId}`,
                METHODS.GET,
                {},
                {
                    Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
                });
            if (response !== null) {
                if (response && response.status === 200) {
                    const action = "Modify";
                    let index = -1
                    let newobj = {};
                    index = tableBodyData.findIndex(function (item) { return item.registrationId === row.registrationId });
                    newobj = Object.assign({}, tableBodyData[index]);
                    newobj["eventAccessStatus"] = newobj["eventAccessStatus"] === "approved" ? "disabled" : newobj["eventAccessStatus"] === "disabled" ? "approved" : ""
                    onEventchange(newobj, index, action, "-Successmsg-" + t("header.EventChangeStatusSuccessMessage"));
                } else {
                    onEventchange({}, 0, "", "-Errormsg-" + t("header.EventChangeStatusErrorMessage"));
                }
            }
        } catch (err) {
            console.log("Error occured at changeEventStatus", err);
        }
    }

    const onSuccessModelClose = () => {
        setIsSuccess(true);
        showQuestionModal && setShowQuestionModal(false);
        if (questionModalContent.type === "Watchlist") {
            saveWatchDeleteStatus()
        }
        else {
            deleteNotification()
        }
    };

    const onCancelClose = () => {
        if (questionModalContent.type !== "Notification") {
        }
    };

    const saveWatchDeleteStatus = () => {
        setShowLoader(true);
        const authAxios = axios.create({
            baseURL: `${process.env.REACT_APP_API_KEY}`,
            headers: {
                'Authorization': `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`
            },
        });
        authAxios
            .post(
                `${process.env.REACT_APP_API_KEY}/VisitorAnalytics/DeleteWatchlist`,
                watchlistProfileData
            )
            .then((response) => {
                setShowLoader(false);
                if (response && response.data.successStatus === true) {
                    let index = -1
                    if (watchlistProfileData.watch !== 1) {
                        index = tableBodyData.findIndex(function (item) { return item.companyId === watchlistProfileData.VisitorId && item.countryId === watchlistProfileData.CountryId });
                    }
                    else {
                        index = tableBodyData.findIndex(function (item) { return item.CompanyId === watchlistProfileData.VisitorId && item.CountryId === watchlistProfileData.CountryId });
                    }
                    let newobj = Object.assign({}, tableBodyData[index]);
                    newobj.watched = 0;
                    const action = watchlistProfileData.watch === 1 ? "Remove" : "Modify";

                    onEventchange(newobj, index, action, "-Successmsg-" + t("header.Message_Visitor_removed_from_your_watchlist_successfully"));
                    localStorage.setItem("refreshCache", 1);
                } else {
                    onEventchange({}, 0, "", "-Errormsg-" + t("header.Something_went_wrong"));
                }
            })
            .catch((error) => {
                console.log("error", error);
            });
        setShowLoader(false);

    };

    const handleDelteWatchlistButtonClick = (e, row, index, type) => {
        if (type !== "watched") {
            e.preventDefault();
            setShowQuestionModal(true);
            setQuestionModalContent({ type: "Watchlist", title: "header.WatchlistDeleteTitle", content: "header.WatchlistDeleteNote" })
            let profiledta = {
                ProfileId: contextData.selectedProfile["WebsiteProfiles.ProfileId"],
                VisitorId: row["CompanyId"],
                CountryId: row["CountryId"],
                UserID: contextData.userId,
                SelectedCompanyID: contextData.companyId,
                PackageId: 4,
                watch: 1
            };
            setwatchlistProfileData(profiledta);
            setwatchlistIndex(index);
        }
        else {
            if (tableBodyData[index].watched === 1) {

                e.preventDefault();
                setShowQuestionModal(true);
                setQuestionModalContent({ type: "Watchlist", title: "header.WatchlistDeleteTitle", content: "header.WatchlistDeleteNote" })
                let profiledta = {
                    ProfileId: contextData.selectedProfile["WebsiteProfiles.ProfileId"],
                    VisitorId: row["companyId"],
                    CountryId: row["countryId"],
                    UserID: contextData.userId,
                    SelectedCompanyID: contextData.companyId,
                    PackageId: 4,
                    watch: 0
                };
                setwatchlistProfileData(profiledta);
                setwatchlistIndex(index);
            }
            else {

                e.preventDefault();
                let profiledta = {
                    ProfileId: contextData.selectedProfile["WebsiteProfiles.ProfileId"],
                    VisitorId: row["companyId"],
                    CountryId: row["countryId"],
                    UserID: contextData.userId,
                    SelectedCompanyID: contextData.companyId,
                    PackageId: 4
                };
                setShowLoader(true);
                const authAxios = axios.create({
                    baseURL: `${process.env.REACT_APP_API_KEY}`,
                    headers: {
                        'Authorization': `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`
                    },
                });
                authAxios
                    .post(
                        `${process.env.REACT_APP_API_KEY}/VisitorAnalytics/SetWatchlist`,
                        profiledta
                    )
                    .then((response) => {
                        setShowLoader(false);
                        if (response && response.data.successStatus === true) {
                            let newobj = Object.assign({}, tableBodyData[index]);
                            newobj.watched = 1;

                            onEventchange(newobj, index, "Modify", "-Successmsg-" + t("header.Message_Visitor_added_to_your_watchlist_successfully"));
                            localStorage.setItem("refreshCache", 1);
                        } else {
                            onEventchange({}, 0, "", "-Errormsg-" + t("header.Something_went_wrong"));
                        }
                    })
                    .catch((error) => {
                        console.log("error", error);
                    });
                setShowLoader(false);
                setIsSuccess(true);
            }
        }
    };

    const handleModalWororderReportButtonClick = (e, obj, row) => {
        setShowReportDetailModal(true);
        setSelectedItem(row);
    };

    const validateFormData = (name, value) => {
        let errorObj = formError;
        switch (name) {
            case "companyName":
                errorObj.companyName = "";
                if (isEmpty(value)) {
                    errorObj.companyName = t("header.WONotificationcompanyValidation");
                }
                break;
            case "notificationName":
                errorObj.notificationName = "";
                if (isEmpty(value)) {
                    errorObj.notificationName = t("header.WONotificationameValidation");
                } else if (isLessThanMinLength(value, 2)) {
                    errorObj.notificationName = t("header.WONotificatioCharValidation");
                }
                break;
            case "threshold":
                errorObj.threshold = "";
                if (value && Object.keys(value).length === 0) {
                    errorObj.threshold = t("header.WONotificatioThresholdValidation");
                } else if (value.key === "custom") {
                    if (!formFields.customThreshold) {
                        errorObj.customThreshold = t(
                            "header.WONotificatioCThresholdValidation"
                        );
                    } else if (formFields.customThreshold < 10) {
                        errorObj.customThreshold = t(
                            "header.WONotificatioThresold10Validation"
                        );
                    } else if (isGreaterThanMaxLength(formFields.customThreshold, 8)) {
                        errorObj.customThreshold = t(
                            "header.WONotificatioThresold8Validation"
                        );
                    }
                }
                break;
            case "customThreshold":
                errorObj.customThreshold = "";
                if (formFields.threshold.key === "custom") {
                    if (!value) {
                        errorObj.customThreshold = t(
                            "header.WONotificatioCThresholdValidation"
                        );
                    } else if (value <= 10) {
                        errorObj.customThreshold = t(
                            "header.WONotificatioThresold10Validation"
                        );
                    } else if (isGreaterThanMaxLength(value, 8)) {
                        errorObj.customThreshold = t(
                            "header.WONotificatioThresold8Validation"
                        );
                    }
                }
                break;
            case "duration":
                errorObj.duration = "";
                const customDurationId = parseInt(value);
                const customDurationIndex = customDurationIds.indexOf(customDurationId);
                if (!value) {
                    errorObj.duration = t("header.WONotificatioDurationValidation");
                } else if (customDurationIndex !== -1) {
                    if (!formFields.customDurationStart) {
                        errorObj.customDurationStart = t(
                            "header.WONotificatioStartDateValidation"
                        );
                    }
                    if (!formFields.keyscustomDurationEnd) {
                        errorObj.customDurationEnd = t(
                            "header.WONotificatioEndDateValidation"
                        );
                    }
                }
                break;
            case "customDurationStart":
                errorObj.customDurationStart = "";
                if (formFields.isCustomDuration) {
                    if (!value) {
                        errorObj.customDurationStart = t(
                            "header.WONotificatioStartDateValidation"
                        );
                    } else if (
                        formFields.customDurationEnd &&
                        value > formFields.customDurationEnd
                    ) {
                        errorObj.customDurationStart = t(
                            "header.WONotificatioEndDateValidation"
                        );
                    }
                }
                break;
            case "customDurationEnd":
                errorObj.customDurationEnd = "";
                if (formFields.isCustomDuration) {
                    if (!value) {
                        errorObj.customDurationEnd = t(
                            "header.WONotificatioEndDateValidation"
                        );
                    }
                }
                break;
            default:
                break;
        }
        setFormError(errorObj);
    };

    const isFormFieldsValid = () => {
        const errorObj = formError;
        let isFieldValid = true;

        for (let fieldName in errorObj) {
            if (fieldName === "selectedCompany") {
                validateFormData(fieldName, selectedCompany);
            } else {
                validateFormData(fieldName, formFields[fieldName]);
            }
            if (errorObj[fieldName].length > 0) {
                isFieldValid = false;
            }
        }
        return isFieldValid;
    };

    const handleSubmitButtonClick = (e) => {
        e.preventDefault();
        setIsFormSubmit(true);
        setShowError(true);
        if (isFormFieldsValid()) {
            const notificationData = {
                UserCompanyId: contextData.companyId,
                UserId: contextData.userId,
                RuleId: aiRuleId ? aiRuleId : 0,
                NotificationName: formFields.notificationName,
                IntellConfigId: contextData.intelConfigId,
                CompanyId: formFields.CompanyId,
                CountryId: formFields.CountryId,
                DurationId: parseInt(formFields.duration),
                PageViewsCount: parseInt(
                    formFields.threshold.key === "custom"
                        ? formFields.customThreshold
                        : formFields.threshold.key
                ),
                IsActive: true,
                EmailAlert: true,
                SMSAlert: false,
            };
            if (formFields.isCustomDuration) {
                notificationData.FromDate = formFields.customDurationStart
                    ? moment(formFields.customDurationStart).toISOString()
                    : null;
                notificationData.ToDate = formFields.customDurationEnd
                    ? moment(formFields.customDurationEnd).toISOString()
                    : null;
            }
            setShowLoader(true);
            const authAxios = axios.create({
                baseURL: `${process.env.REACT_APP_API_KEY}`,
                headers: {
                    'Authorization': `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`
                },
            });
            authAxios
                .post(
                    `${process.env.REACT_APP_API_KEY}/Website/SetNotification?companyid=` + contextData.companyId + `&packageid=` + 5,
                    notificationData
                )
                .then((response) => {
                    setShowLoader(false);
                    if (response && response.data) {
                        setIsFormSubmittedSucessfully(true);
                        setTimeout(function () {
                            setIsFormSubmittedSucessfully(false);
                        }, DEFAULT_TIMEOUT);
                    } else {
                    }
                    //onPageNoChange(1);
                    if (searchText) {
                        setSearchText("");
                    }
                })
                .catch((error) => {
                    setShowLoader(false);
                    console.log("error", error);
                });
        }
    };

    const onQuestionStatusDropdownChange = (e, data, index) => {
        const updatedTableData = [...tableData];
        data["QuestionApprovedFor"] = (e.id === 1) ? "0" : "1";
        if (e.id === 2) {
            data["QuestionApproved"] = false;
            data["QuestionApprovedFor"] = "none";
        }
        else if (e.id === 3) {
            data["QuestionApproved"] = true;
            data["QuestionApprovedFor"] = "all";
        }
        else if (e.id === 4) {
            data["QuestionApproved"] = true;
            data["QuestionApprovedFor"] = "presenter";
        }
        updatedTableData[index] = data;

        setTableData(updatedTableData);
        const authAxios = axios.create({
            baseURL: `${process.env.REACT_APP_API_KEY}`,
            headers: {
                'Authorization': `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`
            },
        });
        authAxios
            .get(
                `${process.env.REACT_APP_API_KEY}/Analytics/SetQuestionStatus?presentationId=${contextData.presentationId}&questionId=${data["QuestionId"]}&questionApproved=${!!+data["QuestionApproved"]}&questionApprovedFor=${data["QuestionApprovedFor"]}`,
            )
            .then(() => {
            })
            .catch((error) => {
                console.log("error", error);
            });
    }

    const deleteNotificationPopup = (row) => {
        setShowQuestionModal(true);
        setQuestionModalContent({ type: "Notification", title: "header.NotificationDeleteTitle", content: "header.NotificationDeleteNote" })
        const notificationd = {
            CompanyId: row.companyId,
            CountryId: row.countryId,
            DurationId: row.durationId,
            EmailAlert: 1,
            FromDate: row.fromDate,
            ToDate: row.toDate,
            IntellConfigId: row.intellConfigId,
            IsActive: row["notifications.Active"],
            NotificationName: row.notificationName,
            PageViewsCount: row.pageViewsCount,
            RuleId: row.ruleId,
            SMSAlert: 0
        }
        setNotificationData(notificationd)
    }

    const deleteNotification = () => {
        request(
            baseURL + `/VisitorAnalytics/DeleteNotification?intelconfigid=` + notificationData.IntellConfigId,
            METHODS.POST,
            notificationData,
            {
                Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
            }
        ).then((response) => {
            setShowLoader(false);
            if (response && response.resultData.ruleId > 0) {
                let index = -1
                index = tableBodyData.findIndex(function (item) { return item.ruleId == notificationData.RuleId && item.companyId == notificationData.CompanyId });

                let newobj = Object.assign({}, tableBodyData[index]);
                const action = "Remove"

                onEventchange(newobj, index, action, "-Successmsg-" + t("header.Message_Notification_deleted_successfully"));
                setIsRefresh(true);
                setSearchText("");
            }
            else {
                onEventchange({}, 0, "", "-Errormsg-" + t("header.Something_went_wrong"));
            }

        }).catch((error) => {
            onEventchange({}, 0, "", "-Errormsg-" + t("header.Something_went_wrong"));
        });
    }

    const renderTableBodyContent = (row, rowKey, rowIndex, t) => {
        const isRowWithImage = tbRowWithImage.some((data) => data === rowKey);
        const isRowWithToggle = tbRowWithToggle.some((data) => data === rowKey);
        const isRowWithCheckBox = tbRowWithCheckBox.some((data) => data === rowKey);
        const isRowDateTime = tbRowWithDateAndTime.some((data) => data === rowKey);
        const isRowDate = tbRowWithDateOnly.some((data) => data === rowKey);
        const isRowMonth = tbRowWithMonthOnly.some((data) => data === rowKey);
        const isRowTime = tbRowWithTimeOnly.some((data) => data === rowKey);
        const isRowDateTimeWithGMT = tbRowWithDateAndTimeWidthGMT.some((data) => data === rowKey);
        const isRowIntNumber = tbRowWithIntegerNumber.some(
            (data) => data === rowKey
        );
        const isRowPercentage = tbRowWithPercentage.some((data) => data === rowKey);
        const isRowDecimalNumber = tbRowWithDecimalNumber.some(
            (data) => data === rowKey
        );
        const isRowDecimalNumberPercentage = tbRowWithDecimalNumberPercentage.some(
            (data) => data === rowKey
        );
        const isRowWithLargeData = tbRowWithLargeData.some(
            (data) => data === rowKey
        );
        const isRowWithLargeDataToolTipLongText = tbRowWithLargeDatawithToolTipLongText.some(
            (data) => data === rowKey
        );
        const shouldShowMultiLineRow = tbRowWithMultiText.find(
            (data) => data.key === rowKey
        );
        const isRowWithBadge = tbRowWithBadge.some((data) => data === rowKey);
        const isRowWithTime = tbRowWithTimeOnly.some((data) => data === rowKey);
        const isRowWithdifferentdatabase = tbRowWithDifferentDatabase.some(
            (data) => data === rowKey
        );
        const isRowWithLink = tbRowWithLink.some((data) => data === rowKey);
        const isColumnHide = tbColumnHide.some((data) => data === rowKey);
        const isRowWithCheckBoxSubscribe = tbRowWithCheckBoxSubscribe.some(
            (data) => data === rowKey
        );
        const isRowWithMultipleAction = tbRowWithMultipleAction.some(
            (data) => data === rowKey
        );
        const isRowWithReplaceData = tblRowWithReplaceData.some(
            (data) => data === row[rowKey]
        );


        if (intColumnWithComma.some((data) => data === rowKey)) {
            return (
                <td className="word-break table-truncate-text" key={rowKey}>
                    <Card.Link onClick={(e) => rowClickEvent({ data: row, type: "display" })}
                    >
                        <div className="table-row-title w-100 parentCell truncate-text-container" title={convertToInternationalFormat(dispalyTableRow((row[rowKey])))}>
                            {rowKey === "PageViewCount" ?
                                convertToInternationalFormat(dispalyTableRow((row[rowKey]))) + `+`
                                : convertToInternationalFormat(dispalyTableRow((row[rowKey])))}
                        </div>
                    </Card.Link>
                </td>
            )
        }

        if (isRowWithImage) {
            return (
                <td key={rowKey}>
                    <Card.Link href="#" className="d-flex align-items-center">
                        <Image src={imageUrl} className="image-small rounded-circle me-2" />
                        {/* <div><span className="h6">{country}</span></div> */}
                    </Card.Link>
                </td>
            );
        }
        else if (isRowWithToggle) {
            if (rowKey === "Watchlist.active") {
                var isToggle = row[rowKey] === true ? true : false;
                return (
                    <td key={rowKey} className={row["isBlocked"] == true ? "is-disabled action-not-allowed " : ""}>
                        <FormCheck type="switch" className={localStorage.getItem("isDemoClient") ? "custom-disable" : ""}>
                            <FormCheck.Input
                                disabled={row["isBlocked"] == true ? true : false}
                                type="checkbox"
                                id="billingSwitch"
                                className="me-2"
                                checked={isToggle}
                                value={isToggle}
                                onChange={(e) => onToggleChange(e, row, rowIndex, "WatchlistNotification")}
                            />
                            <FormCheck.Label>{isToggle ? t("header.Notification_Toggle_On") : t("header.Notification_Toggle_Off")} </FormCheck.Label>
                        </FormCheck>
                    </td>
                );
            }
            else if (rowKey === "notifications.Active") {
                var isToggle = row[rowKey] === true ? true : false;
                return (
                    <td key={rowKey}>
                        <FormCheck type="switch" className={localStorage.getItem("isDemoClient") ? "custom-disable" : ""}>
                            <FormCheck.Input
                                type="checkbox"
                                id="billingSwitch"
                                className="me-2"
                                checked={isToggle}
                                value={isToggle}
                                onChange={(e) => onToggleChange(e, row, rowIndex, "Notification")}
                            />
                            <FormCheck.Label>{isToggle ? t("header.Notification_Toggle_On") : t("header.Notification_Toggle_Off")} </FormCheck.Label>
                        </FormCheck>
                    </td>
                );
            }
            else {
                var isToggle = parseInt(row[rowKey]) === 1 ? true : false;
                return (
                    <td key={rowKey}>
                        <FormCheck type="switch" className={localStorage.getItem("isDemoClient") ? "custom-disable" : ""}>
                            <FormCheck.Input
                                type="checkbox"
                                id="billingSwitch"
                                className="me-2"
                                checked={isToggle}
                                value={isToggle}
                                onChange={(e) => onToggleChange(e, row, rowIndex)}
                            />
                            <FormCheck.Label>{isToggle ? "On" : "Off"} </FormCheck.Label>
                        </FormCheck>
                    </td>
                );
            }
        }
        else if (isRowWithBadge) {
            var tr = row[rowKey];
            if (tr === 1) {
                let msg = "Active";
                if (rowKey === "notifications.Ismailsent") {
                    msg = t("header.Notification_Sent");
                }
                return (
                    <td key={rowKey}>
                        <BadgesComponent
                            bgName={BadgetsVarients["success"]}
                            message={msg}
                            cls="me-1 badge-active"
                        />
                    </td>
                );
            } else {
                let msg = "Inactive";
                if (rowKey === "notifications.Ismailsent") {
                    msg = t("header.Notification_Not_sent");
                }
                return (
                    <td key={rowKey}>
                        <BadgesComponent
                            bgName={BadgetsVarients["light"]}
                            message={msg}
                            cls="me-1 badge-inactive"
                        />
                    </td>
                );
            }
        }
        else if (isRowWithCheckBox) {
            var isChecked = parseInt(row[rowKey]) === 0 ? false : true;

            if (rowKey === "Watchlist.delete" || rowKey === "watched") {
                return (
                    <td key={rowKey} >
                        <FormCheck type="checkbox" className={localStorage.getItem("isDemoClient") ? "custom-disable inbox-check me-2" : "inbox-check me-2"} >
                            <FormCheck.Input
                                title={isChecked ? t("header.Icon_Tooltip_RemoveFromWatchlist") : t("header.Icon_Tooltip_AddToWatchlist")}
                                id="message-1"
                                name={rowKey}
                                className="me-2"
                                checked={isChecked}
                                value={isChecked}
                                onChange={(e) =>
                                    handleDelteWatchlistButtonClick(e, row, rowIndex, rowKey)
                                }
                            />
                            <FormCheck.Label>
                                {" "}
                                {isChecked && rowKey === "Watchlist.delete" ? "" : rowKey === "Watchlist.delete" ? "Off" : ""}{" "}
                            </FormCheck.Label>
                        </FormCheck>
                    </td>
                );
            } else if (
                rowKey != "Midocuments.Documentcheck" &&
                rowKey != "Visitors.iswatchlist"
            ) {
                return (
                    <td key={rowKey}>
                        <FormCheck type="checkbox" className="inbox-check me-2">
                            <FormCheck.Input
                                id="message-1"
                                name={rowKey}
                                className="me-2"
                                checked={isChecked}
                                value={isChecked}
                                onChange={(e) => onCheckBoxChange(e, row, rowIndex)}
                            />
                            <FormCheck.Label>
                                {" "}
                                {isRowWithCheckBoxSubscribe
                                    ? getSubcribedLabel()
                                    : isChecked
                                        ? ""
                                        : ""}{" "}
                            </FormCheck.Label>
                        </FormCheck>
                    </td>
                );
            } else {
                return (
                    <td key={rowKey}>
                        <FormCheck type="checkbox" className="inbox-check me-2">
                            <FormCheck.Input
                                id="message-1"
                                name={rowKey}
                                className="me-2"
                                checked={isChecked}
                                value={isChecked}
                                onChange={(e) => onCheckBoxChangeDocument(e, row, rowIndex)}
                            />
                        </FormCheck>
                    </td>
                );
            }
        }
        else if (isRowPercentage) {
            return (
                <td key={rowKey}>
                    <FormCheck.Label>{`${dispalyTableRow(
                        row[rowKey]
                    )} %`}</FormCheck.Label>
                </td>
            );
        }
        else if (isRowWithReplaceData) {
            return <td key={rowKey}>{repplaceData(row[rowKey])}</td>;
        }
        else if (isRowDecimalNumber) {
            return (
                <td key={rowKey}>
                    <FormCheck.Label>
                        {" "}
                        {`${row[rowKey]
                            ? Math.round(row[rowKey] * 100) / 100
                            : dispalyTableRow(row[rowKey])
                            } ${rowKey === "Googleanalyticsdata.avgtimeonpage" ? "s" : ""}`}
                    </FormCheck.Label>
                </td>
            );
        }
        else if (isRowDecimalNumberPercentage) {
            return (
                <td key={rowKey}>
                    <FormCheck.Label>
                        {" "}
                        {`${row[rowKey]
                            ? Math.round(row[rowKey] * 100) / 100
                            : dispalyTableRow(row[rowKey])
                            } ${rowKey === "Googleanalyticsdata.bouncerate" ? "%" : ""}`}
                    </FormCheck.Label>
                </td>
            );
        }
        else if (isRowIntNumber) {
            return (
                <td key={rowKey}>
                    <FormCheck.Label>
                        {" "}
                        {row[rowKey]
                            ? parseInt(row[rowKey])
                            : dispalyTableRow(row[rowKey])}{" "}
                    </FormCheck.Label>
                </td>
            );
        }
        else if (isRowDate) {
            let dt = `${moment(row[rowKey]).format("DD MMM YYYY")}`;
            if (dt === "Invalid date Invalid date, Invalid date") {
                return (
                    <td key={rowKey}>
                        <FormCheck.Label> {null}</FormCheck.Label>
                    </td>
                );
            }
            return (
                <td key={rowKey}>
                    <FormCheck.Label> {dt}</FormCheck.Label>
                </td>
            );
        }
        else if (isRowMonth) {
            let dt = `${moment(row[rowKey]).format("MMM")}`;
            if (dt === "Invalid date Invalid date, Invalid date") {
                return (
                    <td key={rowKey}>
                        <FormCheck.Label> {null}</FormCheck.Label>
                    </td>
                );
            }
            return (
                <td key={rowKey}>
                    <FormCheck.Label> {dt}</FormCheck.Label>
                </td>
            );
        }
        else if (isRowWithTime) {
            let dt = `${moment(row[rowKey]).format("hh:mm:ss A")}`;
            if (dt === "Invalid date") {
                return (
                    <td key={rowKey}>
                        <FormCheck.Label> {null}</FormCheck.Label>
                    </td>
                );
            }
            return (
                <td key={rowKey}>
                    <FormCheck.Label> {dt}</FormCheck.Label>
                </td>
            );
        }
        else if (isRowDateTime) {
            let dt = row[rowKey] ? `${moment(row[rowKey]).format("MMM")} ${moment(
                row[rowKey]
            ).format("DD")}, ${moment(row[rowKey]).format("YYYY")} ${moment(
                row[rowKey]
            ).format("hh:mm A")}` : "-";
            if (dt === "Invalid date Invalid date, Invalid date Invalid date") {
                return (
                    <td key={rowKey}>
                        <FormCheck.Label> {null}</FormCheck.Label>
                    </td>
                );
            }

            return (
                <td key={rowKey}>
                    <FormCheck.Label> {dt}</FormCheck.Label>
                </td>
            );
        }
        else if (isRowTime) {
            let dt = `${moment(row[rowKey]).format("HH:mm:ss")}`;
            if (dt === "Invalid date") {
                return (
                    <td key={rowKey}>
                        <FormCheck.Label> {null}</FormCheck.Label>
                    </td>
                );
            }
            return (
                <td key={rowKey}>
                    <FormCheck.Label> {dt}</FormCheck.Label>
                </td>
            );
        }
        else if (isRowDateTimeWithGMT) {
            return (
                <td key={rowKey}>
                    <FormCheck.Label> {formateDate(row[rowKey], { month: "short", day: "numeric", year: "numeric", }, ' GMT')}</FormCheck.Label>
                </td>
            );
        }
        else if (isRowWithLargeData) {
            if (rowKey === "EventTable.Category") {
                let lgData = row[rowKey].split(" | ");
                return (
                    <td key={rowKey} className="word-break table-truncate-text">

                        <div className="table-row-subtitle w-100 parentCell truncate-text-container" title={dispalyTableRow(lgData[0])}>
                            {dispalyTableRow(lgData[0])}
                        </div>
                        {lgData.map((data, index) => {
                            if (index !== 0) {
                                return (
                                    <>
                                        <br />{" "}
                                        <div className="table-row-subtitle w-100 parentCell truncate-text-container" title={dispalyTableRow(data)}>
                                            {dispalyTableRow(data)}
                                        </div>
                                    </>
                                );
                            }
                        })}
                    </td>
                );
            }
            else if (rowKey === "companyDetailsVA" || rowKey === "Watchlist.cmp_names") {
                let lgData = row[rowKey].split(" | ");
                return (
                    <td key={rowKey} className="word-break table-truncate-text"  >
                        <Card.Link onClick={(e) => rowClickEvent({ data: row, type: "display" })} className="ms-0 me-2">
                            <div className="table-row-title w-100 parentCell truncate-text-container" title={dispalyTableRow(lgData[0])}>
                                {executeHTMLTags(dispalyTableRow(lgData[0]))}
                            </div>
                            {lgData.map((data, index) => {
                                if (index !== 0) {
                                    return (
                                        <>
                                            {" "}
                                            <div className="table-row-subtitle w-100 parentCell truncate-text-container" title={dispalyTableRow(data)}>
                                                {executeHTMLTags(dispalyTableRow(data))}
                                            </div>

                                        </>
                                    );
                                }
                            })
                            }
                        </Card.Link>
                    </td>
                );
            }
            else if (rowKey === "Watchlist.cmp_name") {
                let lgData = row[rowKey].split(" | ");

                if (row["isBlocked"] === false) {
                    return (
                        <td key={rowKey} className="word-break">
                            <Card.Link onClick={(e) => rowClickEvent({ data: row, type: "display" })} className="ms-0 me-2">
                                <span className="table-row-title table-truncate-text fmxw-400 truncate-text w-100 parentCell" title={dispalyTableRow(lgData[0])}>
                                    {executeHTMLTags(dispalyTableRow(lgData[0]))}
                                </span>
                                {lgData.map((data, index) => {
                                    if (index !== 0) {
                                        return (
                                            <>
                                                <br />{" "}
                                                <span className="table-row-subtitle table-truncate-text fmxw-400 truncate-text w-100 parentCell" title={dispalyTableRow(data)}>
                                                    {executeHTMLTags(dispalyTableRow(data))}
                                                </span>
                                            </>
                                        );
                                    }
                                })
                                }
                            </Card.Link>
                        </td>
                    );
                }
                else {
                    return (
                        <td key={rowKey} className="word-break is-disabled action-not-allowed">
                            <span className="table-row-title table-truncate-text fmxw-400 d-inline-block truncate-text w-100 parentCell" title={dispalyTableRow(lgData[0])}>
                                {executeHTMLTags(dispalyTableRow(lgData[0]))}
                            </span>
                            {lgData.map((data, index) => {
                                if (index !== 0) {
                                    return (
                                        <>
                                            <br />{" "}
                                            <span className="table-row-subtitle table-truncate-text fmxw-400 d-inline-block truncate-text w-100 parentCell" title={dispalyTableRow(data)}>
                                                {executeHTMLTags(dispalyTableRow(data))}
                                            </span>
                                        </>
                                    );
                                }
                            })
                            }
                            <br />
                            <span className="table-row-subtitle table-truncate-text fmxw-400 d-inline-block truncate-text w-100 parentCell mandatory">
                                Blocked Visitor
                            </span>
                        </td>
                    );
                }

            }
            else if (row[rowKey] != null && row[rowKey] !== undefined) {
                var lgData = rowKey == "ContentInsights.Page" ? row[rowKey].split(" || ") : row[rowKey].split(" | ");

                return (
                    <td key={rowKey} className="word-break table-truncate-text">

                        <div className="table-row-title w-100 parentCell truncate-text-container" title={dispalyTableRow(lgData[0])}>
                            {
                                rowKey == "messageSubject" ? dispalyTableRow(lgData[0]) : executeHTMLTags(dispalyTableRow(lgData[0]))
                            }
                        </div>
                        {lgData.map((data, index) => {
                            if (index !== 0) {
                                return (
                                    <>
                                        {" "}
                                        <div className="table-row-subtitle w-100 parentCell truncate-text-container" title={dispalyTableRow(data)}>
                                            {executeHTMLTags(dispalyTableRow(data))}
                                        </div>

                                    </>
                                );
                            }
                        })
                        }

                    </td>
                );
            }
            else {
                return <td key={rowKey}>-</td>;
            }
        }
        else if (isRowWithLargeDataToolTipLongText) {
            if (row[rowKey] != null && row[rowKey] !== undefined) {
                var lgData = row[rowKey].split("||");

                return (
                    <td key={rowKey} className="word-break table-truncate-text" onClick={(e) => rowClickEvent({ data: row, type: "display" })}>

                        <div className="table-row-title w-100 parentCell truncate-text-container" title={dispalyTableRow(lgData[0])}>
                            {dispalyTableRow(lgData[0])}
                        </div>
                        {lgData.map((data, index) => {
                            if (index !== 0) {
                                return (
                                    <>
                                        {" "}
                                        <div className="table-row-subtitle w-100 parentCell truncate-text-container" title={dispalyTableRow(data)}>
                                            {dispalyTableRow(data)}
                                        </div>
                                    </>
                                );
                            }
                        })}
                    </td>
                );
            }
            else {
                return <td key={rowKey}>-</td>;
            }
        }
        else if (isColumnHide) {
            return;
        }
        else if (
            shouldShowMultiLineRow &&
            Object.keys(shouldShowMultiLineRow).length > 0
        ) {
            return (
                <td key={rowKey}>
                    <div className="d-block">
                        <span className="fw-bold">
                            {" "}
                            {dispalyTableRow(row[shouldShowMultiLineRow.value[0]])}{" "}
                        </span>
                        <div className="small text-gray">
                            {" "}
                            {dispalyTableRow(row[shouldShowMultiLineRow.value[1]])}{" "}
                        </div>
                    </div>
                </td>
            );
        }
        else if (isRowWithdifferentdatabase) {
            if (rowKey === "Notifications.VisitorandCountry") {
                var data = row[rowKey];
                var obj = data.split("|");

                var a =
                    contextData.visitorData !== undefined
                        ? contextData.visitorData.find(
                            (x) =>
                                x.companyId === parseInt(obj[0]) &&
                                x.countryId === parseInt(obj[1])
                        )
                        : undefined;
                return a !== undefined ? (
                    <td key={rowKey} className="word-break table-truncate-text">
                        <span className="table-row-title truncate-text-container" title={a.companyName}>{a.companyName}</span>
                        <br />
                        <span className="table-row-subtitle truncate-text-container" title={a.countryId !== 0 ? a.countryName : "All"}>
                            {a.countryId !== 0 ? a.countryName : "All"}
                        </span>
                    </td>
                ) : (
                    <td key={rowKey} className="word-break"></td>
                );
            }
            else {
                return (
                    <td key={rowKey} className="word-break" title={dispalyTableRow(row[rowKey])}>
                        {dispalyTableRow(row[rowKey])}
                    </td>
                );
            }
        }
        else if (isRowWithMultipleAction) {
            if (rowKey === "DocumentTable.Actions" && row["DocumentTable.DocumentID"] === 0) {
                return (
                    <td className="mydocument-actions">
                        <Card.Link target={"_blank"} href={row["DocumentTable.DocumentPath"]} className="ms-0 me-2">
                            <FontAwesomeIcon icon={faExternalLinkAlt} title={t("header.Icon_Tooltip_NavigateToDoc")} />
                        </Card.Link>
                    </td>
                );
            }
            else if (rowKey === "pagesId") {
                return (<td key={rowKey} className="word-break">
                    <Card.Link onClick={(e) => rowClickEvent({ data: row, type: "display" })} className="ms-0 me-2">
                        <FontAwesomeIcon icon={faExternalLinkAlt} title={t("header.Icon_Tooltip_ViewVisitorActivity")} />
                    </Card.Link>
                </td>);
            }
            else if (rowKey === "Watchlist.pagesId") {
                if (row["isBlocked"] === true) {
                    return (<td key={rowKey} className="word-break is-disabled action-not-allowed">
                        <FontAwesomeIcon icon={faExternalLinkAlt} title={t("header.Icon_Tooltip_ViewVisitorActivity")} />
                    </td>);
                }
                else {
                    return (<td key={rowKey} className="word-break">
                        <Card.Link onClick={(e) => rowClickEvent({ data: row, type: "display" })} className="ms-0 me-2">
                            <FontAwesomeIcon icon={faExternalLinkAlt} title={t("header.Icon_Tooltip_ViewVisitorActivity")} />
                        </Card.Link>
                    </td>);
                }

            }
            else if (rowKey === "ManualAlert.Actions") {
                return (
                    <td>
                        <Card.Link title={t("header.Icon_Tooltip_Edit&Preview")} onClick={(e) => { handleEditCancel(e, row, rowKey, OPERATION_TYPE.EDIT) }} className={"me-2"}>
                            <FontAwesomeIcon icon={faEye} />
                        </Card.Link>
                        <Card.Link title={t("header.Icon_Tooltip_Send")} onClick={(e) => { handleEditCancel(e, row, rowKey, OPERATION_TYPE.SEND) }} className={"me-2 ms-0"}>
                            <FontAwesomeIcon icon={faPaperPlane} />
                        </Card.Link>
                        <Card.Link title={t("header.Icon_Tooltip_Cancel")} onClick={(e) => { handleEditCancel(e, row, rowKey, OPERATION_TYPE.CANCEL) }} className={"me-0 ms-0"}>
                            <FontAwesomeIcon icon={faTimesCircle} />
                        </Card.Link>
                    </td>
                );
            }
            else if (rowKey === "AlertysTable.Action") {
                return (
                    <td>
                        <Card.Link title={t("header.Icon_Tooltip_ViewDeliveredEmailAlert")} onClick={(e) => { handleEditCancel(e, row, rowKey, "viewDeliveredEmailAlert", alertMessageDetails?.find(x => x.MessageId === row["AlertysTable.Action"]) ?? null) }} className={"me-2"}>
                            <FontAwesomeIcon icon={faEnvelope} />
                        </Card.Link>
                        <Card.Link title={t("header.Icon_Tooltip_ViewAlertStatistics")} onClick={(e) => { handleEditCancel(e, row, rowKey, "viewAlertStatistics", alertMessageDetails?.find(x => x.MessageId === row["AlertysTable.Action"]) ?? null) }} className={"me-2 ms-0"}>
                            <FontAwesomeIcon icon={faPaperPlane} />
                        </Card.Link>
                    </td>
                );
            }
            else if (rowKey === "EventTable.Actions") {
                return (
                    <td>
                        <Card.Link title="Edit" onClick={(e) => onEditEvent(e, row, rowIndex, "Edit")} className={"me-3"}>
                            <FontAwesomeIcon icon={faEdit} />
                        </Card.Link>
                        <OverlayTrigger
                            placement="top"
                            trigger={["hover", "focus"]}
                            overlay={
                                <Tooltip>
                                    <div>{row && row["EventTable.Attachments"] && row["EventTable.Attachments"].length ? row && row["EventTable.Attachments"] && row["EventTable.Attachments"].length + " Attachments" : "No Attachments"}</div>
                                </Tooltip>
                            }
                        >
                            <Card.Link onClick={(e) => onEditEvent(e, row, rowIndex, "Attachments")} className={"ms-0"}>
                                <FontAwesomeIcon icon={faPaperclip} />
                            </Card.Link>
                        </OverlayTrigger>

                    </td>
                );
            }
            else {
                return (
                    <td className="mydocument-actions">
                        <Card.Link onClick={(e) => onDocumentDownload(e, row, rowIndex)} className={row["DocumentTable.IsDocumentOwner"] ? "ms-0 me-2" : "ms-0 me-2 is-disabled"}>
                            {" "}
                            <FontAwesomeIcon icon={faDownload} title={t("header.Icon_Tooltip_DownloadDoc")} />
                        </Card.Link>
                        <Card.Link onClick={(e) => onDocumentEdit(e, row, rowIndex)} className={row["DocumentTable.IsDocumentOwner"] ? "ms-0 me-2" : "ms-0 me-2 is-disabled"}>
                            <FontAwesomeIcon icon={faEdit} title={t("header.Icon_Tooltip_Edit")} />
                        </Card.Link>
                        <Card.Link onClick={(e) => onDeleteDocument(e, row, rowIndex)} className={row["DocumentTable.IsDocumentOwner"] ? "ms-0 " : "ms-0 is-disabled"}>
                            <FontAwesomeIcon icon={faTrash} title={t("header.Icon_Tooltip_Delete")} />
                        </Card.Link>
                    </td>
                );
            }

        }
        else if (isRowWithLink) {
            if (rowKey === "notifications.Edit" || rowKey === "Watchlist.active") {
                return (
                    <td key={rowKey}>
                        <Card.Link
                            onClick={(e) => rowClickEvent({ data: row, type: "editnotification" })}
                            className={localStorage.getItem("isDemoClient") ? "d-flex align-items-center custom-disable" : "d-flex align-items-center"}
                        >
                            <i className="fas fa-edit" title={t("header.WONotificationsEditTT")}></i>
                        </Card.Link>
                    </td>
                );
            }
            else if (rowKey === "notifications.History") {
                return (
                    <td key={rowKey}>
                        <Card.Link
                            onClick={(e) => rowClickEvent({ data: row, type: "history" })}
                            className={localStorage.getItem("isDemoClient") ? "d-flex align-items-center custom-disable" : "d-flex align-items-center"}
                        >
                            <i className="fa fa-eye" aria-hidden="true" title={t("header.WONotificationsViewTT")}></i>
                        </Card.Link>
                    </td>
                );
            }
            else if (rowKey === "Miworkorderreport.workorderreportname") {
                return (
                    <td key={rowKey}>
                        <Card.Link
                            onClick={(e) =>
                                handleModalWororderReportButtonClick(e, this, row)
                            }
                            className="d-flex align-items-center"
                        >
                            {row["Miworkorderreport.workorderreportname"]}
                        </Card.Link>
                    </td>
                );
            }
            else if (rowKey === "Watchlist.delete") {
                return (
                    <td key={rowKey}>
                        <Card.Link
                            onClick={(e) => handleDelteWatchlistButtonClick(e, row, rowIndex)}
                            className="d-flex align-items-center"
                        >
                            Delete
                        </Card.Link>
                    </td>
                );
            }
            else if (rowKey === "AlertysTable.Actions") {
                let data = row[rowKey].split("|");
                let concentEmail = false;

                let email = data[0];
                let clientId = parseInt(data[1]);
                let subscriberId = parseInt(data[2]);
                if (data[3]) {
                    concentEmail = (data[3] == "True" ? true : false);
                }
                let language = row["AlertysTable.Language"];

                return (
                    <td key={rowKey}>

                        <Card.Link
                            onClick={(e) => !concentEmail ? '' : editSubscriber(e, email, clientId, subscriberId, language)}
                            className={!concentEmail ? 'align-items-center btn-link disabled' : 'align-items-center'}
                        >
                            <FontAwesomeIcon icon={faEdit} title={t("header.Icon_Tooltip_EditSubscriber")} />
                        </Card.Link>

                        <Card.Link
                            onClick={(e) => !concentEmail ? '' : deleteSubscriber(e, email, clientId)}
                            className={!concentEmail ? 'align-items-center btn-link disabled' : 'align-items-center'}
                        >
                            <FontAwesomeIcon icon={faTrash} title={t("header.Icon_Tooltip_DeleteSubscriber")} />
                        </Card.Link>
                    </td>
                );
            }
            else if (rowKey === "ReportHistory.ReportName") {
                return (
                    <td key={rowKey} className="word-break table-truncate-text">
                        <Card.Link onClick={(e) => onSCReportItemClick(e, row, rowIndex)} className="ms-0 me-2">
                            <div className="table-row-title w-100 parentCell truncate-text-container" title=
                                {dispalyTableRow(row[rowKey])}>
                                {executeHTMLTags(dispalyTableRow(row[rowKey]))}
                            </div>
                        </Card.Link>
                    </td>
                );
            }
            else if (rowKey === "Subscribers.Delete") {
                return (
                    <td key={rowKey}>

                    </td>
                );
            }
            else if (rowKey === "Subscribers.Edit") {
                let data = row[rowKey].split("|");
                let email = data[0];
                let clientId = parseInt(data[1]);
                let subscriberId = parseInt(data[2]);
                let concentEmail = parseInt(data[3]);
                return (
                    <td key={rowKey}>

                        <Card.Link
                            onClick={(e) => concentEmail ? '' : editSubscriber(e, email, clientId, subscriberId)}
                            className={concentEmail ? 'd-flex align-items-center btn-link disabled' : 'd-flex align-items-center'}
                        >
                            Edit
                        </Card.Link>
                    </td>
                );
            }
            else if (rowKey === "Subscribers.Delete") {
                let data = row[rowKey].split("|");
                let email = data[0];
                let clientId = parseInt(data[1]);
                let concentEmail = parseInt(data[3]);
                return (
                    <td key={rowKey}>
                        <Card.Link
                            onClick={(e) => concentEmail ? '' : deleteSubscriber(e, email, clientId)}
                            className={concentEmail ? 'd-flex align-items-center btn-link disabled' : 'd-flex align-items-center'}
                        >
                            Delete
                        </Card.Link>
                    </td>
                );
            }
        }
        else if (rowKey === "messageId") {
            return (
                <td key={rowKey}>
                    <Card.Link target={"_blank"} href={row["DocumentTable.DocumentPath"]} className={row["deliveredCount"] !== 0 ? "icon-nav-box" : "icon-nav-box is-disabled"} onClick={() => (row["deliveredCount"] !== 0 ? ExportArchiverAlertSubscribersUsingEPPlus("archived_alerts", row[rowKey]) : "")}>
                        <FontAwesomeIcon icon={faDownload} title={t("header.Icon_Tooltip_DownloadExcel")} />
                    </Card.Link>
                </td>
            );
        }
        else if (rowKey === "messageId") {
            return (
                <td key={rowKey}>
                    <Card.Link target={"_blank"} href={row["DocumentTable.DocumentPath"]} className="icon-nav-box" onClick={() => (row["deliveredCount"] !== 0 ? ExportArchiverAlertSubscribersUsingEPPlus("all", row) : "")}>
                        <FontAwesomeIcon icon={faDownload} title={t("header.Icon_Tooltip_DownloadExcel")} />
                    </Card.Link>

                </td>
            );
        }
        else {
            if (rowKey === "Alertsummary.Month") {
                return <td key={rowKey}>{monthName(row[rowKey])}</td>;
            }
            else if (rowKey == "EventTable.StartDate") {
                return <td>{!row["EventTable.TBCEvent"] ? moment(row["EventTable.StartDate"]).format("MMM DD, YYYY hh:mm A") + " UTC " + row["EventTable.TimeZoneOffSet"] : "TBC"}</td>
            }
            else if (rowKey == "EventTable.EndDate") {
                return <td>{!row["EventTable.TBCEvent"] ? moment(row["EventTable.EndDate"]).format("MMM DD, YYYY hh:mm A") + " UTC " + row["EventTable.TimeZoneOffSet"] : "TBC"}</td>
            }
            else if (rowKey == "EventTable.Status") {
                return <td key={rowKey}><span className={row[rowKey] == "Active" ? "status-mark positive" : "status-mark negative"} title={row[rowKey]}></span></td>
            }
            else if (rowKey == "DocumentTable.DocumentID") {
                return <td key={rowKey}>
                    <FormCheck type="checkbox" className="inbox-check me-2">
                        <FormCheck.Input
                            id="message-1"
                            name={rowKey}
                            disabled={row["DocumentTable.IsDocumentOwner"] ? false : true}
                            className="me-2"
                            checked={checkedDocument.includes(row["DocumentTable.DocumentID"]) ? true : false}
                            value={row["DocumentTable.DocumentID"]}
                            onChange={(e) => onCheckChangeDocument(e, row, rowIndex)}
                        />
                    </FormCheck>
                </td>
            }
            else if (rowKey === "Archivealerts2.Delivered") {
                return <td key={rowKey}>{calculateCommaSepValues(row[rowKey])}</td>;
            } else if (rowKey === "Archivealerts2.Undelivered") {
                return <td key={rowKey}>{calculateCommaSepValues(row[rowKey])}</td>;
            } else if (rowKey === "ViewereventsPlayerevents.duration") {
                return <td key={rowKey}>{msToHMS(row[rowKey])}</td>;
            } else if (rowKey === "NotificationsHistory.Durationdescription") {
                var data = row[rowKey].split("|");
                if (data[0] === "Custom") {
                    var fromdt = `${moment(data[1]).format("MMM")} ${moment(
                        data[1]
                    ).format("DD")},${moment(data[1]).format("YYYY")}`;
                    var todt = `${moment(data[2]).format("MMM")} ${moment(data[2]).format(
                        "DD"
                    )},${moment(data[1]).format("YYYY")}`;
                    return (
                        <td key={rowKey}>
                            {fromdt}-{todt}
                        </td>
                    );
                } else {
                    return <td key={rowKey}>{data[0]}</td>;
                }
            }
            else if (rowKey === "AIcount") {

                if (contextData.selectedDatePicker === 1) {
                    var data = row[rowKey].split("|")
                    return (<td key={rowKey}><span>{data[0]}</span></td>);
                }
                else {
                    var data = row[rowKey].split("|")
                    if (data[1].startsWith("-")) { return (<td key={rowKey}><span>{data[0]}</span>  <span style={{ color: "red" }}>↓{data[1].replace("-", "")}%</span></td>) }
                    else if (data[1] !== "") {
                        return (<td key={rowKey}><span>{data[0]}</span> {data[1] !== "0" ?
                            <span style={{ color: "green" }}>↑{data[1]}%</span> : <span style={{ color: "gray" }}>{data[1]}%</span>}
                        </td>);
                    }
                    else {
                        return (<td key={rowKey}><span>{data[0]}</span></td>);
                    }
                }
            }
            else if (rowKey === "NotificationsHistory.PageViews") {
                if (row[rowKey] != null) {
                    return <td key={rowKey}>{dispalyTableRow(row[rowKey]) + "+"}</td>;
                } else {
                    return <td key={rowKey}>-</td>;
                }
            }
            else if (rowKey === "Miworkorderreport.reportperiod") {
                return (
                    <td key={rowKey}>
                        {moment(row["Miworkorderreport.reportstartdate"]).format("MMM")}{" "}
                        {moment(row["Miworkorderreport.reportstartdate"]).format("DD")},{" "}
                        {moment(row["Miworkorderreport.reportstartdate"]).format("YYYY") +
                            " - " +
                            moment(row["Miworkorderreport.reportenddate"]).format("MMM")}{" "}
                        {moment(row["Miworkorderreport.reportenddate"]).format("DD")},{" "}
                        {moment(row["Miworkorderreport.reportenddate"]).format("YYYY")}
                    </td>
                );
            }
            else if (rowKey === "Miworkorderreportdetail.workorderadjustedtime") {
                return (
                    <td key={rowKey} className="word-break" title={row[rowKey].toFixed(2)}>
                        {row[rowKey].toFixed(2)}
                    </td>
                );
            }
            else if (
                rowKey === "PresentationPollsQaDetails.answerData" ||
                rowKey === "PresentationsPollsQA.answerData"
            ) {
                let total = 0;
                row[rowKey].forEach((element) => {
                    if (element.count !== undefined && element.count !== null) {
                        total += parseInt(element.count);
                    }
                });
                total = Math.round(total);
                row[rowKey].sort((a, b) => parseInt(b.count) - parseInt(a.count));
                return (
                    <td className="poll-answer">
                        {row[rowKey].map(function (x) {
                            return renderProgressBar(x, total);
                        })}
                    </td>
                );
            }
            else if (
                rowKey === "PresentationPollsQaDetails.question" ||
                rowKey === "PresentationsPollsQA.question"
            ) {
                return (
                    <td key={rowKey} className="poll-question">
                        <p className="mb-1 question-count">Question {rowIndex + 1}</p>
                        {dispalyTableRow(row[rowKey])}
                    </td>
                );
            }
            if (rowKey === "QuestionApprovedFor") {
                let tempStatus = quesStatus.find(x => x.statusName === "Pending");
                //if (parseInt(row[rowKey]) === 1) {
                if (row["QuestionApprovedFor"] === "presenter") {
                    tempStatus = quesStatus.find(x => x.statusName === "Approved for Presenter");
                } else if (row["QuestionApprovedFor"] === "none") {
                    tempStatus = quesStatus.find(x => x.statusName === "Pending");
                }
                else {
                    tempStatus = quesStatus.find(x => x.statusName === "Approved for All");
                }
                //}
                return (
                    <td>
                        <DropdownWithIconsComponent
                            dropdownData={quesStatus}
                            arrayValue="statusName"
                            onChange={(e) => onQuestionStatusDropdownChange(e, row, rowIndex)}
                            showLabel={false}
                            showIconInDropdownItem={false}
                            selectedDropdown={tempStatus}
                            isPrimaryImage={false}
                            customClassName="text-overflow"
                        /></td>
                );
            }
            else if (rowKey === "AItitle") {
                const tooltipForfieldText = [{ "key": "Direct", "tooltip": "header.InsightsTrafficSourceDirectHelpTooltipText" },
                { "key": "Organic", "tooltip": "header.InsightsTrafficSourceOrganicHelpTooltipText" },
                { "key": "Social", "tooltip": "header.InsightsTrafficSourceSocialHelpTooltipText" },
                { "key": "Referral", "tooltip": "header.InsightsTrafficSourceReferralHelpTooltipText" },
                { "key": "Email", "tooltip": "header.InsightsTrafficSourceEmailHelpTooltipText" },
                { "key": "Paid Search", "tooltip": "header.InsightsTrafficSourcePaidSearchHelpTooltipText" }]
                return (
                    <td key={rowKey} className="word-break">
                        {dispalyTableRow(row[rowKey])} {tooltipForfieldText.map((element) => dispalyTableRow(row[rowKey]) === element.key ? createTooltipforcolumn(element.tooltip) : '')}
                    </td>
                );
            }
            else if (rowKey === "pagesAvgTimeOnPage") {
                return <td key={rowKey} onClick={(e) => rowClickEvent({ data: row, type: "display" })}>{row[rowKey]} s</td>;
            }
            else if (rowKey === "pagesPageViews") {
                return <td key={rowKey} onClick={(e) => rowClickEvent({ data: row, type: "display" })}>{row[rowKey]}</td>;
            }
            else if (rowKey === "pagesCategory") {
                return <td key={rowKey} className="hide-mobile table-truncate-text" title={row[rowKey]} onClick={(e) => rowClickEvent({ data: row, type: "display" })}><div className="truncate-text-container">{row[rowKey]}</div></td>;
            }
            else if (rowKey === "PageViewCount") {
                return <td key={rowKey}>{row[rowKey]}+</td>;
            }
            else if (rowKey === "IsRuleActive") {
                return <td key={rowKey}>{row[rowKey] === 1 ? <FontAwesomeIcon icon={faCircle} style={{ color: "#027A48", }} /> : <FontAwesomeIcon icon={faCircle} style={{ color: "#B42318" }}></FontAwesomeIcon>} </td>;
            }
            else if (rowKey === "IsMailSent") {
                return <td key={rowKey}>
                    {(row["IsRuleActive"] == 0 && (row["IsMailSent"] == 1 || row["IsMailSent"] == 2)) && <i className="fa fa-check-circle positive" aria-hidden="true"></i>}
                    {!(row["IsRuleActive"] == 0 && (row["IsMailSent"] == 1 || row["IsMailSent"] == 2)) && <span title="No">-</span>}
                </td>
            }
            else if (rowKey === "notifications.Delete") {
                return <td key={rowKey}><Card.Link
                    className={localStorage.getItem("isDemoClient") ? "d-flex align-items-center custom-disable" : "d-flex align-items-center"}
                    onClick={(e) => deleteNotificationPopup(row)}
                >
                    <i className="fa fa-trash" aria-hidden="true" title={t("header.notificationsDeleteTT")}></i>
                </Card.Link></td>
            }
            else if (rowKey === "categoryName" || rowKey === "pageViews") {
                return <td className="word-break table-truncate-text" key={rowKey}>
                    <Card.Link onClick={(e) => rowClickEvent({ data: row, type: "display" })}
                    >
                        <div className="table-row-title w-100 parentCell truncate-text-container" title={dispalyTableRow((row[rowKey]))}>
                            {dispalyTableRow((row[rowKey]))}
                        </div>
                    </Card.Link>
                </td>
            }
            else if (rowKey === "Watchlist.cat_name" || rowKey === "Watchlist.pgviews") {
                if (row["isBlocked"] === true) {
                    return <td className="word-break is-disabled action-not-allowed table-truncate-text" key={rowKey}>
                        {/* <Card.Link> */}
                        <span className="table-row-title table-truncate-text fmxw-400 d-inline-block truncate-text w-100 parentCell" title={dispalyTableRow((row[rowKey]))}>
                            {dispalyTableRow((row[rowKey]))}
                        </span>
                        {/* </Card.Link> */}
                    </td>
                }
                else {
                    return <td className="word-break table-truncate-text" key={rowKey}>
                        <Card.Link onClick={(e) => rowClickEvent({ data: row, type: "display" })} >
                            <span className="table-row-title table-truncate-text fmxw-400 d-inline-block truncate-text w-100 parentCell" title={dispalyTableRow((row[rowKey]))}>
                                {dispalyTableRow((row[rowKey]))}
                            </span>
                        </Card.Link>
                    </td>
                }
            }
            else if (rowKey === "ContentInsights.AverageTimeOnPage") {
                return <td className="word-break" key={rowKey}>
                    <span className="table-row-title table-truncate-text fmxw-400 d-inline-block truncate-text w-100 parentCell" title={dispalyTableRow((row[rowKey]))}>
                        {dispalyTableRow((row[rowKey]))} s
                    </span>
                </td>
            } else if (rowKey === "ManageUserActions") {
                const label = row.eventAccessStatus === "approved" ? "Disable" : "Enable";
                const events = JSON.parse(localStorage.getItem("webcastEventId"))
                return (
                    <td key={rowKey} className={localStorage.getItem("isDemoClient") ? "custom-disable" : ""}>
                        <button className={label === "Disable" ? "btn btn-danger border-0 table-btn btn-sm" : "btn btn-primary border-0 table-btn  btn-sm"} onClick={() => changeEventAccessStatus(label, events["Events.id"], row.registrationId, row, rowIndex)} >
                            {label}
                        </button>
                    </td>
                );
            } else if (rowKey === "ReportHistory.Download") {
                return (
                    <td key={rowKey}>
                        <Col xs={1} className="text-end icon-nav-list svg-icon-nav-list">
                            <div className="d-inline-block download-excel">
                                {/* pdf here */}
                                <span className="icon-nav-box" onClick={() => { GenerateDigestPdf(row["ReportHistory.ReportId"], row["ReportHistory.ReportName"]) }}>
                                    <svg className="icon-nav">
                                        <title>{t("header.Icon_Tooltiop_DownloadReport")}</title>
                                        <use href={iconSprite + `#Download`} />
                                    </svg>
                                </span>
                            </div>
                        </Col>
                    </td>
                );
            }
            else if (rowKey === "AlertysTable.Alerts") {
                return (
                    <td className="word-break table-truncate-text" title={row["AlertysTable.Alerts"]}>
                        <Card.Link onClick={(e) => handleEditCancel(e, row, rowKey, "viewAlertStatistics", alertMessageDetails?.find(x => x.MessageId === row["AlertysTable.Action"]) ?? null)} className={"me-2 ms-0 text-link"}>
                            <div className="d-sm-inline-block truncate-text-container">
                                {row["AlertysTable.Alerts"]}
                            </div>
                        </Card.Link>
                    </td>
                )
            }
            else if (rowKey === "SubscriberLinksClicked") {
                return (
                    <td key={rowKey} >
                        <OverlayTrigger
                            placement="left"
                            show={showViewLinks.id === row.Email && showViewLinks.isOpen}
                            overlay={
                                <Popover className="alert-subscriber-popover">
                                    <Popover.Content>
                                        <ul className="list-unstyled auto-dropdown-menu">
                                            {row.URL.map((item) =>
                                                <li key={item} className="auto-dropdown-menu-item mt-1 mb-1 link-primary" >
                                                    <a href={item} rel="noopener" target="_blank">{item}</a>
                                                </li>
                                            )
                                            }
                                        </ul>
                                    </Popover.Content>
                                </Popover>
                            }>
                            <Button id={"subsLinkButton_" + rowIndex} onClick={(e) => handleViewLinkPopUp(e, row.Email, row.Email === showViewLinks.id ? !showViewLinks.isOpen : true)} className={`btn btn-primary border-0 table-btn btn-sm ${row.TotalClickCount > 0 ? '' : 'disabled'}`}>
                                {t("header.AlertSubscribersViewLinks")}
                            </Button>
                        </OverlayTrigger>
                    </td>
                );
            }
            else if (rowKey == "metadata") {
                return (
                    <td key={rowKey} className="word-break table-truncate-text">
                        <OverlayTrigger
                            placement={"right"}
                            trigger={["hover", "focus"]}
                            overlay={

                                <Tooltip className="manage-user-additional-info">
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: row[rowKey]
                                        }}
                                    ></div>
                                </Tooltip>
                            }
                        >
                            <Button variant="white" className="btn-help border-0 p-0 text-left manage-user-additional-info-label">
                                {/* <i class="far fa-question-circle lg"></i> */}
                                {executeHTMLTags(row[rowKey])}
                            </Button>
                        </OverlayTrigger>
                    </td>
                );
            }
            else {
                const tooltipForfieldText = [{ "key": "Live Webcast Insight Summary", "tooltip": "header.digestSubscriptionsLiverwebcastinsightsummary" },
                { "key": "Quarterly Webcast Insight Summary", "tooltip": "header.digestSubscriptionsQuarterlywebcastinsightsummary" }]
                return (
                    <td key={rowKey} className="word-break table-truncate-text" title={dispalyTableRow(row[rowKey])}>
                        {dispalyTableRow(executeHTMLTags(row[rowKey]))} {tooltipForfieldText.map((element) => dispalyTableRow(row[rowKey]) === element.key ? createTooltip(element.tooltip) : '')}
                    </td>
                );
            }
        }
    };

    const executeHTMLTags = (value) => {
        return (<>
            <div
                className="d-sm-inline-block truncate-text-container"
                dangerouslySetInnerHTML={{
                    __html: value,
                }}
            ></div>
        </>)
    }

    const createTooltipforcolumn = (tooltipKey) => {

        if (tooltipKey !== "") {
            return (
                <OverlayTrigger
                    placement="right"
                    trigger={["hover", "focus"]}
                    overlay={
                        <Tooltip>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: t(tooltipKey, {
                                        interpolation: { escapeValue: false },
                                    }),
                                }}
                            ></div>
                        </Tooltip>
                    }
                >
                    <Button onMouseMove={(e) => e.clientX > (window.screen.width - 300) ? setTooltipPosition("left") : setTooltipPosition("right")} variant="red" className="btn-help border-0 p-1">
                        <i className="far fa-question-circle lg"></i>
                    </Button>
                </OverlayTrigger>
            );
        }
    }

    const createTooltip = (tooltipKey) => {
        if (tooltipKey !== "") {
            return (
                <OverlayTrigger
                    placement="right"
                    trigger={["hover", "focus"]}
                    overlay={
                        <Tooltip>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: t(tooltipKey, {
                                        interpolation: { escapeValue: false },
                                    }),
                                }}
                            ></div>
                        </Tooltip>
                    }
                >
                    <Button onMouseMove={(e) => e.clientX > (window.screen.width - 300) ? setTooltipPosition("left") : setTooltipPosition("right")} variant="" className="btn-help border-0 p-0 px-1">
                        <i className="far fa-question-circle lg"></i>
                    </Button>
                </OverlayTrigger>
            );
        }
    }

    const getSubcribedLabel = () => {
        return t("header.DigestSubscriptionSubscribed");
    }

    const renderOptionalSummary = (headerData) => {
        let optionalData = [];
        headerData.forEach((dta) =>
            optionalSummary.forEach((opDta) =>
                opDta.keys.forEach((keyData) => {
                    if (dta.key === keyData) {
                        const isSummaryExists = optionalData.some((x) => x.id === opDta.id);
                        if (!isSummaryExists) optionalData.push(opDta);
                    }
                })
            )
        );
        if (Array.isArray(optionalData) && optionalData.length > 0) {
            return optionalData.map((dta) => {
                return (
                    <tr>
                        <td className="fw-bold">{dta.title}</td>
                        {
                            headerData.map((hdrVlue, index) => {
                                const summaryDta = dta.values.find(
                                    (x) => x.key === hdrVlue.key
                                );
                                if (summaryDta && Object.keys(summaryDta).length > 0) {
                                    var msgTotal = tableBodyData.reduce(function (prev, cur) {
                                        return prev + parseInt(cur[hdrVlue.key]);
                                    }, 0);
                                    return <td className="fw-bold">{msgTotal} </td>;
                                } else if (index !== 0) return <td></td>;
                            })
                            // })
                        }
                    </tr>
                );
            });
        }
    };

    const formateDate = (dateValue, format, appendString) => {
        let time = new Date(dateValue)
        return new Date(dateValue).toLocaleDateString('en-us', format) + " " + time.getHours() + ":" + time.getMinutes() + ":" + time.getSeconds() + "" + appendString
    }

    const onTextChanged = (e) => {
        const { value, name } = e.target;
        const showAutoFill = value.length > 2 ? true : false;
        const forms = { ...formFields };
        forms[name] = value;
        //Noter: For clearing the country on text change
        forms.notificationName = "";
        forms.country = {};
        setFormFields && setFormFields(forms);
        validateFormData && validateFormData(name, value);
        setSelectedCompany({});
        setAutoFillForCompany(showAutoFill);
        if (value.length === 0) {
            onSearchBoxChange && onSearchBoxChange(value);
            setSearchText("");
        }
    };

    const ExportArchiverAlertSubscribersUsingEPPlus = async (type, MessageId) => {
        setBlurLoader(true)
        const selectedWebsite = localStorage.getItem("alertWebsiteId") !== null ? JSON.parse(localStorage.getItem("alertWebsiteId")) : {}
        let config = {
            headers: {
                'Authorization': `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`,
                "content-type": "application/json"
            },
            params: {
                encodeStr: convertBase64(JSON.stringify({
                    WebsiteId: selectedWebsite["WebsiteList.WebsiteId"] !== undefined ? selectedWebsite["WebsiteList.WebsiteId"] : 0,
                    MessageId: MessageId,
                    companyid: contextData.companyId,
                    culture: localStorage.getItem('i18nextLng') ? localStorage.getItem('i18nextLng') : 'en-US'
                }))
            }
        }

        const tempVar = await axios.get(
            `${process.env.REACT_APP_API_KEY}/Intelligence/ExportArchiverAlertSubscribersUsingEPPlus`,
            config
        );
        if (tempVar !== undefined && tempVar !== null) {
            setBlurLoader(false)
            let fetchDataModified = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${tempVar.data}`;
            let a = document.createElement("a");
            a.href = fetchDataModified;
            a.download = 'ArchivedAlert_Subscribers_' + (new Date()).getTime() + '.xlsx';
            a.click();
        }
    };

    const DownloadPageExcelFileWithEPPLus = async () => {
        let type = "All Pages";
        setShowLoader(true);
        const tempVar = await axios.get(
            `${process.env.REACT_APP_API_KEY}/Intelligence/DownloadPageExcelFileWithEPPLusAnalytics`,
            {
                headers: {
                    'Authorization': `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`,
                    "content-type": "application/json"
                },
                params: {
                    type: type,
                    websitename: contextData.selectedWebsite["WebsiteList.WebsiteName"],
                    sectionid: 0,
                    sectionname: "Website",
                    startdate: moment(contextData.startDate).format("MM/DD/YYYY"),
                    enddate: moment(contextData.endDate).format("MM/DD/YYYY"),
                    profileid: contextData.selectedProfile["WebsiteProfiles.ProfileId"],
                    companyid: contextData.companyId,
                    pagetype: 1,
                    client: JSON.parse(decryptData(localStorage.getItem("selectedCompanyData"), contextData.cryptoKey))["companyName"]
                },
            }
        );
        if (tempVar !== undefined && tempVar !== null) {
            window.open(
                process.env.REACT_APP_API_KEY +
                "/Website/DownloadFile/?docName=" +
                tempVar.data.fileName +
                "&docPath=" +
                tempVar.data.filePath
            );
            setShowLoader(false);
        }
    };

    const renderProgressBar = (dta, total) => {
        let percent = 0;
        if (
            total !== 0 &&
            total !== undefined &&
            total !== null &&
            dta.count !== undefined &&
            dta.count !== null
        ) {
            percent = ((dta.count * 100) / total).toFixed(2);
        }
        return (
            <Row className="mb-3 progress-answer-wrapper">
                <Progress label={dta.ans} value={percent} />
            </Row>
        );
    };

    return (
        <>
            <Row className="search-filter-box">
                {showSearchTextBox && title === undefined &&
                    <Col xs={11}>
                        <div className="table-search-box pe-1">
                            <InputGroup>
                                <InputGroup.Text>
                                    <FontAwesomeIcon icon={faSearch} />
                                </InputGroup.Text>
                                <Form.Control
                                    type="text"
                                    className="form-control input-search"
                                    name="companyName"
                                    placeholder={t("header.visitorAllWatermark")}
                                    value={formFields.companyName}
                                    onChange={(e) => onTextChanged(e)}
                                    autoComplete="off"
                                />
                            </InputGroup>
                        </div>
                    </Col>
                }
                {showSearchTextBox && title === "Pages" &&
                    <Col xs={11}>
                        <Search
                            updateFormFields={updateFormFields}
                            searchWaterMark={searchWaterMark}
                            searchCriteria={searchCriteria}
                            onSearchBoxChange={onSearchBoxChange}
                            setSearchText={setSearchText}
                            formError={formError}
                            setFormError={setFormFields}
                            formFields={visitorFormFields}
                            setFormFields={setVisitorFormFields}
                            isAutoComplete={true}
                            showAutoFillForCompany={showAutoFillForCompany}
                            setAutoFillForCompany={setAutoFillForCompany}
                            setSelectedCompany={setSelectedCompany}
                            queryValue={queryValue}
                            pivotConfigValue={pivotConfigValuePages}
                            type={"server"}
                        ></Search>
                    </Col>
                }
                {downloadExcel && <Col xs={1} className="text-end icon-nav-list svg-icon-nav-list">
                    <div className="d-inline-block download-excel">
                        <span className="icon-nav-box" onClick={() => DownloadPageExcelFileWithEPPLus()}>
                            <svg className="icon-nav">
                                <use href={iconSprite + `#Download`} />
                            </svg>
                        </span>
                    </div>
                </Col>
                }
            </Row>
            <Row>
                <Col className={tableData.length > 0 ? "" : "fmxh-200 position-relative service-center-table-modal"}>
                    {showHistoryModal && (
                        <NotificationHistoryModal
                            showHistoryModal={showHistoryModal}
                            setShowHistoryModal={setShowHistoryModal}
                            historyData={historyData}
                            setHistoryData={setHistoryData}
                        ></NotificationHistoryModal>
                    )}
                    {showModal && (
                        <NotificationModal
                            isRefresh={isRefresh}
                            setIsRefresh={setIsRefresh}
                            isEdit={true}
                            showModal={showModal}
                            setShowModal={setShowModal}
                            formFields={formFields}
                            setFormFields={setFormFields}
                            formError={formError}
                            setFormError={setFormError}
                            isFormSubmit={isFormSubmit}
                            validateFormData={validateFormData}
                            showError={showError}
                            setShowError={setShowError}
                            selectedCompany={selectedCompany}
                            setSelectedCompany={setSelectedCompany}
                            showAutoFillForCompany={showAutoFillForCompany}
                            setAutoFillForCompany={setAutoFillForCompany}
                            handleButtonClick={handleSubmitButtonClick}
                            setIsFormSubmit={setIsFormSubmit}
                            isFormSubmittedSucessfully={isFormSubmittedSucessfully}
                            setIsFormSubmittedSucessfully={setIsFormSubmittedSucessfully}
                            aiRuleId={aiRuleId}
                            historyData={historyData}
                            setHistoryData={setHistoryData}
                            selectedCompanyid={selectedCompanyid}
                            selectedCountryid={selectedCountryid}
                            updateWatchlistNotificationToggle={updateWatchlistNotificationToggle}
                        />
                    )}
                    {showQuestionModal && (
                        <QuestionModal
                            onSuccessModelClose={onSuccessModelClose}
                            showModal={showQuestionModal}
                            onCancelClose={onCancelClose}
                            setShowModal={setShowQuestionModal}
                            isSuccess={isSuccess}
                            setIsSuccess={setIsSuccess}
                            QuestionModalContent={questionModalContent}
                        />
                    )}

                    {tableData.length > 0 ? (
                        <>
                            {tableCaption && (
                                <h6 className="mt-4 card-subtitle">{tableCaption}</h6>
                            )}
                            <Table
                                responsive="lg"
                                className="table table-flush table-striped table-hover mb-3"
                            >
                                {renderTableHeader(tableHeader)}
                                {renderTableBody(tableHeader, tableData)}
                            </Table>
                        </>
                    ) : (
                        <>
                            {tableCaption && (
                                <h6 className="mt-4 card-subtitle">{tableCaption}</h6>
                            )}
                            <div className="card-no-data mt-4">{t("header.NoDataFound")}</div>{" "}
                        </>
                    )}
                </Col>
            </Row>
            {blurLoader && <div id='blurdiv' className="blurbackground">
                <div className={`preloader loader-center-align`}>
                    <Image className="loader-element animate__animated animate__jackInTheBox" src={LoaderImage} height={40} data-seconds={30} />
                </div>
            </div>}
            {showPagination &&
                tableHeader.length > 0 &&
                tableData.length > 0 &&
                totalRecords > 0 && (
                    <Row className="pagination-content">
                        <Col className="px-4 border-0 d-lg-flex align-items-center justify-content-between">
                            <PaginationComponent
                                pageNo={pageNo}
                                setPageNo={setPageNo}
                                pagesData={pagesData}
                                items={items}
                                setItems={setItems}
                                onPageNoChange={onPageNoChange}
                                onItemChange={onItemChange}
                                minimumRows={minimumRows}
                                showPaginationSummary={showPaginationSummary}
                                totalRecords={totalRecords}
                            />
                        </Col>
                    </Row>
                )}
        </>
    );
};

TableComponent.propTypes = {
    tableHeaderData: PropTypes.array,
    tableBodyData: PropTypes.array,
    setSearchText: PropTypes.func,
    searchText: PropTypes.string,
    showPagination: PropTypes.bool,
    onSortingChange: PropTypes.func,
    showPaginationSummary: PropTypes.bool,
};

TableComponent.defaultProps = {
    tableHeaderData: [],
    tableBodyData: [],
    setSearchText: null,
    searchText: "",
    showPagination: false,
    onSortingChange: null,
    showPaginationSummary: false,
};

export default TableComponent;