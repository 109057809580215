import React, { useState, useEffect, useContext, useMemo } from 'react';
import RGL, { WidthProvider } from 'react-grid-layout';
import { Row, Col, Button, Image, Form, InputGroup, Modal, Alert } from '@themesberg/react-bootstrap';
import AppContext from '../components/AppContext';
import CommonHeader from '../components/CommonHeader';
import request from '../apis/request';
import { baseURL, METHODS } from '../apis/utilities/constant';
import LoaderImage from "../assets/img/LoaderGraphic.svg";
import ButtonsComponent from './Buttons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faCheckCircle, faCode, faFileAlt, faSlidersH, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { EDITOR_API_KEY, EMAIL_REGEX, ERROR_TIMEOUT, OPERATION_TYPE } from '../AppConfig';
import Datetime from "react-datetime";
import moment from 'moment';
import ReactTagInput from '@pathofdev/react-tag-input';
import { Editor } from '@tinymce/tinymce-react';
import { ValidateTextInputs } from '../utils/common';
import { useTranslation } from 'react-i18next';
import { decryptData } from '../utils/crypto';


const ReactGridLayout = WidthProvider(RGL);

const ManualAlerts = ({ operationType, success, setTableRefresh, tableRefresh, actionData, setEditPreviewData, parentBlurLoader, onCloseSuccessMessage, errorMessage, handleClose, onConfirm, confirmationText, setConfirmationText, children, dashboardItems, setPageNo, setOffset, selectedWebsiteId, selectedOtpclientid, setSelectedWebsiteId, setSelectedOtpclientid, history, location }) => {

  const contextData = useContext(AppContext);
  const [autoLoad, setAutoLoad] = useState(false);
  const [selectedOtpclientidNew, setSelectedOtpclientidNew] = useState({});
  const [blurLoader, setBlurLoader] = useState(false);
  const [scheduleDate, setScheduleDate] = useState(undefined);
  const [t, i18n] = useTranslation();
  const [subject, setSubject] = useState(undefined);
  const [emailDescriptionHtml, setEmailDescriptionHtml] = useState(undefined);
  const [emailDescriptionText, setEmailDescriptionText] = useState(undefined);
  const [languageCode, setLanguageCode] = useState(undefined);
  const [messageId, setMessageId] = useState(undefined);
  const [messageType, setMessageType] = useState(undefined);
  const [publishDateTime, setPublishDateTime] = useState(undefined);
  const [toAddressHTML, setToAddressHTML] = useState([]);
  const [toAddressText, setToAddressText] = useState([]);
  const [toAddressHTMLMaster, setToAddressHTMLMaster] = useState([]);
  const [toAddressTextMaster, setToAddressTextMaster] = useState([]);
  const [editorValue, setEditorValue] = useState('');
  const [editorText, setEditorText] = useState('');
  const [highlightNote, setHighlightNote] = useState(false);
  const [gatewayId, setGatewayId] = useState(undefined);
  const [smsgatewayId, setSmsGatewayId] = useState(undefined);

  const [initialValue, setInitialValue] = useState(undefined);
  const [error, setErrorMessage] = useState(undefined);
  const [successMessage, setSuccessMessage] = useState(undefined);

  const [categoryId, setCategoryId] = useState(undefined);
  const [updateKey, setUpdateKey] = useState("");
  const [alertState, setAlertState] = useState([]);

  useEffect(() => {
    if (actionData && operationType == OPERATION_TYPE.EDIT) {
      setSubject(actionData?.MessageSubject);
      let date = moment(actionData.PublishTimeField);
      setPublishDateTime(date && date._d);
      setEmailDescriptionText(actionData?.EmailDescriptionText);

      setToAddressHTMLMaster(actionData?.ToAddressHTML);

      let toAddressEmail = actionData?.ToAddressHTML ? actionData?.ToAddressHTML.split(",") : [];
      let validateToAddressEmail = [];
      toAddressEmail && Array.isArray(toAddressEmail) && toAddressEmail.length > 0 && toAddressEmail.map((item) => {
        if (EMAIL_REGEX.test(item.trim())) {
          validateToAddressEmail.push(item);
        }
      })
      actionData?.ToAddressHTML && setToAddressHTML(validateToAddressEmail);

      let ToAddressText = actionData?.ToAddressText ? actionData?.ToAddressText.split(",") : [];
      let validateToAddressText = [];
      ToAddressText && Array.isArray(ToAddressText) && ToAddressText.length > 0 && ToAddressText.map((item) => {
        if (EMAIL_REGEX.test(item.trim())) {
          validateToAddressText.push(item);
        }
      })

      actionData?.ToAddressText && setToAddressText(validateToAddressText);
      setToAddressTextMaster(actionData?.ToAddressText);

      setEditorValue(actionData?.EmailDescriptionHtml);

      setInitialValue(actionData?.EmailDescriptionHtml);
      setMessageId(actionData.MessageId);
      setMessageType(actionData.MessageType);
      setLanguageCode(actionData.LanguageCode);

      setGatewayId(actionData.GatewayId);
      setSmsGatewayId(actionData.SMSGatewayId);

      setCategoryId(actionData.CategoryId);
    }
  }, [actionData, operationType])

  useEffect(() => {
    if (localStorage.getItem("IsPageRefresh") == "1") {
      setSelectedWebsiteId(JSON.parse(localStorage.getItem("manuageAlertId")));
      setSelectedOtpclientidNew(JSON.parse(localStorage.getItem("manuageAlertId")));
    }

  }, []);

  useEffect(() => {
    getWebsites();
  }, [contextData.userId, contextData.companyId]);

  const getWebsites = () => {
    setBlurLoader(true);
    request(baseURL + `/Intelligence/GetAlertsChannelWebsiteByClientUser?CompanyId=${contextData.companyId}&UserId=${contextData.userId}`,
      METHODS.GET,
      {},
      {
        Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
      })
      .then((res) => {
        setBlurLoader(false);
        let resultData = [];
        if (res.result !== null && res.result.length) {
          resultData = res.result.map((e) => ({ ["WebsiteList.WebsiteName"]: e.WebsiteName, ["WebsiteList.WebsiteId"]: e.WebsiteId, ["WebsiteList.WebsiteUrl"]: e.WebsiteUrl, ["ClietConfiguration.otpclientid"]: e.OTPClientId, ["WebsiteList.AlertTrackingDate"]: e.AlertTrackingDate }))
        }
        rendersite({ resultSet: resultData })
      })
      .catch((err) => {
        setBlurLoader(false);
      })
  }

  const onWebsiteDropdownChange = (data) => {
    setSelectedOtpclientidNew({});
    const websiteId = data["WebsiteList.WebsiteId"];
    setSelectedOtpclientidNew(data);
  };

  const sendAlertToMe = (e, alertType) => {
    let obj = {
      // WebsiteId: contextData.websiteId["WebsiteList.WebsiteId"],
      WebsiteId: contextData.websiteId,
      TextTemplateContent: emailDescriptionText,
      HtmlTemplateContent: editorValue,
      AlertType: alertType,
      SubjectText: subject,
      CompanyId: contextData.companyId,
      UserId: contextData.userId,
      Email: contextData.userEmail
    }

    setBlurLoader(true);

    request(baseURL + `/Intelligence/SendAlertToMe`, METHODS.POST, obj, {
      Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
    })
      .then((res) => {
        if (res?.result) {
          setBlurLoader(false);
          setSuccessMessage(t("header.AlertMessageAlertSentSuccessfully"));
          setTimeout(() => {
            setSuccessMessage(undefined);
          }, ERROR_TIMEOUT);
        }
        else {
          setBlurLoader(false);
          setErrorMessage(t("header.AlertMessageErrorOccured"));
          setTimeout(() => {
            setErrorMessage(undefined);
          }, ERROR_TIMEOUT);
          return false;
        }
      })
      .catch((err) => {

      })
  }

  const validateDate = () => {
    if (publishDateTime && publishDateTime <= new Date()) {
      setErrorMessage(t("header.AlertMessageSchDateMustBeGreater"));
      setTimeout(() => {
        setErrorMessage(undefined);
      }, ERROR_TIMEOUT);
      return false;
    }

    return true;
  }

  const SaveAsDraft = (e) => {
    var regexEmail = EMAIL_REGEX;

    if (messageType == "Scheduled" && !validateDate()) {
      return;
    }
    if (ValidateTextInputs(emailDescriptionText)) {
      setErrorMessage(t("header.manualAlertsInvalidAccount"));
      setTimeout(() => {
        setErrorMessage(undefined);
      }, ERROR_TIMEOUT);
      return;
    }
    let isError = false;
    if (toAddressHTML.length > 0) {
      toAddressHTML.map((item, index) => {
        if (item && !regexEmail.test(item.trim())) {
          setErrorMessage(t("header.manualAlertsAlertMessege") + toAddressHTML[index]);
          isError = true;
        }
      })

      if (isError)
        return;
    }
    if (toAddressText.length > 0) {
      isError = false;
      toAddressText.map((item, index) => {
        if (item && !regexEmail.test(item.trim())) {
          setErrorMessage(t("header.manualAlertsAlertMessege") + toAddressText[index]);
          isError = true;
        }
      })

      if (isError)
        return;
    }

    setBlurLoader(true);

    setErrorMessage(undefined);

    let html = "";
    let text = "";

    toAddressHTML && Array.isArray(toAddressHTML) && toAddressHTML.length > 0 && toAddressHTML.map((item) => {
      html = html ? html + "," + item : item;
    })
    toAddressText && Array.isArray(toAddressText) && toAddressText.length > 0 && toAddressText.map((item) => {
      text = text ? text + "," + item : item;
    })

    let obj = {
      EditorContentText: editorValue,
      EmailTemplateText: emailDescriptionText,
      HoursValue: moment(publishDateTime).format("HH"),
      HtmlSubscribers: html,
      LanguageCode: languageCode,
      MessageId: messageId,
      MinuteValue: moment(publishDateTime).format("mm"),
      SMSSubscribers: "",
      SMSTemplateText: "",
      ScheduleDateTime: moment(publishDateTime).format("YYYY-MM-DD"),
      SubjectText: subject,
      TextSubscribers: text,
      // WebsiteId: contextData.websiteId["WebsiteList.WebsiteId"],
      WebsiteId: contextData.websiteId,
      CompanyId: contextData.companyId,
      UserId: contextData.userId,
      MessageType: messageType
    }

    request(baseURL + `/Intelligence/AlertPreviewSend`, METHODS.POST, obj, {
      Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
    })
      .then((res) => {
        if (res?.result) {
          setBlurLoader(false);
          resetNotificationForm();
          setSuccessMessage(t("header.AlertMessageSavedSuccess"));
          setTableRefresh(!tableRefresh);
          setTimeout(() => {
            setSuccessMessage(undefined);
          }, ERROR_TIMEOUT);
        }
        else {
          setBlurLoader(false);
          setErrorMessage(t("header.AlertErrorOccurred"));
          setTimeout(() => {
            setErrorMessage(undefined);
          }, ERROR_TIMEOUT);
          return false;
        }
      })
      .catch((err) => {
        setBlurLoader(false);
        setErrorMessage(t("header.AlertErrorOccurred"));
        setTimeout(() => {
          setErrorMessage(undefined);
        }, ERROR_TIMEOUT);
        return false;
      })
  }

  const SaveAndSend = (e) => {
    var regexEmail = EMAIL_REGEX;
    if (messageType == "Scheduled" && !validateDate()) {
      return;
    }
    if (ValidateTextInputs(emailDescriptionText)) {
      setErrorMessage("Invalid text email content.");
      setTimeout(() => {
        setErrorMessage(undefined);
      }, ERROR_TIMEOUT);
      return;
    }
    let isError = false;
    if (toAddressHTML.length > 0) {
      toAddressHTML.map((item, index) => {
        if (item && !regexEmail.test(item.trim())) {
          setErrorMessage(t("header.manualAlertsAlertMessege") + toAddressHTML[index]);
          isError = true;
        }
      })

      if (isError)
        return;
    }
    if (toAddressText.length > 0) {
      isError = false;
      toAddressText.map((item, index) => {
        if (item && !regexEmail.test(item.trim())) {
          setErrorMessage(t("header.manualAlertsAlertMessege") + toAddressText[index]);
          isError = true;
        }
      })

      if (isError)
        return;
    }

    setBlurLoader(true);

    setErrorMessage(undefined);

    let html = "";
    let text = "";

    toAddressHTML && Array.isArray(toAddressHTML) && toAddressHTML.length > 0 && toAddressHTML.map((item) => {
      html = html ? html + "," + item : item;
    })
    toAddressText && Array.isArray(toAddressText) && toAddressText.length > 0 && toAddressText.map((item) => {
      text = text ? text + "," + item : item;
    })

    let obj = {
      // "WebsiteId": contextData.websiteId["WebsiteList.WebsiteId"],
      "WebsiteId": contextData.websiteId,
      "MessageId": messageId,
      "CategoryId": categoryId,
      "GatewayId": gatewayId,
      "SMSGatewayId": smsgatewayId,
      "LanguageCode": languageCode,
      "MessageType": messageType,
      "ToAddressText": text,
      "ToAddressHtml": html,
      "MobileNo": "",
      "MessageSubject": subject,
      "MessageDescription": "",
      "EmailDescriptionText": emailDescriptionText,
      "EmailDescriptionHTML": editorValue,
      "ResendMessageId": messageId,
      "CompanyId": contextData.companyId,
      "UserId": contextData.userId
    }

    request(baseURL + `/Intelligence/AlertSaveAndSend`, METHODS.POST, obj, {
      Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
    })
      .then((res) => {
        if (res?.result) {
          setBlurLoader(false);
          resetNotificationForm();
          setSuccessMessage(t("header.AlertMessageAlertSentSuccessfully"));
          setTableRefresh(!tableRefresh);
          setTimeout(() => {
            setSuccessMessage(undefined);
          }, ERROR_TIMEOUT);
        }
        else {
          setBlurLoader(false);
          setTableRefresh(!tableRefresh);
          setErrorMessage("Error occurred while sending an alert.");
          setTimeout(() => {
            setErrorMessage(undefined);
          }, ERROR_TIMEOUT);
          return false;
        }
      })
      .catch((err) => {
        setBlurLoader(false);
        setTableRefresh(!tableRefresh);
        setErrorMessage("Error occurred while sending an alert.");
        setTimeout(() => {
          setErrorMessage(undefined);
        }, ERROR_TIMEOUT);
      })
  }

  const onChangeEditor = (newValue, editor) => {
    setEditorValue(newValue);
    setEditorText(editor.getContent({ format: 'text' }));
  };

  const applyFilters = () => {
    setBlurLoader(false);
    const websiteId = selectedOtpclientidNew["WebsiteList.WebsiteId"];
    setSelectedWebsiteId(websiteId);
    contextData.changeWebsiteId(websiteId);
    setSelectedOtpclientid(selectedOtpclientidNew);
    localStorage.setItem("alertWebsiteId", JSON.stringify(selectedOtpclientidNew));// Adding ManualAlertWebsiteId to local stoarge in case of Page Refresh
  }

  const cancelFilters = () => {
    onWebsiteDropdownChange(JSON.parse(localStorage.getItem("alertWebsiteId")))
  }

  const rendersite = ({ resultSet, error, pivotConfig, request }) => {
    if (resultSet !== undefined && resultSet.length && Object.keys(selectedOtpclientid).length <= 0) {
      if (JSON.parse(localStorage.getItem('alertWebsiteId')) !== null) {
        onWebsiteDropdownChange(JSON.parse(localStorage.getItem('alertWebsiteId')));
        setUpdateKey(Math.random())
      } else {
        onWebsiteDropdownChange(resultSet[0]);
        setAutoLoad(!autoLoad);
        setUpdateKey(Math.random());
      }
    }
    if (resultSet !== undefined && resultSet.length > 0) {
      setAlertState(resultSet);
      localStorage.setItem("alertState", JSON.stringify(resultSet))
      setAutoLoad(!autoLoad);
      setUpdateKey(Math.random())
    }

    return (
      <></>
    );

  };

  const commonHeader = useMemo(() => <CommonHeader cancelFilters={cancelFilters} channel="alert" autoLoad={autoLoad} dataSource={[
    { name: 'Dropdown1 - website', data: alertState, selectedItem: selectedOtpclientidNew, isVisible: true, label: t("header.filterLabelWebsite"), callback: onWebsiteDropdownChange, displayText: "WebsiteList.WebsiteName" },
  ]} title={t("header.AlertSubNavManageManualAlerts")} showDate={false} applyFilters={applyFilters} />, [selectedOtpclientidNew, autoLoad, updateKey])

  const validateScheduleDate = (current) => {
    let yesterday = moment().subtract(1, 'day');;
    return current.isAfter(yesterday);
  };

  const onScheduleDateChange = (e, value) => {
    setPublishDateTime(moment(e._d));
  }

  const resetNotificationForm = () => {
    setEditPreviewData(undefined);
    setSubject(undefined);
    setEditorText(undefined);
    setEditorValue(undefined);

    setToAddressHTML([]);
    setToAddressText([]);

    setToAddressHTMLMaster([]);
    setToAddressTextMaster([]);
    setHighlightNote(false);

    setEmailDescriptionText(undefined);
    setEmailDescriptionHtml(undefined);
    setPublishDateTime(undefined);

  }

  const onEditModalClose = () => {
    resetNotificationForm();
    handleClose();
  }

  const onClose = () => {
    setErrorMessage(undefined);
  }

  return (
    <>
      {(errorMessage || successMessage || success) && <div className="fade modal-backdrop show"></div>}
      <Alert
        className="toast-custom"
        variant="danger"
        show={errorMessage ? true : false}
        onClose={() => onCloseSuccessMessage()}
      >
        <div className="d-flex justify-content-between">
          <div>
            <FontAwesomeIcon icon={faTimesCircle} className="me-2" />
            {errorMessage}
          </div>
        </div>
      </Alert>
      <Alert
        className="toast-custom"
        variant="success"
        show={(success || successMessage) ? true : false}
        onClose={() => setSuccessMessage(undefined)}
      >
        <div className="d-flex justify-content-between">
          <div>
            <FontAwesomeIcon icon={faCheckCircle} className="me-2" />
            {success || successMessage}
          </div>
        </div>
      </Alert>

      {
        operationType == OPERATION_TYPE.EDIT && actionData &&
        <>
          {/* Confirmation Model */}
          <Modal as={Modal.Dialog} size="xl" centered show={operationType == OPERATION_TYPE.EDIT ? true : false} onHide={handleClose}>
            <Modal.Header>
              <Modal.Title className="h5"><h5>{t("header.AlertsPreviewEditPreview")}</h5></Modal.Title>
              <Button variant="close" aria-label="Close" onClick={onEditModalClose} />
            </Modal.Header>
            <Modal.Body>
              {messageType == "Scheduled" && publishDateTime && <><Row className='mt-2'>
                <Col md={3} className='mt-2'>
                  <Form.Label>{t("header.AlertsPreviewScheduleDateTime")}<span className="mandatory">*</span></Form.Label>
                </Col>
                <Col md={6}>
                  <Datetime
                    isValidDate={validateScheduleDate}
                    timeFormat={true}
                    closeOnSelect={false}
                    onChange={(e) => {
                      onScheduleDateChange(e);
                    }}
                    className="mt-2"
                    renderInput={(props, openCalendar) => (
                      <InputGroup>
                        <Form.Control
                          required
                          type="text"
                          value={messageType == "Scheduled" && publishDateTime ? moment(publishDateTime).format("MMM DD, YYYY HH:mm") : undefined}
                          onFocus={openCalendar}
                          onChange={() => { }} />
                        <InputGroup.Text><FontAwesomeIcon icon={faCalendarAlt} /></InputGroup.Text>
                      </InputGroup>
                    )} />

                </Col>
              </Row>
                <Row className="mt-2 mb-2">
                  <Col md={12}>
                    <span>{t("header.adhocalertHocAlertsNoteMessage")} <a target={"_blank"} href='http://www.timezoneconverter.com/cgi-bin/tzc.tzc'>{t("header.AlertsPreviewNoteHere")}</a></span>
                  </Col>
                </Row></>}
              <Row className="mt-3 mb-2">
                <Col md={3}>
                  {t("header.AlertsPreviewSubject")}
                </Col>
                <Col md={6}>
                  <Form.Control className="mb-1" value={subject} onChange={(e) => {
                    setSubject(e.target.value)
                  }} rows="2" maxLength="" name="subject" />
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <div className={highlightNote ? "mandatory" : ""}>{t("header.adhocalertEmailAddressRequeire")}</div>
                </Col>
              </Row>
              <Row className="mt-3 mb-2">
                <Col md={12}>
                  <h5><FontAwesomeIcon icon={faFileAlt} />&nbsp; {t("header.AlertsPreviewEmailTextFormat")}</h5>
                </Col>
              </Row>
              <Row className="mt-3 mb-2">
                <Col md={12}>
                  <ReactTagInput
                    tags={toAddressText}
                    remove={
                      () => {
                      }
                    }
                    removeOnBackspace={true}
                    editable={faSlidersH}
                    placeholder={t("header.manualAlertsTypeAndPressPlaceholder")}
                    onChange={(uTags) => {
                      if (toAddressText.length == 1) {
                        if (toAddressText.length > 1) {
                          setHighlightNote(false);
                          setToAddressText(uTags)
                        }
                        else {
                          if (uTags.length > 1) {
                            setHighlightNote(false);
                            setToAddressText(uTags)
                          }
                          else {
                            if (toAddressHTML.length > 0) {
                              setHighlightNote(false);
                              setToAddressText(uTags)
                            }
                            else {
                              setHighlightNote(true);
                            }
                          }
                        }
                      }
                      else {
                        setHighlightNote(false);
                        setToAddressText(uTags)
                      }
                    }}
                  />
                </Col>
              </Row>
              <Row className="mt-3 mb-2">
                <Col md={12}>
                  <Form.Control as="textarea" value={emailDescriptionText} onChange={(e) => {
                    setEmailDescriptionText(e.target.value)
                  }} className="mb-1" rows="5" maxLength="" name="subject" />
                </Col>
              </Row>
              <Row className="mt-3 mb-2">
                <Col md={3}>
                  <ButtonsComponent
                    dissable={subject ? false : true}
                    isIcon={false}
                    isPrimary={false}
                    btnText={t("header.AlertSendAdHocAlertsSendMeCopy")}
                    btn_size="md mt-1"
                    onClick={(e) => sendAlertToMe(e, "Text")}
                  />
                </Col>
              </Row>
              <Row className="mt-3 mb-2">
                <Col md={12}>
                  <h5><FontAwesomeIcon icon={faCode} />&nbsp; {t("header.AlertsPreviewEmailHtmlFormat")}</h5>
                </Col>
              </Row>
              <Row className="mt-3 mb-2">
                <Col md={12}>
                  <ReactTagInput
                    tags={toAddressHTML}
                    remove={
                      () => {
                      }
                    }
                    removeOnBackspace={true}
                    editable={faSlidersH}
                    placeholder={t("header.manualAlertsTypeAndPressPlaceholder")}
                    onChange={(uTags) => {
                      if (toAddressHTML.length == 1) {
                        if (toAddressHTML.length > 1) {
                          setHighlightNote(false);
                          setToAddressHTML(uTags)
                        }
                        else {
                          if (uTags.length > 1) {
                            setHighlightNote(false);
                            setToAddressHTML(uTags)
                          }
                          else {
                            if (toAddressText.length > 0) {
                              setHighlightNote(false);
                              setToAddressHTML(uTags)
                            }
                            else {
                              setHighlightNote(true);
                            }
                          }
                        }
                      }
                      else {
                        setHighlightNote(false);
                        setToAddressHTML(uTags)
                      }
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <Editor
                    initialValue={initialValue}
                    onEditorChange={(newValue, editor) => { onChangeEditor(newValue, editor) }}
                    onInit={(evt, editor) => {
                      setEditorText(editor.getContent({ format: 'text' }));
                    }}
                    apiKey={EDITOR_API_KEY}
                    plugins='lists code'
                    init={{ branding: false, keep_styles: true }}
                  />
                </Col>
              </Row>
              <Row className="mt-3 mb-2">
                <Col md={3}>
                  <ButtonsComponent
                    dissable={subject ? false : true}
                    isIcon={false}
                    isPrimary={false}
                    btnText={t("header.AlertSendAdHocAlertsSendMeCopy")}
                    btn_size="md mt-1"
                    onClick={(e) => sendAlertToMe(e, "Html")}
                  />
                </Col>
              </Row>

              <Row>
                <Col md={8}>
                  <Alert
                    variant="danger"
                    show={error ? true : false}
                    onClose={() => onClose("danger")}
                  >

                    <div className="d-flex justify-content-between">
                      <div>
                        <FontAwesomeIcon icon={faTimesCircle} className="me-2" />
                        {error}
                      </div>
                      <Button variant="close" size="xs" onClick={() => { onClose("danger") }}
                      />
                    </div>
                  </Alert>
                </Col>
                <Col md={4}>
                  <ButtonsComponent
                    dissable={subject ? false : true}
                    isIcon={false}
                    isPrimary={false}
                    btnText={t("header.AlertsPreviewButtonSave")}
                    onClick={(e) => { SaveAsDraft(e) }}
                    btn_class="mx-1" />
                  <ButtonsComponent
                    dissable={subject ? false : true}
                    isIcon={false}
                    isPrimary={true}
                    btnText={t("header.AlertsPreviewButtonSend")}
                    onClick={(e) => { SaveAndSend(e) }}
                    btn_class="me-2" />
                </Col>
              </Row>
            </Modal.Body>
          </Modal>
        </>
      }

      {/* Confirmation Model */}
      <Modal as={Modal.Dialog} size="md" centered show={confirmationText ? true : false} onHide={handleClose}>
        <Modal.Body>
          {confirmationText}
        </Modal.Body>
        <Modal.Footer>
          <ButtonsComponent
            isIcon={false}
            isPrimary={true}
            btnText={t("header.AlertConfirmButtonOK")}
            onClick={onConfirm}
            btn_class="me-3" />
          <ButtonsComponent
            isIcon={false}
            isPrimary={false}
            btnText={t("header.AlertConfirmButtonCancel")}
            onClick={handleClose}
            btn_class="mb-0" />
        </Modal.Footer>
      </Modal>
      {(blurLoader || parentBlurLoader) && <div id='blurdiv' className="blurbackground">
        <div className={`preloader loader-center-align`}>
          <Image className="loader-element animate__animated animate__jackInTheBox" src={LoaderImage} height={40} data-seconds={30} />
        </div>
      </div>}
      <Row>
        <Col>
          {commonHeader}
          {/* <QueryRenderer
            query={{
              "dimensions": [
                "ClietConfiguration.otpclientid",
                "WebsiteList.WebsiteId",
                "WebsiteList.WebsiteName"
              ],
              "timeDimensions": [],
              "order": {
                "WebsiteList.WebsiteId": "asc"
              },
              "filters": [
                {
                  "member": "ClietConfiguration.userid",
                  "operator": "equals",
                  "values": [`${contextData.userId}`]
                },
                {
                  "member": "ClietConfiguration.companyid",
                  "operator": "equals",
                  "values": [`${contextData.companyId}`]
                }
              ],
            }}
            // cubejsApi={cubejsApi}
            resetResultSetOnChange={false}
            render={(props) => rendersite({
              ...props,
              chartType: 'dropdown',
              pivotConfig: {
                "x": [
                  "ClietConfiguration.otpclientid",
                  "WebsiteList.WebsiteId",
                  "WebsiteList.WebsiteName"

                ],
                "y": ["WebsiteList.WebsiteName"],
                "fillMissingDates": true,
                "joinDateRange": false
              }
            })}
          /> */}
        </Col>
      </Row>
      {/* Change css when react grid item order will be changed or rearranged */}
      <Row>
        <Col xs={12}>
          <ReactGridLayout cols={12} rowHeight={50}
            isDraggable={false} isResizable={false} className="rgl-manual-alerts"
          >
            {children}
          </ReactGridLayout>
        </Col>
      </Row>
    </>
  );
};

export default ManualAlerts;
