
import React, { useState, useEffect, useContext, useMemo } from 'react';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import { object_equals } from "../AppConfig";
import { useTranslation } from 'react-i18next';
import { Row, Col, Image } from '@themesberg/react-bootstrap';

import request from '../apis/request';
import { baseURL, METHODS } from '../apis/utilities/constant';
import LoaderImage from "../assets/img/LoaderGraphic.svg";
import { decryptData } from '../utils/crypto';

import PagePerformanceChart from './PagePerformanceChart';
import AppContext from '../components/AppContext';
import CommonHeader from '../components/CommonHeader';

const PagePerformance = ({ children }) => {

  const [t, i18n] = useTranslation();
  const contextData = useContext(AppContext);

  const [selectedProfileIdNew, setSelectedProfileIdNew] = useState("")
  const [selectedWebsiteIdNew, setSelectedWebsiteIdNew] = useState("");
  const [autoLoad, setAutoLoad] = useState("");
  const [profilesList, setProfilesList] = useState([]);
  const [blurLoader, setBlurLoader] = useState(false);
  const [websiteList, setWebsiteList] = useState([]);

  useEffect(() => {
    if (contextData.companyId > 0) {
      if (localStorage.getItem("websiteData") && JSON.parse(localStorage.getItem("websiteData")).length > 0
        && localStorage.getItem("AllprofileData") && JSON.parse(localStorage.getItem("AllprofileData")).length > 0) {
        let websiteList = JSON.parse(localStorage.getItem("websiteData"));
        let selectedWebsite = JSON.parse(localStorage.getItem("WebsiteListWebsiteId"));
        renderWebsite(websiteList, selectedWebsite)
      }
      else if (!localStorage.getItem("websiteData") || !localStorage.getItem("AllprofileData")) {
        if (!localStorage.getItem("websiteData")) {
          getWebsites();
        }
        else if (!localStorage.getItem("AllprofileData")) {
          getProfiles(JSON.parse(localStorage.getItem("websiteData")));
        }
      }
    }
  }, [contextData.companyId])

  const renderWebsite = (resultSet, selectedWebsiteValue) => {
    setWebsiteList(resultSet)
    setSelectedWebsiteIdNew(selectedWebsiteValue);
    renderProfileList(selectedWebsiteValue, 0);
  };

  const renderProfileList = (selectedWebsiteValue, isTempUpdate) => {
    if (localStorage.getItem("AllprofileData") && JSON.parse(localStorage.getItem("AllprofileData")).length > 0) {
      let profileList = JSON.parse(localStorage.getItem("AllprofileData"));
      let selectedWebsiteProfiles = profileList.filter((e) => { return e.WebsiteId === selectedWebsiteValue["WebsiteList.WebsiteId"] });
      if (selectedWebsiteProfiles.length > 0) {
        localStorage.setItem("cookieProfileData", JSON.stringify(selectedWebsiteProfiles));
        let profileData = selectedWebsiteProfiles.map((e) => ({ ["WebsiteProfiles.ProfileId"]: e.ProfileId, ["WebsiteProfiles.DisplayName"]: e.DisplayName, ["WebsiteProfiles.IntelConfigId"]: e.IntelConfigId }));
        setProfilesList(profileData);
        let selectedProfile = profileData[0];
        if (isTempUpdate === 0) {
          let storedProfile = JSON.parse(localStorage.getItem("WebsiteProfilesIntelConfigId"));
          if (storedProfile && Object.keys(storedProfile).length > 0
            && profileData.some(obj => obj["WebsiteProfiles.ProfileId"] === storedProfile["WebsiteProfiles.ProfileId"])) {
            selectedProfile = storedProfile;
          };
          localStorage.setItem("profileData", JSON.stringify(profileData));
        }
        setSelectedProfileIdNew(selectedProfile);
      }
      else {
        setProfilesList([]);
        setSelectedProfileIdNew({});
        if (isTempUpdate === 0) {
          localStorage.setItem("profileData", JSON.stringify([]));
        }
      }
    }
    setAutoLoad(true);
  }

  const getWebsites = () => {
    request(baseURL + `/CommonV2/GetWebsiteChannelWebsiteByClientUser?CompanyId=${contextData.companyId}&UserId=${contextData.userId}`,
      METHODS.GET,
      {},
      {
        Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
      })
      .then((res) => {
        let resultData = [];
        if (res.result && res.result.length > 0) {
          resultData = res.result.map((e) => ({ cmsplatformid: res.platformid, ["WebsiteList.WebsiteName"]: e.WebsiteName, ["WebsiteList.WebsiteId"]: e.WebsiteId, ["WebsiteList.WebsiteUrl"]: e.WebsiteUrl }))
        }
        if (resultData.length > 0) {
          localStorage.setItem("websiteData", JSON.stringify(resultData))
          getProfiles(resultData);
        }
        else {
          localStorage.setItem("websiteData", JSON.stringify([]))
          localStorage.setItem("AllprofileData", JSON.stringify([]))
        }
      })
      .catch((err) => {
      })
  }

  const getProfiles = (resultData) => {
    request(baseURL + `/WebsiteAnalytics/GetWebsites?CompanyId=${contextData.companyId}&UserId=${contextData.userId}`,
      METHODS.GET,
      {},
      {
        Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
      })
      .then((res) => {

        if (res.result != null && res.result.length > 0) {
          let result = removeDuplicateObjects(res.result, 'IntelConfigId')
          localStorage.setItem("AllprofileData", JSON.stringify(result))
        }
        else {
          localStorage.setItem("profileData", JSON.stringify([]))
        }
        if (!localStorage.getItem("WebsiteListWebsiteId")) {
          renderWebsite(resultData, resultData[0])
        }
        else {
          renderWebsite(resultData, JSON.parse(localStorage.getItem("WebsiteListWebsiteId")))
        }
        if (res.result.length == 0) {
          setAutoLoad(true);
        }

      })
      .catch((err) => {
        //setBlurLoader(false);
      })
  }

  function removeDuplicateObjects(array, key) {
    const seen = {};
    return array.filter(item => {
      const itemKey = key ? item[key] : item;
      return seen.hasOwnProperty(itemKey) ? false : (seen[itemKey] = true);
    });
  }
  const onWebsiteDropdownChange = (data) => {
    if (!object_equals(selectedWebsiteIdNew, data)) {
      setSelectedProfileIdNew({});
    }
    setSelectedWebsiteIdNew(data);
    renderProfileList(data, 1)
  };
  const onProfileDropdownChange = (data) => {
    const intellConfigId = data["WebsiteProfiles.IntelConfigId"];
    contextData.changeIntelConfigId(intellConfigId);
    setSelectedProfileIdNew(data)

    //setUpdateKey(Math.random())
  };
  const cancelFilters = () => {
    onWebsiteDropdownChange(JSON.parse(localStorage.getItem("WebsiteListWebsiteId")))
    onProfileDropdownChange(JSON.parse(localStorage.getItem("WebsiteProfilesIntelConfigId")))
  }
  const applyFilters = () => {

    const websiteId = selectedWebsiteIdNew["WebsiteList.WebsiteId"];

    // if (JSON.stringify(selectedProfileIdNew).length == 2) {
    //   setTabDataHide(1);
    // }
    // else {
    //   setTabDataHide(-1);
    // }

    contextData.changeWebsiteId(websiteId);
    contextData.changeSelectedWebsite(selectedWebsiteIdNew);
    //setSelectedWebsiteId(selectedWebsiteIdNew)
    contextData.changeSelectedProfile(selectedProfileIdNew);
    //setSelectedProfileId(selectedProfileIdNew);

    if (profilesList.length > 0) {
      localStorage.setItem("profileData", JSON.stringify(profilesList))
    }
    else {
      localStorage.setItem("profileData", JSON.stringify([]))
    }
    localStorage.setItem("WebsiteListWebsiteId", JSON.stringify(selectedWebsiteIdNew));// Adding WebsiteList.WebsiteId to local stoarge in case of Page Refresh
    localStorage.setItem("WebsiteProfilesIntelConfigId", JSON.stringify(selectedProfileIdNew));// Adding WebsiteProfiles.IntelConfigId to local stoarge in case of Page Refresh
  }
  const commonHeaderChild = useMemo(() => <><CommonHeader cancelFilters={cancelFilters} channel="website" autoLoad={autoLoad} dataSource={[
    { name: 'Dropdown1 - Website', data: websiteList, selectedItem: selectedWebsiteIdNew, isVisible: true, label: t("header.filterLabelWebsite"), callback: onWebsiteDropdownChange, displayText: "WebsiteList.WebsiteName" },
    { name: 'Dropdown2 - Profile', data: profilesList, selectedItem: selectedProfileIdNew, isVisible: true, label: t("header.filterLabelProfile"), callback: onProfileDropdownChange, displayText: "WebsiteProfiles.DisplayName" },
  ]} title={t("header.pageAnalyticsTitle")} showDate={true} applyFilters={applyFilters} /></>, [selectedProfileIdNew, selectedWebsiteIdNew, autoLoad])


  return (
    <>
      {blurLoader && <div id='blurdiv' className="blurbackground">
        <div className={`preloader loader-center-align`}>
          <Image className="loader-element animate__animated animate__jackInTheBox" src={LoaderImage} height={40} data-seconds={30} />
        </div>
      </div>}

      <Row className="align-items-center">
        <Col>
          {commonHeaderChild}
        </Col>
      </Row>

      {contextData.websiteId && children ?
        <Row>
          <Col>
            <div className="d-inline-block ps-3 w-100" id="pageAnalyticsContent">
              <div className="shadow-soft border rounded border-light pb-3 pt-0 ps-1 pe-2 w-100 nested-card-container page-performance-detail">
                {
                  contextData.selectedProfile["WebsiteProfiles.ProfileId"] &&
                  <PagePerformanceChart
                    selectedProfile={contextData.selectedProfile}
                    startDate={contextData.startDate}
                    endDate={contextData.endDate}
                  />
                }
              </div>

              <div className="shadow-soft border rounded border-light mt-3 pt-3 w-100 nested-card-container page-performance-detail page-performance-content-insight">
                {children}
              </div>
            </div>
          </Col>
        </Row>
        :
        <></>
      }
    </>
  );
};

export default PagePerformance;
