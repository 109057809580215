import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button } from "@themesberg/react-bootstrap";

import { cardMenu } from "../stubs/dasbordCardMenu";

import ModalComponent from "../components/VoltModal";
import CardComponent from "../components/CardWithMetrices";

const DashboardItem = ({
  pageName,
  summaryTooltip,
  itemId,
  children,
  title,
  summaryPrimary,
  summarySecondary,
  showSummary,
  history,
  enableOption,
  summary,
  chartType,
  hideTitle,
  tooltipText,
  setIsPageLoaderShown,
  recordsCount,
  customTitle,
  loadedCardIdiesList

}) => {
  const navigate = useNavigate();

  const [t, i18n] = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [showMenu, setShowMenu] = useState(true)

  useEffect(() => {
    setShowMenu(localStorage.getItem("loaderId" + itemId));
  }, [localStorage.getItem("loaderId" + itemId)])

  const onModalClose = () => {
    setShowModal(false);
  };

  const onModalConfirm = () => {
  };

  const onDetauilViewClick = (itemId) => {
    localStorage.setItem("IsPageRefresh", "0"); // set  page refresh indicator to 0 on side bar click event
    if (itemId === "3") {
      navigate(`/website/visitoranalytics-page`);
    }
    else if (itemId === "4") {
      navigate(`/website/visitoranalytics-page/watchlist`);
    } else if (itemId === "6") {
      navigate(`/website/search-page`);
    }
    else if (itemId === "1") {
      navigate(`/website/cookie-analytics-page`);
    }
    else if (itemId === "7") {
      navigate(`/website/useranalytics-page`);
    }
    else if (itemId === "8") {
      navigate(`/website/page-analytics-page`);
    }
  };

  const onCardMenuClick = (name, itemId) => {
    switch (name) {
      case "Detailed_view":
        onDetauilViewClick(itemId);
        break;
      default:
        break;
    }
  };

  return (
    <>
      {showModal && (
        <ModalComponent
          open={showModal}
          title={t("header.dashboardItemsTitle")}
          handleClose={onModalClose}
        >
          <Button
            variant="outline-dark"
            className="m-1 text-gray ms-auto"
            onClick={onModalClose}
          >
            {t("header.dashboardItemsNo")}
          </Button>
          &nbsp;&nbsp;
          <Button
            variant="outline-danger"
            className="m-1 text-gray ms-auto"
            onClick={() => onModalConfirm(itemId)}
          >
            {t("header.dashboardItemsYes")}
          </Button>
        </ModalComponent>
      )}
      <CardComponent
        recordsCount={recordsCount}
        setIsPageLoaderShown={setIsPageLoaderShown}
        itemId={itemId}
        title={title}
        customTitle={customTitle}
        summaryPrimary={summaryPrimary}
        summarySecondary={summarySecondary}
        showSummary={showSummary}
        onModalClose={onModalClose}
        onCardMenuClick={onCardMenuClick}
        showMenuIems={loadedCardIdiesList !== undefined && loadedCardIdiesList.indexOf(parseInt(itemId)) !== -1 ? true : false}
        cardMenu={cardMenu}
        enableOption={enableOption}
        summary={summary}
        chartType={chartType}
        hideTitle={hideTitle}
        tooltipText={tooltipText}
        tooltip={summaryTooltip}
        pageName={pageName}>
        {children}
      </CardComponent>
    </>
  );
};

export default DashboardItem;
