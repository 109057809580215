import React, { useEffect, useState, useContext, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { Row, Col } from '@themesberg/react-bootstrap';

import { defaultLayout, defaultLayoutMobile } from "../utils/layoutConfig";
import { getPages } from '../stubs/ComponentJson/Pages';
import request from '../apis/request';
import { METHODS } from '../apis/utilities/constant';
import { decryptData } from '../utils/crypto';

import DashboardItem from '../components/DashboardItem';
import AppContext from '../components/AppContext';
import Search from "../components/Search";
import ChartRendererTable from '../components/ChartRendererTable';
import PagePerformance from '../components/PagePerformance';

const deserializeItem = (i) => ({
  ...i,
  layout: JSON.parse(i.layout) || {},
  summary: JSON.parse(i.summary) || {},
  layoutone: JSON.parse(i.layoutone) || {},
  layouttwo: JSON.parse(i.layouttwo) || {},
  vizStateSecond: JSON.parse(i.vizStateSecond) || {},
  vizState: JSON.parse(i.vizState)
});

const PagePerformanceAnalytics = (props) => {
  const navigate = useNavigate();

  const [t] = useTranslation();

  const contextData = useContext(AppContext);

  const [searchText, setSearchText] = useState("");
  const [pagesData, setPagesData] = useState([]);
  const [sourceMedium, setSourceMedium] = useState("direct");
  const [offset, setOffset] = useState(0);
  const [d3, setd3] = useState();
  const [apiResponse, setApiResponse] = useState([]);
  const [apiResponseMaster, setApiResponseMaster] = useState([]);
  const [searchData, setSearchData] = useState("");
  const [updateString, setUpdateString] = useState("")
  const [getTotalResults, setGetTotalResults] = useState(0)

  useEffect(() => {
    setSearchData("")
    setApiResponse({})
    setUpdateString(Math.random().toString())
    setApiResponseMaster({});

    const selectedstartdate = moment(contextData.startDate).format("YYYY-MM-DD");
    const selectedenddate = moment(contextData.endDate).format("YYYY-MM-DD");
    const selectedPreviousYearStartDate = moment(contextData.startDate).add(-1, 'years').format("YYYY-MM-DD");
    const selectedPreviousYearEndDate = moment(contextData.endDate).add(-1, 'years').format("YYYY-MM-DD");

    if (contextData.selectedTab == "Website") {
      let dta = null;
      if (contextData.selectedProfile["WebsiteProfiles.ProfileId"]) {
        if (searchText == undefined || searchText == "") {
          dta = ((contextData.selectedProfile["WebsiteProfiles.ProfileId"] !== undefined && contextData.selectedWebsite["WebsiteList.WebsiteUrl"] !== undefined
            && contextData.selectedProfile["WebsiteProfiles.IntelConfigId"] !== undefined && sourceMedium !== undefined && selectedstartdate !== "Invalid date" && selectedenddate !== "Invalid date" && selectedPreviousYearStartDate !== "Invalid date" && selectedPreviousYearEndDate !== "Invalid date"))
            ? getPages(contextData.selectedProfile["WebsiteProfiles.ProfileId"], contextData.selectedWebsite["WebsiteList.WebsiteUrl"], sourceMedium, selectedstartdate, selectedenddate, selectedPreviousYearStartDate, selectedPreviousYearEndDate, contextData.userId, contextData.selectedProfile["WebsiteProfiles.IntelConfigId"], contextData.companyId, offset)
            : null;
        }
      }
      setPagesData(dta);
    }
    else {
      setPagesData([])
    }
  }, [contextData.selectedProfile["WebsiteProfiles.ProfileId"], contextData.selectedTab, sourceMedium, contextData.startDate, contextData.endDate, searchText, offset]);

  useEffect(() => {
    setUpdateString(Math.random().toString())
    let searchResult = userSearch({ ...apiResponseMaster }, searchData)
    localStorage.setItem("pageNo", 1)
    setTimeout(() => { setApiResponse(searchResult); }, 500)
  }, [searchData, apiResponseMaster])

  useEffect(() => {
    if (pagesData !== null && pagesData.length > 0) {
      getData(pagesData[0].apiUrl.url);
    }
  }, [pagesData]);

  const getData = (url) => {
    localStorage.setItem("pageNo", 1)
    request(url, METHODS.GET, {}, { Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json" })
      .then((response) => {
        setApiResponseMaster(response);
        setApiResponse(response)
        setUpdateString(Math.random().toString())
      })
      .catch(() => {
      })
  }

  const userSearch = (result, searchText) => {
    if (searchText !== undefined && searchText !== "") {
      let resultObject = result;
      if (result !== null) {
        if (resultObject?.data !== null && resultObject?.data?.length > 0) {
          let filterResult = resultObject?.data?.filter(item => item["ContentInsights.Page"]?.toLowerCase().indexOf(searchText?.toLowerCase()) > -1);
          resultObject.data = filterResult;
        }
        return resultObject;
      }

    } else {
      return result
    }
  }

  const tableData = useMemo(() =>
    <ChartRendererTable
      pageNo={localStorage.getItem("pageNo") != undefined ? parseInt(localStorage.getItem("pageNo")) : 1}
      showPagination={true}
      resultSet={apiResponse}
      showCount={true}
      showNumberOfRows={10}
      setPageNo={1}
      searchText={updateString}
      setGetTotalResults={(value) => setGetTotalResults(value)}
    />, [updateString, apiResponse]);

  const dashboardItem = (item) => (
    <div className="page-analytics-switch-toggle page-analytics-summary-tooltip " key={item.id} data-grid={isMobile ? defaultLayoutMobile(item) : defaultLayout(item)}>
      <DashboardItem
        key={item.id}
        itemId={item.id}
        title={item.name}
        enableOption={item.enableOption}>
        <>
          <Row>
            <Col className={localStorage.getItem("isDemoClient") ? "custom-disable" : ""}>
              <div className="d-inline-block float-end position-relative search-box-container-box">
                <div className="d-inline-block float-end search-box-container">
                  <Search
                    searchWaterMark={t("header.searchPlaceHolder")}
                    setsearchData={setSearchData}
                    searchData={searchData}
                    isAutoComplete={false}
                    type={'client'}
                  />
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              {tableData}
            </Col>
          </Row>
        </>
      </DashboardItem>
    </div>
  );

  const Empty = () => (
    <div
      style={{
        textAlign: 'center',
        padding: 12,
      }}
    >
      <h2>{t("header.getConnectIdIntelligence")}</h2>
    </div>
  );

  const handleBackButtonClick = (e) => {
    localStorage.setItem("IsPageRefresh", "0");
    navigate(`/website`);
  }

  return !pagesData || pagesData.length > 0 ? (
    <PagePerformance handleBackButtonClick={handleBackButtonClick}>
      {pagesData && pagesData.map(deserializeItem).map(dashboardItem)}
    </PagePerformance>
  ) : (
    <Empty />
  );

}

export default PagePerformanceAnalytics