import React, { useState, useEffect, useContext, useMemo } from 'react';
import RGL, { WidthProvider } from 'react-grid-layout';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { Row, Col, Button } from '@themesberg/react-bootstrap';

import { object_equals } from "../AppConfig";
import request from '../apis/request';
import { baseURL, METHODS } from '../apis/utilities/constant';
import { decryptData } from '../utils/crypto';

import CommonHeader from '../components/CommonHeader';
import AppContext from '../components/AppContext';

const ReactGridLayout = WidthProvider(RGL);

const Useranalytics = ({ children, handleBackButtonClick }) => {

  const [t, i18n] = useTranslation();
  const contextData = useContext(AppContext);
  const [autoLoad, setAutoLoad] = useState(false);
  const [selectedWebsiteIdNew, setSelectedWebsiteIdNew] = useState({})
  const [selectedProfileIdNew, setSelectedProfileIdNew] = useState({});
  const [profilesList, setProfilesList] = useState([]);
  const [websiteList, setWebsiteList] = useState([]);

  useEffect(() => {
    if (contextData.companyId > 0) {
      if (localStorage.getItem("websiteData") && JSON.parse(localStorage.getItem("websiteData")).length > 0) {
        let websiteList = JSON.parse(localStorage.getItem("websiteData"));
        let selectedWebsite = JSON.parse(localStorage.getItem("WebsiteListWebsiteId"));
        renderWebsite(websiteList, selectedWebsite);
      }
      else if (!localStorage.getItem("websiteData")) {
        getWebsites2();
      }
    }
  }, [])

  const renderWebsite = (resultSet, selectedWebsiteValue) => {
    setWebsiteList(resultSet);
    setSelectedWebsiteIdNew(selectedWebsiteValue);
    renderProfileList2(selectedWebsiteValue, 0);
  };

  const renderProfileList2 = (selectedWebsiteValue, isTempUpdate) => {
    if (localStorage.getItem("AllprofileData") && JSON.parse(localStorage.getItem("AllprofileData")).length > 0) {
      let profileList = JSON.parse(localStorage.getItem("AllprofileData"));
      let selectedWebsiteProfiles = profileList.filter((e) => { return e.WebsiteId === selectedWebsiteValue["WebsiteList.WebsiteId"] });
      if (selectedWebsiteProfiles.length > 0) {
        localStorage.setItem("cookieProfileData", JSON.stringify(selectedWebsiteProfiles));
        let profileData = selectedWebsiteProfiles.map((e) => ({ ["WebsiteProfiles.ProfileId"]: e.ProfileId, ["WebsiteProfiles.DisplayName"]: e.DisplayName, ["WebsiteProfiles.IntelConfigId"]: e.IntelConfigId }));
        setProfilesList(profileData);
        let selectedProfile = profileData[0];
        if (isTempUpdate === 0) {
          let storedProfile = JSON.parse(localStorage.getItem("WebsiteProfilesIntelConfigId"));
          if (storedProfile && Object.keys(storedProfile).length > 0
            && profileData.some(obj => obj["WebsiteProfiles.ProfileId"] === storedProfile["WebsiteProfiles.ProfileId"])) {
            selectedProfile = storedProfile;
          };
          localStorage.setItem("profileData", JSON.stringify(profileData));
        }
        setSelectedProfileIdNew(selectedProfile);
      }
      else {
        setProfilesList([]);
        setSelectedProfileIdNew({});
        if (isTempUpdate === 0) {
          localStorage.setItem("profileData", JSON.stringify([]));
        }
      }
    }
    setAutoLoad(true);
  }

  const getWebsites2 = () => {
    request(baseURL + `/CommonV2/GetWebsiteChannelWebsiteByClientUser?CompanyId=${contextData.companyId}&UserId=${contextData.userId}`,
      METHODS.GET,
      {},
      {
        Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
      })
      .then((res) => {
        let resultData = [];
        if (res.result && res.result.length > 0) {
          resultData = res.result.map((e) => ({ cmsplatformid: res.platformid, ["WebsiteList.WebsiteName"]: e.WebsiteName, ["WebsiteList.WebsiteId"]: e.WebsiteId, ["WebsiteList.WebsiteUrl"]: e.WebsiteUrl }));
        }
        if (resultData.length > 0) {
          request(baseURL + `/WebsiteAnalytics/GetWebsites?CompanyId=${contextData.companyId}&UserId=${contextData.userId}`,
            METHODS.GET,
            {},
            {
              Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
            })
            .then((res) => {
              if (res.result != null && res.result.length > 0) {
                let result = removeDuplicateObjects(res.result, 'IntelConfigId');
                localStorage.setItem("AllprofileData", JSON.stringify(result));
              }
              else {
                localStorage.setItem("profileData", JSON.stringify([]));
              }
              localStorage.setItem("websiteData", JSON.stringify(resultData));
              renderWebsite(resultData, resultData[0]);
              if (res.result.length == 0) {
                setAutoLoad(true);
              }
            })
            .catch((err) => {
            })
        }
        else {
          localStorage.setItem("websiteData", JSON.stringify([]));
        }
      })
      .catch((err) => {
      })
  }

  function removeDuplicateObjects(array, key) {
    const seen = {};
    return array.filter(item => {
      const itemKey = key ? item[key] : item;
      return seen.hasOwnProperty(itemKey) ? false : (seen[itemKey] = true);
    });
  }

  const onWebsiteDropdownChange = (data) => {
    if (!object_equals(selectedWebsiteIdNew, data)) {
      setSelectedProfileIdNew({});
    }
    setSelectedWebsiteIdNew(data);
    renderProfileList2(data, 1);
  };

  const onProfileDropdownChange = (data) => {
    const intellConfigId = data["WebsiteProfiles.IntelConfigId"];
    contextData.changeIntelConfigId(intellConfigId);
    setSelectedProfileIdNew(data);
  };

  const cancelFilters = () => {
    onWebsiteDropdownChange(JSON.parse(localStorage.getItem("WebsiteListWebsiteId")));
    onProfileDropdownChange(JSON.parse(localStorage.getItem("WebsiteProfilesIntelConfigId")));
  }

  const applyFilters = () => {
    const websiteId = selectedWebsiteIdNew["WebsiteList.WebsiteId"];
    contextData.changeWebsiteId(websiteId);
    contextData.changeSelectedWebsite(selectedWebsiteIdNew);
    contextData.changeSelectedProfile(selectedProfileIdNew);
    if (profilesList.length > 0) {
      localStorage.setItem("profileData", JSON.stringify(profilesList));
    }
    else {
      localStorage.setItem("profileData", JSON.stringify([]));
    }
    localStorage.setItem("WebsiteListWebsiteId", JSON.stringify(selectedWebsiteIdNew));// Adding WebsiteList.WebsiteId to local stoarge in case of Page Refresh
    localStorage.setItem("WebsiteProfilesIntelConfigId", JSON.stringify(selectedProfileIdNew));// Adding WebsiteProfiles.IntelConfigId to local stoarge in case of Page Refresh
  }

  const commonHeaderChild = useMemo(() => <CommonHeader cancelFilters={cancelFilters} channel="website" autoLoad={autoLoad} dataSource={[
    { name: 'Dropdown1 - Website', data: websiteList, selectedItem: selectedWebsiteIdNew, isVisible: true, label: t("header.filterLabelWebsite"), callback: onWebsiteDropdownChange, displayText: "WebsiteList.WebsiteName" },
    { name: 'Dropdown2 - Profile', data: profilesList, selectedItem: selectedProfileIdNew, isVisible: true, label: t("header.filterLabelProfile"), callback: onProfileDropdownChange, displayText: "WebsiteProfiles.DisplayName" },
  ]} title={t("header.userAnalyticsTitle")} showDate={true} applyFilters={applyFilters} />, [selectedProfileIdNew, selectedWebsiteIdNew, autoLoad])

  return (
    <>
      <Row>
        <Col className="text-start" sm={12} xl={12}>
          {commonHeaderChild}
        </Col>
      </Row>
      <Row className="mb-3">
        <Col className="text-start" lg={12} xl={4}>
        </Col>
        <Col xs={12} xl={8}>
        </Col>
      </Row>
      <Row>
        <Col lg={12}>
          <Button variant="light" onClick={(e) => handleBackButtonClick(e)} bsPrefix="text" className="ms-3 link-icn-btn">
            <FontAwesomeIcon className="me-1" icon={faChevronLeft} /> {t("header.backButton")}
          </Button>
        </Col>
      </Row>
      <Row className="mt-2 mb-2" id="userAnalyticsContent">
        <Col lg={12}>
          {contextData.selectedProfile["WebsiteProfiles.ProfileId"] && children ?
            <ReactGridLayout rowHeight={65}
              isDraggable={false} isResizable={false}
            >
              {children}
            </ReactGridLayout>
            : <div></div>
          }
        </Col>
      </Row>
    </>
  );
};

export default Useranalytics;
