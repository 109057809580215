import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Modal } from "@themesberg/react-bootstrap";

import ButtonsComponent from "../components/Buttons";

const QuestionModel = ({ onSuccessModelClose, showModal, setShowModal, isSuccess, setIsSuccess, onCancelClose, QuestionModalContent }) => {
  const [t, i118] = useTranslation();

  const onModalClose = () => {
    setShowModal && setShowModal(false);
    onCancelClose();
  };

  const onCancelButtonClick = (e) => {
    e.preventDefault();
    setIsSuccess(false);
    onModalClose();
  };

  return (
    <Modal as={Modal.Dialog} size="md" centered show={showModal} onHide={onModalClose}>
      <Modal.Header>
        <Modal.Title className="h5">{t(QuestionModalContent.title)}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {t(QuestionModalContent.content)}
      </Modal.Body>

      <Modal.Footer>
        <ButtonsComponent
          onClick={(e) => onSuccessModelClose(e)}
          isIcon={false}
          isPrimary={true}
          btn_class="me-3"
          btnText={t("header.WatchlisDeleteOk")}
        />
        <ButtonsComponent
          onClick={(e) => onCancelButtonClick(e)}
          isIcon={false}
          isPrimary={false}
          btn_class="mb-0"
          btnText={t("header.WatchlisDeleteCancel")}
        />
      </Modal.Footer>
    </Modal>
  );
};

QuestionModel.propTypes = {
  handleButtonClick: PropTypes.func,
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func,
  isSuccess: PropTypes.bool,
  setIsSuccess: PropTypes.func
};

QuestionModel.defaultProps = {
  handleButtonClick: null,
  showModal: true,
  setShowModal: null,
  isSuccess: false,
  setIsSuccess: false
};

export default QuestionModel;
