import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import '../i18n';
import axios from "axios";
import { Row, Col } from '@themesberg/react-bootstrap';

function BackToCMSPages() {
    const navigate = useNavigate();

    const [t, i18n] = useTranslation();

    var sessiont = localStorage.getItem("sessiontime");
    var t2 = new Date();
    var diffMs = t2.getTime() - new Date(sessiont).getTime();
    var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
    const search = useLocation().search;

    if (diffMins > 5) {
        localStorage.clear();         // Clear All Entries from Local Stoarge
        sessionStorage.clear();       // Clear All Entries from Session Stoarge
        navigate("/login"); // Redirect to Login Page
        window.location.reload();
    }
    else {

        const user = new URLSearchParams(search).get('user');
        const myinvestisdata = new URLSearchParams(search).get('myinvestisdata');
        const errorcode = new URLSearchParams(search).get('errorcode');
        const adminuser = new URLSearchParams(search).get('adminuser');
        const token = localStorage.getItem('IStoken');

        if (localStorage.getItem("validatelogin") == "0" || localStorage.getItem("validatelogin") == undefined) {
            localStorage.setItem("validatelogin", "1");
            axios.post(`${process.env.REACT_APP_API_KEY}/Broadcast/ValidateCMSLogin`, { 'User': user, 'MyInvestisData': myinvestisdata, 'AdminUser': adminuser, 'ErrorCode': errorcode, 'token': token }).then(response => {
                localStorage.setItem("validatelogin", "0");

                if (response.data != null && response.data.isAuthenticated) {
                    localStorage.setItem("PageURL", response.data.redirectURL);
                    window.location.href = response.data.redirectURL;
                }
                else {
                    localStorage.clear();         // Clear All Entries from Local Stoarge
                    sessionStorage.clear();       // Clear All Entries from Session Stoarge
                    navigate("/login"); // Redirect to Login Page
                    window.location.reload();
                }
            })
                .catch(error => {
                    localStorage.clear();         // Clear All Entries from Local Stoarge
                    sessionStorage.clear();       // Clear All Entries from Session Stoarge
                    navigate("/login"); // Redirect to Login Page
                    window.location.reload();
                });
        }
    }

    return (
        <div className="px-3">
            <Row className="mb-3">
                <Col lg={12} xl={12}>
                    <h2 className="h2 mb-0">{t("header.redirect")}</h2>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col lg={12}>
                    <div className="bg-white shadow-soft border rounded border-light p-4 w-100">
                        <h6 className="h6"></h6>
                        <p>
                            <em>{t("header.somethingWentWrong")}</em>
                        </p>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default BackToCMSPages