import React, { useState, useEffect, useContext } from "react";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import axios from "axios";
import SimpleBar from 'simplebar-react';
import { CSSTransition } from 'react-transition-group';
import { faTimes, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Nav, Badge, Image, Button, Accordion, Navbar, Modal } from '@themesberg/react-bootstrap';

import { menuItemsDetais, WebsiteChannelDetailURL, SettingsMenuURL } from "../utils/sidebar";
import { getStringValue } from "../utils/common";
import ConnectIDLogo from "../assets/img/ConnectIDIntelligence.svg";
import ProfileIcon from "../assets/Icon/Account.svg";
import iconSprite from "../assets/Icon/svg-icn-sprite.svg";
import { HiddenMenus, ChannelID } from "../AppConfig";
import { baseURL, METHODS } from '../apis/utilities/constant';
import LoaderImage from "../assets/img/LoaderGraphic.svg";
import request from '../apis/request';
import { decryptData } from "../utils/crypto";
import moment from "moment";
import ButtonComponent from "../components/Buttons";
import AppContext from '../components/AppContext';

const SideBar = React.memo(props => {
  const [t, i18n] = useTranslation();
  const contextData = useContext(AppContext);

  const navigate = useNavigate();

  const location = useLocation();
  const { pathname } = location;
  const [userName, setUserName] = useState("");
  const [show, setShow] = useState(false);
  const [showDefault, setShowDefault] = useState(false);
  const [message, setMessage] = useState("");
  const handleClose = () => setShowDefault(false);
  const showClass = show ? "show" : "";
  const [currentMenuLoadedWebsiteID, setCurrentMenuLoadedWebsiteID] = useState(0);
  const [blurLoader, setBlurLoader] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState("");
  const [isEditWebsiteEnabled, setIsEditWebsiteEnabled] = useState(false)

  const onCollapse = () => setShow(!show);

  useEffect(() => {
    getUserName();
    if (contextData.companyId > 0 && contextData.userId > 0 && (contextData.selectedTab === "" || contextData.menu.length === 0)) {
      setCurrentMenuLoadedWebsiteID(0)
      setBlurLoader(true);
      let oo = localStorage.getItem("WebsiteListWebsiteId");
      const obj2 = JSON.parse(oo);
      let websiteid = 0;
      if (obj2 !== null) {
        websiteid = obj2["WebsiteList.WebsiteId"]
      }

      request(baseURL + `/UserV2/GetMainMenuByClientUser?userId=${contextData.userId}&companyid=${contextData.companyId}`,
        METHODS.GET,
        {},
        {
          Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
        })
        .then((response) => {
          if (response && response.resultData) {

            let menu = response.resultData.menuDetails;
            menu = menu.filter((x) => { return x.id !== 4 })

            // let settingDta = {
            //   id: 0,
            //   dispIndex: 13,
            //   isActionPoint: false,
            //   isActive: true,
            //   subMenu: [],
            //   subSections: null,
            //   title: "Settings"
            // };

            // if (localStorage.getItem("selectedTab") === "Settings") {

            //   let submenuarray = [{ actionParam: "", actionType: 1, dispIndex: 0, id: 1, isActionPoint: true, isActive: true, isEnabled: true, title: "My account" },
            //   { actionParam: "", actionType: 1, dispIndex: 1, id: 2, isActionPoint: true, isActive: true, isEnabled: true, title: "My documents" },
            //   { actionParam: "", actionType: 1, dispIndex: 2, id: 3, isActionPoint: true, isActive: true, isEnabled: true, title: "Digest subscriptions" }];
            //   settingDta.subMenu = submenuarray;
            // }

            // menu.push(settingDta);

            menu.sort(function (a, b) {
              return a.dispIndex - b.dispIndex;
            });

            contextData.setMenu(menu);


            if (!localStorage.getItem("selectedTab")) {

              // if (selectedtab === 1 || selectedtab === 0) {
              localStorage.setItem("selectedTab", "Website")
              setSelectedMenu("Website")
              contextData.changeSelectedTab("Website");
              // }
              // else {
              //   localStorage.setItem("selectedTab", "Alerts")
              //   setSelectedMenu("Alerts")
              //   contextData.changeSelectedTab("Alerts");
              // }

            }
            else {
              //1stroundchange added whole if condition to counter navigation on change of client dropdown for settings
              // if (localStorage.getItem("selectedTab") === "Website") {
              //   let websitemenu = menu.find(x => x.id === 1)
              //   if (
              //     pathname.includes("settings") &&
              //     websitemenu.isActive) {
              //     navigate("/website")
              //   }
              //   else if (websitemenu.isActive === false) {
              //     navigate("/website-marketing")
              //   }
              // }

              //1stroundchange added next line 
              setSelectedMenu(localStorage.getItem("selectedTab"))
              contextData.changeSelectedTab(localStorage.getItem("selectedTab"));
            }
            if (SettingsMenuURL.includes(pathname)) {
              setBlurLoader(false);
            }
          }
        })
        .catch((err) => {
          // setBlurLoader(false);
        })
    }
  }, [contextData.companyId]);

  // For TF only
  useEffect(() => {
    let currentProfileId = 0;
    if (contextData.menu) {
      let menu = contextData.menu.find(item => item.id === 15);
      if (menu?.subMenu?.length) {
        currentProfileId = menu.subMenu[0].refId
      }
    }
    //this condition is applied when user tries to modify url when it is already showing tf-marketing page.
    //so when it is showing tf marketing page local storage has path  value set to '/tf-marketing', and if any one changes url and that url contains /tnf/, it will redirect to marketing page again.
    if (pathname.toLowerCase().startsWith("/tnf/") && localStorage.getItem("path") == '/tf-marketing') {
      navigate("/tf-marketing");
    }
    if (contextData.selectedTab === "IR Tools Analytics" && contextData.selectedProfile["WebsiteProfiles.ProfileId"] > 0) {
      if (currentProfileId !== contextData.selectedProfile["WebsiteProfiles.ProfileId"]) {
        let submenu = [{
          actionParam: "",
          actionType: 1,
          dispIndex: 1,
          isActionPoint: true,
          isActive: true,
          isEnabled: true,
          title: "Tools Insight",
          link: menuItemsDetais.find(x => x.sid === 0).link,
          refId: contextData.selectedProfile["WebsiteProfiles.ProfileId"]
        }, {
          actionParam: "",
          actionType: 1,
          dispIndex: 2,
          isActionPoint: true,
          isActive: true,
          isEnabled: true,
          title: "Visitor Analytics",
          link: menuItemsDetais.find(x => x.sid === 1).link,
          refId: contextData.selectedProfile["WebsiteProfiles.ProfileId"]
        },
        {
          actionParam: "",
          actionType: 1,
          dispIndex: 2,
          isActionPoint: true,
          isActive: true,
          isEnabled: true,
          title: "Notification",
          link: menuItemsDetais.find(x => x.sid === 21).link,
          refId: contextData.selectedProfile["WebsiteProfiles.ProfileId"]
        }
        ];
        request(baseURL + `/UserV2/GetSubMenuByClientUserTF?IntelConfigId=` + contextData.selectedProfile["WebsiteProfiles.IntelConfigId"], //passed IntelconfigId in place of profileId
          METHODS.GET,
          {},
          {
            Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
          })
          .then((res) => {
            if (res) {

              res.forEach((dta) => {
                submenu.push({
                  actionParam: "",
                  actionType: 1,
                  dispIndex: dta.displayOrder + 1,
                  isActionPoint: true,
                  isActive: true,
                  isEnabled: true,
                  title: dta.tool_name,
                  toolid: dta.tool_id,
                  link: menuItemsDetais.find(x => x.sid === dta.tool_id + 1).link,
                  refId: contextData.selectedProfile["WebsiteProfiles.ProfileId"]
                });
              });

              if (submenu.length) {
                submenu.sort((a, b) => a.dispIndex - b.dispIndex);
                let menu = contextData.menu;
                let newarr = menu.filter(function (item) {
                  return item.id === 15;
                });
                let newobject = newarr[0];
                newobject.subMenu = submenu;
                let updatedArray = menu.map(obj => {
                  if (obj.id == 15) {
                    return newobject
                  }
                  else {
                    return obj;
                  }
                });
                contextData.setMenu(updatedArray);
              }
              if (!submenu.some(obj => obj.link.includes(pathname))) {
                navigate(menuItemsDetais.find(x => x.sid === 0).link);
              }
            }
          })
          .catch((err) => {
          })
      }
      else {
        //This condition is for restricting the access to 
        let menu = contextData.menu.find(item => item.id === 15);
        if (!menu?.subMenu.some(obj => obj.link.includes(pathname))) {
          navigate(menuItemsDetais.find(x => x.sid === 0).link);
        }
      }
    }
    else if (contextData.selectedTab === "IR Tools Analytics") {
      if (currentProfileId > 0) {
        let submenu = [{
          actionParam: "",
          actionType: 1,
          dispIndex: 1,
          id: 112,
          isActionPoint: true,
          isActive: true,
          isEnabled: true,
          title: "Tools Insight",
          link: menuItemsDetais.find(x => x.sid === 1).link,
          refId: contextData.selectedProfile["WebsiteProfiles.ProfileId"]
        }];
        let menu = contextData.menu.find(item => item.id === 15);
        let newobject = menu;
        newobject.subMenu = submenu;
        let updatedArray = contextData.menu.map(obj => {
          if (obj.id === 15) {
            return newobject
          }
          else {
            return obj;
          }
        });
        contextData.setMenu(updatedArray);
        navigate(menuItemsDetais.find(x => x.sid === 0).link);
      }
    }
  }, [contextData.selectedProfile["WebsiteProfiles.ProfileId"], contextData.selectedTab])

  useEffect(() => {
    if (contextData.selectedTab) {
      let localstoragewebsitewid = 0;
      const tabWebsiteMapping = {
        Website: "WebsiteListWebsiteId",
        Alerts: "alertWebsiteId",
        Tools: "SelectedEventId",
      };
      const selectedKey = tabWebsiteMapping[contextData.selectedTab];
      if (selectedKey) {
        const obj2 = JSON.parse(localStorage.getItem(selectedKey));
        if (obj2) {
          localstoragewebsitewid = obj2["WebsiteList.WebsiteId"];
        }
      }
      const tabMapping = {
        "Alerts": 8,
        "On-Air Streaming": 5,
        "Tools": 6,
        "Settings": 0,
        "IR Tools Analytics": 0,
      };
      const selectedTab = contextData.selectedTab;
      const sectionid = tabMapping[selectedTab] ?? 1;
      let menu = contextData.menu;
      let currentmenuchildren = menu.find(x => x.id === sectionid);
      //below 2 if condition is written to counter a situation where a user changes client from website channel and website id not getting set
      // from the child page due to improper config in website- profile dropdown this condition enforces setting id accoringly to pages available
      if (!localstoragewebsitewid && localStorage.getItem("TempWId")) {
        localstoragewebsitewid = localStorage.getItem("TempWId");
        localStorage.removeItem("TempWId")
      }
      // if (contextData.selectedTab === localStorage.getItem("selectedTab") && contextData.selectedTab === "Website" && !localstoragewebsitewid) {
      //   ClickTab(null, "Website")
      // }

      //contextid is for context id which sometime takes time to change in few cases hence compared with localstorage value & 2nd condition is the case when there is now website in list for a client
      //same logic applies for tab of context and localstorage
      //for submenu logic is applied to avoid multiple calling if the submenu is already loaded for parent link & 2nd condition is to reload children if it exists for specific website after selection of one client which has no websites
      //for settings section id is 0

      //1stroundchange added contextid>0 because if otp client entry doesnt exist then its going in if condition with localstoragewebsitewid & removed  || newarr[0].subMenu.length == 0 from 3rd condition
      if (((contextData.websiteId > 0 && localstoragewebsitewid == contextData.websiteId) || (localstoragewebsitewid == 0 && contextData.websiteId > 0))
        && (contextData.selectedTab == localStorage.getItem("selectedTab"))
        // temp changes for reducing redundancy
        //  && ((!currentmenuchildren || (currentmenuchildren.subMenu?.length == 0) || (localstoragewebsitewid != currentMenuLoadedWebsiteID)) || (localstoragewebsitewid == 0 && contextData.websiteId > 0))
        && ((!currentmenuchildren?.subMenu?.length || (localstoragewebsitewid != (currentmenuchildren?.subMenu?.[0]?.refId ?? 0))) || (localstoragewebsitewid == 0 && contextData.websiteId > 0))
        && sectionid != 0) {

        setCurrentMenuLoadedWebsiteID(localstoragewebsitewid)

        if (!selectedMenu) {
          setSelectedMenu(localStorage.getItem("selectedTab"))
        }

        loadSubmenu(contextData.companyId, sectionid, contextData.websiteId)

      }

      else if (localstoragewebsitewid != contextData.websiteId && localstoragewebsitewid > 0 && localStorage.getItem("selectedTab") == contextData.selectedTab) {
        // This condition is executed when we go from one channel to other which has different website id 
        setBlurLoader(false);
        contextData.changeWebsiteId(localstoragewebsitewid);
      }
      else if (contextData.selectedTab === "On-Air Streaming" && contextData.websiteId === 0 && contextData.menu.find(x => x.id === 5)?.isActive === true && !currentmenuchildren?.subMenu?.length) {
        // For webcast this condition is written specially to avoid complexity in first condition but the functionality is same to bring submenu
        setCurrentMenuLoadedWebsiteID(0)
        if (!selectedMenu) {
          setSelectedMenu(localStorage.getItem("selectedTab"))
        }
        loadSubmenu(contextData.companyId, sectionid, contextData.websiteId)
      }
      else if (contextData.selectedTab === "Settings") {
        resetToRemoveChildFromMenuArray(0);
      }
      else if (localstoragewebsitewid === (currentmenuchildren?.subMenu?.[0]?.refId ? currentmenuchildren?.subMenu?.[0]?.refId : 0)) {
        // This if is executed as a url change to validate url if the submenu is already fetched and url is changed from navigation bar
        if (currentmenuchildren?.subMenu?.length) {

          let submenuarray = currentmenuchildren.subMenu;

          // To check if the Edit website is avilable in context for respective websiteid
          if (submenuarray.find(obj => obj.id === 4)) {
            setIsEditWebsiteEnabled(true)
          } else {
            setIsEditWebsiteEnabled(false)
          }

          if (!submenuarray.some(obj => obj.link.includes(pathname)) && !WebsiteChannelDetailURL.includes(pathname)) {

            if (submenuarray[0].link) {
              navigate(submenuarray[0].link);
            }
            else if (contextData.selectedTab === "Website" && submenuarray.some(obj => obj.id === 6)) {
              navigate("/editable-pages");
            }
            else if (contextData.selectedTab === "Website" && !submenuarray.some(obj => obj.id === 6)) {
              navigate("/website");
            }

          }
          else if (contextData.selectedTab == "Website" && ((pathname == "/") || (WebsiteChannelDetailURL.includes(pathname) && !submenuarray.some(obj => obj.link.includes("/website"))))) {
            navigate("/website");
          }
          else if (pathname.includes("/website/visitoranalytics-page") && (!submenuarray.some(obj => obj.link.includes("/website/visitoranalytics-page")) && WebsiteChannelDetailURL.includes(pathname))) {
            navigate("/website");
          }

        }
        else {
          const isWebsiteTab = contextData.selectedTab === "Website";
          const isActiveMenu = contextData.menu?.find(x => x.id === 1)?.isActive;

          if (isWebsiteTab && isActiveMenu && !pathname.includes("website-marketing")) {
            resetToRemoveChildFromMenuArray(sectionid);
            navigate("/website");
          } else if (isWebsiteTab && !pathname.includes("website-marketing")) {
            navigate("/website-marketing");
          } else if (contextData.selectedTab === "Tools" && !pathname.includes("tools-marketing")) {
            navigate("/tools-marketing");
          } else if (contextData.selectedTab === "Alerts" && !pathname.includes("alert-marketing")) {
            navigate("/alert-marketing");
          } else if (contextData.selectedTab === "On-Air Streaming" && !pathname.includes("webcast-marketing")) {
            navigate("/webcast-marketing");
          }

        }
        setBlurLoader(false);
      }
      else {
        //1stround whole else condition
        setBlurLoader(false);
      }
    }
  }, [contextData.websiteId, contextData.selectedTab]);

  function loadSubmenu(cid, sectionid, wid) {
    setBlurLoader(true);
    let url = sectionid == 1 ? `/UserV2/GetSubMenuByClientUser?companyId=${cid}&sectionid=` + sectionid + `&websiteid=${wid}&aiconsent=1` : `/UserV2/GetSubMenuByClientUser?companyId=${cid}&sectionid=` + sectionid + `&websiteid=${wid}`;
    request(baseURL + url,
      METHODS.GET,
      {},
      {
        Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
      })
      .then((res) => {
        setBlurLoader(false);
        let submenuarray = [];
        if (res[0] && Array.isArray(res[0].subSections) && res[0].subSections.length > 0) {
          let menuDta = res[0].subSections;

          let first = 1;
          let firstobj = {};
          menuDta.forEach((dta) => {
            //this condition works for alerts and website channel only
            if (dta.title == "Insights" && wid > 0) {
              let subMenuDetails = menuItemsDetais.find(men => men.title === (dta.title + ' analytics'));
              let dorder = 1;
              if (subMenuDetails) {
                dorder = subMenuDetails.dispOrder;
              }
              let channelOverviewDetails = {
                actionParam: "",
                actionType: 1,
                dispIndex: dorder,
                id: 0,
                isActionPoint: true,
                isActive: true,
                isEnabled: true,
                title: `${res[0].title} analytics`,
                link: res[0].title == "Website" ? `/website` : `/alert/statistics`,
                refId: wid
              };
              submenuarray.push(channelOverviewDetails);
            }

            if (dta && Array.isArray(dta.actions) && dta.actions.length > 0) {
              //this is to hide and show Edit Website button.
              if (dta.actions.find(obj => obj.id === 4)) {
                setIsEditWebsiteEnabled(true)
              } else {
                setIsEditWebsiteEnabled(false)
              }
              dta.actions.forEach((data) => {
                //Id 53 is for feedback which is not needed in side menu
                if (data.title != "Workflow" && data.id !== 53 && (data.isEnabled === true || data.title == "Page analytics")) {
                  let subMenuDetails = menuItemsDetais.find(men => men.title === data.title);
                  if (first == 1) {
                    firstobj = subMenuDetails;
                    first = 0;
                  }
                  if (subMenuDetails && submenuarray.filter(e => e.title === subMenuDetails.title).length == 0) {
                    if (subMenuDetails) {
                      data.dispIndex = subMenuDetails.dispOrder;
                    }
                    let channeltitle = subMenuDetails.title;
                    let channelOverviewDetails = {
                      actionParam: "",
                      actionType: 1,
                      dispIndex: data.dispIndex,
                      id: data.id,
                      isActionPoint: true,
                      isActive: true,
                      isEnabled: true,
                      title: channeltitle,
                      link: subMenuDetails.link,
                      refId: wid
                    };
                    submenuarray.push(channelOverviewDetails);
                  }
                }
              });
            }
          });
          let wasdisabled = 0;
          if (submenuarray.length > 0) {
            let menu = contextData.menu;
            let newarr = menu.filter(function (item) {
              return item.id === sectionid;
            });
            let newobject = newarr[0];
            newobject.subMenu = submenuarray;
            if (newobject.isActive == false) {
              wasdisabled = 1;
            }

            let updatedArray = menu.map(obj => {
              if (obj.id == sectionid) {
                return newobject;
              }
              else {
                obj.subMenu = [];
              }
              return obj;
            });
            contextData.setMenu(updatedArray);
          }

          //First if -For 1 parent tab to other parent tab transfer
          //Second for children to other children transfer by change of website dropdown
          if (selectedMenu != localStorage.getItem("selectedTab") && (selectedMenu || wasdisabled == 1)) {
            if (localStorage.getItem("selectedTab") == "Website" && (firstobj.link == "/website/visitoranalytics-page" || firstobj.link == "/website/page-analytics-page")) {
              navigate("/website");
            }
            else if (localStorage.getItem("selectedTab") == "Website" && (!firstobj.link || wasdisabled == 1)) {
              navigate("/website");
            }
            else {
              navigate(firstobj.link);
            }
          }
          else if (submenuarray.length > 0 && !submenuarray.some(obj => obj.link.includes(pathname)) && !WebsiteChannelDetailURL.includes(pathname)) {
            if (submenuarray[0].link) {
              navigate(submenuarray[0].link);
            }
            else if (contextData.selectedTab === "Website" && submenuarray.some(obj => obj.id === 6)) {
              navigate("/editable-pages");
            }
            else if (contextData.selectedTab === "Website" && !submenuarray.some(obj => obj.id === 6)) {
              navigate("/website");
            }
          }
          else if (contextData.selectedTab == "Website" && submenuarray.length > 0 && ((pathname == "/") || (WebsiteChannelDetailURL.includes(pathname) && !submenuarray.some(obj => obj.link.includes("/website"))))) {
            //addition of last && condition to navigate to website tab from detail tab if website tab doesnt exists in selected website
            navigate("/website");
          }
          else if (pathname.includes("/website/visitoranalytics-page") && (!submenuarray.some(obj => obj.link.includes("/website/visitoranalytics-page")) && WebsiteChannelDetailURL.includes(pathname))) {
            //This condition is written to restrict the access of watchlist page if submenuarray doesn't have menu for visitor analytics. This case would occour if client is having essential package.
            // By adding this condition if submenuarray doesn't have visitor analytics link but the url contains /website/visitoranalytics-page/watchlist which is present in WebsiteChannelDetailURL, it will redirect to /website page.
            navigate("/website");
          }
        }
        else if (contextData.selectedTab == "Website" && localStorage.getItem("path").indexOf("website-marketing") === -1) {
          resetToRemoveChildFromMenuArray(sectionid);
          navigate("/website")
        }
      })
      .catch((err) => {
        setBlurLoader(false);
        console.log(err)
      })
  }

  function ClickTab(linkProps, t) {
    if (!linkProps) {
      setSelectedMenu("");
      let wid = contextData.websiteId;
      if (t === "Alerts") {
        //1stround
        setBlurLoader(true);
        let istabactive = contextData.menu.find(x => x.id === ChannelID["Alerts"]).isActive;
        if (istabactive) {
          request(baseURL + `/Intelligence/GetAlertsChannelWebsiteByClientUser?companyid=` + contextData.companyId,
            METHODS.GET,
            {},
            {
              Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
            })
            .then((res) => {
              if (res && res.result.length > 0) {
                wid = res.result[0].WebsiteId;
                // This will write values in local storage for ALERT CHANNEL when visited for first time
                if (!localStorage.getItem("alertWebsiteId")) {
                  const { WebsiteId, OTPClientId, AlertTrackingDate, WebsiteName, WebsiteUrl } = res.result[0];
                  localStorage.setItem(
                    "alertWebsiteId",
                    JSON.stringify({
                      "WebsiteList.WebsiteId": WebsiteId,
                      "ClietConfiguration.otpclientid": OTPClientId,
                      "WebsiteList.AlertTrackingDate": AlertTrackingDate,
                      "WebsiteList.WebsiteName": WebsiteName,
                      "WebsiteList.WebsiteUrl": WebsiteUrl,
                    })
                  );
                }
                else {
                  const selectedWebsite = JSON.parse(localStorage.getItem("alertWebsiteId"));
                  wid = selectedWebsite["WebsiteList.WebsiteId"];
                }


                localStorage.setItem("selectedTab", "Alerts")
                contextData.changeSelectedTab("Alerts");
                if (contextData.websiteId != wid) {

                  contextData.changeWebsiteId(wid);
                }

                const selectedWebsite = res.result.find(w => w.WebsiteId === wid)

                if (moment(selectedWebsite.AlertTrackingDate) > moment(contextData.startDate)) {

                  let alertDate = moment(selectedWebsite.AlertTrackingDate).add(1, "day");

                  const currentDate = moment();

                  const daysDifference = currentDate.diff(alertDate, 'days');

                  let endDate = moment(new Date).subtract(1, 'day').format("MMM DD, YYYY");

                  if (daysDifference >= 180) {

                    contextData.changeStartDate(moment(new Date).subtract(180, 'days').format("MMM DD, YYYY"))
                  }
                  else if (daysDifference >= 90) {

                    contextData.changeStartDate(moment(new Date).subtract(90, 'days').format("MMM DD, YYYY"))
                  }
                  else if (daysDifference >= 30) {

                    contextData.changeStartDate(moment(new Date).subtract(30, 'days').format("MMM DD, YYYY"))
                  }
                  else if (daysDifference >= 7) {

                    contextData.changeStartDate(moment(new Date).subtract(7, 'days').format("MMM DD, YYYY"))
                  }
                  else {

                    if (endDate == moment(alertDate).subtract(1, 'days').format("MMM DD, YYYY")) {

                      contextData.changeStartDate(moment(alertDate).subtract(1, 'days').format("MMM DD, YYYY"));
                    } else if (moment(alertDate).subtract(1, 'days').format("MMM DD, YYYY") == moment(new Date).format("MMM DD, YYYY")) {

                      contextData.changeStartDate(moment(alertDate).subtract(2, 'days').format("MMM DD, YYYY"));
                    } else {

                      contextData.changeStartDate(moment(alertDate).format("MMM DD, YYYY"));
                    }
                  }
                }
                else if (!localStorage.getItem("SelectedDatePicker_alert")) {
                  contextData.changeStartDate(`${moment().add(-180, 'days')}`)
                  contextData.changeEndDate(`${moment().add(-1, 'days')}`)
                }


              }
              else {
                resetToRemoveChildFromMenuArray(0);
                localStorage.removeItem("alertWebsiteId")
                localStorage.setItem("selectedTab", "Alerts")
                contextData.changeSelectedTab("Alerts");
                contextData.changeWebsiteId(0);
                navigate("/alert-marketing");
                setBlurLoader(false);
              }
            })
            .catch((err) => {
            })
        }
        else {
          resetToRemoveChildFromMenuArray(0);
          localStorage.removeItem("alertWebsiteId")
          localStorage.setItem("selectedTab", "Alerts")
          contextData.changeSelectedTab("Alerts");
          contextData.changeWebsiteId(0);
          navigate("/alert-marketing");
          setBlurLoader(false);
        }
      }
      else if (t === "Website") {
        setBlurLoader(true);
        let istabactive = contextData.menu.find(x => x.id === ChannelID["Website"]).isActive;
        if (istabactive) {
          request(baseURL + `/CommonV2/GetWebsiteChannelWebsiteByClientUser?CompanyId=${contextData.companyId}&UserId=${contextData.userId}`,
            METHODS.GET,
            {},
            {
              Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
            })
            .then((res) => {
              if (res && res.result.length > 0) {
                wid = res.result[0].WebsiteId;
                localStorage.setItem("selectedTab", "Website")
                if (contextData.websiteId != wid) {
                  // this condition is added to inject website id if its not setting from child page as above added code in useffect
                  const obj2 = JSON.parse(localStorage.getItem("WebsiteListWebsiteId"));
                  if (JSON.stringify(obj2) == "{}") {
                    localStorage.setItem("TempWId", "" + wid)
                  }
                  contextData.changeSelectedTab("Website");
                  contextData.changeWebsiteId(wid)
                  contextData.changeSelectedProfile({});
                }
                else {
                  setBlurLoader(false);
                  contextData.changeSelectedTab("Website");
                  //This condition  is written to handle 2 situation where 1. if a user logs in it first goes in if and then else which removes profileid
                  // without below written if condition so header shows value but internally profile is erased hence data are not loaded on card
                  //2. if there r 2 profiles in 1 website and user toggles between website and t&f so resetting profileid becomes must

                  if (JSON.parse(localStorage.getItem("WebsiteListWebsiteId"))["WebsiteProfiles.ProfileId"] != contextData.selectedProfile["WebsiteProfiles.ProfileId"]) {
                    contextData.changeSelectedProfile({});
                  }
                }
              }
              else {
                resetToRemoveChildFromMenuArray(0);
                localStorage.setItem("selectedTab", "Website")
                contextData.changeSelectedTab("Website");
                navigate("/website-marketing");
              }
            })
            .catch((err) => {
            })
        }
        else {
          resetToRemoveChildFromMenuArray(0);
          localStorage.setItem("selectedTab", "Website")
          contextData.changeSelectedTab("Website");
          navigate("/website-marketing");
        }
      }
      else if (t === "On-Air Streaming") {
        let istabactive = contextData.menu.find(x => x.id === ChannelID["Webcasting"]).isActive;
        resetToRemoveChildFromMenuArray(0);
        localStorage.setItem("selectedTab", "On-Air Streaming")
        contextData.changeWebsiteId(0);
        contextData.changeSelectedTab("On-Air Streaming");
        if (!istabactive) {
          navigate("/webcast-marketing");
        }
      }
      else if (t === "Tools") {
        setBlurLoader(true);
        let istabactive = contextData.menu.find(x => x.id === ChannelID["Tools"]).isActive;
        if (istabactive) {
          request(baseURL + `/Intelligence/GetToolsWebsiteForClientUser?companyid=` + contextData.companyId,
            METHODS.GET,
            {},
            {
              Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
            })
            .then((res) => {
              if (res && res.result.length > 0) {
                wid = res.result[0].WebsiteId;
                // This will write values in local storage for TOOLs CHANNEL when visited for first time
                if (!localStorage.getItem("SelectedEventId")) {
                  const { WebsiteId, OTPClientId, AlertTrackingDate, WebsiteName, WebsiteUrl } = res.result[0];
                  localStorage.setItem(
                    "SelectedEventId",
                    JSON.stringify({
                      "WebsiteList.WebsiteId": WebsiteId,
                      "ClietConfiguration.otpclientid": OTPClientId,
                      "WebsiteList.WebsiteName": WebsiteName
                    })
                  );
                }
                localStorage.setItem("selectedTab", "Tools")
                if (contextData.websiteId != wid) {
                  contextData.changeWebsiteId(wid);
                  contextData.changeSelectedTab("Tools");
                }
                else {
                  contextData.changeSelectedTab("Tools");
                }
              }
              else {
                resetToRemoveChildFromMenuArray(0);
                //localStorage.removeItem("SelectedEventId")
                localStorage.setItem("selectedTab", "Tools")
                contextData.changeSelectedTab("Tools");
                contextData.changeWebsiteId(0);
                navigate("/tools-marketing");
                setBlurLoader(false);
              }
            })
            .catch((err) => {
            })
        }
        else {
          resetToRemoveChildFromMenuArray(0)
          localStorage.setItem("selectedTab", "Tools")
          contextData.changeSelectedTab("Tools");
          contextData.changeWebsiteId(0);
          navigate("/tools-marketing");
          setBlurLoader(false);
        }
      }
      else if (t === "IR Tools Analytics") {
        let istabactive = contextData.menu.find(x => x.id === 15).isActive;
        if (istabactive) {
          request(baseURL + `/UserV2/GetFirstSubMenuByClientUserTF?companyid=` + contextData.companyId,
            METHODS.GET,
            {},
            {
              Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
            })
            .then((res) => {
              resetToRemoveChildFromMenuArray(0);
              if (Array.isArray(res) && res.length) {
                contextData.changeSelectedProfile({});
                contextData.changeSelectedTab("IR Tools Analytics");
                localStorage.setItem("selectedTab", "IR Tools Analytics")
                navigate("/Tnf/tools-overview");
              }
              else {
                contextData.changeSelectedProfile({});
                contextData.changeSelectedTab("IR Tools Analytics");
                localStorage.setItem("selectedTab", "IR Tools Analytics")
                navigate("/tf-marketing");
              }
            })
            .catch((err) => {
            })
        }
        else {
          resetToRemoveChildFromMenuArray(0)
          localStorage.setItem("selectedTab", "IR Tools Analytics")
          contextData.changeSelectedTab("IR Tools Analytics");
          contextData.changeWebsiteId(0);
          navigate("/tf-marketing");
          contextData.changeSelectedProfile({})
        }
      }
    }
    else if (t === "Reports") {
      resetToRemoveChildFromMenuArray(0);
      localStorage.setItem("selectedTab", "Reports")
      contextData.changeWebsiteId(0);
      contextData.changeSelectedTab("Reports");
    }
    else if (linkProps.title === "Service Centre") {
      resetToRemoveChildFromMenuArray(0);
      localStorage.setItem("selectedTab", "Service Centre")
      contextData.changeWebsiteId(0);
      contextData.changeSelectedTab("Service Centre");
    }
    else if (linkProps.title === "Copilot") {
      resetToRemoveChildFromMenuArray(0);
      localStorage.setItem("selectedTab", "Copilot")
      contextData.changeWebsiteId(0);
      contextData.changeSelectedTab("Copilot");
    }
  }

  const getUserName = () => {
    let userNm = contextData.firstName ? contextData.firstName : "";
    setUserName(userNm);
  };

  function generateUUID() { // Public Domain/MIT
    var d = new Date().getTime();//Timestamp
    var d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now() * 1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16;//random number between 0 and 16
      if (d > 0) {//Use timestamp until depleted
        r = (d + r) % 16 | 0;
        d = Math.floor(d / 16);
      } else {//Use microseconds since page-load if supported
        r = (d2 + r) % 16 | 0;
        d2 = Math.floor(d2 / 16);
      }
      return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
  }

  const onNaVItemClickevent = (event, title) => {
    localStorage.setItem("IsPageRefresh", "0");
    localStorage.removeItem("EventId");
    localStorage.removeItem("ManageusersEventId");
    localStorage.removeItem("EventCalendarWebsiteId");
    localStorage.removeItem("EventCalendarProfileId");
    localStorage.removeItem("AlertStatsWebsiteId");
    localStorage.removeItem("ArchiveAlertWebsiteId");
    localStorage.removeItem("ManualAlertWebsiteId");
    localStorage.removeItem("ManagesubscriptionWebsiteId");
    localStorage.removeItem("AlertSummaryWebsiteId");
    setShow(false)
  };

  const onNaVItemClick = (event, title) => {
    event.preventDefault();
    contextData.userEmail = decryptData(localStorage.getItem("email"), contextData.cryptoKey);
    let token = decryptData(localStorage.getItem("token"), contextData.cryptoKey);
    axios
      .get(
        `${process.env.REACT_APP_API_KEY}/WebsiteAnalytics/GetWebsitePlatformStatus`,
        {
          params: {
            cmsplatformid: JSON.parse(localStorage.getItem("WebsiteListWebsiteId"))["cmsplatformid"]
          },
          headers: {
            'Authorization': `Bearer ${token}`
          }
        },
      )
      .then(response => {
        if (response && response.data) {
          if (response.data.isPlatformDown) {
            setMessage(response.data.maintenanceMessage);
            setShowDefault(true);
          }
          else {
            localStorage.setItem("validatelogin", "0");
            localStorage.setItem("sessiontime", new Date());
            const token = generateUUID();
            contextData.changeToken(localStorage.setItem('IStoken', token));
            switch (title) {
              case "Edit Website":
                axios
                  .get(
                    `${process.env.REACT_APP_API_KEY}/WebsiteAnalytics/GetWebsiteSelfServeURL`,
                    {
                      params: {
                        websiteid: contextData.websiteId,
                        requesturl: window.location.href,
                        destinationurl: '',
                        siteindex: contextData.websiteId,
                        token: contextData.token,
                        companyId: contextData.companyId,
                        UserId: contextData.userId,
                        Email: contextData.userEmail,
                        iseditwebsite: 1
                      },
                      headers: {
                        'Authorization': `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`,
                        'X-CSRF-Token': `${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`
                      }
                    },
                  )
                  .then(response => {
                    if (response && response.data && response.data.url) {
                      if (response.data.cmsMethod == 0) {
                        window.location.href = response.data.url;
                      }
                      else {
                        var urldata = response.data.url.split("?token=");
                        if (urldata.length > 1) {
                          var f = document.createElement('form');
                          f.action = urldata[0];
                          f.method = 'POST';
                          var i = document.createElement('input');
                          i.type = 'hidden';
                          i.name = 'token';
                          i.value = urldata[1];
                          f.appendChild(i);
                          document.body.appendChild(f);
                          f.submit();
                        }
                      }
                    }
                  })
                  .catch(error => {
                  });
                event.preventDefault();
                break;
              case "Media Library":
                axios
                  .get(
                    `${process.env.REACT_APP_API_KEY}/WebsiteAnalytics/GetWebsiteSelfServeURL`,
                    {
                      params: {
                        websiteid: contextData.websiteId,
                        requesturl: window.location.href,
                        destinationurl: '',
                        siteindex: contextData.websiteId,
                        token: contextData.token,
                        urlType: 5,
                        companyId: contextData.companyId,
                        UserId: contextData.userId,
                        Email: contextData.userEmail
                      },
                      headers: {
                        'Authorization': `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`,
                        'X-CSRF-Token': `${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`
                      }
                    },
                  )
                  .then(response => {
                    if (response && response.data && response.data.url) {
                      window.location.href = response.data.url;
                    }
                  })
                  .catch(error => {
                  });
                event.preventDefault();
                break;
              case "Preview Website":
                axios
                  .get(
                    `${process.env.REACT_APP_API_KEY}/WebsiteAnalytics/GetWebsiteSelfServeURL`,
                    {
                      params: {
                        websiteid: contextData.websiteId,
                        requesturl: window.location.href,
                        destinationurl: '',
                        siteindex: contextData.websiteId,
                        token: contextData.token,
                        companyId: contextData.companyId,
                        UserId: contextData.userId,
                        Email: contextData.userEmail,
                        urlType: 2,
                      },
                      headers: {
                        'Authorization': `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`,
                        'X-CSRF-Token': `${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`
                      }
                    },
                  )
                  .then(response => {
                    if (response && response.data && response.data.url) {

                      if (response.data.cmsMethod == 0) {
                        window.location.href = response.data.url;
                      }
                      else {
                        var urldata = response.data.url.split("?token=");
                        if (urldata.length > 1) {
                          var f = document.createElement('form');
                          f.action = urldata[0];
                          f.method = 'POST';
                          var i = document.createElement('input');
                          i.type = 'hidden';
                          i.name = 'token';
                          i.value = urldata[1];
                          f.appendChild(i);
                          document.body.appendChild(f);
                          f.submit();
                        }
                      }
                    }
                  })
                  .catch(error => {
                  });
                event.preventDefault();
                break;
              case "Logout":
                onLogout();
                break;
              default:
                break
            }
          }
        }
      })
      .catch(error => {
      });
  };

  const onLogout = () => {
    localStorage.clear();
    sessionStorage.clear();
    navigate("/login");
  }

  const CollapsableNavItem = (props) => {

    const { eventKey, title, icon, isCustomIcon, children = null, active, isEnabled } = props;

    const defaultKey = pathname.indexOf(eventKey) !== -1
      || eventKey == "IR Tools Analytics"
      || pathname.includes("notification-page")
      || pathname.includes("editable-pages") ? eventKey : "";
    const disable = !active && !isEnabled ? "disabled" : "";
    const classes = "d-flex justify-content-between align-items-center " + disable;

    return (

      <Accordion id="mainsidebar" as={Nav.Item} defaultActiveKey={defaultKey}>
        <Accordion.Item eventKey={eventKey}>
          <Accordion.Button as={Nav.Link} className={classes}>
            <span>

              {isCustomIcon ?
                <svg className="sidenav-icon">
                  <use href={iconSprite + `#${icon}`} />
                </svg>
                :
                <span className="sidebar-icon">
                  <FontAwesomeIcon icon={icon} />
                </span>
              }

              <span className="sidebar-text">{title}</span>
            </span>
          </Accordion.Button>
          <Accordion.Body className="multi-level">
            <Nav className="flex-column">
              {children}
            </Nav>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    );
  };

  const NavItem = (props) => {
    const { title, link, external, target, icon, isCustomIcon, hasButtonClick, image, badgeText, badgeBg = "secondary", badgeColor = "primary", active, isEnabled } = props;
    const disable = !active && !isEnabled ? "disabled" : "";
    const classNames = badgeText ? "d-flex justify-content-start align-items-center justify-content-between " + disable : disable;
    let navItemClassName = ""

    if (link.toLowerCase() === pathname.toLowerCase()) {
      navItemClassName = "active";
    }
    else if ((pathname === "/website/cookie-analytics-page" && link === "/website")
      || (pathname === "/website/useranalytics-page" && link === "/website")) {
      navItemClassName = "active";
    }
    else if (pathname === "/website/visitoranalytics-page/watchlist" && title === "Visitor analytics") {
      navItemClassName = "active";
    }
    else if (pathname === "/webcast-marketing" && title === "On-Air Streaming") {
      navItemClassName = "active";
    }
    else if (pathname === "/tools-marketing" && title === "Tools") {
      navItemClassName = "active";
    }
    else if (pathname === "/alert-marketing" && title === "Alerts") {
      navItemClassName = "active";
    }
    else if (pathname === "/website-marketing" && title === "Website") {
      navItemClassName = "active";
    }
    else if (pathname === "/tf-marketing" && title === "IR Tools Analytics") {
      navItemClassName = "active";
    }
    const linkProps = link ? (external ? { href: link } : { as: Link, to: link, title: title }) : null;
    return (
      <Nav.Item className={navItemClassName} onClick={(e) => onNaVItemClickevent(e, title)} >
        {hasButtonClick ?
          <Nav.Link className={classNames} onClick={(e) => onNaVItemClick(e, title)}>
            <span>
              {icon ?
                <>
                  {isCustomIcon ?
                    <svg className="sidenav-icon">
                      <use href={iconSprite + `#${icon}`} />
                    </svg>
                    :
                    <span className="sidebar-icon">
                      <FontAwesomeIcon icon={icon} />
                    </span>
                  }
                </>
                : null}
              {image ? <Image src={image} width={20} height={20} className="sidebar-icon svg-icon" /> : null}

              <span className="sidebar-text">{title}</span>
            </span>
            {badgeText ? (
              <Badge pill bg={badgeBg} text={badgeColor} className="badge-md notification-count ms-2">{badgeText}</Badge>
            ) : null}
          </Nav.Link >
          : <Nav.Link {...linkProps} target={target} className={classNames} onClick={() => { ClickTab(linkProps, title) }}>
            <span>
              {icon ?
                <>
                  {isCustomIcon ?
                    <svg className="sidenav-icon">
                      <use href={iconSprite + `#${icon}`} />
                    </svg>
                    :
                    <span className="sidebar-icon">
                      <FontAwesomeIcon icon={icon} />
                    </span>
                  }
                </>
                : null}
              {image ? <Image src={image} width={20} height={20} className="sidebar-icon svg-icon" /> : null}

              <span className="sidebar-text">{title}</span>
            </span>
            {badgeText ? (
              <Badge pill bg={badgeBg} text={badgeColor} className="badge-md notification-count ms-2">{badgeText}</Badge>
            ) : null}
          </Nav.Link>
        }
      </Nav.Item>
    );
  };

  const renderCollapsableNavItem = (collapseNavItemData) => {
    var key = "";
    if (!collapseNavItemData.isActive) {
      if (collapseNavItemData.title === "Social Media") {
        key = (collapseNavItemData.title + "-marketing").toLowerCase().replace(' ', '');
      }
      else {
        key = (collapseNavItemData.title + "-marketing").toLowerCase();
      }
    }
    const collapseNavItemdetails = collapseNavItemData.isActive ? menuItemsDetais.find(dta => dta.title === collapseNavItemData.title && dta.Key != (collapseNavItemData.title + "-marketing").toLowerCase()) : menuItemsDetais.find(dta => dta.title === collapseNavItemData.title && dta.Key === key);
    var title = collapseNavItemData.title;
    if (collapseNavItemData.title === "Webcasting") {
      title = "On-Air Streaming";
    }
    else if (collapseNavItemData.title === "App") {
      title = "Mobile App";
    }
    else if (collapseNavItemData.title === "Alerts") {
      title = "Alerts";
    }
    if (collapseNavItemData.isActive && collapseNavItemdetails && Object.keys(collapseNavItemdetails).length > 0) {
      return (
        <CollapsableNavItem
          isEnabled={collapseNavItemData.isEnabled}
          active={collapseNavItemData.isActive}
          link={collapseNavItemdetails.link}
          eventKey={collapseNavItemdetails.Key}
          title={title}
          icon={collapseNavItemdetails.icon}
          id={collapseNavItemData.id}
          isCustomIcon={collapseNavItemdetails.isCustomIcon} >
          {
            collapseNavItemData.subMenu && collapseNavItemData.isActive && Array.isArray(collapseNavItemData.subMenu) && collapseNavItemData.subMenu.length > 0 &&
            collapseNavItemData.subMenu.map(collItem => {
              return renderNavItem(collItem);
            })
          }
        </CollapsableNavItem>
      );
    }
    else if (!collapseNavItemData.isActive && collapseNavItemdetails && Object.keys(collapseNavItemdetails).length > 0) {
      return (
        <NavItem
          isEnabled={collapseNavItemData.isEnabled}
          active={collapseNavItemData.isActive}
          title={collapseNavItemData.title}
          icon={collapseNavItemdetails.icon}
          link={collapseNavItemdetails.link}
          isCustomIcon={collapseNavItemdetails.isCustomIcon}
          hasButtonClick={collapseNavItemdetails.hasButtonClick}
          id={collapseNavItemdetails.id}
        />);
    }
  }

  const renderNavItem = (navItemData) => {
    let navItemdetails = navItemData.isActive ? menuItemsDetais.find(dta => dta.title === navItemData.title && dta.Key != (navItemData.title + "-marketing").toLowerCase()) : menuItemsDetais.find(dta => dta.title === navItemData.title && dta.Key === (navItemData.title + "-marketing").toLowerCase());

    if (navItemData?.toolid > 0) {
      navItemdetails = navItemData.isActive && menuItemsDetais.find(dta => dta.toolid === navItemData.toolid);
    }

    if (navItemdetails && Object.keys(navItemdetails).length > 0) {
      var title = navItemData.title;

      if (navItemData.title === "Alert Statistics") {
        title = "Alert analytics";
      }
      else if (navItemData.title === "Manage Manage Events") {
        title = "Event calendar";
      }
      else if (navItemData.title === "Social Media analytics") {
        title = "Social media analytics";
      }
      else if (navItemData.title === "Webcast Statistics") {
        title = "Stream Analytics";
      }
      else if (navItemData.title === "Webcasting") {
        title = "On-Air Streaming";
      }

      return (
        navItemData.title !== "Edit Website" ?
          <NavItem
            isEnabled={navItemData.isEnabled}
            active={navItemData.isActive}
            title={title}
            icon={navItemdetails.icon}
            link={navItemdetails.link}
            isCustomIcon={navItemdetails.isCustomIcon}
            hasButtonClick={navItemdetails.hasButtonClick}
          />
          : <></>
      );
    }
  };

  const renderSideBarItems = () => {
    if (contextData.menu && Array.isArray(contextData.menu) && contextData.menu.length > 0) {
      return contextData.menu.map(data => {
        const index = HiddenMenus.findIndex(x => x === data.id);
        if (index === -1) {
          if (data.subMenu && Array.isArray(data.subMenu) && data.subMenu.length > 0) {
            return renderCollapsableNavItem(data);
          }
          else {
            return renderNavItem(data);
          }
        }
      });
    }
  };

  const getCompanyDetails = () => {
    if (contextData.companies && Array.isArray(contextData.companies) && contextData.companies.length > 0) {
      if (contextData.companyId) {
        const selectedComapnyData = contextData.companies.find(dta => dta.companyId === contextData.companyId);
        if (selectedComapnyData && Object.keys(selectedComapnyData).length > 0)
          return <span>{selectedComapnyData.companyName}</span>;
      }
    }
  };

  const onUserIconClick = () => {
    setShow(false);
    navigate("/user-settings");
  };

  function resetToRemoveChildFromMenuArray(sectionid) {
    let menu = contextData.menu;
    let newobject = {};
    if (sectionid > 0) {
      let newarr = menu.filter(function (item) {
        return item.id === sectionid;
      });
      newobject = newarr[0];
      newobject.subMenu = [];
    }
    let updatedArray = menu.map(obj => {
      if (obj.id == sectionid && sectionid > 0) {
        return newobject;
      }
      else {
        obj.subMenu = [];
      }
      return obj;
    });
    contextData.setMenu(updatedArray);
  }

  const HandleEditWebsiteClick = (event) => {
    request(baseURL + `/WebsiteAnalytics/GetWebsiteSelfServeURL?websiteid=${contextData.websiteId}&requesturl=${encodeURIComponent(window.location.href)}&destinationurl=&siteindex=${contextData.websiteId}&token=${contextData.token}&companyId=${contextData.companyId}&userId=${contextData.userId}&Email=${contextData.userEmail}&iseditwebsite=1`,
      METHODS.GET,
      {},
      {
        'Authorization': `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`,
        'X-CSRF-Token': `${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`
      }
    )
      .then(response => {
        if (response && response.url) {
          if (response.cmsMethod == 0) {
            window.location.href = response.url;
          }
          else {
            var urldata = response.url.split("?token=");
            if (urldata.length > 1) {
              var f = document.createElement('form');
              f.action = urldata[0];
              f.method = 'POST';

              var i = document.createElement('input');
              i.type = 'hidden';
              i.name = 'token';
              i.value = urldata[1];
              f.appendChild(i);
              document.body.appendChild(f);
              f.submit();
            }
          }
        }
      })
      .catch(error => {
      });
    event.preventDefault();
  }

  return (
    <>
      {
        contextData.menu && Array.isArray(contextData.menu) && contextData.menu.length > 0 &&
        <>
          {blurLoader && <div id='blurdiv' className="blurbackground">
            <div className={`preloader loader-center-align`}>
              <Image className="loader-element animate__animated animate__jackInTheBox" src={LoaderImage} height={40} data-seconds={30} />
            </div>
          </div>}
          <Modal as={Modal.Dialog} size="xs" centered show={showDefault} onHide={handleClose}>
            <Modal.Header>
              <Modal.Title className="h5">Alert</Modal.Title>
              <Button variant="close" aria-label="Close" onClick={handleClose} />
            </Modal.Header>
            <Modal.Body>
              <p>{message}</p>
            </Modal.Body>
            <Modal.Footer>
              <ButtonComponent
                isIcon={false}
                isPrimary={true}
                btnText="OK"
                onClick={handleClose}
                btn_class="me-3" />
            </Modal.Footer>
          </Modal>
          <Navbar expand={false} collapseOnSelect className="navbar-theme-primary px-3 d-md-none">
            <Navbar.Brand as={Link} className="me-lg-5" to="/">
              <Image src={ConnectIDLogo} className="logo-avtar large-avatar" />
            </Navbar.Brand>
            <Navbar.Toggle as={Button} aria-controls="main-navbar" onClick={onCollapse}>
              <span className="navbar-toggler-icon pt-2" >
                <i className="fa fa-bars" aria-hidden="true"></i>
              </span>
            </Navbar.Toggle>
          </Navbar>
          <CSSTransition timeout={300} in={show} classNames="sidebar-transition">
            <SimpleBar className={`collapse ${showClass} sidebar d-md-block`} id="sidebardiv">
              <div className="sidebar-inner" id="mainbar">
                <div className="user-card d-flex d-md-none align-items-center justify-content-between justify-content-md-center">
                  <div className="d-flex align-items-center">
                    <div className="d-block">
                      <Button className="profile-btn" onClick={onUserIconClick}><Image src={ProfileIcon} /> </Button>
                      <span className="mb-0 ms-3 fw-bold profile-name mt-1">{t("header.WelcomeBackText")}, {getStringValue(userName)}</span>
                      <div className="mt-1 w-100 header-company-name">
                        {getCompanyDetails()}
                      </div>
                    </div>
                  </div>
                  <Nav.Link className="collapse-close d-md-none" onClick={onCollapse}>
                    <FontAwesomeIcon icon={faTimes} />
                  </Nav.Link>
                </div>
                <Nav className="flex-column pt-3 pt-md-0">
                  {renderSideBarItems()}
                  {isEditWebsiteEnabled &&
                    <div className="d-flex justify-content-between header-edit-btn-container">
                      <div className="d-flex align-items-center icon-nav-list svg-icon-nav-list">
                        <Button onClick={(e) => HandleEditWebsiteClick(e)} variant="" className="m-2 header-edit-btn">
                          <span>{"Edit Website"}</span>
                          <span className="right-icon">
                            <FontAwesomeIcon icon={faArrowRight} />
                          </span>
                        </Button>
                      </div>
                    </div>
                  }
                </Nav>
              </div>
            </SimpleBar>
          </CSSTransition>
        </>
      }
    </>
  );

});

export default SideBar;