import React, { useState, useEffect, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Row, Col, Image, Alert } from "@themesberg/react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";

import LoaderImage from "../assets/img/LoaderGraphic.svg";
import iconSprite from "../assets/Icon/svg-icn-sprite.svg";
import { baseURL, METHODS } from '../apis/utilities/constant';
import request from '../apis/request';
import { decryptData } from '../utils/crypto';
import { DataStatusMessage, WebcastHeaders } from "../utils/constant";

import AppContext from "../components/AppContext";
import CommonHeader from "../components/CommonHeader";
import WebcastCharts from "../components/Webcast/WebcastCharts";
import WebcastTabs from "../components/Webcast/WebcastTabs";
import WebcastSummary from "../components/Webcast/WebcastSummary";

export const WebcastStatisticsPage = () => {
  const [t, i18n] = useTranslation();
  const contextData = useContext(AppContext);

  const [selectedPresentationNew, setSelectedPresentationNew] = useState({});
  const [selectedEventNew, setSelectedEventNew] = useState({});

  const [filteredPresentation, setFilteredPresentation] = useState({})

  const [eventPresentationData, setEventPresentationData] = useState([]);
  const [eventData, setEventData] = useState([]);
  const [presentationData, setPresentationData] = useState([]);

  const [summary, setSummary] = useState({ transactionStatus: DataStatusMessage.Loading });
  const [liveChart, setLiveChart] = useState({ transactionStatus: DataStatusMessage.Loading });
  const [onDemandChart, setOnDemandChart] = useState({ transactionStatus: DataStatusMessage.Loading });
  const [viewerDevice, setViewerDevice] = useState({ transactionStatus: DataStatusMessage.Loading });
  const [viewerDomain, setViewerDomain] = useState({ transactionStatus: DataStatusMessage.Loading });

  const [masterViewers, setMasterViewers] = useState({ transactionStatus: DataStatusMessage.Loading });

  const [onPageRefresh, setOnPageRefresh] = useState(0);
  const [showLoader, setShowLoader] = useState(false);
  const [alertVisible, setAlertVisible] = useState(false);
  const [autoLoad, setAutoLoad] = useState(false);

  useEffect(() => {
    if (contextData.companyId) {
      getWebcastConfig(contextData.companyId);
    }
  }, [contextData.companyId]);

  useEffect(() => {
    if (eventPresentationData != null && eventPresentationData.length) {
      if (contextData.hasEvents === 1) {
        let presentationsData = eventPresentationData.filter((e) => {
          return e["Presentations.eventid"] === selectedEventNew["Events.id"];
        });
        getPresentationData(presentationsData);
      } else {
        getPresentationData(eventPresentationData);
      }
    } else {
      setSelectedPresentationNew({});
      getPresentationData([]);
    }
  }, [selectedEventNew, eventPresentationData]);

  useEffect(() => {
    if (Object.keys(filteredPresentation).length > 0) {
      setWebcastOnLoadData();
    }
  }, [filteredPresentation]);

  useEffect(() => {
    if (onPageRefresh !== 0) {
      setWebcastOnLoadData();
    }
  }, [onPageRefresh]);

  const getWebcastConfig = (companyId) => {
    if (companyId > 0) {
      request(
        baseURL + `/WebcastV2/GetWebcastConfiguration?companyId=` + companyId,
        METHODS.GET,
        {},
        {
          Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`,
          "content-type": "application/json",
        }
      )
        .then((res) => {
          if (res) {
            contextData.changeHasEvents(res.hasEvent);
            contextData.changeWebcastType(res.webcastType);
            contextData.changeWebcastClientId(res.webcastClientId);
            getEventPresentationData();
          }
        })
        .catch((err) => {
          console.log("error", err);
        })
    }
  }

  const setWebcastOnLoadData = () => {
    setSummary({ transactionStatus: DataStatusMessage.Loading });
    setLiveChart({ transactionStatus: DataStatusMessage.Loading });
    setOnDemandChart({ transactionStatus: DataStatusMessage.Loading });
    setViewerDomain({ transactionStatus: DataStatusMessage.Loading });
    setViewerDevice({ transactionStatus: DataStatusMessage.Loading });
    setMasterViewers({ transactionStatus: DataStatusMessage.Loading });
    if (contextData.hasEvents === 1) {
      request(
        baseURL +
        `/WebcastV2/GetWebcastVE?presentationId=${filteredPresentation["Presentations.id"]}&companyId=${contextData.companyId}&eventId=${filteredPresentation["Presentations.eventid"]}&viewerType=&domain=&viewer=`,
        METHODS.GET, {},
        {
          Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`,
          "content-type": "application/json",
        }
      )
        .then((res) => {
          if (res && res.webcastSummary) {
            setSummary({ data: res.webcastSummary, transactionStatus: DataStatusMessage.DataAvailable });
          } else {
            setSummary({ transactionStatus: DataStatusMessage.DataUnAvailable });
          }
          if (res && res.viewerDomainCount > 0) {
            setViewerDomain({ data: JSON.parse(res.viewerDomain), count: res.viewerDomainCount, header: WebcastHeaders.ViewerDomainTable, transactionStatus: DataStatusMessage.DataAvailable });
          } else {
            setViewerDomain({ transactionStatus: DataStatusMessage.DataUnAvailable });
          }
          if (res && res.viewersCount > 0) {
            setMasterViewers({ data: res.viewers, transactionStatus: DataStatusMessage.DataAvailable, veVersion: res.version, completedAt: "" })
          }
          else {
            setMasterViewers({ transactionStatus: DataStatusMessage.DataUnAvailable });
          }
        })
        .catch((err) => {
          setSummary({ transactionStatus: DataStatusMessage.DataUnAvailable });
          setViewerDomain({ transactionStatus: DataStatusMessage.DataUnAvailable });
          setMasterViewers({ transactionStatus: DataStatusMessage.DataUnAvailable });
          console.error("Error: " + err);
        })
    }
    else {
      request(baseURL + `/WebcastV2/GetWebcastIL?presentationId=${filteredPresentation["Presentations.id"]}&companyId=${contextData.companyId}&viewerType=&domain=&deviceType=&isGenerateReport=true`,
        METHODS.GET, {},
        {
          Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
        })
        .then((res) => {
          if (res && res.webcastSummary) {
            setSummary({ data: res.webcastSummary, transactionStatus: DataStatusMessage.DataAvailable });
          }
          else {
            setSummary({ transactionStatus: DataStatusMessage.DataUnAvailable });
          }
          if (res && res.liveChart.values.length > 0) {
            setLiveChart({ data: res.liveChart, transactionStatus: DataStatusMessage.DataAvailable });
          }
          else {
            setLiveChart({ transactionStatus: DataStatusMessage.DataUnAvailable });
          }
          if (res && res.onDemandChart.values.length > 0) {
            setOnDemandChart({ data: res.onDemandChart, transactionStatus: DataStatusMessage.DataAvailable });
          }
          else {
            setOnDemandChart({ transactionStatus: DataStatusMessage.DataUnAvailable });
          }
          if (res && res.viewerDomainCount > 0) {
            setViewerDomain({ data: JSON.parse(res.viewerDomain), count: res.viewerDomainCount, header: WebcastHeaders.ViewerDomainTable, transactionStatus: DataStatusMessage.DataAvailable });
          }
          else {
            setViewerDomain({ transactionStatus: DataStatusMessage.DataUnAvailable });
          }
          if (res && res.viewerDeviceCount > 0) {
            setViewerDevice({ data: JSON.parse(res.viewerDevice), count: res.viewerDeviceCount, transactionStatus: DataStatusMessage.DataAvailable });
          }
          else {
            setViewerDevice({ transactionStatus: DataStatusMessage.DataUnAvailable });
          }
          if (res && res.viewersCount > 0) {
            setMasterViewers({ data: res.viewers, transactionStatus: DataStatusMessage.DataAvailable, veVersion: res.version, completedAt: res.completedAt ? res.completedAt : "" })
          }
          else {
            setMasterViewers({ transactionStatus: DataStatusMessage.DataUnAvailable });
          }
        })
        .catch((err) => {
          setSummary({ transactionStatus: DataStatusMessage.DataUnAvailable });
          setLiveChart({ transactionStatus: DataStatusMessage.DataUnAvailable });
          setOnDemandChart({ transactionStatus: DataStatusMessage.DataUnAvailable });
          setViewerDomain({ transactionStatus: DataStatusMessage.DataUnAvailable });
          setViewerDevice({ transactionStatus: DataStatusMessage.DataUnAvailable });
          setMasterViewers({ transactionStatus: DataStatusMessage.DataUnAvailable });
          console.error("Error: " + err);
        })
    }
  };

  const getEventPresentationData = () => {
    request(baseURL + `/Common/GetWebcastPresentationByClientUser?CompanyId=${contextData.companyId}&UserId=${contextData.userId}`,
      METHODS.GET,
      {},
      {
        Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`,
        "content-type": "application/json",
      }
    )
      .then((res) => {
        let event = [];
        let presentation = [];
        if (res !== null) {
          event = res.Events.map((e) => ({ ["Events.title"]: e.EventTitle, ["Events.id"]: e.EventId }));
          presentation = res.EventPresentations.map((e) => ({
            ["Presentations.name"]: e.Name,
            ["Presentations.id"]: e.Slug,
            ["Presentations.clientslug"]: e.ClientId,
            ["Presentations.slug"]: e.Url,
            ["Presentations.eventid"]: e.EventId,
            ["Presentations.url"]: e.Url,
            ["Presentations.clientid"]: e.ClientId,
          }));
          if (event !== null && event.length) {
            setEventData(event);
            if (JSON.parse(localStorage.getItem("webcastEventId")) !== null && Object.keys(JSON.parse(localStorage.getItem("webcastEventId"))).length > 0) {
              onEventDropdownChange(JSON.parse(localStorage.getItem("webcastEventId")));
            } else {
              onEventDropdownChange(event[0]);
            }
          }

          if (presentation !== null && presentation.length) {
            setEventPresentationData(presentation);
            setAutoLoad(true);
          }
        }
      })
      .catch((err) => { });
  };

  const getPresentationData = (presentation) => {
    if (presentation != null && presentation.length) {
      setPresentationData(presentation);
      let selectedPresentation = presentation[0];
      if (JSON.parse(localStorage.getItem("presentationId")) !== null && Object.keys(JSON.parse(localStorage.getItem("presentationId"))).length > 0) {
        let presentationData = JSON.parse(localStorage.getItem("presentationId"));
        if (presentation.find(x => x["Presentations.id"] === presentationData["Presentations.id"])) {
          selectedPresentation = presentationData;
        }
      }
      onPresentationDropdownChange(selectedPresentation);
    }
  };

  const onEventDropdownChange = (data) => {
    setSelectedEventNew(data);
  };

  const onPresentationDropdownChange = (data) => {
    setSelectedPresentationNew(data);
  };

  const applyFilters = () => {
    if (contextData.hasEvents === 1) {
      localStorage.setItem("webcastEventId", JSON.stringify(selectedEventNew));
    }
    localStorage.setItem("presentationId", JSON.stringify(selectedPresentationNew));
    setFilteredPresentation(selectedPresentationNew);
  };

  const cancelFilters = () => {
    onEventDropdownChange(JSON.parse(localStorage.getItem("webcastEventId")));
    onPresentationDropdownChange(JSON.parse(localStorage.getItem("presentationId")));
  };

  const commonHeader = useMemo(
    () => (
      <>
        <CommonHeader
          channel="webcast"
          cancelFilters={cancelFilters}
          autoLoad={autoLoad}
          dataSource={[
            {
              name: "Dropdown1 - Events",
              data: eventData,
              selectedItem: selectedEventNew,
              isVisible: contextData && contextData.hasEvents == 1,
              label: t("header.filterEventLabel"),
              callback: onEventDropdownChange,
              displayText: "Events.title",
            },
            {
              name: "Dropdown2 - Presentation",
              data: presentationData,
              selectedItem: selectedPresentationNew,
              isVisible: true,
              label: t("header.filterPresentationLabel"),
              callback: onPresentationDropdownChange,
              displayText: "Presentations.name",
            },
          ]}
          title={<> {t("header.WebcastStatisticsTopHeader")} <span
            className="icon-nav-box webcast-sync-icon"
            onClick={(e) => {
              setOnPageRefresh(Math.random());
            }}
          >
            <svg className="icon-nav webcast-page-refresh" >
              <title>
                {t("header.Icon_Tooltip_Webcast_Sync")}
              </title>
              <use href={iconSprite + `#Sync`} />
            </svg>
          </span></>}
          showDate={false}
          applyFilters={applyFilters}
        />
      </>
    ),
    [selectedPresentationNew, selectedEventNew, autoLoad]
  );

  return (
    <>
      {showLoader && (
        <div id="blurdiv" className="blurbackground">
          <div className={`preloader loader-center-align`}>
            <Image
              className="loader-element animate__animated animate__jackInTheBox"
              src={LoaderImage}
              height={40}
              data-seconds={30}
            />
          </div>
        </div>
      )}
      {alertVisible && <div className="fade modal-backdrop show"></div>}
      <Alert className="toast-custom" variant="success" show={alertVisible}>
        <div className="d-flex justify-content-between">
          <div>
            <FontAwesomeIcon icon={faCheckCircle} className="me-2" />
            {t("header.PdfDeliveredToMailMessage")}
          </div>
        </div>
      </Alert>
      <Row>
        <Col xs={12} className="webcast-analytics">
          {Object.keys(selectedPresentationNew).length > 0 && commonHeader}
          {contextData && contextData.hasEvents == 0 && (
            <Row className="ps-3">
              <Col xs={12} sm={12}>
                <p>{t("header.webcastStatisticsMessage")}</p>
              </Col>
            </Row>
          )}
          <div className="d-inline-block p-3 pt-0 pe-0 w-100" id="webcastStatistics">
            {Object.keys(filteredPresentation).length > 0 &&
              <>
                <WebcastSummary summary={summary} />
                <WebcastCharts hasEvents={contextData.hasEvents} liveChart={liveChart} onDemandChart={onDemandChart} viewerDevice={viewerDevice} viewerDomain={viewerDomain} />
                <WebcastTabs hasEvents={contextData.hasEvents} companyId={contextData.companyId} cryptoKey={contextData.cryptoKey} webcastType={contextData.webcastType} filteredPresentation={filteredPresentation}
                  masterViewers={masterViewers} viewerDomain={viewerDomain} setShowLoader={setShowLoader} setAlertVisible={setAlertVisible} onPageRefresh={onPageRefresh} setOnPageRefresh={setOnPageRefresh} />
              </>
            }
          </div>
        </Col>
      </Row>
    </>
  );
};

export default WebcastStatisticsPage;
