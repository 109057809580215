import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from '@themesberg/react-bootstrap';

const VoltModal = ({
    open,
    title,
    handleClose,
    showCloseButton,
    showFooter,
    CancelText,
    ConfirmText,
    handleConfirm,
    children,
    subtitle,
    subtitle2,
    className
}) => {

    return (
        <Modal as={Modal.Dialog} size="xl" centered show={open} onHide={handleClose}>
            <Modal.Header>
                <Modal.Title className="h5">{title}
                    {subtitle && <><p className='mt-1 mb-2 word-break'>{subtitle}</p>   </>}
                    {subtitle2 && <p className='word-break mb-0'>{subtitle2}</p>}
                </Modal.Title>
                {showCloseButton &&
                    <Button variant="close" className="" aria-label="Close" onClick={handleClose} />
                }

            </Modal.Header>
            <Modal.Body className={className ? className : ""}>
                {children && children}
            </Modal.Body>
            {
                showFooter &&
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleConfirm && handleConfirm}>
                        {ConfirmText}
                    </Button>
                    <Button variant="link" className="text-gray ms-auto" onClick={handleClose}>
                        {CancelText}
                    </Button>
                </Modal.Footer>
            }
        </Modal>
    );
};

VoltModal.propTypes = {
    open: PropTypes.bool,
    showCloseButton: PropTypes.bool,
    showFooter: PropTypes.bool,
    title: PropTypes.string,
    CancelText: PropTypes.string,
    ConfirmText: PropTypes.string,
    handleConfirm: PropTypes.func,
    handleClose: PropTypes.func,
    children: PropTypes.func
};

VoltModal.defaultProps = {
    open: true,
    showCloseButton: false,
    showFooter: false,
    title: "",
    CancelText: "",
    ConfirmText: "",
    handleConfirm: null,
    handleClose: null,
    children: null
}

export default VoltModal;
