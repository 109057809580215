import React from "react";
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Nav, Pagination } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDoubleLeft, faAngleDoubleRight } from "@fortawesome/free-solid-svg-icons";

import { TABLE_TOTAL_ROWS, DEFAULT_PAGE_NO, convertToInternationalFormat } from "../AppConfig";

const PaginationComponent = ({
    withIcons,
    pageNo,
    items,
    onPageNoChange,
    showPaginationSummary,
    totalRecords,
    minimumRows
}) => {
    const [t, i18n] = useTranslation();

    const pageSize = 5;
    const totalData = totalRecords;
    const consideredMinimumRows = minimumRows !== undefined ? minimumRows : TABLE_TOTAL_ROWS
    let pageNum = localStorage.getItem("pageNo");
    const showingStart = (pageNum - 1) * consideredMinimumRows + 1;
    const showingEnd = Math.min(pageNum * consideredMinimumRows, totalData);
    const tableRows = minimumRows !== undefined ? minimumRows : TABLE_TOTAL_ROWS
    const totalPages = Math.ceil(totalData / tableRows);

    const Pagesvalue = (num) => {
        items = [];
        let i = 0;
        let n = num != 1 && num != totalPages ? num - 1 : (num === totalPages && totalPages > pageSize ? num - (pageSize - 2) : totalPages < pageSize ? 1 : (num === totalPages) ? num - 2 : num);
        for (let number = n; number >= 1 && number <= totalPages; number++) {
            const isItemActive = pageNo === number;
            items.push(
                <Pagination.Item active={isItemActive} key={number} onClick={() => onPaginationItemClick(number)}>
                    {number}
                </Pagination.Item>
            );

            i++;
            if (i === pageSize)
                break;
        };
        return items;
    }

    const onPaginationItemClick = (number) => {
        localStorage.setItem("pageNo", number)
        onPageNoChange && onPageNoChange(number);

    };

    const onPrevsItemClick = () => {
        localStorage.setItem("pageNo", 1)
        onPageNoChange && onPageNoChange(1);
    };

    const onNextItemClick = () => {
        localStorage.setItem("pageNo", totalPages)
        onPageNoChange && onPageNoChange(totalPages);
    };


    return (
        <>
            {
                totalData > 0 && totalRecords > 0 && (totalData > tableRows) &&
                <>
                    {
                        showPaginationSummary ?
                            <small className="fw-bold">
                                {t("header.paginationMessage1")} <b>{showingStart} - {showingEnd}</b>{t("header.paginationMessage2")} <b> {convertToInternationalFormat(totalData)}</b> {t("header.paginationMessage3")}
                            </small >
                            :
                            <small className="fw-bold">
                            </small>
                    }
                    <Nav>
                        <Pagination className="mb-2 mb-lg-0 mt-2 mt-lg-0">
                            <Pagination.Prev onClick={onPrevsItemClick} disabled={pageNo === DEFAULT_PAGE_NO ? true : false}>
                                {withIcons ? <FontAwesomeIcon icon={faAngleDoubleLeft} /> : "Previous"}
                            </Pagination.Prev>
                            {Pagesvalue(pageNo)}
                            <Pagination.Next onClick={onNextItemClick} disabled={pageNo === totalPages ? true : false}>
                                {withIcons ? <FontAwesomeIcon icon={faAngleDoubleRight} /> : "Next"}
                            </Pagination.Next>
                        </Pagination>
                    </Nav>
                </>
            }
        </>
    );
}

PaginationComponent.propTypes = {
    tableBodyData: PropTypes.array,
    withIcons: PropTypes.bool,
    pageNo: PropTypes.number,
    items: PropTypes.array,
    pages: PropTypes.array,
    onPageNoChange: PropTypes.func,
    onItemChange: PropTypes.func,
    showPaginationSummary: PropTypes.bool,
    totalRecords: PropTypes.number
};

PaginationComponent.defaultProps = {
    tableBodyData: [],
    withIcons: true,
    pageNo: DEFAULT_PAGE_NO,
    items: [],
    pages: [],
    onPageNoChange: null,
    onItemChange: null,
    showPaginationSummary: false,
    totalRecords: 25
}

export default PaginationComponent;