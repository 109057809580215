import { Form } from '@themesberg/react-bootstrap';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { object_equals } from "../AppConfig";
import Loader from '../components/VoltLoader';


const MultilineToggleComponent = (props) => {
  const [t, i18n] = useTranslation();
  const [toggleButtonsData, setToggleButtonsData] = useState([]);


  const onCheckBoxChange = (legendName) => {
    const currentOpacity = props.d3.selectAll("." + legendName).style("opacity");
    props.d3.selectAll("." + legendName).transition().style("opacity", currentOpacity == 1 ? 0 : 1)
    props.setd3(props.d3);
  };

  const renderTotals = (resultSet, error, pivotConfig, request) => {
    if (!resultSet.resultSet) {
      return <Loader show={false} />;
    }

    //const data = [...resultSet.resultSet.tablePivot(pivotConfig)];
    if (resultSet !== null) {
      const dataObject = [];
      const dataNew = resultSet.resultSet.loadResponses[0].annotation.measures;
      const dataValues = resultSet.resultSet.loadResponses[0].data[0];
      const objectKeys = Object.keys(dataNew);

      if (!dataNew !== undefined) {
        if (objectKeys.length > 0) {
          objectKeys.map((element, index) => {
            dataObject.push({ shortTitle: dataNew[element].shortTitle, keyName: element, value: dataValues[element] });
          })
          if (!object_equals(dataObject, toggleButtonsData)) {
            setToggleButtonsData(dataObject);
          }
          // if (!object_equals(dataObject, toggleButtonsData)) {
          //   setToggleButtonsData(dataObject);
          //   }
          // if(toggleButtonsData.length === 0){
          //   setToggleButtonsData(dataObject);
          // }

        }
      }
    }

    return <></>
  }

  const toggleButtons = (dataObject) => {
    const buttonsLayout = [];
    dataObject.map((element, index) => {
      let legendValue = element.shortTitle;
      legendValue = legendValue.replace(" ", "");
      buttonsLayout.push(<div className="d-inline-block switch-toggle-component-item">
        <div className="w-100 d-block widget-summary-metrics">{element.value ?? 0}</div>
        <div className="w-100 d-block">
          <label className={'widget-summary-metrics-label d-inline-block label-' + (index + 1)}>{element.shortTitle}</label>
          <Form className="d-inline-block ms-2">
            <Form.Check
              type="switch"
              defaultChecked="true"
              onChange={(e) => onCheckBoxChange(legendValue)}
              label=""
              id="switch1"
              htmlFor="switch1"
            />
          </Form>
        </div>
      </div>);
      //return <div>{element.shortTitle}</div>
    })

    return buttonsLayout;
  }

  const ExtraSummaryRenderer = (extraSummary) => {
    return <>
      {
        // <QueryRenderer
        //   query={extraSummary}
        //   cubejsApi={cubejsApi}
        //   resetResultSetOnChange={true}
        //   render={(props) => renderTotals({
        //     ...props,
        //     pivotConfig: {
        //       "x": [],
        //       "y": [
        //         "measures"
        //       ],
        //       "fillMissingDates": true,
        //       "joinDateRange": false
        //     }
        //   })}
        // />
      }
    </>
  };

  return (
    <>{ExtraSummaryRenderer(props.extraSummary)}{toggleButtonsData.length > 0 && toggleButtons(toggleButtonsData)}</>
  );
};

MultilineToggleComponent.propTypes = {
  d3: PropTypes.object,
  extraSummary: PropTypes.object,
};

MultilineToggleComponent.defaultProps = {
  d3: null,
  extraSummary: {},
};
export default MultilineToggleComponent;
