import React, { useEffect } from 'react';
import { Routes, Route, useLocation } from "react-router-dom";
import TagManager from "react-gtm-module";

import PublicRoute from './PublicRoute';
import PrivateRoute from './PrivateRoute';
import DashboardPage from '../pages/DashboardPage';
import FormPage from '../pages/FormsPage';
import WebsitePage from '../pages/WebsitePage';
import LoginPage from '../pages/LgnPage';
import UserSettingsPage from '../pages/UserSettingsPage';
import AlertStatisPage from '../pages/AlertStatsPage';
import AlertSummaryPage from '../pages/AlertSummaryPage';
import ArchiveAlertsPage from '../pages/ArchiveAlertsPage';
import ManualAlertsPage from '../pages/ManualAlertsPage';
import ManageSubscriptionsPage from '../pages/ManageSubscriptionsPage';
import EventCalenderPage from '../pages/EventCalenderPage';
import ReportsPage from '../pages/ReportsPage';
import WebcastStatisticsPage from '../pages/WebcastStatisticsPage';
import WebcastManageUserListPage from '../pages/WebcastManageUsersPage';
import { VisitorInsightsPage } from '../pages/VisitorInsightsPage';
import PagePerformanceAnalytics from '../pages/PageAnalyticsPage';
import VideoAnalyticsPage from '../pages/VideoAnalyticsPage';
import FeedbackAnalyticsPage from '../pages/FeedbackAnalyticsPage';
import EditablePages from '../pages/EditablePages';
import PreviewWebsitePage from '../pages/PreviewWebsitePage';
import AddEventPages from '../pages/AddEventPages';
import UploadDownloadPages from '../pages/UploadDownloadPages';
import SocialMediaPages from '../pages/SocialMediaPages';
import PublishMessagePages from '../pages/PublishMessagePages';
import AppStatisticsPages from '../pages/AppStatisticsPages';
import ManageDocumentsPages from '../pages/ManageDocumentsPages';
import AddDocumentsPages from '../pages/AddDocumentsPages';
import BannerImagePages from '../pages/BannerImagePages';
import ServiceCentrePages from '../pages/ServiceCentrePages';
import SettingsPages from '../pages/SettingsPages';
import AlertsMarketingPage from '../pages/MarketingPages/AlertsmarketingPage';
import AppMarketingPage from '../pages/MarketingPages/AppsMarketingPage';
import SocialMediaMarketingPages from '../pages/MarketingPages/SocialMediaPage';
import ToolsMarketingPages from '../pages/MarketingPages/ToolsMarketingPage';
import WebcastMarketingPages from '../pages/MarketingPages/WebcastMarketing';
import WebsiteMarketingPages from '../pages/MarketingPages/WebsiteMarketing';
import MyAccountPage from '../pages/MyAccountPage';
import MyDocumentsPage from '../pages/MyDocumentsPage';
import DigestSubscriptionsPage from '../pages/DigestSubscriptionsPage';
import UserAnalyticsPage from '../pages/UserAnalyticsPage';
import BackToCMSPages from '../pages/BackToCMSPage';
import SendAdHocAlertPage from '../pages/AdHocAlertPage';
import ClientPreview from '../pages/ClientPreview';
import NotificationPages from '../pages/NotificationPage';
import SearchPage from '../pages/SearchPage';
import CookieAnalyticsPage from '../pages/CookieAnalyticsPage';
import NoAccess from '../pages/MarketingPages/NoAccess';
import TnfSharePriceMiniChartPage from '../pages/TnfAnalytics/TnfSharePriceMiniChartPage';
import CorporateAlertServicePage from '../pages/TnfAnalytics/CorporateAlertServicePage';
import DividendCalculatorPage from '../pages/TnfAnalytics/DividendCalculatorPage';
import SSOLogin from '../pages/SSOLogin';
import TnfSharePriceCenterPage from '../pages/TnfAnalytics/TnfSharePriceCenterPage';
import { ToolsOverviewPage } from '../pages/TnfAnalytics/ToolsOverviewPage';
import TFMarketingPage from '../pages/MarketingPages/TFMarketingPage';
import { LargestShareholdersPage } from '../pages/TnfAnalytics/LargestShareholdersPage';
import RegulatoryNewsPage from '../pages/TnfAnalytics/RegulatoryNewsPage';
import OMXNewsPage from '../pages/TnfAnalytics/OMXNewsPage';
import { TFVisitorInsightsPage } from '../pages/TnfAnalytics/TFVisitorInsightsPage';
import ProcessClientData from '../pages/ProcessClientDataPage';
import TnFNotificationPage from '../pages/TnfAnalytics/NotificationPage'
import { SharePriceTablePage } from '../pages/TnfAnalytics/SharePriceTablePage';
import { HistoricalPriceLookupPage } from '../pages/TnfAnalytics/HistoricalPriceLookupPage';
import { TotalReturnCenterPage } from '../pages/TnfAnalytics/TotalReturnCenterPage';
import { SharePriceCalculatorPage } from '../pages/TnfAnalytics/SharePriceCalculatorPage';
import { ExchangeListPage } from '../pages/TnfAnalytics/ExchangeListPage';
import { OMXFilingsPage } from '../pages/TnfAnalytics/OMXFilingsPage';
import { CisionFilingsPage } from '../pages/TnfAnalytics/CisionFilingsPage';
import { DGAPFilingsPage } from '../pages/TnfAnalytics/DGAPFilingsPage';
import { SECFilingsPage } from '../pages/TnfAnalytics/SECFilingsPage';
import { USPressReleasePage } from '../pages/TnfAnalytics/USPressReleasePage';
import { ASXAnnouncementPage } from '../pages/TnfAnalytics/ASXAnnouncementPage';
import { ModularFinanceNewsPage } from '../pages/TnfAnalytics/ModularFinanceNewsPage';
import { STTNewsPage } from '../pages/TnfAnalytics/STTNewsPage';
import { ReportsAndPresentationsPage } from '../pages/TnfAnalytics/ReportsAndPresentationsPage';
import AICopilotPage from '../pages/AICopilotPage';
import CookiePolicyPage from '../pages/CookiePolicyPage';

const AppRoutes = ({ }) => {
    const location = useLocation();

    useEffect(() => {
        if (process.env.REACT_APP_ENV === "PROD") {
            setTimeout(() => {
                TagManager.dataLayer({
                    dataLayer: {
                        event: "page_view",
                        page: location.pathname,
                        title: document.title,
                    },
                });
            }, 100);
        }
    }, [location]);

    return (
        <Routes>
            {/* Public Routes */}
            <Route path="/processclientdata" element={<PublicRoute title="Process Client Data"><ProcessClientData /></PublicRoute>} />
            <Route path="/login/:token?" element={<PublicRoute title="Login"><LoginPage /></PublicRoute>} />
            <Route path="/bypasslogin/:token/:ip/:emailID/:provider" element={<PublicRoute title="SSO Login"><SSOLogin /></PublicRoute>} />
            <Route path="/preview-sso/:token" element={<PublicRoute title="Preview SSO"><ClientPreview /></PublicRoute>} />
            <Route path="/cookie-policy" element={<PublicRoute title="Cookie Policy"><CookiePolicyPage /></PublicRoute>} />

            {/* Private Routes */}
            <Route path="/loginsso.aspx" element={<PrivateRoute title="SSO Login"><BackToCMSPages /></PrivateRoute>} />
            <Route path="/dashbord" element={<PrivateRoute title="Dashboard"><DashboardPage /></PrivateRoute>} />
            <Route path="/form" element={<PrivateRoute title="Form"><FormPage /></PrivateRoute>} />
            <Route path="/" element={<PrivateRoute title="Website Analytics"><WebsitePage /></PrivateRoute>} />
            <Route path="/website" element={<PrivateRoute title="Website Analytics"><WebsitePage /></PrivateRoute>} />
            <Route path="/website/notification" element={<PrivateRoute title="Website Notifications"><NotificationPages /></PrivateRoute>} />
            <Route path="/website/visitoranalytics-page/:iswatchlist?" element={<PrivateRoute title="Website Visitor Analytics"><VisitorInsightsPage /></PrivateRoute>} />
            <Route path="/website/page-analytics-page" element={<PrivateRoute title="Page Analytics"><PagePerformanceAnalytics /></PrivateRoute>} />
            <Route path="/website/cookie-analytics-page" element={<PrivateRoute title="Cookies"><CookieAnalyticsPage /></PrivateRoute>} />
            <Route path="/website/useranalytics-page" element={<PrivateRoute title="Users"><UserAnalyticsPage /></PrivateRoute>} />
            <Route path="/user-settings" element={<PrivateRoute title="User Settings"><UserSettingsPage /></PrivateRoute>} />
            <Route path="/alert/statistics" element={<PrivateRoute title="Alert Statistics"><AlertStatisPage /></PrivateRoute>} />
            <Route path="/alert/summary" element={<PrivateRoute title="Alert Summary"><AlertSummaryPage /></PrivateRoute>} />
            <Route path="/alert/archieve" element={<PrivateRoute title="Archived Results"><ArchiveAlertsPage /></PrivateRoute>} />
            <Route path="/alert/adhocalert" element={<PrivateRoute title="Send Ad-Hoc Alerts"><SendAdHocAlertPage /></PrivateRoute>} />
            <Route path="/alert/manual-alerts" element={<PrivateRoute title="Manage Manual Alerts"><ManualAlertsPage /></PrivateRoute>} />
            <Route path="/alert/manage-subscription" element={<PrivateRoute title="Manage Subscribers"><ManageSubscriptionsPage /></PrivateRoute>} />
            <Route path="/tools/event-calender" element={<PrivateRoute title="Manage Events"><EventCalenderPage /></PrivateRoute>} />
            <Route path="/tools/add-event-pages" element={<PrivateRoute title="Add Events"><AddEventPages /></PrivateRoute>} />
            <Route path="/reports" element={<PrivateRoute title="Reports"><ReportsPage /></PrivateRoute>} />
            <Route path="/webcast/manage-statistics" element={<PrivateRoute title="Stream Statistics"><WebcastStatisticsPage /></PrivateRoute>} />
            <Route path="/webcast/manage-users" element={<PrivateRoute title="Manage Users"><WebcastManageUserListPage /></PrivateRoute>} />
            <Route path="/video-analytics-page" element={<PrivateRoute title="Video Analytics"><VideoAnalyticsPage /></PrivateRoute>} />
            <Route path="/feedback-analytics-page" element={<PrivateRoute title="Feedback Analytics"><FeedbackAnalyticsPage /></PrivateRoute>} />
            <Route path="/editable-pages" element={<PrivateRoute title="Editable Pages"><EditablePages /></PrivateRoute>} />
            <Route path="/preview-website-pages" element={<PrivateRoute title="Preview Website"><PreviewWebsitePage /></PrivateRoute>} />
            <Route path="/upload-download-pages" element={<PrivateRoute title="Upload Download"><UploadDownloadPages /></PrivateRoute>} />
            <Route path="/social-media-pages" element={<PrivateRoute title="Social Media"><SocialMediaPages /></PrivateRoute>} />
            <Route path="/publish-message-pages" element={<PrivateRoute title="Publish Message"><PublishMessagePages /></PrivateRoute>} />
            <Route path="/app-statistics-pages" element={<PrivateRoute title="App Statistics"><AppStatisticsPages /></PrivateRoute>} />
            <Route path="/manage-documents-pages" element={<PrivateRoute title="Manage Documents"><ManageDocumentsPages /></PrivateRoute>} />
            <Route path="/add-documents-pages" element={<PrivateRoute title="Add Documents"><AddDocumentsPages /></PrivateRoute>} />
            <Route path="/banner-image-pages" element={<PrivateRoute title="Banner Image"><BannerImagePages /></PrivateRoute>} />
            <Route path="/service-centre-pages" element={<PrivateRoute title="Service Centre"><ServiceCentrePages /></PrivateRoute>} />
            <Route path="/settings-pages" element={<PrivateRoute title="Settings"><SettingsPages /></PrivateRoute>} />
            <Route path="/settings/myaccount-page" element={<PrivateRoute title="My Account"><MyAccountPage /></PrivateRoute>} />
            <Route path="/settings/mydocuments-page" element={<PrivateRoute title="My Documents"><MyDocumentsPage /></PrivateRoute>} />
            <Route path="/settings/digestsubscriptions-page" element={<PrivateRoute title="Digest Subscriptions"><DigestSubscriptionsPage /></PrivateRoute>} />
            <Route path="/tools-marketing" element={<PrivateRoute title="Tools Marketing"><ToolsMarketingPages /></PrivateRoute>} />
            <Route path="/webcast-marketing" element={<PrivateRoute title="On-Air Streaming Marketing"><WebcastMarketingPages /></PrivateRoute>} />
            <Route path="/website-marketing" element={<PrivateRoute title="Website Marketing"><WebsiteMarketingPages /></PrivateRoute>} />
            <Route path="/alert-marketing" element={<PrivateRoute title="Alert Marketing"><AlertsMarketingPage /></PrivateRoute>} />
            <Route path="/app-marketing" element={<PrivateRoute title="App Marketing"><AppMarketingPage /></PrivateRoute>} />
            <Route path="/sm-marketing" element={<PrivateRoute title="Social Media Marketing"><SocialMediaMarketingPages /></PrivateRoute>} />
            <Route path="/tf-marketing" element={<PrivateRoute title="IR Tools Marketing"><TFMarketingPage /></PrivateRoute>} />
            <Route path="/notification-page" element={<PrivateRoute title="Website Notifications"><NotificationPages /></PrivateRoute>} />
            <Route path="/website/search-page" element={<PrivateRoute title="Search"><SearchPage /></PrivateRoute>} />
            <Route path="/no-access" element={<PrivateRoute title="No Access"><NoAccess /></PrivateRoute>} />
            <Route path="/Tnf/tools-overview" element={<PrivateRoute title="Tools Overview"><ToolsOverviewPage /></PrivateRoute>} />
            <Route path="/Tnf/share-price-center-page" element={<PrivateRoute title="Share Price Center"><TnfSharePriceCenterPage /></PrivateRoute>} />
            <Route path="/Tnf/share-price-mini-chart-page" element={<PrivateRoute title="Share Price Mini Chart"><TnfSharePriceMiniChartPage /></PrivateRoute>} />
            <Route path="/Tnf/corporate-alert-service-page" element={<PrivateRoute title="Corporate Alert Service"><CorporateAlertServicePage /></PrivateRoute>} />
            <Route path="/Tnf/dividend-calculator-page" element={<PrivateRoute title="Dividend Calculator"><DividendCalculatorPage /></PrivateRoute>} />
            <Route path="/Tnf/largest-shareholders-page" element={<PrivateRoute title="Largest Shareholders"><LargestShareholdersPage /></PrivateRoute>} />
            <Route path="/Tnf/regulatory-news-page" element={<PrivateRoute title="Regulatory News"><RegulatoryNewsPage /></PrivateRoute>} />
            <Route path="/Tnf/OMX-news-page" element={<PrivateRoute title="OMX News"><OMXNewsPage /></PrivateRoute>} />
            <Route path="/Tnf/share-price-table-page" element={<PrivateRoute title="Share Price Table"><SharePriceTablePage /></PrivateRoute>} />
            <Route path="/Tnf/historical-price-lookup-page" element={<PrivateRoute title="Historical Price Lookup"><HistoricalPriceLookupPage /></PrivateRoute>} />
            <Route path="/Tnf/total-return-center-page" element={<PrivateRoute title="Total Return Center"><TotalReturnCenterPage /></PrivateRoute>} />
            <Route path="/Tnf/share-price-calculator-page" element={<PrivateRoute title="Share Price Calculator"><SharePriceCalculatorPage /></PrivateRoute>} />
            <Route path="/Tnf/exchange-list-page" element={<PrivateRoute title="Exchange List"><ExchangeListPage /></PrivateRoute>} />
            <Route path="/Tnf/omx-filings-page" element={<PrivateRoute title="OMX Filings"><OMXFilingsPage /></PrivateRoute>} />
            <Route path="/Tnf/cision-filings-page" element={<PrivateRoute title="Cision Filings"><CisionFilingsPage /></PrivateRoute>} />
            <Route path="/Tnf/dgap-filings-page" element={<PrivateRoute title="DGAP Filings"><DGAPFilingsPage /></PrivateRoute>} />
            <Route path="/Tnf/sec-filings-page" element={<PrivateRoute title="SEC Filings"><SECFilingsPage /></PrivateRoute>} />
            <Route path="/Tnf/us-press-release-page" element={<PrivateRoute title="US Press Release"><USPressReleasePage /></PrivateRoute>} />
            <Route path="/Tnf/asx-announcement-page" element={<PrivateRoute title="ASX Announcement"><ASXAnnouncementPage /></PrivateRoute>} />
            <Route path="/Tnf/modular-finance-news-page" element={<PrivateRoute title="Modular Finance News"><ModularFinanceNewsPage /></PrivateRoute>} />
            <Route path="/Tnf/stt-news-page" element={<PrivateRoute title="STT News"><STTNewsPage /></PrivateRoute>} />
            <Route path="/Tnf/reports-and-presentations-page" element={<PrivateRoute title="Reports And Presentations"><ReportsAndPresentationsPage /></PrivateRoute>} />
            <Route path="/Tnf/Insights" element={<PrivateRoute title="IR Tools Visitor Analytics"><TFVisitorInsightsPage /></PrivateRoute>} />
            <Route path="/Tnf/notifications" element={<PrivateRoute title="IR Tools Notifications"><TnFNotificationPage /></PrivateRoute>} />
            <Route path="/Tnf/ai-copilot-page" element={<PrivateRoute title="AI Copilot"><AICopilotPage /></PrivateRoute>} />

            {/* Catch-all Route */}
            <Route path="*" element={<LoginPage />} />
        </Routes>

    );
}

export default AppRoutes;