import React, { useContext, useState, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle, faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { Row, Col, Card, Form, Button, Tooltip, Alert } from '@themesberg/react-bootstrap';

import { ReactComponent as UploadSvg } from '../assets/Icon/upload.svg';
import request from '../apis/request';
import { baseURL, METHODS } from "../apis/utilities/constant";
import { ERROR_TIMEOUT } from "../AppConfig";
import { decryptData } from "../utils/crypto";
import { convertBase64 } from "../utils/common";

import AppContext from '../components/AppContext';

const Dropzone = (props) => {
    const [t, i18n] = useTranslation();
    const contextData = useContext(AppContext);

    const {
        setIsTableRefresh,
        setBlurLoader
    } = props;

    const [files, setFiles] = React.useState([]);
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [showAlertSuccess, setShowAlertSuccess] = useState(false);
    const [alertMessageSuccess, setAlertMessageSuccess] = useState("");
    const [bytesInStream, setBytesInStream] = useState([]);

    const onCloseAlert = () => {
        setShowAlert(false);
        setShowAlertSuccess(false);
        setAlertMessage("");
    };

    const { getRootProps, getInputProps } = useDropzone({

        accept: ['.jpg', '.jpeg', '.png', '.pdf', '.docx', '.doc', '.pptx', '.ppt', '.zip', '.txt'].join(','),
        maxSize: 1000000,// 1MB

        onDrop: useCallback((acceptedFiles, rejectedFiles) => {
            if (acceptedFiles && acceptedFiles.length > 0) {
                acceptedFiles.forEach((file) => {
                    const reader = new FileReader()

                    reader.onabort = () => console.log('file reading was aborted')
                    reader.onerror = () => console.log('file reading has failed')
                    reader.onload = () => {
                        // Do whatever you want with the file contents
                        const binaryStr = reader.result
                        setBlurLoader(true);

                        const cid = parseInt(contextData.companyId);
                        const uid = parseInt(contextData.userId);
                        let currentFile = file;
                        const filename = currentFile.name.split('.').slice(0, -1).join('.');
                        const ContentType = currentFile.type;

                        // Do whatever you want with the file contents
                        setBytesInStream(reader.result)
                        var a = binaryStr.buffer;
                        let baseRequest = convertBase64(JSON.stringify({ companyId: cid, docTitle: currentFile.name.split(".")[0], fileName: filename, docDescription: currentFile.name.split(".")[0], docContentType: ContentType, FileContent: binaryStr }))
                        request(baseURL + `/CommonV2/SaveDocumentByClientUser/?encodeStr=` + baseRequest,
                            METHODS.POST,
                            {},
                            {
                                Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
                            }
                        )
                            .then((response) => {
                                setIsTableRefresh(true);
                                setBlurLoader(false);
                                setShowAlertSuccess(true);
                                setTimeout(() => {
                                    setShowAlertSuccess(false);
                                }, ERROR_TIMEOUT)
                                setShowAlert(false);
                                if (rejectedFiles && rejectedFiles.length > 0) {
                                    let errText = t("header.SettingsMyDocUploadSuccessfulWithErr");
                                    setAlertMessageSuccess(errText.replace("failedUploadCount", rejectedFiles.length));
                                }
                                else {
                                    setAlertMessageSuccess(t("header.MyDocumentsUploadSuccessful"))
                                }
                            })
                            .catch(() => {
                                setBlurLoader(false);
                            })
                    }
                    reader.readAsArrayBuffer(file)
                })
            }
            else if (rejectedFiles && rejectedFiles.length > 0) {
                setShowAlert(true);
                setTimeout(() => {
                    setShowAlert(false);
                }, ERROR_TIMEOUT)
                setShowAlertSuccess(false);
                let errText = t("header.MyDocumentsUploadErrorneous");
                setAlertMessage(errText.replace("failedUploadCount", rejectedFiles.length));
            }
        }, [])
    });

    const DropzoneFile = (props) => {
        const { path, preview } = props;
        return (
            <Col xs={3} className="dropzone-preview">
                <Card.Text className="dropzone-filename">
                    {preview}
                </Card.Text>
            </Col>
        );
    };

    return (
        <>
            <Form {...getRootProps({ className: "dropzone rounded d-flex align-items-center justify-content-center mb-2" })}>
                <Form.Control {...getInputProps()} />
                <div className="dz-default dz-message text-center">
                    <p className="dz-button mt-4 mb-3">
                        <span className="file-upload-icon">
                            <UploadSvg title={t("header.Icon_Tooltip_Upload")} />
                        </span>
                    </p>
                    <p className="dz-button mb-1">{t("header.dropZoneMessage1")}</p>
                    <p className="dz-button mb-3 file-upload-info">
                        <div
                            dangerouslySetInnerHTML={{
                                __html: t("header.dropZoneMessage2", {
                                    interpolation: { escapeValue: false },
                                }),
                            }}
                        ></div>
                        <Tooltip>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: t("header.dropZoneMessage2", {
                                        interpolation: { escapeValue: false },
                                    }),
                                }}
                            ></div>
                        </Tooltip>
                    </p>
                </div>
            </Form>
            <Row className="dropzone-files">
                {files.map((file) => (
                    <>
                        <DropzoneFile key={file.path} {...file} />
                    </>
                ))}
            </Row>
            <Alert
                variant="danger"
                show={showAlert}
                onClose={() => onCloseAlert("danger")}
            >
                <div className="d-flex justify-content-between">
                    <div>
                        <FontAwesomeIcon icon={faTimesCircle} className="me-2" />
                        {alertMessage}
                    </div>
                    <Button variant="close" size="xs" onClick={() => onCloseAlert("danger")} />
                </div>
            </Alert>
            <Alert
                variant="success"
                show={showAlertSuccess}
                onClose={() => onCloseAlert("success")}
            >
                <div className="d-flex justify-content-between">
                    <div>
                        <FontAwesomeIcon icon={faCheckCircle} className="me-2" />
                        {alertMessageSuccess}
                    </div>
                    <Button variant="close" size="xs" onClick={() => onCloseAlert("success")} />
                </div>
            </Alert>
        </>
    );
};

export default Dropzone;