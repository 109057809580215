import React, { useState, useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Row, Col, Dropdown, ButtonGroup } from "@themesberg/react-bootstrap";

import iconSprite from "../../assets/Icon/svg-icn-sprite.svg";
import { baseURL, METHODS } from '../../apis/utilities/constant';
import request, { requestPDF } from '../../apis/request';
import { decryptData } from '../../utils/crypto';
import { DataStatusMessage, WebcastHeaders } from "../../utils/constant";

import CommonMessageComponent from "../CommonMessageComponent";
import DataTableRenderer from "../DataTableRenderer";
import WebcastFilters from "./WebcastFilters";
import Search from "../../components/Search";

const WebcastViewers = ({ hasEvents, companyId, cryptoKey, filteredPresentation, viewerDomain, setShowLoader, setAlertVisible, masterViewers, appliedViewerFilter, setAppliedViewerFilter }) => {
    const [t] = useTranslation();

    const [searchData, setSearchData] = useState("");

    const [viewerFilter, setViewerFilter] = useState({
        viewerType: { value: "", label: "All" },
        deviceType: { value: "", label: "All" },
        domain: { value: "", label: "All" },
        viewer: { value: "unique", label: "Unique" }
    });

    const [openDropdown, setOpenDropdown] = useState(null); // Tracks open dropdown
    const dropdownRef = useRef(null);

    const [viewers, setViewers] = useState({ transactionStatus: DataStatusMessage.Loading });

    useEffect(() => {
        if (masterViewers.transactionStatus !== DataStatusMessage.Loading) {
            if (masterViewers.transactionStatus === DataStatusMessage.DataAvailable) {
                let data = [...masterViewers.data];
                let header = WebcastHeaders.ViewersV1Table;
                if (appliedViewerFilter.viewerType && appliedViewerFilter.viewerType.value !== "") {
                    data = data.filter(p => p.viewersType && p.viewersType.toLowerCase() === appliedViewerFilter.viewerType.value.toLowerCase());
                }
                if (appliedViewerFilter.deviceType && appliedViewerFilter.deviceType.value !== "") {
                    data = data.filter(p => p.deviceType && p.deviceType.toLowerCase() === appliedViewerFilter.deviceType.value.toLowerCase());
                }
                if (appliedViewerFilter.domain && appliedViewerFilter.domain.value !== "") {
                    data = data.filter(p => p.email && p.email.includes('@') && p.email.split('@')[1].toLowerCase() === appliedViewerFilter.domain.value.toLowerCase());
                }
                if (appliedViewerFilter.viewer && appliedViewerFilter.viewer.value !== "" && masterViewers.veVersion !== "v2") {
                    data = processViewerV1Data(data);
                }
                else {
                    data = processViewerV2Data(data);
                    header = WebcastHeaders.ViewersTable;
                }

                if (searchData !== "") {
                    data = data.filter(x => x.nameCompany.toLowerCase().includes(searchData.toLocaleLowerCase()) || x.email.toLowerCase().includes(searchData.toLocaleLowerCase()));
                }
                setViewers({ header: header, transactionStatus: DataStatusMessage.DataAvailable, data: data, count: data.length });
            }
            else {
                setViewers({ transactionStatus: DataStatusMessage.DataUnAvailable });
            }
        }
        else if (masterViewers.transactionStatus === DataStatusMessage.Loading) {

            if (searchData !== "") {
                setSearchData("");
            }
            let filters = { viewerType: { value: "", label: "All" }, deviceType: { value: "", label: "All" }, domain: { value: "", label: "All" }, viewer: { value: "unique", label: "Unique" } };
            if (JSON.stringify(viewerFilter) !== JSON.stringify(filters)) {
                setViewerFilter(filters);
            }
            if (JSON.stringify(appliedViewerFilter) !== JSON.stringify(filters)) {
                setAppliedViewerFilter(filters);
            }
            setViewers({ transactionStatus: DataStatusMessage.Loading });
        }
        // masterViewers is used here to refresh Questions data when we applied search 
        // or filter along with autorefresh on 
    }, [searchData, appliedViewerFilter, masterViewers]);

    const processViewerV1Data = (data) => {
        data = data
            .reduce((acc, wv) => {
                const key = wv.name + wv.email;
                if (!acc[key]) {
                    acc[key] = wv;
                } else {
                    if (new Date(acc[key].viewedDate) < new Date(wv.viewedDate)) {
                        acc[key] = wv;
                    }
                }
                return acc;
            }, {});

        return Object.values(data);
    }

    const processViewerV2Data = (data) => {
        const statusTypes = [{ value: "live", label: "Live" }, { value: "ondemand", label: "On demand" }, { value: "registeredbutnotviewed", label: "Pre live" }]
        const result = [];
        const dataMap = {};
        data.forEach(item => {
            const key = `${item.email}`;
            let status = statusTypes.find(x => item.status.toLowerCase() == x.value.toLowerCase());
            if (!dataMap[key]) {
                dataMap[key] = { nameCompany: item.nameCompany, name: item.name, email: item.email, viewersType: item.viewersType, deviceType: item.deviceType, totalViewerDuration: item.totalViewerDuration, children: [] };
            }
            let childItem = { status: status ? status.label : item.status, viewedDate: item.viewedDate, connectTime: item.connectTime, disconnectTime: item.disconnectTime, viewDuration: item.viewDuration }
            dataMap[key].children.push(childItem);
            dataMap[key].showChildData = false;
        });

        for (const key in dataMap) {
            result.push(dataMap[key]);
        }
        return result;
    };

    const setDowloadDropdownMenu = () => {
        let ddList = ["Excel", "PDF"];
        return ddList.map((item) => {
            return (
                <Dropdown.Item
                    className="dropdown-item-download"
                    onClick={() => downloadGrid(item)}
                >
                    {item}
                </Dropdown.Item>
            );
        });
    };

    const downloadGrid = (item) => {
        if (item === "Excel") {
            downloadExcel();
        } else {
            downloadPDF();
        }
    };

    const downloadExcel = async () => {
        setShowLoader(true);
        let url = "";
        if (hasEvents === 1) {
            url = baseURL + `/WebcastV2/GetWebcastVE?presentationId=${filteredPresentation["Presentations.id"]}&companyId=${companyId}&eventId=${filteredPresentation["Presentations.eventid"]}&viewerType=${viewerFilter.viewerType.value}&domain=${viewerFilter.domain.value}&viewer=${viewerFilter.viewer.value}&isExport=true&companyName=${JSON.parse(decryptData(localStorage.getItem("selectedCompanyData"), cryptoKey))["companyName"]}`;
        }
        else {
            url = baseURL + `/WebcastV2/GetWebcastIL?presentationId=${filteredPresentation["Presentations.id"]}&companyId=${companyId}&viewerType=${viewerFilter.viewerType.value}&domain=${viewerFilter.domain.value}&deviceType=${viewerFilter.deviceType.value}&isGenerateReport=true&isExport=true&companyName=${JSON.parse(decryptData(localStorage.getItem("selectedCompanyData"), cryptoKey))["companyName"]}`
        }

        request(`${url}`,
            METHODS.GET,
            {},
            {
                Authorization: `Bearer ${decryptData(localStorage.getItem("token"), cryptoKey)}`,
            })
            .then((res) => {
                if (res) {
                    let fetchDataModified = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${res}`;
                    let a = document.createElement("a");
                    a.href = fetchDataModified;
                    a.download = 'Webcast_Statistics_' + filteredPresentation["Presentations.name"] + '.xlsx';
                    a.click();
                }
                setShowLoader(false);
            })
            .catch((err) => {
                setShowLoader(false);
                console.error('Error generating Excel:', err);
            })
    };

    const downloadPDF = async () => {
        setShowLoader(true);
        let url = "";
        let fileName = "";
        let filters = "";
        let obj = {};
        url = baseURL + (hasEvents === 1 ? `/WebcastV2/GetWebcastVE?presentationId=${filteredPresentation["Presentations.id"]}&companyId=${companyId}&eventId=${filteredPresentation["Presentations.eventid"]}&viewerType=${viewerFilter.viewerType.value}&domain=${viewerFilter.domain.value}&viewer=${viewerFilter.viewer.value}` : `/WebcastV2/GetWebcastIL?presentationId=${filteredPresentation["Presentations.id"]}&companyId=${companyId}&viewerType=${viewerFilter.viewerType.value}&domain=${viewerFilter.domain.value}&deviceType=${viewerFilter.deviceType.value}&isGenerateReport=true`);
        fileName = "Stream_Statistics.pdf";
        filters = (searchData !== "" && searchData !== null) ? "nameCompany|contains|" + searchData + "||email|contains|" + searchData : "";
        obj = {
            Title: "Stream statistics for " + filteredPresentation["Presentations.name"],
            SubTitle: "Client: " + JSON.parse(decryptData(localStorage.getItem("selectedCompanyData"), cryptoKey))["companyName"],
            PageName: "Stream viewers",
            CountTitle: "<span class='total-record-count'>{{count}}</span> Total Viewers",
            Filters: filters,
            FilterContent: getFilterContent(),
            Url: url,
            Token: decryptData(localStorage.getItem("token"), cryptoKey),
            FileName: fileName,
            Email: decryptData(localStorage.getItem("email"), cryptoKey),
            Name: decryptData(localStorage.getItem("firstName"), cryptoKey),
            Count: 0,
            ClientName: "",
            TableHeaders: (hasEvents === 1 && masterViewers.veVersion === "v1") ? WebcastHeaders.ViewersV1Table : WebcastHeaders.ViewersTable
        }

        requestPDF(`${process.env.REACT_APP_API_KEY}/CommonV2/GeneratePdf`,
            METHODS.POST,
            obj,
            {
                Authorization: `Bearer ${decryptData(localStorage.getItem("token"), cryptoKey)}`
            })
            .then((res) => {
                const contentType = res.headers['content-type'];
                if (res.status === 200) {
                    if (contentType?.includes('application/pdf')) {
                        const blob = new Blob([res.data], { type: 'application/pdf' });
                        const url = window.URL.createObjectURL(blob);
                        const a = document.createElement('a');
                        a.href = url;
                        a.download = fileName;
                        document.body.appendChild(a);
                        a.click();
                        window.URL.revokeObjectURL(url);
                    }
                    else {
                        setAlertVisible(true);
                        setTimeout(() => {
                            setAlertVisible(false);
                        }, 3000);
                    }
                    setShowLoader(false);
                }
                else {
                    setShowLoader(false);
                    console.error('Error generating PDF.');
                }
            })
            .catch((err) => {
                setShowLoader(false);
                console.error('Error generating PDF:', err);
            })
    };

    const getFilterContent = () => {
        let filters = "";
        if (viewerFilter.viewerType.value !== "" || viewerFilter.deviceType.value !== "" || viewerFilter.domain.value !== "" || viewerFilter.viewer.value !== "") {
            if (viewerFilter.viewerType.value !== "") {
                filters += "(Viewer Type : " + viewerFilter.viewerType.label + ") "
            }
            if (viewerFilter.domain.value !== "") {
                filters = filters === "" ? "" : (filters + "And ");
                filters += "(Domain : " + viewerFilter.domain.label + ") "
            }
            if (viewerFilter.viewer.value !== "" && masterViewers.veVersion == "v1") {
                filters = filters === "" ? "" : (filters + "And ");
                filters += "(Viewer : " + viewerFilter.viewer.label + ") "
            }
            if (viewerFilter.deviceType.value !== "") {
                filters = filters === "" ? "" : (filters + "And ");
                filters += "(Device Type : " + viewerFilter.deviceType.label + ")"
            }
            filters = filters !== "" ? `Filter : ` + filters : "";
        }
        return filters;
    }

    const handleDropdownToggle = (dropdownName) => {
        setOpenDropdown((prev) => (prev === dropdownName ? null : dropdownName));
    };

    const createFilter = useMemo(
        () => (
            <WebcastFilters
                applyFilters={() => applyViewerFilter()}
                cancelFilter={() => cancelViewerFilter()}
                dataSource={[
                    {
                        name: "Viewer Type",
                        data: [
                            { value: "", label: "All" },
                            { value: "live", label: "Live Viewers" },
                            { value: "ondemand", label: "On Demand Viewers" },
                            { value: "registeredbutnotviewed", label: "Pre live" }
                        ],
                        selectedItem: viewerFilter.viewerType,
                        isVisible: true,
                        label: t("header.WebcastFilterLabelViewerType"),
                        callback: (x) => OnChangeViewerFilter(x, "viewerType"),
                        displayText: "label",
                    },
                    hasEvents
                        ? ""
                        : {
                            name: "Device Type",
                            data: [
                                { value: "", label: "All" },
                                { value: "desktop", label: "Desktop" },
                                { value: "tablet", label: "Tablet" },
                                { value: "mobile", label: "Mobile" },
                            ],
                            selectedItem: viewerFilter.deviceType,
                            isVisible: true,
                            label: t("header.WebcastFilterLabelDevice"),
                            callback: (x) => OnChangeViewerFilter(x, "deviceType"),
                            displayText: "label",
                        },
                    {
                        name: "Domains",
                        data: [{ value: "", label: "All" }].concat(
                            viewerDomain?.data?.map((x) => {
                                return { value: x.title, label: x.title };
                            })
                        ),
                        selectedItem: viewerFilter.domain,
                        isVisible: true,
                        label: t("header.WebcastFilterLabelDomain"),
                        callback: (x) => OnChangeViewerFilter(x, "domain"),
                        displayText: "label",
                    },
                    masterViewers.veVersion === "v1"
                        ? {
                            name: "Viewers",
                            data: [
                                { value: "", label: "All" },
                                { value: "unique", label: "Unique" },
                            ],
                            selectedItem: viewerFilter.viewer,
                            isVisible: true,
                            label: t("header.WebcastPDFFilterLabelViewers"),
                            callback: (x) => OnChangeViewerFilter(x, "viewer"),
                            displayText: "label",
                        }
                        : "",
                ]}
            ></WebcastFilters>
        ),
        [viewerFilter, viewerDomain, masterViewers.veVersion]
    );

    const OnChangeViewerFilter = (data, filterfor) => {
        let filters = { ...viewerFilter, [filterfor]: data };
        setViewerFilter(filters);
    };

    const applyViewerFilter = () => {
        setAppliedViewerFilter(viewerFilter);
    };

    const cancelViewerFilter = () => {
        let filter = { viewerType: { value: "", label: "All" }, deviceType: { value: "", label: "All" }, domain: { value: "", label: "All" }, viewer: { value: "unique", label: "Unique" } };
        setViewerFilter(filter);
        setAppliedViewerFilter(filter);
    };

    const renderSearch = (count) => {
        return (
            <div
                className={
                    count > 0
                        ? "d-inline-block float-end webcast-question-search-container"
                        : "d-inline-block float-end webcast-question-search-container custom-disable"
                }
            >
                <Search
                    setsearchData={setSearchData}
                    searchData={searchData}
                    isAutoComplete={false}
                    type={"server"}
                ></Search>
            </div>
        );
    };
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setOpenDropdown(null);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <>
            <Row className="webcast-questions-card-nav mb-1">
                <Col
                    md={12}
                    className={
                        localStorage.getItem("isDemoClient")
                            ? "custom-disable text-end"
                            : "text-end"
                    }
                >
                    <div  className="d-inline-block mt-2 me-3 webcast-table-nav float-end">
                        <div className="icon-nav-list svg-icon-nav-list">
                            <div onClick={() => handleDropdownToggle("filter")}
                                className={
                                    (masterViewers.data && masterViewers.data.length > 0)
                                        ? "icon-nav-box hide-mobile webcast-download-nav"
                                        : "icon-nav-box hide-mobile disabled"
                                }
                            >
                                {createFilter}
                            </div>
                            <span ref={dropdownRef}
                                className={
                                    viewers.data && viewers.data.length > 0
                                        ? "icon-nav-box hide-mobile webcast-download-nav"
                                        : "icon-nav-box hide-mobile disabled"
                                }
                            >
                                <Dropdown as={ButtonGroup} show={openDropdown === "download"}
                                    onToggle={(isOpen) => handleDropdownToggle("download")}>
                                    <Dropdown.Toggle
                                        split
                                        className="card-dropdown-btn p-0 border-0 pdf-download-btn"
                                    >
                                        <svg className="icon-nav">
                                            <title>
                                                {t(
                                                    "header.Icon_Tooltip_Download"
                                                )}
                                            </title>
                                            <use
                                                href={
                                                    iconSprite + `#Download`
                                                }
                                            />
                                        </svg>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        {setDowloadDropdownMenu()}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </span>
                        </div>
                    </div>
                    {renderSearch(masterViewers?.data?.length)}
                </Col>

                {hasEvents !== 1 &&
                    (masterViewers.completedAt && masterViewers?.completedAt !== "") && (
                        <div className="d-inline-block mt-0 me-3 webcast-table-nav float-end">
                            <span
                                className="fw-bold last-update"
                                id="lastUpdate"
                            >
                                {t("header.lastUpdated")} :{" "}
                                {masterViewers.completedAt}
                            </span>
                        </div>
                    )}
            </Row>

            {viewers.transactionStatus ===
                DataStatusMessage.DataAvailable && (
                    <DataTableRenderer
                        childHeader={
                            WebcastHeaders.ViewersChildTable
                        }
                        header={viewers.header}
                        dataSet={viewers.data}
                        transactionStatus={
                            viewers.transactionStatus
                        }
                        customAttributes={{
                            displayRowsPerPage: 10,
                            showCount: true,
                            summaryCount: viewers.count,
                            summaryText: t(
                                "header.webcastViewerMessage"
                            ),
                            tooltipText: t("header.webcastViewersTooltipText"),
                            showPagination: true,
                        }}
                    ></DataTableRenderer>
                )}
            {viewers.transactionStatus !==
                DataStatusMessage.DataAvailable && (
                    <CommonMessageComponent
                        transactionStatus={
                            viewers.transactionStatus
                        }
                    ></CommonMessageComponent>
                )}
        </>
    )
}


export default WebcastViewers;