import moment from "moment";
import { NULL_VALUE_MESSAGE_STRING, channelOverviewDetails } from "../AppConfig";
import { menuItemsDetais } from "../utils/sidebar";
import demoClientData_180 from './demoClientData_180.json';
import demoClientData_90 from "./demoClientData_90.json";
import demoClientData_30 from "./demoClientData_30.json";
import demoClientData_7 from "./demoClientData_7.json";

const LessThan30Threshold = [1, 10, 50, 100, 500];

const moreThan30Threshold = [1, 50, 250, 500, 1000];

const orMoreText = "or more";

export const SessionTimeoutWarningTime = 5 * 1000 * 60; //This is related to warning for Idal timeout

export const SessionTimeoutPopupTime = 5 * 1000 * 60;   //If no activity found within 5 min then logout

export const AUTO_LOGOUT_KEY = "auto_logout";

export const SessionTimeoutPopupTimeinMinutes = 5;   // 02  minutes

//Always change this 2 prop to test session related task
export const ForcedLogoutTokenExpireMinitues = 60; //60 minutes for forced logout.
export const IntimationBeforeForcedLogoutMinitues = 2;

export const OTPExpireTime = 30;   // 30  minutes

export const headerTitle = "Insights by IDX";

export const generalErrorMessage = "There was an error processing your request. Please try again later.";

export const getStringValue = (value) => {
    let valueToReturn = value ? value : NULL_VALUE_MESSAGE_STRING;
    return valueToReturn;
};

export const capitalizeName = (name) => {
    if (!name) return '';
    return name
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join(' ');
};

export const getInitials = (name) => {
    let rgx = new RegExp(/(\p{L})\p{L}*/u, 'gu');

    let initials = [...name.matchAll(rgx)] || [];

    if (initials.length === 1) {
        initials = [initials[0][1], name[1] || ''];
    } else {
        initials = [
            initials.shift()?.[1] || '',
            initials.pop()?.[1] || ''
        ];
    }

    return initials.join('').toUpperCase();
};

export const getThresholdDataOnDuration = (duration) => {
    let thresholdList = duration < 30 ? LessThan30Threshold : moreThan30Threshold;
    let thresholdData = [];
    if (thresholdList.length > 0) {
        thresholdList.forEach((data) => {
            let dta = {};
            dta.key = data;
            dta.label = `${data} ${orMoreText}`;
            thresholdData.push(dta);

        });
        const customData = {
            key: "custom",
            label: "Custom"
        };

        thresholdData.push(customData);
    }
    return thresholdData;
};

export const getThresholdData = () => {
    let thresholdList = [1, 10, 50];
    let thresholdData = [];
    if (thresholdList.length > 0) {
        thresholdList.forEach((data) => {
            let dta = {};
            dta.key = data;
            dta.label = `${data} ${orMoreText}`;
            thresholdData.push(dta);

        });
        const customData = {
            key: "custom",
            label: "Custom"
        };
        thresholdData.push(customData);
    }
    return thresholdData;
};

export const getWebsiteURL = () => {
    let profiles = JSON.parse(localStorage.getItem("cookieProfileData"));
    return profiles[0];
}

export const ValidateTextInputs = (orgData) => {
    var containsBadInput = false;
    if (orgData != undefined) {
        var filteredData = orgData.replace(/\<|\>|\"|\%|\$|\;|\(|\)|\&|\+|\'|\~|\`|--/g, "");
        containsBadInput = (orgData == filteredData) ? false : true;
    }
    return containsBadInput;
}

export const getMenusAndSubMenuDetails = (menuDetails) => {
    let menuDta = menuDetails;
    if (menuDta && menuDta.length > 0) {
        menuDta.forEach((dta, index) => {
            dta.subMenu = [];
            let hasOverviewDetails = channelOverviewDetails.indexOf(dta.title);
            if (hasOverviewDetails > -1 && (dta.title === "Website" && dta.subSections && dta.subSections.find(x => x.title === "Insights")) !== undefined) {
                const subMenuDetails = menuItemsDetais.find(men => men.title === (dta.title + ' analytics'));
                let dorder = 1;
                if (subMenuDetails) {
                    dorder = subMenuDetails.dispOrder;
                }
                let channelOverviewDetails = {
                    actionParam: "",
                    actionType: 1,
                    dispIndex: dorder,
                    id: 0,
                    isActionPoint: true,
                    isActive: true,
                    isEnabled: true,
                    title: `${dta.title} analytics`
                };
            }
            if (dta.subSections && Array.isArray(dta.subSections) && dta.subSections.length > 0) {
                dta.subSections.forEach(subSectionDta => subSectionDta.actions.forEach((actionDta) => {
                    const subMenuDetails = menuItemsDetais.find(men => men.title === actionDta.title);
                    if (subMenuDetails) {
                        actionDta.dispIndex = subMenuDetails.dispOrder;
                    }
                    dta.subMenu.push(actionDta);
                }));
            }
        });
    }
    let settingDta = {
        dispIndex: 11,
        id: 0,
        isActionPoint: false,
        isActive: true,
        subMenu: [{ actionParam: "", actionType: 1, dispIndex: 0, id: 1, isActionPoint: true, isActive: true, isEnabled: true, title: "My account" },
        { actionParam: "", actionType: 1, dispIndex: 1, id: 2, isActionPoint: true, isActive: true, isEnabled: true, title: "My documents" },
        { actionParam: "", actionType: 1, dispIndex: 2, id: 3, isActionPoint: true, isActive: true, isEnabled: true, title: "Digest subscriptions" }],
        subSections: [{ id: 162, title: "Settings My account", actions: Array(0), isActive: true, dispIndex: 1 }],
        title: "Settings"
    };
    menuDta.push(settingDta);
    let logout = {
        dispIndex: 12,
        id: 0,
        isActionPoint: true,
        isActive: true,
        subMenu: [],
        title: "Logout"
    };
    menuDta.push(logout);
    menuDta.forEach(subSectionDta => {
        subSectionDta.subMenu.sort(function (a, b) {
            return (a.dispIndex - b.dispIndex);
        });
    });

    return menuDta;
};

export const checkValueExixtsInObject = (objectName, value) => {
    let valueExixts = Object.keys(objectName).some((k) => {
        return objectName[k] === value;
    });
    return valueExixts;

};

//Start with 0 index
//Ex: JAN = 0
export const PRINT_MONTH_NAME = (monthNo, isPrintShortName) => {
    let format = isPrintShortName ? "MMM" : "MMMM";
    return moment().month(monthNo).format(format);
}

export const monthName = (monthNo) => {
    if (monthNo === 1)
        return "Jan";
    else if (monthNo === 2) {
        return "Feb";
    }
    else if (monthNo === 3) {
        return "Mar";
    }
    else if (monthNo === 4) {
        return "Apr";
    }
    else if (monthNo === 5) {
        return "May";
    }
    else if (monthNo === 6) {
        return "Jun";
    }
    else if (monthNo === 7) {
        return "Jul";
    }
    else if (monthNo === 8) {
        return "Aug";
    }
    else if (monthNo === 9) {
        return "Sep";
    }
    else if (monthNo === 10) {
        return "Oct";
    }
    else if (monthNo === 11) {
        return "Nov";
    }
    else if (monthNo === 12) {
        return "Dec";
    }
    else {
        return "Unknown";
    }
};

export const repplaceData = (data) => {
    if (data === "Visitor_Pageviews.directpageviewcount")
        return "Direct";
    else if (data === "Visitor_Pageviews.referralpageviewcount") {
        return "Referral";
    }
    else if (data === "Visitor_Pageviews.organicpageviewcount") {
        return "Organic";
    }
    else if (data === "Visitor_Pageviews.socialpageviewcount") {
        return "Social";
    }
    else if (data === "Visitor_Pageviews.paidpageviewcount") {
        return "Paid";
    }
    else if (data === "Visitor_Pageviews.emailpageviewcount") {
        return "Email";
    }
    else {
        return data;
    }
};

export const repplaceHeaderData = (data) => {
    if (data === "measures")
        return "Traffic Sources";
    else if (data === "value") {
        return "Page Views";
    }
    else {
        return data;
    }
};

export const msToHMS = (ms) => {
    // 1- Convert to seconds:
    var seconds = ((ms % 60000) / 1000).toFixed(0);
    // 2- Extract hours:
    var hours = parseInt(seconds / 3600); // 3,600 seconds in 1 hour
    // seconds = seconds % 3600; // seconds remaining after extracting hours
    // 3- Extract minutes:
    var minutes = Math.floor(ms / 60000);// parseInt( seconds / 60 ); // 60 seconds in 1 minute
    // 4- Keep only seconds not extracted to minutes:
    seconds = ((ms % 60000) / 1000).toFixed(0);

    return convertIntoTwoDigit(roundNumber(hours)) + ":" + convertIntoTwoDigit(minutes) + ":" + convertIntoTwoDigit((seconds));
}

function convertIntoTwoDigit(num) {
    if (num < 10) {
        return '0' + num
    }
    return '' + num
}

export const roundNumber = (num) => {
    if (num === 0)
        return 0;
    else
        return Math.round(num * 10) / 10;
};

function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
}

export const calculateCommaSepValues = (data) => {
    if (data === null || data === undefined || data.length === 0)
        return 0;
    else {
        const myArr = data.split(",");
        if (myArr[0] === ",") {
            myArr.splice(0, 1);
        }
        return myArr.filter(onlyUnique).length;
    }
};

export const countryCodes = [
    {
        "code": "AFG",
        "name": "Afghanistan",
        "eu": false
    },
    {
        "code": "ALA",
        "name": "Åland Islands",
        "eu": false
    },
    {
        "code": "ALB",
        "name": "Albania",
        "eu": false
    },
    {
        "code": "DZA",
        "name": "Algeria",
        "eu": false
    },
    {
        "code": "ASM",
        "name": "American Samoa",
        "eu": false
    },
    {
        "code": "AND",
        "name": "Andorra",
        "eu": false
    },
    {
        "code": "AGO",
        "name": "Angola",
        "eu": false
    },
    {
        "code": "AIA",
        "name": "Anguilla",
        "eu": false
    },
    {
        "code": "ATA",
        "name": "Antarctica",
        "eu": false
    },
    {
        "code": "ATG",
        "name": "Antigua and Barbuda",
        "eu": false
    },
    {
        "code": "ARG",
        "name": "Argentina",
        "eu": false
    },
    {
        "code": "ARM",
        "name": "Armenia",
        "eu": false
    },
    {
        "code": "ABW",
        "name": "Aruba",
        "eu": false
    },
    {
        "code": "AUS",
        "name": "Australia",
        "eu": false
    },
    {
        "code": "AUT",
        "name": "Austria",
        "eu": true
    },
    {
        "code": "AZE",
        "name": "Azerbaijan",
        "eu": false
    },
    {
        "code": "BHS",
        "name": "Bahamas",
        "eu": false
    },
    {
        "code": "BHR",
        "name": "Bahrain",
        "eu": false
    },
    {
        "code": "BGD",
        "name": "Bangladesh",
        "eu": false
    },
    {
        "code": "BRB",
        "name": "Barbados",
        "eu": false
    },
    {
        "code": "BLR",
        "name": "Belarus",
        "eu": false
    },
    {
        "code": "BEL",
        "name": "Belgium",
        "eu": true
    },
    {
        "code": "BLZ",
        "name": "Belize",
        "eu": false
    },
    {
        "code": "BEN",
        "name": "Benin",
        "eu": false
    },
    {
        "code": "BMU",
        "name": "Bermuda",
        "eu": false
    },
    {
        "code": "BTN",
        "name": "Bhutan",
        "eu": false
    },
    {
        "code": "BOL",
        "name": "Bolivia",
        "eu": false
    },
    {
        "code": "BES",
        "name": "Bonaire, Sint Eustatius and Saba",
        "eu": false
    },
    {
        "code": "BIH",
        "name": "Bosnia and Herzegovina",
        "eu": false
    },
    {
        "code": "BWA",
        "name": "Botswana",
        "eu": false
    },
    {
        "code": "BVT",
        "name": "Bouvet Island",
        "eu": false
    },
    {
        "code": "BRA",
        "name": "Brazil",
        "eu": false
    },
    {
        "code": "IOT",
        "name": "British Indian Ocean Territory",
        "eu": false
    },
    {
        "code": "BRN",
        "name": "Brunei Darussalam",
        "eu": false
    },
    {
        "code": "BGR",
        "name": "Bulgaria",
        "eu": true
    },
    {
        "code": "BFA",
        "name": "Burkina Faso",
        "eu": false
    },
    {
        "code": "BDI",
        "name": "Burundi",
        "eu": false
    },
    {
        "code": "KHM",
        "name": "Cambodia",
        "eu": false
    },
    {
        "code": "CMR",
        "name": "Cameroon",
        "eu": false
    },
    {
        "code": "CAN",
        "name": "Canada",
        "eu": false
    },
    {
        "code": "CPV",
        "name": "Cape Verde",
        "eu": false
    },
    {
        "code": "CYM",
        "name": "Cayman Islands",
        "eu": false
    },
    {
        "code": "CAF",
        "name": "Central African Republic",
        "eu": false
    },
    {
        "code": "TCD",
        "name": "Chad",
        "eu": false
    },
    {
        "code": "CHL",
        "name": "Chile",
        "eu": false
    },
    {
        "code": "CHN",
        "name": "China",
        "eu": false
    },
    {
        "code": "CXR",
        "name": "Christmas Island",
        "eu": false
    },
    {
        "code": "CCK",
        "name": "Cocos (Keeling) Islands",
        "eu": false
    },
    {
        "code": "COL",
        "name": "Colombia",
        "eu": false
    },
    {
        "code": "COM",
        "name": "Comoros",
        "eu": false
    },
    {
        "code": "COG",
        "name": "Congo - Brazzaville",
        "eu": false
    },
    {
        "code": "COD",
        "name": "Congo - Kinshasa",
        "eu": false
    },
    {
        "code": "COK",
        "name": "Cook Islands",
        "eu": false
    },
    {
        "code": "CRI",
        "name": "Costa Rica",
        "eu": false
    },
    {
        "code": "CIV",
        "name": "Côte d'Ivoire",
        "eu": false
    },
    {
        "code": "HRV",
        "name": "Croatia",
        "eu": true
    },
    {
        "code": "CUB",
        "name": "Cuba",
        "eu": false
    },
    {
        "code": "CUW",
        "name": "Curaçao",
        "eu": false
    },
    {
        "code": "CYP",
        "name": "Cyprus",
        "eu": true
    },
    {
        "code": "CZE",
        "name": "Czechia",
        "eu": true
    },
    {
        "code": "DNK",
        "name": "Denmark",
        "eu": true
    },
    {
        "code": "DJI",
        "name": "Djibouti",
        "eu": false
    },
    {
        "code": "DMA",
        "name": "Dominica",
        "eu": false
    },
    {
        "code": "DOM",
        "name": "Dominican Republic",
        "eu": false
    },
    {
        "code": "ECU",
        "name": "Ecuador",
        "eu": false
    },
    {
        "code": "EGY",
        "name": "Egypt",
        "eu": false
    },
    {
        "code": "SLV",
        "name": "El Salvador",
        "eu": false
    },
    {
        "code": "GNQ",
        "name": "Equatorial Guinea",
        "eu": false
    },
    {
        "code": "ERI",
        "name": "Eritrea",
        "eu": false
    },
    {
        "code": "EST",
        "name": "Estonia",
        "eu": true
    },
    {
        "code": "ETH",
        "name": "Ethiopia",
        "eu": false
    },
    {
        "code": "FLK",
        "name": "Falkland Islands (Islas Malvinas)",
        "eu": false
    },
    {
        "code": "FRO",
        "name": "Faroe Islands",
        "eu": false
    },
    {
        "code": "FJI",
        "name": "Fiji",
        "eu": false
    },
    {
        "code": "FIN",
        "name": "Finland",
        "eu": true
    },
    {
        "code": "FRA",
        "name": "France",
        "eu": true
    },
    {
        "code": "GUF",
        "name": "French Guiana",
        "eu": false
    },
    {
        "code": "PYF",
        "name": "French Polynesia",
        "eu": false
    },
    {
        "code": "ATF",
        "name": "French Southern Territories",
        "eu": false
    },
    {
        "code": "GAB",
        "name": "Gabon",
        "eu": false
    },
    {
        "code": "GMB",
        "name": "Gambia",
        "eu": false
    },
    {
        "code": "GEO",
        "name": "Georgia",
        "eu": false
    },
    {
        "code": "DEU",
        "name": "Germany",
        "eu": true
    },
    {
        "code": "GHA",
        "name": "Ghana",
        "eu": false
    },
    {
        "code": "GIB",
        "name": "Gibraltar",
        "eu": false
    },
    {
        "code": "GRC",
        "name": "Greece",
        "eu": true
    },
    {
        "code": "GRL",
        "name": "Greenland",
        "eu": false
    },
    {
        "code": "GRD",
        "name": "Grenada",
        "eu": false
    },
    {
        "code": "GLP",
        "name": "Guadeloupe",
        "eu": false
    },
    {
        "code": "GUM",
        "name": "Guam",
        "eu": false
    },
    {
        "code": "GTM",
        "name": "Guatemala",
        "eu": false
    },
    {
        "code": "GGY",
        "name": "Guernsey",
        "eu": false
    },
    {
        "code": "GIN",
        "name": "Guinea",
        "eu": false
    },
    {
        "code": "GNB",
        "name": "Guinea-Bissau",
        "eu": false
    },
    {
        "code": "GUY",
        "name": "Guyana",
        "eu": false
    },
    {
        "code": "HTI",
        "name": "Haiti",
        "eu": false
    },
    {
        "code": "HMD",
        "name": "Heard Island and McDonald Islands",
        "eu": false
    },
    {
        "code": "VAT",
        "name": "Holy See (Vatican City State)",
        "eu": false
    },
    {
        "code": "HND",
        "name": "Honduras",
        "eu": false
    },
    {
        "code": "HKG",
        "name": "Hong Kong",
        "eu": false
    },
    {
        "code": "HUN",
        "name": "Hungary",
        "eu": true
    },
    {
        "code": "ISL",
        "name": "Iceland",
        "eu": false
    },
    {
        "code": "IND",
        "name": "India",
        "eu": false
    },
    {
        "code": "IDN",
        "name": "Indonesia",
        "eu": false
    },
    {
        "code": "IRN",
        "name": "Iran",
        "eu": false
    },
    {
        "code": "IRQ",
        "name": "Iraq",
        "eu": false
    },
    {
        "code": "IRL",
        "name": "Ireland",
        "eu": true
    },
    {
        "code": "IMN",
        "name": "Isle of Man",
        "eu": false
    },
    {
        "code": "ISR",
        "name": "Israel",
        "eu": false
    },
    {
        "code": "ITA",
        "name": "Italy",
        "eu": true
    },
    {
        "code": "JAM",
        "name": "Jamaica",
        "eu": false
    },
    {
        "code": "JPN",
        "name": "Japan",
        "eu": false
    },
    {
        "code": "JEY",
        "name": "Jersey",
        "eu": false
    },
    {
        "code": "JOR",
        "name": "Jordan",
        "eu": false
    },
    {
        "code": "KAZ",
        "name": "Kazakhstan",
        "eu": false
    },
    {
        "code": "KEN",
        "name": "Kenya",
        "eu": false
    },
    {
        "code": "KIR",
        "name": "Kiribati",
        "eu": false
    },
    {
        "code": "PRK",
        "name": "North Korea",
        "eu": false
    },
    {
        "code": "KOR",
        "name": "South Korea",
        "eu": false
    },
    {
        "code": "XKK",
        "name": "Kosovo",
        "eu": false
    },
    {
        "code": "KWT",
        "name": "Kuwait",
        "eu": false
    },
    {
        "code": "KGZ",
        "name": "Kyrgyzstan",
        "eu": false
    },
    {
        "code": "LAO",
        "name": "Laos",
        "eu": false
    },
    {
        "code": "LVA",
        "name": "Latvia",
        "eu": true
    },
    {
        "code": "LBN",
        "name": "Lebanon",
        "eu": false
    },
    {
        "code": "LSO",
        "name": "Lesotho",
        "eu": false
    },
    {
        "code": "LBR",
        "name": "Liberia",
        "eu": false
    },
    {
        "code": "LBY",
        "name": "Libya",
        "eu": false
    },
    {
        "code": "LIE",
        "name": "Liechtenstein",
        "eu": false
    },
    {
        "code": "LTU",
        "name": "Lithuania",
        "eu": true
    },
    {
        "code": "LUX",
        "name": "Luxembourg",
        "eu": true
    },
    {
        "code": "MAC",
        "name": "Macao",
        "eu": false
    },
    {
        "code": "MKD",
        "name": "North Macedonia",
        "eu": false
    },
    {
        "code": "MDG",
        "name": "Madagascar",
        "eu": false
    },
    {
        "code": "MWI",
        "name": "Malawi",
        "eu": false
    },
    {
        "code": "MYS",
        "name": "Malaysia",
        "eu": false
    },
    {
        "code": "MDV",
        "name": "Maldives",
        "eu": false
    },
    {
        "code": "MLI",
        "name": "Mali",
        "eu": false
    },
    {
        "code": "MLT",
        "name": "Malta",
        "eu": true
    },
    {
        "code": "MHL",
        "name": "Marshall Islands",
        "eu": false
    },
    {
        "code": "MTQ",
        "name": "Martinique",
        "eu": false
    },
    {
        "code": "MRT",
        "name": "Mauritania",
        "eu": false
    },
    {
        "code": "MUS",
        "name": "Mauritius",
        "eu": false
    },
    {
        "code": "MYT",
        "name": "Mayotte",
        "eu": false
    },
    {
        "code": "MEX",
        "name": "Mexico",
        "eu": false
    },
    {
        "code": "FSM",
        "name": "Micronesia, Federated States of",
        "eu": false
    },
    {
        "code": "MDA",
        "name": "Moldova",
        "eu": false
    },
    {
        "code": "MCO",
        "name": "Monaco",
        "eu": false
    },
    {
        "code": "MNG",
        "name": "Mongolia",
        "eu": false
    },
    {
        "code": "MNE",
        "name": "Montenegro",
        "eu": false
    },
    {
        "code": "MSR",
        "name": "Montserrat",
        "eu": false
    },
    {
        "code": "MAR",
        "name": "Morocco",
        "eu": false
    },
    {
        "code": "MOZ",
        "name": "Mozambique",
        "eu": false
    },
    {
        "code": "MMR",
        "name": "Myanmar (Burma)",
        "eu": false
    },
    {
        "code": "NAM",
        "name": "Namibia",
        "eu": false
    },
    {
        "code": "NRU",
        "name": "Nauru",
        "eu": false
    },
    {
        "code": "NPL",
        "name": "Nepal",
        "eu": false
    },
    {
        "code": "NLD",
        "name": "Netherlands",
        "eu": true
    },
    {
        "code": "NCL",
        "name": "New Caledonia",
        "eu": false
    },
    {
        "code": "NZL",
        "name": "New Zealand",
        "eu": false
    },
    {
        "code": "NIC",
        "name": "Nicaragua",
        "eu": false
    },
    {
        "code": "NER",
        "name": "Niger",
        "eu": false
    },
    {
        "code": "NGA",
        "name": "Nigeria",
        "eu": false
    },
    {
        "code": "NIU",
        "name": "Niue",
        "eu": false
    },
    {
        "code": "NFK",
        "name": "Norfolk Island",
        "eu": false
    },
    {
        "code": "MNP",
        "name": "Northern Mariana Islands",
        "eu": false
    },
    {
        "code": "NOR",
        "name": "Norway",
        "eu": false
    },
    {
        "code": "OMN",
        "name": "Oman",
        "eu": false
    },
    {
        "code": "PAK",
        "name": "Pakistan",
        "eu": false
    },
    {
        "code": "PLW",
        "name": "Palau",
        "eu": false
    },
    {
        "code": "PSE",
        "name": "Palestinian Territory, Occupied",
        "eu": false
    },
    {
        "code": "PAN",
        "name": "Panama",
        "eu": false
    },
    {
        "code": "PNG",
        "name": "Papua New Guinea",
        "eu": false
    },
    {
        "code": "PRY",
        "name": "Paraguay",
        "eu": false
    },
    {
        "code": "PER",
        "name": "Peru",
        "eu": false
    },
    {
        "code": "PHL",
        "name": "Philippines",
        "eu": false
    },
    {
        "code": "PCN",
        "name": "Pitcairn",
        "eu": false
    },
    {
        "code": "POL",
        "name": "Poland",
        "eu": true
    },
    {
        "code": "PRT",
        "name": "Portugal",
        "eu": true
    },
    {
        "code": "PRI",
        "name": "Puerto Rico",
        "eu": false
    },
    {
        "code": "QAT",
        "name": "Qatar",
        "eu": false
    },
    {
        "code": "REU",
        "name": "Réunion",
        "eu": false
    },
    {
        "code": "ROU",
        "name": "Romania",
        "eu": true
    },
    {
        "code": "RUS",
        "name": "Russia",
        "eu": false
    },
    {
        "code": "RWA",
        "name": "Rwanda",
        "eu": false
    },
    {
        "code": "BLM",
        "name": "Saint Barthélemy",
        "eu": false
    },
    {
        "code": "SHN",
        "name": "Saint Helena, Ascension and Tristan da Cunha",
        "eu": false
    },
    {
        "code": "KNA",
        "name": "Saint Kitts and Nevis",
        "eu": false
    },
    {
        "code": "LCA",
        "name": "Saint Lucia",
        "eu": false
    },
    {
        "code": "MAF",
        "name": "Saint Martin (French part)",
        "eu": false
    },
    {
        "code": "SPM",
        "name": "Saint Pierre and Miquelon",
        "eu": false
    },
    {
        "code": "VCT",
        "name": "Saint Vincent and the Grenadines",
        "eu": false
    },
    {
        "code": "WSM",
        "name": "Samoa",
        "eu": false
    },
    {
        "code": "SMR",
        "name": "San Marino",
        "eu": false
    },
    {
        "code": "STP",
        "name": "Sao Tome and Principe",
        "eu": false
    },
    {
        "code": "SAU",
        "name": "Saudi Arabia",
        "eu": false
    },
    {
        "code": "SEN",
        "name": "Senegal",
        "eu": false
    },
    {
        "code": "SRB",
        "name": "Serbia",
        "eu": false
    },
    {
        "code": "SYC",
        "name": "Seychelles",
        "eu": false
    },
    {
        "code": "SLE",
        "name": "Sierra Leone",
        "eu": false
    },
    {
        "code": "SGP",
        "name": "Singapore",
        "eu": false
    },
    {
        "code": "SXM",
        "name": "Sint Maarten (Dutch part)",
        "eu": false
    },
    {
        "code": "SVK",
        "name": "Slovakia",
        "eu": true
    },
    {
        "code": "SVN",
        "name": "Slovenia",
        "eu": true
    },
    {
        "code": "SLB",
        "name": "Solomon Islands",
        "eu": false
    },
    {
        "code": "SOM",
        "name": "Somalia",
        "eu": false
    },
    {
        "code": "ZAF",
        "name": "South Africa",
        "eu": false
    },
    {
        "code": "SGS",
        "name": "South Georgia and the South Sandwich Islands",
        "eu": false
    },
    {
        "code": "SSD",
        "name": "South Sudan",
        "eu": false
    },
    {
        "code": "ESP",
        "name": "Spain",
        "eu": true
    },
    {
        "code": "LKA",
        "name": "Sri Lanka",
        "eu": false
    },
    {
        "code": "SDN",
        "name": "Sudan",
        "eu": false
    },
    {
        "code": "SUR",
        "name": "Suriname",
        "eu": false
    },
    {
        "code": "SJM",
        "name": "Svalbard and Jan Mayen",
        "eu": false
    },
    {
        "code": "SWZ",
        "name": "Swaziland",
        "eu": false
    },
    {
        "code": "SWE",
        "name": "Sweden",
        "eu": true
    },
    {
        "code": "CHE",
        "name": "Switzerland",
        "eu": false
    },
    {
        "code": "SYR",
        "name": "Syria",
        "eu": false
    },
    {
        "code": "TWN",
        "name": "Taiwan, Province of China",
        "eu": false
    },
    {
        "code": "TJK",
        "name": "Tajikistan",
        "eu": false
    },
    {
        "code": "TZA",
        "name": "Tanzania",
        "eu": false
    },
    {
        "code": "THA",
        "name": "Thailand",
        "eu": false
    },
    {
        "code": "TLS",
        "name": "Timor-Leste",
        "eu": false
    },
    {
        "code": "TGO",
        "name": "Togo",
        "eu": false
    },
    {
        "code": "TKL",
        "name": "Tokelau",
        "eu": false
    },
    {
        "code": "TON",
        "name": "Tonga",
        "eu": false
    },
    {
        "code": "TTO",
        "name": "Trinidad & Tobago",
        "eu": false
    },
    {
        "code": "TUN",
        "name": "Tunisia",
        "eu": false
    },
    {
        "code": "TUR",
        "name": "Turkey",
        "eu": false
    },
    {
        "code": "TKM",
        "name": "Turkmenistan",
        "eu": false
    },
    {
        "code": "TCA",
        "name": "Turks and Caicos Islands",
        "eu": false
    },
    {
        "code": "TUV",
        "name": "Tuvalu",
        "eu": false
    },
    {
        "code": "UGA",
        "name": "Uganda",
        "eu": false
    },
    {
        "code": "UKR",
        "name": "Ukraine",
        "eu": false
    },
    {
        "code": "ARE",
        "name": "United Arab Emirates",
        "eu": false
    },
    {
        "code": "GBR",
        "name": "United Kingdom",
        "eu": true
    },
    {
        "code": "USA",
        "name": "United States",
        "eu": false
    },
    {
        "code": "UMI",
        "name": "United States Minor Outlying Islands",
        "eu": false
    },
    {
        "code": "URY",
        "name": "Uruguay",
        "eu": false
    },
    {
        "code": "UZB",
        "name": "Uzbekistan",
        "eu": false
    },
    {
        "code": "VUT",
        "name": "Vanuatu",
        "eu": false
    },
    {
        "code": "VEN",
        "name": "Venezuela",
        "eu": false
    },
    {
        "code": "VNM",
        "name": "Vietnam",
        "eu": false
    },
    {
        "code": "VGB",
        "name": "Virgin Islands, British",
        "eu": false
    },
    {
        "code": "VIR",
        "name": "Virgin Islands, U.S.",
        "eu": false
    },
    {
        "code": "WLF",
        "name": "Wallis and Futuna",
        "eu": false
    },
    {
        "code": "ESH",
        "name": "Western Sahara",
        "eu": false
    },
    {
        "code": "YEM",
        "name": "Yemen",
        "eu": false
    },
    {
        "code": "ZMB",
        "name": "Zambia",
        "eu": false
    },
    {
        "code": "ZWE",
        "name": "Zimbabwe",
        "eu": false
    }
];

export const convertBase64 = (object) => {
    // Convert the object to a string (e.g., JSON.stringify if it's an object)
    const base64String = btoa(unescape(encodeURIComponent(object)));

    let key = "INVESTISDIGI";
    let text = base64String;
    const alphabetLength = 26;
    let encryptedText = '';

    for (let i = 0; i < text.length; i++) {
        const char = text[i];
        const keyChar = key[i % key.length];

        // Convert characters to their ASCII codes
        const charCode = char.charCodeAt(0);
        const keyCharCode = keyChar.charCodeAt(0);

        // Check if the character is an uppercase letter
        if (charCode >= 65 && charCode <= 90) {
            const encryptedCharCode = ((charCode - 65 + keyCharCode - 65) % alphabetLength) + 65;
            encryptedText += String.fromCharCode(encryptedCharCode);
        } else {
            // Preserve non-alphabetic characters as is
            encryptedText += char;
        }
    }

    let charString = Math.random().toString(36).slice(2, 6);
    encryptedText = decodeURIComponent(encryptedText);
    return charString + encryptedText;
};

export const convertTranslatinString = (value) => {
    if (value !== undefined && value.length > 0) {
        let lebelString = value.trim().split(" ").join("_");
        return lebelString
    } else {
        return ""
    }
}

export const getDemoClientData = () => {
    let dateRange = null;
    if (localStorage.getItem("SelectedDatePicker_website")) {
        dateRange = JSON.parse(localStorage.getItem("SelectedDatePicker_website")).quickSelect;
    }
    if (dateRange && dateRange == 180) {
        return demoClientData_180;
    }
    else if (dateRange && dateRange == 90) {
        return demoClientData_90;
    }
    else if (dateRange && dateRange == 30) {
        return demoClientData_30;
    }
    else if (dateRange && dateRange == 7) {
        return demoClientData_7;
    }
    else {
        return demoClientData_180;
    }
}

export const getDemoClientDataByKey = async (key) => {
    let dateRange = null;
    if (localStorage.getItem("SelectedDatePicker_website")) {
        dateRange = JSON.parse(localStorage.getItem("SelectedDatePicker_website")).quickSelect;
    }
    key = (dateRange && dateRange != 0) ? key + "_" + dateRange : key;
    let data = getDemoClientData().find(x => x.key.includes(key)).value;
    data = modifyResponse(data);
    return data;
}

const modifyResponse = (obj) => {
    const str = JSON.stringify(obj);
    const result = str.replace(new RegExp('rolls-royce', 'gi'), 'TTA Aerospace')
        .replace(new RegExp('rolls royce', 'gi'), 'TTA Aerospace')
        .replace(new RegExp('tokmanni', 'gi'), 'CostRetail')
        .replace(new RegExp('Tokmanni', 'gi'), 'CostRetail')
        .replace(new RegExp('UK Ministry of Defence', 'gi'), 'RELDefence');
    return JSON.parse(result);
}

export const getYearQuarter = (getYear) => {
    let date = new Date();
    if (getYear) {
        return date.getFullYear();
    }
    else {
        let month = date.getMonth();
        if (month <= 2) {
            return 1;
        }
        else if (month <= 5) {
            return 2;
        }
        else if (month <= 8) {
            return 3;
        }
        else {
            return 4;
        }
    }
}