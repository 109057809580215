import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from 'react-i18next';
import { Col, Form, Row } from "@themesberg/react-bootstrap";

import ChartRendererTable from '../components/ChartRendererTable';
import ButtonsComponent from "./Buttons";
import { baseURL } from "../apis/utilities/constant";


const UserWebsiteDigestComponent = (props) => {
  const [t, i18n] = useTranslation();
  const { websiteTableData } = props;

  const [allData, setAllData] = useState([])
  const [inputValue, setInputValue] = useState(""); // Initial value is empty
  const [initialValue, setInitialValue] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  useEffect(() => {
    setIsButtonDisabled(true);
    if (allData.additionalEmails) {
      setInitialValue(allData.additionalEmails); // Set the initial value from Exportdata
      setInputValue(allData.additionalEmails);   // Set the input box value
    }
  }, [allData])

  const handleInputChange = (e) => {
    const newValue = e.target.value;
    // Enable the button if user starts typing beyond the initial value
    if (newValue !== initialValue) {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
    setInputValue(newValue); // Update input value as user types
    props.setRecipients(newValue);
  };

  const table = useMemo(() => (
    <ChartRendererTable
      apiUrl={{ url: baseURL + `/User/GetUserWebsiteDigestByUserId?CompanyId=${props.companyId}&UserId=${props.userId}` }}
      onItemChange={props.onItemChange}
      showCount={false}
      Exportdata={setAllData}
    />), [])

  return (
    <div className="shadow-soft border rounded border-light w-100 nested-card-container pb-2">
      {table}
      <Row className="mt-3">
        <Col>
          <span className="ms-3 text-bold">Add Additional Email</span>
        </Col>
      </Row>
      <Row className="mt-2 mb-3">
        <Col md={6}>
          <Form.Control className="recipient-textarea-DS ms-3 me-3" as="textarea" rows="1" name="recipients" value={inputValue} placeholder={"Add comma-separated email address"} onChange={(e) => handleInputChange(e)} />
        </Col>
      </Row>
      <Row>
        <Col>
          <ButtonsComponent
            onClick={(e) => props.handleSubmitButtonClick(e)}
            isIcon={false}
            dissable={websiteTableData && websiteTableData.length > 0 || !isButtonDisabled ? false : true}
            isPrimary={true}
            btn_class="ms-3"
            btnText={t("header.websiteDigestSaveButton")}
          />
        </Col>
      </Row>
    </div>
  )
};
export default UserWebsiteDigestComponent;
