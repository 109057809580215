import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from "moment";
import { DateRangePicker } from 'react-date-range';
import { enUS } from 'date-fns/locale';
import { addDays, addYears } from 'date-fns';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { Row, Col, FormCheck } from '@themesberg/react-bootstrap';

import { dashbordDateRangeList } from '../utils/datetime';

import ButtonsComponent from './Buttons';
import DropdownWithIconsComponent from './DropdownWithIcons';

const DateRangeComponent = ({ startDate, setStartDate, endDate, setEndDate, setShowCustomDatePicker, setDatePickerValue, setDatePickerCompareValue, validStartDate, selectedDateRangeOption, setSelectedDateRangeOption }) => {
    const [t, i18n] = useTranslation();

    const [dateRange, setDateRange] = useState({
        startDate: startDate ? new Date(startDate) : addDays(new Date(), -180),
        endDate: endDate ? new Date(endDate) : addDays(new Date(), -1),
        key: 'selection',
    });

    const [quickSelect, setQuickSelect] = useState(selectedDateRangeOption.quickSelect);
    const [years, setYears] = useState([]);
    const [quarters, setQuarters] = useState([]);

    const [selectedYear, setSelectedYear] = useState();
    const [selectedQuarter, setSelectedQuarter] = useState();

    const [activeDateRangeOption, setActiveDateRangeOption] = useState(selectedDateRangeOption.activeDateRangeOption);
    const [errorMessage, setErrorMessage] = useState("");

    useEffect(() => {
        const startYear = new Date(validStartDate).getFullYear();
        const endYear = addDays(new Date(), -1).getFullYear();

        let yearsArray = [];
        for (let year = startYear; year <= endYear; year++) {
            yearsArray.push({ label: year, value: year });
        }
        setYears(yearsArray);
        let year = yearsArray.find(x => x.value === selectedDateRangeOption.selectedYear);
        setSelectedYear(year)
        setValidQuarter(year.value, false);
    }, []);

    const handleSelect = (ranges) => {
        setDateRange(ranges.selection);
        setSelectedDateRangeOption({ ...selectedDateRangeOption, activeDateRangeOption: 3, startDate: ranges.selection.startDate, endDate: ranges.selection.endDate });
    };

    const onChangeDateRangeOption = (value) => {
        setActiveDateRangeOption(value);
        if (value === 1) {
            onClickQuickOptions(quickSelect);
        }
        else if (value === 2) {
            setValidQuarter(selectedYear.value, true);
        }
    }

    const onClickQuickOptions = (value) => {
        let startDate = addDays(new Date(), -value);
        let endDate = addDays(new Date(), -1);
        if (value === 0) {
            const currentDate = moment();
            const daysDifference = currentDate.diff(validStartDate, 'days');
            if (daysDifference >= 180) {
                startDate = moment(new Date).subtract(180, 'days').toDate();
                value = 180;
            }
            else if (daysDifference >= 90) {
                startDate = moment(new Date).subtract(90, 'days').toDate();
                value = 90;
            }
            else if (daysDifference >= 30) {
                startDate = moment(new Date).subtract(30, 'days').toDate();
                value = 30;
            }
            else if (daysDifference >= 7) {
                startDate = moment(new Date).subtract(7, 'days').toDate();
                value = 7;
            }
            else {
                startDate = moment(validStartDate).toDate();
                value = 7;
            }
        }
        setQuickSelect(value);
        if (moment(validStartDate).isAfter(moment(startDate))) {
            startDate = moment(validStartDate).toDate();
        }
        setDateRange({
            startDate: startDate,
            endDate: endDate,
            key: 'selection'
        });
    }

    const onChangeYear = (year) => {
        setSelectedYear(year);
        setValidQuarter(year.value, true);
    }

    const onChangeQuarter = (quarter) => {
        let validEndDate = addDays(new Date(), -1);
        setSelectedQuarter(quarter);
        let startDate = new Date(quarter.startDate >= validStartDate ? quarter.startDate : validStartDate);
        let endDate = new Date(quarter.endDate >= validEndDate ? validEndDate : quarter.endDate);
        setDateRange({
            startDate: startDate,
            endDate: endDate,
            key: 'selection'
        });
    }

    const onApplyDateRange = () => {
        const { startDate, endDate } = dateRange;
        const oneYearLater = addYears(startDate, 1);
        if (endDate > oneYearLater) {
            setErrorMessage(t("header.dateValidationError"));
            return;
        }
        let selectedDateRange = { ...selectedDateRangeOption, activeDateRangeOption: activeDateRangeOption, startDate: dateRange.startDate, endDate: dateRange.endDate };
        if (activeDateRangeOption === 1) {
            selectedDateRange = { ...selectedDateRange, quickSelect: quickSelect, selectedYear: years[years.length - 1].value, selectedQuarter: quarters[0].value }
        }
        else if (activeDateRangeOption === 2) {
            selectedDateRange = { ...selectedDateRange, quickSelect: 0, selectedYear: selectedYear.value, selectedQuarter: selectedQuarter.value }
        }
        else {
            selectedDateRange = { ...selectedDateRange, quickSelect: 0, selectedYear: years[years.length - 1].value, selectedQuarter: quarters[0].value }
        }
        setSelectedDateRangeOption(selectedDateRange);

        setStartDate(dateRange.startDate);
        setEndDate(dateRange.endDate);
        setDatePickerValue(`${moment(dateRange.startDate).format("MMM DD, YYYY")} - ${moment(dateRange.endDate).format("MMM DD, YYYY")}`);
        let previousEndDate = moment(dateRange.endDate).subtract(1, 'years').format("MMM DD, YYYY");
        let previousStartDate = moment(dateRange.startDate).subtract(1, 'years').format("MMM DD, YYYY");
        let datePickerCmpr = `${previousStartDate} - ${previousEndDate}`;
        setDatePickerCompareValue(datePickerCmpr);
        setTimeout(() => { setShowCustomDatePicker(false) }, 10);
    }

    const setValidQuarter = (year, changeDateRange) => {
        let validEndDate = addDays(new Date(), -1);
        const quarters = [
            { label: "Q1", value: 1, start: new Date(year, 0, 1), end: new Date(year, 2, 31) },
            { label: "Q2", value: 2, start: new Date(year, 3, 1), end: new Date(year, 5, 30) },
            { label: "H1", value: 5, start: new Date(year, 0, 1), end: new Date(year, 5, 30) },
            { label: "Q3", value: 3, start: new Date(year, 6, 1), end: new Date(year, 8, 30) },
            { label: "Q4", value: 4, start: new Date(year, 9, 1), end: new Date(year, 11, 31) },
            { label: "H2", value: 6, start: new Date(year, 6, 1), end: new Date(year, 11, 31) },
        ].filter(q => q.end >= validStartDate && q.start <= validEndDate)
            .map(q => { return { value: q.value, label: q.label, startDate: q.start, endDate: q.end } });

        setQuarters(quarters);
        let quarter = quarters.find(x => x.value === selectedDateRangeOption.selectedQuarter && year === x.startDate.getFullYear());
        quarter = quarter ? quarter : quarters[0];
        setSelectedQuarter(quarter);
        let startDate = new Date(quarters[0].startDate >= validStartDate ? quarter.startDate : validStartDate);
        let endDate = new Date(quarters[0].endDate >= validEndDate ? validEndDate : quarter.endDate);
        if (changeDateRange) {
            setDateRange({
                startDate: startDate,
                endDate: endDate,
                key: 'selection'
            });
        }
    }

    const renderQuickSelect = () => {
        return (
            <>
                {dashbordDateRangeList.map((item, index) => (
                    <ButtonsComponent
                        key={index}
                        onClick={() => onClickQuickOptions(item.compareOn)}
                        isIcon={false}
                        isPrimary={false}
                        btnText={item.value}
                        btn_class={activeDateRangeOption === 1 && quickSelect === item.compareOn ? "btn-md mb-1 active custom-tabs" : "btn-md mb-1 custom-tabs"}
                    />
                ))}
            </>
        );
    }

    return (
        <div className="calendar-container">
            <Row >
                <Col xs={3} sm={3} className='left-column'>
                    <div className="mb-3">
                        <div className='d-flex custom-radio-button mt-1'>
                            <FormCheck
                                type="radio"
                                value={3}
                                checked={activeDateRangeOption === 1}
                                defaultChecked={false}
                                name="date-option"
                                id="html"
                                onClick={(e) => onChangeDateRangeOption(1)}
                            />
                            <span>{t("header.QuickSelect")}</span>
                        </div>
                        <div id="quick-select-section" className={activeDateRangeOption !== 1 ? "custom-disable" : ""}>
                            {renderQuickSelect()}
                        </div>
                    </div>
                    <div className="mb-3">
                        <div className='d-flex mb-2 custom-radio-button'>
                            <FormCheck
                                type="radio"
                                value={2}
                                checked={activeDateRangeOption === 2}
                                defaultChecked={false}
                                name="date-option"
                                id="html"
                                onClick={(e) => onChangeDateRangeOption(2)}
                            />
                            <span>{t("header.YearAndQuarter")}</span>
                        </div>
                        <div id="year-quarter-section" className={activeDateRangeOption !== 2 ? "custom-disable" : ""}>
                            <DropdownWithIconsComponent
                                dropdownData={years}
                                arrayValue={"label"}
                                selectedDropdown={selectedYear}
                                onChange={(e) => onChangeYear(e)}
                                showLabel={false}
                                showIconInDropdownItem={false}
                                customClassName="full-width text-overflow"
                                isDropdownDisabled={false}
                            />
                            <DropdownWithIconsComponent
                                dropdownData={quarters}
                                arrayValue={"label"}
                                selectedDropdown={selectedQuarter}
                                onChange={(e) => onChangeQuarter(e)}
                                showLabel={false}
                                showIconInDropdownItem={false}
                                customClassName="full-width text-overflow mt-2"
                                isDropdownDisabled={false}
                            />
                        </div>
                    </div>
                </Col>
                <Col xs={9} sm={9}>
                    <div>
                        <div className='d-flex custom-radio-button mt-1'>
                            <FormCheck
                                type="radio"
                                value={3}
                                checked={activeDateRangeOption === 3}
                                defaultChecked={false}
                                name="date-option"
                                id="html"
                                onClick={(e) => onChangeDateRangeOption(3)}
                            />
                            <span>{t("header.CustomDateRange")}</span>
                        </div>
                        <div id="custom-range-section" className={activeDateRangeOption !== 3 ? "custom-disable" : ""}>
                            <DateRangePicker
                                ranges={[dateRange]}
                                onChange={handleSelect}
                                months={2}
                                direction='horizontal'
                                staticRanges={[]}
                                inputRanges={[]}
                                minDate={new Date(validStartDate)}
                                maxDate={addDays(new Date(), -1)}
                                locale={enUS}
                            />
                        </div>
                        <div className="d-flex justify-content-end">
                            <div className="invalid-feedback d-block" style={{ textAlign: "left" }}>{errorMessage !== "" ? errorMessage : ""}</div>
                            <ButtonsComponent
                                onClick={() => setTimeout(() => { setShowCustomDatePicker(false) }, 10)}
                                isIcon={false}
                                isPrimary={false}
                                btnText={"Cancel"}
                                btn_class={"btn-md filter-btn"}
                            />
                            <ButtonsComponent
                                onClick={() => onApplyDateRange()}
                                isIcon={false}
                                isPrimary={true}
                                btnText={"Apply"}
                                btn_class={"btn-md ms-3 filter-btn"}
                            />
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default DateRangeComponent;
