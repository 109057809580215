import React, { useState, useEffect, useContext } from 'react';
import { Navigate } from "react-router-dom";
import axios from 'axios';
import { Helmet } from 'react-helmet-async';

import { isEmpty } from "../AppConfig";

import Sidebar from '../components/Sidebar';
import AppContext from '../components/AppContext';
import Header from '../components/Header';
import ErrorPage from '../pages/ErrorPage';

const PrivateRoute = ({ children, title }) => {

    const contextData = useContext(AppContext);

    const localStorageIsContracted = () => {
        return localStorage.getItem('sidebarContracted') === 'false' ? false : true
    }

    const [contracted, setContracted] = useState(localStorageIsContracted());
    const [contractSidebar, setContractSidebar] = useState(localStorageIsContracted());
    const [valid, setValid] = useState(false);

    const tokenFromLocalStorage = localStorage.getItem("token");

    useEffect(() => {
        if (!contextData.cryptoKey) {
            axios.get(
                `${process.env.REACT_APP_API_KEY}/CommonV2/GetCryptoKey`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem("token")}`
                }
            }
            )
                .then(response => {
                    if (response.data) {
                        contextData.changeCryptoKey(response.data)
                    }
                })
                .catch(error => {
                    console.log("login error from sso", error);
                });
        }
    }, []);

    const resize = () => {
        let resize = setInterval(() => {
            window.dispatchEvent(new Event('resize'));
        }, 10);
        setTimeout(function () {
            clearInterval(resize);
        }, 301);
    }

    const toggleContracted = () => {
        setContracted(!contracted);
        setContractSidebar(!contracted);
        localStorage.setItem('sidebarContracted', !contracted);
        resize();
    };

    const toggleMouseOver = () => {
        if (contracted) {
            setContractSidebar(!contractSidebar);
        }
        resize();
    };

    return (
        contextData.cryptoKey &&
        <>
            {!isEmpty(tokenFromLocalStorage) ?
                <>
                    <Header setValid={setValid} toggleContracted={toggleContracted} />
                    <main className="content" id="maindiv">
                        <Sidebar
                            contracted={contractSidebar}
                            onMouseEnter={toggleMouseOver}
                            onMouseLeave={toggleMouseOver}
                        />
                        <Helmet>
                            <title>
                                {title ? `${title} - Insights by IDX` : "Insights by IDX"}
                            </title>
                        </Helmet>
                        <ErrorPage>{children}</ErrorPage>
                    </main>
                </>
                :
                <Navigate to='/login' />
            }
        </>
    );

};

export default PrivateRoute;