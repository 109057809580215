import { Breadcrumb } from '@themesberg/react-bootstrap'
import React from 'react'

const CookiePolicyPage = () => {
    return (
        <div className='container-fluid'>


            <div className='row'>
                <div className='col-12'>
                    <div className='bg-white shadow-soft border rounded border-light p-4 w-100 cookie-page'>
                        <Breadcrumb listProps={{ className: "breadcrumb-text-light text-black" }}>
                            <Breadcrumb.Item href="#/">Dashboard</Breadcrumb.Item>
                            <Breadcrumb.Item active href="#">Cookie Policy</Breadcrumb.Item>
                        </Breadcrumb>
                        <h2>COOKIE POLICY</h2>
                        <p>Investis Digital Limited ("<strong>IDX</strong>", "<strong>we</strong>", "<strong>us</strong>", "<strong>our</strong>").</p>
                        <p><em>Updated January 2024.</em></p>
                        <br />
                        <p>Our website ('IDX') uses cookies. We use cookies for many reasons, for example to distinguish you from other users of our website. This helps us to provide you with a good experience when you browse our website and also allows us to improve our website.</p>
                        <br />
                        <p>This Cookie Policy describes:</p>
                        <ul>
                            <li>what cookies are;</li>
                            <li>our use of cookies;</li>
                            <li>how you can manage cookies used on our website;</li>
                            <li>privacy;</li>
                            <li>further information; and</li>
                            <li>changes to this Cookie Policy.</li>
                        </ul>

                        <h5>What are cookies?</h5>
                        <p>A cookie is a small file of letters and numbers that we store on your browser or the hard drive of your computer, smart phone, tablet or other electronic device if you agree. Cookies contain information that is transferred to your device's hard drive and some cookies may collect personal data about you. You can find more general information about cookies at: www.aboutcookies.org or www.allaboutcookies.org.</p>

                        <h5>Our use of cookies</h5>
                        <p>We want you to understand the different types of cookies that are used on our website. We use a variety of types of cookies for the following purposes:</p>
                        <ul>
                            <li><b>Strictly necessary cookies:</b> these are cookies strictly necessary for the performance of our website, for example cookies that enables website content to be delivered securely and efficiently.</li>
                            <li><b>Performance Cookies:</b>  these cookies allow us to recognise and count the number of visitors and to see how visitors move around our website when they are using it. This helps us to improve the way our website works, for example, by ensuring that users are finding what they are looking for easily. </li>
                        </ul>

                        <p>You can find more information about the individual cookies we use on our website and the purposes for which we use them in the table below:</p>
                        <h5>First Party Cookies</h5>
                        <div class="table-responsive-lg">
                            <table class="table table-flush table-striped table-hover mb-3 table">
                                <thead class="thead-light">
                                    <tr>
                                        <th>Cookie Name</th>
                                        <th>Purpose</th>
                                        <th>Duration</th>
                                        <th>More Information</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>__CookieConsentV300</td>
                                        <td>A JavaScript plugin that alerts users of the use of cookies on this website.</td>
                                        <td>Session</td>
                                        <td>Strictly necessary</td>
                                    </tr>
                                    <tr>
                                        <td>__cf_bm</td>
                                        <td>To read and filter requests from bots.</td>
                                        <td>A few seconds</td>
                                        <td>Strictly necessary</td>
                                    </tr>
                                    <tr>
                                        <td>_cfuvid</td>
                                        <td>The _cfuvid cookie is set on a user’s browser to assign them a unique ID in addition to identifying them through their IP address. Without this cookie, visitors to a site will be recognized as the same user if they share the same IP, and any data collected will be shared for all users with that IP.</td>
                                        <td>End of session</td>
                                        <td>Strictly necessary</td>
                                    </tr>
                                    <tr>
                                        <td>_ga</td>
                                        <td>This cookie name is associated with Google Universal Analytics - which is a significant update to Google's more commonly used analytics service. This cookie is used to distinguish unique users by assigning a randomly generated number as a client identifier. It is included in each page request in a site and used to calculate visitor, session and campaign data for the sites analytics reports. By default it is set to expire after 2 years, although this is customisable by website owners.</td>
                                        <td>730 days</td>
                                        <td>Performance</td>
                                    </tr>
                                    <tr>
                                        <td>_gid</td>
                                        <td>This cookie name is associated with Google Universal Analytics. This appears to be a new cookie and as of Spring 2017 no information is available from Google. It appears to store and update a unique value for each page visited.</td>
                                        <td>1 day</td>
                                        <td>Performance</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <p><i>*Duration means the period that our cookies are installed on your device.</i></p>
                        <p>Please note that the following third parties may also use cookies, which are not controlled by the cookie manager. These named third parties may include, for example, advertising networks and providers of external services like web traffic analysis services.</p>

                        {/* Third party cookies */}

                        <h5>How you can manage cookies used on our website</h5>
                        <p>Cookies do lots of jobs on our website. However, most browsers allow you to disable them if you would like to. You can also disable certain non-essential cookies that are placed on your device when you access our website by clicking the 'Cookie Settings' link at the bottom of each web page on our website, or by clicking here.If you decline cookies, you may find that you have a less personalised experience on our website and that certain parts of our website do not work at all or do not work correctly.</p>

                        <h5>Recording of consent</h5>
                        <p>To adhere to privacy regulations, it is necessary to record what consent you provided for cookies loading should loaded within the browser. A unique identifier is created when you interact with the cookie manager, this identifier is stored in a strictly necessary cookie and is also stored within our secure servers. On being provided with an identifier, we will be able to list the date and time when a selection was made within the cookie manager, along with what consent was provided. For more details on the regulation regarding the recording of consent, please see <a>https://ico.org.uk/for-organisations/guide-to-data-protection/guide-to-the-general-data-protection-regulation-gdpr/consent/how-should-we-obtain-record-and-manage-consent/</a></p>


                        <h5>Privacy</h5>
                        <p>We are committed to using cookies fairly and in accordance with applicable privacy rights. Our Privacy Policy can be accessed here.</p>

                        <h5>Further information</h5>
                        <p>If you have any queries relating to this Cookie Policy please <a href="mailto:gdpr@investisdigital.com">gdpr@investisdigital.com</a>.</p>

                        <h5>Changes to this Cookie Policy</h5>
                        <p>If this Cookie Policy changes, the revised policy will include a new effective date and will be posted on this page. Please check back regularly to keep informed of updates.</p>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default CookiePolicyPage