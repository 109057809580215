import React, { useState, useEffect, useContext } from 'react';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import { Row, Col, Button, Tooltip, OverlayTrigger, Form, InputGroup } from '@themesberg/react-bootstrap';
import { useTranslation } from 'react-i18next';
import Datetime from "react-datetime";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";

import { dashbordDateRangeList } from "../utils/datetime";
import iconSprite from "../assets/Icon/svg-icn-sprite.svg";

import DropdownWithIconComponent from "../components/DropdownWithIcons";
import AppContext from '../components/AppContext';
import ButtonComponent from "../components/Buttons";

const Filters = (props, { selectedWebsiteId, setSelectedWebsiteId, startDate, endDate }) => {

  const [t, i18n] = useTranslation();

  const contextData = useContext(AppContext);

  const [showCustomDatePicker, setShowCustomDatePicker] = useState(false);
  const [showFiltersMenu, setShowFiltersMenu] = useState(false);
  const [datePickerValue, setDatePickerValue] = useState("");
  const [selectedDatePicker, setSelectedDatePicker] = useState({});

  useEffect(() => {
    const lastThirtyDays = moment(startDate).format("MMM DD,YYYY") + ` - ${moment(endDate).format("MMM DD,YYYY")}`;
    let diffDays = moment(endDate).diff(startDate, 'days');
    const getDropdownPickerDetails = dashbordDateRangeList.find(dta => dta.key === ((diffDays !== 30 && diffDays !== 90 && diffDays !== 180) ? "" : lastThirtyDays));
    setSelectedDatePicker(getDropdownPickerDetails);
    setDatePickerValue(lastThirtyDays);
  }, []);

  useEffect(() => {
    if (props?.hideFilter) {
      setShowFiltersMenu(false);
    }
  }, [props?.hideFilter])

  useEffect(() => {
    if (showFiltersMenu) {
      document.addEventListener('click', onClickOutsideListenerFilters);
    }
  }, [showFiltersMenu]);

  useEffect(() => {
    if (startDate) {
      setDefaultPickerValueOnDateChange("startDate");
    }
  }, [startDate]);

  useEffect(() => {
    if (endDate) {
      setDefaultPickerValueOnDateChange("endDate");
    }
  }, [endDate]);

  useEffect(() => {
    if (contextData.userId > 0 && contextData.companyId > 0) {
      // Fetching values from Local storage in case of Page Refresh
      if (localStorage.getItem("IsPageRefresh") == "1") {
        if (localStorage.getItem("WebsiteListWebsiteId")) {
          if (selectedWebsiteId) {
            setSelectedWebsiteId(JSON.parse(localStorage.getItem("WebsiteListWebsiteId")));
          }
        }
        if (localStorage.getItem("WebsiteProfilesIntelConfigId") && localStorage.getItem("WebsiteProfilesIntelConfigId") != "")
          if (localStorage.getItem("SelectedDatePicker") && localStorage.getItem("SelectedDatePicker") != "") {
            setSelectedDatePicker(JSON.parse(localStorage.getItem("SelectedDatePicker")));
          }
      }
    }
  }, [contextData.userId, contextData.companyId]);

  useEffect(() => {
    //renderWebsiteProfiles(selectedWebsiteId);
  }, [selectedWebsiteId]);

  const setDefaultPickerValueOnDateChange = (dateType) => {
    if (datePickerValue.length > 0) {
      let datePickerVle;
      setDatePickerValue(datePickerVle);
    }
  };

  const cancerFilters = function () {
    setShowFiltersMenu(false);
    if (props.cancelFilter !== undefined) {
      props.cancelFilter();
    }
  }

  const applyFilters = function () {
    props.applyFilters();
    setShowFiltersMenu(false);
  }

  const onDatePickerDropdownChange = (data) => {
    if (data && Object.keys(data).length > 0) {
      if (data.key.length > 0) {
        const datePickerSplit = data.key.split("-");
        contextData.changeStartDate(moment(datePickerSplit[0]));
        contextData.changeEndDate(moment(datePickerSplit[1]));
        if (data.compareOn || data.showDatePicker) {
          setDatePickerValue(`${moment(datePickerSplit[0]).format("MMM DD,YYYY")} - ${moment(datePickerSplit[1]).format("MMM DD,YYYY")}`);
        }
      }
      if (data.showDatePicker)
        setShowCustomDatePicker(true);
      else
        setShowCustomDatePicker(false);
    }
    setSelectedDatePicker(data)
    localStorage.setItem("SelectedDatePicker", JSON.stringify(data));// Adding SelectedDatePicker to local stoarge in case of Page Refresh
  };

  const validStartDate = (current) => {
    let currentDay = moment(endDate).format("MM/DD/YYYY");
    return current.isBefore(currentDay);
  };

  const validEndDate = (current) => {
    let yesterday = moment().subtract(1, 'day');
    return current.isBefore(yesterday);
  };

  //slider dropdown
  const onClickOutsideListener = () => {
    setShowCustomDatePicker(false);
    document.removeEventListener("click", onClickOutsideListener)
  }

  const onClickOutsideListenerFilters = () => {
    setShowFiltersMenu(false)
    document.removeEventListener("click", onClickOutsideListenerFilters)
  }

  return (
    <>
      <div className="d-inline-block icon-nav icon-nav-filter ms-2">
        <div className="slider-dropdown-tigger d-inline-block filter-box-tigger icon-nav-list svg-icon-nav-list">
          <span className="icon-nav-box ms-0" onClick={(e) => {
            setShowFiltersMenu(!showFiltersMenu); e.stopPropagation();
            if (props.onIconClick) {
              props.onIconClick();
            }
          }}>
            <svg className="icon-nav">
              {props.iconText && <title>{props.iconText}</title>}
              {props.iconText == undefined && <title>{t("header.Icon_Tooltip_Filter")}</title>}
              <use href={iconSprite + `#Filter`} />
            </svg>
          </span>
        </div>
        {showFiltersMenu &&
          <Form className="date-range-container mt-2 filter-container"
            onMouseLeave={() => {
              document.addEventListener("click", onClickOutsideListenerFilters)
            }} onMouseEnter={() => { document.removeEventListener("click", onClickOutsideListenerFilters) }}>
            <Row className="text-end">
              {props.showHeader !== false && <Col xs={12} sm={12} className="text-left">
                <h4 className="mt-4">{t("header.Filters")}<OverlayTrigger
                  placement="right"
                  trigger={['hover', 'focus']}
                  overlay={
                    <Tooltip>{t("header.filtersMessage")}</Tooltip>
                  }>
                  <Button variant="white" className="btn-help border-0 p-2">
                    <i className="far fa-question-circle lg"></i>
                  </Button>
                </OverlayTrigger></h4>
              </Col>}
              <Col xs={12} sm={12} className="mb-1 position-relative">
                {
                  props.dataSource.map((element) => {

                    if (element.isVisible) {
                      return (<div className="text-left pt-2"><span className="d-block mb-1">{element.label}</span><DropdownWithIconComponent
                        dropdownData={element.data}
                        arrayValue={element.displayText}
                        selectedDropdown={element.selectedItem}
                        onChange={element.callback}
                        showLabel={false}
                        showIconInDropdownItem={false}
                        customClassName="full-width text-overflow"
                      /></div>)
                    } else {
                      return <></>
                    }
                  })
                }
                {props.showDate &&
                  <Form>
                    <div className="text-left pt-2"><span>{t("header.filtersDateLabel")}</span></div>
                    <DropdownWithIconComponent
                      dropdownData={dashbordDateRangeList}
                      arrayValue="value"
                      selectedDropdown={selectedDatePicker}
                      onChange={onDatePickerDropdownChange}
                      showLabel={false}
                      showIconInDropdownItem={false}
                      customClassName="full-width text-overflow"
                    />
                  </Form>
                }
                {showCustomDatePicker &&
                  <Form className="date-range-container" onMouseLeave={() => {
                    document.addEventListener("click", onClickOutsideListener)
                  }} onMouseEnter={() => { document.removeEventListener("click", onClickOutsideListener) }}>
                    <Form.Group className="">
                      <Datetime
                        timeFormat={false}
                        closeOnSelect={false}
                        onChange={contextData.changeStartDate}
                        className="mt-2"
                        isValidDate={validStartDate}
                        renderInput={(props, openCalendar) => (
                          <InputGroup>
                            <Form.Control
                              required
                              type="text"
                              value={startDate ? moment(startDate).format("MMM DD,YYYY") : ""}
                              placeholder={t("header.ToolsEventCalEventListColStartDate")}
                              onFocus={openCalendar}
                              onChange={() => { }} />
                            <InputGroup.Text><FontAwesomeIcon icon={faCalendarAlt} /></InputGroup.Text>
                          </InputGroup>
                        )} />
                      <Datetime
                        timeFormat={false}
                        closeOnSelect={false}
                        onChange={contextData.changeEndDate}
                        isValidDate={validEndDate}
                        className="mt-2"
                        renderInput={(props, openCalendar) => (
                          <InputGroup>
                            <Form.Control
                              required
                              type="text"
                              value={endDate ? moment(endDate).format("MMM DD,YYYY") : ""}
                              placeholder={t("header.ToolsEventCalEventListColEndDate")}
                              onFocus={openCalendar}
                              onChange={() => { }} />
                            <InputGroup.Text><FontAwesomeIcon icon={faCalendarAlt} /></InputGroup.Text>
                          </InputGroup>
                        )} />
                    </Form.Group>
                  </Form>
                }
              </Col>
              <Col className="text-start" lg={12} xl={12}>
                <div className="d-block mt-4">
                  <ButtonComponent
                    onClick={() => applyFilters()}
                    isIcon={false}
                    isPrimary={true}
                    btnText={props.btnOneText ? props.btnOneText : "Ok"}
                    btn_class="btn-md mb-3 filter-btn"
                  />
                </div>
                <div className={props.btnTwoText ? 'd-block' : 'd-none'}>
                  <ButtonComponent
                    onClick={() => cancerFilters()}
                    isIcon={false}
                    isPrimary={false}
                    btnText={props.btnOneText ? props.btnTwoText : "Cancel"}
                    btn_class="btn-md mb-3 filter-btn"
                  />
                </div>
              </Col>
            </Row>
          </Form>
        }
      </div>
    </>
  );
};

export default Filters;
