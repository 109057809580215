import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Card, Col, Row, Nav, Tab, Form, OverlayTrigger, Tooltip, Button } from '@themesberg/react-bootstrap';

import { DataStatusMessage } from "../../utils/constant";
import { METHODS } from '../../apis/utilities/constant.js';
import request from '../../apis/request.js';
import { decryptData } from '../../utils/crypto.js';
import { VITableHeaders } from '../../utils/constant';

import DataChartRenderer from '../DataChartRenderer.js';
import CommonMessageComponent from '../CommonMessageComponent.js';
import DataTableRenderer from '../DataTableRenderer.js';
import ModalComponent from "../VoltModal.js";

export const Charts = ({ cryptoKey, profileId, startDate, endDate, toolId, intelConfigId, isTF, sectionId }) => {
    const [t, i18n] = useTranslation();

    const [multiline, setMultiline] = useState({ transactionStatus: DataStatusMessage.Loading });

    const [tabCategory, setTabCategory] = useState(1);

    const [categoryPieChart, setCategoryPieChart] = useState({ transactionStatus: DataStatusMessage.Loading });
    const [categoryTableChart, setCategoryTableChart] = useState({ transactionStatus: DataStatusMessage.Loading });
    const [countryPieChart, setCountryPieChart] = useState({ transactionStatus: DataStatusMessage.Loading });
    const [countryTableChart, setCountryTableChart] = useState({ transactionStatus: DataStatusMessage.Loading });
    const [trafficPieChart, setTrafficPieChart] = useState({ transactionStatus: DataStatusMessage.Loading });
    const [trafficTableChart, setTrafficTableChart] = useState({ transactionStatus: DataStatusMessage.Loading });
    const [includesNA, setIncludesNA] = useState(false);
    const [masterCategoryData, setMasterCategoryData] = useState([]);
    const [remainingTableItems, setRemainingTableItems] = useState([])

    useEffect(() => {
        if (profileId) {
            setIncludesNA(false);
            setMasterCategoryData([]);
            if (multiline.transactionStatus !== DataStatusMessage.Loading) {
                setCategoryPieChart({ transactionStatus: DataStatusMessage.Loading })
                setCountryPieChart({ transactionStatus: DataStatusMessage.Loading })
                setTrafficPieChart({ transactionStatus: DataStatusMessage.Loading })
                setCategoryTableChart({ transactionStatus: DataStatusMessage.Loading })
                setCountryTableChart({ transactionStatus: DataStatusMessage.Loading })
                setTrafficTableChart({ transactionStatus: DataStatusMessage.Loading })
                setMultiline({ transactionStatus: DataStatusMessage.Loading });
            }

            const selectedStartDate = moment(startDate).format("YYYY-MM-DD");
            const selectedEndDate = moment(endDate).format("YYYY-MM-DD");

            request(`${process.env.REACT_APP_API_KEY}/VisitorAnalytics/GetIntelligencePageViewSeriesByProfileId?profileId=${profileId}&startDate=${selectedStartDate}&endDate=${selectedEndDate}&isTF=${isTF}&toolid=${toolId}&intelconfigid=${intelConfigId}&sectionid=${sectionId}&packageid=5`,
                METHODS.GET, {},
                {
                    Authorization: `Bearer ${decryptData(localStorage.getItem("token"), cryptoKey)}`,
                    "content-type": "application/json"
                })
                .then((response) => {
                    if (response && response.transactionStatus === DataStatusMessage.DataAvailable) {
                        setMultiline({ result: response.result, transactionStatus: response.transactionStatus })
                    }
                    else {
                        setMultiline({ result: [], transactionStatus: response ? response.transactionStatus : DataStatusMessage.ErrorOccured });
                    }
                })
                .catch(() => {
                    setMultiline({ result: [], transactionStatus: DataStatusMessage.ErrorOccured });
                })
        }
    }, [profileId, toolId, sectionId, startDate, endDate]);

    useEffect(() => {
        const selectedStartDate = moment(startDate).format("YYYY-MM-DD");
        const selectedEndDate = moment(endDate).format("YYYY-MM-DD");
        if (multiline.transactionStatus !== DataStatusMessage.Loading) {
            if (tabCategory === 2 && countryPieChart.transactionStatus === DataStatusMessage.Loading) {
                request(`${process.env.REACT_APP_API_KEY}/VisitorAnalytics/GetCountryWisePageViews?profileid=${profileId}&startDate=${selectedStartDate}&endDate=${selectedEndDate}&isTF=${isTF}&toolid=${toolId}&culture=en-US&intelconfigid=${intelConfigId}&sectionId=${sectionId}&packageid=5`,
                    METHODS.GET, {},
                    {
                        Authorization: `Bearer ${decryptData(localStorage.getItem("token"), cryptoKey)}`,
                        "content-type": "application/json"
                    })
                    .then((response) => {
                        if (response && response.transactionStatus === DataStatusMessage.DataAvailable) {
                            let dataObj = [];
                            let remaining = {};
                            response.result.map((obj) => {
                                if (obj.title != 'Remaining') {
                                    dataObj.push({ Title: obj.title, Count: obj.TotalOpenCount })
                                }
                                else {
                                    remaining = { Title: 'Remaining', Count: obj.TotalOpenCount }
                                }
                            });
                            dataObj.push(remaining);
                            setCountryPieChart({ result: response.result, transactionStatus: DataStatusMessage.DataAvailable });
                            setCountryTableChart({
                                header: isTF ? VITableHeaders.CountryPieChartTFTable : VITableHeaders.CountryPieChartTable, data: dataObj, transactionStatus: DataStatusMessage.DataAvailable
                            });
                        }
                        else {
                            setCountryPieChart({ transactionStatus: response ? response.transactionStatus : DataStatusMessage.ErrorOccured })
                        }
                    })
                    .catch(() => {
                        setCountryPieChart({ result: [], transactionStatus: DataStatusMessage.ErrorOccured });
                    })

            }
            else if (tabCategory === 3 && trafficPieChart.transactionStatus === DataStatusMessage.Loading) {
                request(`${process.env.REACT_APP_API_KEY}/VisitorAnalytics/GetTrafficSourceByProfileId?profileid=${profileId}&startDate=${selectedStartDate}&endDate=${selectedEndDate}&toolid=${toolId}&isTF=${isTF}&sectionid=${sectionId}&culture=en-US&intelconfigid=${intelConfigId}`,
                    METHODS.GET, {},
                    {
                        Authorization: `Bearer ${decryptData(localStorage.getItem("token"), cryptoKey)}`,
                        "content-type": "application/json"
                    })
                    .then((response) => {
                        if (response && response.transactionStatus === DataStatusMessage.DataAvailable) {
                            let dataObj = [];
                            response.result.map((obj) => {
                                dataObj.push({ Title: obj.title, Count: obj.TotalOpenCount })
                            });
                            setTrafficPieChart({ result: response.result, transactionStatus: DataStatusMessage.DataAvailable });
                            setTrafficTableChart({
                                header: isTF ? VITableHeaders.TrafficPieChartTFTable : VITableHeaders.TrafficPieChartTable, data: dataObj, transactionStatus: DataStatusMessage.DataAvailable
                            });
                        }
                        else {
                            setTrafficPieChart({ transactionStatus: response ? response.transactionStatus : DataStatusMessage.ErrorOccured })
                        }
                    })
                    .catch(() => {
                        setTrafficPieChart({ transactionStatus: DataStatusMessage.ErrorOccured });
                    })
            }
            else if (tabCategory === 1 && categoryPieChart.transactionStatus === DataStatusMessage.Loading) {
                request(`${process.env.REACT_APP_API_KEY}/VisitorAnalytics/GetCategoryWisePageViews?profileId=${profileId}&startDate=${selectedStartDate}&endDate=${selectedEndDate}&isTF=${isTF}&toolid=${toolId}&intelconfigid=${intelConfigId}&sectionId=${sectionId}&packageid=5`,
                    METHODS.GET, {},
                    {
                        Authorization: `Bearer ${decryptData(localStorage.getItem("token"), cryptoKey)}`,
                        "content-type": "application/json"
                    })
                    .then((response) => {
                        if (response && response.transactionStatus === DataStatusMessage.DataAvailable) {
                            setMasterCategoryData(response.result);
                            modifyCategoryData(response.result);
                        }
                        else {
                            setCategoryPieChart({ transactionStatus: response ? response.transactionStatus : DataStatusMessage.ErrorOccured })
                        }
                    })
                    .catch(() => {
                        setCategoryPieChart({ result: [], transactionStatus: DataStatusMessage.ErrorOccured });
                    })
            }
        }
    }, [tabCategory, multiline.transactionStatus]);

    useEffect(() => {
        if (masterCategoryData.length) {
            modifyCategoryData(masterCategoryData);
        }
    }, [includesNA]);

    const modifyCategoryData = (categoryData) => {
        let pieChartData = [];
        let tableData = [];
        let remaining = { Title: 'Remaining', Count: 0, Data: [] };
        categoryData.filter(x => x.title !== "N/A").map((item, index) => {
            if (index < 5) {
                pieChartData.push(item);
                tableData.push({ Title: item.title, Count: item.TotalOpenCount });
            }
            else {
                remaining.Count += item.TotalOpenCount;
                remaining.Data.push(item);
            }
        });

        if (categoryData.findIndex(x => x.title === "N/A") > -1) {
            let naData = categoryData.find(x => x.title === "N/A");
            if (includesNA) {
                let remainingData = pieChartData.slice(4)[0];
                remaining.Count += remainingData.TotalOpenCount;
                remaining.Data.push(remainingData);
                pieChartData = pieChartData.slice(0, 4);
                tableData = tableData.slice(0, 4);
                pieChartData.push(naData);
                tableData.push({ Title: naData.title, Count: naData.TotalOpenCount });
            }
            else {
                remaining.Count += naData.TotalOpenCount;
                remaining.Data.push(naData);
            }
        }
        pieChartData.push({
            title: remaining.Title,
            TotalClickCount: 0,
            TotalOpenCount: remaining.Count,
            ToolTipTitle: " of Total Page Views"
        });
        tableData = tableData.sort((a, b) => b.Count - a.Count);
        tableData.push(remaining);
        setCategoryPieChart({ result: pieChartData, transactionStatus: DataStatusMessage.DataAvailable });
        setCategoryTableChart({ header: isTF ? VITableHeaders.CategoryPieChartTFTable : VITableHeaders.CategoryPieChartTable, data: tableData, transactionStatus: DataStatusMessage.DataAvailable });
    }

    const onChangeIncludeSwitch = (e) => {
        setIncludesNA(e.target.checked);
    }

    const ShowRemainingItems = (rowobj) => {
        let remainingData = []
        rowobj.data.Data.map((item) => {
            let obj = { Title: item.title, Count: item.TotalOpenCount }
            remainingData.push(obj)
        })
        setRemainingTableItems(remainingData)
    }
    const createTooltip = (tooltipKey, placement) => {
        if (tooltipKey !== "") {
            return (
                <OverlayTrigger
                    placement={placement}
                    trigger={["hover", "focus"]}
                    overlay={
                        <Tooltip>
                            <div>{tooltipKey}</div>
                        </Tooltip>
                    }
                >
                    <Button variant="white" className="btn-help border-0 p-0 ps-1">
                        <i className="far fa-question-circle lg"></i>
                    </Button>
                </OverlayTrigger>
            );
        }
    }

    return (
        <>
            <div className="d-inline-block pt-3 ps-3 w-100">
                <div className="shadow-soft border rounded border-light pt-4 w-100 nested-card-container">
                    <Row>
                        <Col>
                            <h5 className="ms-3">{isTF ? (toolId > 0 ? t("header.VisitorInsights") : t("header.VisitorInsightByTool")) : t("header.visitorAnalyticsHeadingText")}</h5>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="tools-dashboard-card-loader">
                                <Card border="light" className="bg-white shadow-sm ms-1 p-2">
                                    <Card.Body>
                                        {
                                            multiline.transactionStatus === DataStatusMessage.DataAvailable && <DataChartRenderer
                                                data={
                                                    multiline.result
                                                }
                                                chartType="multiline"
                                                customAttributes={{ isDefaultMultilineValuesEnabled: true, titleAboveToggles: "Visitor Companies", tooltipText: "header.VisitorInsightsCardNameTooltipForTnf", keysToDisable: ["Unique"] }} /> //keysToDisable is list of item not having toggle associated with it
                                        }
                                        {
                                            multiline.transactionStatus !== DataStatusMessage.DataAvailable &&
                                            <CommonMessageComponent transactionStatus={multiline.transactionStatus}></CommonMessageComponent>
                                        }
                                    </Card.Body>
                                </Card>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="tools-dashboard-card-loader">
                                <Card border="light" className="bg-white shadow-sm">
                                    <Card.Body>
                                        <Tab.Container defaultActiveKey="Category">
                                            <Nav fill variant="pills" className="tab subtab ms-3 me-3 mt-4">
                                                <Nav.Item className="pe-0">
                                                    <Nav.Link eventKey="Category" onClick={() => { setTabCategory(1) }} className="mb-sm-3 mb-md-0">
                                                        <span className="d-inline-block w-100 text-left tools-tab-label-text">
                                                            {t("header.VisitorsIconFilterVisitorsLabelCategoryText")}
                                                        </span>
                                                    </Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="Country" onClick={() => { setTabCategory(2) }} className="mb-sm-3 mb-md-0">
                                                        <span className="d-inline-block w-100 text-left tools-tab-label-text">
                                                            {t("header.VisitorsIconFilterVisitorsLabelCountryText")}
                                                        </span>
                                                    </Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="TrafficSource" onClick={() => { setTabCategory(3) }} className="mb-sm-3 mb-md-0">
                                                        <span className="d-inline-block w-100 text-left tools-tab-label-text">
                                                            {t("header.VisitorsActivityPopTrafficTabText")}
                                                        </span>
                                                    </Nav.Link>
                                                </Nav.Item>
                                            </Nav>
                                            <Tab.Content>
                                                <Tab.Pane eventKey="Category" className="mt-4" >
                                                    {tabCategory === 1 && <>
                                                        {
                                                            categoryPieChart.transactionStatus === DataStatusMessage.DataAvailable &&
                                                            <Row>
                                                                <Col md={6}>
                                                                    <DataChartRenderer
                                                                        data={
                                                                            categoryPieChart.result
                                                                        }
                                                                        cardProperty={{ "x": 0, "y": 0, "w": 6, "h": 8 }} chartType="pie" />
                                                                </Col>
                                                                <Col md={6}>
                                                                    {
                                                                        categoryTableChart.transactionStatus === DataStatusMessage.DataAvailable && <DataTableRenderer
                                                                            header={categoryTableChart.header}
                                                                            dataSet={categoryTableChart.data}
                                                                            transactionStatus={categoryTableChart.transactionStatus}
                                                                            customAttributes={{ displayRowsPerPage: 15, showPagination: true, showMoreButton: true }}
                                                                            rowClickEventShowMore={ShowRemainingItems}
                                                                        ></DataTableRenderer>
                                                                    }
                                                                </Col>
                                                                <div className="toggle-switch-container">
                                                                    {<Form className="d-inline-block ms-3">
                                                                        <Form.Check
                                                                            type="switch"
                                                                            className={includesNA ? "switch-checked" : "switch-uncheck"}
                                                                            checked={includesNA}
                                                                            onChange={(e) => onChangeIncludeSwitch(e)}
                                                                            label={
                                                                                <span>
                                                                                    {t("header.VisitorInsightIncludeNA")}
                                                                                    {createTooltip(t("List of companies for which categories are not identified."), "right")}
                                                                                </span>
                                                                            }
                                                                            id="switchIncludeNA"
                                                                            htmlFor="switchIncludeNA"
                                                                            disabled={false}

                                                                        />

                                                                    </Form>}
                                                                </div>
                                                            </Row>
                                                        }
                                                        {
                                                            categoryPieChart.transactionStatus !== DataStatusMessage.DataAvailable &&
                                                            <Row>
                                                                <Col md={12}>
                                                                    <CommonMessageComponent transactionStatus={categoryPieChart.transactionStatus}></CommonMessageComponent>
                                                                </Col>
                                                            </Row>
                                                        }
                                                    </>}
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="Country" className="mt-4">
                                                    {tabCategory === 2 && <>
                                                        {
                                                            countryPieChart.transactionStatus === DataStatusMessage.DataAvailable && <Row>
                                                                <Col md={6}>
                                                                    <DataChartRenderer
                                                                        data={
                                                                            countryPieChart.result
                                                                        }
                                                                        cardProperty={{ "x": 0, "y": 0, "w": 6, "h": 8 }} chartType="pie" />
                                                                </Col>
                                                                <Col md={6}>
                                                                    {
                                                                        countryTableChart.transactionStatus === DataStatusMessage.DataAvailable && <DataTableRenderer
                                                                            header={countryTableChart.header}
                                                                            dataSet={countryTableChart.data}
                                                                            transactionStatus={countryTableChart.transactionStatus}
                                                                            customAttributes={{ displayRowsPerPage: 15, showPagination: true }}
                                                                        ></DataTableRenderer>
                                                                    }
                                                                </Col>
                                                            </Row>
                                                        }
                                                        {
                                                            countryPieChart.transactionStatus !== DataStatusMessage.DataAvailable &&
                                                            <Row>
                                                                <Col md={12}>
                                                                    <CommonMessageComponent transactionStatus={countryPieChart.transactionStatus}></CommonMessageComponent>
                                                                </Col>
                                                            </Row>
                                                        }
                                                    </>}
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="TrafficSource" className="mt-4">
                                                    {tabCategory === 3 && <>
                                                        {
                                                            trafficPieChart.transactionStatus === DataStatusMessage.DataAvailable && <Row>
                                                                <Col md={6}>
                                                                    <DataChartRenderer
                                                                        data={
                                                                            trafficPieChart.result
                                                                        }
                                                                        cardProperty={{ "x": 0, "y": 0, "w": 6, "h": 8 }} chartType="pie" />
                                                                </Col>
                                                                <Col md={6}>
                                                                    {
                                                                        trafficTableChart.transactionStatus === DataStatusMessage.DataAvailable && <DataTableRenderer
                                                                            header={trafficTableChart.header}
                                                                            dataSet={trafficTableChart.data}
                                                                            transactionStatus={trafficTableChart.transactionStatus}
                                                                            customAttributes={{ displayRowsPerPage: 15, showPagination: true }}
                                                                        ></DataTableRenderer>
                                                                    }
                                                                </Col>
                                                            </Row>
                                                        }
                                                        {
                                                            trafficPieChart.transactionStatus !== DataStatusMessage.DataAvailable &&
                                                            <Row>
                                                                <Col md={12}>
                                                                    <CommonMessageComponent transactionStatus={trafficPieChart.transactionStatus}></CommonMessageComponent>
                                                                </Col>
                                                            </Row>
                                                        }
                                                    </>}
                                                </Tab.Pane>
                                            </Tab.Content>
                                        </Tab.Container>
                                    </Card.Body>
                                </Card>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>

            <ModalComponent
                title={"Remaining Categories"}
                showCloseButton={true}
                open={remainingTableItems?.length > 0 ? true : false}
                handleClose={() => { setRemainingTableItems([]) }}
                className="visitor-analytics-modal tnf-insights-modal ps-0"
            >
                <Row>
                    <Col>
                        {
                            <DataTableRenderer
                                header={(isTF ? VITableHeaders.CategoryPieChartTFTable : VITableHeaders.CategoryPieChartTable).map(header => ({
                                    ...header,
                                    shortTitle: header.key === "Title" ? "Remaining Categories" : header.shortTitle
                                }))}
                                dataSet={remainingTableItems.sort((a, b) => b.Count - a.Count)}
                                transactionStatus={categoryTableChart.transactionStatus}
                                customAttributes={{ displayRowsPerPage: 10, showPagination: true, }}
                            ></DataTableRenderer>
                        }
                    </Col>
                </Row>


            </ModalComponent>
        </>
    )
}
