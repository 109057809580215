import { getCardDataFromGA, getCardDataFromGABar, getCookiesCardDataPieChart } from "../../apis/services/websitePageService";
import { COMPONENT } from "../../apis/utilities/constant";

export const apiCharts = [
    {
        id: 1,
        service: (cryptoKey, payload, callBackFn) => { getCookiesCardDataPieChart(cryptoKey, payload, callBackFn) },
    },
    {
        id: 2,
        service: (cryptoKey, payload, callBackFn) => { getCardDataFromGA(cryptoKey, "sessions", payload, callBackFn, COMPONENT.AUDIENCETREND, 1) },
    },
    {
        id: 5,
        service: (cryptoKey, payload, callBackFn) => { getCardDataFromGABar(cryptoKey, payload, callBackFn, COMPONENT.TRAFFICSOURCE) },
    },
    {
        id: 7,
        service: (cryptoKey, payload, callBackFn) => { getCardDataFromGA(cryptoKey, "users", payload, callBackFn, COMPONENT.USERS, 1) },
    }

]

export const getWebsite = (profileId, sourceMedium, startDate, endDate, intellConfigId, companyid, culture) => {
    let websiteData = [
        {
            isSummeryShown: true,
            isResultShownFromApi: true,
            id: "2",
            layout: "{\"x\":6,\"y\":24,\"w\":6,\"h\":8}",
            layoutone: `{}`,
            layouttwo: `{}`,
            name: "Audience trends",
            mainlayout: `{}`,
            vizStateSecond: `{}`,
            chartType: "line",
            legends: [{ name: "Sessions" }],
            enableOption: true,
        },
        {
            isSummeryShown: true,
            chartType: "bar",
            isResultShownFromApi: true,
            id: "5",
            layout: "{\"x\":0,\"y\":24,\"w\":6,\"h\":8}",
            layoutone: `{}`,
            layouttwo: `{}`,
            name: "Traffic source",
            mainlayout: `{}`,
            vizStateSecond: `{}`,
            legends: [{ name: `${sourceMedium}` }],
            enableOption: true,
        },
        {
            isResultShownFromApi: true,
            apiUrl: { url: `${process.env.REACT_APP_API_KEY}/WebsiteAnalytics/GetVisitorWithWatchlistByProfileId?profileid=${profileId}&intellConfigId=${intellConfigId}&startDate=${startDate}&endDate=${endDate}&companyid=${companyid}&packageid=5&isshort=1`, type: "get", count: "" },
            id: "3",
            layout: "{\"x\":0,\"y\":24,\"w\":6,\"h\":10}",
            layoutone: `{}`,
            layouttwo: `{}`,
            name: "Visitor Insights",
            mainlayout: `{}`,
            vizStateSecond: `{}`,
            legends: ``,
            enableOption: true,
            summarylabel: "Companies",
        },
        {
            isResultShownFromApi: true,
            apiUrl: { url: `${process.env.REACT_APP_API_KEY}/WebsiteAnalytics/GetWatchedVisitorByWebsite?profileid=${profileId}&intellConfigId=${intellConfigId}&startDate=${startDate}&endDate=${endDate}&companyid=${companyid}&packageid=5&isshort=1`, type: "get", count: "" },
            id: "4",
            layout: "{\"x\":6,\"y\":24,\"w\":6,\"h\":10}",
            layoutone: `{}`,
            layouttwo: `{}`,
            name: "Watchlist",
            mainlayout: `{}`,
            vizStateSecond: `{}`,
            legends: ``,
            enableOption: true,
            summarylabel: "Watchlist",
        },
        {
            isSummeryShown: true,
            isResultShownFromApi: true,
            chartType: "pie",
            id: "1",
            layout: "{\"x\":0,\"y\":24,\"w\":6,\"h\":8}",
            layoutone: `{}`,
            layouttwo: `{}`,
            name: "Cookies",
            vizStateSecond: `{}`,
            mainlayout: `{}`,
            legends: ``,
            enableOption: true,
        },
        {
            id: "6",
            layout: "{\"x\":6,\"y\":24,\"w\":6,\"h\":8}",
            layoutone: `{}`,
            layouttwo: `{}`,
            name: "Search",
            vizStateSecond: `{}`,
            legends: ``,
            enableOption: true,
            apiUrl: { url: `${process.env.REACT_APP_API_KEY}/WebsiteAnalytics/GetCardDataFromGAV2?StartDate=${startDate}&EndDate=${endDate}&profileID=${profileId}&Matrix=searchResultViews&Dimensions=searchKeyword&Component=${COMPONENT.SEARCH}&isShort=1`, type: "get", count: "" },
        },
        {
            isSummeryShown: true,
            isResultShownFromApi: true,
            chartType: "line",
            id: "7",
            layout: "{\"x\":0,\"y\":25,\"w\":12,\"h\":8}",
            layoutone: "{\"x\":0,\"y\":24,\"w\":6,\"h\":7}",
            layouttwo: "{\"x\":6,\"y\":24,\"w\":6,\"h\":7}",
            name: "User",
            nametwoo: "Top 5 contries",
            vizStateSecond: `{\"chartType\":\"table\",\"pivotConfig\":{\"x\":[\"Googleanalyticsusersdata.country\"],\"y\":[\"measures\"],\"fillMissingDates\":true,\"joinDateRange\":false,\"limit\":5},\"query\":{\"dimensions\":[\"Googleanalyticsusersdata.country"],\"timeDimensions\":[{\"dimension\": \"Googleanalyticsusersdata.gadate\", \"dateRange\": [\"${startDate}\",\"${endDate}\"]}],\"order\":[[\"Googleanalyticsusersdata.users\",\"desc\"]],\"limit\":5,\"measures\":[\"Googleanalyticsusersdata.users\"],\"filters\":[{\"member\": \"Googleanalyticsdata.profileid\", \"operator\": \"equals\",\"values\": [\"${profileId}\"]}]},\"tableCaption\":\"Top 5 Countries\"}`,
            legends: [{ name: "User" }],
            enableOption: true,
            apiUrl: { url: `${process.env.REACT_APP_API_KEY}/WebsiteAnalytics/GetCardDataFromGAV2?StartDate=${startDate}&EndDate=${endDate}&profileID=${profileId}&Matrix=users&Dimensions=country&Component=${COMPONENT.COUNTRY}&culture=${culture}&isShort=1`, type: "get", count: "" },
        },
        {
            id: "8",
            layout: "{\"x\":0,\"y\":25,\"w\":12,\"h\":10}",
            layoutone: `{}`,
            layouttwo: `{}`,
            name: "Content Insights",
            mainlayout: `{}`,
            vizStateSecond: `{}`,
            apiUrl: { url: `${process.env.REACT_APP_API_KEY}/WebsiteAnalytics/GetCardDataFromGAV2?StartDate=${startDate}&EndDate=${endDate}&profileID=${profileId}&Matrix=screenPageViews,averageSessionDuration,bounceRate,totalUsers&Dimensions=pageTitle,pagePath&Component=${COMPONENT.CONTENTINSIGHT}&isShort=1`, type: "get", count: "" },
            legends: ``,
            enableOption: true,
        }
    ];
    return websiteData;
};


