import { faFileAlt } from "@fortawesome/free-regular-svg-icons";

export const menuItemsDetais = [
    //Start For Main Menu
    {
        title: "Website",
        icon: "Website",
        link: "",
        Key: "website",
        isCustomIcon: true,
        hasButtonClick: false,

    },
    {
        title: "IR Tools Analytics",
        icon: "Chart",
        link: "",
        Key: "IR Tools Analytics",
        isCustomIcon: true,
        hasButtonClick: false,

    },
    {
        title: "Tools Insight",
        icon: "",
        link: "/tnf/tools-overview",
        Key: "tools-overview",
        sid: 0
    },
    {
        title: "Visitor Analytics",
        icon: "",
        link: "/tnf/insights",
        Key: "insights",
        sid: 1
    },
    {
        title: "Notification",
        icon: "",
        link: "/tnf/notifications",
        Key: "notifications",
        sid: 21
    },
    {
        title: "Share Price Center",
        icon: "",
        link: "/tnf/share-price-center-page",
        Key: "share-price-center-page",
        sid: 2,
        toolid: 1
    },
    {
        title: "Share Price Mini Chart",
        icon: "",
        link: "/tnf/share-price-mini-chart-page",
        Key: "share-price-mini-chart-page",
        sid: 3,
        toolid: 2
    },
    {
        title: "Corporate Alert Service",
        icon: "",
        link: "/tnf/corporate-alert-service-page",
        Key: "corporate-alert-service-page",
        sid: 4,
        toolid: 3
    },
    {
        title: "Dividend Calculator",
        icon: "",
        link: "/tnf/dividend-calculator-page",
        Key: "dividend-calculator-page",
        sid: 5,
        toolid: 4
    },
    {
        title: "Largest Shareholders",
        icon: "",
        link: "/tnf/largest-shareholders-page",
        Key: "largest-shareholders-page",
        sid: 6,
        toolid: 5
    },
    {
        title: "Regulatory News",
        icon: "",
        link: "/Tnf/regulatory-news-page",
        Key: "regulatory-news",
        sid: 7,
        toolid: 6
    },
    {
        title: "OMX News",
        icon: "",
        link: "/Tnf/OMX-news-page",
        Key: "OMX-news",
        sid: 8,
        toolid: 7
    },
    {
        title: "Share Price Table",
        icon: "",
        link: "/Tnf/share-price-table-page",
        Key: "share-price-table",
        sid: 9,
        toolid: 8
    },
    {
        title: "Historical Price Lookup",
        icon: "",
        link: "/Tnf/historical-price-lookup-page",
        Key: "historical-price-lookup",
        sid: 10,
        toolid: 9
    },
    {
        title: "Total Return Center",
        icon: "",
        link: "/Tnf/total-return-center-page",
        Key: "total-return-center",
        sid: 11,
        toolid: 10
    },
    {
        title: "Share Price Calculator",
        icon: "",
        link: "/Tnf/share-price-calculator-page",
        Key: "share-price-calculator",
        sid: 12,
        toolid: 11
    },
    {
        title: "Exchange List",
        icon: "",
        link: "/Tnf/exchange-list-page",
        Key: "exchange-list",
        sid: 13,
        toolid: 12
    },
    {
        title: "OMX Filings",
        icon: "",
        link: "/Tnf/OMX-filings-page",
        Key: "OMX-filings",
        sid: 14,
        toolid: 13
    },
    {
        title: "Cision Filings",
        icon: "",
        link: "/Tnf/cision-filings-page",
        Key: "cision-filings",
        sid: 15,
        toolid: 14
    },
    {
        title: "DGAP Filings",
        icon: "",
        link: "/Tnf/DGAP-filings-page",
        Key: "DGAP-filings",
        sid: 16,
        toolid: 15
    },
    {
        title: "SEC Filings",
        icon: "",
        link: "/Tnf/SEC-filings-page",
        Key: "SEC-filings",
        sid: 17,
        toolid: 16
    },
    {
        title: "US Press Release",
        icon: "",
        link: "/Tnf/US-press-release-page",
        Key: "US-press-release",
        sid: 18,
        toolid: 17
    },
    {
        title: "ASX Announcement",
        icon: "",
        link: "/Tnf/ASX-announcement-page",
        Key: "ASX-announcement",
        sid: 19,
        toolid: 18
    },
    {
        title: "Modular Finance News",
        icon: "",
        link: "/Tnf/modular-finance-news-page",
        Key: "modular-finance-news",
        sid: 20,
        toolid: 19
    },
    {
        title: "STT News",
        icon: "",
        link: "/Tnf/STT-news-page",
        Key: "STT-news",
        sid: 21,
        toolid: 20
    },
    {
        title: "Reports And Presentations",
        icon: "",
        link: "/Tnf/reports-and-presentations-page",
        Key: "reports-and-presentations",
        sid: 22,
        toolid: 21
    },
    {
        title: "Alerts",
        icon: "bell",
        link: "",
        Key: "alert",
        isCustomIcon: true,
        hasButtonClick: false
    },
    {
        title: "Tools",
        icon: "Tools",
        link: "",
        Key: "tools",
        isCustomIcon: true,
        hasButtonClick: false
    },
    {
        title: "Social Media",
        icon: "SocialMedia",
        link: "",
        Key: "social-media",
        isCustomIcon: true,
        hasButtonClick: false
    },
    {
        title: "Webcasting",
        icon: "Video",
        link: "",
        Key: "webcast",
        isCustomIcon: true,
        hasButtonClick: false
    },
    {
        title: "Webcasting",
        icon: "Video",
        link: "",
        Key: "webcasting-marketing",
        isCustomIcon: true,
        hasButtonClick: false
    },
    {
        title: "Timeline",
        icon: faFileAlt,
        link: "",
        Key: "timeline",
        isCustomIcon: false,
        hasButtonClick: false
    },
    {
        title: "App",
        icon: "App",
        link: "",
        Key: "app",
        isCustomIcon: true,
        hasButtonClick: false
    },
    {
        title: "Digest",
        icon: faFileAlt,
        link: "",
        Key: "digest",
        isCustomIcon: false,
        hasButtonClick: false
    },
    {
        title: "News Direct",
        icon: faFileAlt,
        link: "",
        Key: "news-direct",
        isCustomIcon: false,
        hasButtonClick: false
    },
    {
        title: "Reports",
        icon: "File",
        link: "/reports",
        Key: "reports",
        isCustomIcon: true,
        hasButtonClick: false
    },
    {
        title: "Service Centre",
        icon: "ServiceCentre",
        link: "/service-centre-pages",
        Key: "service-centre",
        isCustomIcon: true,
        hasButtonClick: false
    },
    {
        title: "SEO",
        icon: faFileAlt,
        link: "",
        Key: "seo",
        isCustomIcon: false,
        hasButtonClick: false
    },
    // {
    //     title: "Settings",
    //     icon: "Settings",
    //     link: "",
    //     Key: "settings",
    //     isCustomIcon: true,
    //     hasButtonClick: false
    // },
    {
        title: "My account",
        icon: "",
        link: "/settings/myaccount-page",
        Key: "/settings",
    },
    {
        title: "Digest subscriptions",
        icon: "",
        link: "/settings/digestsubscriptions-page",
        Key: "settings",
    },
    {
        title: "My documents",
        icon: "",
        link: "/settings/mydocuments-page",
        Key: "settings",
    },
    //End For Main Menu
    {
        title: "Website analytics",
        icon: "Chart",
        link: "/website",
        key: "",
        isCustomIcon: true,
        dispOrder: 1
    },
    {
        title: "Visitor analytics",
        icon: "Chart",
        link: "/website/visitoranalytics-page",
        key: "/website",
        isCustomIcon: true,
        dispOrder: 2
    },
    {
        title: "Visitors",
        icon: "Chart",
        link: "/website/visitoranalytics-page",
        key: "/website",
        isCustomIcon: true,
        dispOrder: 2
    },
    {
        title: "Social Media analytics",
        icon: "Chart",
        link: "/social-media-pages",
        key: "social-media",
        isCustomIcon: true
    },
    {
        title: "Edit Website",
        icon: "",
        link: "",
        key: "",
        isCustomIcon: false,
        hasButtonClick: true,
        dispOrder: 4
    },
    {
        title: "Editable Pages",
        icon: "",
        link: "/editable-pages",
        key: "editable-pages",
        isCustomIcon: false,
        hasButtonClick: false,
        dispOrder: 8
    },
    {
        title: "Preview Website",
        icon: "",
        link: "/preview-website-pages",
        key: "preview-website",
        isCustomIcon: false,
        hasButtonClick: true,
        dispOrder: 5
    },
    {
        title: "Media Library",
        icon: "",
        link: "/media-library-pages",
        key: "media-library",
        isCustomIcon: false,
        hasButtonClick: true,
        dispOrder: 7
    },
    {
        title: "Visitor anlytics",
        icon: "Chart",
        link: "/visitor-analytics-page",
        key: "visitor-analytics",
        isCustomIcon: true,
        hasButtonClick: false,
        dispOrder: 1
    },
    {
        title: "Page analytics",
        icon: "Chart",
        link: "/website/page-analytics-page",
        key: "page-analytics",
        isCustomIcon: true,
        hasButtonClick: false,
        dispOrder: 3

    },
    {
        title: "Pages",
        icon: "Chart",
        link: "/website/page-analytics-page",
        key: "page-analytics",
        isCustomIcon: true,
        hasButtonClick: false,
        dispOrder: 3

    },
    // {
    //     title: "Video analytics",
    //     icon: faChartBar,
    //     link: "/video-analytics-page",
    //     key: "video-analytics",
    //     isCustomIcon: false,
    //     hasButtonClick: false,
    //     dispOrder: 3
    // },
    {
        title: "Notifications",
        icon: "",
        link: "/notification-page",
        key: "",
        isCustomIcon: false,
        hasButtonClick: false,
        dispOrder: 6
    },
    // {
    //     title: "Feedback",
    //     icon: "",
    //     link: "/feedback-analytics-page",
    //     key: "feedback-analytics",
    //     isCustomIcon: false,
    //     hasButtonClick: false,
    //     dispOrder: 6
    // },

    {
        title: "Alert Statistics",
        icon: "Chart",
        link: "/alert/statistics",
        key: "/form",
        isCustomIcon: true,
        hasButtonClick: false,
        dispOrder: 1
    },

    {
        title: "Manage Manual Alerts",
        icon: "",
        link: "/alert/manual-alerts",
        key: "",
        isCustomIcon: false,
        hasButtonClick: false,
        dispOrder: 2

    },
    {
        title: "Send Ad-hoc Alerts",
        icon: "",
        link: "/alert/adhocalert",
        key: "",
        isCustomIcon: false,
        hasButtonClick: false,
        dispOrder: 3

    },
    {
        title: "Manage Subscribers",
        icon: "",
        link: "/alert/manage-subscription",
        key: "",
        isCustomIcon: false,
        hasButtonClick: false,
        dispOrder: 5
    },
    {
        title: "Alert Summary",
        icon: "",
        link: "/alert/summary",
        key: "",
        isCustomIcon: false,
        hasButtonClick: false,
        dispOrder: 6
    },
    {
        title: "Archived Alerts",
        icon: "",
        link: "/alert/archieve",
        key: "",
        isCustomIcon: false,
        hasButtonClick: false,
        dispOrder: 4
    },
    {
        title: "Manage Events",
        icon: "",
        link: "/tools/event-calender",
        key: "",
        isCustomIcon: false,
        hasButtonClick: false,
        dispOrder: 1
    },
    {
        title: "Add Event",
        icon: "",
        link: "/tools/add-event-pages",
        key: "",
        isCustomIcon: false,
        hasButtonClick: false,
        dispOrder: 2
    },
    // {
    //     title: "Upload/Download Data",
    //     icon: "",
    //     link: "/upload-download-pages",
    //     key: "upload-download",
    //     isCustomIcon: false,
    //     hasButtonClick: false
    // },
    // {
    //     title: "Publish Message",
    //     icon: "",
    //     link: "/publish-message-pages",
    //     key: "publish-message",
    //     isCustomIcon: false,
    //     hasButtonClick: false
    // },
    {
        title: "Webcast Statistics",
        icon: "Chart",
        link: "/webcast/manage-statistics",
        key: "",
        isCustomIcon: true,
        hasButtonClick: false,
        dispOrder: 1
    },
    {
        title: "Manage Users",
        icon: "",
        link: "/webcast/manage-users",
        key: "",
        isCustomIcon: false,
        hasButtonClick: false,
        dispOrder: 2
    },
    {
        title: "Manage Documents",
        icon: "",
        link: "/manage-documents-pages",
        key: "manage-documents",
        isCustomIcon: false,
        hasButtonClick: false
    },
    {
        title: "App Statistics",
        icon: "Chart",
        link: "/app-statistics-pages",
        key: "app-statistics",
        isCustomIcon: true,
        hasButtonClick: false
    },
    {
        title: "Add Documents",
        icon: "",
        link: "/add-documents-pages",
        key: "add-documents",
        isCustomIcon: false,
        hasButtonClick: false
    },
    {
        title: "Banner Image",
        icon: "",
        link: "/banner-image-pages",
        key: "banner-image",
        isCustomIcon: false,
        hasButtonClick: false
    },
    {
        title: "Keywords",
        icon: "",
        link: "",
        key: "",
        isCustomIcon: false,
        hasButtonClick: false
    },
    {
        title: "Indexed Pages",
        icon: "",
        link: "",
        key: "",
        isCustomIcon: false,
        hasButtonClick: false
    },
    {
        title: "Watchlist",
        icon: "",
        link: "",
        key: "",
        isCustomIcon: false,
        hasButtonClick: false
    },
    {
        title: "SEO Comparison",
        icon: "",
        link: "/dashbord",
        key: "",
        isCustomIcon: false,
        hasButtonClick: false
    },
    {
        title: "Website",
        icon: "Website",
        link: "",
        Key: "website-marketing",
        isCustomIcon: true,
        hasButtonClick: false
    },
    {
        title: "Alerts",
        icon: "bell",
        link: "",
        Key: "alerts-marketing",
        isCustomIcon: true,
        hasButtonClick: false
    },
    {
        title: "Tools",
        icon: "Tools",
        link: "",
        Key: "tools-marketing",
        isCustomIcon: true,
        hasButtonClick: false
    },
    {
        title: "Social Media",
        icon: "comment",
        link: "/sm-marketing",
        Key: "socialmedia-marketing",
        isCustomIcon: true,
        hasButtonClick: false
    },
    {
        title: "Webcasting",
        icon: "video",
        link: "/webcast-marketing",
        Key: "webcasting-marketing",
        isCustomIcon: true,
        hasButtonClick: false
    },

    {
        title: "App",
        icon: "App",
        link: "/app-marketing",
        Key: "app-marketing",
        isCustomIcon: true,
        hasButtonClick: false
    },
    {
        title: "T&F Analytics",
        icon: "Chart",
        link: "",
        Key: "t&f analytics-marketing",
        isCustomIcon: true,
        hasButtonClick: false
    },
    {
        title: "No Access",
        icon: "No Access",
        link: "/no-access",
        Key: "no-access",
        isCustomIcon: true,
        hasButtonClick: false
    },
    // {
    //     title: "Logout",
    //     icon: faSignOutAlt,
    //     link: "",
    //     Key: "Logout",
    //     isCustomIcon: false,
    //     hasButtonClick: true,
    //     className: "dasddasdsa"

    // }
];

export const WebsiteChannelDetailURL
    = ["/website/cookie-analytics-page", "/website/search-page", "/website/useranalytics-page", "/tools-overview", "/website/visitoranalytics-page/watchlist"]

export const SettingsMenuURL
    = ["/settings/myaccount-page", "/settings/mydocuments-page", "/settings/digestsubscriptions-page"]

export const ToolsMenuURL
    = ["/tools/event-calender", "/tools/add-event-pages"]