import React, { useContext, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { Image } from '@themesberg/react-bootstrap';

import LoaderImage from "../assets/img/LoaderGraphic.svg";
import { isEmpty, updateLocalStorageObject } from "../AppConfig";
import { menuItemsDetais } from "../utils/sidebar";
import request from '../apis/request';
import { baseURL, METHODS } from '../apis/utilities/constant';
import { decryptData, encryptData } from "../utils/crypto";

import AppContext from '../components/AppContext';

function SSOLogin() {
  const navigate = useNavigate();

  const contextData = useContext(AppContext);

  //'/bypasslogin/0b1bd666-070a-4f3c-87d7-758c2df99189/10.100.25.10/Vaibhav.Patel@idx.inc/okta'
  const { token, ip, emailID, provider } = useParams();

  const [selectedLanguage, setSelectedLanguage] = useState({});
  const [isLanguageChanged, setIsLanguageChanged] = useState(false);
  const [showLoader, setShowLoader] = useState(false);

  const [checkBypassLogin, setCheckBypassLogin] = useState(false)

  let isssoLogin = false;

  useEffect(() => {
    isssoLogin = true;
    const parameter = {
      emailAddress: emailID,
      IPAddress: ip,
      SSOToken: token
    };
    try {
      if (!checkBypassLogin) {
        request(baseURL + `/Auth/BypassLogin`, METHODS.POST, parameter,
          {
            "content-type": "application/json"
          }).then((response) => {
            if (response) {
              handleSubmitbySSO();
              if (response.userId > 0) {
                setCheckBypassLogin(true);
              }
            }
          })
      }
    } catch (error) {
      console.log("login error from sso", error);
    } finally {
      setShowLoader(false);
    }
  }, [checkBypassLogin])

  useEffect(() => {
    if (contextData.userId > 0 && contextData.companyId > 0) {
      getMenu();
    }
  }, [contextData.userId, contextData.companyId]);

  const handleSubmitbySSO = async () => {
    let Password = '';
    const UserCrdentials = { EmailAddress: emailID, Password: Password, isssoLogin: isssoLogin };
    const response = await axios.post(`${process.env.REACT_APP_API_KEY}/Auth/ValidateLogin`, UserCrdentials);

    const { userToken, userId, languagePreference, firstName, lastName, email, cryptoKey } = response.data;
    contextData.changeCryptoKey(cryptoKey);
    const companies = response.data.resultData;
    let companyId = companies && Array.isArray(companies) && companies.length > 0 ? companies[0].companyId : 0;
    let userName = (!isEmpty(firstName) && !isEmpty(lastName)) ? firstName.concat(lastName) : "";
    let langPref;
    if (isLanguageChanged) {
      langPref = (selectedLanguage && Object.keys(selectedLanguage).length > 0) ? selectedLanguage.id : languagePreference;
    }
    else {
      langPref = languagePreference;
    }

    localStorage.setItem("sessionTime", new Date());
    localStorage.setItem("token", encryptData(userToken, cryptoKey));
    localStorage.setItem("userId", encryptData(userId, cryptoKey));
    localStorage.setItem("firstName", encryptData(firstName, cryptoKey));
    localStorage.setItem("lastName", encryptData(lastName, cryptoKey));
    localStorage.setItem("userName", encryptData(userName, cryptoKey));
    localStorage.setItem("email", encryptData(email, cryptoKey));
    localStorage.setItem("companies", JSON.stringify(companies));
    updateLocalStorageObject("selectedCompanyData", "companyId", companyId, cryptoKey)
    if (companies.find(x => x.companyId === companyId)) {
      updateLocalStorageObject("selectedCompanyData", "companyName", companies.find(x => x.companyId === companyId)?.companyName, cryptoKey);
    }
    localStorage.setItem("langPref", langPref);
    localStorage.setItem("userlangPref", languagePreference);
    contextData.changeFirstName(firstName);
    contextData.changeLastName(lastName);
    contextData.changeUserName(userName);
    contextData.changeEmail(email);
    contextData.changeUserToken(userToken);
    contextData.changeUerId(userId);
    contextData.changeLanguagePref(langPref);
    contextData.changeCompanyId(companyId);
    contextData.setCompanies(companies);
  }

  const getMenu = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_KEY}/UserV2/GetMainMenuByClientUser`,
        {
          params: {
            userId: contextData.userId,
            companyid: contextData.companyId,
            websiteid: contextData.websiteId
          },
          headers: {
            'Authorization': `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`
          }
        },
      )
      .then(response => {
        if (response && response.data && response.data.resultData.menuDetails) {
          let menuDta = response.data.resultData.menuDetails;
          menuDta = menuDta.filter((x) => { return x.id !== 4 && x.id !== 13 && x.id !== 14 })
          if (Array.isArray(menuDta) && menuDta.length > 0) {
            let defaultMenuData = menuDta.filter(dta => dta.isActive === true)[0];
            if (defaultMenuData) {
              let settingDta = {
                id: 0,
                dispIndex: 13,
                isActionPoint: false,
                isActive: true,
                subMenu: [],
                subSections: null,
                title: "Settings"
              };
              menuDta.push(settingDta);
              contextData.setMenu(menuDta);
            }
            else {
              contextData.setMenu(menuDta);
              localStorage.setItem("selectedTab", "Website")
              contextData.changeSelectedTab("Website");
              navigate(`/website-marketing`);
            }
            if (defaultMenuData.id === 1) {
              localStorage.setItem("selectedTab", "Website")
              contextData.changeSelectedTab("Website");
              navigate(`/website`);
            }
            else if (defaultMenuData.id === 8) {
              request(baseURL + `/Intelligence/GetAlertsChannelWebsiteByClientUser?companyid=` + contextData.companyId,
                METHODS.GET,
                {},
                {
                  Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
                })
                .then((res) => {

                  if (res && res.result.length > 0) {

                    let wid = res.result[0].WebsiteId;
                    request(baseURL + `/UserV2/GetSubMenuByClientUser?companyId=${contextData.companyId}&sectionid=` + defaultMenuData.id + `&websiteid=${wid}`,
                      METHODS.GET,
                      {},
                      {
                        Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
                      })
                      .then((res) => {
                        let menuDta = res[0].subSections;
                        let subMenuDetails = menuItemsDetais.find(men => men.title === menuDta[0].actions[0].title);
                        if (subMenuDetails.link) {
                          localStorage.setItem("selectedTab", "Alerts")
                          contextData.changeSelectedTab("Alerts");
                          navigate(subMenuDetails.link);
                        }
                        else {
                          navigate(`/website`);
                        }
                      })
                      .catch((err) => {
                      })
                  }
                  else {
                    localStorage.setItem("selectedTab", "Alerts")
                    contextData.changeSelectedTab("Alerts");
                    navigate("/alert-marketing");
                  }
                })
                .catch((err) => {
                })
            }
            else if (defaultMenuData.id === 5) {
              request(baseURL + `/UserV2/GetSubMenuByClientUser?companyId=${contextData.companyId}&sectionid=` + defaultMenuData.id + `&websiteid=0`,
                METHODS.GET,
                {},
                {
                  Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
                })
                .then((res) => {
                  let menuDta = res[0].subSections;
                  let subMenuDetails = menuItemsDetais.find(men => men.title === menuDta[0].actions[0].title);
                  if (subMenuDetails.link) {
                    localStorage.setItem("selectedTab", "On-Air Streaming")
                    contextData.changeSelectedTab("On-Air Streaming");
                    navigate(subMenuDetails.link);
                  }
                  else {
                    navigate(`/website`);
                  }
                })
                .catch((err) => {
                })
            }
            else if (defaultMenuData.id === 6) {
              request(baseURL + `/Intelligence/GetToolsWebsiteForClientUser?companyid=` + contextData.companyId,
                METHODS.GET,
                {},
                {
                  Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
                })
                .then((res) => {
                  if (res && res.result.length > 0) {
                    let wid = res.result[0].WebsiteId;
                    request(baseURL + `/UserV2/GetSubMenuByClientUser?companyId=${contextData.companyId}&sectionid=` + defaultMenuData.id + `&websiteid=${wid}`,
                      METHODS.GET,
                      {},
                      {
                        Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
                      })
                      .then((res) => {
                        let menuDta = res[0].subSections;
                        let subMenuDetails = menuItemsDetais.find(men => men.title === menuDta[0].actions[0].title);
                        if (subMenuDetails.link) {
                          localStorage.setItem("selectedTab", "Tools")
                          contextData.changeSelectedTab("Tools");
                          navigate(subMenuDetails.link);
                        }
                        else {
                          navigate(`/website`);
                        }
                      })
                      .catch((err) => {
                      })
                  }
                  else {
                    localStorage.setItem("selectedTab", "Alerts")
                    contextData.changeSelectedTab("Alerts");
                    navigate("/tools-marketing");
                  }
                })
                .catch((err) => {
                })
            }
            else if (defaultMenuData.id === 10) {
              localStorage.setItem("selectedTab", "Reports")
              contextData.changeSelectedTab("Reports");
              navigate(`/reports`);
            }
            else if (defaultMenuData.id === 11) {
              localStorage.setItem("selectedTab", "Service Centre")
              contextData.changeSelectedTab("Service Centre");
              navigate(`/service-centre-pages`);
            }
          }
        }
      })
      .catch(error => {
        console.log("login error", error);
      });
  }

  return (
    <div>
      {showLoader ?
        (<div id='blurdiv' className="blurbackground service-center-modal-backdrop">
          <div className={`preloader loader-center-align`}>
            <Image className="loader-element animate__animated animate__jackInTheBox" src={LoaderImage} height={40} data-seconds={30} />
            <h3>SSO Login</h3>
          </div>
        </div>) : (<></>)}
    </div>
  )
}

export default SSOLogin