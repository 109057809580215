import React, { useContext, useEffect, useState } from "react";
import PropTypes, { string } from "prop-types";
import axios from "axios";
import { Col, Row } from "@themesberg/react-bootstrap";

import {
  tbRowWithCheckBox,
  tbRowWithToggle,
  checkArrayElementsAreSame,
  tbHeaderWithSorting,
  tbHeaderReplace,
} from "../AppConfig";
import { repplaceHeaderData } from "../utils/common";
import { decryptData } from "../utils/crypto";

import ChartsComponent from "../components/Charts";
import TableComponent from "../components/Table";
import AppContext from "../components/AppContext";
import Loader from "../components/VoltLoader";

const ChartRenderer = ({
  vizState,
  cardProperty,
  items,
  setItems,
  onItemChange,
  pageNo,
  setPageNo,
  onPageNoChange,
  showSearchTextBox,
  searchText,
  setSearchText,
  handlePlusButtonClick,
  onSearchBoxChange,
  legends,
  totalRecords,
  showPagination,
  deleteSubscriber,
  editSubscriber,
  offset,
  isRefresh,
  setIsRefresh,
  setd3,
  minimumRows,
  setShowModal,
  setSelectedItem,
  cardName,
  title,
  downloadExcel,
  showPlusModal,
  setShowPlusModal,
  apiUrl,
  setNumberOfRecords
}) => {
  const contextData = useContext(AppContext);

  const { widjetName, query, chartType, pivotConfig, tableCaption } = vizState;
  const [apiResponse, setApiResponse] = useState("");

  const TypeToMemoChartComponent = Object.keys(TypeToChartComponent)
    .map((key) => ({
      [key]: React.memo(TypeToChartComponent[key]),
    }))
    .reduce((a, b) => ({ ...a, ...b }));

  const component = TypeToMemoChartComponent[chartType];

  let queryArray = [];

  if (Array.isArray(query)) {
    query[0].offset = offset;
    queryArray = query;
  } else {
    query.offset = offset;
    queryArray.push(query);
  }

  var totalRecords = totalRecords;
  var show = false;

  if (apiUrl !== "" && showPagination) {
    show = showPagination.show;
  } else {
    if (showPagination) {
      show = showPagination.show;
    }
  }

  useEffect(() => {
    if (apiUrl !== "" && apiUrl !== undefined) {
      const config = {
        headers: {
          'Authorization': `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`
        }
      }
      if (apiUrl.type === "get") {
        axios.get(apiUrl.url, config).then((response) => {
          setApiResponse(response.data);

          setNumberOfRecords(response.data.count)
          if (response.data.count !== undefined) {
            apiUrl.count = response.data.count;
          }
        }).catch((e) => console.log(e));
      } else {
        axios.post(apiUrl.url, apiUrl.data, config).then((response) => {
          setApiResponse(response.data);
        }).catch((e) => console.log(e));
      }
    }
  }, [apiUrl]);

  const TypeToChartComponent = {
    line: (props) => {
      return <ChartsComponent apiUrl={apiUrl} resultSetNew={apiResponse} type="line" {...props} />;
    },
    multiLine: (props) => {
      return <ChartsComponent apiUrl={apiUrl} resultSetNew={apiResponse} type="multiLine" {...props} />;
    },
    bar: (props) => {
      return <ChartsComponent type="bar" resultSetNew={apiResponse} {...props} />;
    },
    multiBar: (props) => {
      return <ChartsComponent type="multiBar" {...props} />;
    },
    area: (props) => {
      return <ChartsComponent type="area" {...props} />;
    },
    pie: (props) => {
      return <ChartsComponent type="pie" {...props} />;
    },
    donet: (props) => {
      if (apiResponse.data !== undefined) {
        return <ChartsComponent apiUrl={apiUrl} resultSetNew={apiResponse} type="donet" {...props} />;
      }
    },
    map: (props) => {
      return <ChartsComponent type="map" apiUrl={apiUrl} resultSetNew={apiResponse} {...props} />;
    },
    scatter: (props) => {
      return <ChartsComponent type="scatter"  {...props} />;
    },
    number: ({ resultSet }) => {
      return (
        <Row
          type="flex"
          justify="center"
          align="middle"
          style={{
            height: "100%",
          }}
        >
          <Col>
            <h4
              style={{
                textAlign: "center",
              }}
            >
              {resultSet.seriesNames().map((s) => resultSet.totalRow()[s.key])}
            </h4>
          </Col>
        </Row>
      );
    },
    table: ({
      pageNo,
      setPageNo,
      setItems,
      items,
      onPageNoChange,
      onItemChange,
      searchText,
      setSearchText,
      onSearchBoxChange,
      resultSet,
      totalRecords,
      showPagination,
      showPaginationSummary,
      showSearchTextBox,
      isRefresh,
      setIsRefresh,
      widjetName,
      queryArray,
      pivotConfig,
      contextData,
      tableCaption,
      minimumRows,
      setShowModal,
      setSelectedItem,
      cardName,
      title,
      downloadExcel,
      handlePlusButtonClick,
      showPlusModal,
      setShowPlusModal,
      deleteSubscriber,
      editSubscriber,
      apiUrl
    }) => {
      return renderTable(
        pageNo,
        setPageNo,
        setItems,
        items,
        onPageNoChange,
        onItemChange,
        searchText,
        setSearchText,
        onSearchBoxChange,
        resultSet,
        totalRecords,
        showPagination,
        showPaginationSummary,
        showSearchTextBox,
        isRefresh,
        setIsRefresh,
        widjetName,
        queryArray,
        pivotConfig,
        contextData,
        tableCaption,
        minimumRows,
        setShowModal,
        setSelectedItem,
        cardName,
        title,
        downloadExcel,
        handlePlusButtonClick,
        showPlusModal,
        setShowPlusModal,
        deleteSubscriber,
        editSubscriber,
        apiUrl
      );
    },
  };

  const renderTable = (
    pageNo,
    setPageNo,
    setItems,
    items,
    onPageNoChange,
    onItemChange,
    searchText,
    setSearchText,
    onSearchBoxChange,
    resultSet,
    totalRecords,
    showPagination,
    showSearchTextBox,
    isRefresh,
    setIsRefresh,
    widjetName,
    queryArray,
    pivotConfig,
    contextData,
    tableCaption,
    minimumRows,
    setShowModal,
    setSelectedItem,
    cardName,
    title,
    downloadExcel,
    handlePlusButtonClick,
    showPlusModal,
    setShowPlusModal,
    deleteSubscriber,
    editSubscriber,
    apiUrl
  ) => {

    let tableHeader = [];
    if (apiUrl !== "" && apiResponse) {
      resultSet = apiResponse
      tableHeader = resultSet.headers
    } else {
      tableHeader = [...resultSet.tableColumns(pivotConfig)];
    }

    let tableData = [];
    if (widjetName === "UserWecastdigest") {
      tableData = [...getUserWebcastdigestData(resultSet, contextData)];
    } else if (widjetName === "Userappdigest") {
      tableData = [...getUserAppdigestData(resultSet, queryArray)];
    }
    else if (widjetName === "Watchlist") {
      tableData = [...getWatchlistCardata(resultSet, queryArray, contextData)];
    } else if (widjetName === "VisitorDetails") {
      tableData = [...getVisitorDetails(resultSet, queryArray)];
    } else if (cardName === "Polls") {
      tableData = [...getPollsCardata(resultSet)];
    } else if (cardName === "Multipoll Questions") {
      tableData = [...getMultiPollsCardata(resultSet)];
    } else {
      if (apiUrl !== "" && apiResponse) {
        resultSet = apiResponse
        tableData = resultSet.data;
      } else {
        tableData = [...resultSet.tablePivot(pivotConfig)];
      }
    }
    if (tableHeader !== undefined && tableHeader.length > 0 && apiUrl == "") {
      tableHeader.forEach((data, index) => {
        let sordingData = tbHeaderWithSorting.some((dta) => dta === data.key);
        data.isSortable = sordingData;
        if (sordingData) {
          data.isSortDesc = false;
          data.isSortAscAndDesc = true;
        }
        data.shortTitle = tbHeaderReplace.some((dta) => dta === data.key)
          ? repplaceHeaderData(data.key)
          : data.shortTitle;
      });
    }
    if (tableData !== undefined && tableData.length > 0) {
      tableData.forEach((data, index) => {
        let hasToggle = checkArrayElementsAreSame(
          Object.keys(data),
          tbRowWithToggle
        );
        let hasCheckbox = checkArrayElementsAreSame(
          Object.keys(data),
          tbRowWithCheckBox
        );
        if (hasToggle) {
          data.isToggle = false;
        }
        if (hasCheckbox) {
          data.isCheckBox = false;
        }
      });
    }
    return (
      <TableComponent
        tableHeaderData={tableHeader}
        tableBodyData={tableData}
        pageNo={pageNo}
        setPageNo={setPageNo}
        onPageNoChange={onPageNoChange}
        onItemChange={onItemChange}
        showPagination={showPagination}
        showPaginationSummary={showPagination}
        searchText={searchText}
        setSearchText={setSearchText}
        showSearchTextBox={showSearchTextBox}
        onSearchBoxChange={onSearchBoxChange}
        totalRecords={totalRecords}
        isRefresh={isRefresh}
        setIsRefresh={setIsRefresh}
        setItems={setItems}
        items={items}
        tableCaption={tableCaption}
        minimumRows={minimumRows}
        setShowReportDetailModal={setShowModal}
        setSelectedItem={setSelectedItem}
        cardName={cardName}
        title={title}
        downloadExcel={downloadExcel}
        handlePlusButtonClick={handlePlusButtonClick}
        showPlusModal={showPlusModal}
        setShowPlusModal={setShowPlusModal}
        deleteSubscriber={deleteSubscriber}
        editSubscriber={editSubscriber}
      />
    );
  };

  const getUserWebcastdigestData = (resultSet, contextData) => {
    let userWebcastdigestData = resultSet.loadResponses[0].data;
    if (userWebcastdigestData.length === 0 && contextData.webcastClientId) {
      userWebcastdigestData.push({
        "Userwebcastdigest.Finalstatus": "1",
        "Userwebcastdigest.frequency": "Live Webcast Insight Summary",
      });
      userWebcastdigestData.push({
        "Userwebcastdigest.Finalstatus": "1",
        "Userwebcastdigest.frequency": "Quarterly Webcast Insight Summary",
      });
      return userWebcastdigestData;
    }
    return userWebcastdigestData;
  };

  const getUserAppdigestData = (resultSet, queryArray) => {
    let userAppdigestData = resultSet.loadResponses[0].data;
    if (userAppdigestData.length === 0) {
      return userAppdigestData;
    }
    return userAppdigestData;
  };

  const getWatchlistCardata = (resultSet, queryArray, contextData) => {
    let watchListDatatable = resultSet.loadResponses[0].data;
    if (watchListDatatable.length === 0) {
      return watchListDatatable;
    }
    let visitorids = [];
    let visitorcontryids = [];

    watchListDatatable.forEach((element) => {
      visitorids.push('"' + element["Watchlist.Visitorid"] + '"');
    });
    watchListDatatable.forEach((element) => {
      visitorcontryids.push('"' + element["Watchlist.Visitorcountryid"] + '"');
    });

    watchListDatatable.forEach((element) => {
      element["Watchlist.pgviews"] = "0";
      element["Watchlist.active"] = "0";
    });
    return watchListDatatable
      .sort((x) => x["Watchlist.pgviews"].desc)
      .slice(0, 5);
  };

  const getPollsCardata = (resultSet) => {
    let pollsDatatable = resultSet.loadResponses[0].data;
    let pollsFinalDatatable = [];
    if (pollsDatatable.length === 0) {
      return pollsDatatable;
    }
    pollsDatatable.forEach((element) => {
      element["PresentationPollsQaDetails.answerData"] = [];
      element["PresentationPollsQaDetails.answerData"].push({
        ans: element["PresentationPollsQaDetails.answersValue"],
        count: element["PresentationPollsQaDetails.answersCount"],
      });
      if (pollsFinalDatatable.length > 0) {
        let tempPoll = pollsFinalDatatable.find(
          (x) =>
            x["PresentationPollsQaDetails.question"] ===
            element["PresentationPollsQaDetails.question"]
        );
        if (tempPoll !== null && tempPoll !== undefined) {
          tempPoll["PresentationPollsQaDetails.answerData"].push(
            element["PresentationPollsQaDetails.answerData"][0]
          );
        } else {
          pollsFinalDatatable.push(element);
        }
      } else {
        pollsFinalDatatable.push(element);
      }
    });
    return pollsFinalDatatable;
  };

  const getMultiPollsCardata = (resultSet) => {
    let pollsDatatable = resultSet.loadResponses[0].data;
    let pollsFinalDatatable = [];
    if (pollsDatatable.length === 0) {
      return pollsDatatable;
    }
    pollsDatatable.forEach((element) => {
      element["PresentationsPollsQA.answerData"] = [];
      element["PresentationsPollsQA.answerData"].push({
        ans: element["PresentationsPollsQA.answersValue"],
        count: element["PresentationsPollsQA.answersCount"],
      });
      if (pollsFinalDatatable.length > 0) {
        let tempPoll = pollsFinalDatatable.find(
          (x) =>
            x["PresentationsPollsQA.question"] ===
            element["PresentationsPollsQA.question"]
        );
        if (tempPoll !== null && tempPoll !== undefined) {
          tempPoll["PresentationsPollsQA.answerData"].push(
            element["PresentationsPollsQA.answerData"][0]
          );
        } else {
          pollsFinalDatatable.push(element);
        }
      } else {
        pollsFinalDatatable.push(element);
      }
    });
    return pollsFinalDatatable;
  };

  const getVisitorDetails = (resultset, queryArray) => {
    let vitorListDatatable = resultset.loadResponses[0].data;
    if (vitorListDatatable.length === 0) {
      return vitorListDatatable;
    }
    return vitorListDatatable;
  };

  const renderChart =
    (Component) =>
      ({
        resultSet,
        cardProperty,
        totalRecords,
        pageNo,
        setPageNo,
        items,
        setItems,
        onItemChange,
        onPageNoChange,
        showSearchTextBox,
        searchText,
        setSearchText,
        onSearchBoxChange,
        legends,
        pivotConfig,
        show,
        isRefresh,
        setIsRefresh,
        widjetName,
        queryArray,
        setd3,
        contextData,
        tableCaption,
        minimumRows,
        setShowModal,
        setSelectedItem,
        cardName,
        title,
        downloadExcel,
        handlePlusButtonClick,
        deleteSubscriber,
        editSubscriber,
        apiUrl
      }) => {
        var showLoader = false;

        if (resultSet == null) {
          setTimeout((showLoader = true), 1000);
        }
        return (
          (resultSet && (
            <Component
              resultSet={resultSet}
              cardProperty={cardProperty}
              pageNo={pageNo}
              setPageNo={setPageNo}
              onPageNoChange={onPageNoChange}
              items={items}
              setItems={setItems}
              onItemChange={onItemChange}
              showSearchTextBox={showSearchTextBox}
              searchText={searchText}
              onSearchBoxChange={onSearchBoxChange}
              totalRecords={totalRecords}
              legends={legends}
              pivotConfig={pivotConfig}
              showPagination={show}
              setSearchText={setSearchText}
              isRefresh={isRefresh}
              setIsRefresh={setIsRefresh}
              widjetName={widjetName}
              queryArray={queryArray}
              setd3={setd3}
              contextData={contextData}
              tableCaption={tableCaption}
              minimumRows={minimumRows}
              setShowModal={setShowModal}
              setSelectedItem={setSelectedItem}
              cardName={cardName}
              title={title}
              downloadExcel={downloadExcel}
              handlePlusButtonClick={handlePlusButtonClick}
              deleteSubscriber={deleteSubscriber}
              editSubscriber={editSubscriber}
              apiUrl={apiUrl}
            />
          )) || <Loader show={showLoader} />
        );
      };

  ChartRenderer.propTypes = {
    vizState: PropTypes.object,
    cubejsApi: PropTypes.object,
    cardProperty: PropTypes.object,
    pageNo: PropTypes.number,
    setPageNo: PropTypes.func,
    onPageNoChange: PropTypes.func,
    onItemChange: PropTypes.func,
    searchText: PropTypes.string,
    onSearchTextChange: PropTypes.func,
    legends: PropTypes.object,
    handlePlusButtonClick: PropTypes.func,
    showPlusModal: PropTypes.bool,
    setShowPlusModal: PropTypes.func,
    deleteSubscriber: PropTypes.func,
    editSubscriber: PropTypes.func,
    apiUrl: string
  };

  ChartRenderer.defaultProps = {
    vizState: {},
    cubejsApi: null,
    cardProperty: {},
    pageNo: 1,
    onPageNoChange: null,
    handlePlusButtonClick: null,
    onItemChange: null,
    searchText: "",
    onSearchTextChange: null,
    legends: {},
    apiUrl: ""
  };

  return (
    component &&
    renderChart(component)({
      ...cardProperty,
      totalRecords,
      pageNo,
      setPageNo,
      items,
      setItems,
      onItemChange,
      onPageNoChange,
      showSearchTextBox,
      searchText,
      setSearchText,
      onSearchBoxChange,
      legends,
      pivotConfig,
      show,
      isRefresh,
      setIsRefresh,
      widjetName,
      queryArray,
      setd3,
      contextData,
      tableCaption,
      minimumRows,
      setShowModal,
      setSelectedItem,
      cardName,
      title,
      downloadExcel,
      handlePlusButtonClick,
      showPlusModal,
      setShowPlusModal,
      deleteSubscriber,
      editSubscriber,
      apiUrl
    })
  );
};

export default ChartRenderer;
