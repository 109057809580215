import React, { useEffect, useState } from 'react';
import { Col, Row, Nav, Image, Tab, Dropdown, ButtonGroup, Alert, Button, Form, InputGroup, Popover, OverlayTrigger, FormCheck } from '@themesberg/react-bootstrap';
import { useTranslation } from 'react-i18next';
import Datetime from "react-datetime";
import html2pdf from "html2pdf.js";
import moment from 'moment';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt, faCheckCircle } from "@fortawesome/free-solid-svg-icons";

import request, { requestPDF } from '../../apis/request.js';
import { decryptData } from '../../utils/crypto.js';
import iconSprite from "../../assets/Icon/svg-icn-sprite.svg";
import LoaderImage from "../../assets/img/LoaderGraphic.svg";
import { convertBase64 } from '../../utils/common.js';
import { DataStatusMessage, VITableHeaders } from "../../utils/constant.js";
import { convertTranslatinString } from '../../utils/common.js';
import CommonMessageComponent from '../CommonMessageComponent.js';
import { METHODS } from '../../apis/utilities/constant.js';
import { convertToInternationalFormat, ERROR_TIMEOUT } from "../../AppConfig.js";

import DataChartRenderer from '../DataChartRenderer.js';
import DataTableRenderer from '../DataTableRenderer.js';
import Search from "../Search.js";
import ModalComponent from "../VoltModal.js";
import QuestionModal from "../QuestionModel.js";
import ButtonComponent from "../Buttons.js";

export const Watchlist = ({ cryptoKey, searchData, setSearchDataList, startDate, endDate, profileId, companyId, userId, intelConfigId, setWatchlistData, refreshWatchlist, setRefreshWatchlist, exportdata, filterParams, setDownloadEnable, isTF, tool, section, ...props }) => {
    const [t] = useTranslation();

    const [watchlistResponse, setWatchlistResponse] = useState({ transactionStatus: DataStatusMessage.Loading });
    const [watchlist, setWatchlist] = useState({ transactionStatus: DataStatusMessage.Loading });
    const [showWatchlistModal, setShowWatchlistModal] = useState(false);
    const [tabWatchlistModal, setTabWatchlistModal] = useState(0);
    const [watchlistPopupLinechart, setWatchlistPopupLinechart] = useState({ transactionStatus: DataStatusMessage.Loading });
    const [watchlistPopupTableResponse, setWatchlistPopupTableResponse] = useState({ transactionStatus: DataStatusMessage.Loading });
    const [watchlistPagesViewedTable, setWatchlistPagesViewedTable] = useState({ transactionStatus: DataStatusMessage.Loading });
    const [trafficSourceBarchart, setTrafficSourceBarchart] = useState({ transactionStatus: DataStatusMessage.Loading });
    const [trafficSourceTable, setTrafficSourceTable] = useState({ transactionStatus: DataStatusMessage.Loading });
    const [showAutoFillForPopupSearch, setShowAutoFillForPopupSearch] = useState(false);
    const [popupSearchText, setPopupSearchText] = useState([]);
    const [popupSearchDataList, setPopupSearchDataList] = useState([]);
    const [trafficSourceText, setTrafficSourceText] = useState(t("header.VisitorsActivityPopTrafficChartOrganicHelpText"));
    const [trafficSourceTotal, setTrafficSourceTotal] = useState(0);
    const [trafficSource, setTrafficSource] = useState("Organic");
    const [rowDetails, setRowDetails] = useState([]);
    const [trafficSourceMetrices, setTrafficSourceMetrices] = useState({ transactionStatus: DataStatusMessage.DataUnAvailable });
    const [successMessage, setSuccessMessage] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);
    const [showLoader, setShowLoader] = useState(false);
    const [showQuestionModal, setShowQuestionModal] = useState(false);
    const [questionModalContent, setQuestionModalContent] = useState({});
    const [isSuccess, setIsSuccess] = useState(false);
    const [watchlistProfileData, setwatchlistProfileData] = useState({});
    const [alertVisible, setAlertVisible] = useState(false);
    const [toggle, setToggle] = useState(true);

    const [notificationAddEditData, setNotificationAddEditData] = useState({
        operation: '', companyid: 0, companyname: '', notificationname: '', threshold: '', country: '', countrydata: '', companyurl: '', moreurls: [], startdate: null, enddate: null, durationId: 0,
        companynameisvalid: true, notificationnameisvalid: true, thresholdisvalid: true, startdateisvalid: true, enddateisvalid: true, durationidisvalid: true
    });
    const [showAddEditPopup, setShowAddEditPopup] = useState(false);
    const [duration, setDuration] = useState([]);
    const [notificationEditData, setNotificationEditData] = useState({});
    const [addEditFormErrors, setAddEditFormErrors] = useState("");

    useEffect(() => {
        if (refreshWatchlist && profileId) {
            setVisitorWatchlistData();
        }
    }, [profileId, startDate, endDate, tool, section, refreshWatchlist]);

    useEffect(() => {
        setPopupSearchText("");
        setToggle(!toggle);
        setShowAutoFillForPopupSearch(false);
        const selectedStartDate = moment(startDate).format("YYYY-MM-DD");
        const selectedEndDate = moment(endDate).format("YYYY-MM-DD");
        if (tabWatchlistModal === 1) {
            let url = `${process.env.REACT_APP_API_KEY}/VisitorAnalytics/GetPageViewVisitByVisitor?profileid=${profileId}&startdate=${selectedStartDate}&enddate=${selectedEndDate}&visitorid=${rowDetails.companyId}&countryid=${rowDetails.countryId}`;
            if (isTF === 0 && section.sectionId > 0) {
                url += `&sectionid=${section.sectionId}&packageid=5`;
            }
            else {
                url += `&isTF=${isTF}&toolid=${isTF ? tool.toolID : 0}&intelconfigid=${intelConfigId}`
            }
            request(url,
                METHODS.GET, {},
                {
                    Authorization: `Bearer ${decryptData(localStorage.getItem("token"), cryptoKey)}`,
                    "content-type": "application/json"
                })
                .then((response) => {
                    if (response && response.transactionStatus === DataStatusMessage.DataAvailable) {
                        setWatchlistPopupLinechart({ result: response.result, transactionStatus: response.transactionStatus })
                    } else {
                        setWatchlistPopupLinechart({ result: [], transactionStatus: DataStatusMessage.DataUnAvailable })
                    }
                }).catch(() => {
                    setWatchlistPopupLinechart({ result: [], transactionStatus: DataStatusMessage.DataUnAvailable })
                })

            url = `${process.env.REACT_APP_API_KEY}/VisitorAnalytics/GetIntelligencePagesByVisitor?profileid=${profileId}&startDate=${selectedStartDate}&endDate=${selectedEndDate}&countryid=${rowDetails.countryId}&visitorid=${rowDetails.companyId}&isTF=${isTF}&toolid=${isTF ? tool.toolID : 0}&intelconfigid=${intelConfigId}`;
            if (isTF === 0 && section.sectionId > 0) {
                url = `${process.env.REACT_APP_API_KEY}/VisitorAnalytics/GetIntelligencePageByVisitorSection?profileid=${profileId}&startDate=${selectedStartDate}&endDate=${selectedEndDate}&visitorcountryid=${rowDetails.countryId}&visitorid=${rowDetails.companyId}&pagesectionid=${section.sectionId}&packageid=5&selectedcompanyid=${companyId}`
            }

            request(url,
                METHODS.GET, {},
                {
                    Authorization: `Bearer ${decryptData(localStorage.getItem("token"), cryptoKey)}`,
                    "content-type": "application/json"
                })
                .then((response) => {
                    if (response && response.transactionStatus === DataStatusMessage.DataAvailable) {
                        setWatchlistPopupTableResponse(response);
                        setPopupSearchDataList(response.result.filterdata);
                        setWatchlistPagesViewedTable({
                            header: isTF ? VITableHeaders.PagesViewedTFTable : VITableHeaders.PagesViewedTable, data: response.result.data, transactionStatus: response.transactionStatus
                        });

                    } else {
                        setWatchlistPagesViewedTable({ result: [], transactionStatus: DataStatusMessage.DataUnAvailable })
                    }
                }).catch(() => {
                    setWatchlistPagesViewedTable({ result: [], transactionStatus: DataStatusMessage.DataUnAvailable })
                })
        }

        else if (tabWatchlistModal === 2) {
            let url = `${process.env.REACT_APP_API_KEY}/VisitorAnalytics/GetIntelligenceTrafficSourceByVisitor?profileid=${profileId}&startdate=${selectedStartDate}&enddate=${selectedEndDate}&companyid=${rowDetails.companyId}&countryid=${rowDetails.countryId}&isTF=${isTF}&toolid=${isTF ? tool.toolID : 0}&intelconfigid=${intelConfigId}`;
            if (isTF === 0 && section.sectionId > 0) {
                url += `&sectionid=${section.sectionId}&packageid=5`
            }
            request(url,
                METHODS.GET, {},
                {
                    Authorization: `Bearer ${decryptData(localStorage.getItem("token"), cryptoKey)}`,
                    "content-type": "application/json"
                })
                .then((response) => {
                    if (response && response.transactionStatus === DataStatusMessage.DataAvailable) {
                        setTrafficSourceBarchart({ result: response.result, transactionStatus: response.transactionStatus })

                    } else {
                        setTrafficSourceBarchart({ result: [], transactionStatus: DataStatusMessage.DataUnAvailable })

                    }
                }).catch(() => [
                    setTrafficSourceBarchart({ result: [], transactionStatus: DataStatusMessage.DataUnAvailable })
                ])

            getTrafficSource(trafficSource);

        }
    }, [tabWatchlistModal])

    useEffect(() => {
        const allData = watchlistResponse.result;

        if (allData && !searchData && Object.keys(filterParams).length == 0) {
            setWatchlist(prevState => ({
                ...prevState,
                data: allData.data
            }));
            setDownloadEnable(allData.data.length > 0);
        }

        if (allData && Object.keys(filterParams).length > 0 && searchData) {
            const filteredSearchData = searchContent(allData, searchData);

            let filteredobj = filteredSearchData;
            let filtervalueexists = 0;
            let filterfs2 = {};
            let columns = [];
            for (const obj in filterParams) {
                if (filterParams[obj] != "All" && filterParams[obj] != 0) {
                    filtervalueexists = 1;
                    filterfs2[`${obj}`] = filterParams[obj]
                    columns.push(obj)
                }
            }
            if (filtervalueexists == 1) {
                if (columns.length > 0) {
                    filteredobj = filteredobj.filter(function (item) {
                        if (item[columns[0]] === filterfs2[columns[0]]) {
                            return true;
                        }
                        return false;
                    });
                }
                if (columns.length > 1) {
                    filteredobj = filteredobj.filter(function (item) {
                        if (item[columns[1]] === filterfs2[columns[1]]) {
                            return true;
                        }
                        return false;
                    });
                }
            }
            setWatchlist({ header: isTF ? VITableHeaders.WatchlistTFTable : VITableHeaders.WatchlistTable, data: filteredobj, transactionStatus: 1 })
            setDownloadEnable(filteredobj.length > 0);
        }
        else if (allData && searchData) {
            const filteredSearchData = searchContent(allData, searchData);
            setWatchlist(prevState => ({
                ...prevState,
                data: filteredSearchData
            }));
            setDownloadEnable(filteredSearchData.length > 0);
        }
        else if (allData && Object.keys(filterParams).length > 0) {
            let filteredobj = allData.data;
            let filtervalueexists = 0;
            let filterfs2 = {};
            let columns = [];
            for (const obj in filterParams) {
                if (filterParams[obj] != "All" && filterParams[obj] != 0) {
                    filtervalueexists = 1;
                    filterfs2[`${obj}`] = filterParams[obj]
                    columns.push(obj)
                }
            }
            if (filtervalueexists == 1) {
                if (columns.length > 0) {
                    filteredobj = filteredobj.filter(function (item) {
                        if (item[columns[0]] === filterfs2[columns[0]]) {
                            return true;
                        }
                        return false;
                    });
                }
                if (columns.length > 1) {
                    filteredobj = filteredobj.filter(function (item) {
                        if (item[columns[1]] === filterfs2[columns[1]]) {
                            return true;
                        }
                        return false;
                    });
                }
            }
            setWatchlist({ header: isTF ? VITableHeaders.WatchlistTFTable : VITableHeaders.WatchlistTable, data: filteredobj, transactionStatus: 1 });
            setDownloadEnable(filteredobj.length > 0);
        }
    }, [searchData, filterParams]);

    useEffect(() => {
        if (watchlistPopupTableResponse.result) {
            setWatchlistPagesViewedTable(prevState => ({
                ...prevState,
                data: searchContent(watchlistPopupTableResponse.result, popupSearchText)
            }));
        }
    }, [popupSearchText]);

    const setVisitorWatchlistData = () => {
        setWatchlistResponse({ transactionStatus: DataStatusMessage.Loading });
        setWatchlist({ transactionStatus: DataStatusMessage.Loading });
        const selectedStartDate = moment(startDate).format("YYYY-MM-DD");
        const selectedEndDate = moment(endDate).format("YYYY-MM-DD");
        let url = `${process.env.REACT_APP_API_KEY}/WebsiteAnalytics/GetWatchedVisitorByWebsite?endDate=${selectedEndDate}&startDate=${selectedStartDate}&profileId=${profileId}&packageid=5&intellConfigId=${intelConfigId}&companyid=${companyId}&isTF=${isTF}&toolid=${isTF ? tool.toolID : 0}&refreshCache=1`;
        if (isTF === 0 && section && section.sectionId !== 0) {
            url = `${process.env.REACT_APP_API_KEY}/VisitorAnalytics/GetWatchedVisitorBySection?profileid=${profileId}&intellConfigId=${intelConfigId}&startDate=${selectedStartDate}&endDate=${selectedEndDate}&companyid=${companyId}&sectionid=${section.sectionId}&packageid=5`;
        }
        request(url,
            METHODS.GET, {},
            {
                "Authorization": `Bearer ${decryptData(localStorage.getItem("token"), cryptoKey)}`,
                "content-type": "application/json"
            })
            .then((response) => {
                if (response && response.transactionStatus === DataStatusMessage.DataAvailable) {
                    if (exportdata !== undefined) {
                        exportdata(response.result);
                    }
                    setWatchlistResponse(response);
                    setSearchDataList(response.result.filterdata);
                    setWatchlistData(response.result);
                    setWatchlist({
                        header: isTF ? VITableHeaders.WatchlistTFTable : VITableHeaders.WatchlistTable, data: response.result.data, transactionStatus: response.transactionStatus
                    });
                    setDownloadEnable(response.result.data.length > 0);
                }
                else {
                    setWatchlistResponse({ transactionStatus: DataStatusMessage.DataUnAvailable });
                    setWatchlist({ transactionStatus: DataStatusMessage.DataUnAvailable });
                    setDownloadEnable(false);
                    setWatchlistData(null);
                }
                setShowLoader(false);
            })
            .catch(() => {
                setWatchlistResponse({ transactionStatus: DataStatusMessage.DataUnAvailable });
                setWatchlist({ transactionStatus: DataStatusMessage.DataUnAvailable });
                setDownloadEnable(false);
                setWatchlistData(null);
            })
    }

    const searchContent = (objSearch, searchText) => {
        let filteredResult = [];
        if (searchText) {
            if (objSearch.filterColumns && objSearch.filterColumns.length > 0) {
                const filtercolumns = objSearch.filterColumns;
                let filterfs = {};
                for (let i = 0; i < filtercolumns.length; i++) {
                    filterfs[filtercolumns[i]] = searchText;
                }
                let result = objSearch.data;
                filteredResult = result.filter(function (item) {
                    for (var key in filterfs) {
                        if (item[key]?.toLowerCase().includes(filterfs[key]?.toLowerCase())) {
                            return true;
                        }
                        else {
                            return false;
                        }
                    }
                });
            }
            else {
                filteredResult = objSearch.data.filter(item => item.filterybykey === searchText);
            }
        }
        else {
            filteredResult = objSearch.data.slice();
        }
        setDownloadEnable(filteredResult.length > 0);
        return filteredResult;
    }

    const rowClickEvent = (rowobj) => {
        setShowWatchlistModal(true);
        setRowDetails(rowobj.data)
        setTabWatchlistModal(1);
    }

    const handleModalClose = () => {
        setShowWatchlistModal(false);
        setTrafficSource("Organic");
        setTabWatchlistModal(0);
        setWatchlistPopupLinechart({ transactionStatus: DataStatusMessage.Loading })
        setWatchlistPagesViewedTable({ transactionStatus: DataStatusMessage.Loading })
        setTrafficSourceBarchart({ transactionStatus: DataStatusMessage.Loading })
        setTrafficSourceTable({ transactionStatus: DataStatusMessage.Loading })
        setTrafficSourceMetrices({ transactionStatus: DataStatusMessage.DataUnAvailable })
    }

    const getTrafficSource = (source) => {
        const selectedStartDate = moment(startDate).format("YYYY-MM-DD");
        const selectedEndDate = moment(endDate).format("YYYY-MM-DD");
        let TSType = source; //traffic source type

        if (trafficSourceMetrices.transactionStatus === DataStatusMessage.DataAvailable) {
            if (source === "Organic") {
                setTrafficSourceText(t("header.VisitorsActivityPopTrafficChartOrganicHelpText"));
                setTrafficSourceTotal((isTF ? t("header.TFallPagesTrafficSourceTotal1") : t("header.allPagesTrafficSourceTotal1")) + " " + t("header.barchart_Xaxis_Below_" + source) + " " + t("header.allPagesTrafficSourceTotal2") + " " + convertToInternationalFormat(trafficSourceMetrices.result.organic));
            }
            else if (source === "Direct") {
                setTrafficSourceText(t("header.VisitorsActivityPopTrafficChartDirectHelpText"));
                setTrafficSourceTotal((isTF ? t("header.TFallPagesTrafficSourceTotal1") : t("header.allPagesTrafficSourceTotal1")) + " " + t("header.barchart_Xaxis_Below_" + source) + " " + t("header.allPagesTrafficSourceTotal2") + " " + convertToInternationalFormat(trafficSourceMetrices.result.direct));
            }
            else if (source === "Referral") {
                setTrafficSourceText(t("header.VisitorsActivityPopTrafficChartReferralHelpText"));
                setTrafficSourceTotal((isTF ? t("header.TFallPagesTrafficSourceTotal1") : t("header.allPagesTrafficSourceTotal1")) + " " + t("header.barchart_Xaxis_Below_" + source) + " " + t("header.allPagesTrafficSourceTotal2") + " " + convertToInternationalFormat(trafficSourceMetrices.result.referral));
            }
            else if (source === "Email") {
                setTrafficSourceText(t("header.VisitorsActivityPopTrafficChartEmailHelpText"));
                setTrafficSourceTotal((isTF ? t("header.TFallPagesTrafficSourceTotal1") : t("header.allPagesTrafficSourceTotal1")) + " " + t("header.barchart_Xaxis_Below_" + source) + " " + t("header.allPagesTrafficSourceTotal2") + " " + convertToInternationalFormat(trafficSourceMetrices.result.email));
            }
            else if (source === "Social") {
                setTrafficSourceText(t("header.VisitorsActivityPopTrafficChartSocialHelpText"));
                setTrafficSourceTotal((isTF ? t("header.TFallPagesTrafficSourceTotal1") : t("header.allPagesTrafficSourceTotal1")) + " " + t("header.barchart_Xaxis_Below_" + source) + " " + t("header.allPagesTrafficSourceTotal2") + " " + convertToInternationalFormat(trafficSourceMetrices.result.social));
            }
            else if (source === "PaidSearch") {
                setTrafficSourceText(t("header.VisitorsActivityPopTrafficChartPaidSearchHelpText"));
                setTrafficSourceTotal((isTF ? t("header.TFallPagesTrafficSourceTotal1") : t("header.allPagesTrafficSourceTotal1")) + " " + t("header.barchart_Xaxis_Below_" + source) + " " + t("header.allPagesTrafficSourceTotal2") + " " + convertToInternationalFormat(trafficSourceMetrices.result.paidSearch));
            }
            getTrafficSourceTable(TSType, selectedStartDate, selectedEndDate);
        }
        else {
            let url = `${process.env.REACT_APP_API_KEY}/VisitorAnalytics/GetIntelligenceTrafficSourceByVisitor?profileid=${profileId}&startdate=${selectedStartDate}&enddate=${selectedEndDate}&companyid=${rowDetails.companyId}&countryid=${rowDetails.countryId}&isTF=${isTF}&getvalues=1&toolid=${isTF ? tool.toolID : 0}&intelconfigid=${intelConfigId}`;
            if (isTF === 0 && section.sectionId > 0) {
                url += `&sectionid=${section.sectionId}&packageid=5`
            }
            request(url,
                METHODS.GET, {},
                {
                    Authorization: `Bearer ${decryptData(localStorage.getItem("token"), cryptoKey)}`,
                    "content-type": "application/json"
                })
                .then((response) => {

                    if (response !== null) {
                        setTrafficSourceMetrices({ result: response, transactionStatus: DataStatusMessage.DataAvailable })
                        if (response.organic === 0) {
                            TSType = "Direct";
                        }
                        if (TSType === "Organic") {
                            setTrafficSourceText(t("header.VisitorsActivityPopTrafficChartOrganicHelpText"));
                            setTrafficSourceTotal((isTF ? t("header.TFallPagesTrafficSourceTotal1") : t("header.allPagesTrafficSourceTotal1")) + " " + t("header.barchart_Xaxis_Below_" + TSType) + " " + t("header.allPagesTrafficSourceTotal2") + " " + convertToInternationalFormat(response.organic));
                        }
                        else if (TSType === "Direct") {
                            setTrafficSourceText(t("header.VisitorsActivityPopTrafficChartDirectHelpText"));
                            setTrafficSourceTotal((isTF ? t("header.TFallPagesTrafficSourceTotal1") : t("header.allPagesTrafficSourceTotal1")) + " " + t("header.barchart_Xaxis_Below_" + TSType) + " " + t("header.allPagesTrafficSourceTotal2") + " " + convertToInternationalFormat(response.direct));
                        }
                        else if (TSType === "Referral") {
                            setTrafficSourceText(t("header.VisitorsActivityPopTrafficChartReferralHelpText"));
                            setTrafficSourceTotal((isTF ? t("header.TFallPagesTrafficSourceTotal1") : t("header.allPagesTrafficSourceTotal1")) + " " + t("header.barchart_Xaxis_Below_" + TSType) + " " + t("header.allPagesTrafficSourceTotal2") + " " + convertToInternationalFormat(response.referral));
                        }
                        else if (TSType === "Email") {
                            setTrafficSourceText(t("header.VisitorsActivityPopTrafficChartEmailHelpText"));
                            setTrafficSourceTotal((isTF ? t("header.TFallPagesTrafficSourceTotal1") : t("header.allPagesTrafficSourceTotal1")) + " " + t("header.barchart_Xaxis_Below_" + TSType) + " " + t("header.allPagesTrafficSourceTotal2") + " " + convertToInternationalFormat(response.email));
                        }
                        else if (TSType === "Social") {
                            setTrafficSourceText(t("header.VisitorsActivityPopTrafficChartSocialHelpText"));
                            setTrafficSourceTotal((isTF ? t("header.TFallPagesTrafficSourceTotal1") : t("header.allPagesTrafficSourceTotal1")) + " " + t("header.barchart_Xaxis_Below_" + TSType) + " " + t("header.allPagesTrafficSourceTotal2") + " " + convertToInternationalFormat(response.social));
                        }
                        else if (TSType === "PaidSearch") {
                            setTrafficSourceText(t("header.VisitorsActivityPopTrafficChartPaidSearchHelpText"));
                            setTrafficSourceTotal((isTF ? t("header.TFallPagesTrafficSourceTotal1") : t("header.allPagesTrafficSourceTotal1")) + " " + t("header.barchart_Xaxis_Below_" + TSType) + " " + t("header.allPagesTrafficSourceTotal2") + " " + convertToInternationalFormat(response.paidSearch));
                        }
                    }
                    getTrafficSourceTable(TSType, selectedStartDate, selectedEndDate);
                })
                .catch(() => {
                })
        }

    }

    const getTrafficSourceTable = (TSType, selectedStartDate, selectedEndDate) => {
        setTrafficSource(TSType);
        if (TSType === "Organic" || TSType === "Referral" || TSType === "Social") {
            let url = `${process.env.REACT_APP_API_KEY}/VisitorAnalytics/GetTopSourceMediumDomainsByTrafficSourceAndVisitor?profileid=${profileId}&startdate=${selectedStartDate}&enddate=${selectedEndDate}&countryid=${rowDetails.countryId}&companyid=${rowDetails.companyId}&traffic=${TSType}&isTF=${isTF}&toolid=${isTF ? tool.toolID : 0}&intelconfigid=${intelConfigId}`;
            if (isTF === 0 && section.sectionId > 0) {
                url = `${process.env.REACT_APP_API_KEY}/VisitorAnalytics/GetTopSourceMediumDomainsByTrafficSourceVisitorSection?profileid=${profileId}&startdate=${selectedStartDate}&enddate=${selectedEndDate}&selectedcompanyid=${companyId}&countryid=${rowDetails.countryId}&companyid=${rowDetails.companyId}&traffic=${TSType}&packageid=5&sectionid=${section.sectionId}`
            }
            request(url,
                METHODS.GET, {},
                {
                    Authorization: `Bearer ${decryptData(localStorage.getItem("token"), cryptoKey)}`,
                    "content-type": "application/json"
                })
                .then((response) => {
                    if (response && response.transactionStatus === DataStatusMessage.DataAvailable) {
                        setTrafficSourceTable({ header: isTF ? VITableHeaders.TrafficSourceTFTable : VITableHeaders.TrafficSourceTable, data: response.result.data, transactionStatus: response.transactionStatus });
                    } else {
                        setTrafficSourceTable({ result: [], transactionStatus: DataStatusMessage.DataUnAvailable })
                    }
                })
                .catch(() => {
                    setTrafficSourceTable({ result: [], transactionStatus: DataStatusMessage.ErrorOccured })
                })
        } else {
            setTrafficSourceTable({ result: [], transactionStatus: DataStatusMessage.DataUnAvailable })
        }
    }

    const handleDeleteWatchlistButtonClick = (e, row) => {
        e.preventDefault();
        setShowQuestionModal(true);
        setQuestionModalContent({ type: "Watchlist", title: "header.WatchlistDeleteTitle", content: "header.WatchlistDeleteNote" })
        let profiledta = {
            ProfileId: profileId,
            VisitorId: row.companyId,
            CountryId: row.countryId,
            UserID: userId,
            SelectedCompanyID: companyId,
            watch: 1,
            isTF: isTF,
            PackageId: 4
        };
        setwatchlistProfileData(profiledta);
    }

    const onEventchange = (type, msg) => {
        if (type === "Modify") {
            setVisitorWatchlistData();
        }
        setTimeout(() => {
            if (msg.includes("-Successmsg-")) {
                setSuccessMessage(msg.replace("-Successmsg-", ""));
            }
            else if (msg.includes("-Errormsg-")) {
                setErrorMessage(msg.replace("-Errormsg-", ""));
            }
        }, 500);
        setTimeout(() => {
            if (msg.includes("-Successmsg-")) {
                setSuccessMessage(undefined);
            }
            else if (msg.includes("-Errormsg-")) {
                setErrorMessage(undefined);
            }
        }, ERROR_TIMEOUT);
    }

    const onCloseErrorMessage = () => {
        setErrorMessage(undefined)
    };

    const onCloseSuccessMessage = () => {
        setSuccessMessage(undefined)
    };

    const saveWatchDeleteStatus = () => {
        setShowLoader(true);
        request(`${process.env.REACT_APP_API_KEY}/VisitorAnalytics/DeleteWatchlist`,
            METHODS.POST, watchlistProfileData,
            {
                Authorization: `Bearer ${decryptData(localStorage.getItem("token"), cryptoKey)}`,
            })
            .then((response) => {
                if (response && response.successStatus === true) {
                    onEventchange("Modify", "-Successmsg-" + t("header.Message_Visitor_removed_from_your_watchlist_successfully"));
                } else {
                    onEventchange("", "-Errormsg-" + t("header.Something_went_wrong"));
                }
                setShowLoader(false);
                localStorage.setItem("refreshCache", 1);
            })
            .catch((error) => {
                console.log("error", error);
                setShowLoader(false);
            })
    };

    const onSuccessModelClose = () => {
        setIsSuccess(true);
        showQuestionModal && setShowQuestionModal(false);
        if (questionModalContent.type === "Watchlist") {
            saveWatchDeleteStatus()
        }
    };

    const onCancelClose = () => {
        setShowQuestionModal(false);
    };

    const setDowloadDropdownMenu = () => {
        let ddList = ["Excel", "PDF"];
        return ddList.map(item => {
            return (
                <Dropdown.Item className='dropdown-item-download' onClick={() => downloadGrid(item)}>
                    {item}
                </Dropdown.Item>
            );
        })
    }

    const downloadGrid = (item) => {
        if (item === "Excel") {
            downloadExcel()
        }
        else {
            downloadPDF();
        }
    }

    const downloadExcel = async () => {
        setShowLoader(true);
        let paramsList = {
            profileid: profileId,
            profileName: props.profileData["WebsiteProfiles.DisplayName"],
            visitorid: rowDetails.companyId,
            countryid: rowDetails.countryId,
            startDate: moment(startDate).format("YYYY-MM-DD"),
            endDate: moment(endDate).format("YYYY-MM-DD"),
            visitor: rowDetails.companyName,
            companyName: props.companyName,
            pagesectionid: isTF ? tool.toolID : section.sectionId,
            pageSectionName: isTF ? tool.title : section.title,
            packageid: 5,
            companyid: companyId,
            webSiteName: props.websiteData["WebsiteList.WebsiteName"],
            culture: localStorage.getItem('i18nextLng') ? localStorage.getItem('i18nextLng') : 'en-US',
            isTF: isTF,
            toolid: isTF ? tool.toolID : 0,
            intelconfigid: intelConfigId
        }

        request(`${process.env.REACT_APP_API_KEY}/VisitorAnalytics/ExportVisitorPageViewExcelFileWithEPPlus?encodeStr=${convertBase64(JSON.stringify(paramsList))}`,
            METHODS.GET,
            {},
            {
                Authorization: `Bearer ${decryptData(localStorage.getItem("token"), cryptoKey)}`,
            })
            .then((res) => {
                if (res) {
                    let fetchDataModified = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${res}`;
                    let a = document.createElement("a");
                    a.href = fetchDataModified;
                    a.download = "Insights_Visitor.xlsx";
                    a.click();
                }
                setShowLoader(false);
            })
            .catch((err) => {
                setShowLoader(false);
                console.error('Error generating Excel:', err);
            })
    };

    const downloadPDF = async () => {
        setShowLoader(true);
        const selectedStartDate = moment(startDate).format("YYYY-MM-DD");
        const selectedEndDate = moment(endDate).format("YYYY-MM-DD");
        let url = `${process.env.REACT_APP_API_KEY}/VisitorAnalytics/GetIntelligencePagesByVisitor?profileid=${profileId}&startDate=${selectedStartDate}&endDate=${selectedEndDate}&countryid=${rowDetails.countryId}&visitorid=${rowDetails.companyId}&isTF=${isTF}&toolid=${isTF ? tool.toolID : 0}&intelconfigid=${intelConfigId}`;
        if (isTF === 0 && section.sectionId > 0) {
            url = `${process.env.REACT_APP_API_KEY}/VisitorAnalytics/GetIntelligencePageByVisitorSection?profileid=${profileId}&startDate=${selectedStartDate}&endDate=${selectedEndDate}&visitorcountryid=${rowDetails.countryId}&visitorid=${rowDetails.companyId}&pagesectionid=${section.sectionId}&packageid=5&selectedcompanyid=${companyId}`
        }
        let fileName = "Insights_Pages_Viewed.pdf";
        let filters = (popupSearchText !== "" && popupSearchText !== null) ? "pagesTitle|contains|" + popupSearchText : "";
        let obj = {
            Title: rowDetails.companyName,
            SubTitle: t("header.TFvisitorAllMessageText1") + rowDetails.companyName + t("header.visitorAllMessageText2") + moment(startDate).format("DD MMM YYYY") + t("header.visitorAllMessageText3") + moment(endDate).format("DD MMM YYYY"),
            PageName: "Pages Viewed",
            Filters: filters,
            Url: url,
            Token: decryptData(localStorage.getItem("token"), cryptoKey),
            FileName: fileName,
            Email: decryptData(localStorage.getItem("email"), cryptoKey),
            Name: decryptData(localStorage.getItem("firstName"), cryptoKey),
            Count: 0,
            CountTitle: "",
            FilterContent: "",
            ClientName: "",
            TableHeaders: isTF ? VITableHeaders.PagesViewedTFTable : VITableHeaders.PagesViewedTable
        }

        requestPDF(`${process.env.REACT_APP_API_KEY}/CommonV2/GeneratePdf`,
            METHODS.POST,
            obj,
            {
                Authorization: `Bearer ${decryptData(localStorage.getItem("token"), cryptoKey)}`
            })
            .then((res) => {
                const contentType = res.headers['content-type'];
                if (res.status === 200) {
                    if (contentType?.includes('application/pdf')) {
                        const blob = new Blob([res.data], { type: 'application/pdf' });
                        const url = window.URL.createObjectURL(blob);
                        const a = document.createElement('a');
                        a.href = url;
                        a.download = fileName;
                        document.body.appendChild(a);
                        a.click();
                        window.URL.revokeObjectURL(url);
                    }
                    else {
                        setAlertVisible(true);
                        setTimeout(() => {
                            setAlertVisible(false);
                        }, 3000);
                    }
                    setShowLoader(false);
                }
                else {
                    setShowLoader(false);
                    console.error('Error generating PDF.');
                }
            })
            .catch((err) => {
                setShowLoader(false);
                console.error('Error generating PDF:', err);
            })
    };

    const downloadPageLevelPDF = async (pageName, fileName) => {
        setShowLoader(true);
        const content = document.createElement("div");
        content.innerHTML = document.querySelector('.tab-content .fade.tab-pane.active.show.' + pageName)?.innerHTML;
        const options = {
            filename: fileName,
            margin: [5, 2, 2, 2], //[top, right, bottom, left]
            image: { type: "jpeg", quality: 1 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: "mm", format: "a4", orientation: "landscape" }
        };
        const combinedNode = document.createElement("div");
        combinedNode.innerHTML = `<div class="row mt-3">
                <div class="col-md-12">
                    <div class="ms-2 mb-0 pdf-download-title">${rowDetails.companyName}</div>
                </div>
            </div>`;
        combinedNode.appendChild(content.cloneNode(true));
        combinedNode.querySelectorAll('.pagination-content')?.forEach(btn => {
            btn.style.display = "none";
        });
        if (combinedNode.querySelector(".table-responsive-lg")) {
            combinedNode.querySelector(".table-responsive-lg")?.querySelectorAll("tr").forEach(tr => {
                tr.style.pageBreakInside = "avoid";
            });
        }
        combinedNode.querySelectorAll(".me-1")?.forEach(item => {
            item.style.display = "none";
        });
        combinedNode.querySelectorAll("a").forEach(link => {
            link.removeAttribute("href");
            link.style.pointerEvents = "none";
        });
        combinedNode.innerHTML = await covertSVGToIMG(combinedNode);
        await html2pdf().set(options).from(combinedNode).save().then(() => {
            setShowLoader(false);
        });
    }

    const loadImageAsync = (img) => {
        return new Promise((resolve, reject) => {
            img.onload = () => resolve(img);
            img.onerror = reject;
        });
    };

    const covertSVGToIMG = async (content) => {
        var dynamicSvgs = content.querySelectorAll('svg:not(.svg-inline--fa):not(.icon-nav)');

        for (let i = 0; i < dynamicSvgs.length; i++) {
            dynamicSvgs[i].querySelectorAll("line").forEach(x => {
                x.setAttribute("stroke", "#dddee2");
            });
            dynamicSvgs[i].querySelectorAll("path.domain[stroke='currentColor']").forEach(x => {
                x.style.display = "none";
            });

            var canvas = document.createElement('canvas');
            const scaleFactor = 2;
            canvas.width = dynamicSvgs[i].width.baseVal.value * scaleFactor;
            canvas.height = dynamicSvgs[i].height.baseVal.value * scaleFactor;

            var context = canvas.getContext('2d');
            context.scale(scaleFactor, scaleFactor);

            context.fillStyle = '#ffffff';
            context.fillRect(0, 0, canvas.width, canvas.height);

            var svgData = new XMLSerializer().serializeToString(dynamicSvgs[i]);
            var img = new window.Image();
            img.src = 'data:image/svg+xml,' + encodeURIComponent(svgData);

            await loadImageAsync(img);

            const scaleWidthFactor = 1;

            context.drawImage(img, 0, 0, img.width * scaleWidthFactor, img.height * scaleWidthFactor);

            var imgSrc = canvas.toDataURL('image/jpeg');
            var dynamicImg = document.createElement('img');
            dynamicImg.src = imgSrc;

            let parent = dynamicSvgs[i].parentNode;
            while (parent && parent !== content) {
                parent = parent.parentNode;
            }

            if (parent === content) {
                dynamicSvgs[i].parentNode.replaceChild(dynamicImg, dynamicSvgs[i]);
            }
        }
        return content.innerHTML;
    };

    const companyUrlClick = (event, url) => {
        event.preventDefault();
        window.open(url, "_blank");
    };

    const changeStartDate = (current) => {
        if (errorMessage == "Enter custom date range" || (errorMessage == t("header.dateValidationErrorNew") && moment(notificationAddEditData.enddate) > moment(current))) {
            setAddEditFormErrors("");
        }
        setNotificationAddEditData(prevState => ({
            ...prevState,
            startdate: current
        }));
    }

    const changeEndDate = (current) => {
        if (errorMessage == "Enter custom date range" || (errorMessage == t("header.dateValidationErrorNew") && moment(current) > moment(notificationAddEditData.startdate))) {
            setAddEditFormErrors("");
        }
        setNotificationAddEditData(prevState => ({
            ...prevState,
            enddate: current
        }));
    }

    const openNotificationPopup = (row) => {
        setNotificationEditData(row);
        let curl = "";
        let moreurls = [];
        request(
            `${process.env.REACT_APP_API_KEY}/WebsiteAnalytics/GetIntelligenceDomainByVisitor?visitorid=` + row.companyId + `&countryid=0`,
            METHODS.GET,
            {},
            {
                Authorization: `Bearer ${decryptData(localStorage.getItem("token"), cryptoKey)}`, "content-type": "application/json"
            }
        ).then((response) => {
            if (response?.resultData?.length > 0) {
                for (let i = 0; i < response.resultData.length; i++) {
                    if (response.resultData[i].domainUrl != "" && curl == "") {
                        curl = response.resultData[i].domainUrl;
                    }
                    else if (response.resultData[i].domainUrl != "") {
                        moreurls.push(response.resultData[i].domainUrl)
                    }
                    else if (i == 0 && response.resultData.length == 1) {
                        curl = "-"
                    }
                }
            }
            setNotificationAddEditData(prevState => ({
                ...prevState,
                companyname: row.companyName,
                country: row.countryName,
                notificationname: row.notificationName,
                companyurl: curl,
                moreurls: moreurls,
                thresholdisvalid: true,
                notificationnameisvalid: true,
                startdateisvalid: true,
                enddateisvalid: true,
                operation: row.ruleId == 0 ? "add" : "edit",
                durationId: row.ruleId == 0 ? 4 : row.durationId,
                threshold: row.pageViewsCount > 0 ? "" + row.pageViewsCount : "",
                startdate: row.durationId == 3 ? row.fromDate : null,
                enddate: row.durationId == 3 ? row.toDate : null,
            }));
            setShowAddEditPopup(true);
            setAddEditFormErrors("");
        });
        if (duration.length == 0) {
            request(
                `${process.env.REACT_APP_API_KEY}/WebsiteAnalytics/GetNotificationDuration?packageid=5`,
                METHODS.GET,
                {},
                {
                    Authorization: `Bearer ${decryptData(localStorage.getItem("token"), cryptoKey)}`, "content-type": "application/json"
                }
            ).then((response) => {
                if (response?.resultData) {
                    setDuration(response.resultData)
                }
            });
        }
    }

    const onFormElementsChange = (event) => {
        const { name, value, id, checked } = event.target;
        const forms = { ...notificationAddEditData };
        let formVlue;
        if (name == "durationId") {
            formVlue = id;
        }
        else if (name === "confirm" || name === "toggle")
            formVlue = checked;
        else if (name === "threshold") {
            formVlue = value.replace(/\D/g, "");

            if (formVlue == "0" || formVlue.trim().length == 0) {
                forms['thresholdisvalid'] = false;
            }
            else {
                forms['thresholdisvalid'] = true;
            }
        }
        else if (name === "companyname") {
            formVlue = value;
            //onTextChanged(value);
        }
        else if (name == "notificationname") {
            formVlue = value.replace(/[&+()$~%'":;<>`]/g, '');
        }
        else
            formVlue = value;
        forms[name] = formVlue;
        if (formVlue.trim().length > 0 && name !== "threshold") {
            forms[name + 'isvalid'] = true;
        }
        else if (name !== "threshold") {
            forms[name + 'isvalid'] = false;
        }
        setNotificationAddEditData(forms);
    };

    const validateFormElement = (e) => {
        e.preventDefault();
        let iserror = 0;
        if (notificationAddEditData.companyname.trim().length == 0) {
            setNotificationAddEditData(prevState => ({
                ...prevState,
                companynameisvalid: false
            }));
            iserror = 1;
        }
        if (notificationAddEditData.notificationname.trim().length == 0) {
            setNotificationAddEditData(prevState => ({
                ...prevState,
                notificationnameisvalid: false
            }));
            iserror = 1;
        }

        if (notificationAddEditData.threshold.trim().length == 0 || notificationAddEditData.threshold.trim() == "0 or more") {
            setNotificationAddEditData(prevState => ({
                ...prevState,
                thresholdisvalid: false
            }));
            iserror = 1;
        }
        if (notificationAddEditData.durationId == 3) {
            if (notificationAddEditData.startdate === null) {
                setAddEditFormErrors("Enter custom date range")
                setNotificationAddEditData(prevState => ({
                    ...prevState,
                    startdateisvalid: false
                }));
                iserror = 1;
            }
            if (notificationAddEditData.enddate === null) {
                setAddEditFormErrors("Enter custom date range")
                setNotificationAddEditData(prevState => ({
                    ...prevState,
                    enddateisvalid: false
                }));
                iserror = 1;
            }
            if (moment(notificationAddEditData.enddate) < moment(notificationAddEditData.startdate)) {
                setAddEditFormErrors(t("header.dateValidationErrorNew"))
                setNotificationAddEditData(prevState => ({
                    ...prevState,
                    enddateisvalid: false
                }));
                iserror = 1;
            }
        }
        if (iserror == 0) {
            onAddEditSave();
        }
    }

    const closeAddEditPopup = () => {
        setShowAddEditPopup(false)
    }

    const validStartDate = (current) => {
        let yesterday = moment().subtract(1, 'day');;
        return current.isAfter(yesterday);
    };

    const validEndDate = (current) => {
        let yesterday = moment().subtract(1, 'day');;
        return current.isAfter(yesterday);
    };

    const onAddEditSave = () => {
        let notificationd = {}

        if (notificationAddEditData.operation === "edit") {
            notificationd = {
                CompanyId: notificationEditData.companyId,
                CountryId: notificationEditData.countryId,
                DurationId: notificationAddEditData.durationId,
                EmailAlert: 1,
                FromDate: notificationAddEditData.durationId == 3 ? notificationAddEditData.startdate : "0001-01-01T00:00:00",
                ToDate: notificationAddEditData.durationId == 3 ? notificationAddEditData.enddate : "0001-01-01T00:00:00",
                IntellConfigId: notificationEditData.intellConfigId,
                IsActive: 1,
                NotificationName: notificationAddEditData.notificationname,
                PageViewsCount: notificationAddEditData.threshold.replace(" or more", ""),
                RuleId: notificationEditData.ruleId,
                SMSAlert: 0
            }
        }
        else {
            notificationd = {
                CompanyId: notificationEditData.companyId,
                CountryId: notificationEditData.countryId,
                DurationId: notificationAddEditData.durationId,
                EmailAlert: 1,
                FromDate: notificationAddEditData.durationId == 3 ? notificationAddEditData.startdate : "0001-01-01T00:00:00",
                ToDate: notificationAddEditData.durationId == 3 ? notificationAddEditData.enddate : "0001-01-01T00:00:00",
                IntellConfigId: JSON.parse(localStorage.getItem(isTF ? "TnfWebsiteProfilesIntelConfigId" : "WebsiteProfilesIntelConfigId"))["WebsiteProfiles.IntelConfigId"],
                IsActive: 1,
                NotificationName: notificationAddEditData.notificationname,
                PageViewsCount: notificationAddEditData.threshold.replace(" or more", ""),
                RuleId: 0,
                SMSAlert: 0
            }
        }

        notificationOperation(notificationd);
    }

    const notificationOperation = (data) => {
        request(`${process.env.REACT_APP_API_KEY}/WebsiteAnalytics/SetNotification?companyid=${companyId}&packageid=5&isTF=${isTF}`,
            METHODS.POST,
            data,
            {
                Authorization: `Bearer ${decryptData(localStorage.getItem("token"), cryptoKey)}`, "content-type": "application/json"
            }
        ).then((response) => {
            if (response?.resultData && response.resultData?.ruleId > 0) {
                setSuccessMessage(t("header.Message_" + convertTranslatinString(response.resultData.message)));
                setTimeout(() => {
                    setSuccessMessage(undefined);
                }, ERROR_TIMEOUT);
                setShowAddEditPopup(false);
                setVisitorWatchlistData();
            }
            else {
                setErrorMessage(response.resultData.message.length > 0 ? t("header.Message_" + convertTranslatinString(response.resultData.message)) : t("header.Something_went_wrong"));
                setTimeout(() => {
                    setErrorMessage(undefined);
                }, ERROR_TIMEOUT);
            }
        }).catch((error) => {
            setErrorMessage(t("header.Something_went_wrong"));
            setTimeout(() => {
                setErrorMessage(undefined);
            }, ERROR_TIMEOUT);
        });
    }

    const addNotificationWatchlist = (event, data) => {
        const { checked } = event.target;
        let notificationd = {}
        if (data["watchlist.active"] == true) {
            notificationd = {
                CompanyId: data.companyId,
                CountryId: data.countryId,
                DurationId: data.durationId,
                EmailAlert: 1,
                FromDate: data.fromDate != null ? data.fromDate : "0001-01-01T00:00:00",
                ToDate: data.toDate != null ? data.toDate : "0001-01-01T00:00:00",
                IntellConfigId: data.intellConfigId,
                IsActive: checked,
                NotificationName: data.notificationName,
                PageViewsCount: data.pageViewsCount,
                RuleId: data.ruleId,
                SMSAlert: 0
            }
            setShowLoader(true);
            notificationOperation(notificationd);
        }
        else {
            openNotificationPopup(data);
        }
    }

    return (
        <>
            {showLoader && <div id='blurdiv' className="blurbackground">
                <div className={`preloader loader-center-align`}>
                    <Image className="loader-element animate__animated animate__jackInTheBox" src={LoaderImage} height={40} data-seconds={30} />
                </div>
            </div>}
            {(successMessage || errorMessage) && <div className="fade modal-backdrop show"></div>}
            <Alert
                className="toast-custom"
                variant="success"
                show={successMessage ? true : false}
                onClose={() => onCloseSuccessMessage()}
            >
                <div className="d-flex justify-content-between">
                    <div>
                        <FontAwesomeIcon icon={faCheckCircle} className="me-2" />
                        {successMessage}
                    </div>
                    <Button variant="close" size="xs" onClick={() => onCloseSuccessMessage()} />
                </div>
            </Alert>
            <Alert
                className="toast-custom"
                variant="danger"
                show={errorMessage ? true : false}
                onClose={() => onCloseErrorMessage()}
            >
                <div className="d-flex justify-content-between">
                    <div>
                        <FontAwesomeIcon icon={faCheckCircle} className="me-2" />
                        {errorMessage}
                    </div>
                    <Button variant="close" size="xs" onClick={() => onCloseErrorMessage()} />
                </div>
            </Alert>
            {showQuestionModal && (
                <QuestionModal
                    onSuccessModelClose={onSuccessModelClose}
                    showModal={showQuestionModal}
                    onCancelClose={onCancelClose}
                    setShowModal={setShowQuestionModal}
                    isSuccess={isSuccess}
                    setIsSuccess={setIsSuccess}
                    QuestionModalContent={questionModalContent}
                />
            )}
            {
                watchlist.transactionStatus === DataStatusMessage.DataAvailable && <DataTableRenderer
                    header={watchlist.header}
                    dataSet={watchlist.data}
                    transactionStatus={watchlist.transactionStatus}
                    rowClickEvent={rowClickEvent}
                    handleDeleteWatchlistButtonClick={handleDeleteWatchlistButtonClick}
                    addNotificationWatchlist={addNotificationWatchlist}
                    customAttributes={
                        {
                            displayRowsPerPage: 15,
                            showPagination: true,
                            summaryText: t("header.watchlistTotalWatchlist"),
                            tooltipText: t("header.VisitorsWatchlistCountHelpTooltipText"),
                            summaryCount: watchlist.data.length,
                            showCount: true
                        }
                    }
                ></DataTableRenderer>
            }
            <Row>
                <Col md={12}>
                    <CommonMessageComponent transactionStatus={watchlist.transactionStatus}></CommonMessageComponent>
                </Col>
            </Row>

            <ModalComponent
                title={rowDetails.companyName}
                showCloseButton={true}
                open={showWatchlistModal}
                handleClose={() => { handleModalClose() }}
                className="visitor-analytics-modal tnf-insights-modal">
                <Row>
                    <Col>
                        {showLoader && <div id='blurdiv' className="blurbackground">
                            <div className={`preloader loader-center-align`}>
                                <Image className="loader-element animate__animated animate__jackInTheBox" src={LoaderImage} height={40} data-seconds={30} />
                            </div>
                        </div>}
                        {alertVisible && <div className="fade modal-backdrop show"></div>}
                        <Alert
                            className="toast-custom"
                            variant="success"
                            show={alertVisible}
                        >
                            <div className="d-flex justify-content-between">
                                <div>
                                    <FontAwesomeIcon icon={faCheckCircle} className="me-2" />
                                    {t("header.PdfDeliveredToMailMessage")}
                                </div>
                            </div>
                        </Alert>
                        <Row className="position-relative hide-mobile"
                            hidden={tabWatchlistModal == 2}>
                            <Col md={12}>
                                {/*Pages viewed download chart */}
                                <Row className="mb-4">
                                    <Col md={12} className={localStorage.getItem("isDemoClient") ? "custom-disable text-end" : "text-end"}>
                                        <div className="icon-nav-list svg-icon-nav-list float-end mb-2">
                                            <span className={(watchlistPopupLinechart.transactionStatus === DataStatusMessage.DataAvailable || watchlistPagesViewedTable.transactionStatus === DataStatusMessage.DataAvailable) ? "icon-nav-box ms-2 hide-mobile custom-select" : "icon-nav-box hide-mobile disabled"}
                                                onClick={() => downloadPageLevelPDF('visitorsContent', 'Insights_Pages_Viewed')}
                                            >
                                                <svg className="icon-nav">
                                                    <title>{t("header.Icon_Tooltip_DownloadPDF")}</title>
                                                    <use href={iconSprite + `#Download`} />
                                                </svg>
                                            </span>
                                        </div>
                                    </Col>
                                </Row>

                                {/* Pages viewed table search */}
                                <Row>
                                    <Col md={12} className={localStorage.getItem("isDemoClient") ? "custom-disable text-end" : "text-end"}>
                                        <div className="search-box-container modal-search-box">
                                            <Search
                                                setsearchData={setPopupSearchText}
                                                searchData={popupSearchText}
                                                searchWaterMark={t("header.visitorAllWatermark")}
                                                isAutoComplete={true}
                                                setAutoFillForCompany={setShowAutoFillForPopupSearch}
                                                showAutoFillForCompany={showAutoFillForPopupSearch}
                                                data={popupSearchDataList}
                                                type={"client"}
                                                emptyTextBox={toggle}
                                            ></Search>

                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row className="position-relative hide-mobile"
                            hidden={tabWatchlistModal == 1}>
                            <Col md={12}>
                                {/* Traffic source download chart */}
                                <Row className="mb-4">
                                    <Col md={12} className={localStorage.getItem("isDemoClient") ? "custom-disable text-end" : "text-end"}>
                                        <div className="icon-nav-list svg-icon-nav-list float-end mb-2">
                                            <span className={(trafficSourceBarchart.transactionStatus === DataStatusMessage.DataAvailable || trafficSourceTable.transactionStatus === DataStatusMessage.DataAvailable) ? "icon-nav-box ms-2 hide-mobile custom-select" : "icon-nav-box hide-mobile disabled"}
                                                onClick={() => downloadPageLevelPDF('trafficContent', 'Insights_Traffic_Sources')}
                                            >
                                                <svg className="icon-nav">
                                                    <title>{t("header.Icon_Tooltip_DownloadPDF")}</title>
                                                    <use href={iconSprite + `#Download`} />
                                                </svg>
                                            </span>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                    <Col md={12}>
                        <Tab.Container defaultActiveKey="ToolsViewed">
                            <Nav fill variant="pills" className="tab subtab ms-1 me-0">
                                <Nav.Item className="pe-0">
                                    <Nav.Link eventKey="ToolsViewed" onClick={() => { setTabWatchlistModal(1) }} className="mb-sm-3 mb-md-0">
                                        {isTF ? t("header.TFVisitorsActivityPopPagesTabText") : t("header.VisitorsActivityPopPagesTabText")}
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="TrafficSources" onClick={() => { setTabWatchlistModal(2) }} className="mb-sm-3 mb-md-0">
                                        {t("header.VisitorsActivityPopTrafficTabText")}
                                    </Nav.Link>
                                </Nav.Item>
                            </Nav>
                            <Tab.Content>
                                <Tab.Pane eventKey="ToolsViewed" className="mt-4 visitorsContent" >
                                    <Row className="mt-4">
                                        <Col md={12}>
                                            <p>{isTF ? t("header.TFvisitorAllMessageText1") : t("header.visitorAllMessageText1")} {rowDetails.companyName} {t("header.visitorAllMessageText2")} {moment(startDate).format("DD MMM YYYY")} {t("header.visitorAllMessageText3")} {moment(endDate).format("DD MMM YYYY")}</p>
                                        </Col>
                                    </Row>
                                    {tabWatchlistModal === 1 &&
                                        <>
                                            {/* Pages viewed line chart */}
                                            <>
                                                {
                                                    watchlistPopupLinechart.transactionStatus === DataStatusMessage.DataAvailable &&
                                                    <Row>
                                                        <Col md={12}>
                                                            <DataChartRenderer
                                                                data={
                                                                    watchlistPopupLinechart.result
                                                                }
                                                                chartType="line" />
                                                        </Col>
                                                    </Row>
                                                }
                                                {
                                                    watchlistPopupLinechart.transactionStatus !== DataStatusMessage.DataAvailable &&
                                                    <Row>
                                                        <Col md={12}>
                                                            <CommonMessageComponent transactionStatus={watchlistPopupLinechart.transactionStatus}></CommonMessageComponent>
                                                        </Col>
                                                    </Row>
                                                }
                                            </>
                                            {watchlistPagesViewedTable.transactionStatus !== DataStatusMessage.DataUnAvailable &&
                                                <Row>
                                                    <Col md={12} className={localStorage.getItem("isDemoClient") ? "custom-disable text-end" : "text-end"}>
                                                        <div className="icon-nav-list svg-icon-nav-list float-end mb-2">
                                                            <span className={watchlistPagesViewedTable.transactionStatus === DataStatusMessage.DataAvailable ? "icon-nav-box ms-2 hide-mobile" : "icon-nav-box hide-mobile disabled"}>
                                                                <Dropdown as={ButtonGroup}>
                                                                    <Dropdown.Toggle split className="card-dropdown-btn p-0 border-0 pdf-download-btn">
                                                                        <svg className="icon-nav">
                                                                            <title>{t("header.Icon_Tooltip_Download")}</title>
                                                                            <use href={iconSprite + `#Download`} />
                                                                        </svg>
                                                                    </Dropdown.Toggle>
                                                                    <Dropdown.Menu>
                                                                        {setDowloadDropdownMenu()}
                                                                    </Dropdown.Menu>
                                                                </Dropdown>
                                                            </span>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            }


                                            {/* Pages viewed table */}
                                            <>
                                                {
                                                    watchlistPagesViewedTable.transactionStatus === DataStatusMessage.DataAvailable &&
                                                    <Row>
                                                        <Col md={12}>
                                                            {
                                                                watchlistPagesViewedTable.transactionStatus === DataStatusMessage.DataAvailable && <DataTableRenderer
                                                                    header={watchlistPagesViewedTable.header}
                                                                    dataSet={watchlistPagesViewedTable.data}
                                                                    transactionStatus={watchlistPagesViewedTable.transactionStatus}
                                                                    customAttributes={{ displayRowsPerPage: 10, showPagination: true }}
                                                                ></DataTableRenderer>
                                                            }
                                                        </Col>
                                                    </Row>
                                                }
                                                {
                                                    watchlistPopupLinechart.transactionStatus !== DataStatusMessage.DataAvailable &&
                                                    <Row>
                                                        <Col md={12}>
                                                            <CommonMessageComponent transactionStatus={watchlistPopupLinechart.transactionStatus}></CommonMessageComponent>
                                                        </Col>
                                                    </Row>
                                                }
                                            </>
                                        </>
                                    }
                                </Tab.Pane>
                                <Tab.Pane eventKey="TrafficSources" className="mt-4 trafficContent" >
                                    <Row className="mt-4">
                                        <Col md={12}>
                                            <p>{t("header.visitorAllTrafficMessageText1")} {rowDetails.companyName} {t("header.watchlistMessageText")} {moment(startDate).format("DD MMM YYYY")} {t("header.visitorAllTrafficMessageText2")} {moment(endDate).format("DD MMM YYYY")}</p>
                                        </Col>
                                    </Row>
                                    {tabWatchlistModal === 2 &&
                                        <>
                                            <>
                                                {trafficSourceBarchart.transactionStatus === DataStatusMessage.DataAvailable &&
                                                    <Row>
                                                        <Col md={12}>
                                                            <DataChartRenderer
                                                                data={
                                                                    trafficSourceBarchart
                                                                }
                                                                barChartClickEvent={function (d) { setTrafficSource(d.name); getTrafficSource(d.name); }}
                                                                chartType="bar" />
                                                        </Col>
                                                    </Row>
                                                }
                                                {
                                                    trafficSourceBarchart.transactionStatus !== DataStatusMessage.DataAvailable &&
                                                    <Row>
                                                        <Col md={12}>
                                                            <CommonMessageComponent transactionStatus={trafficSourceBarchart.transactionStatus}></CommonMessageComponent>
                                                        </Col>
                                                    </Row>
                                                }
                                            </>
                                            {(trafficSourceBarchart.transactionStatus === DataStatusMessage.DataAvailable || trafficSourceTable.transactionStatus === DataStatusMessage.DataAvailable) &&
                                                <>
                                                    <Row className="mb-0">
                                                        <Col md={12}>
                                                            {trafficSourceText &&
                                                                <div className="alert alert-gray-800" role="alert">
                                                                    <p className="mb-0 text-body fw-bold"><i className="fa fa-info-circle lg"></i> {trafficSourceText}</p>
                                                                </div>
                                                            }
                                                        </Col>
                                                    </Row>
                                                    <Row className="mb-0 mt-0">
                                                        <Col md={12} >
                                                            <p className="text-body fw-bold">{trafficSourceTotal} </p>
                                                        </Col>
                                                    </Row>
                                                </>
                                            }

                                            {/* Traffic sources table */}
                                            {trafficSourceTable.transactionStatus === DataStatusMessage.DataAvailable &&
                                                <Row>
                                                    <Col md={12}>
                                                        {(trafficSource === "Organic" || trafficSource === "Referral" || trafficSource === "Social") &&
                                                            <DataTableRenderer
                                                                header={trafficSourceTable.header}
                                                                dataSet={trafficSourceTable.data}
                                                                transactionStatus={trafficSourceTable.transactionStatus}
                                                                customAttributes={{ displayRowsPerPage: 10, showPagination: true }}
                                                            ></DataTableRenderer>
                                                        }
                                                    </Col>
                                                </Row>
                                            }
                                            {
                                                trafficSourceTable.transactionStatus === DataStatusMessage.Loading &&
                                                <Row>
                                                    <Col md={12}>
                                                        <CommonMessageComponent transactionStatus={trafficSourceTable.transactionStatus}></CommonMessageComponent>
                                                    </Col>
                                                </Row>
                                            }
                                        </>
                                    }
                                </Tab.Pane>
                            </Tab.Content>
                        </Tab.Container>
                    </Col>
                </Row>
            </ModalComponent>
            <ModalComponent title={t("header.notificationSetting")}
                showCloseButton={true}
                open={showAddEditPopup}
                handleClose={closeAddEditPopup}
            >
                <Row>
                    <Col md={12}>
                        <p className='fw-bold'>{t("header.WONotificationnotifiedText")}</p>
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col md={3}>
                        {t("header.WONotificationSetupCName")}   {notificationAddEditData.operation == "add" && <span style={{ color: "red" }}>*</span>}
                    </Col>
                    <Col md={4}>
                        {notificationAddEditData.companyname}
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col md={3}>
                        {t("header.WONotificationSetupCountry")}
                    </Col>
                    <Col md={4}>
                        {notificationAddEditData.country}
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col md={3}>
                        {t("header.WONotificationSetupCompURL")}
                    </Col>
                    <Col md={9}>
                        {notificationAddEditData.companyurl !== "-" ? <a className='link-primary' onClick={(e) => companyUrlClick(e, notificationAddEditData.companyurl)} >{notificationAddEditData.companyurl}</a> : "-"}

                        {notificationAddEditData.moreurls.length > 0 && <>
                            <OverlayTrigger
                                placement="bottom"
                                trigger="click"
                                overlay={
                                    <Popover className="notification-url-popover">
                                        <Popover.Content>
                                            <ul className="list-unstyled auto-dropdown-menu mb-0">
                                                {notificationAddEditData.moreurls.map((item) =>

                                                    <li className="auto-dropdown-menu-item mt-1 mb-1 link-primary" onClick={(e) => companyUrlClick(e, item)} >
                                                        {item}
                                                    </li>
                                                )
                                                }
                                            </ul>
                                        </Popover.Content>
                                    </Popover>
                                }>
                                <Button variant="white" size="sm" className="m-2">{t("header.WONotificationSetupMore")}</Button>
                            </OverlayTrigger></>}
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col md={3}>
                        {t("header.WONotificationSetupName")} <span style={{ color: "red" }}>*</span>
                    </Col>
                    <Col md={4}>
                        {
                            (notificationAddEditData.notificationnameisvalid ? <Form.Control maxLength={100} type="text" value={notificationAddEditData.notificationname} onChange={(e) => onFormElementsChange(e)} name="notificationname" autoComplete="off" /> :
                                <Form.Control isInvalid maxLength={100} value={notificationAddEditData.notificationname} type="text" onChange={(e) => onFormElementsChange(e)} name="notificationname" autoComplete="off" />)
                        }
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col md={12}>
                        <b>
                            {t("header.notificationNofifyMessage")}
                        </b><br />
                        {t("header.notificationsProcessingMessage")}
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col md={6}>
                        <Form.Group>
                            {duration.map((type, i) => (
                                <FormCheck
                                    checked={type.durationId == notificationAddEditData.durationId ? true : false}
                                    type="radio"
                                    value={notificationAddEditData.durationId}
                                    label={t("header." + convertTranslatinString(type.description))}
                                    name="durationId"
                                    id={type.durationId}
                                    onChange={(e) => onFormElementsChange(e)}
                                />
                            ))}
                        </Form.Group>
                    </Col>
                    {notificationAddEditData.durationId == 3 && <>
                        <Col md={6}>
                            <Row>
                                <Col md={6}>
                                    <Datetime
                                        closeOnClickOutside={false}
                                        timeFormat={false}
                                        closeOnSelect={true}
                                        onChange={changeStartDate}
                                        className="mt-2"
                                        isValidDate={validStartDate}
                                        renderInput={(props, openCalendar) => (
                                            <InputGroup>
                                                <Form.Control
                                                    required
                                                    type="text"
                                                    value={notificationAddEditData.startdate ? moment(notificationAddEditData.startdate).format("MMM DD,YYYY") : ""}
                                                    placeholder={t("header.notificationsFrom")}
                                                    onFocus={openCalendar}
                                                />
                                                <InputGroup.Text><FontAwesomeIcon icon={faCalendarAlt} /></InputGroup.Text>
                                            </InputGroup>
                                        )} />
                                </Col>
                                <Col md={6}>
                                    <Datetime
                                        closeOnClickOutside={false}
                                        timeFormat={false}
                                        closeOnSelect={true}
                                        onChange={changeEndDate}
                                        isValidDate={validEndDate}
                                        className="mt-2"
                                        renderInput={(props, openCalendar) => (
                                            <InputGroup>
                                                <Form.Control
                                                    required
                                                    type="text"
                                                    value={notificationAddEditData.enddate ? moment(notificationAddEditData.enddate).format("MMM DD,YYYY") : ""}
                                                    placeholder={t("header.notificationsTo")}
                                                    onFocus={openCalendar}
                                                    onChange={() => { }} />
                                                <InputGroup.Text><FontAwesomeIcon icon={faCalendarAlt} /></InputGroup.Text>
                                            </InputGroup>
                                        )} />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={8}>
                                    <div className="invalid-feedback d-block" style={{ textAlign: "left" }}>{addEditFormErrors ? addEditFormErrors : ''}</div>
                                </Col>
                            </Row>
                        </Col>
                    </>}
                </Row>
                <Row className="mt-4">
                    <Col md={3}>
                        {t("header.WONotificationSetupPVThreshold")} <span style={{ color: "red" }}>*</span>
                    </Col>
                    <Col md={4}>
                        <Form.Group className="mb-3">
                            {
                                (notificationAddEditData.thresholdisvalid ? <Form.Control maxLength={8} onBlur={() => {
                                    if (notificationAddEditData.threshold.length > 0) {
                                        setNotificationAddEditData(prevState => ({
                                            ...prevState,
                                            threshold: notificationAddEditData.threshold + " or more"
                                        }));
                                    }
                                }} onFocus={() => {
                                    if (notificationAddEditData.threshold.length > 0) {
                                        setNotificationAddEditData(prevState => ({
                                            ...prevState,
                                            threshold: notificationAddEditData.threshold.replace(" or more", "")
                                        }))
                                    }
                                }} type="text" value={notificationAddEditData.threshold} onChange={(e) => onFormElementsChange(e)} name="threshold" autoComplete="off" /> :
                                    <Form.Control isInvalid maxLength={8} onBlur={() => {
                                        if (notificationAddEditData.threshold.length > 0) {
                                            setNotificationAddEditData(prevState => ({
                                                ...prevState,
                                                threshold: notificationAddEditData.threshold + " or more"
                                            }));
                                        }
                                    }} onFocus={() => {
                                        if (notificationAddEditData.threshold.length > 0) {
                                            setNotificationAddEditData(prevState => ({
                                                ...prevState,
                                                threshold: notificationAddEditData.threshold.replace(" or more", "")
                                            }))
                                        }
                                    }}
                                        value={notificationAddEditData.threshold} type="text" onChange={(e) => onFormElementsChange(e)} name="threshold" autoComplete="off" />)
                            }
                        </Form.Group>
                        {notificationAddEditData.threshold == "0 or more"
                            && <div className="invalid-feedback d-block">{t("header.WONotificationSetupPageViewsThreshold")}</div>
                        }
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col md={3}>
                    </Col>
                    <Col md={3}>
                        <ButtonComponent variant="secondary" btnText={t("header.Btn_Save")} isIcon={false} isPrimary={true} btn_class="me-3" onClick={(e) => validateFormElement(e)}>
                        </ButtonComponent>
                        <ButtonComponent variant="btn-outline-primary" btnText={t("header.Btn_Cancel")} isIcon={false} isPrimary={false} btn_class="mb-0" onClick={(e) => closeAddEditPopup(e)} >
                        </ButtonComponent>
                    </Col>
                </Row>
            </ModalComponent>
        </>
    );
}