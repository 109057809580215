import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from "react-i18next";
import { Row, Col } from '@themesberg/react-bootstrap';
import moment from "moment";

import AppContext from '../components/AppContext';
import request from '../apis/request';
import { METHODS, baseURL } from '../apis/utilities/constant';
import { decryptData } from '../utils/crypto';
import { object_equals } from '../AppConfig';

import CommonHeader from '../components/CommonHeader';
import DropdownWithIconComponent from "../components/DropdownWithIcons";
import { Summary } from '../components/VisitorAnalytics/Summary';
import { Charts } from '../components/VisitorAnalytics/Charts';
import { Tabs } from '../components/VisitorAnalytics/Tabs';

export const VisitorInsightsPage = () => {

    const [t] = useTranslation();
    const contextData = useContext(AppContext);

    const [sectionList, setSectionList] = useState([]);
    const [section, setSection] = useState(null);

    const [isGA4Profile, setIsGA4Profile] = useState(false);

    const [websiteList, setWebsiteList] = useState([]);
    const [profilesList, setProfilesList] = useState([]);

    const [selectedWebsiteIdNew, setSelectedWebsiteIdNew] = useState(websiteList[0]);
    const [selectedProfileIdNew, setSelectedProfileIdNew] = useState({})
    const [autoLoad, setAutoLoad] = useState(false);

    useEffect(() => {
        if (contextData.companyId > 0) {
            if (localStorage.getItem("websiteData") && JSON.parse(localStorage.getItem("websiteData")).length > 0
                && localStorage.getItem("AllprofileData") && JSON.parse(localStorage.getItem("AllprofileData")).length > 0) {
                let websiteList = JSON.parse(localStorage.getItem("websiteData"));
                let selectedWebsite = JSON.parse(localStorage.getItem("WebsiteListWebsiteId"));
                renderWebsite(websiteList, selectedWebsite)
            }
            else if (!localStorage.getItem("websiteData") || !localStorage.getItem("AllprofileData")) {
                if (!localStorage.getItem("websiteData")) {
                    getWebsites();
                }
                else if (!localStorage.getItem("AllprofileData")) {
                    getProfiles(JSON.parse(localStorage.getItem("websiteData")));
                }
            }
        }
    }, [contextData.companyId])

    useEffect(() => {
        if (contextData.selectedProfile["WebsiteProfiles.ProfileId"]) {
            const selectedStartDate = moment(contextData.startDate).format("YYYY-MM-DD");
            const selectedEndDate = moment(contextData.endDate).format("YYYY-MM-DD");
            request(`${process.env.REACT_APP_API_KEY}/VisitorAnalytics/GetWebsiteInsightSummaryByProfileId?endDate=${selectedEndDate}&isAnalyticsRequired=false&profileId=${contextData.selectedProfile["WebsiteProfiles.ProfileId"]}&startDate=${selectedStartDate}`, METHODS.GET, {},
                { Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json" })
                .then((response) => {
                    if (response != null) {
                        setSectionList(response.data);
                        setSection(response.data[0]);
                        setIsGA4Profile(response.isGA4Profile);
                    }
                })
                .catch(() => {
                })
        }
    }, [contextData.selectedProfile["WebsiteProfiles.ProfileId"], contextData.startDate, contextData.endDate]);

    const getWebsites = () => {
        request(baseURL + `/CommonV2/GetWebsiteChannelWebsiteByClientUser?CompanyId=${contextData.companyId}&UserId=${contextData.userId}`,
            METHODS.GET,
            {},
            {
                Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
            })
            .then((res) => {
                let resultData = [];

                if (res.result && res.result.length > 0) {
                    resultData = res.result.map((e) => ({ cmsplatformid: res.platformid, ["WebsiteList.WebsiteName"]: e.WebsiteName, ["WebsiteList.WebsiteId"]: e.WebsiteId, ["WebsiteList.WebsiteUrl"]: e.WebsiteUrl }))
                }

                if (resultData.length > 0) {
                    localStorage.setItem("websiteData", JSON.stringify(resultData))
                    getProfiles(resultData);
                }
                else {
                    localStorage.setItem("websiteData", JSON.stringify([]))
                    localStorage.setItem("AllprofileData", JSON.stringify([]))
                }
            })
            .catch((err) => {
            })
    }

    const getProfiles = (resultData) => {
        request(baseURL + `/WebsiteAnalytics/GetWebsites?CompanyId=${contextData.companyId}&UserId=${contextData.userId}`,
            METHODS.GET,
            {},
            {
                Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
            })
            .then((res) => {
                if (res.result && res.result.length > 0) {
                    let result = removeDuplicateObjects(res.result, 'IntelConfigId')
                    localStorage.setItem("AllprofileData", JSON.stringify(result))
                }
                else {
                    localStorage.setItem("profileData", JSON.stringify([]))
                }
                if (!localStorage.getItem("WebsiteListWebsiteId")) {
                    renderWebsite(resultData, resultData[0])
                }
                else {
                    renderWebsite(resultData, JSON.parse(localStorage.getItem("WebsiteListWebsiteId")))
                }
                if (res.result.length == 0) {
                    setAutoLoad(true);
                }
            })
            .catch((err) => {
            })
    }

    const renderWebsite = (resultSet, selectedWebsiteValue) => {
        setWebsiteList(resultSet)
        setSelectedWebsiteIdNew(selectedWebsiteValue);
        renderProfileList(selectedWebsiteValue, 0);
    };

    const renderProfileList = (selectedWebsiteValue, isTempUpdate) => {

        if (localStorage.getItem("AllprofileData") && JSON.parse(localStorage.getItem("AllprofileData")).length > 0) {

            let profileList = JSON.parse(localStorage.getItem("AllprofileData"));
            let selectedWebsiteProfiles = profileList.filter((e) => { return e.WebsiteId === selectedWebsiteValue["WebsiteList.WebsiteId"] });

            if (selectedWebsiteProfiles.length > 0) {
                let profileData = selectedWebsiteProfiles.map((e) => ({ ["WebsiteProfiles.ProfileId"]: e.ProfileId, ["WebsiteProfiles.DisplayName"]: e.DisplayName, ["WebsiteProfiles.IntelConfigId"]: e.IntelConfigId }));
                setProfilesList(profileData)
                let selectedProfile = profileData[0];

                if (isTempUpdate === 0) {
                    let storedProfile = JSON.parse(localStorage.getItem("WebsiteProfilesIntelConfigId"));
                    if (storedProfile && Object.keys(storedProfile).length > 0
                        && profileData.some(obj => obj["WebsiteProfiles.ProfileId"] === storedProfile["WebsiteProfiles.ProfileId"])) {
                        selectedProfile = storedProfile;
                    };
                    localStorage.setItem("profileData", JSON.stringify(profileData))
                }
                setSelectedProfileIdNew(selectedProfile);
            }
            else {
                setProfilesList([])
                setSelectedProfileIdNew({});
                if (isTempUpdate === 0) {
                    localStorage.setItem("profileData", JSON.stringify([]))
                }
            }
        }
        setAutoLoad(true)
    }

    const removeDuplicateObjects = (array, key) => {
        const seen = {};
        return array.filter(item => {
            const itemKey = key ? item[key] : item;
            return seen.hasOwnProperty(itemKey) ? false : (seen[itemKey] = true);
        });
    }

    const onDropdownChange = (data) => {
        setSection(data)
    };

    const onWebsiteDropdownChange = (data) => {
        if (!object_equals(selectedWebsiteIdNew, data)) {
            setSelectedProfileIdNew({});
        }
        setSelectedWebsiteIdNew(data);
        renderProfileList(data, 1)
    };

    const onProfileDropdownChange = (data) => {
        const intellConfigId = data["WebsiteProfiles.IntelConfigId"];
        contextData.changeIntelConfigId(intellConfigId);
        setSelectedProfileIdNew(data)
    };

    const applyFilters = () => {
        const websiteId = selectedWebsiteIdNew["WebsiteList.WebsiteId"];
        contextData.changeWebsiteId(websiteId);
        contextData.changeSelectedWebsite(selectedWebsiteIdNew);
        contextData.changeSelectedProfile(selectedProfileIdNew);
        if (profilesList.length > 0) {
            localStorage.setItem("profileData", JSON.stringify(profilesList))
        }
        else {
            localStorage.setItem("profileData", JSON.stringify([]))
        }
        localStorage.setItem("WebsiteListWebsiteId", JSON.stringify(selectedWebsiteIdNew));
        localStorage.setItem("WebsiteProfilesIntelConfigId", JSON.stringify(selectedProfileIdNew));
    }

    const cancelFilters = () => {
        onWebsiteDropdownChange(JSON.parse(localStorage.getItem("WebsiteListWebsiteId")))
        onProfileDropdownChange(JSON.parse(localStorage.getItem("WebsiteProfilesIntelConfigId")))
    }

    const commonHeader = useMemo(
        () => (
            <CommonHeader
                cancelFilters={cancelFilters}
                channel="website"
                autoLoad={autoLoad}
                dataSource={[
                    {
                        name: "Website",
                        data: websiteList,
                        selectedItem: selectedWebsiteIdNew,
                        isVisible: true,
                        label: t("header.filterLabelWebsite"),
                        callback: onWebsiteDropdownChange,
                        displayText: "WebsiteList.WebsiteName",
                    },
                    {
                        name: "Profile",
                        data: profilesList,
                        selectedItem: selectedProfileIdNew,
                        isVisible: true,
                        label: t("header.filterLabelProfile"),
                        callback: onProfileDropdownChange,
                        displayText: "WebsiteProfiles.DisplayName",
                    },
                ]}
                title={t("header.visitorAnalyticsTitle")}
                showDate={true}
                applyFilters={applyFilters}
            />
        ),
        [selectedWebsiteIdNew, selectedProfileIdNew, autoLoad]
    );

    return (
        <Row id='visitorAnalyticsContent'>
            <Col>
                <Row className="align-items-center">
                    <Col>
                        {commonHeader}
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} sm={3} className={localStorage.getItem("isDemoClient") ? "custom-disable" : ""}>
                        <div className="ps-3 w-100 d-inline-block">
                            {sectionList.length > 0 && <DropdownWithIconComponent
                                dropdownData={sectionList}
                                arrayValue="title"
                                selectedDropdown={section}
                                onChange={onDropdownChange}
                                showLabel={false}
                                showIconInDropdownItem={false}
                                customClassName="full-width text-overflow"
                                isDropdownDisabled={false}
                            />}
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col lg={12}>
                        {section && <Summary
                            cryptoKey={contextData.cryptoKey}
                            profileId={contextData.selectedProfile["WebsiteProfiles.ProfileId"]}
                            startDate={contextData.startDate}
                            endDate={contextData.endDate}
                            companyId={contextData.companyId}
                            isTF={0}
                            toolId={0}
                            intelConfigId={contextData.selectedProfile["WebsiteProfiles.IntelConfigId"]}
                        />}
                    </Col>
                </Row>
                <Row>
                    <Col lg={12}>
                        {section && <Charts cryptoKey={contextData.cryptoKey} profileId={contextData.selectedProfile["WebsiteProfiles.ProfileId"]}
                            startDate={contextData.startDate} endDate={contextData.endDate} isTF={0} toolId={0} sectionId={section.sectionId} intelConfigId={contextData.selectedProfile["WebsiteProfiles.IntelConfigId"]} />}
                    </Col>
                </Row >
                <Row>
                    <Col lg={12}>
                        {section &&
                            <Tabs
                                userId={contextData.userId}
                                cryptoKey={contextData.cryptoKey}
                                profileId={contextData.selectedProfile["WebsiteProfiles.ProfileId"]}
                                startDate={contextData.startDate}
                                endDate={contextData.endDate}
                                intelConfigId={contextData.selectedProfile["WebsiteProfiles.IntelConfigId"]}
                                companyId={contextData.companyId}
                                isTF={0}
                                section={section}
                                websiteData={contextData.selectedWebsite}
                                profileData={contextData.selectedProfile}
                                companyName={JSON.parse(decryptData(localStorage.getItem("selectedCompanyData"), contextData.cryptoKey))["companyName"]}
                            />}
                    </Col>
                </Row>
                {!section && <h1 className='text-center'>Insights by IDX</h1>}
            </Col>
        </Row>
    )
}
