import React from 'react';
import { Helmet } from 'react-helmet-async';

import ErrorPage from '../pages/ErrorPage';

const PublicRouter = ({ children, title }) => {
    return (
        <>
            <Helmet>
                <title>
                    {title ? `${title} - Insights by IDX` : "Insights by IDX"}
                </title>
            </Helmet>
            <ErrorPage>
                {children}
            </ErrorPage>
        </>
    );
};

export default PublicRouter;