import React, { useEffect, useState, useContext } from 'react';
import { isMobile } from "react-device-detect";
import { useTranslation } from 'react-i18next';

import { archieveAlerts } from "../stubs/ComponentJson/alerts";
import { TABLE_TOTAL_ROWS } from "../AppConfig";

import ArchiveAlerts from '../components/ArchiveAlerts';
import DashboardItem from '../components/DashboardItem';
import AppContext from '../components/AppContext';
import ChartRendererTable from '../components/ChartRendererTable';
import Search from "../components/Search";

const deserializeItem = (i) => ({
    ...i,
    layout: JSON.parse(i.layout) || {},
    summary: i.summary ? JSON.parse(i.summary) : {},
    vizState: i.vizState ? JSON.parse(i.vizState) : {},
    apiUrl: i.apiUrl ? i.apiUrl : undefined
});

const defaultLayout = (i) => ({
    x: i.layout.x || 0,
    y: i.layout.y || 0,
    w: i.layout.w || 4,
    h: i.layout.h || 15,
    minW: 4,
    minH: 15,
});

const defaultLayoutMobile = (i) => ({
    x: i.layout.x || 0,
    y: i.layout.y || 0,
    w: 12,
    h: i.layout.h + 2 || 12,
    minW: 4,
    minH: 8,
});


export const ArchiveAlertsPage = () => {
    const [t, i18n] = useTranslation();
    const contextData = useContext(AppContext);

    const [selectedWebsiteId, setSelectedWebsiteId] = useState({});
    const [alertStatsData, setAlertStatsData] = useState([]);
    const [selectedOtpclientid, setSelectedOtpclientid] = useState({});
    const [pageNo, setPageNo] = useState(0);
    const [offset, setOffset] = useState(0);
    const [getTotalResults, setGetTotalResults] = useState(0)
    const [searchData, setSearchData] = useState("");

    const [numberOfRecords, setNumberOfRecords] = useState(0);

    useEffect(() => {
        const websiteId = contextData.websiteId;
        const companyId = contextData.companyId
        const otpClientId = selectedOtpclientid && selectedOtpclientid["ClietConfiguration.otpclientid"];
        if (websiteId > 0 && contextData.selectedTab == "Alerts") {
            if (pageNo === 0) {
                setPageNo(1);
            }
            let dta = archieveAlerts(otpClientId, offset, websiteId, companyId, pageNo);
            setAlertStatsData(dta);
        }
        else {
            setAlertStatsData([]);
        }
    }, [contextData.websiteId, contextData.selectedTab]);

    useEffect(() => {
        setSearchData("");
        if (pageNo && selectedOtpclientid["ClietConfiguration.otpclientid"] > 0) {
            onPageNoChange(pageNo);
        }
    }, [selectedOtpclientid])

    const onPageNoChange = (number) => {
        const num = (number - 1) * TABLE_TOTAL_ROWS;
        const page = number;
        setOffset(num);
        setPageNo(page);
    }

    const dashboardItem = (item) => (
        <div key={item.id} data-grid={isMobile ? defaultLayoutMobile(item) : defaultLayout(item)}>
            <DashboardItem
                key={item.id}
                itemId={item.id}
                title={t("header.AlertArchivedInstruction")}
                customTitle={"archivedalert"}
            >
                <div className="float-right">
                    <div className="mt-3">
                        <Search searchWaterMark={t("header.searchPlaceHolder")} setsearchData={setSearchData} searchData={searchData} isAutoComplete={false} type={'client'}></Search>
                    </div>
                </div>
                {item.apiUrl &&
                    <ChartRendererTable
                        showCount={true}
                        minimumRows={10}
                        showPagination={true}
                        apiUrl={item.apiUrl}
                        summaryBesideText={t("header.AlertArchivedTotalAlertText")}
                        showNumberOfRows={10}
                        totalRecords={numberOfRecords}
                        SearchText={searchData}
                        setSearchText={setSearchData}
                        setGetTotalResults={(value) => setGetTotalResults(value)}
                    />}
            </DashboardItem>
        </div>
    );

    return alertStatsData && alertStatsData.length > 0 ? (
        <ArchiveAlerts dashboardItems={alertStatsData}
            selectedWebsiteId={selectedWebsiteId}
            selectedOtpclientid={selectedOtpclientid}
            setSelectedWebsiteId={setSelectedWebsiteId}
            setSelectedOtpclientid={setSelectedOtpclientid}
            setPageNo={setPageNo}
            setOffset={setOffset}
        >
            {alertStatsData.map(deserializeItem).map(dashboardItem)}
        </ArchiveAlerts>
    ) : (
        <h1 className='text-center'>Insights by IDX</h1>
    );
};


export default ArchiveAlertsPage;