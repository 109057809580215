import React, { useRef, useState, useEffect, useMemo, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import moment from "moment";
import Datetime from "react-datetime";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Editor } from '@tinymce/tinymce-react';
import { faEdit, faTrash, faCalendarAlt, faCheckCircle, faSlidersH, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import ReactTagInput from '@pathofdev/react-tag-input';
import { Col, Row, Card, Form, Image, InputGroup, FormCheck, Button, Modal, OverlayTrigger, Tooltip, Tab, Nav, Alert } from '@themesberg/react-bootstrap';

import { EDITOR_API_KEY, EMAIL_REGEX, isEmpty } from "../AppConfig";
import request from '../apis/request';
import { baseURL, METHODS } from '../apis/utilities/constant';
import LoaderImage from "../assets/img/LoaderGraphic.svg";
import { decryptData } from '../utils/crypto';

import AppContext from '../components/AppContext';
import CommonHeader from '../components/CommonHeader';
import ButtonComponent from "../components/Buttons";

const SendAdHocAlertPage = ({
    formData,
    setFormData,
    formError,
    validateFormData,
    isFormSubmit,
    hobbiesData,
    onSubmit,
    maxCharacters,
    setMaxCharacters
}) => {
    const [t, i18n] = useTranslation();
    const contextData = useContext(AppContext);
    const [highlightNote, setHighlightNote] = useState(false);
    const [HTMLSubscriberMaster, setHTMLSubscriberMaster] = useState("");
    const [TextSubscriberMaster, setTextSubscriberMaster] = useState("");
    const [HTMLSubscriber, setHTMLSubscriber] = useState([]);
    const [Template, setTemplate] = useState(undefined);
    const [TextSubscriber, setTextSubscriber] = useState([]);
    const [additionalRecipient, setAdditionalRecipient] = useState([]);

    const [isDeleteHtmlTempleteClick, setIsDeleteHtmlTempleteClick] = useState(false);
    const [showUploadEditModel, setShowUploadEditModel] = useState(false);
    const [blurLoader, setBlurLoader] = useState(false);
    const [isPreviewModalShown, setIsPreviewModalShown] = useState(false);
    const [errorMessage, setErrorMessage] = useState(undefined);
    const [successMessage, setSuccessMessage] = useState(undefined);
    const [isConfirmModalShown, setIsConfirmModalShown] = useState(false);
    const [isSendBtnClick, setIsSendBtnClick] = useState(false);
    const [isSendMeBtnClick, setIsSendMeBtnClick] = useState(false);
    const [editorValue, setEditorValue] = useState('<p>The quick brown fox jumps over the lazy dog</p>');
    const [editorText, setEditorText] = useState('');
    const [selectedFileContent, setSelectedFileContent] = useState(undefined);
    const [scheduleDate, setScheduleDate] = useState(undefined);
    const [languagesList, setLanguagesList] = useState([]);
    const [selectedLanguage, setSelectedLanguage] = useState([]);
    const [alertOption, setAlertOption] = useState("instant")
    const [templateType, setTempleteType] = useState("HTML_template")
    const [templateProcess, setTemplateProcess] = useState("upload_template")
    const [alertsList, setAlertsList] = useState("")
    const [selectedWesbsiteId, setSelectedWebsiteId] = useState({})
    const [selectedWebsiteIdNew, setSelectedWebsiteIdNew] = useState({});
    const [autoLoad, setAutoLoad] = useState(false)
    const [subject, setSubject] = useState(undefined);
    const [recipients, setRecipients] = useState("");
    const [textMessage, setTextMessage] = useState("");

    const fileInputRef = useRef();

    //Get All data load while page load
    useEffect(() => {
        if (contextData.websiteId > 0 && contextData.selectedTab === "Alerts") {
            getLanguage();
        }
    }, [contextData.websiteId, contextData.companyId, contextData.selectedTab])

    //Get Data based on language
    useEffect(() => {
        if (selectedLanguage.LanguageCode !== undefined) {
            getAlert();
        }
    }, [selectedLanguage])

    useEffect(() => {
        getWebsites();
    }, [contextData.userId, contextData.companyId]);

    const getAlert = () => {
        setBlurLoader(true);
        setAlertsList([]);
        request(baseURL + `/Intelligence/GetAlertAvailableRecipients?WebsiteId=${contextData.websiteId}&companyid=${contextData.companyId}&userid=${contextData.userId}&langaugeCode=${selectedLanguage.LanguageCode}&alertType=${"Email"}`,
            METHODS.GET,
            {},
            {
                Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
            })
            .then((res) => {
                setBlurLoader(false);
                // setShowLoader(false);
                let alertList = [];

                res?.result && res?.result.map((item) => {
                    item.isChecked = false;
                    alertList.push(item);
                })

                setAlertsList(alertList);

            })
            .catch((err) => {
                setBlurLoader(false);
                setAlertsList([]);
            })
    }

    const getWebsites = () => {
        request(baseURL + `/Intelligence/GetAlertsChannelWebsiteByClientUser?CompanyId=${contextData.companyId}&UserId=${contextData.userId}`,
            METHODS.GET,
            {},
            {
                Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
            })
            .then((res) => {
                let resultData = [];
                if (res.result !== null && res.result.length) {
                    resultData = res.result.map((e) => ({ ["WebsiteList.WebsiteName"]: e.WebsiteName, ["WebsiteList.WebsiteId"]: e.WebsiteId, ["WebsiteList.WebsiteUrl"]: e.WebsiteUrl, ["ClietConfiguration.otpclientid"]: e.OTPClientId, ["WebsiteList.AlertTrackingDate"]: e.AlertTrackingDate }))
                }
                rendersite({ resultSet: resultData })
            })
            .catch((err) => {

            })
    }

    //Get Language
    const getLanguage = () => {
        let selectedLng = selectedLanguage;
        setBlurLoader(true);

        setAlertsList([]);
        setSelectedLanguage({});
        setLanguagesList([]);
        setSubject("");
        setTextMessage("");
        setTemplateProcess("upload_template");
        setAlertOption("instant");
        setTempleteType("HTML_template");
        if (fileInputRef.current) {
            fileInputRef.current.value = "";
        }
        setSelectedFileContent(undefined);
        setErrorMessage("");
        setRecipients("");
        setAlertsList([]);
        request(baseURL + `/Intelligence/GetAlertClientLanguagesByWebsite?WebsiteId=${contextData.websiteId}&companyid=${contextData.companyId}&userid=${contextData.userId}`,
            METHODS.GET,
            {},
            {
                Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
            })
            .then((res) => {
                setBlurLoader(false);
                setLanguagesList(res?.result);
                if (selectedLng && Object.keys(selectedLng).length > 0)
                    setSelectedLanguage(selectedLng);
                else {
                    if (res?.result && Array.isArray(res?.result) && res?.result.length > 0)
                        setSelectedLanguage(res?.result[0])
                }

            })
            .catch((err) => {
                setBlurLoader(false);
                setLanguagesList([]);
            })
    }

    const onChangeAlertOption = (e) => {
        setAlertOption(e.target.value);
        if (e.target.value == "instant")
            setScheduleDate(undefined);
    }

    const showTemplate = (value) => {
        setTempleteType(value)
    }

    const onTemplateProcessChange = (e) => {
        if (e.target.value == "create_template")
            setSelectedFileContent(undefined);

        setTemplateProcess(e.target.value)
    }

    const validateScheduleDate = (current) => {
        let yesterday = moment().subtract(1, 'day');;
        return current.isAfter(yesterday);
    };

    const onFileUpload = (e) => {
        e.preventDefault();
        let file = e.target.files[0];
        const formData = new FormData();
        formData.append("FileDetails", file);

        setBlurLoader(true);
        request(baseURL + `/Intelligence/UploadHtmlTemplate`,
            METHODS.POST,
            formData,
            {
                Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
            })
            .then((res) => {
                setBlurLoader(false);
                if (res?.result?.IsValidHtml)
                    setSelectedFileContent(res?.result?.HtmlContentText);
                else {
                    setErrorMessage(t("header.AlertSendAdHocAlertsInvalidTemplate"));
                    setSelectedFileContent(res?.result?.HtmlContentText);
                }
            })
            .catch((err) => {
                if (fileInputRef.current) {
                    fileInputRef.current.value = "";
                }
                setErrorMessage(t("header.invalidFileContent"));
                setBlurLoader(false);
                setSelectedFileContent(undefined);
            })
    }

    const cancelFilters = () => {
        onWebsiteDropdownChange(JSON.parse(localStorage.getItem("alertWebsiteId")))
    }

    const rendersite = ({ resultSet, error, pivotConfig, request }) => {
        if (resultSet !== null && resultSet.length) {
            if (Object.keys(selectedWebsiteIdNew).length <= 0) {
                if (localStorage.getItem("alertWebsiteId")) {
                    onWebsiteDropdownChange(JSON.parse(localStorage.getItem("alertWebsiteId")))
                } else {
                    onWebsiteDropdownChange(resultSet[0])
                }
            }
            localStorage.setItem("ad-hoc-alerts", JSON.stringify(resultSet))
            setAutoLoad(true)
        }
    };

    const onWebsiteDropdownChange = (data) => {
        setSelectedWebsiteIdNew(data)
    };

    const applyFilters = () => {
        contextData.changeWebsiteId(selectedWebsiteIdNew["WebsiteList.WebsiteId"]);
        setSelectedWebsiteId(selectedWebsiteIdNew);
        localStorage.setItem("alertWebsiteId", JSON.stringify(selectedWebsiteIdNew)); // Adding ArchiveAlertWebsiteId to local stoarge in case of Page Refresh
    }

    const commonHeader = useMemo(() => <CommonHeader cancelFilters={cancelFilters} channel="alert" autoLoad={autoLoad} dataSource={[
        { name: 'Dropdown1 - website', data: JSON.parse(localStorage.getItem("ad-hoc-alerts")), selectedItem: selectedWebsiteIdNew, isVisible: true, label: t("header.filterLabelWebsite"), callback: onWebsiteDropdownChange, displayText: "WebsiteList.WebsiteName" },
    ]} title={t("header.sendAdHocAlertTitle")} showDate={false} applyFilters={applyFilters} />, [selectedWebsiteIdNew, autoLoad])

    const onlanguagechange = (e, languageCode) => {
        let selectedLanguage = languagesList.find(x => x.LanguageCode == languageCode);
        setSelectedLanguage(selectedLanguage);
    }

    const onAlertItemChange = (e, index) => {
        let newAlerList = alertsList;
        if (newAlerList[index].isChecked) {
            newAlerList[index].isChecked = false;
        }
        else {
            newAlerList[index].isChecked = true;
        }

        setTimeout(() => {
            setAlertsList(newAlerList);
        }, 10);
    }

    const onScheduleDateChange = (e, value) => {
        setScheduleDate(moment(e._d));
    }

    const onChangeEditor = (newValue, editor) => {
        setEditorValue(newValue);
        setEditorText(editor.getContent({ format: 'text' }));
    };

    const resetForm = () => {
        getLanguage();
    }

    const validateForm = (isFromSendmeFirstCopy) => {
        var regexEmail = EMAIL_REGEX;

        let isSuccess = true;
        let isRecipientSelected = false;
        let selectedAlert = [];
        alertsList && Array.isArray(alertsList) && alertsList.map((item, index) => {
            if (item.isChecked) {
                selectedAlert.push(item.AlertId);
            }
        })
        isRecipientSelected = !isFromSendmeFirstCopy ? selectedAlert.length == 0 ? !recipients ? false : true : true : true;

        let addiRecipient = recipients.split(",");


        if (!isRecipientSelected) {
            isSuccess = false;
            setErrorMessage(t("header.AlertErrorSelectRecipients"));
            return false;
        }
        if (!isFromSendmeFirstCopy) {
            if (isSuccess && addiRecipient && Array.isArray(addiRecipient) && addiRecipient.length > 0) {
                addiRecipient.map((item, index) => {
                    if (item && !regexEmail.test(item.trim())) {
                        setErrorMessage(t("header.AlertEmailErrorMessageText") + addiRecipient[index]);
                        isSuccess = false;
                        return false;
                    }
                })

            }
        }
        if (isSuccess && alertOption == "schedule") {
            if (!scheduleDate) {
                setErrorMessage(t("header.AlertSendAdHocAlertsDateTime"));
                isSuccess = false;
                return false;
            }
            if (scheduleDate && scheduleDate <= new Date()) {
                setErrorMessage(t("header.AdhocAlertTimeSchedule"));
                isSuccess = false;
                return false;
            }
        }
        if (isSuccess && !subject) {
            setErrorMessage(t("header.AlertErrorMessageSubjectRequired"));
            isSuccess = false;
            return false;
        }
        if (isSuccess && templateProcess == "upload_template") {
            if (isSuccess && (!selectedFileContent || !textMessage)) {
                setErrorMessage(t("header.adhocalertHtmlTextTemplate"));
                isSuccess = false;
                return false;
            }
        }
        else {
            if (isSuccess && (!editorText || !textMessage)) {
                setErrorMessage(t("header.adhocalertHtmlTextTemplate"));
                isSuccess = false;
                return false;
            }
        }

        //Checking validation of form on preview if new suscriber added
        if (isSuccess && HTMLSubscriber && Array.isArray(HTMLSubscriber) && HTMLSubscriber.length > 0) {
            HTMLSubscriber.map((item, key) => {
                if (item && !regexEmail.test(item.trim())) {
                    setErrorMessage(t("header.adhocalertEmailAddressIncorrect"));
                    isSuccess = false;
                    return false;
                }
            })
        }

        if (isSuccess && additionalRecipient && Array.isArray(additionalRecipient) && additionalRecipient.length > 0) {
            additionalRecipient.map((item, key) => {
                if (item && !regexEmail.test(item.trim())) {
                    setErrorMessage(t("adhocalertEmailAddressIncorrect"));
                    isSuccess = false;
                    return;
                }
            })
        }

        if (isSuccess && TextSubscriber && Array.isArray(TextSubscriber) && TextSubscriber.length > 0) {
            TextSubscriber.map((item, key) => {
                if (item && !regexEmail.test(item.trim())) {
                    setErrorMessage(t("header.adhocalertEmailAddressIncorrect"));
                    isSuccess = false;
                    return;
                }
            })
        }
        return isSuccess;
    }

    //Api Calling or any other operation while confirm btn click on popup
    const onConfirm = (e) => {
        e.preventDefault();
        setIsConfirmModalShown(false);
        if (isSendMeBtnClick) {
            setBlurLoader(true);
            let obj = {
                WebsiteId: contextData.websiteId,
                TextTemplateContent: textMessage,
                HtmlTemplateContent: templateProcess == "upload_template" ? selectedFileContent : editorValue,
                AlertType: templateType === "HTML_template" ? "Html" : "Text",
                SubjectText: subject,
                CompanyId: contextData.companyId,
                UserId: contextData.userId,
                Email: contextData.userEmail
            }
            request(baseURL + `/Intelligence/SendAlertToMe`, METHODS.POST, obj, {
                Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
            })
                .then((res) => {
                    if (res?.result) {
                        setBlurLoader(false);
                        setSuccessMessage(t("header.AlertMessageAlertSentSuccessfully"));
                        setIsSendMeBtnClick(false);
                    }
                    else {
                        setBlurLoader(false);
                        setErrorMessage(t("header.AlertMessageErrorOccured"));
                        setIsSendMeBtnClick(false);
                        return false;
                    }
                })
                .catch((err) => {

                })
        }
        else if (isSendBtnClick) {
            let additionalSub = recipients.split(",");
            let html = "";
            let text = "";
            let additional = "";
            HTMLSubscriber && Array.isArray(HTMLSubscriber) && HTMLSubscriber.length > 0 && HTMLSubscriber.map((item) => {
                html = html ? html + "," + item : item;
            })
            TextSubscriber && Array.isArray(TextSubscriber) && TextSubscriber.length > 0 && TextSubscriber.map((item) => {
                text = text ? text + "," + item : item;
            })
            additionalSub && Array.isArray(additionalSub) && additionalSub.length > 0 && additionalSub.map((item) => {
                additional = additional ? additional + "," + item : item;
            })

            let selectedAlert = [];
            setBlurLoader(true);
            let RNSAlertId = 0;
            alertsList && Array.isArray(alertsList) && alertsList.map((item, index) => {
                if (item.isChecked && item.AlertId != "1") {
                    selectedAlert.push(item.AlertId);
                }
                if (item.isChecked && item.AlertId == "1")
                    RNSAlertId = item.AlertId
            })
            if (alertOption == "instant") {
                let obj = {
                    AlertId: selectedAlert,
                    AlertType: "Email",
                    EditorContentText: templateProcess == "upload_template" ? selectedFileContent : editorValue,
                    EmailTemplateText: textMessage,
                    HtmlSubcribers: html,
                    IsSendFromPreview: isPreviewModalShown ? true : false,
                    LanguageCode: selectedLanguage.LanguageCode,
                    RNSAlertId: RNSAlertId,
                    SubjectText: subject,
                    TextSubscribers: text,
                    ToAdditionalSubscribers: additional,
                    WebsiteId: contextData.websiteId,
                    CompanyId: contextData.companyId,
                    UserId: contextData.userId
                }

                request(baseURL + `/Intelligence/AdhocAlertSend`, METHODS.POST, obj, {
                    Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
                })
                    .then((res) => {
                        if (res?.result > 0) {
                            request(baseURL + `/Intelligence/AlertReadyForSending?WebsiteId=${contextData.websiteId}&ResendMessageId=${res?.result}&companyid=${contextData.companyId}&userid=${contextData.userId}`, METHODS.POST, {}, {
                                Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
                            })
                                .then((res) => {
                                    setBlurLoader(false);
                                    if (res?.result) {
                                        setSuccessMessage(t("header.AlertMessageAlertSentSuccessfully"));
                                    }
                                    else {
                                        setErrorMessage(t("header.AlertMessageErrorOccured"));
                                    }
                                    resetForm();
                                    setBlurLoader(false);
                                    setIsSendBtnClick(false);
                                    setHighlightNote(false);
                                    setIsPreviewModalShown(false);
                                })
                                .catch((err) => {
                                    resetForm();
                                    setBlurLoader(false);
                                    setIsSendBtnClick(false);
                                    setHighlightNote(false);
                                    setIsPreviewModalShown(false);
                                })
                        }
                        else {
                            if (res.includes("No subscribers available.Alert can not be saved or sent.")) {
                                setErrorMessage(t("header.adhocalertSubcriverCannotBeSaved"));
                            }
                            else {
                                setErrorMessage(t("header.AlertMessageErrorOccured"));
                            }
                            setBlurLoader(false);
                            setIsSendBtnClick(false);
                            setHighlightNote(false);
                            setIsPreviewModalShown(false);
                        }
                    })
                    .catch((err) => {
                        resetForm();
                        setBlurLoader(false);
                        setIsSendBtnClick(false);
                        setHighlightNote(false);
                        setIsPreviewModalShown(false);
                    })
            }
            else {
                let obj = {
                    AlertId: selectedAlert,
                    AlertType: "Email",
                    EditorContentText: templateProcess == "upload_template" ? selectedFileContent : editorValue,
                    EmailTemplateText: textMessage,
                    HoursValue: moment(scheduleDate).format("HH"),
                    MinuteValue: moment(scheduleDate).format("mm"),
                    ScheduleDateTime: moment(scheduleDate).format("YYYY-MM-DD"),
                    HtmlSubcribers: html,
                    IsSendFromPreview: isPreviewModalShown ? true : false,
                    LanguageCode: selectedLanguage.LanguageCode,
                    RNSAlertId: RNSAlertId,
                    SubjectText: subject,
                    TextSubscribers: text,
                    ToAdditionalSubscribers: additional,
                    WebsiteId: contextData.websiteId,
                    CompanyId: contextData.companyId,
                    UserId: contextData.userId
                }

                request(baseURL + `/Intelligence/ScheduleAlertSend`, METHODS.POST, obj, {
                    Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
                })
                    .then((res) => {
                        if (res?.result) {
                            setSuccessMessage("Alert scheduled successfully.");
                        }
                        else {
                            if (res.includes("No subscribers available.Alert can not be saved or sent.")) {
                                setErrorMessage(t("header.adhocalertSubcriverCannotBeSaved"));
                            }
                            else {
                                setErrorMessage(t("header.AlertMessageErrorOccured"));
                            }
                        }
                        resetForm();
                        setBlurLoader(false);
                        setIsSendBtnClick(false);
                        setHighlightNote(false);
                        setIsPreviewModalShown(false);
                    })
                    .catch((err) => {
                        resetForm();
                        setBlurLoader(false);
                        setIsSendBtnClick(false);
                        setHighlightNote(false);
                        setIsPreviewModalShown(false);
                    })
            }
        }
        else if (isDeleteHtmlTempleteClick) {
            if (fileInputRef.current) {
                fileInputRef.current.value = "";
            }
            setSelectedFileContent(undefined);
            setIsDeleteHtmlTempleteClick(false);
            setIsConfirmModalShown(false);
        }
        else if (showUploadEditModel) {
            setShowUploadEditModel(false);
        }
    }

    const handleClose = () => {
        setIsConfirmModalShown(false);
        setIsSendMeBtnClick(false);
        setIsSendBtnClick(false);
        setIsDeleteHtmlTempleteClick(false);
        setShowUploadEditModel(false);
    }

    const onHandlePreview = (e) => {
        e.preventDefault();
        if (validateForm(false)) {
            setErrorMessage("");
            setBlurLoader(true);
            let selectedAlert = [];
            let RNSAlertId = 0;
            alertsList && Array.isArray(alertsList) && alertsList.map((item, index) => {
                if (item.isChecked && item.AlertId != "1") {
                    selectedAlert.push(item.AlertId);
                }
                if (item.isChecked && item.AlertId == "1")
                    RNSAlertId = item.AlertId

            })
            let obj = {
                RNSAlertId: RNSAlertId,
                WebsiteId: contextData.websiteId,
                MessageType: alertOption == "instant" ? "Adhoc" : "Scheduled",
                AlertType: "Email",
                AlertId: selectedAlert,
                ScheduleDateTime: moment(scheduleDate).format("MMM DD,YYYY HH:mm"),
                HoursValue: moment().format("HH"),
                MinuteValue: moment().format("mm"),
                EmailTemplateText: textMessage,
                EditorContentText: templateProcess == "upload_template" ? selectedFileContent : editorValue,
                LanguageCode: selectedLanguage.LanguageCode,
                AdditionalSubscribers: recipients,
                CompanyId: contextData.companyId,
                UserId: contextData.userId
            }

            request(baseURL + `/Intelligence/GetPreviewAdhocAlertTemplate`, METHODS.POST, obj, {
                Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
            })
                .then((res) => {
                    res?.result?.HTMLSubscriber ? setHTMLSubscriberMaster(res?.result?.HTMLSubscriber) : setHTMLSubscriberMaster("");
                    res?.result?.TextSubscriber ? setTextSubscriberMaster(res?.result?.TextSubscriber) : setTextSubscriberMaster("");

                    res?.result?.HTMLSubscriber ? setHTMLSubscriber(res?.result?.HTMLSubscriber.split(",")) : setHTMLSubscriber([]);
                    setTemplate(res?.result?.Template);
                    res?.result?.TextSubscriber ? setTextSubscriber(res?.result?.TextSubscriber.split(",")) : setTextSubscriber([]);
                    recipients ? setAdditionalRecipient(recipients.split(",")) : setAdditionalRecipient([]);
                    setIsPreviewModalShown(true);
                    setBlurLoader(false);
                })
                .catch((err) => {
                    setHighlightNote(false);
                    setIsPreviewModalShown(false);
                })
        }
    }

    const onClosePreviewModel = () => {
        setHighlightNote(false);
        setIsPreviewModalShown(false);
        setHTMLSubscriber([]);
        setTextSubscriber([]);
        setAdditionalRecipient([]);
        setHTMLSubscriberMaster([]);
        setTextSubscriberMaster([]);
        setAdditionalRecipient([]);
        setErrorMessage("");
    }

    const onHandleSendMeFirstCopy = (e) => {
        e.preventDefault();

        if (validateForm(true)) {
            setIsConfirmModalShown(true);
            setIsSendMeBtnClick(true);
        }
    }

    const onHandleSend = (e) => {
        e.preventDefault();

        if (validateForm(false)) {
            setIsConfirmModalShown(true);
            setIsSendBtnClick(true);
        }
    }

    const fileReset = (e) => {
        e.preventDefault();
        setIsConfirmModalShown(true);
        setIsDeleteHtmlTempleteClick(true);
    }

    const onClose = () => {
        setErrorMessage(undefined);
        setSuccessMessage(undefined);
    }

    const onEditUpload = (e) => {
        e.preventDefault();
        setShowUploadEditModel(true);
    }

    const onChangeEditUpload = (e) => {
        setSelectedFileContent(e.target.value);
    }

    return (
        <>
            {blurLoader && <div id='blurdiv' className="blurbackground">
                <div className={`preloader loader-center-align`}>
                    <Image className="loader-element animate__animated animate__jackInTheBox" src={LoaderImage} height={40} data-seconds={30} />
                </div>
            </div>}
            {/* Preview Model */}
            <Modal as={Modal.Dialog} size="xl" centered show={isPreviewModalShown} onHide={handleClose}>
                <Modal.Header>
                    <Modal.Title className="h5"><h5>{t("header.AlertAdHocAlertsPreview")}</h5></Modal.Title>
                    <Button variant="close" aria-label="Close" onClick={onClosePreviewModel} />
                </Modal.Header>
                <Modal.Body>
                    <div className={highlightNote ? 'mandatory' : ''}>{t("header.adhocalertEmailAddressRequeire")}</div>
                    {TextSubscriber && TextSubscriber.length > 0 && <Row className='mt-2'>
                        <Form.Label>{t("header.AlertAdHocAlertsPreviewTextSubscribers")}</Form.Label>
                        <Col md={12}>
                            <ReactTagInput
                                tags={TextSubscriber}
                                remove={
                                    () => {
                                    }
                                }
                                removeOnBackspace={true}
                                editable={faSlidersH}
                                placeholder={t("header.manualAlertsTypeAndPressPlaceholder")}
                                onChange={(uTags) => {
                                    if (HTMLSubscriber.length == 0 && additionalRecipient.length == 0) {
                                        if (TextSubscriber.length > 1) {
                                            setHighlightNote(false);
                                            setTextSubscriber(uTags)
                                        }
                                        else {
                                            if (uTags.length > 1) {
                                                setHighlightNote(false);
                                                setTextSubscriber(uTags)
                                            }
                                            else {
                                                setHighlightNote(true);
                                            }
                                        }
                                    }
                                    else {
                                        setHighlightNote(false);
                                        setTextSubscriber(uTags)
                                    }
                                }}
                            />
                        </Col>
                    </Row>}
                    {HTMLSubscriber && HTMLSubscriber.length > 0 && <Row className='mt-2'>
                        <Form.Label>{t("header.AlertAdHocAlertsPreviewHTMLSubscribers")}</Form.Label>
                        <Col md={12}>
                            <ReactTagInput
                                tags={HTMLSubscriber}
                                placeholder={t("header.manualAlertsTypeAndPressPlaceholder")}
                                removeOnBackspace={true}
                                onChange={(uTags) => {
                                    if (TextSubscriber.length == 0 && additionalRecipient.length == 0) {
                                        if (HTMLSubscriber.length > 1) {
                                            setHighlightNote(false);
                                            setHTMLSubscriber(uTags)
                                        }
                                        else {
                                            if (uTags.length > 1) {
                                                setHighlightNote(false);
                                                setHTMLSubscriber(uTags)
                                            }
                                            else {
                                                setHighlightNote(true);
                                            }
                                        }
                                    }
                                    else {
                                        setHighlightNote(false);
                                        setHTMLSubscriber(uTags)
                                    }
                                }}
                            />
                        </Col>
                    </Row>}
                    <Row className='mt-2'>
                        <Form.Label>{t("header.AlertAdhocAlertsPreviewAdditionalSubscribers")}</Form.Label>
                        <Col md={12}>
                            <ReactTagInput
                                tags={additionalRecipient}
                                placeholder={t("header.manualAlertsTypeAndPressPlaceholder")}
                                removeOnBackspace={true}
                                onChange={(uTags) => {
                                    if (TextSubscriber.length == 0 && HTMLSubscriber.length == 0) {
                                        if (additionalRecipient.length > 1) {
                                            setHighlightNote(false);
                                            setAdditionalRecipient(uTags)
                                        }
                                        else {
                                            if (uTags.length > 1) {
                                                setHighlightNote(false);
                                                setAdditionalRecipient(uTags)
                                            }
                                            else {
                                                setHighlightNote(true);
                                            }
                                        }
                                    }
                                    else {
                                        setHighlightNote(false);
                                        setAdditionalRecipient(uTags)
                                    }
                                }}
                            />
                        </Col>
                    </Row>

                    <Row className='mt-2'>
                        <div dangerouslySetInnerHTML={{ __html: Template }}></div>
                    </Row>
                    <Row>
                        <Alert
                            variant="danger"
                            show={errorMessage ? true : false}
                            onClose={() => onClose("danger")}
                        >
                            <div className="d-flex justify-content-between">
                                <div>
                                    <FontAwesomeIcon icon={faTimesCircle} className="me-2" />
                                    {errorMessage}
                                </div>
                                <Button variant="close" size="xs" onClick={() => onClose("danger")}
                                />
                            </div>
                        </Alert>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <ButtonComponent
                        isIcon={false}
                        isPrimary={true}
                        btnText={alertOption == "schedule" ? t("header.AlertSendAdHocAlertsScheduleAlert") : t("header.AlertSendAdHocAlertsSend")}
                        onClick={(e) => { onHandleSend(e) }}
                        btn_class="me-3" />
                </Modal.Footer>
            </Modal>

            {/* Upload edit Model */}
            <Modal as={Modal.Dialog} size="xl" centered show={showUploadEditModel} onHide={handleClose}>
                <Modal.Header>
                    <Modal.Title className="h5"><h5>{t("header.AlertAdhocUploadHtmlEditTitle")}</h5></Modal.Title>
                    <Button variant="close" aria-label="Close" onClick={handleClose} />
                </Modal.Header>
                <Modal.Body>
                    <textarea onChange={(e) => { onChangeEditUpload(e) }} cols={"20"} rows={"20"} value={selectedFileContent} className='form-control'></textarea>
                </Modal.Body>
                <Modal.Footer>
                    <ButtonComponent
                        isIcon={false}
                        isPrimary={true}
                        btnText={t("header.AlertAdhocUploadHtmlEditSave")}
                        onClick={onConfirm}
                        btn_class="me-3" />
                    <ButtonComponent
                        isIcon={false}
                        isPrimary={false}
                        btnText={t("header.AlertAdhocUploadHtmlEditCancel")}
                        onClick={handleClose}
                        btn_class="mb-0" />
                </Modal.Footer>
            </Modal>

            {/* Confirmation Model */}
            <Modal as={Modal.Dialog} size="xs" centered show={isConfirmModalShown} onHide={handleClose}>
                <Modal.Body>
                    {
                        isSendBtnClick &&
                        <>
                            {t("header.AlertSendAdHocAlertsMailSendConfirmation")}
                            {!isPreviewModalShown && <>
                                <span>
                                    <div dangerouslySetInnerHTML={{
                                        __html: t("header.adhocalertPreviewSendingToSubscriber", {
                                            interpolation: { escapeValue: false },
                                        }),
                                    }}
                                    >
                                    </div>
                                </span>
                            </>
                            }
                        </>
                    }
                    {isSendMeBtnClick &&
                        <>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: t("header.adhocalertAlwaysPreviewMessaage", {
                                        interpolation: { escapeValue: false },
                                    }),
                                }}
                            ></div>
                        </>
                    }
                    {
                        isDeleteHtmlTempleteClick &&
                        <>
                            {t("header.AlertSendAdHocAlertsTemplateDeleteConfirmation")}
                        </>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <ButtonComponent
                        isIcon={false}
                        isPrimary={true}
                        btnText={t("header.AlertConfirmButtonOK")}
                        onClick={onConfirm}
                        btn_class="me-3" />
                    <ButtonComponent
                        isIcon={false}
                        isPrimary={false}
                        btnText={t("header.AlertAdhocUploadHtmlEditCancel")}
                        onClick={handleClose}
                        btn_class="mb-0" />
                </Modal.Footer>
            </Modal>
            {/* fields starts here */}
            <Row>
                <Col>
                    {commonHeader}
                </Col>
            </Row>
            <Row className="mb-3">
                <Col lg={12}>
                    <Card border="light" className="bg-white shadow-sm mb-4 ms-3" >
                        <Card.Header>
                            <div>
                                <p className='mb-3 title'>{t("header.AlertSendAdHocAlertsInstruction")}</p>
                            </div>
                        </Card.Header>
                        <Card.Body>
                            <Form className="form-container pt-2 ps-3 pe-3">
                                <Row className="mt-2 mb-2">
                                    <Col md={3}>
                                        <Form.Label className="fw-regular">{t("header.AlertSendAdHocAlertsSelectLanguage")}<span className="mandatory">*</span></Form.Label>
                                    </Col>
                                </Row>
                                <Row className="mt-2 mb-2">
                                    <>
                                        {
                                            (languagesList && languagesList.length > 0) &&
                                            languagesList.map((element, index) =>
                                                <Col md={4}>
                                                    <Form.Group className="mb-3">
                                                        <FormCheck
                                                            onChange={(e) => {
                                                                onlanguagechange(e, element.LanguageCode);
                                                            }}
                                                            checked={selectedLanguage.LanguageCode == element.LanguageCode}
                                                            type="radio"
                                                            value={element.LanguageCode}
                                                            label={element.LanguageName}
                                                            name="languages"
                                                            id={element.LanguageCode}
                                                        />
                                                    </Form.Group>
                                                </Col>)
                                        }
                                    </>
                                </Row>
                                <Row className="mt-2 mb-2">
                                    <Col md={6}>
                                        <Form.Label>{t("header.AlertSendAdHocAlertsRecipients")}</Form.Label><OverlayTrigger
                                            placement="right"
                                            trigger={['hover', 'focus']}
                                            overlay={
                                                <Tooltip>
                                                    <div
                                                        dangerouslySetInnerHTML={{
                                                            __html: t("header.AlertSendAdHocAlertsRecipientsHelpTT", {
                                                                interpolation: { escapeValue: false },
                                                            }),
                                                        }}
                                                    ></div>
                                                </Tooltip>
                                            }>
                                            <Button variant="white" className="btn-help border-0 p-2">
                                                <i className="far fa-question-circle lg"></i>
                                            </Button>
                                        </OverlayTrigger>
                                        <div className='alertList-container mb-3'>
                                            {
                                                (alertsList && alertsList.length > 0) &&
                                                alertsList.map((element, index) =>
                                                    <FormCheck
                                                        type="checkbox"
                                                        className="inbox-check me-2"
                                                    >
                                                        <FormCheck.Input onChange={(e) => { onAlertItemChange(e, index) }} className="me-2" value={element.AlertId} />
                                                        <FormCheck.Label>{element.AlertName}</FormCheck.Label>
                                                    </FormCheck>)
                                            }
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Label>{t("header.AlertSendAdHocAlertsAdditionalRecipients")}</Form.Label>
                                        <OverlayTrigger
                                            placement="right"
                                            trigger={['hover', 'focus']}
                                            overlay={
                                                <Tooltip>
                                                    <div
                                                        dangerouslySetInnerHTML={{
                                                            __html: t("header.AlertSendAdHocAlertsAdditionalRecipientsHelpTT", {
                                                                interpolation: { escapeValue: false },
                                                            }),
                                                        }}
                                                    ></div>
                                                </Tooltip>
                                            }>
                                            <Button variant="white" className="btn-help border-0 p-2">
                                                <i className="far fa-question-circle lg"></i>
                                            </Button>
                                        </OverlayTrigger>
                                        <Form.Control className="mb-1 recipient-textarea" as="textarea" rows="3" maxLength="" name="recipients" value={recipients} placeholder={t("header.AlertSendAdHocAlertsAdditionalReciPlaceHolder")} onChange={(e) => setRecipients(e.target.value)} />
                                    </Col>
                                </Row>
                                <Row className="mt-1 mb-2">
                                    <Col md={3}>
                                        <Form.Label>{t("header.adhocalertOptions")}<span className="mandatory">*</span></Form.Label>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group className="mb-3">
                                            <FormCheck
                                                checked={alertOption == "instant" ? true : false}
                                                type="radio"
                                                value={'instant'}
                                                label={t("header.AlertSendAdHocAlertsInstant")}
                                                name="alertOption"
                                                id="instant"
                                                onChange={(e) => onChangeAlertOption(e)}
                                            />
                                            <FormCheck
                                                checked={alertOption == "schedule" ? true : false}
                                                type="radio"
                                                value={'schedule'}
                                                label={t("header.AlertSendAdHocAlertsSchedule")}
                                                name="alertOption"
                                                id="Schedule"
                                                onChange={(e) => onChangeAlertOption(e)}
                                            />
                                            {(isFormSubmit && !isEmpty(formError.gender)) &&
                                                <span className="alert-msg-danger"> {formError.gender} </span>
                                            }
                                        </Form.Group>
                                    </Col>
                                </Row>
                                {
                                    (alertOption == "schedule") &&
                                    <>
                                        <Row className="mt-2 mb-2">
                                            <Col md={3}>
                                                <Form.Label>{t("header.AlertSendAdHocAlertsDateTime")}<span className="mandatory">*</span></Form.Label>
                                            </Col>
                                            <Col md={4}>
                                                <Datetime
                                                    isValidDate={validateScheduleDate}
                                                    timeFormat={true}
                                                    closeOnSelect={false}
                                                    onChange={(e) => {
                                                        onScheduleDateChange(e);
                                                    }}
                                                    className="mt-2"
                                                    renderInput={(props, openCalendar) => (
                                                        <InputGroup>
                                                            <Form.Control
                                                                required
                                                                type="text"
                                                                value={scheduleDate ? moment(scheduleDate).format("MMM DD,YYYY HH:mm") : undefined}
                                                                onFocus={openCalendar}
                                                                onChange={() => { }} />
                                                            <InputGroup.Text onClick={openCalendar}><FontAwesomeIcon icon={faCalendarAlt} /></InputGroup.Text>
                                                        </InputGroup>
                                                    )} />

                                            </Col>
                                        </Row>
                                        <Row className="mt-2 mb-2">
                                            <Col md={3}>
                                            </Col>
                                            <Col md={9}>
                                                <span>{t("header.adhocalertHocAlertsNoteMessage")}<a target={"_blank"} href='http://www.timezoneconverter.com/cgi-bin/tzc.tzc'>{t("header.AlertSendAdHocAlertsHere")}</a></span>
                                            </Col>
                                        </Row>
                                    </>
                                }
                                <Row className="mt-3 mb-2">
                                    <Col md={3}>
                                        {t("header.AlertSendAdHocAlertsMessageSubject")}<span className="mandatory">*</span>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Control className="mb-1" as="textarea" rows="2" maxLength="" name="subject" value={subject} onChange={(e) => setSubject(e.target.value)} />
                                    </Col>
                                </Row>
                                <Row className="mt-3 mb-2">
                                    <Col md={3}>
                                    </Col>
                                    <Col md={6}>
                                        <Tab.Container activeKey={templateType == "HTML_template" ? "HTML_template" : "Text_template"} defaultActiveKey="home" >
                                            <Nav fill variant="pills" className="flex-column flex-sm-row tab border-bottom-0 ms-1 mt-3 mb-2">
                                                <Nav.Item className="pe-0">
                                                    <Nav.Link onClick={() => showTemplate('HTML_template')} eventKey="HTML_template" className={templateType == "HTML_template" ? "mb-sm-3 mb-md-0 active" : "mb-sm-3 mb-md-0"}>
                                                        {t("header.AlertSendAdHocAlertsHtmlTemplate")}
                                                    </Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link aria-selected={templateType != "HTML_template" ? true : false} onClick={() => showTemplate('Text_template')} eventKey="Text_template" className={templateType != "HTML_template" ? "mb-sm-3 mb-md-0 active" : "mb-sm-3 mb-md-0"}>
                                                        {t("header.AlertSendAdHocAlertsTextTemplate")}
                                                    </Nav.Link>
                                                </Nav.Item>
                                            </Nav>
                                        </Tab.Container>
                                    </Col>
                                </Row>
                                <Row hidden={templateType === "HTML_template" ? false : true}>
                                    <Col>
                                        <Row className="mt-4 mb-2">
                                            <Col md={3}>
                                                {t("header.AlertSendAdHocAlertsHtmlTemplate")}<span className="mandatory">*</span>
                                            </Col>
                                            <Col md={6}>
                                                <Form.Group className="mb-3">
                                                    <FormCheck
                                                        checked={templateProcess == "upload_template" ? true : false}
                                                        type="radio"
                                                        value={'upload_template'}
                                                        label={t("header.AlertSendAdHocAlertsUploadHTMLTemplateLabel")}
                                                        name="templateType"
                                                        id="templateType"
                                                        onChange={(e) => onTemplateProcessChange(e)}
                                                    />
                                                    <FormCheck
                                                        checked={templateProcess == "create_template" ? true : false}
                                                        type="radio"
                                                        value={'create_template'}
                                                        label={t("header.AlertSendAdHocAlertsCreateHTMLTemplateRadio")}
                                                        name="templateType1"
                                                        id="templateType1"
                                                        onChange={(e) => onTemplateProcessChange(e)}
                                                    />
                                                    {(isFormSubmit && !isEmpty(formError.gender)) &&
                                                        <span className="alert-msg-danger"> {formError.gender} </span>
                                                    }
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        {
                                            (templateProcess === "upload_template") &&
                                            <Row className="mt-4 mb-2 ">
                                                <Col md={3}>
                                                </Col>
                                                <Col md={6}>
                                                    <Form.Control ref={fileInputRef} accept={".html,.htm"} type="file" onChange={(e) => onFileUpload(e)} />
                                                </Col>
                                                {selectedFileContent && <Col md={3} className='pt-2'>
                                                    <Card.Link
                                                        onClick={(e) => { onEditUpload(e) }}
                                                        className={"align-items-center btn-link"}
                                                    >
                                                        <FontAwesomeIcon icon={faEdit} />
                                                    </Card.Link>
                                                    <Card.Link
                                                        onClick={(e) => { fileReset(e) }}
                                                        className={"align-items-center btn-link ms-3"}
                                                    >
                                                        <FontAwesomeIcon icon={faTrash} />
                                                    </Card.Link>
                                                </Col>}
                                            </Row>
                                        }
                                        {
                                            (templateProcess === "create_template") &&
                                            <Editor
                                                onEditorChange={(newValue, editor) => { onChangeEditor(newValue, editor) }}
                                                onInit={(evt, editor) => {
                                                    setEditorText(editor.getContent({ format: 'text' }));
                                                }}
                                                apiKey={EDITOR_API_KEY}
                                                plugins='lists code'
                                                init={{ branding: false, keep_styles: true }}
                                            />
                                        }
                                    </Col>
                                </Row>
                                <Row hidden={templateType === "Text_template" ? false : true} className="mt-2 mb-2">
                                    <Col md={12}>
                                        <Form.Control className="mb-1" as="textarea" rows="12" maxLength="" onChange={(e) => setTextMessage(e.target.value)} name="textMessage" value={textMessage} />
                                    </Col>
                                </Row>
                                <Row className="mt-3 mb-2">
                                    <Col md={3}></Col>
                                    <Col md={6}>
                                        <Alert
                                            variant="danger"
                                            show={errorMessage ? true : false}
                                            onClose={() => onClose("danger")}
                                        >
                                            <div className="d-flex justify-content-between">
                                                <div>
                                                    <FontAwesomeIcon icon={faTimesCircle} className="me-2" />
                                                    {errorMessage}
                                                </div>
                                                <Button variant="close" size="xs" onClick={() => onClose("danger")}
                                                />
                                            </div>
                                        </Alert>
                                        <Alert
                                            variant="success"
                                            show={successMessage ? true : false}
                                            onClose={() => onClose("danger")}
                                        >
                                            <div className="d-flex justify-content-between">
                                                <div>
                                                    <FontAwesomeIcon icon={faCheckCircle} className="me-2" />
                                                    {successMessage}
                                                </div>
                                                <Button variant="close" size="xs" onClick={() => onClose("danger")}
                                                />
                                            </div>
                                        </Alert>
                                    </Col>
                                </Row>
                                <Row className="mt-3 mb-2">
                                    <Col md={3}></Col>
                                    <Col md={9}>
                                        <ButtonComponent
                                            isIcon={false}
                                            isPrimary={false}
                                            btnText={t("header.AlertSendAdHocAlertsSendMeCopy")}
                                            btn_size="md mt-1 me-3"
                                            onClick={(e) => onHandleSendMeFirstCopy(e)}
                                        />
                                        <ButtonComponent
                                            isIcon={false}
                                            isPrimary={false}
                                            btnText={t("header.AlertSendAdHocAlertsPreview")}
                                            btn_size="md mt-1 me-3"
                                            onClick={(e) => onHandlePreview(e)}
                                        />
                                        <ButtonComponent
                                            isIcon={false}
                                            isPrimary={true}
                                            btnText={alertOption == "schedule" ? t("header.AlertSendAdHocAlertsScheduleAlert") : t("header.AlertSendAdHocAlertsSend")}
                                            btn_size="md mt-1"
                                            onClick={(e) => onHandleSend(e)}
                                        />
                                    </Col>
                                </Row>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            {/* fields ends here */}
        </>
    );

};

SendAdHocAlertPage.propTypes = {
    formData: PropTypes.object,
    formError: PropTypes.object,
    setFormData: PropTypes.func,
    isFormSubmit: PropTypes.bool,
    hobbiesData: PropTypes.array,
    validateFormData: PropTypes.func,
    onSubmit: PropTypes.func,
    maxCharacters: PropTypes.number,
    setMaxCharacters: PropTypes.func
};

SendAdHocAlertPage.defaultProps = {
    formData: {},
    formError: {},
    setFormData: null,
    isFormSubmit: false,
    hobbiesData: [],
    validateFormData: null,
    onSubmit: null,
    maxCharacters: 0,
    setMaxCharacters: null
}

export default SendAdHocAlertPage;
