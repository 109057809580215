
import React, { useState, useEffect, useContext, useMemo } from 'react';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { Row, Col, Button, Image } from '@themesberg/react-bootstrap';

import { object_equals } from "../AppConfig";
import request from '../apis/request';
import { baseURL, METHODS } from '../apis/utilities/constant';
import LoaderImage from "../assets/img/LoaderGraphic.svg";
import { decryptData } from '../utils/crypto';

import CommonHeader from './CommonHeader';
import CookiePerformanceChart from './CookiePerformanceChart';
import AppContext from './AppContext';

const CookiePerformance = ({ selectedProfileId, selectedWebsiteId, history, handleBackButtonClick }) => {

  const [t, i18n] = useTranslation();
  const contextData = useContext(AppContext);

  const [showCustomDatePicker, setShowCustomDatePicker] = useState(false);
  const [selectedProfileIdNew, setSelectedProfileIdNew] = useState("")
  const [selectedWebsiteIdNew, setSelectedWebsiteIdNew] = useState("");
  const [autoLoad, setAutoLoad] = useState("");
  const [profilesList, setProfilesList] = useState([]);
  const [blurLoader, setBlurLoader] = useState(false);

  const [websiteList, setWebsiteList] = useState([]);

  useEffect(() => {
    if (contextData.companyId > 0) {
      setAutoLoad(false);
      if (localStorage.getItem("websiteData") && JSON.parse(localStorage.getItem("websiteData")).length > 0
        && localStorage.getItem("AllprofileData") && JSON.parse(localStorage.getItem("AllprofileData")).length > 0) {
        let websiteList = JSON.parse(localStorage.getItem("websiteData"));
        let selectedWebsite = JSON.parse(localStorage.getItem("WebsiteListWebsiteId"));
        renderWebsite(websiteList, selectedWebsite)
      }
      else if (!localStorage.getItem("websiteData") || !localStorage.getItem("AllprofileData")) {
        //These conditions are bifurcated because there are chances that a user can navigate from a page where only website 
        //is store in localstorage but not profile, hence profile needs to be stored & then the logic of localstorage for website & profile works smoothly.
        if (!localStorage.getItem("websiteData")) {
          getWebsites();
        }
        else if (!localStorage.getItem("AllprofileData")) {
          getProfiles(JSON.parse(localStorage.getItem("websiteData")));
        }
      }
    }
  }, [contextData.companyId])

  const getWebsites = () => {
    setBlurLoader(true);
    request(baseURL + `/CommonV2/GetWebsiteChannelWebsiteByClientUser?CompanyId=${contextData.companyId}&UserId=${contextData.userId}`,
      METHODS.GET,
      {},
      {
        Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
      })
      .then((res) => {
        let resultData = [];
        if (res.result && res.result.length > 0) {
          resultData = res.result.map((e) => ({ cmsplatformid: res.platformid, ["WebsiteList.WebsiteName"]: e.WebsiteName, ["WebsiteList.WebsiteId"]: e.WebsiteId, ["WebsiteList.WebsiteUrl"]: e.WebsiteUrl }))
        }
        if (resultData.length > 0) {
          localStorage.setItem("websiteData", JSON.stringify(resultData))
          getProfiles(resultData)
        }
        else {
          localStorage.setItem("websiteData", JSON.stringify([]))
          localStorage.setItem("AllprofileData", JSON.stringify([]))
        }
      })
      .catch((err) => {
        setBlurLoader(false);
      })
  }

  const getProfiles = (resultData) => {
    request(baseURL + `/WebsiteAnalytics/GetWebsites?CompanyId=${contextData.companyId}&UserId=${contextData.userId}`,
      METHODS.GET,
      {},
      {
        Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
      })
      .then((res) => {
        if (res.result && res.result.length > 0) {
          let result = removeDuplicateObjects(res.result, 'IntelConfigId')
          localStorage.setItem("AllprofileData", JSON.stringify(result))
        }
        else {
          setProfilesList([]);
          setSelectedProfileIdNew({})
          localStorage.setItem("profileData", JSON.stringify([]))
        }
        if (!localStorage.getItem("WebsiteListWebsiteId")) {
          renderWebsite(resultData, resultData[0])
        }
        else {
          renderWebsite(resultData, JSON.parse(localStorage.getItem("WebsiteListWebsiteId")))
        }
        setBlurLoader(false);
        if (res.result.length == 0) {
          setAutoLoad(true);
        }
      })
      .catch((err) => {
        setBlurLoader(false);
      })
  }

  function removeDuplicateObjects(array, key) {
    const seen = {};
    return array.filter(item => {
      const itemKey = key ? item[key] : item;
      return seen.hasOwnProperty(itemKey) ? false : (seen[itemKey] = true);
    });
  }

  const onWebsiteDropdownChange = (data) => {
    if (!object_equals(selectedWebsiteIdNew, data)) {
      setSelectedProfileIdNew({});
    }
    setSelectedWebsiteIdNew(data);
    renderProfileList(data, 1)
  };

  const onProfileDropdownChange = (data) => {
    const intellConfigId = data["WebsiteProfiles.IntelConfigId"];
    contextData.changeIntelConfigId(intellConfigId);
    setSelectedProfileIdNew(data)
  };

  const applyFilters = () => {
    const websiteId = selectedWebsiteIdNew["WebsiteList.WebsiteId"];
    contextData.changeWebsiteId(websiteId);
    contextData.changeSelectedWebsite(selectedWebsiteIdNew);
    contextData.changeSelectedProfile(selectedProfileIdNew);
    if (profilesList.length > 0) {
      localStorage.setItem("profileData", JSON.stringify(profilesList))
    }
    else {
      localStorage.setItem("profileData", JSON.stringify([]))
    }
    localStorage.setItem("WebsiteListWebsiteId", JSON.stringify(selectedWebsiteIdNew));// Adding WebsiteList.WebsiteId to local stoarge in case of Page Refresh
    localStorage.setItem("WebsiteProfilesIntelConfigId", JSON.stringify(selectedProfileIdNew));// Adding WebsiteProfiles.IntelConfigId to local stoarge in case of Page Refresh
  }

  const cancelFilters = () => {
    onWebsiteDropdownChange(JSON.parse(localStorage.getItem("WebsiteListWebsiteId")))
    onProfileDropdownChange(JSON.parse(localStorage.getItem("WebsiteProfilesIntelConfigId")))
  }

  const onClickOutsideListener = () => {
    setShowCustomDatePicker(false);
    document.removeEventListener("click", onClickOutsideListener)
  }

  const renderWebsite = (resultSet, selectedWebsiteValue) => {
    setWebsiteList(resultSet)
    setSelectedWebsiteIdNew(selectedWebsiteValue);
    renderProfileList(selectedWebsiteValue, 0);
  };

  const renderProfileList = (selectedWebsiteValue, isTempUpdate) => {
    if (localStorage.getItem("AllprofileData") && JSON.parse(localStorage.getItem("AllprofileData")).length > 0) {
      let profileList = JSON.parse(localStorage.getItem("AllprofileData"));
      let selectedWebsiteProfiles = profileList.filter((e) => { return e.WebsiteId === selectedWebsiteValue["WebsiteList.WebsiteId"] });
      if (selectedWebsiteProfiles.length > 0) {
        localStorage.setItem("cookieProfileData", JSON.stringify(selectedWebsiteProfiles));
        let profileData = selectedWebsiteProfiles.map((e) => ({ ["WebsiteProfiles.ProfileId"]: e.ProfileId, ["WebsiteProfiles.DisplayName"]: e.DisplayName, ["WebsiteProfiles.IntelConfigId"]: e.IntelConfigId }));
        setProfilesList(profileData)
        let selectedProfile = profileData[0];
        if (isTempUpdate === 0) {
          let storedProfile = JSON.parse(localStorage.getItem("WebsiteProfilesIntelConfigId"));
          if (storedProfile && Object.keys(storedProfile).length > 0
            && profileData.some(obj => obj["WebsiteProfiles.ProfileId"] === storedProfile["WebsiteProfiles.ProfileId"])) {
            selectedProfile = storedProfile;
          };
          localStorage.setItem("profileData", JSON.stringify(profileData))
        }
        setSelectedProfileIdNew(selectedProfile);
      }
      else {
        setProfilesList([])
        setSelectedProfileIdNew({});
        if (isTempUpdate === 0) {
          localStorage.setItem("profileData", JSON.stringify([]))
        }
      }
      setAutoLoad(true)
    }
  }

  const commonHeaderChild = useMemo(() => <><CommonHeader cancelFilters={cancelFilters} channel="website" autoLoad={autoLoad} history={history} dataSource={[
    { name: 'Dropdown1 - Website', data: websiteList, selectedItem: selectedWebsiteIdNew, isVisible: true, label: 'Website', callback: onWebsiteDropdownChange, displayText: "WebsiteList.WebsiteName" },
    { name: 'Dropdown2 - Profile', data: profilesList, selectedItem: selectedProfileIdNew, isVisible: true, label: 'Website profile', callback: onProfileDropdownChange, displayText: "WebsiteProfiles.DisplayName" },
  ]} title={t("header.coockiesTitle")} showDate={true} applyFilters={applyFilters} /></>, [selectedProfileIdNew, selectedWebsiteIdNew, autoLoad])


  return (
    <>
      {blurLoader && <div id='blurdiv' className="blurbackground">
        <div className={`preloader loader-center-align fff`}>
          <Image className="loader-element animate__animated animate__jackInTheBox" src={LoaderImage} height={40} data-seconds={30} />
        </div>
      </div>}
      <Row>
        <Col lg={12}>
          <Button variant="light" onClick={(e) => handleBackButtonClick(e)} bsPrefix="text" className="ms-3 link-icn-btn">
            <FontAwesomeIcon className="me-1" icon={faChevronLeft} /> {t("header.backButton")}
          </Button>
        </Col>
      </Row>
      <Row className="align-items-center">
        <Col>
          {commonHeaderChild}
        </Col>
      </Row>
      {selectedWebsiteId && (selectedWebsiteId["WebsiteList.WebsiteId"] || selectedProfileId["WebsiteProfiles.ProfileId"]) ?
        <Row>
          <Col>
            <div className="d-inline-block pt-3 ps-3 w-100" id="cookiesContent">
              <div className="shadow-soft border rounded border-light p-3 ps-2 pe-2 w-100 nested-card-container page-performance-detail">
                <CookiePerformanceChart selectedWebsiteId={selectedWebsiteId} selectedProfileId={selectedProfileId}></CookiePerformanceChart>
              </div>
            </div>
          </Col>
        </Row>
        : <div className="p-3 w-100" id="cookiesContent">
          <div className="bg-white shadow-soft border rounded border-light w-100 fmh-200 position-relative">
            <div className="card-no-data mt-n3">{t("header.NoDataFound")}</div>
          </div>
        </div>
      }
    </>
  );
};

export default CookiePerformance;
