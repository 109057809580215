
import React, { useState, useEffect, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import axios from "axios";
import { Row, Col } from '@themesberg/react-bootstrap';

import request from '../apis/request';
import { baseURL, METHODS } from '../apis/utilities/constant';
import { decryptData } from '../utils/crypto';

import Loader from '../components/VoltLoader';
import AppContext from '../components/AppContext';
import PaginationComponent from "../components/Pagination";
import AccordionComponent from "../components/AccordionComponent";
import CommonHeader from '../components/CommonHeader';

const EditablePage = ({
  //children, dashboardItems, selectedProfileId, setSelectedProfileId, selectedWebsiteId, setSelectedWebsiteId, history, location, startDate, //setStartDate, endDate, setEndDate, profileData, setProfileData 
}) => {
  const contextData = useContext(AppContext);
  const [t, i18n] = useTranslation();
  const [siteData, setSiteData] = useState([])
  const [selectedWebsiteIdNew, setSelectedWebsiteIdNew] = useState("");
  const [selectedWebsiteId, setSelectedWebsiteId] = useState("");
  const [autoLoad, setAutoLoad] = useState(false);
  const [siteMapData, setSiteMapData] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [showPagination, setShowPagination] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [websiteList, setWebsiteList] = useState([]);
  const [noDataflag, setNoDataFlag] = useState(false);

  useEffect(() => {
    if (contextData.companyId > 0) {

      if (localStorage.getItem("websiteData") && JSON.parse(localStorage.getItem("websiteData")).length > 0) {

        let websiteList = JSON.parse(localStorage.getItem("websiteData"));
        let selectedWebsite = JSON.parse(localStorage.getItem("WebsiteListWebsiteId"));
        if (!selectedWebsite) {
          selectedWebsite = websiteList[0];
        }
        renderWebsite(websiteList, selectedWebsite)
      }
      else if (!localStorage.getItem("websiteData")) {
        getWebsites();
      }
    }
  }, [contextData.companyId])


  useEffect(() => {
    if (pageNo === 0) {
      setPageNo(1);
    }
  }, [pageNo, siteMapData, siteData]);

  const getWebsites = () => {

    request(baseURL + `/CommonV2/GetWebsiteChannelWebsiteByClientUser?CompanyId=${contextData.companyId}&UserId=${contextData.userId}`,
      METHODS.GET,
      {},
      {
        Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
      })
      .then((res) => {
        let resultData = [];

        if (res.result && res.result.length > 0) {
          resultData = res.result.map((e) => ({ cmsplatformid: res.platformid, ["WebsiteList.WebsiteName"]: e.WebsiteName, ["WebsiteList.WebsiteId"]: e.WebsiteId, ["WebsiteList.WebsiteUrl"]: e.WebsiteUrl }))
        }

        if (resultData.length > 0) {
          localStorage.setItem("websiteData", JSON.stringify(resultData))
          renderWebsite(resultData, resultData[0])
        }
        else {
          localStorage.setItem("websiteData", JSON.stringify([]))
          setAutoLoad(true);
        }

      })
      .catch((err) => {
      })
  }

  const renderWebsite = (resultSet, selectedWebsiteValue) => {

    setWebsiteList(resultSet)
    setSelectedWebsiteIdNew(selectedWebsiteValue);
    setAutoLoad(true);
  };

  const cancelFilters = () => {
    onWebsiteDropdownChange(JSON.parse(localStorage.getItem("WebsiteListWebsiteId")))
  }

  const onWebsiteDropdownChange = (data) => {
    setSelectedWebsiteIdNew(data);
  };


  const onPageNoChange = (number) => {
    const num = (number - 1) * 10;
    const page = number;
    siteData.splice(0, siteData.length)
    setSiteData(siteData);
    var arr = JSON.parse(localStorage.getItem("siteMapData"));
    var sites = arr.slice(num, num + 10);
    var val = [];
    for (const [i, site] of sites.entries()) {
      site.buttonClick = buttonClick;
      val.push(site);
    }
    setSiteData(val);
    setPageNo(page);
    setShowPagination(true);
  }

  const buttonClick = (url) => {
    if (url) {
      contextData.userEmail = decryptData(localStorage.getItem("email"), contextData.cryptoKey);
      var urlvalue = url;
      axios
        .get(
          `${process.env.REACT_APP_API_KEY}/WebsiteAnalytics/GetWebsiteSelfServeURL`,
          {
            params: {
              websiteid: contextData.websiteId,
              requesturl: window.location.href,
              destinationurl: urlvalue,
              siteindex: contextData.websiteId,
              token: '',
              urlType: 4,
              companyId: contextData.companyId,
              UserId: contextData.userId,
              Email: contextData.userEmail
            },
            headers: {
              'Authorization': `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`
            }
          },
        )
        .then(response => {
          if (response && response.data && response.data.url) {
            window.location.href = response.data.url;
          }
        })
        .catch(error => {
        });
    }
    else {
      return false;
    }
  }

  useEffect(() => {

    if (contextData.selectedTab === "Website" && contextData.websiteId && contextData.menu[0].subMenu) {
      const idsSet = new Set(contextData.menu[0].subMenu.map(item => item.id));
      const hasEditablePage = idsSet.has(6);
      if (hasEditablePage && contextData.menu[0].subMenu?.[0]?.refId === contextData.websiteId)
        GetSitemap();
    }
  }, [contextData.websiteId, contextData.menu]);

  const applyFilters = () => {
    setSelectedWebsiteId(selectedWebsiteIdNew)
    contextData.changeWebsiteId(selectedWebsiteIdNew["WebsiteList.WebsiteId"]);
    localStorage.setItem("WebsiteListWebsiteId", JSON.stringify(selectedWebsiteIdNew));// Adding WebsiteList.WebsiteId to local stoarge in case of Page Refresh
  }

  const GetSitemap = () => {
    setShowLoader(true)
    request(
      baseURL + `/WebsiteAnalytics/GetWebsitemap?userId=${contextData.userId}&websiteId=${contextData.websiteId}&companyId=${contextData.companyId}`,
      METHODS.GET,
      {},
      {
        Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
      }
    ).then((response) => {
      if (response) {
        setNoDataFlag(true);
        const value = response;
        setSiteMapData([]);
        siteData.splice(0, siteData.length)
        setSiteData(siteData);
        var listItems = value.filter(p => p.level == 1).map((page) =>
        ({
          id: page.index,
          eventKey: page.index,
          buttonClick: (e) => { buttonClick(e) },
          tooltip: t("header.editablePagesMessage") + page.updatedBy,
          url: page.url,
          title: !page.isLink ? '<div className="accordion-title btn-help"><span data-url=' + page.url + ' class="disabled" data-link=' + page.isLink + '>' + page.title + '</span></div>' : '<div className="accordion-title"><span data-url=' + page.url + ' data-link=' + page.isLink + '>' + page.title + '</span></div>',
          description: value.filter(x => x.parentIndex == page.index && x.level == 2).map((item) => !item.isLink ? '<span  class="disabled btn-help border-0 p-2" variant="white"  data-link=' + item.isLink + '>' + item.title + '</span>' : '<span class="btn-help border-0 p-2" variant="white" data-url=' + item.url + ' data-link=' + item.isLink + '>' + item.title + '</span>'),
          descriptiontooltip: value.filter(x => x.parentIndex == page.index && x.level == 2).map((item) => t("header.editablePagesMessage") + item.updatedBy)
        }));
        localStorage.setItem("siteMapData", JSON.stringify(listItems));
        setSiteMapData(JSON.parse(localStorage.getItem("siteMapData")));
        onPageNoChange(1);
        setShowLoader(false);
      }
      else {
        setShowLoader(false);
      }
    }).catch((error) => {
      setShowLoader(false);
    });;

  }

  const commonHeaderChild = useMemo(() => <>
    <CommonHeader cancelFilters={cancelFilters} channel="website" autoLoad={autoLoad} dataSource={[
      { name: 'Dropdown1 - Website', data: websiteList, selectedItem: selectedWebsiteIdNew, isVisible: true, label: t("header.filterLabelWebsite"), callback: onWebsiteDropdownChange, displayText: "WebsiteList.WebsiteName" },
    ]} title={t("header.EditablePagesPageHeading")} showDate={false} applyFilters={applyFilters} />
  </>, [autoLoad, selectedWebsiteIdNew]);

  return (
    <>
      <Row>
        <Col>{commonHeaderChild}</Col>
      </Row>

      <Row>
        {showLoader === true ?
          (
            <Col>
              <div className='mt-5'>
                <Loader show={showLoader} className="editable-pages-loader" />
              </div>
            </Col>
          ) : (
            <Col>
              <div className="ms-3 me-3 editable-pages">
                {siteData && siteData.length > 0 ?
                  <AccordionComponent
                    buttonClick={buttonClick}
                    defaultKey="panel-1"
                    data={siteData} />
                  :
                  (
                    siteData.length == 0 && noDataflag &&
                    <div className="no-data">{t("header.contentUnavailable")}</div>
                  )
                }

                {showPagination &&
                  siteData.length > 0 &&
                  (
                    <Row>
                      <Col className="px-4 border-0 d-lg-flex align-items-center justify-content-between">
                        <PaginationComponent
                          pageNo={pageNo}
                          setPageNo={setPageNo}
                          pagesData={siteData}
                          onPageNoChange={onPageNoChange}
                          minimumRows={10}
                          showPaginationSummary={true}
                          totalRecords={siteMapData.length}
                        />
                      </Col>
                    </Row>
                  )
                }
              </div>
            </Col>
          )}
      </Row>
    </>
  );
};

export default EditablePage;
