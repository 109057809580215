import React, { useRef, useState, useEffect, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import moment from "moment";
import Datetime from "react-datetime";
import { useTranslation } from 'react-i18next';
import { faCalendarAlt, faCheckCircle, faTimesCircle, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Col, Row, Card, Form, InputGroup, FormCheck, Alert } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import request from '../apis/request';
import { baseURL, METHODS } from '../apis/utilities/constant';
import { decryptData } from '../utils/crypto';
import { DataStatusMessage } from "../utils/constant";
import { ERROR_TIMEOUT, isEmpty, TIMEOUT_FOR_ALERTS, VALIDATE_URL_REGEX } from "../AppConfig";

import ButtonComponent from "../components/Buttons";
import DropdownWithIconsComponent from "../components/DropdownWithIcons";
import AppContext from '../components/AppContext';
import CommonHeader from '../components/CommonHeader';
import CommonMessageComponent from '../components/CommonMessageComponent';

const AddEventPages = (props) => {

    const {
        isEditMode,
        editData,
        formError,
        isFormSubmit,
        attachmentView,
        setEditData,
        setIsPageLoad
    } = props;

    const [t, i18n] = useTranslation();
    const contextData = useContext(AppContext);

    const fileInputRef = useRef();
    const eventRef = useRef();
    const attachmentRef = useRef();
    const [blurLoader, setBlurLoader] = useState(false);
    const [selectedWebsiteIdNew, setSelectedWebsiteIdNew] = useState({});
    const [autoLoad, setAutoLoad] = useState(false)
    const [selectedWesbsiteId, setSelectedWebsiteId] = useState({})
    //APIs State
    const [timeZone, setTimeZone] = useState([]);
    const [categories, setCategory] = useState([]);
    const [selectedTimeZone, setSelectedTimeZone] = useState({});

    const [language, setLanguage] = useState([]);
    const [selectedLanguage, setSelectedLanguage] = useState({ status: DataStatusMessage.Loading });

    const [startDate, setStartDate] = useState(undefined);
    const [endDate, setEndDate] = useState(undefined);
    const [active, setActive] = useState(true);
    const [showEndTime, setShowEndTime] = useState(false);
    const [silentEvent, setSilentEvent] = useState(false);
    const [isWholeDayEvent, setIsWholeDayEvent] = useState(false);
    const [tbcEvent, setTbcEvent] = useState(false);

    const [title, setTitle] = useState(undefined);
    const [titleErr, setTitleErr] = useState(undefined);

    const [desc, setDesc] = useState(undefined);
    const [link, setLink] = useState(undefined);
    const [webcastUrl, setWebcastUrl] = useState(undefined);

    const [linkErr, setLinkErr] = useState(undefined);
    const [webcastUrlErr, setWebcastUrlErr] = useState(undefined);
    const [locationErr, setLocationErr] = useState(undefined);

    const [location, setLocation] = useState(undefined);
    const [edActive, setEdActive] = useState(true);

    const [fileFormData, setFileFormData] = useState(undefined);
    const [fileOtherData, setFileOtherData] = useState(undefined);
    const [fileDisplayName, setFileDisplayName] = useState(undefined);
    const [fileDisplayNameErr, setFileDisplayNameErr] = useState(undefined);
    const [fileErr, setFileErr] = useState(undefined);
    const [attachmentTableData, setAttachmentTableData] = useState([]);
    const [deletedAttachments, setDeletedAttachments] = useState([]);
    const [isFormValid, setIsFormValid] = useState(false);
    const [isEventFormValid, setIsEventFormValid] = useState(false);

    const [eventTableData, setEventTableData] = useState([]);
    const [genericErr, setGenericErr] = useState(undefined);

    const [selectedAttachment, setSelectedAttachment] = useState(undefined);

    const [websiteList, setWebsiteList] = useState([]);

    const [attachmentSuccessMsg, setAttachmentSuccessMsg] = useState(undefined);

    const [successMessage, setSuccessMessage] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);
    const [hasLanguage, setHasLanguage] = useState(false);
    const [hiddenAlerts, setHiddenAlerts] = React.useState([]);

    //Edit Mode Configuration
    useEffect(() => {
        if (isEditMode && editData) {
            if (attachmentView) {
                setTimeout(() => {
                    fileInputRef && fileInputRef.current && fileInputRef.current.scrollIntoView();
                }, 10);
            }
            if (editData?.result?.EventData) {
                let { EventData, Attachments, CategoryList, EventDetails, LanguageList, TimeZoneList } = editData?.result;
                let { Active, ClientId, DefaultReminder, EndDate, EventId, IsWholeDayEvent, ShowEndTime,
                    SilentEvent, StartDate, TBCEvent, TimeZone } = EventData;
                setTimeZone(TimeZoneList);
                if (TimeZoneList && Array.isArray(TimeZoneList) && TimeZoneList.length > 0) {
                    TimeZoneList.map((item, index) => {
                        if (item.Id == TimeZone) {
                            setSelectedTimeZone(item);
                        }
                    })
                }
                setLanguage(LanguageList);
                if (LanguageList && Array.isArray(LanguageList) && LanguageList.length > 0) {
                    setSelectedLanguage(LanguageList[0]);
                }
                let eDetails = [];
                EventDetails && Array.isArray(EventDetails) && EventDetails.map((item, index) => {
                    let lng = LanguageList && Array.isArray(LanguageList) && LanguageList.filter((x => x.LanguageCode == item.LanguageCode));
                    let obj = {
                        eventName: item.Title,
                        language: lng && lng[0],
                        title: item.Title,
                        desc: item.Description,
                        link: item.Link,
                        webcastUrl: item.WebcastUrl,
                        location: item.Location,
                        edActive: item.Active
                    }
                    eDetails.push(obj);
                })
                if (eDetails.length > 0) {
                    setTitle(eDetails[0].title);
                    setSelectedLanguage(eDetails[0].language);
                    setLink(eDetails[0].link);
                    setDesc(eDetails[0].desc);
                    setEdActive(eDetails[0].edActive);
                    setLocation(eDetails[0].location);
                    setWebcastUrl(eDetails[0].webcastUrl);
                }
                let categories = [];
                CategoryList && Array.isArray(CategoryList) && CategoryList.length > 0 && CategoryList.map((item) => {
                    item.isChecked = item.Active;
                    categories.push(item);
                })
                setCategory(categories);
                let attachments = [];
                Attachments && Array.isArray(Attachments) && Attachments.map((item, index) => {
                    let obj = {
                        itemId: Math.floor(Math.random() * 90 + 10),
                        id: item.AttachmentId,
                        fileName: item.DisplayName,
                        originalName: item.DisplayName,
                        type: item.FileType,
                        uploadedFileName: item.UploadFileName,
                        uploadedFilePath: item.FilePath,
                        languageCode: item.LanguageCode
                    }
                    attachments.push(obj);
                })
                setAttachmentTableData(attachments);
                setEventTableData(eDetails);
                setTbcEvent(TBCEvent);
                setIsWholeDayEvent(IsWholeDayEvent);
                setActive(Active);
                setShowEndTime(ShowEndTime);
                setSilentEvent(SilentEvent);
                if (!TBCEvent) {
                    setStartDate(new Date(StartDate));
                    setEndDate(new Date(EndDate));
                }
            }
        }
        else {
            setEndDate(new Date());
            setStartDate(new Date());
            setActive(true);
            setEdActive(true);
        }
    }, [])

    useEffect(() => {
        setBlurLoader(true);
        if (contextData.companyId > 0) {
            request(baseURL + `/Intelligence/GetToolsWebsiteForClientUser?CompanyId=${contextData.companyId}&UserId=${contextData.userId}`,
                METHODS.GET,
                {},
                {
                    Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
                })
                .then((res) => {
                    onWebsiteDropdownChange(undefined);

                    let webSiteData = [];
                    res?.result && Array.isArray(res?.result) && res?.result.length > 0 && res?.result.map((item) => {
                        webSiteData.push({
                            "ClietConfiguration.otpclientid": 0,
                            "WebsiteList.WebsiteId": item.WebsiteId,
                            "WebsiteList.WebsiteName": item.WebsiteName
                        })
                    })
                    if (Object.keys(selectedWebsiteIdNew).length <= 0) {
                        if (localStorage.getItem("SelectedEventId")) {
                            onWebsiteDropdownChange(JSON.parse(localStorage.getItem("SelectedEventId")))
                        } else {
                            webSiteData.length > 0 ? onWebsiteDropdownChange(webSiteData[0]) : onWebsiteDropdownChange({});
                        }
                    }
                    localStorage.setItem("eventsWebsiteData", JSON.stringify(webSiteData))
                    setAutoLoad(true)
                    setWebsiteList(webSiteData);
                    setBlurLoader(false);
                })
                .catch((err) => {
                    setBlurLoader(false);
                    setCategory([]);
                })
        }
    }, [contextData.companyId])

    useEffect(() => {
        if (contextData.selectedTab === "Tools" && selectedWesbsiteId && selectedWesbsiteId["WebsiteList.WebsiteId"] & !isEditMode) {
            getCategory();
        }
    }, [selectedWesbsiteId])

    useEffect(() => {
        if (selectedWebsiteIdNew && selectedWebsiteIdNew["WebsiteList.WebsiteId"] && selectedLanguage?.status != 0) {
            checkLanguage();
        }
    }, [selectedWebsiteIdNew])

    const getCategory = () => {
        if (selectedWesbsiteId && selectedWesbsiteId["WebsiteList.WebsiteId"]) {
            setBlurLoader(true);
            let webSiteId = selectedWesbsiteId["WebsiteList.WebsiteId"];
            request(baseURL + `/EventCalendar/GetEventCategoryByClient?CompanyId=${contextData.companyId}&UserId=${contextData.userId}&WebsiteId=${webSiteId}`,
                METHODS.GET,
                {},
                {
                    Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
                })
                .then((res) => {
                    let categories = [];

                    res?.result && Array.isArray(res?.result) && res?.result.length > 0 && res?.result.map((item) => {
                        item.isChecked = false;
                        categories.push(item);
                    })

                    setCategory(categories);
                    setBlurLoader(false);
                })
                .catch((err) => {
                    setBlurLoader(false);
                    setCategory([]);
                })
        }
    }

    const getLanguage = () => {
        setBlurLoader(true);
        let webSiteId = selectedWebsiteIdNew["WebsiteList.WebsiteId"];
        request(baseURL + `/EventCalendar/GetEventLanguagesByClient?CompanyId=${contextData.companyId}&UserId=${contextData.userId}&WebsiteId=${webSiteId}`,
            METHODS.GET,
            {},
            {
                Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
            })
            .then((res) => {
                if (typeof res.message == 'string') {
                    setSelectedLanguage({ status: DataStatusMessage.DataUnAvailable });

                }
                else {

                    setLanguage(res?.result);
                    setSelectedAttachment(undefined);
                    setSelectedAttachment(undefined);
                    setFileDisplayName("");
                    setFileOtherData(undefined);
                    setFileFormData("");
                    if (res?.result && Array.isArray(res?.result) && res?.result.length > 0)
                        setSelectedLanguage(res?.result[0]);
                    setBlurLoader(false);
                }
            })
            .catch((err) => {
                setBlurLoader(false);
                setLanguage([]);
            })
    }

    const onCategoryItemChange = (e, index) => {
        setCategory(undefined);
        let updatedCategories = categories;

        if (updatedCategories[index].isChecked) {
            updatedCategories[index].isChecked = false;
        }
        else {
            updatedCategories[index].isChecked = true;
        }

        setTimeout(() => {
            setCategory(updatedCategories);
        }, 10)
    }

    const getTimeZoneList = () => {
        if (timeZone.length === 0) {
            setBlurLoader(true);
            request(baseURL + `/EventCalendar/GetTimeZoneList`,
                METHODS.GET,
                {},
                {
                    Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
                })
                .then((res) => {
                    setTimeZone(res?.result);
                    if (res?.result && Array.isArray(res?.result) && res?.result.length > 0)
                        setSelectedTimeZone(res?.result[0]);

                    setBlurLoader(false);
                })
                .catch((err) => {
                    setBlurLoader(false);
                    setTimeZone([]);
                })
        }
    }

    const onTimeZoneDrpChange = (data) => {
        setSelectedTimeZone(data);
    }

    const onFileUpload = (e) => {
        e.preventDefault();
        let file = e.target.files[0];
        setFileOtherData({
            name: file.name,
            type: file.type
        });
        const formData = new FormData();
        formData.append("filedetails", file);
        setFileFormData(formData);
        setFileErr(undefined);
        setSelectedAttachment(undefined);
    }

    const onClose = (alertId) => {
        const hiddenAlertsUpdated = [...hiddenAlerts, alertId];
        setHiddenAlerts(hiddenAlertsUpdated);
    };

    const validateAttachmentDetails = () => {
        if (!fileDisplayName) {
            setFileDisplayNameErr(t("header.addEventsDisplayNameMessage"));
            return false;
        }
        if (fileDisplayName) {
            var filteredData = fileDisplayName.replace(/\<|\>|\"|\%|\$|\;|\(|\)|\&|\+|\'|\~|\`|--/g, "");
            let containsBadInput = (fileDisplayName == filteredData) ? false : true;
            if (containsBadInput) {
                setFileDisplayNameErr(t("header.addEventsInvalidDisplayName"));
                return false;
            }
            else {
                setFileDisplayNameErr("");
            }
        }
        if (!selectedAttachment && !fileFormData) {
            setFileErr(t("header.ToolsEventCalModEventSelectFile"));
            return false;
        }
        else {
            setFileErr(undefined);
        }

        return true;
    }

    const onHandleFileUpload = (e) => {
        e.preventDefault();

        if (validateAttachmentDetails()) {
            if (selectedAttachment) {
                let index = attachmentTableData.findIndex(x => x.originalName == selectedAttachment.originalName);
                attachmentTableData[index].fileName = fileDisplayName;

                setAttachmentTableData(attachmentTableData);
                setSelectedAttachment(undefined);
                setFileDisplayName("");
                setFileOtherData(undefined);
                setFileFormData("");
            }
            else {
                setBlurLoader(true);
                request(baseURL + `/EventCalendar/UploadEventAttachment`,
                    METHODS.POST,
                    fileFormData,
                    {
                        Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
                    })
                    .then((res) => {
                        if (res?.result) {
                            let updatedAttachmentTable = attachmentTableData;
                            updatedAttachmentTable.push({
                                itemId: Math.floor(Math.random() * 90 + 10),
                                id: 0,
                                formData: fileFormData,
                                fileName: fileDisplayName,
                                originalName: fileOtherData.name,
                                type: fileOtherData.type,
                                uploadedFileName: res?.result?.FileName,
                                uploadedFilePath: res?.result?.FilePath,
                                languageCode: selectedLanguage.LanguageCode
                            })
                            setAttachmentSuccessMsg(t("header.ToolsEventCalModEventAttachmentUploaded"));
                            setTimeout(() => {
                                setAttachmentSuccessMsg("");
                            }, TIMEOUT_FOR_ALERTS);
                            if (fileInputRef.current) {
                                fileInputRef.current.value = "";
                            }
                            setAttachmentTableData(updatedAttachmentTable);
                            setFileDisplayName("");
                            setFileOtherData(undefined);
                            setFileFormData("");
                            setBlurLoader(false);
                        }
                    })
                    .catch((err) => {
                        setErrorMessage(t("header.selectValidFile"));
                        setTimeout(() => {
                            setErrorMessage(undefined);
                        }, ERROR_TIMEOUT);
                        if (fileInputRef.current) {
                            fileInputRef.current.value = "";
                        }
                        setFileOtherData(undefined);
                        setAttachmentTableData([]);
                        setFileDisplayName("");
                        setFileFormData("");
                        setBlurLoader(false);
                    })
            }
        }
    }

    const onDeleteAttachment = (e, index) => {
        e.preventDefault();
        let updateAttachment = attachmentTableData;
        deletedAttachments.push(attachmentTableData[index]);

        setDeletedAttachments(deletedAttachments);
        setAttachmentTableData(undefined);
        updateAttachment.splice(index, 1);
        setAttachmentSuccessMsg(t("header.ToolsEventCalModEventAttachmentDeleted"));

        setTimeout(() => {
            setAttachmentSuccessMsg("");
        }, TIMEOUT_FOR_ALERTS);
        setTimeout(() => {
            setAttachmentTableData(updateAttachment);
            setSelectedAttachment(undefined);
            setSelectedAttachment(undefined);
            setFileDisplayName("");
            setFileOtherData(undefined);
            setFileFormData("");
        }, 10);
    }

    const resetForm = () => {
        if (timeZone?.length) {
            setSelectedTimeZone(timeZone[0]);
        }
        else {
            setSelectedTimeZone({});
        }
        setSelectedLanguage({});
        setStartDate(new Date());
        setEndDate(new Date());
        setActive(true);
        setShowEndTime(false);
        setSilentEvent(false);
        setIsWholeDayEvent(false);
        setTbcEvent(false);
        setTitle("");
        setDesc("");
        setLink("");
        setWebcastUrl("");
        setLocation("");
        setEdActive(true);
        setFileFormData("");
        setFileOtherData("");
        setFileDisplayName("");
        setAttachmentTableData([]);
        setIsFormValid(false);
        setIsEventFormValid(false);
        setEventTableData([]);
        setGenericErr("");
        setCategory("");
        getTimeZoneList();
        getLanguage();
    }

    const onSaveEvent = (e) => {
        e.preventDefault();
        if (validateFormSaveEvent()) {
            setBlurLoader(true);
            let selectedCategory = [];
            let attachments = [];
            let events = [];

            let EventData = editData && editData?.result?.EventData;
            let EventId = EventData && EventData?.EventId;

            categories && categories.length > 0 && categories.map((category) => {
                if (category.isChecked) {
                    selectedCategory.push({
                        CategoryId: category.CategoryId,
                        CategoryName: category.CategoryName,
                        Active: true
                    })
                }
            })
            attachmentTableData && attachmentTableData.length > 0 && attachmentTableData.map((item) => {
                attachments.push({
                    Active: 1,
                    DisplayName: item.fileName,
                    AttachmentId: item.id,
                    LanguageCode: selectedLanguage && Object.keys(selectedLanguage).length > 0 ? selectedLanguage.LanguageCode : "",
                    FileName: item.originalName,
                    UploadFileName: item.uploadedFileName,
                    fileFilePathPath: item.uploadedFilePath,
                    FileType: item.type,
                })
            })

            deletedAttachments && deletedAttachments.length > 0 && deletedAttachments.map((item) => {
                attachments.push({
                    Active: 0,
                    DisplayName: item.fileName,
                    AttachmentId: item.id,
                    LanguageCode: selectedLanguage && Object.keys(selectedLanguage).length > 0 ? selectedLanguage.LanguageCode : "",
                    FileName: item.originalName,
                    UploadFileName: item.uploadedFileName,
                    FilePath: item.uploadedFilePath,
                    FileType: item.type,
                    //fileName: item.fileName
                })
            })


            eventTableData && eventTableData.length > 0 && eventTableData.map((item) => {
                events.push({
                    "EventId": isEditMode ? EventId : 0,
                    "EventDetailsId": 0,
                    "LanguageCode": item.language.LanguageCode,
                    "Title": item.title,
                    "Description": item.desc,
                    "Location": item.location,
                    "Link": item.link,
                    "WebcastUrl": item.webcastUrl,
                    "Active": item.edActive,
                    "DisplayName": ""
                })
            })

            let obj = {
                "WebsiteId": selectedWebsiteIdNew["WebsiteList.WebsiteId"],
                "CompanyId": contextData.companyId,
                "UserId": contextData.userId,
                "EventData": {
                    "EventId": isEditMode ? EventId : 0,
                    "StartDate": moment(startDate).toISOString(),
                    "EndDate": moment(endDate).toISOString(),
                    "TimeZone": selectedTimeZone && Object.keys(selectedTimeZone).length > 0 ? selectedTimeZone.Id : "",
                    "Active": active,
                    "ShowEndTime": showEndTime,
                    "SilentEvent": silentEvent,
                    "IsWholeDayEvent": isWholeDayEvent,
                    "TBCEvent": tbcEvent
                },
                "CategoryList": selectedCategory,
                "EventDetails": events,
                "Attachments": attachments
            }

            request(baseURL + `/EventCalendar/SaveEvent?CompanyId=${contextData.companyId}`,
                METHODS.POST,
                obj,
                {
                    Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
                })
                .then((res) => {
                    if (res.IsValid) {
                        setSuccessMessage(t("header.EventSavedSuccessfully"));
                        setTimeout(() => {
                            setSuccessMessage(undefined);
                            resetForm();
                            setEditData && setEditData(undefined);
                            isEditMode && setIsPageLoad(true);
                        }, ERROR_TIMEOUT);
                    }
                    if (res.message) {
                        setErrorMessage(t("header.addEventsSavingError"));
                        setTimeout(() => {
                            setErrorMessage(undefined);
                            resetForm();
                            setEditData && setEditData(undefined);
                        }, ERROR_TIMEOUT);
                        setGenericErr(res.message);
                    }
                    setBlurLoader(false);
                })
                .catch((err) => {
                    setBlurLoader(false);
                    setGenericErr(t("header.somethingWentWrong"));
                })
        }
    }

    const onSaveDetails = (e) => {
        e.preventDefault();

        if (validateForm()) {

            let selectedData = undefined;
            let isLanguageDataAdded = false;
            let newEventTableData = eventTableData;
            setEventTableData(undefined);
            newEventTableData.length > 0 && newEventTableData.map((item) => {
                if (!isLanguageDataAdded && item.language.LanguageCode == selectedLanguage.LanguageCode) {
                    isLanguageDataAdded = true;
                    selectedData = item;
                }
            })
            if (isLanguageDataAdded) {
                //Find Index of existing data
                let index = newEventTableData.findIndex(x => x.eventName == selectedData.eventName);
                if (index != -1) {
                    newEventTableData[index] = {
                        eventName: title,
                        language: selectedLanguage,
                        title: title,
                        desc: desc,
                        link: link,
                        webcastUrl: webcastUrl,
                        location: location,
                        edActive: edActive
                    }
                }
            }
            else {
                newEventTableData.push({
                    eventName: title,
                    language: selectedLanguage,
                    title: title,
                    desc: desc,
                    link: link,
                    webcastUrl: webcastUrl,
                    location: location,
                    edActive: edActive
                })
            }
            setTimeout(() => {
                setEventTableData(newEventTableData);
                isEditMode && setSuccessMessage(t("header.ToolsEventCalModEventDetailsUpdated"))
            }, 10);
            setTimeout(() => {
                isEditMode && setSuccessMessage(undefined)
            }, ERROR_TIMEOUT);
        }
    }

    const onAttachmentItemClick = (e, item) => {
        e.preventDefault();
        setSelectedAttachment(item);
        setFileDisplayName(item.fileName);
        setFileErr("");
        setTimeout(() => {
            attachmentRef && attachmentRef.current && attachmentRef.current.scrollIntoView();
        }, 10);
    }

    const onEventTableItemClick = (e, item) => {
        e.preventDefault();
        setTitle(item.title);
        setSelectedLanguage(item.language);
        setLink(item.link);
        setDesc(item.desc);
        setEdActive(item.edActive);
        setLocation(item.location);
        setWebcastUrl(item.webcastUrl);

        setTimeout(() => {
            eventRef && eventRef.current && eventRef.current.scrollIntoView();
        }, 10);
    }

    const onChangeLanguage = (data) => {
        setSelectedLanguage(data);
        let eventDetailsData = eventTableData;
        let selectedData = undefined;
        let isLanguageDataAdded = false;
        eventDetailsData.length > 0 && eventDetailsData.map((item) => {
            if (!isLanguageDataAdded && item.language.LanguageCode == data.LanguageCode) {
                isLanguageDataAdded = true;
                selectedData = item;
            }
        })
        if (isLanguageDataAdded) {
            setTitle(selectedData.eventName);
            setSelectedLanguage(selectedData.language);
            setDesc(selectedData.desc);
            setLink(selectedData.link);
            setWebcastUrl(selectedData.webcastUrl);
            setLocation(selectedData.location);
            setEdActive(selectedData.edActive);
        }
        else {
            resetEventDetails();
        }
    }

    const resetEventDetails = () => {
        setTitle("");
        setDesc("");
        setLink("");
        setWebcastUrl("");
        setLocation("");

        setEdActive(false);

        //getLanguage();
    }

    const validateFormSaveEvent = () => {
        let isValidate = true;

        if (isValidate && !tbcEvent && endDate <= startDate) {
            setGenericErr(t("header.ToolsEventCalModEventEndLessThanStartError"));
            isValidate = false;
        }
        else {
            isValidate && setGenericErr("");
        }
        if (isValidate && eventTableData.length <= 0) {
            isValidate = false;
            setGenericErr(t("header.ToolsEventCalModEventMinimumOneEvent"));
        }
        else {
            isValidate && setGenericErr("");
        }


        setIsEventFormValid(!isValidate);
        setTimeout(() => {
            setIsEventFormValid(false);
        }, 5000);
        return isValidate;
    }

    const validateForm = () => {
        let isValidate = true;
        if (!title) {
            setTitleErr(t("header.ToolsEventCalModEventTitleRequired"));
            isValidate = false;
        }
        else if (title) {
            var filteredData = title.replace(/\<|\>|\"|\%|\$|\;|\(|\)|\&|\+|\'|\~|\`|--/g, "");
            let containsBadInput = (title == filteredData) ? false : true;
            if (containsBadInput) {
                setTitleErr(t("header.addEventsInvalidTitle"));
                isValidate = false;
            }
            else {
                setTitleErr("");
            }
        }

        if (link && !VALIDATE_URL_REGEX.test(link)) {
            setLinkErr(t("header.ToolsEventCalModEventLinkInvalidURL"));
            isValidate = false;
        }
        else {
            setLinkErr("");
        }
        if (webcastUrl && !VALIDATE_URL_REGEX.test(webcastUrl)) {
            setWebcastUrlErr(t("header.ToolsEventCalModEventWCUrlInvalid"));
            isValidate = false;
        }
        else {
            setWebcastUrlErr("");
        }
        if (location) {
            var filteredData = location.replace(/\<|\>|\"|\%|\$|\;|\(|\)|\&|\+|\'|\~|\`|--/g, "");
            let containsBadInput = (location == filteredData) ? false : true;
            if (containsBadInput) {
                setLocationErr(t("header.addEventsInvalidLocation"));
                isValidate = false;
            }
            else {
                setLocationErr("");
            }
        }

        setIsFormValid(!isValidate);
        setTimeout(() => {
            setIsFormValid(false);
        }, 5000);
        return isValidate;
    }

    const onWebsiteDropdownChange = (data) => {
        setSelectedWebsiteIdNew(data);
    };

    const checkLanguage = async () => {
        try {
            let wId = JSON.parse(localStorage.getItem("SelectedEventId"));
            const webSiteId = selectedWebsiteIdNew["WebsiteList.WebsiteId"] ? selectedWebsiteIdNew["WebsiteList.WebsiteId"] : wId["WebsiteList.WebsiteId"];

            const res = await request(baseURL + `/EventCalendar/GetEventLanguagesByClient?CompanyId=${contextData.companyId}&UserId=${contextData.userId}&WebsiteId=${webSiteId}`, METHODS.GET, {}, {
                Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`,
                "content-type": "application/json"
            });

            if (res?.result && Array.isArray(res?.result) && res?.result.length > 0) {
                setHasLanguage(true);
            } else {
                setHasLanguage(false);
            }
        } catch (err) {
            console.log(err);
            setHasLanguage(false);
        }
    };

    const cancelFilters = () => {
        onWebsiteDropdownChange(JSON.parse(localStorage.getItem("SelectedEventId")))
    }

    const applyFilters = () => {
        setSelectedWebsiteId(selectedWebsiteIdNew);
        resetForm();
        localStorage.setItem("SelectedEventId", JSON.stringify(selectedWebsiteIdNew));// Adding SelectedEventId to local stoarge in case of Page Refresh
    }

    const onCloseSuccessMessage = () => {
        setSuccessMessage(false);
        setErrorMessage(false);
    }

    const commonHeaderChild =
        useMemo(() =>
            <>
                <CommonHeader
                    cancelFilters={cancelFilters}
                    channel="alert"
                    autoLoad={autoLoad}
                    dataSource={[
                        { name: 'Dropdown1 - website', data: JSON.parse(localStorage.getItem("eventsWebsiteData")), selectedItem: selectedWebsiteIdNew, isVisible: true, label: t("header.filterLabelWebsite"), callback: onWebsiteDropdownChange, displayText: "WebsiteList.WebsiteName" },
                    ]}
                    title={t("header.ToolsEventCalTabAddEvents")}
                    showDate={false}
                    applyFilters={applyFilters}
                    isApplyDisabled={!hasLanguage ? true : false}
                />
            </>,
            [autoLoad, selectedWebsiteIdNew, hasLanguage])

    return (
        <>
            {!isEditMode && <Row>
                <Col>
                    {
                        commonHeaderChild
                    }
                </Col>
            </Row>
            }
            {
                (selectedLanguage?.hasOwnProperty("status") || Object.keys(selectedLanguage).length == 0) &&
                <>
                    <div className="d-inline-block p-3 pe-0 w-100">
                        <div className="shadow-soft border rounded border-light w-100 nested-card-container box-height">
                            <div className='text-center no-data position-relative mt-5'>
                                <CommonMessageComponent transactionStatus={selectedLanguage?.hasOwnProperty("status") ? selectedLanguage.status : DataStatusMessage.Loading}></CommonMessageComponent>
                            </div>
                        </div>
                    </div>
                </>
            }
            {
                !selectedLanguage?.hasOwnProperty("status") && Object.keys(selectedLanguage).length > 0 &&
                <>
                    {successMessage || errorMessage && <div className="fade modal-backdrop show"></div>}
                    <Alert
                        className="toast-custom"
                        variant="success"
                        show={successMessage ? true : false}
                        onClose={() => onCloseSuccessMessage()}
                    >
                        <div className="d-flex justify-content-between">
                            <div>
                                <FontAwesomeIcon icon={faCheckCircle} className="me-2" />
                                {successMessage}
                            </div>
                        </div>
                    </Alert>
                    <Alert
                        className="toast-custom"
                        variant="danger"
                        show={errorMessage ? true : false}
                        onClose={() => onCloseSuccessMessage()}
                    >
                        <div className="d-flex justify-content-between">
                            <div>
                                <FontAwesomeIcon icon={faTimesCircle} className="me-2" />
                                {errorMessage}
                            </div>
                        </div>
                    </Alert>

                    {/* fields starts here */}
                    <Row className="mb-3">
                        <Col lg={12}>
                            <Card border="light" className="bg-white shadow-sm mb-4 ms-3" >
                                {!isEditMode && <Card.Header>
                                    <div>
                                        <p className='mb-3 title'>{t("header.ToolsEventCalAddEventsInfo")}</p>
                                    </div>
                                </Card.Header>}
                                <Card.Body>
                                    <Form className="form-container pt-2 ps-3 pe-3">
                                        <Row>
                                            <Col md={12}>
                                                <h6>{t("header.ToolsEventCalModEventConfigTitle")}</h6>
                                            </Col>
                                        </Row>
                                        <Row className="mt-2 mb-2">
                                            <Col md={3}>
                                                <Form.Label className="fw-regular">{t("header.addEventsTentativeEvent")}</Form.Label>
                                            </Col>
                                            <Col md={6}>
                                                <FormCheck type="checkbox" className="inbox-check me-2">
                                                    <FormCheck.Input checked={tbcEvent ? true : false} value={tbcEvent} onChange={(e) => {
                                                        setTbcEvent(e.target.checked);
                                                    }} className="me-2" />
                                                    <FormCheck.Label></FormCheck.Label>
                                                </FormCheck>
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col md={3}>
                                                <Form.Label>{t("header.ToolsEventCalModEventStartDate")}</Form.Label>
                                            </Col>
                                            <Col md={3} className="mb-2">
                                                <Form.Group>
                                                    <Datetime
                                                        timeFormat={true}
                                                        closeOnSelect={false}
                                                        onChange={(date) => {
                                                            setStartDate(date._d);
                                                        }}
                                                        renderInput={(props, openCalendar) => (
                                                            <InputGroup className={tbcEvent ? "is-disabled" : ""}>
                                                                <Form.Control
                                                                    className={tbcEvent ? "is-disabled" : ""}
                                                                    type="text"
                                                                    disabled={tbcEvent ? true : false}
                                                                    autoComplete="off"
                                                                    name="startDate"
                                                                    value={moment(startDate).format("MMM DD, YYYY hh:mm A")}
                                                                    placeholder={t("header.selectStartDate")}
                                                                    onFocus={openCalendar}
                                                                    onChange={() => { }} />
                                                                <InputGroup.Text onClick={openCalendar}><FontAwesomeIcon icon={faCalendarAlt} /></InputGroup.Text>
                                                            </InputGroup>
                                                        )} />
                                                    {(isFormSubmit && !isEmpty(formError.dob)) &&
                                                        <small className="alert-msg-danger">{formError.dob}</small>
                                                    }
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col md={3}>
                                                <Form.Label>{t("header.ToolsEventCalEventListColEndDate")}</Form.Label>
                                            </Col>
                                            <Col md={3} className="mb-2">
                                                <Form.Group>
                                                    <Datetime
                                                        timeFormat={true}
                                                        closeOnSelect={false}
                                                        onChange={(date) => {
                                                            setEndDate(date._d);
                                                        }}
                                                        renderInput={(props, openCalendar) => (
                                                            <InputGroup className={tbcEvent ? "is-disabled" : ""}>
                                                                <Form.Control
                                                                    className={tbcEvent ? "is-disabled" : ""}
                                                                    type="text"
                                                                    disabled={tbcEvent ? true : false}
                                                                    autoComplete="off"
                                                                    name="endDate"
                                                                    value={moment(endDate).format("MMM DD, YYYY hh:mm A")}
                                                                    placeholder={t("header.selectEndDate")}
                                                                    onFocus={openCalendar}
                                                                    onChange={() => { }} />
                                                                <InputGroup.Text onClick={openCalendar}>
                                                                    <FontAwesomeIcon icon={faCalendarAlt} />
                                                                </InputGroup.Text>
                                                            </InputGroup>
                                                        )}
                                                    />
                                                    {(isFormSubmit && !isEmpty(formError.dob)) &&
                                                        <small className="alert-msg-danger">{formError.dob}</small>
                                                    }
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col md={3}>
                                                <Form.Label>{t("header.ToolsEventCalModEventTimeZone")}</Form.Label>
                                            </Col>
                                            <Col md={6}>
                                                <Row className="mb-3">
                                                    <Col md={12}>
                                                        <DropdownWithIconsComponent
                                                            isDropdownDisabled={tbcEvent ? true : false}
                                                            selectedDropdown={selectedTimeZone}
                                                            dropdownData={timeZone}
                                                            arrayValue="DisplayName"
                                                            primaryIcon="none"
                                                            primaryIconType="icon"
                                                            primaryIconCustom={false}
                                                            onChange={onTimeZoneDrpChange}
                                                            showLabel={false}
                                                            showIconInDropdownItem={false}
                                                            isPrimaryImage={true}
                                                            customClassName="full-width"
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={6} className="mb-2">
                                                        <FormCheck type="checkbox" className="inbox-check me-2">
                                                            <FormCheck.Input checked={isWholeDayEvent ? true : false} disabled={tbcEvent ? true : false} value={isWholeDayEvent} onChange={(e) => {
                                                                setIsWholeDayEvent(e.target.checked);
                                                            }} className="me-2" />
                                                            <FormCheck.Label>{t("header.ToolsEventCalModEventDayCheck")}</FormCheck.Label>
                                                        </FormCheck>
                                                    </Col>
                                                    <Col md={6} className="mb-2">
                                                        <FormCheck type="checkbox" className="inbox-check me-2">
                                                            <FormCheck.Input checked={showEndTime ? true : false} disabled={tbcEvent ? true : false} value={showEndTime} onChange={(e) => {
                                                                setShowEndTime(e.target.checked);
                                                            }} className="me-2" />
                                                            <FormCheck.Label>{t("header.ToolsEventCalModEventEndTimeCheck")}</FormCheck.Label>
                                                        </FormCheck>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={6} className="mb-2">
                                                        <FormCheck type="checkbox" className="inbox-check me-2">
                                                            <FormCheck.Input checked={silentEvent ? true : false} disabled={tbcEvent ? true : false} value={silentEvent} onChange={(e) => {
                                                                setSilentEvent(e.target.checked);
                                                            }} className="me-2" />
                                                            <FormCheck.Label>{t("header.ToolsEventCalModEventSilentCheck")}</FormCheck.Label>
                                                        </FormCheck>
                                                    </Col>
                                                    <Col md={6}>
                                                        <Form.Group>
                                                            <FormCheck type="switch">
                                                                <FormCheck.Input
                                                                    checked={active ? true : false}
                                                                    type="checkbox" id="billingSwitch" className="me-2" name="toggle"
                                                                    value={active}
                                                                    onChange={(e) => {
                                                                        setActive(e.target.checked);
                                                                    }}
                                                                />
                                                                <FormCheck.Label>{active ? t("header.ToolsEventCalModEventActivateSwitch") : t("header.ToolsEventCalModEventActivateSwitch")} </FormCheck.Label>
                                                            </FormCheck>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col md={3}>
                                                <Form.Label>{t("header.ToolsEventCalModEventCategories")}</Form.Label>
                                            </Col>
                                            <Col md={6}>
                                                {
                                                    categories && Array.isArray(categories) && categories.length > 0
                                                    && categories.map((category, index) => (
                                                        <FormCheck type="checkbox" className="inbox-check me-2">
                                                            <FormCheck.Input checked={category.isChecked} onChange={(e) => { onCategoryItemChange(e, index) }} value={category.isChecked} className="me-2" />
                                                            <FormCheck.Label>{category?.CategoryName}</FormCheck.Label>
                                                        </FormCheck>
                                                    ))
                                                }
                                            </Col>
                                        </Row>
                                        <Row ref={eventRef}>
                                            <Col md={12}>
                                                <h5>{t("header.ToolsEventCalModEventDetailsTitle")}</h5>
                                                <h6>{t("header.ToolsEventCalModEventModLanguageTitle")}</h6>
                                            </Col>
                                        </Row>
                                        <Row className="mt-2 mb-2">
                                            <Col md={3}>
                                                <Form.Label>{t("header.AlertAddSubscriberFormLanguage")}</Form.Label>
                                            </Col>
                                            <Col md={6}>
                                                <DropdownWithIconsComponent
                                                    dropdownData={language}
                                                    arrayValue="DisplayName"
                                                    primaryIcon="none"
                                                    primaryIconType="icon"
                                                    primaryIconCustom={false}
                                                    selectedDropdown={selectedLanguage}
                                                    onChange={(data) => {
                                                        onChangeLanguage(data)
                                                    }}
                                                    showLabel={false}
                                                    showIconInDropdownItem={false}
                                                    isPrimaryImage={true}
                                                    customClassName="full-width"
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="mt-2 mb-2">
                                            <Col md={3}>
                                                <Form.Label>{t("header.ToolsEventCalModEventTitle")} <span className="alert-msg-danger">*</span></Form.Label>
                                            </Col>
                                            <Col md={6}>
                                                <Form.Control isInvalid={titleErr ? true : false} onChange={(e) => { setTitle(e.target.value) }} type="text" name="username" value={title} autoComplete="off" />
                                                <Form.Control.Feedback type="invalid">
                                                    {titleErr}
                                                </Form.Control.Feedback>
                                            </Col>
                                        </Row>
                                        <Row className="mt-2 mb-2">
                                            <Col md={3}>
                                                <Form.Label>{t("header.ToolsEventCalModEventDesc")}</Form.Label>
                                            </Col>
                                            <Col md={6}>
                                                <Form.Control onChange={(e) => { setDesc(e.target.value) }} className="mb-1" as="textarea" rows="3" maxLength="" name="address1" value={desc} />
                                            </Col>
                                        </Row>
                                        <Row className="mt-2 mb-2">
                                            <Col md={3}>
                                                <Form.Label>{t("header.ToolsEventCalModEventLink")}</Form.Label>
                                            </Col>
                                            <Col md={6}>
                                                <Form.Control isInvalid={linkErr ? true : false} onChange={(e) => { setLink(e.target.value) }} type="text" name="username" value={link} autoComplete="off" />
                                                <Form.Control.Feedback type="invalid">
                                                    {linkErr}
                                                </Form.Control.Feedback>
                                            </Col>
                                        </Row>
                                        <Row className="mt-2 mb-2">
                                            <Col md={3}>
                                                <Form.Label>{t("header.ToolsEventCalModEventWCUrl")}</Form.Label>
                                            </Col>
                                            <Col md={6}>
                                                <Form.Control isInvalid={webcastUrlErr ? true : false} onChange={(e) => { setWebcastUrl(e.target.value) }} type="text" name="username" value={webcastUrl} autoComplete="off" />
                                                <Form.Control.Feedback type="invalid">
                                                    {webcastUrlErr}
                                                </Form.Control.Feedback>
                                            </Col>
                                        </Row>
                                        <Row className="mt-2 mb-2">
                                            <Col md={3}>
                                                <Form.Label>{t("header.ToolsEventCalEventListLocation")}</Form.Label>
                                            </Col>
                                            <Col md={6}>
                                                <Form.Control isInvalid={locationErr ? true : false} onChange={(e) => { setLocation(e.target.value) }} type="text" name="username" value={location} autoComplete="off" />
                                                <Form.Control.Feedback type="invalid">
                                                    {locationErr}
                                                </Form.Control.Feedback>
                                            </Col>
                                        </Row>
                                        <Row className="mt-2 mb-4">
                                            <Col md={3}>
                                                <Form.Label>{t("header.ToolsEventCalModEventActiveCheck")}</Form.Label>
                                            </Col>
                                            <Col md={6}>
                                                <FormCheck type="checkbox" className="inbox-check mt-1 me-2">
                                                    <FormCheck.Input
                                                        checked={edActive ? edActive : false}
                                                        onChange={(e) => {
                                                            setEdActive(e.target.checked)
                                                        }} className="me-2" value={edActive} />
                                                    <FormCheck.Label></FormCheck.Label>
                                                </FormCheck>
                                            </Col>
                                        </Row>
                                        <Row ref={attachmentRef}>
                                            <Col md={12}>
                                                <h6>{t("header.ToolsEventCalModEventAttachments")}</h6>
                                            </Col>
                                        </Row>
                                        <Row className="mt-2 mb-4">
                                            <Col md={3}>
                                                <Form.Label>{!selectedAttachment ? t("header.ToolsEventCalModEventSelFile") : t("header.addEventsUploadedFile")}</Form.Label>
                                            </Col>
                                            <Col md={6} className="file-upload">
                                                {!selectedAttachment && <Form.Control ref={fileInputRef} type="file" isInvalid={fileErr ? true : false} onChange={(e) => onFileUpload(e)} />}
                                                {selectedAttachment && <small className="alert-msg-warning">{selectedAttachment.originalName}</small>}
                                                <small className="alert-msg-danger"> {fileErr} </small>
                                                {!selectedAttachment && <p className="mt-1 mb-0">{t("header.addEventsValidFileFormates")} <br />{t("header.addEventsValidFileSize")}</p>}
                                            </Col>
                                        </Row>
                                        <Row className="mt-2 mb-2">
                                            <Col md={3}>
                                                <Form.Label>{t("header.ToolsEventCalModEventDisplayName")}</Form.Label>
                                            </Col>
                                            <Col xs={8} md={5}>
                                                {selectedAttachment ? <Form.Control isInvalid={fileDisplayNameErr ? true : false} onChange={(e) => {
                                                    setFileDisplayName(e.target.value);

                                                }} type="text" name="username" value={fileDisplayName} autoComplete="off" /> :
                                                    <Form.Control isInvalid={fileDisplayNameErr ? true : false} onChange={(e) => {
                                                        setFileDisplayName(e.target.value);

                                                    }} type="text" name="username" value={fileDisplayName} autoComplete="off" />}
                                                <small className="alert-msg-danger"> {fileDisplayNameErr} </small>
                                            </Col>
                                            <Col xs={4} md={4} className="ps-0">
                                                <ButtonComponent
                                                    isIcon={false}
                                                    isPrimary={false}
                                                    btnText={t("header.ToolsEventCalModEventUpload")}
                                                    btn_size="sm mt-1"
                                                    onClick={(e) => onHandleFileUpload(e)}
                                                />
                                                {selectedAttachment && <ButtonComponent
                                                    isIcon={false}
                                                    isPrimary={false}
                                                    btnText={t("header.ToolsEventCalModEventButtonCancel")}
                                                    btn_size="sm ms-2 mt-1"
                                                    onClick={() => {
                                                        setSelectedAttachment(undefined);
                                                        setSelectedAttachment(undefined);
                                                        setFileDisplayName("");
                                                        setFileOtherData(undefined);
                                                        setFileFormData("");
                                                    }}
                                                />}
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={12}>
                                                <Alert
                                                    variant="success"
                                                    show={attachmentSuccessMsg ? true : false}
                                                    onClose={() => onClose("danger")}
                                                >
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            <FontAwesomeIcon icon={faCheckCircle} className="me-2" />
                                                            {attachmentSuccessMsg}
                                                        </div>
                                                    </div>
                                                </Alert>
                                            </Col>
                                        </Row>
                                        {attachmentTableData && Array.isArray(attachmentTableData) && attachmentTableData.filter(x => x.languageCode == selectedLanguage.LanguageCode).length > 0 &&
                                            <table className='table table-striped'>
                                                <thead>
                                                    <th></th>
                                                    <th>{t("header.ToolsEventCalModEventAction")}</th>
                                                </thead>
                                                <tbody>
                                                    {
                                                        attachmentTableData && Array.isArray(attachmentTableData) && attachmentTableData.filter(x => x.languageCode == selectedLanguage.LanguageCode).length > 0 &&
                                                        attachmentTableData.filter(x => x.languageCode == selectedLanguage.LanguageCode).map((item, index) => (
                                                            <tr>
                                                                <td>
                                                                    <Card.Link onClick={(e) => { onAttachmentItemClick(e, item) }}>
                                                                        {item.fileName}
                                                                    </Card.Link>
                                                                </td>
                                                                <td>
                                                                    <Card.Link onClick={(e) => { onDeleteAttachment(e, index) }} title='Delete' className={"mb-sm-3 mb-md-0 px-0"}>
                                                                        {" "}
                                                                        <FontAwesomeIcon icon={faTrash} />
                                                                    </Card.Link>
                                                                </td>
                                                            </tr>
                                                        ))
                                                    }
                                                </tbody>
                                            </table>
                                        }
                                        <Row>
                                            <Col md={6}>
                                                <Alert
                                                    variant="danger"
                                                    show={isFormValid}
                                                    onClose={() => onClose("danger")}
                                                >
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            <FontAwesomeIcon icon={faTimesCircle} className="me-2" />
                                                            {t("header.validationOccured")}
                                                        </div>
                                                    </div>
                                                </Alert>
                                            </Col>
                                        </Row>
                                        <Row className="mt-4 mb-2">
                                            <Col md={12} className='text-center'>
                                                <ButtonComponent
                                                    isIcon={false}
                                                    isPrimary={true}
                                                    btnText={t("header.ToolsEventCalModEventSaveDetails")}
                                                    btn_size="md"
                                                    onClick={(e) => onSaveDetails(e)}
                                                />
                                            </Col>
                                        </Row>
                                        {
                                            eventTableData && Array.isArray(eventTableData) && eventTableData.length > 0 ?
                                                <table className='table table-striped'>
                                                    <thead>
                                                        <th>{t("header.ToolsEventCalModEventAddedEvents")}</th>
                                                        <th>{t("header.ToolsEventCalModEventAddedEventsLang")}</th>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            eventTableData && Array.isArray(eventTableData) && eventTableData.length > 0 &&
                                                            eventTableData.map((item, index) => (
                                                                <tr>
                                                                    <td><Card.Link onClick={(e) => { onEventTableItemClick(e, item) }}>{item.eventName}</Card.Link></td>
                                                                    <td>
                                                                        {
                                                                            item.language ? item.language.DisplayName : "-"
                                                                        }
                                                                    </td>
                                                                </tr>
                                                            ))
                                                        }
                                                    </tbody>
                                                </table> : <></>
                                        }
                                        {
                                            eventTableData && eventTableData.length == 0 ? <p className='text-center no-data position-relative'>{t("header.ToolsEventCalModEventNoAddedEvents")}</p> : <></>
                                        }
                                        <Row>
                                            <Col md={6}>
                                                <Alert
                                                    variant="danger"
                                                    show={genericErr ? true : false}
                                                    onClose={() => onClose("danger")}
                                                >
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            <FontAwesomeIcon icon={faTimesCircle} className="me-2" />
                                                            {genericErr}
                                                        </div>
                                                    </div>
                                                </Alert>
                                            </Col>
                                        </Row>
                                        <Row className="mt-4 mb-2">
                                            <Col md={12} className="text-center">
                                                <ButtonComponent
                                                    isIcon={false}
                                                    isPrimary={true}
                                                    btnText={t("header.ToolsEventCalModEventSave")}
                                                    btn_size="md me-3"
                                                    onClick={(e) => onSaveEvent(e)}
                                                />
                                            </Col>
                                        </Row>
                                    </Form>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    {/* fields ends here */}
                </>
            }
        </>
    );
};

AddEventPages.propTypes = {
    formData: PropTypes.object,
    formError: PropTypes.object,
    setFormData: PropTypes.func,
    isFormSubmit: PropTypes.bool,
    hobbiesData: PropTypes.array,
    validateFormData: PropTypes.func,
    onSubmit: PropTypes.func,
    maxCharacters: PropTypes.number,
    setMaxCharacters: PropTypes.func
};

AddEventPages.defaultProps = {
    formData: {},
    formError: {},
    setFormData: null,
    isFormSubmit: false,
    hobbiesData: [],
    validateFormData: null,
    onSubmit: null,
    maxCharacters: 0,
    setMaxCharacters: null
}

export default AddEventPages;