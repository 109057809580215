import React, { useEffect, useContext, useState } from "react";
import { useTranslation } from 'react-i18next';
import { Row, Col, Button } from "@themesberg/react-bootstrap";

import request from "../apis/request";
import { baseURL, METHODS } from "../apis/utilities/constant";
import { decryptData } from "../utils/crypto";

import ButtonsComponent from "./Buttons";
import ModalComponent from "../components/VoltModal";
import AppContext from '../components/AppContext';

const YourInvestisTeam = (props) => {

  const [t, i18n] = useTranslation();
  const contextData = useContext(AppContext);

  const [myInvestisTeamData, setMyInvestisTeamData] = useState([]);
  const [viewMore, setViewMore] = useState(false);
  const [viewMoreModal, setViewMoreModal] = useState(false);

  let {
    setShowBlurLoader
  } = props;

  useEffect(() => {
    getMyInvestisData();
  }, [contextData.companyId, contextData.userId]);

  const getMyInvestisData = () => {
    let companyId = contextData.companyId;
    let userId = contextData.userId;
    setShowBlurLoader && setShowBlurLoader(true);
    if (companyId > 0) {
      setMyInvestisTeamData([]);
      request(baseURL + `/ServiceCentre/GetInvestisTeamByCompany?CompanyId=${companyId}&userId=${userId}`,
        METHODS.GET,
        {},
        {
          Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
        }
      )
        .then((res) => {
          setMyInvestisTeamData(res?.data);
          setShowBlurLoader && setShowBlurLoader(false);
        })
        .catch((err) => {
          setMyInvestisTeamData([]);
          setShowBlurLoader && setShowBlurLoader(false);
        })
    }
  }

  return (
    <>
      <ModalComponent
        open={viewMoreModal}
        title="Your IDX team"
        handleClose={() => { setViewMoreModal(false) }}
        showCloseButton={true}>
        <Row className="mb-2">
          {
            myInvestisTeamData && Array.isArray(myInvestisTeamData) && myInvestisTeamData.length > 0 && myInvestisTeamData.map((item, index) => (
              <>

                <Col xs={12} sm={6} Col={6}>
                  <p className="fw-bold mb-2 text-capitalize">{item["InvestisDigitalTeam.Name"]}</p>
                  <p className="investis-team">
                    {item["InvestisDigitalTeam.Role"]}
                    <br />
                    <a href={"mailto:" + item["InvestisDigitalTeam.EmailLink"]}>{item["InvestisDigitalTeam.Email"]}</a>
                    <br />
                    <a href="tel:{item.phoneno}" className="phone fw-semi-bold">
                      {item["InvestisDigitalTeam.Phone"]}
                    </a>
                  </p>
                </Col>
              </>
            ))
          }
        </Row>
        <Row className="mt-4 mb-2">
          <Col>
            <ButtonsComponent
              isIcon={false}
              isPrimary={false}
              btnText={t("header.yourInvestisCloseButtonText")}
              onClick={() => { setViewMoreModal(false) }}
              btn_class="mb-0" />
          </Col>
        </Row>
      </ModalComponent>
      <Row>
        <Col xs={12}>
          <div className="card-custom-title mb-0 fw-bold">
            {t("header.HeaderMyAccountTitle")}
          </div>
        </Col>
      </Row>
      <Row className="mt-3">
        {
          myInvestisTeamData && Array.isArray(myInvestisTeamData) && myInvestisTeamData.length > 0 && myInvestisTeamData.map((item, index) => (
            <>
              {index < 4 &&
                <Col xs={12} sm={6} Col={6}>
                  <p className="fw-bold mb-2 text-capitalize">{item["InvestisDigitalTeam.Name"]}</p>
                  <p className="investis-team">
                    {item["InvestisDigitalTeam.Role"]}
                    <br />
                    <a href={"mailto:" + item["InvestisDigitalTeam.Email"] + "?subject=Query for Service Centre&body="}>{item["InvestisDigitalTeam.Email"]}</a>
                    <br />
                    <a href="tel:{item.phoneno}" className="phone fw-semi-bold">
                      {item["InvestisDigitalTeam.Phone"]}
                    </a>
                  </p>
                </Col>}
            </>
          ))
        }
        {
          viewMore && myInvestisTeamData && Array.isArray(myInvestisTeamData) && myInvestisTeamData.length > 0 && myInvestisTeamData.map((item, index) => (
            <>
              {index >= 4 &&
                <Col xs={12} sm={6} Col={6}>
                  <p className="fw-bold mb-2">{item["InvestisDigitalTeam.Name"]}</p>
                  <p className="investis-team">
                    {item["InvestisDigitalTeam.Role"]}
                    <br />
                    <a href={"mailto:" + item["InvestisDigitalTeam.Email"] + "?subject=Query for Service Centre&body="}>{item["InvestisDigitalTeam.Email"]}</a>
                    <br />
                    <a href="tel:{item.phoneno}" className="phone fw-semi-bold">
                      {item["InvestisDigitalTeam.Phone"]}
                    </a>
                  </p>
                </Col>}
            </>
          ))
        }
        {
          myInvestisTeamData && Array.isArray(myInvestisTeamData) && myInvestisTeamData.length > 0 && myInvestisTeamData.length > 4 ?
            <Row>
              <Col className="text-center">
                <Button onClick={() => { setViewMore(!viewMore) }} variant="primary" className="me-2">
                  {viewMore ? t("header.viewLessLabel") : t("header.loadMoreLabel")}
                </Button>
              </Col>
            </Row> :
            <></>
        }
      </Row>
    </>
  );

};
export default YourInvestisTeam;
