import React, { useState, useEffect, useContext } from 'react';
import RGL, { WidthProvider } from 'react-grid-layout';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import '../i18n';
import Datetime from "react-datetime";
import moment from "moment";
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { Row, Col, Form, InputGroup } from '@themesberg/react-bootstrap';

import { DEFAULT_WEBSITE_DASHBORD, DEFAULT_PROFILE_DASHBORD } from "../AppConfig";
import { dashbordDateRangeList } from "../utils/datetime";

import DropdownWithIconComponent from "../components/DropdownWithIcons";
import ButtonComponent from "../components/Buttons";
import AppContext from '../components/AppContext';
import Loader from '../components/VoltLoader';

const ReactGridLayout = WidthProvider(RGL);

const Dashboard = ({ children, dashboardItems }) => {
  const [t, i18n] = useTranslation();
  const contextData = useContext(AppContext);

  const [selectedWebsiteId, setSelectedWebsiteId] = useState({});
  const [selectedProfileId, setSelectedProfileId] = useState({});
  const [showCustomDatePicker, setShowCustomDatePicker] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [datePickerValue, setDatePickerValue] = useState("");
  const [selectedDatePicker, setSelectedDatePicker] = useState({});
  const [datePickerCompareValue, setDatePickerCompareValue] = useState("");

  useEffect(() => {
    const lastThirtyDays = `${moment().add(-30, 'days').format("MMM DD,YYYY")} - ${moment(new Date()).format("MMM DD,YYYY")}`;
    const lastSixtyDays = `${moment().add(-60, 'days').format("MMM DD,YYYY")} - ${moment(new Date()).format("MMM DD,YYYY")}`;
    const getDropdownPickerDetails = dashbordDateRangeList.find(dta => dta.key === lastThirtyDays);
    setSelectedDatePicker(getDropdownPickerDetails);
    setDatePickerValue(lastThirtyDays);
    setDatePickerCompareValue(lastSixtyDays);
    setSelectedWebsiteId(DEFAULT_WEBSITE_DASHBORD);
    setSelectedProfileId(DEFAULT_PROFILE_DASHBORD);
  }, []);

  useEffect(() => {
    if (startDate) {
      setDefaultPickerValueOnDateChange("startDate");
    }
  }, [startDate]);

  useEffect(() => {
    if (endDate) {
      setDefaultPickerValueOnDateChange("endDate");
    }
  }, [endDate]);

  useEffect(() => {
    if (contextData.userId > 0 && contextData.companyId > 0) {
      const websiteId = {
        "WebsiteList.WebsiteId": 0,
        "WebsiteList.WebsiteName": "Select"
      };
      setSelectedWebsiteId(websiteId);
      setSelectedProfileId({});
    }
  }, [contextData.userId, contextData.companyId]);

  useEffect(() => {
    renderWebsiteProfiles(selectedWebsiteId);
  }, [selectedWebsiteId]);

  const onProfileDropdownChange = (data) => {
    const intellConfigId = data["WebsiteProfiles.IntelConfigId"];
    contextData.changeIntelConfigId(intellConfigId);
    setSelectedProfileId(data);
  };

  const setDefaultPickerValueOnDateChange = (dateType) => {
    const datePickerSplit = datePickerValue.split("-");
    let datePickerVle;
    if (dateType == "startDate") {
      datePickerVle = `${moment(startDate).format("MMM DD,YYYY")} - ${moment(datePickerSplit[1]).format("MMM DD,YYYY")}`;
      setDtPickerCompareValueOnDatePickerChange(startDate, moment(datePickerSplit[1]));
    }
    else if (dateType == "endDate") {
      datePickerVle = `${moment(datePickerSplit[0]).format("MMM DD,YYYY")} - ${moment(endDate).format("MMM DD,YYYY")}`;
      setDtPickerCompareValueOnDatePickerChange(moment(datePickerSplit[0]), endDate);
    }
    setDatePickerValue(datePickerVle);
  };

  const setDtPickerCompareValueOnDatePickerChange = (startDate, endDate) => {
    let diffDays = endDate.diff(startDate, 'days');
    let previousEndDate = moment(startDate).subtract(1, 'days').format("MMM DD,YYYY");
    let previousStartDate = moment(previousEndDate).subtract(diffDays, 'days').format("MMM DD,YYYY");
    let datePickerCmpr = `${previousStartDate} - ${previousEndDate}`;
    setDatePickerCompareValue(datePickerCmpr);
  };

  const onLayoutChange = (newLayout) => {
    newLayout.forEach((l) => {
      const item = dashboardItems.find((i) => i.id.toString() === l.i);
      const toUpdate = JSON.stringify({
        x: l.x,
        y: l.y,
        w: l.w,
        h: l.h,
      });
    });
  };

  const renderWebsiteProfiles = ({ resultSet, error, pivotConfig, request }) => {
    if (error) {
      return <div>{error.toString()}</div>;
    }

    if (!resultSet) {
      return <Loader show={false} />;
    }
    return (
      <DropdownWithIconComponent
        dropdownData={resultSet.tablePivot(pivotConfig)}
        arrayValue="WebsiteProfiles.DisplayName"
        selectedDropdown={Object.keys(selectedProfileId).length > 0 ? selectedProfileId : resultSet.tablePivot(pivotConfig)[0]}
        onChange={onProfileDropdownChange}
        showLabel={false}
        showIconInDropdownItem={false}
      />
    );
  };

  const onDatePickerDropdownChange = (data) => {
    setStartDate("");
    setEndDate("");
    setDatePickerCompareValue("");
    if (data && Object.keys(data).length > 0) {
      if (data.compareOn) {
        const datePickerSplit = data.key.split("-");
        const compareOnStartDate = moment(datePickerSplit[0]).add(-data.compareOn, 'days').format("MMM DD,YYYY");
        const compareOnEndDate = moment(datePickerSplit[1]).add(-data.compareOn, 'days').format("MMM DD,YYYY");
        const compareOnValue = `${compareOnStartDate} - ${compareOnEndDate}`;
        setDatePickerCompareValue(compareOnValue);
      }
      if (data.showDatePicker)
        setShowCustomDatePicker(true);
      else
        setShowCustomDatePicker(false);
    }
    setDatePickerValue(data.key);
    setSelectedDatePicker(data)
  };

  const validStartDate = (current) => {
    let currentDay = moment(endDate).format("MM/DD/YYYY");
    return current.isBefore(currentDay);
  };

  return (
    <>
      <Row className="align-items-center mt-3 mb-3">
        <Col>
          <h3>{t("header.WOWebsiteInsightsMainTab")}</h3>
        </Col>
        <Col className="text-end">
          <ButtonComponent
            isIcon={false}
            isPrimary={true}
            btnText="Edit Website" />
        </Col>
      </Row>
      <Row className="justify-content-md-center">
        <Col xs={12} sm={6} xl={4} className="mb-4">
        </Col>
        {(selectedWebsiteId && Object.keys(selectedWebsiteId).length > 0) &&
          <Col xs={12} sm={6} xl={4} className="mb-4">
          </Col>
        }
        <Col xs={12} sm={6} xl={4} className="mb-4">
          <DropdownWithIconComponent
            dropdownData={dashbordDateRangeList}
            arrayValue="value"
            selectedDropdown={selectedDatePicker}
            onChange={onDatePickerDropdownChange}
            showLabel={false}
            showIconInDropdownItem={false}
          />
          {showCustomDatePicker &&
            <Form>
              <Form.Group className="mb-3">
                <Datetime
                  timeFormat={false}
                  closeOnSelect={false}
                  onChange={setStartDate}
                  isValidDate={validStartDate}
                  renderInput={(props, openCalendar) => (
                    <InputGroup>
                      <Form.Control
                        required
                        type="text"
                        value={startDate ? moment(startDate).format("MMM DD,YYYY") : ""}
                        placeholder={t("header.startDatePlaceHolder")}
                        onFocus={openCalendar}
                        onChange={() => { }} />
                      <InputGroup.Text><FontAwesomeIcon icon={faCalendarAlt} /></InputGroup.Text>
                    </InputGroup>
                  )} />
                <Datetime
                  timeFormat={false}
                  closeOnSelect={false}
                  onChange={setEndDate}
                  renderInput={(props, openCalendar) => (
                    <InputGroup>
                      <Form.Control
                        required
                        type="text"
                        value={endDate ? moment(endDate).format("MMM DD,YYYY") : ""}
                        placeholder={t("header.endDatePlaceHolder")}
                        onFocus={openCalendar}
                        onChange={() => { }} />
                      <InputGroup.Text><FontAwesomeIcon icon={faCalendarAlt} /></InputGroup.Text>
                    </InputGroup>
                  )} />
              </Form.Group>
            </Form>
          }
        </Col>
      </Row>
      <div className="d-block mb-4 mb-md-0">
        <center>
          <p className="mb-0">
            <small>  {t("header.InsightDateLabel")}: {datePickerValue} </small>
            <small>  {t("header.WOComparedToText")} {datePickerCompareValue} </small>
          </p>
        </center>
      </div>
      {/* Note:  ReactGridLayout 's 
      isDraggable is for the Card Draggable
     isResizable is for the Card Resizing  */}
      <ReactGridLayout cols={12} rowHeight={50}
        isDraggable={false} isResizable={false}
        onLayoutChange={onLayoutChange}>
        {children}
      </ReactGridLayout>
    </>
  );
};

export default Dashboard;
