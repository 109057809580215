import React, { useState, useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import {
    Row,
    Col,
    Dropdown,
    ButtonGroup,
    Form,
    FormCheck
} from "@themesberg/react-bootstrap";

import iconSprite from "../../assets/Icon/svg-icn-sprite.svg";
import { convertBase64 } from "../../utils/common";
import { baseURL, METHODS } from '../../apis/utilities/constant';
import request, { requestPDF } from '../../apis/request';
import { decryptData } from '../../utils/crypto';
import { DataStatusMessage, WebcastHeaders } from "../../utils/constant";

import CommonMessageComponent from "../CommonMessageComponent";
import DataTableRenderer from "../DataTableRenderer";
import WebcastFilters from "./WebcastFilters";
import Search from "../../components/Search";

const WebcastQuestions = ({ hasEvents, webcastType, cryptoKey, filteredPresentation, setShowLoader, setAlertVisible, masterQuestions, setMasterQuestions, appliedQuestionFilter, setAppliedQuestionFilter, onPageRefresh }) => {
    const [t] = useTranslation();

    const [searchData, setSearchData] = useState("");
    const [isAutoRefreshQuestions, setIsAutoRefreshQuestions] = useState(true);

    const [questionFilter, setQuestionFilter] = useState({
        status: { value: "", label: "All" }
    });

    const [openDropdown, setOpenDropdown] = useState(null); // Tracks open dropdown
    const dropdownRef = useRef(null);

    const [questions, setQuestions] = useState({ transactionStatus: DataStatusMessage.Loading });

    useEffect(() => {
        if (masterQuestions.transactionStatus !== DataStatusMessage.Loading) {
            if (masterQuestions.transactionStatus === DataStatusMessage.DataAvailable && masterQuestions.data.length > 0) {
                let data = masterQuestions.data;
                let header = hasEvents === 1 ? WebcastHeaders.QuestionsVETable : WebcastHeaders.QuestionsILTable;
                if (appliedQuestionFilter.status && appliedQuestionFilter.status.value !== "" && hasEvents === 1) {
                    data = data.filter(x => x.QuestionApprovedFor.toLocaleLowerCase() === appliedQuestionFilter.status.value.toLocaleLowerCase());
                }

                if (searchData !== "") {
                    data = data.filter(x => x.Email.toLocaleLowerCase().includes(searchData.toLocaleLowerCase()) || x.Question.toLocaleLowerCase().includes(searchData.toLocaleLowerCase()));
                }
                setQuestions({ header: header, transactionStatus: DataStatusMessage.DataAvailable, data: data, count: data.length });
            }
            else {
                setQuestions({ transactionStatus: DataStatusMessage.DataUnAvailable });
            }
        }
        else {
            setQuestionsData(true);
        }
        // masterQuestions is used here to refresh Questions data when we applied search 
        // or filter along with autorefresh on 
    }, [searchData, appliedQuestionFilter, masterQuestions]);

    useEffect(() => {
        const intervalId = setInterval(() => {
            if (isAutoRefreshQuestions) {
                setSearchData("");
                setQuestionsData(false);
            }
        }, 10000);
        return () => clearInterval(intervalId);
    }, [isAutoRefreshQuestions]);

    useEffect(() => {
        if (onPageRefresh !== 0) {
            setMasterQuestions({ transactionStatus: DataStatusMessage.Loading });
            setSearchData("");
            setQuestionFilter({
                status: { value: "", label: "All" }
            });
            setAppliedQuestionFilter({
                status: { value: "", label: "All" }
            });
        }
    }, [onPageRefresh])

    const setQuestionsData = (showLoading) => {
        if (showLoading) {
            setQuestions({ transactionStatus: DataStatusMessage.Loading });
        }
        let url = baseURL + (hasEvents === 1 ? `/Analytics/GetInvestisLiveQuetions?clientSlug=${filteredPresentation["Presentations.clientslug"]}&presentationId=${filteredPresentation["Presentations.id"]}&presentationSlug=${filteredPresentation["Presentations.slug"]}&skip=0&providerName=InvestisLive&searchString=&clienttype=VE&approvedFor=` : `/WebcastV2/GetQuestionIL?presentationId=${filteredPresentation["Presentations.id"]}`);
        request(url,
            METHODS.GET,
            {},
            {
                Authorization: `Bearer ${decryptData(localStorage.getItem("token"), cryptoKey)}`, "content-type": "application/json"
            })
            .then((res) => {
                setMasterQuestions({ data: res.data, transactionStatus: DataStatusMessage.DataAvailable });
            })
            .catch((err) => {
                setMasterQuestions({ transactionStatus: DataStatusMessage.DataUnAvailable });
                console.error("Error: " + err);
            })
    };

    const onRefresh = () => {
        setSearchData("");
        let filters = { status: { value: "", label: "All" } };
        setQuestionFilter(filters);
        setQuestionsData(true);
    };

    const setDowloadDropdownMenu = () => {
        let ddList = ["Excel", "PDF"];
        return ddList.map((item) => {
            return (
                <Dropdown.Item
                    className="dropdown-item-download"
                    onClick={() => downloadGrid(item)}
                >
                    {item}
                </Dropdown.Item>
            );
        });
    };

    const downloadGrid = (item) => {
        if (item === "Excel") {
            downloadExcel();
        } else {
            downloadPDF();
        }
    };

    const downloadExcel = async () => {
        setShowLoader(true);
        let url = "";
        let paramsList = {};

        if (hasEvents === 1) {
            let providerName = ""
            if (webcastType === 1) {
                providerName = webcastTypeEnum.LIVE;
            }
            else if (webcastType === 2) {
                providerName = webcastTypeEnum.CHORUS;
            }
            else {
                providerName = webcastTypeEnum.METRO;
            }

            paramsList = {
                presentationName: filteredPresentation["Presentations.name"],
                clientSlug: filteredPresentation["Presentations.clientslug"],
                presentationId: filteredPresentation["Presentations.id"],
                presentationSlug: filteredPresentation["Presentations.slug"],
                skip: 0,
                providerName: providerName,
                companyName: JSON.parse(decryptData(localStorage.getItem("selectedCompanyData"), cryptoKey))["companyName"],
                culture: localStorage.getItem('i18nextLng') ? localStorage.getItem('i18nextLng') : 'en-US'
            }
            url = baseURL + `/Website/ExportInvestisLiveQuestionsUsingEPPlus?encodeStr=${convertBase64(JSON.stringify(paramsList))}`;
        }
        else {
            url = baseURL + `/WebcastV2/GetQuestionIL?presentationId=${filteredPresentation["Presentations.id"]}&isExport=true&presentationName=${filteredPresentation["Presentations.name"]}&companyName=${JSON.parse(decryptData(localStorage.getItem("selectedCompanyData"), cryptoKey))["companyName"]}`
        }
        request(`${url}`,
            METHODS.GET,
            {},
            {
                Authorization: `Bearer ${decryptData(localStorage.getItem("token"), cryptoKey)}`,
            })
            .then((res) => {
                if (res) {
                    let fetchDataModified = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${res}`;
                    let a = document.createElement("a");
                    a.href = fetchDataModified;
                    a.download = 'Webcast_Statistics_' + filteredPresentation["Presentations.name"] + '.xlsx';
                    a.click();
                }
                setShowLoader(false);
            })
            .catch((err) => {
                setShowLoader(false);
                console.error('Error generating Excel:', err);
            })
    };

    const downloadPDF = async () => {
        setShowLoader(true);
        let fileName = "";
        let filters = "";
        let obj = {};

        let url = baseURL + (hasEvents === 1 ? `/Analytics/GetInvestisLiveQuetions?clientSlug=${filteredPresentation["Presentations.clientslug"]}&presentationId=${filteredPresentation["Presentations.id"]}&presentationSlug=${filteredPresentation["Presentations.slug"]}&skip=0&providerName=InvestisLive&searchString=&clienttype=VE&approvedFor=${questionFilter.status.value}` : `/WebcastV2/GetQuestionIL?presentationId=${filteredPresentation["Presentations.id"]}`);
        if (searchData && searchData !== "") {
            filters = `Email|contains|${searchData}||Question|contains|${searchData}`
        }
        fileName = "Stream_Statistics.pdf";
        obj = {
            Title: "Stream statistics for " + filteredPresentation["Presentations.name"],
            SubTitle: "Client: " + JSON.parse(decryptData(localStorage.getItem("selectedCompanyData"), cryptoKey))["companyName"],
            PageName: "Stream questions",
            CountTitle: "<span class='total-record-count'>{{count}}</span> Total questions",
            Filters: filters,
            ClientName: JSON.parse(decryptData(localStorage.getItem("selectedCompanyData"), cryptoKey))["companyName"],
            Url: url,
            Token: decryptData(localStorage.getItem("token"), cryptoKey),
            FileName: fileName,
            Email: decryptData(localStorage.getItem("email"), cryptoKey),
            Name: decryptData(localStorage.getItem("firstName"), cryptoKey),
            Count: 0,
            FilterContent: "",
            TableHeaders: hasEvents === 1 ? WebcastHeaders.QuestionsVETable : WebcastHeaders.QuestionsILTable
        }
        requestPDF(`${process.env.REACT_APP_API_KEY}/CommonV2/GeneratePdf`,
            METHODS.POST,
            obj,
            {
                Authorization: `Bearer ${decryptData(localStorage.getItem("token"), cryptoKey)}`
            })
            .then((res) => {
                const contentType = res.headers['content-type'];
                if (res.status === 200) {
                    if (contentType?.includes('application/pdf')) {
                        const blob = new Blob([res.data], { type: 'application/pdf' });
                        const url = window.URL.createObjectURL(blob);
                        const a = document.createElement('a');
                        a.href = url;
                        a.download = fileName;
                        document.body.appendChild(a);
                        a.click();
                        window.URL.revokeObjectURL(url);
                    }
                    else {
                        setAlertVisible(true);
                        setTimeout(() => {
                            setAlertVisible(false);
                        }, 3000);
                    }
                    setShowLoader(false);
                }
                else {
                    setShowLoader(false);
                    console.error('Error generating PDF.');
                }
            })
            .catch((err) => {
                setShowLoader(false);
                console.error('Error generating PDF:', err);
            })
    };

    const createFilter = useMemo(
        () => (
            <WebcastFilters
                applyFilters={() => applyQuestionFilter()}
                cancelFilter={() => cancelQuestionFilter()}
                dataSource={[
                    {
                        name: "Publish Status",
                        data: [
                            { value: "", label: "All" },
                            { value: "none", label: "Pending" },
                            { value: "all", label: "Approved for All" },
                            { value: "presenter", label: "Approved for Presenter" },
                        ],
                        selectedItem: questionFilter.status,
                        isVisible: true,
                        label: t("header.webcastQuestionsPublishStatus"),
                        callback: (x) => OnChangeQuestionFilter(x, "status"),
                        displayText: "label",
                    },
                ]}
            ></WebcastFilters>
        ),
        [questionFilter]
    );

    const OnChangeQuestionFilter = (data, filterfor) => {
        let filters = { ...questionFilter, [filterfor]: data };
        setQuestionFilter(filters);
    };

    const applyQuestionFilter = () => {
        setAppliedQuestionFilter(questionFilter);
    };

    const cancelQuestionFilter = () => {
        let filter = { status: { value: "", label: "All" } };
        setAppliedQuestionFilter(filter);
        setQuestionFilter(filter);
    };

    var webcastTypeEnum = {
        LIVE: "InvestisLive",
        CHORUS: "ChorusCall",
        METRO: "Metro"
    };

    const setQuestionStatus = (e, row, index) => {
        setShowLoader(true);
        const updatedTableData = [...masterQuestions.data];
        row["QuestionApprovedFor"] = e.id === 1 ? "0" : "1";
        if (e.id === 2) {
            row["QuestionApproved"] = false;
            row["QuestionApprovedFor"] = "none";
        } else if (e.id === 3) {
            row["QuestionApproved"] = true;
            row["QuestionApprovedFor"] = "all";
        } else if (e.id === 4) {
            row["QuestionApproved"] = true;
            row["QuestionApprovedFor"] = "presenter";
        }
        updatedTableData[index] = row;

        request(baseURL + `/Analytics/SetQuestionStatus?presentationId=${filteredPresentation["Presentations.id"]}&questionId=${row["QuestionId"]}&questionApproved=${!!+row["QuestionApproved"]}&questionApprovedFor=${row["QuestionApprovedFor"]}`,
            METHODS.GET,
            {},
            {
                Authorization: `Bearer ${decryptData(localStorage.getItem("token"), cryptoKey)}`, "content-type": "application/json"
            })
            .then((res) => {
                if (res.isModified) {
                    setMasterQuestions({ ...masterQuestions, data: updatedTableData });
                }
                setShowLoader(false);
            })
            .catch((err) => {
                console.error("Error: " + err);
            })
    };

    const toggleRefreshQuestions = (event) => {
        const { checked } = event.target;
        setIsAutoRefreshQuestions(checked);
    };

    const handleDropdownToggle = (dropdownName) => {
        setOpenDropdown((prev) => (prev === dropdownName ? null : dropdownName));
    };

    const renderSearch = (count) => {
        return (
            <div
                className={
                    count > 0
                        ? "d-inline-block float-end webcast-question-search-container"
                        : "d-inline-block float-end webcast-question-search-container custom-disable"
                }
            >
                <Search
                    setsearchData={setSearchData}
                    searchData={searchData}
                    isAutoComplete={false}
                    type={"server"}
                ></Search>
            </div>
        );
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setOpenDropdown(null);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <>
            <Row className="webcast-questions-card-nav mb-1">
                <Col
                    md={12}
                    className={
                        localStorage.getItem("isDemoClient")
                            ? "custom-disable text-end"
                            : "text-end"
                    }
                >
                    <div className="d-inline-block me-4 float-end">
                        <div className="icon-nav-list svg-icon-nav-list">
                            <div onClick={() => handleDropdownToggle("filter")}
                                className={
                                    masterQuestions.data && masterQuestions.data.length > 0
                                        ? "icon-nav-box hide-mobile"
                                        : "icon-nav-box hide-mobile disabled"
                                }
                            >
                                {hasEvents
                                    ? createFilter
                                    : ""}
                            </div>
                            <span ref={dropdownRef}
                                className={
                                    questions.data && questions.data.length > 0
                                        ? "icon-nav-box hide-mobile"
                                        : "icon-nav-box hide-mobile disabled"
                                }
                            >
                                <Dropdown
                                    title={t(
                                        "header.iconTooltiop_download",
                                        "Download"
                                    )}
                                    as={ButtonGroup}
                                    show={openDropdown === "download"}
                                    onToggle={(isOpen) => handleDropdownToggle("download")}
                                >
                                    <Dropdown.Toggle
                                        split
                                        className="pt-0 card-dropdown-btn"
                                    >
                                        <svg className="icon-nav">
                                            <title>
                                                {t(
                                                    "header.Icon_Tooltip_Download"
                                                )}
                                            </title>
                                            <use
                                                href={
                                                    iconSprite + `#Download`
                                                }
                                            />
                                        </svg>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        {setDowloadDropdownMenu()}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </span>
                            <span className={
                                isAutoRefreshQuestions
                                    ? "icon-nav-box webcast-sync-icon disabled"
                                    : "icon-nav-box webcast-sync-icon"
                            }
                                onClick={(e) => {
                                    onRefresh();
                                }}
                            >
                                <svg className="icon-nav">
                                    <title>
                                        {t("header.Icon_Tooltip_Refresh")}
                                    </title>
                                    <use href={iconSprite + `#Sync`} />
                                </svg>
                            </span>
                        </div>
                    </div>
                    <div className="d-inline-block webcast-question-search-row">
                        <div className="d-inline-block mt-2 pe-3 text-end float-end">
                            <Form.Group className="d-inline-block float-end">
                                <Form.Label className="float-start me-2">
                                    {t(
                                        "header.WebcastInvestisLiveQuestionAutoRefreshText",
                                        "Auto-Refresh"
                                    )}{" "}
                                    :
                                </Form.Label>
                                <FormCheck
                                    type="switch"
                                    className="float-start"
                                >
                                    <FormCheck.Input
                                        checked={isAutoRefreshQuestions}
                                        type="checkbox"
                                        id="billingSwitch"
                                        className="me-2"
                                        name="toggle"
                                        onChange={(e) =>
                                            toggleRefreshQuestions(e)
                                        }
                                    />
                                </FormCheck>
                            </Form.Group>
                        </div>
                        {renderSearch(masterQuestions.data ? masterQuestions.data.length : 0)}
                    </div>
                </Col>
            </Row>
            {questions.transactionStatus ===
                DataStatusMessage.DataAvailable && (
                    <DataTableRenderer
                        rowClickEvent={(e, row, index) =>
                            setQuestionStatus(e, row, index)
                        }
                        header={questions.header}
                        dataSet={questions.data}
                        transactionStatus={
                            questions.transactionStatus
                        }
                        customAttributes={{
                            displayRowsPerPage: 10,
                            showCount: true,
                            summaryCount: questions.count,
                            summaryText: t(
                                "header.WebcastTotalQuestionsText"
                            ),
                            tooltipText: t("header.questionscardTooltipText"),
                            showPagination: true,
                        }}
                    ></DataTableRenderer>
                )}
            {questions.transactionStatus !==
                DataStatusMessage.DataAvailable && (
                    <CommonMessageComponent
                        transactionStatus={
                            questions.transactionStatus
                        }
                    ></CommonMessageComponent>
                )}
        </>
    )
}


export default WebcastQuestions;