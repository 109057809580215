import React, { useEffect, useState } from "react";
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Row, Col, Card, Tooltip, OverlayTrigger, Nav, Tab, Button } from '@themesberg/react-bootstrap';

import request from '../../apis/request.js';
import { baseURL, METHODS } from '../../apis/utilities/constant.js';
import { DataStatusMessage } from "../../utils/constant.js";
import { TnfTableHeaders } from '../../utils/constant.js';

import DataChartRenderer from '../DataChartRenderer.js';
import DataTableRenderer from '../DataTableRenderer.js';
import CommonMessageComponent from '../CommonMessageComponent.js';
import { convertToInternationalFormat, formatCount } from "../../AppConfig.js";

const CorporateAlertService = React.memo(({ profileID, startDate, endDate }) => {
    const [t, i18n] = useTranslation();

    const [summary, setSummary] = useState({ transactionStatus: DataStatusMessage.Loading });
    const [lineChart, setLineChart] = useState({ transactionStatus: DataStatusMessage.Loading });

    const [mapData, setMapData] = useState({ transactionStatus: DataStatusMessage.Loading });
    const [tableDataMap, setTableDataMap] = useState({ transactionStatus: DataStatusMessage.Loading });

    const [tableSubmitButtonClick, setSubmitButtonClick] = useState({ transactionStatus: DataStatusMessage.Loading });
    const [tableAlertServiceSubscribers, setAlertServiceSubscribers] = useState({ transactionStatus: DataStatusMessage.Loading });

    const [tab, setTab] = useState(1);

    useEffect(() => {
        if (summary.transactionStatus !== DataStatusMessage.Loading) {
            setSummary({ transactionStatus: DataStatusMessage.Loading });
            setLineChart({ transactionStatus: DataStatusMessage.Loading });
            setMapData({ transactionStatus: DataStatusMessage.Loading });
            setSubmitButtonClick({ transactionStatus: DataStatusMessage.Loading });
            setAlertServiceSubscribers({ transactionStatus: DataStatusMessage.Loading })
        }
        const selectedStartDate = moment(startDate).format("YYYY-MM-DD");
        const selectedEndDate = moment(endDate).format("YYYY-MM-DD");
        request(
            baseURL + `/TnfAnalytics/GetGA4DataTnfComponents?StartDate=${selectedStartDate}&EndDate=${selectedEndDate}&profileID=${profileID}&Matrix=activeUsers,eventCount,eventCountPerUser,eventValue,screenPageViews,screenPageViewsPerUser,totalUsers,userEngagementDuration&Dimensions=eventName,pageTitle&EventName=corporate_alert_service_Interactions&Component=summary`,
            METHODS.GET,
            {},
            {
                Authorization: `Bearer ${localStorage.getItem("token")}`, "content-type": "application/json"
            }
        ).then((response) => {
            if (response.transactionStatus === DataStatusMessage.DataAvailable) {
                setSummary(response)
            }
            else {
                setSummary({ transactionStatus: response.transactionStatus });
            }
        }).catch(
            (error) => {
                setSummary({ transactionStatus: DataStatusMessage.ErrorOccured });
            }
        );

        request(
            baseURL + `/TnfAnalytics/GetGA4DataTnfComponents?StartDate=${selectedStartDate}&EndDate=${selectedEndDate}&profileID=${profileID}&Matrix=eventCount,eventCountPerUser,eventValue,totalUsers&Dimensions=country,date,eventName&EventName=corporate_alert_service_Interactions&Component=chartandmap`,
            METHODS.GET,
            {},
            {
                Authorization: `Bearer ${localStorage.getItem("token")}`, "content-type": "application/json"
            }
        ).then((response) => {
            if (response.transactionStatus === DataStatusMessage.DataAvailable) {
                setLineChart({ result: response.resultFirst, transactionStatus: response.transactionStatus })
                setMapData({ result: response.resultSecond, transactionStatus: response.transactionStatus });
            }
            else {
                setLineChart({ transactionStatus: response.transactionStatus })
                setMapData({ transactionStatus: response.transactionStatus });
            }
            setTableDataMap({
                header: TnfTableHeaders.MapUserTable, data: response.transactionStatus === DataStatusMessage.DataAvailable ?
                    response.resultSecond : [], transactionStatus: response.transactionStatus
            });

        }).catch(
            (error) => {
                setLineChart({ transactionStatus: DataStatusMessage.ErrorOccured })
                setMapData({ transactionStatus: DataStatusMessage.ErrorOccured });
            }
        );

        request(
            baseURL + `/TnfAnalytics/GetGA4DataTnfComponents?StartDate=${selectedStartDate}&EndDate=${selectedEndDate}&profileID=${profileID}&Matrix=eventCount,totalUsers&Dimensions=customEvent:corporate_alert_service_Submit_Button_Cl,customEvent:corporate_alert_service_Interactions,eventName&EventName=corporate_alert_service_Interactions&Component=customeventtable`,
            METHODS.GET,
            {},
            {
                Authorization: `Bearer ${localStorage.getItem("token")}`, "content-type": "application/json"
            }
        ).then((response) => {

            setSubmitButtonClick({
                header: TnfTableHeaders.CustomEventSearchOptions, data: response.transactionStatus === DataStatusMessage.DataAvailable ?
                    response.resultFirst.data : [], headerSummary: response.resultFirst.headerSummary, transactionStatus: response.transactionStatus
            })

            setAlertServiceSubscribers({
                header: TnfTableHeaders.CustomEventDateSelector, data: response.transactionStatusSecond === DataStatusMessage.DataAvailable ?
                    response.resultSecond.data : [], headerSummary: response.resultSecond.headerSummary, transactionStatus: response.transactionStatusSecond
            });

        }).catch(
            (error) => {
                setSubmitButtonClick({
                    header: TnfTableHeaders.CustomEventSearchOptions,
                    transactionStatus: DataStatusMessage.ErrorOccured
                })
                setAlertServiceSubscribers({
                    header: TnfTableHeaders.CustomEventDateSelector,
                    transactionStatus: DataStatusMessage.ErrorOccured
                });
            }
        );

    }, [profileID, startDate, endDate])


    const createTooltip = (tooltipKey, placement) => {
        if (tooltipKey !== "") {
            return (
                <OverlayTrigger
                    placement={placement}
                    trigger={["hover", "focus"]}
                    overlay={
                        <Tooltip>
                            <div>{tooltipKey}</div>
                        </Tooltip>
                    }
                >
                    <Button variant="white" className="btn-help border-0 p-1">
                        <i className="far fa-question-circle lg"></i>
                    </Button>
                </OverlayTrigger>
            );
        }
    }
    return (<>
        <div className={summary.transactionStatus !== DataStatusMessage.DataAvailable ?
            "d-inline-block tools-dashboard-card-loader card-spacing-bottom w-100" : "d-inline-block w-100 cas-minichart-equation-box card-spacing-bottom"}>
            <Card border="light" className="bg-white shadow-sm ms-3 p-3">
                <Card.Header className="pt-0 ps-0">
                    {t("header.Summary")}{createTooltip(t("header.CAS_Summary_Tooltip"), "right")}
                </Card.Header>
                <Card.Body className=' pb-0'>
                    {
                        summary.transactionStatus !== DataStatusMessage.DataAvailable &&
                        <CommonMessageComponent transactionStatus={summary.transactionStatus}></CommonMessageComponent>
                    }
                    {summary.transactionStatus === DataStatusMessage.DataAvailable &&
                        <Row className="tools-overview-key-insight-container card-spacing-bottom">
                            <Col md={3}>
                                <div className="pod equation-calc-card-container ng-scope">
                                    <div className="card card-key-insights w-100">
                                        <div className="card-body">
                                            <div className="key-insight-value ng-binding" title={convertToInternationalFormat(summary.result.ScreenPageViews)}>
                                                {formatCount(summary.result.ScreenPageViews)}
                                            </div>
                                            <div class="card-value-title ng-binding key-insight-title-box">
                                                {t("header.summary_views")}
                                                {createTooltip(t("header.summary_views_tooltip"), "right")}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col md={3}>
                                <div className="pod equation-calc-card-container ng-scope">
                                    <div className="card card-key-insights w-100">
                                        <div className="card-body">
                                            <div className="key-insight-value ng-binding" title={convertToInternationalFormat(summary.result.TotalUsers)}>
                                                {formatCount(summary.result.TotalUsers)}
                                            </div>
                                            <div class="card-value-title ng-binding key-insight-title-box">
                                                {t("header.summary_users")}
                                                {createTooltip(t("header.summary_users_tooltip"), "left")}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col md={3}>
                                <div className="pod equation-calc-card-container ng-scope">
                                    <div className="card card-key-insights w-100">
                                        <div className="card-body">
                                            <div className="key-insight-value ng-binding" title={summary.result.ScreenPageViewsPerUser}>
                                                {summary.result.ScreenPageViewsPerUser}
                                            </div>
                                            <div class="card-value-title ng-binding key-insight-title-box">
                                                {t("header.summary_views_per_user")}
                                                {createTooltip(t("header.summary_views_per_user_tooltip"), "right")}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col md={3}>
                                <div className="pod equation-calc-card-container ng-scope">
                                    <div className="card card-key-insights w-100">
                                        <div className="card-body">
                                            <div className="key-insight-value ng-binding" title={summary.result.UserEngagementDurationConverted ? summary.result.UserEngagementDurationConverted : "0s"}>
                                                {summary.result.UserEngagementDurationConverted ? summary.result.UserEngagementDurationConverted : "0s"}
                                            </div>
                                            <div class="card-value-title ng-binding key-insight-title-box">
                                                {t("header.summary_time_per_user")}
                                                {createTooltip(t("header.summary_time_per_user_tooltip"), "left")}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    }
                </Card.Body>
            </Card>
        </div>
        <div className="d-inline-block tools-dashboard-card-loader card-spacing-bottom w-100">
            <Card border="light" className="bg-white shadow-sm ms-3 p-3">
                <Card.Body>
                    <Row className="tools-overview-subtab">
                        <Col md={12}>
                            {
                                summary.transactionStatus === DataStatusMessage.DataAvailable &&
                                <Tab.Container defaultActiveKey="tab1" >
                                    <Nav fill variant="pills" className="flex-column flex-sm-row tab subtab ms-0 me-0 mt-1">
                                        <Nav.Item className="pe-0">
                                            <Nav.Link eventKey="tab1" onClick={() => { setTab(1) }} className="mb-sm-3 mb-md-0">
                                                <span className="d-inline-block w-100 text-left tools-tab-label-text">
                                                    {t("header.linechart_summary_views")}
                                                    {createTooltip(t("header.linechart_summary_views_tooltip"), "right")}
                                                </span>
                                                <span className="d-inline-block w-100 text-left tools-tab-count"> {formatCount(summary.result.ScreenPageViews)}</span>
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="tab2" onClick={() => { setTab(2) }} className="mb-sm-3 mb-md-0">
                                                <span className="d-inline-block w-100 text-left tools-tab-label-text">
                                                    {t("header.linechart_summary_users")}
                                                    {createTooltip(t("header.linechart_summary_users_tooltip"), "right")}
                                                </span>
                                                <span className="d-inline-block w-100 text-left tools-tab-count">{formatCount(summary.result.TotalUsers)}</span>
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="tab3" onClick={() => { setTab(3) }} className="mb-sm-3 mb-md-0">
                                                <span className="d-inline-block w-100 text-left tools-tab-label-text">
                                                    {t("header.linechart_summary_events")}
                                                    {createTooltip(t("header.linechart_summary_events_tooltip"), "right")}
                                                </span>
                                                <span className="d-inline-block w-100 text-left tools-tab-count">{formatCount(summary.result.EventCount)}</span>
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="tab4" onClick={() => { setTab(4) }} className="mb-sm-3 mb-md-0">
                                                <span className="d-inline-block w-100 text-left tools-tab-label-text">
                                                    {t("header.linechart_summary_event_users")}
                                                    {createTooltip(t("header.linechart_summary_event_users_tooltip"), "right")}
                                                </span>
                                                <span className="d-inline-block w-100 text-left tools-tab-count">{formatCount(summary.result.EventTotalUsers)}</span>
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="tab5" onClick={() => { setTab(5) }} className="mb-sm-3 mb-md-0">
                                                <span className="d-inline-block w-100 text-left tools-tab-label-text">
                                                    {t("header.linechart_summary_events_per_user")}
                                                    {createTooltip(t("header.linechart_summary_events_per_user_tooltip", "left"))}
                                                </span>
                                                <span className="d-inline-block w-100 text-left tools-tab-count">{summary.result.EventCountPerUser}</span>
                                            </Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                    <Tab.Content>
                                        <Tab.Pane eventKey="tab1" className="py-2">
                                            {tab === 1 && lineChart.transactionStatus.PageViewStatus === DataStatusMessage.DataAvailable &&
                                                <DataChartRenderer
                                                    data={
                                                        lineChart.result?.PageView
                                                    }
                                                    chartType="line" />
                                            }
                                            {lineChart.transactionStatus.PageViewStatus !== DataStatusMessage.DataAvailable &&
                                                <CommonMessageComponent transactionStatus={lineChart.transactionStatus.PageViewStatus}></CommonMessageComponent>
                                            }
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="tab2" className="py-2">
                                            {tab === 2 && lineChart.transactionStatus.TotalUsersStatus === DataStatusMessage.DataAvailable &&
                                                <DataChartRenderer
                                                    data={
                                                        lineChart.result?.TotalUsers
                                                    }
                                                    chartType="line" />
                                            }
                                            {lineChart.transactionStatus.TotalUsersStatus !== DataStatusMessage.DataAvailable &&
                                                <CommonMessageComponent transactionStatus={lineChart.transactionStatus.TotalUsersStatus}></CommonMessageComponent>
                                            }

                                        </Tab.Pane>
                                        <Tab.Pane eventKey="tab3" className="py-2">
                                            {tab === 3 && lineChart.transactionStatus.EventCountStatus === DataStatusMessage.DataAvailable &&
                                                <DataChartRenderer
                                                    data={
                                                        lineChart.result?.EventCount
                                                    }
                                                    chartType="line" />}
                                            {lineChart.transactionStatus.EventCountStatus !== DataStatusMessage.DataAvailable &&
                                                <CommonMessageComponent transactionStatus={lineChart.transactionStatus.EventCountStatus}></CommonMessageComponent>
                                            }

                                        </Tab.Pane>
                                        <Tab.Pane eventKey="tab4" className="py-2">
                                            {tab === 4 && lineChart.transactionStatus.EventTotalUsersStatus === DataStatusMessage.DataAvailable &&
                                                <DataChartRenderer
                                                    data={
                                                        lineChart.result?.EventTotalUsers
                                                    }
                                                    chartType="line" />}
                                            {lineChart.transactionStatus.EventTotalUsersStatus !== DataStatusMessage.DataAvailable &&
                                                <CommonMessageComponent transactionStatus={lineChart.transactionStatus.EventTotalUsersStatus}></CommonMessageComponent>
                                            }
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="tab5" className="py-2">
                                            {tab === 5 && lineChart.transactionStatus.EventCountPerUserStatus === DataStatusMessage.DataAvailable &&
                                                <DataChartRenderer
                                                    data={
                                                        lineChart.result?.EventCountPerUser
                                                    }
                                                    chartType="line" />}
                                            {lineChart.transactionStatus.EventCountPerUserStatus !== DataStatusMessage.DataAvailable &&
                                                <CommonMessageComponent transactionStatus={lineChart.transactionStatus.EventCountPerUserStatus}></CommonMessageComponent>
                                            }
                                        </Tab.Pane>
                                    </Tab.Content>
                                </Tab.Container>
                            }

                            {
                                (summary.transactionStatus !== DataStatusMessage.DataAvailable) &&
                                <CommonMessageComponent transactionStatus={summary.transactionStatus}></CommonMessageComponent>
                            }

                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </div>
        <div className="d-inline-block tools-dashboard-card-loader w-100 card-spacing-bottom">
            <Card border="light" className="bg-white shadow-sm ms-3">
                <Card.Header className="pt-3 ps-3">
                    {t("header.cardheading_views_by_country")}{createTooltip(t("header.cardheading_views_by_country_tooltip"), "right")}
                </Card.Header>
                <Card.Body>
                    {
                        mapData.transactionStatus !== DataStatusMessage.DataAvailable &&
                        <Row>
                            <Col md={12}>
                                <CommonMessageComponent transactionStatus={mapData.transactionStatus}></CommonMessageComponent>
                            </Col>
                        </Row>
                    }
                    {
                        mapData.transactionStatus === DataStatusMessage.DataAvailable &&
                        <Row>
                            <Col md={12}>
                                <DataChartRenderer
                                    data={
                                        mapData.result
                                    }
                                    title={t("header.cardheading_views_by_country")}
                                    chartType="map" />
                            </Col>
                            <Col md={12} className='mt-2'>
                                {tableDataMap.transactionStatus !== DataStatusMessage.Loading &&
                                    <DataTableRenderer
                                        header={tableDataMap.header}
                                        dataSet={tableDataMap.data}
                                        transactionStatus={tableDataMap.transactionStatus}
                                        customAttributes={{ displayRowsPerPage: 15, showPagination: true }}
                                    ></DataTableRenderer>
                                }
                            </Col>
                        </Row>
                    }
                </Card.Body>
            </Card>
        </div>
        <Row className="half-width-card-container">
            <Col md={6}>
                <div className="d-inline-block tools-dashboard-card-loader w-100">
                    <Card border="light" className="bg-white shadow-sm mb-3 ms-3">
                        <Card.Header className="pt-3 ps-3 pe-3 pb-2">
                            {t("header.Corporate_Alert_Service_Submit_Button_Click")}{createTooltip(t("header.CAS_CorporateAlertServiceSubmitButtonClick_Tooltip"), "right")}
                        </Card.Header>
                        <Card.Body>
                            {
                                tableSubmitButtonClick.transactionStatus === DataStatusMessage.Loading &&
                                <CommonMessageComponent transactionStatus={tableSubmitButtonClick.transactionStatus}></CommonMessageComponent>
                            }
                            {
                                tableSubmitButtonClick.transactionStatus !== DataStatusMessage.Loading &&
                                <DataTableRenderer
                                    header={tableSubmitButtonClick.header}
                                    dataSet={tableSubmitButtonClick.data}
                                    transactionStatus={tableSubmitButtonClick.transactionStatus}
                                    headerSummaryData={tableSubmitButtonClick.headerSummary}
                                    customAttributes={{ displayRowsPerPage: 7, showHeaderSummary: true, showPagination: true }}
                                ></DataTableRenderer>
                            }
                        </Card.Body>
                    </Card>
                </div>
            </Col>
        </Row>
    </>);
});

export default CorporateAlertService;