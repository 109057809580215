import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Col, Row, Form, FormCheck, Image } from "@themesberg/react-bootstrap";

import { isEmpty, DEFAULT_TIMEOUT, EMAIL_REGEX } from "../AppConfig";
import { ValidateTextInputs } from "../utils/common";
import request from "../apis/request";
import { baseURL, METHODS } from "../apis/utilities/constant";
import { decryptData } from "../utils/crypto";
import LoaderImage from "../assets/img/LoaderGraphic.svg";

import AppContext from '../components/AppContext';
import ModalComponent from "../components/VoltModal";
import ButtonsComponent from "../components/Buttons";
import DropdownWithIconComponent from "../components/DropdownWithIcons";

const AddSubscriberModal = ({
  setIsRefreshParant,
  setSaveFailedError,
  isRnsCat,
  alertClientActiveRes,
  showPlusModal,
  setShowPlusModal,
  formFields,
  setFormFields,
  formError,
  isFormSubmit,
  setFormError,
  setIsFormSubmit,
  closePopup,
  setShowError,
  selectedOtpclientid,
  setshowSuccess,
  subscriberId,
  subscriberEmail,
  languageParent,
  customCatParent,
  titleParent,
  occupationParent,
  rnsCatParent,
  onLanguageDrpChange,
  selectedLanguageParent
}) => {
  const [t, i18n] = useTranslation();
  const contextData = useContext(AppContext);

  const [language, setLanguage] = useState([]);
  const [title, setTitle] = useState([]);
  const [occupation, setOccupation] = useState([]);
  const [customCat, setCustomCat] = useState([]);
  const [rnsCat, setRnsCat] = useState([]);
  const [rnsCatMaster, setRnsCatMaster] = useState([]);

  const [allTypesSelected, setAllTypesSelected] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);

  const [selectedLanguageNew, setSelectedLanguageNew] = useState("");
  const [autoLoad, setAutoLoad] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState({});
  const [selectedTitle, setSelectedTitle] = useState({});
  const [selectedOccupation, setSelectedOccupation] = useState({});
  const [isRefresh, setIsRefresh] = useState("");
  const [isFormSubmittedSucessfully, setIsFormSubmittedSucessfully] = useState(false);
  const [showModal, setShowModal] = useState(showPlusModal);
  const [showCategory, setshowCategory] = useState(false);
  const [showRnsCategory, setshowRnsCategory] = useState(false);
  const [updateData, setUpdateData] = useState(false);
  const [blurLoader, setBlurLoader] = useState(false);
  const [isBtnDisabled, setIsBtnDisabled] = useState(true);

  useEffect(() => {
    setLanguage(languageParent);
    setTitle(titleParent);
    setOccupation(occupationParent);

    setCustomCat(customCatParent);
    setRnsCatMaster(rnsCatParent);
    setRnsCat(rnsCatParent);
    setSelectedLanguage(selectedLanguageParent);
    if (titleParent && titleParent.length > 0) {
      setSelectedTitle(titleParent[0]);
    }
    else {
      setSelectedTitle({});
    }
    if (occupationParent && occupationParent.length > 0)
      setSelectedOccupation(occupationParent[0]);
    else
      setSelectedOccupation({});
  }, [customCatParent, rnsCatParent, selectedLanguageParent, titleParent, occupationParent, languageParent])

  useEffect(() => {
    setIsEditMode(true);
  }, [subscriberId])

  useEffect(() => {
    var regexEmail = EMAIL_REGEX;
    let error = true;
    if (isEmpty(formFields.email)) {
      error = true;
    }
    else if (!regexEmail.test(formFields.email.trim())) {
      error = true;
    }
    else {
      error = false;
    }
    setIsBtnDisabled(error);
  }, [formFields])

  useEffect(() => {
    if (isEditMode && subscriberId > 0) {
      setIsEditMode(false);
      setBlurLoader(true);
      request(baseURL + `/Intelligence/GetAlertSubscriberDataBySubscriberId?WebsiteId=${contextData.websiteId}&subscriberId=${subscriberId}&companyid=${contextData.companyId}&userid=${contextData.userId}`,
        METHODS.GET,
        {},
        {
          Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
        })
        .then((res) => {
          setBlurLoader(false);
          let data = res?.result;
          if (data) {
            const formData = {
              email: data.EmailId,
              country: data.CountryName,
              firstName: data.FirstName,
              lastName: data.LastName,
              occupation: occupation.find(x => x.DisplayValue == data.Occupation),
              isRNSHtml: data.IsRNSViaHtml,
              title: title.find(x => x.DisplayValue == data.Title),
              mobile: data.Mobile,
              languageCode: language.find(x => x.LanguageCode == data.LanguageCode),
              isSubscriberHtml: data.IsRNSViaHtml ? 1 : 0,
              isSubscriberText: data.IsRNSViaText ? 1 : 0,
              password: data.Password
            };
            data && data.SubscriberCustomAlerts && data.SubscriberCustomAlerts.length > 0
              && data.SubscriberCustomAlerts.map((item) => {
                let hasSelectedCustomCat = customCat.findIndex(x => x.AlertId == item.AlertId);
                if (item.EmailAlert && hasSelectedCustomCat > -1) {
                  customCat[hasSelectedCustomCat].isChecked = true;
                }
              })

            //considering RNSAlertId for display data
            let newRns = [];
            let rnsData = data.RNSAlertId ? data.RNSAlertId.split("|") : [];
            if (rnsData[0] == "ALL") {
              rnsCat && rnsCat.length > 0 && rnsCat.map((item) => {
                item.isChecked = true;
                newRns.push(item);
              })
            }
            rnsCat && rnsCat.length > 0 && rnsCat.map((rnsItem, rIndex) => {
              let findIndex = rnsData.findIndex(x => x == rnsItem.CategoryName);
              if (findIndex != -1) {
                rnsItem.isChecked = true;
              }
              newRns.push(rnsItem);
            })

            setAllTypesSelected(rnsCat && rnsData && Array.isArray(rnsData) && rnsCat.length == rnsData.length ? true : false);
            if (!updateData) {
              setSelectedTitle(formData.title ? formData.title : titleParent[0]);
              setSelectedOccupation(formData.occupation);
              setSelectedLanguage(formData.languageCode);
              setRnsCat(newRns);
              setCustomCat(customCat);
              setFormFields(formData);
              setUpdateData(true)
            }
          }
        })
        .catch((err) => {
          setBlurLoader(false);
        })
    }
  }, [language, customCat, title, occupation, rnsCat])

  useEffect(() => {
    if (isFormSubmittedSucessfully) {
      onModalClose();
    }
  }, [isFormSubmittedSucessfully, showPlusModal, setShowPlusModal, isFormSubmit]);

  useEffect(() => {
    const languageData = JSON.parse(localStorage.getItem("languageData"))
    if (formFields.languageCode !== undefined && formFields.languageCode !== "" && languageData !== null && languageData.length > 0) {
      const languageDataNew = languageData.filter((data) => {
        if (data["ClientLanguages.LanguageCode"] === formFields.languageCode)
          return data;
      });
      setSelectedLanguage(languageDataNew[0])
    }
  }, [localStorage.getItem("languageData"), formFields]);

  useEffect(() => {
    const occupationData = JSON.parse(localStorage.getItem("Occupation"))
    if (formFields.occupation !== undefined && formFields.occupation !== "" && occupationData !== null && occupationData.length > 0) {
      const occupationDataNew = occupationData.filter((data) => {
        if (data["ClientOccupation.Description"] === formFields.occupation)
          return data;
      });
      setSelectedOccupation(occupationDataNew[0])
    }
  }, [localStorage.getItem("Occupation"), formFields]);

  useEffect(() => {
    const titleData = JSON.parse(localStorage.getItem("Title"))
    if (formFields.title !== undefined && formFields.title !== "" && titleData !== null && titleData.length > 0) {

      const titleDataNew = titleData.filter((data) => {
        if (data["ClientTitle.Description"] === formFields.title)
          return data;
      });
      if (titleDataNew[0] !== undefined && Object.keys(titleDataNew[0])) {
        setSelectedTitle(titleDataNew[0])
      }
    }
  }, [localStorage.getItem("Title"), formFields]);

  useEffect(() => {
    const formData = {
      RNSAllStatus: false,
      email: "",
      country: "",
      firstName: "",
      lastName: "",
      occupation: "",
      category: "",
      title: 'Mr.',
      password: "",
      mobile: "",
      languageCode: "",
      isRNSHtml: true,
      isSubscriberHtml: 1,
      isSubscriberText: 0,
      customCategoriesNew: [],
      rnsCategoriesNew: [],
      categoeisIdies: []
    };
    setFormFields(formData);
  }, [])

  const onModalClose = () => {
    resetFormData();
    setShowModal && setShowModal(false);
    setShowPlusModal && setShowPlusModal(false);
    closePopup && closePopup();
  };

  const resetFormData = () => {
    const formDta = {
      RNSAllStatus: false,
      email: "",
      country: "",
      firstName: "",
      lastName: "",
      occupation: "",
      password: "",
      category: "",
      isSubscriberHtml: 1,
      isSubscriberText: 0,
    };
    const errorDta = {
      RNSAllStatus: false,
      email: "",
      country: "",
      firstName: "",
      lastName: "",
      password: "",
      occupation: "",
      category: "",
      isSubscriberHtml: false
    };
    setFormFields && setFormFields(formDta);
    setFormError && setFormError(errorDta);
    setRnsCat([]);
    setRnsCatMaster([]);
    setCustomCat([]);
  };

  const onCancelButtonClick = (e) => {
    e.preventDefault();
    onModalClose();
  };

  const onCheckBoxChange = (e, item) => {
    e.preventDefault();
    let updatedRnsCategories = [];
    let index = customCat.findIndex(x => x.AlertId == item.AlertId);
    if (customCat[index].isChecked) {
      customCat[index].isChecked = false;
      updatedRnsCategories = customCat;
    }
    else {
      customCat[index].isChecked = true;
      updatedRnsCategories = customCat;
    }

    setCustomCat(undefined);
    setTimeout(() => {
      setCustomCat(updatedRnsCategories);
    }, 10)
  }

  const onFormElementsChange = (event) => {
    const { name, value, id, keys } = event.target;
    const forms = { ...formFields };
    let formVlue;
    formVlue = value;
    if (name == "isSubscriberHtml") {
      formVlue = formVlue == "false" ? 1 : 0;
      forms['isSubscriberText'] = formVlue == 0 ? 1 : 0;
      forms['isRNSHtml'] = formVlue;
    }
    if (name == "isSubscriberText") {
      formVlue = formVlue == "false" ? 1 : 0;
      forms['isSubscriberHtml'] = formVlue == 0 ? 1 : 0;
      forms['isRNSHtml'] = formVlue;
    }
    forms[name] = formVlue;
    setFormFields && setFormFields(forms);
  };

  const onRnsCheckBoxChange = (e, item) => {
    e.preventDefault();
    let updatedRnsCategories = [];
    if (item == "All") {
      if (allTypesSelected) {
        rnsCatMaster && rnsCatMaster.map((item) => {
          item.isChecked = false;
          updatedRnsCategories.push(item);
        })
      }
      else {
        rnsCatMaster && rnsCatMaster.map((item) => {
          item.isChecked = true;
          updatedRnsCategories.push(item);
        })
      }
    }
    else {
      let index = rnsCatMaster.findIndex(x => x.RNSCategoryId == item.RNSCategoryId);
      if (rnsCatMaster[index].isChecked) {
        rnsCatMaster[index].isChecked = false;
        updatedRnsCategories = rnsCatMaster;
      }
      else {
        rnsCatMaster[index].isChecked = true;
        updatedRnsCategories = rnsCatMaster;
      }
    }
    setRnsCatMaster(undefined);
    let c = updatedRnsCategories.find(x => x.isChecked == false);
    setAllTypesSelected(c ? false : true);
    setTimeout(() => {
      setRnsCatMaster(updatedRnsCategories);
    }, 10)
  }

  const handleSubmitButtonClick = (e) => {
    let errorObj = formError;
    let error = false;
    var regexEmail = EMAIL_REGEX;
    if (isEmpty(formFields.email)) {
      error = true;
      errorObj.email = t("header.manageSubscribersEmailValidationMessage");
    }
    if (!regexEmail.test(formFields.email.trim())) {
      error = true;
      errorObj.email = t("header.addSubscriberInvalidEmail");
    }
    if ((subscriberId == 0) && alertClientActiveRes.RegistrationMethod != 4 && (formFields.password == '' || formFields.password == null)) {

      error = true;
      errorObj.password = t("header.AlertErrorPasswordIsRequired");
    }
    else {
      errorObj.password = "";
    }
    if (ValidateTextInputs(formFields.firstName)) {
      error = true;
      errorObj.firstName = t("header.addSubscriberInvalidFirstName");
    }
    if (ValidateTextInputs(formFields.lastName)) {
      error = true;
      errorObj.lastName = t("header.addSubscriberInvalidLastName");
    }
    if (ValidateTextInputs(formFields.country)) {
      error = true;
      errorObj.country = t("header.addSubscriberInvalidCountryName");
    }

    let selectedCustomCat = customCat.filter(x => x.isChecked == true);
    if (customCat && customCat.length > 0 && selectedCustomCat && selectedCustomCat.length == 0) {
      error = true;
      errorObj.category = t("header.addSubscriberCategoryNotice");
      setshowCategory(true);
    }
    else if (selectedCustomCat.length == 0) {
      error = true;
      errorObj.category = t("header.addSubscriberCategoryNotice");
      setshowCategory(true);
    }
    else if (selectedCustomCat.length > 0) {
      errorObj.category = "";
    }

    let sRNSCat = rnsCat && Array.isArray(rnsCat) && rnsCat.filter(x => x.isChecked == true);
    if (rnsCat && rnsCat.length > 0 && sRNSCat && sRNSCat.length == 0) {
      error = true;
      errorObj.rnsCatErr = t("header.addSubscriberRNSCategoryNotice");
      setshowRnsCategory(true);
    }
    else if (sRNSCat.length == 0) {
      error = true;
      errorObj.rnsCatErr = t("header.addSubscriberRNSCategoryNotice");
      setshowRnsCategory(true);
    }
    else if (sRNSCat.length > 0) {
      errorObj.rnsCatErr = "";
    }
    if (error) {
      setIsRefresh(!isRefresh);
      setIsFormSubmit(true);
      setFormError(errorObj);
      setShowError(true);
    }
    else {
      if (formFields.isSubscriberHtml == undefined) {
        formFields.isSubscriberHtml = false;
      }

      let selectedRnsCat = "";
      rnsCat && rnsCat.length > 0 && rnsCat.map((item) => {
        if (item.isChecked) {
          if (!selectedRnsCat) {
            selectedRnsCat = item.CategoryName;
          }
          else {
            selectedRnsCat = selectedRnsCat + "|" + item.CategoryName;
          }
        }
      })

      var obj = {
        "WebsiteId": selectedOtpclientid["WebsiteList.WebsiteId"],
        "ClientId": selectedOtpclientid["ClietConfiguration.otpclientid"],
        "LanguageCode": selectedLanguage && selectedLanguage["LanguageCode"] ? selectedLanguage["LanguageCode"] : null,
        "Title": selectedTitle && selectedTitle["DisplayValue"] ? selectedTitle["DisplayValue"] : null,
        "Email": formFields.email,
        "Country": formFields.country,
        "FirstName": formFields.firstName,
        "LastName": formFields.lastName,
        "Mobile": formFields.mobile,
        "Occupation": selectedOccupation && selectedOccupation ? selectedOccupation["DisplayValue"] : '',
        "Active": true,
        "ConsentEmail": false,
        "ConsentSMS": false,
        "ConsentMarketing": false,
        "IsRNSHtml": formFields.isSubscriberHtml == 0 ? false : true,
        "RNSAlertViaSMS": false,
        "RNSAlertViaEmail": true,
        "RNSAlertID": selectedRnsCat,
        "SubscriberId": subscriberId,
        "CompanyId": contextData.companyId,
        "UserId": contextData.userId,
        "Password": formFields.password
      }
      setBlurLoader(true);
      request(
        baseURL + `/Intelligence/SaveSubscriberDetail`,
        METHODS.POST,
        obj,
        {
          Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
        }
      )
        .then((response) => {
          setBlurLoader(false);
          if (response.result > 0) {
            setBlurLoader(true);
            let prepareCustomCat = [];
            customCat && Array.isArray(customCat) && customCat.length > 0 && customCat.map((pItem) => {
              let findInex = selectedCustomCat.findIndex(x => x.AlertId == pItem.AlertId);
              if (findInex !== -1) {
                prepareCustomCat.push({
                  "AlertId": pItem.AlertId,
                  "EmailAlert": true,
                  "IsTextFormat": formFields.isSubscriberText == 0 ? false : true,
                  "SMSAlert": pItem.IsSMS
                });
              }
              else {
                prepareCustomCat.push({
                  "AlertId": pItem.AlertId,
                  "EmailAlert": false,
                  "IsTextFormat": formFields.isSubscriberText == 0 ? false : true,
                  "SMSAlert": pItem.IsSMS
                });
              }
            })
            let obj1 = {
              ListCustomAlerts: prepareCustomCat,
              SubscriberId: response?.result,
              WebsiteId: contextData.websiteId,
              CompanyId: contextData.companyId,
              UserId: contextData.userId
            }

            request(
              baseURL + `/Intelligence/SaveSubscriberAlerts`,
              METHODS.POST,
              obj1,
              {
                Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
              }
            )
              .then((res) => {
                setBlurLoader(false);
                setIsRefreshParant(true);
                setIsRefresh(false);
                setIsFormSubmittedSucessfully(true);
                setTimeout(function () {
                  setIsFormSubmittedSucessfully(false);
                  setshowSuccess(false);
                }, DEFAULT_TIMEOUT);

                setshowSuccess(true);
              })
              .catch((err) => {
              })
          }
          else {
            setIsRefresh(false);
            setBlurLoader(false);
            setShowError(true);
            setSaveFailedError(response.message);
            setIsFormSubmittedSucessfully(true);
            setTimeout(function () {
              setIsFormSubmittedSucessfully(false);
              setSaveFailedError(undefined);
            }, DEFAULT_TIMEOUT);
          }
        })
        .catch((error) => {
          setBlurLoader(false);
        })
    }
  }

  const onLanguageDropdownChange = (data) => {
    setSelectedLanguageNew(data)
    setAutoLoad(true)
    setSelectedLanguage(data);
    onLanguageDrpChange(data);
    localStorage.setItem("EventCalendarProfileId", JSON.stringify(data));// Adding EventCalendarProfileId to local stoarge in case of Page Refresh
  };

  const onTitleDropdownChange = (data) => {
    setSelectedTitle(data);
    setAutoLoad(true);
  };

  const onOccupationDrownChange = (data) => {
    setSelectedOccupation(data);
    setAutoLoad(true);
  };

  return (
    <ModalComponent
      open={showPlusModal}
      title={subscriberId > 0 ? t("header.AlertManageSubscribersEditSubscribers") : t("header.AlertManageSubscribersAddSubscribers")}
      handleClose={onModalClose}
      showCloseButton={true}
    >
      {blurLoader && <div id='blurdiv' className="blurbackground">
        <div className={`preloader loader-center-align`}>
          <Image className="loader-element animate__animated animate__jackInTheBox" src={LoaderImage} height={40} data-seconds={30} />
        </div>
      </div>}
      <Row>
        <Col sm="6">
          <Row className="mb-1">
            <Col md={12}>
              <h6 className="body-text mt-1 mb-2">{t("header.AlertAddSubscriberFormHeader")}</h6>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col md={4}>
              <Form.Label>
                {t("header.AlertAddSubscriberFormLanguage")}
                <span className="mandatory">*</span>
              </Form.Label>
            </Col>
            <Col md={8}>
              <DropdownWithIconComponent
                dropdownData={language}
                arrayValue="LanguageName"
                selectedDropdown={selectedLanguage}
                onChange={onLanguageDropdownChange}
                showLabel={false}
                showIconInDropdownItem={false}
                customClassName="full-width text-overflow"
                isDropdownDisabled={subscriberEmail !== "" ? true : false}
              />
            </Col>
          </Row>
          <Row className="mb-2">
            <Col md={4}>
              <Form.Label>{t("header.AlertAddSubscriberFormEmail")}
                <span className="mandatory">*</span> </Form.Label>
            </Col>
            <Col md={8}>
              <Form.Group className="mb-1">
                {
                  isFormSubmit ?
                    (!isEmpty(formError.email) ?
                      <Form.Control type="email" name="email" disabled={subscriberId > 0 ? true : false} value={formFields.email} onChange={(e) => onFormElementsChange(e)} />
                      : <Form.Control type="email" name="email" disabled={subscriberId > 0 ? true : false} value={formFields.email} onChange={(e) => onFormElementsChange(e)} />
                    )
                    : <Form.Control type="email" name="email" disabled={subscriberId > 0 ? true : false} value={formFields.email} onChange={(e) => onFormElementsChange(e)} />
                }
                {(isFormSubmit && !isEmpty(formError.email)) &&
                  <Form.Control.Feedback> {formError.email} </Form.Control.Feedback>
                }
                <p className="text-italic fw-normal font-small mb-0">
                  {subscriberId > 0 ? '' : t("header.AlertAddSubscriberFormEmailInfo")}
                </p>
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col md={4}>
              <Form.Label>{t("header.AlertAddSubscriberFormTitle")}</Form.Label>
            </Col>
            <Col md={8}>
              {selectedLanguage &&
                <DropdownWithIconComponent
                  dropdownData={title}
                  arrayValue="DisplayText"
                  selectedDropdown={selectedTitle}
                  onChange={onTitleDropdownChange}
                  showLabel={false}
                  showIconInDropdownItem={false}
                  customClassName="full-width text-overflow"
                  isDropdownDisabled={title.length > 0 ? false : true}
                />
              }
            </Col>
          </Row>
          <Row className="mb-2">
            <Col md={4}>
              <Form.Label>
                {t("header.AlertAddSubscriberFormFirstName")}
              </Form.Label>
            </Col>
            <Col md={8}>
              <Form.Group className="">
                {
                  isFormSubmit ?
                    (!isEmpty(formError.firstName) ?
                      <Form.Control type="text" name="firstName" value={formFields.firstName} onChange={(e) => onFormElementsChange(e)} />
                      : <Form.Control type="text" name="firstName" value={formFields.firstName} onChange={(e) => onFormElementsChange(e)} />
                    )
                    : <Form.Control type="text" name="firstName" value={formFields.firstName} onChange={(e) => onFormElementsChange(e)} />
                }
                {(isFormSubmit && !isEmpty(formError.firstName)) &&
                  <Form.Control.Feedback> {formError.firstName} </Form.Control.Feedback>
                }
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col md={4}>
              <Form.Label>
                {t("header.AlertAddSubscriberFormLastName")}
              </Form.Label>
            </Col>
            <Col md={8}>
              <Form.Group>
                {
                  isFormSubmit ?
                    (!isEmpty(formError.lastName) ?
                      <Form.Control type="text" name="lastName" value={formFields.lastName} onChange={(e) => onFormElementsChange(e)} />
                      : <Form.Control type="text" name="lastName" value={formFields.lastName} onChange={(e) => onFormElementsChange(e)} />
                    )
                    : <Form.Control type="text" name="lastName" value={formFields.lastName} onChange={(e) => onFormElementsChange(e)} />
                }
                {(isFormSubmit && !isEmpty(formError.lastName)) &&
                  <Form.Control.Feedback> {formError.lastName} </Form.Control.Feedback>
                }
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col md={4}>
              <Form.Label>
                {t("header.AlertAddSubscriberFormCountry")}
              </Form.Label>
            </Col>
            <Col md={8}>
              <Form.Group>
                {
                  isFormSubmit ?
                    (!isEmpty(formError.country) ?
                      <Form.Control type="text" name="country" value={formFields.country} onChange={(e) => onFormElementsChange(e)} />
                      : <Form.Control type="text" name="country" value={formFields.country} onChange={(e) => onFormElementsChange(e)} />
                    )
                    : <Form.Control type="text" name="country" value={formFields.country} onChange={(e) => onFormElementsChange(e)} />
                }
                {(isFormSubmit && !isEmpty(formError.country)) &&
                  <Form.Control.Feedback > {formError.country} </Form.Control.Feedback>
                }
              </Form.Group>
            </Col>
          </Row>
          {subscriberId <= 0 && alertClientActiveRes.RegistrationMethod != 4 && <Row className="mb-2">
            <Col md={4}>
              <Form.Label>
                {t("header.AlertAddSubscriberFormPassword")} <span className="mandatory">*</span>
              </Form.Label>
            </Col>
            <Col md={8}>
              <Form.Group>
                {
                  isFormSubmit ?
                    (!isEmpty(formError.password) ?
                      <Form.Control type="password" name="password" value={formFields.password} onChange={(e) => onFormElementsChange(e)} />
                      : <Form.Control type="password" name="password" value={formFields.password} onChange={(e) => onFormElementsChange(e)} />
                    )
                    : <Form.Control type="password" name="password" value={formFields.password} onChange={(e) => onFormElementsChange(e)} />
                }
                {(isFormSubmit && !isEmpty(formError.password)) &&
                  <Form.Control.Feedback type="invalid" style={{ "display": "block" }}> {formError.password}  </Form.Control.Feedback>
                }
              </Form.Group>
            </Col>
          </Row>}
          <Row className="mb-2">
            <Col md={4}>
              <Form.Label>
                {t("header.AlertAddSubscriberFormMobile")}
              </Form.Label>
            </Col>
            <Col md={8}>
              <Form.Group>
                {
                  isFormSubmit ?
                    (!isEmpty(formError.mobile) ?
                      <Form.Control type="text" name="mobile" disabled={subscriberId > 0 ? true : false} value={formFields.mobile} onChange={(e) => onFormElementsChange(e)} />
                      : <Form.Control type="text" name="mobile" disabled={subscriberId > 0 ? true : false} value={formFields.mobile} onChange={(e) => onFormElementsChange(e)} />
                    )
                    : <Form.Control type="text" name="mobile" value={formFields.mobile} disabled={subscriberId > 0 ? true : false} onChange={(e) => onFormElementsChange(e)} />
                }
                {(isFormSubmit && !isEmpty(formError.mobile)) &&
                  <Form.Control.Feedback type="invalid"> {formError.mobile} </Form.Control.Feedback>
                }
              </Form.Group>
              <p className="text-italic fw-normal font-small mb-0">
                {subscriberId > 0 ? '' : t("header.AlertAddSubscriberFormMobileInfo")}
              </p>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col md={4}>
              <Form.Label>{t("header.AlertAddSubscriberFormOccupation")} </Form.Label>
            </Col>
            <Col md={8}>
              {selectedLanguage &&
                <DropdownWithIconComponent
                  dropdownData={occupation}
                  arrayValue="DisplayText"
                  selectedDropdown={selectedOccupation}
                  onChange={onOccupationDrownChange}
                  showLabel={false}
                  showIconInDropdownItem={false}
                  customClassName="full-width text-overflow"
                  isDropdownDisabled={occupation.length > 0 ? false : true}
                />
              }
            </Col>
          </Row>
        </Col>
        <Col sm="6">
          {customCat && customCat.length > 0 && <Row className="mb-1">
            <Col md={12}>
              {showCategory && <Form.Control.Feedback type="invalid" style={{ "display": "block" }}> {formError.category} </Form.Control.Feedback>}
            </Col>
            <Col md={12}>
              <h6 className="body-text mt-1 mb-1">{t("header.AlertAddSubscriberCustomCategories")} <span className="mandatory">*</span> </h6>
            </Col>
          </Row>}
          <Row className="mb-2">
            <Col md={12}>
              <Form.Group className="mb-3">
                {
                  customCat && Array.isArray(customCat) && customCat.map((item, i) => (
                    <Col md={6} className="mb-2">
                      <FormCheck key={item.AlertId} type="checkbox" className="inbox-check me-2">
                        <FormCheck.Input id={i} className="me-2"
                          checked={item.isChecked ? item.isChecked : false}
                          value={item.AlertId} onChange={(e) => onCheckBoxChange(e, item)}
                        />
                        <FormCheck.Label> {item.AlertName} </FormCheck.Label>
                      </FormCheck>
                    </Col>
                  ))
                }
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-1">
            <Col md={12}>
              {showRnsCategory && <Form.Control.Feedback type="invalid" style={{ "display": "block" }}> {formError.rnsCatErr} </Form.Control.Feedback>}
            </Col>
            <Col md={12}>
              <h6 className="body-text mt-3 mb-1">{t("header.AlertAddSubscriberRns")}<span className="mandatory">*</span></h6>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col sm="12">
              <Form.Group>
                {rnsCatMaster && rnsCatMaster.length == 0 && <span >{t("header.AlertAddSubscriberRnsnodata")}</span>}
                {rnsCatMaster && rnsCatMaster.length > 0 && <div className="add-subscriber">
                  <Row>
                    <Col md={12} className="mb-2">
                      <FormCheck type="checkbox" className="inbox-check me-2">
                        <FormCheck.Input id="0" className="me-2"
                          checked={allTypesSelected ? true : false}
                          value={allTypesSelected}
                          onChange={(e) => onRnsCheckBoxChange(e, "All")}
                        />
                        <FormCheck.Label className="fw-bold">{t("header.AlertAddSubscriberAllTypes")}</FormCheck.Label>
                      </FormCheck>
                    </Col>
                    {rnsCatMaster && rnsCatMaster.length && rnsCatMaster.map((item, i) => (
                      <Col md={6} className="mb-2">
                        <FormCheck type="checkbox" className="inbox-check me-2">
                          <FormCheck.Input id={i} className="me-2"
                            checked={item.isChecked}
                            onChange={(e) => onRnsCheckBoxChange(e, item)}
                          />
                          <FormCheck.Label>{item.CategoryName} </FormCheck.Label>
                        </FormCheck>
                      </Col>
                    ))}
                  </Row>
                </div>}
              </Form.Group>
            </Col>
          </Row>
          {isRnsCat && isRnsCat.IsEmailVisible && <><Row className="mb-1">
            <Col md={12}>
              <h6 className="body-text mt-3 mb-1">{t("header.AlertAddSubscriberFormEmail")}</h6>
            </Col>
          </Row>
            <Row className="mb-2">
              <Col md="12">
                <Form.Group>
                  <FormCheck
                    type="radio"
                    value={formFields.isSubscriberHtml == 0 ? false : true}
                    label={t("header.AlertAddSubscriberHtml")}
                    keys=""
                    checked={formFields.isSubscriberHtml == 0 ? false : true}
                    defaultChecked={false}
                    name="isSubscriberHtml"
                    id="html"
                    onClick={(e) => onFormElementsChange(e)}
                  />
                  <FormCheck
                    type="radio"
                    value={formFields.isSubscriberText == 0 ? false : true}
                    label={t("header.AlertAddSubscriberText")}
                    keys=""
                    checked={formFields.isSubscriberText == 0 ? false : true}
                    defaultChecked={false}
                    name="isSubscriberText"
                    id="text"
                    onClick={(e) => onFormElementsChange(e)}
                  />
                </Form.Group>
              </Col>
            </Row></>}
        </Col>
      </Row>
      <Row>
        <Col md={6}>
        </Col>
        <Col md={6} className="text-end pt-3">
          <ButtonsComponent
            onClick={(e) => handleSubmitButtonClick(e)}
            isIcon={false}
            isPrimary={true}
            btn_class="me-3"
            btnText={t("header.WONotificationSetupSave")}
            dissable={isBtnDisabled}
          />
          <ButtonsComponent
            onClick={(e) => onCancelButtonClick(e)}
            isIcon={false}
            isPrimary={false}
            btn_class="mb-0"
            btnText={t("header.WONotificationSetupCancel")}
          />
        </Col>
      </Row>
    </ModalComponent>
  );
};

AddSubscriberModal.propTypes = {
  handleButtonClick: PropTypes.func,
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func,
  selectedCompany: PropTypes.array,
  setSelectedCompany: PropTypes.func,
  showAutoFillForCompany: PropTypes.bool,
  setAutoFillForCompany: PropTypes.func,
  formFields: PropTypes.object,
  setFormFields: PropTypes.func,
  formError: PropTypes.object,
  isFormSubmit: PropTypes.bool,
  validateFormData: PropTypes.func,
  setFormError: PropTypes.func,
  setIsFormSubmit: PropTypes.func,
};

AddSubscriberModal.defaultProps = {
  handleButtonClick: null,
  showModal: true,
  setShowModal: null,
  selectedCompany: {},
  setSelectedCompany: null,
  showAutoFillForCompany: false,
  setAutoFillForCompany: null,
  formFields: {},
  setFormFields: null,
  formError: {},
  isFormSubmit: false,
  validateFormData: null,
  setFormError: null,
  setIsFormSubmit: null,
};

export default AddSubscriberModal;
