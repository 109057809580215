import axios from "axios";
import { METHODS } from "./utilities/constant";
import { getDemoClientData, getDemoClientDataByKey } from "../utils/common";
///
//@param: methos: "post", "get" etc...
//@url: api url
//@payload: data for body part
//@header: header if any
///
export default function request(
    url,
    method,
    payload,
    headers
) {

    let allowMethods = ["get", "post", "put", "options", "delete"];

    return new Promise((resolve, reject) => {
        if (url && allowMethods.includes(method)) {
            if (method !== "post" && localStorage.getItem("isDemoClient") && getDemoClientData().find(x => x.key.includes(processUrl(url)))) {
                setTimeout(() => {
                    resolve(getDemoClientDataByKey(processUrl(url)));
                }, (Math.floor(Math.random() * 3) + 1) * 1000)
            }
            else {
                axios({
                    url,
                    method,
                    data: payload,
                    headers: {
                        ...headers
                    }
                })
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        if (error?.response?.status == 401) {
                            localStorage.clear();         // Clear All Entries from Local Stoarge
                            sessionStorage.clear();       // Clear All Entries from Session Stoarge
                            //window.location.reload();   
                            //window.location.href = "#/login";
                        }
                        reject(error.error);
                    })
            }
        }
        else {
            throw new Error("Invalid api method. please see valid methods - " + METHODS.toString())
        }
    })


}

export function requestPDF(
    url,
    method,
    payload,
    headers
) {

    let allowMethods = ["get", "post", "put", "options", "delete"];

    return new Promise((resolve, reject) => {
        if (allowMethods.includes(method)) {
            axios({
                url,
                method,
                data: payload,
                responseType: 'arraybuffer',
                headers: {
                    ...headers
                }
            })
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    if (error?.response?.status == 401) {
                        localStorage.clear();         // Clear All Entries from Local Stoarge
                        sessionStorage.clear();       // Clear All Entries from Session Stoarge
                    }
                    reject(error.error);
                })
        }
        else {
            throw new Error("Invalid api method. please see valid methods - " + METHODS.toString())
        }
    })


}

const processUrl = (url) => {
    const urlObj = new URL(url);
    const queryParams = new URLSearchParams(urlObj.search);
    const newParams = {};
    // Iterate over query parameters
    for (const [key, value] of queryParams.entries()) {
        if (['startdate', 'enddate', 'websiteurl', 'intellconfigid', 'companyid', 'intelconfigid', 'selectedcompanyid', 'countryid', 'visitorid', 'culture', 'toolid', 'traffic'].includes(key.toLowerCase())) {
            continue;
        }
        newParams[key] = value;
    }
    const searchParams = new URLSearchParams(newParams);
    urlObj.search = searchParams.toString();
    return urlObj.pathname.toString() + urlObj.search.toString().replace(/[\+\s]/g, '');
}
