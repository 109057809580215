import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { Row, Col, Image, Alert, Button } from '@themesberg/react-bootstrap';

import LoaderImage from "../../assets/img/LoaderGraphic.svg";
import iconSprite from "../../assets/Icon/svg-icn-sprite.svg";
import request from '../../apis/request';
import { decryptData } from '../../utils/crypto';
import { convertBase64 } from "../../utils/common";
import { baseURL, METHODS } from '../../apis/utilities/constant';
import { DataStatusMessage, WebcastHeaders } from "../../utils/constant";

import CommonMessageComponent from "../CommonMessageComponent";
import DataTableRenderer from "../DataTableRenderer";
import Search from "../../components/Search";
import { ERROR_TIMEOUT } from '../../AppConfig';

const WebcastManageUsers = ({ hasEvents, event, presentation, cryptoKey }) => {
    const [t, i18n] = useTranslation();

    const [users, setUsers] = useState({ transactionStatus: DataStatusMessage.Loading });
    const [masterUsers, setMasterUsers] = useState([]);

    const [searchData, setSearchData] = useState("");
    const [blurLoader, setBlurLoader] = useState(false);
    const [alertMessage, setAlertMessage] = useState(null);

    useEffect(() => {
        setUsers({ transactionStatus: DataStatusMessage.Loading });
        setSearchData("");
        getWebcastManageUser();
    }, [hasEvents, event, presentation]);

    useEffect(() => {
        let lstUsers = masterUsers;
        if (searchData !== "") {
            lstUsers = lstUsers.filter(item => item.email.toLowerCase().indexOf(searchData.toLowerCase()) > -1);
        }
        setUsers(prevState => ({ ...prevState, data: lstUsers, count: lstUsers.length }));
    }, [searchData]);

    const getWebcastManageUser = () => {
        let url = baseURL + '/Analytics/';
        if (hasEvents === 1) {
            url += `GetRegisteredUsers?eventId=${event["Events.id"]}`
        }
        else {
            url += `GetRegisteredUsersWebcast?ClientID=${presentation["Presentations.clientslug"]}&Slug=${presentation["Presentations.id"]}&Url=${presentation["Presentations.slug"]}`
        }
        request(url,
            METHODS.GET,
            {},
            {
                Authorization: `Bearer ${decryptData(localStorage.getItem("token"), cryptoKey)}`,
            })
            .then((res) => {
                if (res.data.length > 0) {
                    setUsers({ transactionStatus: DataStatusMessage.DataAvailable, header: hasEvents === 1 ? WebcastHeaders.ManageUserVETable : WebcastHeaders.ManageUserILTable, data: res.data, count: res.data.length });
                    setMasterUsers(res.data);
                }
                else {
                    setUsers({ transactionStatus: DataStatusMessage.DataUnAvailable });
                }
            })
            .catch((err) => {
                setUsers({ transactionStatus: DataStatusMessage.DataUnAvailable });
            })
    }

    const downloadManageUsersExcelFileWithEPPLus = async () => {
        setBlurLoader(true);
        let companyName = JSON.parse(decryptData(localStorage.getItem("selectedCompanyData"), cryptoKey))["companyName"];
        let paramsList = {}
        let url = ""
        let sortingObject = "";
        let orderBy = "";
        let orderByAsc = "";
        if (localStorage.getItem("sorting") !== null) {
            sortingObject = JSON.parse(localStorage.getItem("sorting"));
            orderBy = sortingObject.key;
            orderByAsc = sortingObject.sortOrder === "desc" ? true : false
        } else {
            orderBy = "registrationDate"
            orderByAsc = false;
        }
        if (hasEvents === 1) {
            url = "ExportVirtualEventRegisterUserEPPlus"
            paramsList = {
                eventName: event["Events.title"],
                eventId: event["Events.id"],
                companyName: companyName,
                orderBy: orderBy,
                orderByAsc: orderByAsc,
                culture: localStorage.getItem('i18nextLng') ? localStorage.getItem('i18nextLng') : 'en-US'
            }
        } else {
            url = "ExportWebcastRegisterUserEPPlus";
            paramsList = {
                WebcastName: presentation["Presentations.name"],
                ClientID: presentation["Presentations.clientid"],
                Slug: presentation["Presentations.id"],
                Url: presentation["Presentations.url"],
                companyName: companyName,
                orderBy: orderBy,
                orderByAsc: orderByAsc,
                culture: localStorage.getItem('i18nextLng') ? localStorage.getItem('i18nextLng') : 'en-US'
            }
        }

        request(baseURL + `/Website/${url}?encodeStr=${convertBase64(JSON.stringify(paramsList))}`,
            METHODS.GET,
            {},
            {
                Authorization: `Bearer ${decryptData(localStorage.getItem("token"), cryptoKey)}`,
            })
            .then((res) => {
                if (res) {
                    let fetchDataModified = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${res}`;
                    let a = document.createElement("a");
                    a.href = fetchDataModified;
                    a.download = hasEvents === 1 ? 'Virtual_Event_User_' + event["Events.title"] : 'Webcast_User_' + presentation["Presentations.name"] + '.xlsx';
                    a.click();
                }
                setBlurLoader(false);
            })
            .catch((err) => {
                setBlurLoader(false);
                console.error('Error generating Excel:', err);
            })
    };

    const changeEventAccessStatus = async (label, row) => {
        const eventStatus = label === "Disable" ? "disabled" : "enabled";
        try {
            const response = await request(baseURL + `/Analytics/ChangeEventAccessStatus?EventAccessStatus=${eventStatus}&EventId=${event["Events.id"]}&userId=${row.registrationId}`,
                METHODS.GET,
                {},
                {
                    Authorization: `Bearer ${decryptData(localStorage.getItem("token"), cryptoKey)}`, "content-type": "application/json"
                });
            if (response !== null) {
                if (response && response.status === 200) {
                    let index = -1;
                    let lstUsers = masterUsers;
                    index = masterUsers.findIndex(function (item) { return item.registrationId === row.registrationId });
                    lstUsers[index]["eventAccessStatus"] = lstUsers[index]["eventAccessStatus"] === "approved" ? "disabled" : "approved";
                    setUsers(prevState => ({ ...prevState, data: lstUsers }));
                    setAlertMessage({ type: "success", message: t("header.EventChangeStatusSuccessMessage") });
                } else {
                    setAlertMessage({ type: "danger", message: t("header.EventChangeStatusErrorMessage") });
                }
                setTimeout(() => {
                    setAlertMessage(null);
                }, ERROR_TIMEOUT);
            }
        } catch (err) {
            console.log("Error occured at changeEventStatus", err);
        }
    }

    const onCloseAlert = () => {
        setAlertMessage(null);
    }

    return (
        <>
            {blurLoader && <div id='blurdiv' className="blurbackground">
                <div className={`preloader loader-center-align`}>
                    <Image className="loader-element animate__animated animate__jackInTheBox" src={LoaderImage} height={40} data-seconds={30} />
                </div>
            </div>}
            {alertMessage && <div className="fade modal-backdrop show"></div>}
            <Alert
                className="toast-custom"
                variant={alertMessage?.type}
                show={alertMessage}
                onClose={() => onCloseAlert()}
            >
                <div className="d-flex justify-content-between">
                    <div>
                        <FontAwesomeIcon icon={faCheckCircle} className="me-2" />
                        {alertMessage?.message}
                    </div>
                    <Button variant="close" size="xs" onClick={() => onCloseAlert()} />
                </div>
            </Alert>
            {
                hasEvents === 0 &&
                <Row className="ps-3">
                    <Col xs={12} sm={12}>
                        <p>{t("header.webcastManageUsersMessage")}
                        </p>
                    </Col>
                </Row>
            }

            <div className="d-inline-block p-3 pe-0 w-100">
                <div class="shadow-soft border rounded border-light w-100 nested-card-container webcast-manage-users">
                    <div class="user-table shadow-sm card border-light">
                        <div class="card-body">
                            <Row className="mb-2">
                                <Col className={localStorage.getItem("isDemoClient") ? "custom-disable" : ""}>
                                    <div className="d-inline-block float-end page-search-box manage-users-page">
                                        <div className="icon-nav-list svg-icon-nav-list">
                                            <span className={users.data && users.data.length ? "icon-nav-box hide-mobile custom-select" : "icon-nav-box hide-mobile disabled"} onClick={() => downloadManageUsersExcelFileWithEPPLus()}>
                                                <svg className="icon-nav">
                                                    <title>{t("header.Icon_Tooltip_DownloadExcel")}</title>
                                                    <use href={iconSprite + `#Download`} />
                                                </svg>
                                            </span>
                                        </div>
                                    </div>
                                    <div className="d-inline-block float-end position-relative webcast-manage-users-search search-box-container-box mt-5">
                                        <div className="d-inline-block float-end search-box-container">
                                            <Search searchWaterMark={t("header.searchPlaceHolder")} setsearchData={setSearchData} searchData={searchData} isAutoComplete={false} type={'client'}></Search>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    {users.transactionStatus ===
                                        DataStatusMessage.DataAvailable && (
                                            <DataTableRenderer
                                                rowClickEvent={(row, index) =>
                                                    changeEventAccessStatus(row, index)
                                                }
                                                header={users.header}
                                                dataSet={users.data}
                                                transactionStatus={users.transactionStatus}
                                                customAttributes={{
                                                    displayRowsPerPage: 15,
                                                    showCount: true,
                                                    summaryCount: users.count,
                                                    summaryText: t(
                                                        "header.webcastManageUserBesideText"
                                                    ),
                                                    tooltipText: t("header.manageUsersUsersTooltipText"),
                                                    showPagination: true,
                                                }}
                                            ></DataTableRenderer>
                                        )
                                    }
                                    {users.transactionStatus !==
                                        DataStatusMessage.DataAvailable && (
                                            <CommonMessageComponent
                                                transactionStatus={
                                                    users.transactionStatus
                                                }
                                            ></CommonMessageComponent>
                                        )}
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default WebcastManageUsers;
