import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Row, Col } from "@themesberg/react-bootstrap";

import iconSprite from "../../assets/Icon/svg-icn-sprite.svg";
import { baseURL, METHODS } from '../../apis/utilities/constant';
import request from '../../apis/request';
import { decryptData } from '../../utils/crypto';
import { DataStatusMessage } from "../../utils/constant";

import CommonMessageComponent from "../CommonMessageComponent";
import DropdownWithIconsComponent from "../DropdownWithIcons";
import Progress from "../Progress";

const WebcastPolls = ({ hasEvents, cryptoKey, filteredPresentation, polls, setPolls, onPageRefresh }) => {

    const [t, i18n] = useTranslation();

    const [pollsRefresh, setPollsRefresh] = useState(false);

    const [questionsData, setQuestionsData] = useState([]);
    const [pollItems, setPollItemes] = useState([]);
    const [selectedPoll, setSelectedPoll] = useState({});
    const [pollName, setPollName] = useState("");
    const [totalQuestions, setTotalQuestions] = useState(0);
    const [totalSubmissions, setTotalSubmissions] = useState(0)
    const [showMessage, setShowMessage] = useState(false);
    const [updatedQuestionsData, setUpdatedQuestionsData] = useState([])

    useEffect(() => {
        if (polls.transactionStatus === DataStatusMessage.Loading || pollsRefresh) {
            setPollsData();
            if (pollsRefresh) {
                setPollsRefresh(false);
            }
        }
    }, [pollsRefresh]);

    useEffect(() => {
        if (onPageRefresh !== 0) {
            setPollsData();
        }
    }, [onPageRefresh])

    useEffect(() => {
        if (polls.data) {
            let items = [];
            if (polls.data !== undefined && polls.data.ResultData !== null) {
                setPollItemes([]);
                setPollName("");
                setSelectedPoll({});
                setQuestionsData(polls.data.ResultData);
                setShowMessage(false);
            }
            else if (polls.data !== undefined && polls.data.MultiPollResultData !== null) {
                if (polls.data.MultiPollResultData.length) {
                    polls.data.MultiPollResultData.forEach(element => {
                        items.push({ id: element._id, statusName: element.name, submissions: element.responseCount })
                    });
                }
                setPollItemes(items);
                setShowMessage(false);
            }
            else {
                setPollItemes([]);
                setQuestionsData(polls.data.ResultData);
                setShowMessage(true);
            }
        }
    }, [polls])

    useEffect(() => {
        let questions = 0;
        let count = 0;
        if (questionsData && questionsData.length > 0) {
            questionsData.map((element) => {
                questions++;
                if (element.answers.length) {

                    let duplicate = [...element.answers];
                    duplicate = duplicate.sort((a, b) => { return b.percentage - a.percentage });
                    let firstMax = "";
                    let secondMax = "";
                    firstMax = duplicate[0].percentage;

                    if (duplicate[1] !== undefined && duplicate[1].percentage) {
                        secondMax = duplicate[1].percentage;
                    }
                    if (firstMax !== secondMax) {
                        element.maxValue = firstMax
                    }
                    count = 0;
                    element.answers.map((submissionValue) => {
                        if (submissionValue.percentage > 0) {
                            count++;
                        }
                    })
                }
            });
        }
        setTotalQuestions(questions);
        setUpdatedQuestionsData(questionsData);
    }, [questionsData]);

    useEffect(() => {
        if (pollItems.length) {
            onPollDropdownChange(pollItems[0]);
        }
    }, [pollItems]);

    const onPollDropdownChange = (e) => {
        setSelectedPoll(e);
        setPollName(e.statusName);
        setTotalSubmissions(e.submissions);
        for (let i = 0; i < pollItems.length; i++) {
            if (polls.data.MultiPollResultData[i]._id === e.id) {
                setQuestionsData(polls.data.MultiPollResultData[i].questions);
                break;
            }
        }
    }

    const setPollsData = () => {
        if (hasEvents === 1) {
            setPolls({ transactionStatus: DataStatusMessage.Loading });
            request(baseURL + `/Analytics/GetPollDetail?presentationId=${filteredPresentation["Presentations.id"]}`,
                METHODS.GET,
                {},
                {
                    Authorization: `Bearer ${decryptData(localStorage.getItem("token"), cryptoKey)}`,
                    "content-type": "application/json",
                }
            )
                .then((res) => {
                    setPolls({ data: res, transactionStatus: DataStatusMessage.DataAvailable });
                })
                .catch((err) => {
                    setPolls({ transactionStatus: DataStatusMessage.DataUnAvailable });
                    console.error("Error: " + err);
                });
        }
    };

    return (
        <>
            {polls.transactionStatus ===
                DataStatusMessage.DataAvailable && (
                    <div className="single-poll-tab-content">
                        <div className="p-3 pt-0">
                            <Row>
                                <Col md={12}>
                                    <div className={`d-inline-block mb-4 w-100 ${pollItems && pollItems.length > 0 ? 'border-top border-bottom py-2' : ''}`}>
                                        <Row>
                                            <Col sm={4}>
                                                {
                                                    pollItems && pollItems.length > 0 &&
                                                    <DropdownWithIconsComponent
                                                        dropdownData={pollItems}
                                                        arrayValue="statusName"
                                                        onChange={(e) => onPollDropdownChange(e)}
                                                        showLabel={false}
                                                        showIconInDropdownItem={false}
                                                        selectedDropdown={selectedPoll}
                                                        isPrimaryImage={false}
                                                        customClassName="full-width text-overflow"
                                                    />
                                                }
                                            </Col>

                                            <Col sm={3}><span className="mt-2 d-inline-block">{pollItems && pollItems.length === 0 ? '' : totalQuestions + ' ' + t("header.WebcastViewersQuestions")} </span></Col>
                                            <Col sm={3}><span className="mt-2 d-inline-block">{pollItems && pollItems.length === 0 ? '' : totalSubmissions + ' ' + t("header.pollsSubmissions")}</span></Col>
                                            <Col sm={2} className="text-end mt-2">
                                                <div className="me-2 float-end polls-refresh-button">
                                                    <div className="icon-nav-list svg-icon-nav-list">
                                                        <span className={"icon-nav-box"} onClick={() => setPollsRefresh(true)}>
                                                            <svg className="icon-nav">
                                                                <title>{t("header.Icon_Tooltip_Refresh")}</title>
                                                                <use href={iconSprite + `#Sync`} />
                                                            </svg>
                                                        </span>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <div className="question-container">
                                        {
                                            updatedQuestionsData && updatedQuestionsData.length > 0 &&
                                            updatedQuestionsData.map((element, index) => <>
                                                <Row className="poll-question-block">
                                                    <Col><span className="d-inline-block poll-question-label mb-2 w-100">{'Question ' + (index + 1)}</span> <span className="d-inline-block fw-bold poll-question-txt">{element.question}</span></Col>
                                                    <Col>
                                                        {
                                                            element.answers && element.answers.length &&
                                                            element.answers.map((submission) => <><Row>
                                                                <Col>
                                                                    <Progress maxValue={element.maxValue === submission.percentage ? Math.round(submission.percentage) : ''} label={submission.value} value={Math.round(submission.percentage)} />
                                                                </Col>
                                                            </Row></>)
                                                        }
                                                    </Col>
                                                </Row>
                                            </>)
                                        }
                                    </div>
                                    {
                                        ((questionsData && questionsData.length <= 0) || (showMessage)) &&
                                        <div className="text-center no-data">{t("header.NoDataFound")}</div>
                                    }
                                </Col>
                            </Row>
                        </div>
                    </div>
                )}
            {polls.transactionStatus !==
                DataStatusMessage.DataAvailable && (
                    <CommonMessageComponent
                        transactionStatus={polls.transactionStatus}
                    ></CommonMessageComponent>
                )}
        </>
    );
}

export default WebcastPolls;