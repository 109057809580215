import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import html2pdf from "html2pdf.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { Col, Row, Nav, Image, Tab, Dropdown, ButtonGroup, Alert, Button } from '@themesberg/react-bootstrap';

import { DataStatusMessage, VITableHeaders } from "../../utils/constant.js";
import { decryptData } from '../../utils/crypto.js';
import { convertBase64 } from '../../utils/common.js';
import { METHODS } from '../../apis/utilities/constant.js';
import request, { requestPDF } from '../../apis/request.js';
import iconSprite from "../../assets/Icon/svg-icn-sprite.svg";
import LoaderImage from "../../assets/img/LoaderGraphic.svg";
import { convertToInternationalFormat, ERROR_TIMEOUT } from "../../AppConfig.js";

import Search from "../Search.js";
import ModalComponent from "../VoltModal.js";
import QuestionModal from "../QuestionModel.js";
import CommonMessageComponent from '../CommonMessageComponent.js';
import DataChartRenderer from '../DataChartRenderer.js';
import DataTableRenderer from '../DataTableRenderer.js';

export const VisitorAll = React.memo(({ cryptoKey, searchData, setSearchDataList, profileId, startDate, endDate, intelConfigId, companyId, userId, exportdata, filterParams, setDownloadEnable, isTF, tool, section, ...props }) => {
    const [t] = useTranslation();
    const [visitorResponse, setVisitorResponse] = useState({ transactionStatus: DataStatusMessage.Loading });
    const [visitorAll, setVisitorAll] = useState({ transactionStatus: DataStatusMessage.Loading });
    const [visitorAllPopupLinechart, setVisitorAllPopupLinechart] = useState({ transactionStatus: DataStatusMessage.Loading });
    const [vaPopupTableResponse, setVaPopupTableResponse] = useState({ transactionStatus: DataStatusMessage.Loading });
    const [visitorPagesViewedTable, setVisitorPagesViewedTable] = useState({ transactionStatus: DataStatusMessage.Loading });
    const [trafficSourceBarchart, setTrafficSourceBarchart] = useState({ transactionStatus: DataStatusMessage.Loading });
    const [trafficSourceTable, setTrafficSourceTable] = useState({ transactionStatus: DataStatusMessage.Loading });

    const [showVisitorAllModal, setShowVisitorAllModal] = useState(false);
    const [tabVisitorModal, setTabVisitorModal] = useState(0);
    const [showAutoFillForPopupSearch, setShowAutoFillForPopupSearch] = useState(false);
    const [popupSearchText, setPopupSearchText] = useState([]);
    const [popupSearchDataList, setPopupSearchDataList] = useState([]);
    const [trafficSourceText, setTrafficSourceText] = useState(t("header.VisitorsActivityPopTrafficChartOrganicHelpText"));
    const [trafficSourceTotal, setTrafficSourceTotal] = useState(0);
    const [trafficSource, setTrafficSource] = useState("Organic");
    const [rowDetails, setRowDetails] = useState([]);
    const [trafficSourceMetrices, setTrafficSourceMetrices] = useState({ transactionStatus: DataStatusMessage.DataUnAvailable });
    const [successMessage, setSuccessMessage] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);
    const [showLoader, setShowLoader] = useState(false);
    const [showQuestionModal, setShowQuestionModal] = useState(false);
    const [questionModalContent, setQuestionModalContent] = useState({});
    const [watchlistProfileData, setwatchlistProfileData] = useState({});
    const [alertVisible, setAlertVisible] = useState(false);
    const [toggle, setToggle] = useState(true);

    useEffect(() => {
        if (profileId) {
            setVisitorAllData();
            setTrafficSource("Organic")
        }
    }, [profileId, startDate, endDate, tool, section]);

    useEffect(() => {
        setPopupSearchText("");
        setToggle(!toggle);
        setShowAutoFillForPopupSearch(false);
        const selectedStartDate = moment(startDate).format("YYYY-MM-DD");
        const selectedEndDate = moment(endDate).format("YYYY-MM-DD");
        if (tabVisitorModal === 1) {
            let url = `${process.env.REACT_APP_API_KEY}/VisitorAnalytics/GetPageViewVisitByVisitor?profileid=${profileId}&startdate=${selectedStartDate}&enddate=${selectedEndDate}&visitorid=${rowDetails.companyId}&countryid=${rowDetails.countryId}`;
            if (isTF === 0 && section.sectionId > 0) {
                url += `&sectionid=${section.sectionId}&packageid=5`;
            }
            else {
                url += `&isTF=${isTF}&toolid=${isTF ? tool.toolID : 0}&intelconfigid=${intelConfigId}`
            }
            request(url,
                METHODS.GET, {},
                {
                    Authorization: `Bearer ${decryptData(localStorage.getItem("token"), cryptoKey)}`,
                    "content-type": "application/json"
                })
                .then((response) => {
                    if (response && response.transactionStatus === DataStatusMessage.DataAvailable) {
                        setVisitorAllPopupLinechart({ result: response.result, transactionStatus: response.transactionStatus })
                    } else {
                        setVisitorAllPopupLinechart({ result: [], transactionStatus: DataStatusMessage.ErrorOccured })
                    }
                }).catch(() => {
                    setVisitorAllPopupLinechart({ result: [], transactionStatus: DataStatusMessage.ErrorOccured })
                })

            url = `${process.env.REACT_APP_API_KEY}/VisitorAnalytics/GetIntelligencePagesByVisitor?profileid=${profileId}&startDate=${selectedStartDate}&endDate=${selectedEndDate}&countryid=${rowDetails.countryId}&visitorid=${rowDetails.companyId}&isTF=${isTF}&toolid=${isTF ? tool.toolID : 0}&intelconfigid=${intelConfigId}`;
            if (isTF === 0 && section.sectionId > 0) {
                url = `${process.env.REACT_APP_API_KEY}/VisitorAnalytics/GetIntelligencePageByVisitorSection?profileid=${profileId}&startDate=${selectedStartDate}&endDate=${selectedEndDate}&visitorcountryid=${rowDetails.countryId}&visitorid=${rowDetails.companyId}&pagesectionid=${section.sectionId}&packageid=5&selectedcompanyid=${companyId}`;
            }
            request(url,
                METHODS.GET, {},
                {
                    Authorization: `Bearer ${decryptData(localStorage.getItem("token"), cryptoKey)}`,
                    "content-type": "application/json"
                })
                .then((response) => {
                    if (response && response.transactionStatus === DataStatusMessage.DataAvailable) {
                        setVaPopupTableResponse(response);
                        setPopupSearchDataList(response.result.filterdata);
                        setVisitorPagesViewedTable({
                            header: isTF ? VITableHeaders.PagesViewedTFTable : VITableHeaders.PagesViewedTable, data: response.result.data, transactionStatus: response.transactionStatus
                        });

                    } else {
                        setVisitorPagesViewedTable({ result: [], transactionStatus: DataStatusMessage.ErrorOccured })
                    }
                }).catch(() => {
                    setVisitorPagesViewedTable({ result: [], transactionStatus: DataStatusMessage.ErrorOccured })
                })
        }

        else if (tabVisitorModal === 2) {
            let url = `${process.env.REACT_APP_API_KEY}/VisitorAnalytics/GetIntelligenceTrafficSourceByVisitor?profileid=${profileId}&startdate=${selectedStartDate}&enddate=${selectedEndDate}&companyid=${rowDetails.companyId}&countryid=${rowDetails.countryId}&isTF=${isTF}&toolid=${isTF ? tool.toolID : 0}&intelconfigid=${intelConfigId}`;
            if (isTF === 0 && section.sectionId !== 0) {
                url = `${process.env.REACT_APP_API_KEY}/VisitorAnalytics/GetIntelligenceTrafficSourceByVisitor?profileid=${profileId}&startdate=${selectedStartDate}&enddate=${selectedEndDate}&companyid=${rowDetails.companyId}&countryid=${rowDetails.countryId}&sectionid=${section.sectionId}&packageid=5`;
            }
            request(url,
                METHODS.GET, {},
                {
                    Authorization: `Bearer ${decryptData(localStorage.getItem("token"), cryptoKey)}`,
                    "content-type": "application/json"
                })
                .then((response) => {

                    if (response && response.transactionStatus === DataStatusMessage.DataAvailable) {
                        setTrafficSourceBarchart({ result: response.result, transactionStatus: response.transactionStatus })

                    } else {
                        setTrafficSourceBarchart({ result: [], transactionStatus: DataStatusMessage.ErrorOccured })

                    }
                }).catch(() => [
                    setTrafficSourceBarchart({ result: [], transactionStatus: DataStatusMessage.ErrorOccured })
                ])

            getTrafficSource(trafficSource);

        }
    }, [tabVisitorModal])

    useEffect(() => {
        const allData = visitorResponse.result

        if (allData && !searchData && Object.keys(filterParams).length == 0) {
            setVisitorAll(prevState => ({
                ...prevState,
                data: allData.data
            }))
        }

        if (allData && Object.keys(filterParams).length > 0 && searchData) {
            const filteredSearchData = searchContent(allData, searchData);

            let filteredobj = filteredSearchData;
            let filtervalueexists = 0;
            let filterfs2 = {};
            let columns = [];
            for (const obj in filterParams) {
                if (filterParams[obj] != "All" && filterParams[obj] != 0) {
                    filtervalueexists = 1;
                    filterfs2[`${obj}`] = filterParams[obj]
                    columns.push(obj)
                }
            }
            if (filtervalueexists == 1) {
                if (columns.length > 0) {
                    filteredobj = filteredobj.filter(function (item) {
                        if (item[columns[0]] === filterfs2[columns[0]]) {
                            return true;
                        }
                        return false;
                    });
                }
                if (columns.length > 1) {
                    filteredobj = filteredobj.filter(function (item) {
                        if (item[columns[1]] === filterfs2[columns[1]]) {
                            return true;
                        }
                        return false;
                    });
                }
            }
            setVisitorAll({ header: isTF ? VITableHeaders.VisitorAllTFTable : VITableHeaders.VisitorAllTable, data: filteredobj, transactionStatus: 1 })
        }
        else if (allData && searchData) {
            const filteredSearchData = searchContent(allData, searchData);
            setVisitorAll(prevState => ({
                ...prevState,
                data: filteredSearchData
            }))
        }
        else if (allData && Object.keys(filterParams).length > 0) {
            let filteredobj = allData.data;
            let filtervalueexists = 0;
            let filterfs2 = {};
            let columns = [];
            for (const obj in filterParams) {
                if (filterParams[obj] != "All" && filterParams[obj] != 0) {
                    filtervalueexists = 1;
                    filterfs2[`${obj}`] = filterParams[obj]
                    columns.push(obj)
                }
            }
            if (filtervalueexists == 1) {
                if (columns.length > 0) {
                    filteredobj = filteredobj.filter(function (item) {
                        if (item[columns[0]] === filterfs2[columns[0]]) {
                            return true;
                        }
                        return false;
                    });
                }
                if (columns.length > 1) {
                    filteredobj = filteredobj.filter(function (item) {
                        if (item[columns[1]] === filterfs2[columns[1]]) {
                            return true;
                        }
                        return false;
                    });
                }
            }
            setVisitorAll({ header: isTF ? VITableHeaders.VisitorAllTFTable : VITableHeaders.VisitorAllTable, data: filteredobj, transactionStatus: 1 })
        }
    }, [searchData, filterParams]);

    useEffect(() => {
        if (vaPopupTableResponse.result) {
            setVisitorPagesViewedTable(prevState => ({
                ...prevState,
                data: searchContent(vaPopupTableResponse.result, popupSearchText)
            }));
        }
    }, [popupSearchText]);

    const searchContent = (objSearch, searchText) => {
        let filteredResult = [];
        if (searchText) {
            if (objSearch.filterColumns && objSearch.filterColumns.length > 0) {
                const filtercolumns = objSearch.filterColumns;
                let filterfs = {};
                for (let i = 0; i < filtercolumns.length; i++) {
                    filterfs[filtercolumns[i]] = searchText;
                }
                let result = objSearch.data;
                filteredResult = result.filter(function (item) {
                    for (var key in filterfs) {
                        if (item[key]?.toLowerCase().includes(filterfs[key]?.toLowerCase())) {
                            return true;
                        }
                        else {
                            return false;
                        }
                    }
                });
            }
        }
        else {
            filteredResult = objSearch.data.slice();
        }
        return filteredResult;
    }

    const setVisitorAllData = () => {
        setVisitorResponse({ transactionStatus: DataStatusMessage.Loading });
        setVisitorAll({ transactionStatus: DataStatusMessage.Loading });
        const selectedStartDate = moment(startDate).format("YYYY-MM-DD");
        const selectedEndDate = moment(endDate).format("YYYY-MM-DD");
        let url = `${process.env.REACT_APP_API_KEY}/WebsiteAnalytics/GetVisitorWithWatchlistByProfileId?profileid=${profileId}&intellConfigId=${intelConfigId}&startDate=${selectedStartDate}&endDate=${selectedEndDate}&companyid=${companyId}&packageid=5&isTF=${isTF}&toolid=${isTF ? tool.toolID : 0}`;
        if (isTF === 0 && section.sectionId !== 0) {
            url = `${process.env.REACT_APP_API_KEY}/VisitorAnalytics/GetVisitorWithWatchlistBySection?profileid=${profileId}&intellConfigId=${intelConfigId}&startDate=${selectedStartDate}&endDate=${selectedEndDate}&companyid=${companyId}&pagesectionid=${section.sectionId}&packageid=5`;
        }
        request(url,
            METHODS.GET, {},
            {
                Authorization: `Bearer ${decryptData(localStorage.getItem("token"), cryptoKey)}`,
                "content-type": "application/json"
            })
            .then((response) => {
                if (response && response.transactionStatus === DataStatusMessage.DataAvailable) {
                    if (exportdata !== undefined) {
                        exportdata(response.result);
                    }
                    setVisitorResponse(response);
                    setSearchDataList(response.result.filterdata);
                    setVisitorAll({
                        header: isTF ? VITableHeaders.VisitorAllTFTable : VITableHeaders.VisitorAllTable, data: response.result.data, transactionStatus: response.transactionStatus
                    });
                    setDownloadEnable(response.result.data.length > 0);
                }
                else {
                    setVisitorAll({ transactionStatus: DataStatusMessage.DataUnAvailable });
                }
            })
            .catch(() => {
                setVisitorAll({ transactionStatus: DataStatusMessage.ErrorOccured });
            })
    }

    const getTrafficSource = (source) => {
        const selectedStartDate = moment(startDate).format("YYYY-MM-DD");
        const selectedEndDate = moment(endDate).format("YYYY-MM-DD");
        let TSType = source; //traffic source type

        if (trafficSourceMetrices.transactionStatus === DataStatusMessage.DataAvailable) {
            if (source === "Organic") {
                setTrafficSourceText(t("header.VisitorsActivityPopTrafficChartOrganicHelpText"));
                setTrafficSourceTotal((isTF ? t("header.TFallPagesTrafficSourceTotal1") : t("header.allPagesTrafficSourceTotal1")) + " " + t("header.barchart_Xaxis_Below_" + source) + " " + t("header.allPagesTrafficSourceTotal2") + " " + convertToInternationalFormat(trafficSourceMetrices.result.organic));
            }
            else if (source === "Direct") {
                setTrafficSourceText(t("header.VisitorsActivityPopTrafficChartDirectHelpText"));
                setTrafficSourceTotal((isTF ? t("header.TFallPagesTrafficSourceTotal1") : t("header.allPagesTrafficSourceTotal1")) + " " + t("header.barchart_Xaxis_Below_" + source) + " " + t("header.allPagesTrafficSourceTotal2") + " " + convertToInternationalFormat(trafficSourceMetrices.result.direct));
            }
            else if (source === "Referral") {
                setTrafficSourceText(t("header.VisitorsActivityPopTrafficChartReferralHelpText"));
                setTrafficSourceTotal((isTF ? t("header.TFallPagesTrafficSourceTotal1") : t("header.allPagesTrafficSourceTotal1")) + " " + t("header.barchart_Xaxis_Below_" + source) + " " + t("header.allPagesTrafficSourceTotal2") + " " + convertToInternationalFormat(trafficSourceMetrices.result.referral));
            }
            else if (source === "Email") {
                setTrafficSourceText(t("header.VisitorsActivityPopTrafficChartEmailHelpText"));
                setTrafficSourceTotal((isTF ? t("header.TFallPagesTrafficSourceTotal1") : t("header.allPagesTrafficSourceTotal1")) + " " + t("header.barchart_Xaxis_Below_" + source) + " " + t("header.allPagesTrafficSourceTotal2") + " " + convertToInternationalFormat(trafficSourceMetrices.result.email));
            }
            else if (source === "Social") {
                setTrafficSourceText(t("header.VisitorsActivityPopTrafficChartSocialHelpText"));
                setTrafficSourceTotal((isTF ? t("header.TFallPagesTrafficSourceTotal1") : t("header.allPagesTrafficSourceTotal1")) + " " + t("header.barchart_Xaxis_Below_" + source) + " " + t("header.allPagesTrafficSourceTotal2") + " " + convertToInternationalFormat(trafficSourceMetrices.result.social));
            }
            else if (source === "PaidSearch") {
                setTrafficSourceText(t("header.VisitorsActivityPopTrafficChartPaidSearchHelpText"));
                setTrafficSourceTotal((isTF ? t("header.TFallPagesTrafficSourceTotal1") : t("header.allPagesTrafficSourceTotal1")) + " " + t("header.barchart_Xaxis_Below_" + source) + " " + t("header.allPagesTrafficSourceTotal2") + " " + convertToInternationalFormat(trafficSourceMetrices.result.paidSearch));
            }
            getTrafficSourceTable(TSType, selectedStartDate, selectedEndDate);
        }
        else {
            let url = `${process.env.REACT_APP_API_KEY}/VisitorAnalytics/GetIntelligenceTrafficSourceByVisitor?profileid=${profileId}&startdate=${selectedStartDate}&enddate=${selectedEndDate}&companyid=${rowDetails.companyId}&countryid=${rowDetails.countryId}&isTF=${isTF}&getvalues=1&toolid=${isTF ? tool.toolID : 0}&intelconfigid=${intelConfigId}`;
            if (isTF === 0 && section.sectionId > 0) {
                url += `&sectionid=${section.sectionId}&packageid=5`
            }
            request(url,
                METHODS.GET, {},
                {
                    Authorization: `Bearer ${decryptData(localStorage.getItem("token"), cryptoKey)}`,
                    "content-type": "application/json"
                })
                .then((response) => {
                    if (response !== null) {
                        setTrafficSourceMetrices({ result: response, transactionStatus: DataStatusMessage.DataAvailable })
                        if (response.organic === 0) {
                            TSType = "Direct";
                        }
                        if (TSType === "Organic") {
                            setTrafficSourceText(t("header.VisitorsActivityPopTrafficChartOrganicHelpText"));
                            setTrafficSourceTotal((isTF ? t("header.TFallPagesTrafficSourceTotal1") : t("header.allPagesTrafficSourceTotal1")) + " " + t("header.barchart_Xaxis_Below_" + TSType) + " " + t("header.allPagesTrafficSourceTotal2") + " " + convertToInternationalFormat(response.organic));
                        }
                        else if (TSType === "Direct") {
                            setTrafficSourceText(t("header.VisitorsActivityPopTrafficChartDirectHelpText"));
                            setTrafficSourceTotal((isTF ? t("header.TFallPagesTrafficSourceTotal1") : t("header.allPagesTrafficSourceTotal1")) + " " + t("header.barchart_Xaxis_Below_" + TSType) + " " + t("header.allPagesTrafficSourceTotal2") + " " + convertToInternationalFormat(response.direct));
                        }
                        else if (TSType === "Referral") {
                            setTrafficSourceText(t("header.VisitorsActivityPopTrafficChartReferralHelpText"));
                            setTrafficSourceTotal((isTF ? t("header.TFallPagesTrafficSourceTotal1") : t("header.allPagesTrafficSourceTotal1")) + " " + t("header.barchart_Xaxis_Below_" + TSType) + " " + t("header.allPagesTrafficSourceTotal2") + " " + convertToInternationalFormat(response.referral));
                        }
                        else if (TSType === "Email") {
                            setTrafficSourceText(t("header.VisitorsActivityPopTrafficChartEmailHelpText"));
                            setTrafficSourceTotal((isTF ? t("header.TFallPagesTrafficSourceTotal1") : t("header.allPagesTrafficSourceTotal1")) + " " + t("header.barchart_Xaxis_Below_" + TSType) + " " + t("header.allPagesTrafficSourceTotal2") + " " + convertToInternationalFormat(response.email));
                        }
                        else if (TSType === "Social") {
                            setTrafficSourceText(t("header.VisitorsActivityPopTrafficChartSocialHelpText"));
                            setTrafficSourceTotal((isTF ? t("header.TFallPagesTrafficSourceTotal1") : t("header.allPagesTrafficSourceTotal1")) + " " + t("header.barchart_Xaxis_Below_" + TSType) + " " + t("header.allPagesTrafficSourceTotal2") + " " + convertToInternationalFormat(response.social));
                        }
                        else if (TSType === "PaidSearch") {
                            setTrafficSourceText(t("header.VisitorsActivityPopTrafficChartPaidSearchHelpText"));
                            setTrafficSourceTotal((isTF ? t("header.TFallPagesTrafficSourceTotal1") : t("header.allPagesTrafficSourceTotal1")) + " " + t("header.barchart_Xaxis_Below_" + TSType) + " " + t("header.allPagesTrafficSourceTotal2") + " " + convertToInternationalFormat(response.paidSearch));
                        }
                    }
                    getTrafficSourceTable(TSType, selectedStartDate, selectedEndDate);
                })
                .catch(() => {
                })
        }
    }

    const getTrafficSourceTable = (TSType, selectedStartDate, selectedEndDate) => {
        setTrafficSource(TSType);
        if (TSType === "Organic" || TSType === "Referral" || TSType === "Social") {
            let url = `${process.env.REACT_APP_API_KEY}/VisitorAnalytics/GetTopSourceMediumDomainsByTrafficSourceAndVisitor?profileid=${profileId}&startdate=${selectedStartDate}&enddate=${selectedEndDate}&countryid=${rowDetails.countryId}&companyid=${rowDetails.companyId}&traffic=${TSType}&isTF=${isTF}&toolid=${isTF ? tool.toolID : 0}&intelconfigid=${intelConfigId}`;
            if (isTF === 0 && section.sectionId > 0) {
                url = `${process.env.REACT_APP_API_KEY}/VisitorAnalytics/GetTopSourceMediumDomainsByTrafficSourceVisitorSection?profileid=${profileId}&startdate=${selectedStartDate}&enddate=${selectedEndDate}&selectedcompanyid=${companyId}&countryid=${rowDetails.countryId}&companyid=${rowDetails.companyId}&traffic=${TSType}&packageid=5&sectionid=${section.sectionId}`;
            }
            request(url,
                METHODS.GET, {},
                {
                    Authorization: `Bearer ${decryptData(localStorage.getItem("token"), cryptoKey)}`,
                    "content-type": "application/json"
                })
                .then((response) => {
                    if (response && response.transactionStatus === DataStatusMessage.DataAvailable) {
                        setTrafficSourceTable({ header: isTF ? VITableHeaders.TrafficSourceTFTable : VITableHeaders.TrafficSourceTable, data: response.result.data, transactionStatus: response.transactionStatus });
                    } else {
                        setTrafficSourceTable({ data: [], transactionStatus: DataStatusMessage.DataUnAvailable })
                    }
                })
                .catch(() => {
                    setTrafficSourceTable({ data: [], transactionStatus: DataStatusMessage.ErrorOccured })
                })
        } else {
            setTrafficSourceTable({ data: [], transactionStatus: DataStatusMessage.DataUnAvailable })
        }
    }

    const rowClickEvent = (rowobj) => {
        setShowVisitorAllModal(true);
        setRowDetails(rowobj.data)
        setTabVisitorModal(1);
    }

    const handleModalClose = () => {
        setShowVisitorAllModal(false);
        setTrafficSource("Organic");
        setTabVisitorModal(0);
        setVisitorAllPopupLinechart({ transactionStatus: DataStatusMessage.Loading })
        setVisitorPagesViewedTable({ transactionStatus: DataStatusMessage.Loading })
        setTrafficSourceBarchart({ transactionStatus: DataStatusMessage.Loading })
        setTrafficSourceTable({ transactionStatus: DataStatusMessage.Loading })
        setTrafficSourceMetrices({ transactionStatus: DataStatusMessage.DataUnAvailable })
    }

    const handleDeleteWatchlistButtonClick = (e, row) => {
        if (row.watched === 1) {
            e.preventDefault();
            setShowQuestionModal(true);
            let profiledta = {
                ProfileId: profileId,
                VisitorId: row.companyId,
                CountryId: row.countryId,
                UserID: userId,
                SelectedCompanyID: companyId,
                watch: 0,
                isTF: isTF,
                PackageId: 4
            };
            setQuestionModalContent({ type: "Watchlist", title: "header.WatchlistDeleteTitle", content: "header.WatchlistDeleteNote" })
            setwatchlistProfileData(profiledta);
        }
        else {
            e.preventDefault();
            let profiledta = {
                ProfileId: profileId,
                VisitorId: row.companyId,
                CountryId: row.countryId,
                UserID: userId,
                SelectedCompanyID: companyId,
                isTf: isTF,
                PackageId: 4
            };
            setShowLoader(true);
            request(`${process.env.REACT_APP_API_KEY}/VisitorAnalytics/SetWatchlist`,
                METHODS.POST, profiledta,
                {
                    Authorization: `Bearer ${decryptData(localStorage.getItem("token"), cryptoKey)}`,
                })
                .then((response) => {
                    if (response && response.successStatus === true) {
                        onEventchange("Modify", "-Successmsg-" + t("header.Message_Visitor_added_to_your_watchlist_successfully"));
                    } else {
                        onEventchange("", "-Errormsg-" + t("header.Something_went_wrong"));
                    }
                    setShowLoader(false);
                    localStorage.setItem("refreshCache", 1);
                })
                .catch((error) => {
                    console.log("error", error);
                    setShowLoader(false);
                })
        }
    }

    const onEventchange = (type, msg) => {
        if (type === "Modify") {
            setVisitorAllData();
        }
        if (msg.includes("-Successmsg-")) {
            setSuccessMessage(msg.replace("-Successmsg-", ""));
        }
        else if (msg.includes("-Errormsg-")) {
            setErrorMessage(msg.replace("-Errormsg-", ""));
        }
        setTimeout(() => {
            if (msg.includes("-Successmsg-")) {
                setSuccessMessage(undefined);
            }
            else if (msg.includes("-Errormsg-")) {
                setErrorMessage(undefined);
            }
        }, ERROR_TIMEOUT);
    }

    const onCloseSuccessMessage = () => {
        setSuccessMessage(undefined)
    };

    const saveWatchDeleteStatus = () => {
        setShowLoader(true);
        request(`${process.env.REACT_APP_API_KEY}/VisitorAnalytics/DeleteWatchlist`,
            METHODS.POST, watchlistProfileData,
            {
                Authorization: `Bearer ${decryptData(localStorage.getItem("token"), cryptoKey)}`,
            })
            .then((response) => {
                if (response && response.successStatus === true) {
                    onEventchange("Modify", "-Successmsg-" + t("header.Message_Visitor_removed_from_your_watchlist_successfully"));
                } else {
                    onEventchange("", "-Errormsg-" + t("header.Something_went_wrong"));
                }
                setShowLoader(false);
                localStorage.setItem("refreshCache", 1);
            })
            .catch((error) => {
                console.log("error", error);
                setShowLoader(false);
            })
    };

    const onSuccessModelClose = () => {
        setShowQuestionModal(false);
        if (questionModalContent.type === "Watchlist") {
            saveWatchDeleteStatus()
        }
    };

    const onCancelClose = () => {
        setShowQuestionModal(false);
    };

    const setDowloadDropdownMenu = () => {
        let ddList = ["Excel", "PDF"];
        return ddList.map(item => {
            return (
                <Dropdown.Item className='dropdown-item-download' onClick={() => downloadGrid(item)}>
                    {item}
                </Dropdown.Item>
            );
        })
    }

    const downloadGrid = (item) => {
        if (item === "Excel") {
            downloadExcel()
        }
        else {
            downloadPDF();
        }
    }

    const downloadExcel = async () => {
        setShowLoader(true);
        let paramsList = {
            profileid: profileId,
            profileName: props.profileData["WebsiteProfiles.DisplayName"],
            visitorid: rowDetails.companyId,
            countryid: rowDetails.countryId,
            startDate: moment(startDate).format("YYYY-MM-DD"),
            endDate: moment(endDate).format("YYYY-MM-DD"),
            visitor: rowDetails.companyName,
            companyName: props.companyName,
            pagesectionid: isTF ? tool.toolID : section.sectionId,
            pageSectionName: isTF ? tool.title : section.title,
            packageid: 5,
            companyid: companyId,
            webSiteName: props.websiteData["WebsiteList.WebsiteName"],
            culture: localStorage.getItem('i18nextLng') ? localStorage.getItem('i18nextLng') : 'en-US',
            isTF: isTF,
            toolid: isTF ? tool.toolID : 0,
            intelconfigid: intelConfigId
        }

        request(`${process.env.REACT_APP_API_KEY}/VisitorAnalytics/ExportVisitorPageViewExcelFileWithEPPlus?encodeStr=${convertBase64(JSON.stringify(paramsList))}`,
            METHODS.GET,
            {},
            {
                Authorization: `Bearer ${decryptData(localStorage.getItem("token"), cryptoKey)}`,
            })
            .then((res) => {
                if (res) {
                    let fetchDataModified = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${res}`;
                    let a = document.createElement("a");
                    a.href = fetchDataModified;
                    a.download = "Insights_Visitor.xlsx";
                    a.click();
                }
                setShowLoader(false);
            })
            .catch((err) => {
                setShowLoader(false);
                console.error('Error generating Excel:', err);
            })
    };

    const downloadPDF = async () => {
        setShowLoader(true);
        const selectedStartDate = moment(startDate).format("YYYY-MM-DD");
        const selectedEndDate = moment(endDate).format("YYYY-MM-DD");
        let url = `${process.env.REACT_APP_API_KEY}/VisitorAnalytics/GetIntelligencePagesByVisitor?profileid=${profileId}&startDate=${selectedStartDate}&endDate=${selectedEndDate}&countryid=${rowDetails.countryId}&visitorid=${rowDetails.companyId}&isTF=${isTF}&toolid=${isTF ? tool.toolID : 0}&intelconfigid=${intelConfigId}`;
        if (isTF === 0 && section.sectionId !== 0) {
            url = `${process.env.REACT_APP_API_KEY}/VisitorAnalytics/GetIntelligencePageByVisitorSection?profileid=${profileId}&startDate=${selectedStartDate}&endDate=${selectedEndDate}&visitorcountryid=${rowDetails.countryId}&visitorid=${rowDetails.companyId}&pagesectionid=${section.sectionId}&packageid=5&selectedcompanyid=${companyId}`;
        }
        let fileName = "Insights_Pages_Viewed.pdf";
        let filters = (popupSearchText !== "" && popupSearchText !== null) ? "pagesTitle|contains|" + popupSearchText : "";
        let obj = {
            Title: rowDetails.companyName,
            SubTitle: (isTF ? t("header.TFvisitorAllMessageText1") : t("header.visitorAllMessageText1")) + rowDetails.companyName + t("header.visitorAllMessageText2") + moment(startDate).format("DD MMM YYYY") + t("header.visitorAllMessageText3") + moment(endDate).format("DD MMM YYYY"),
            PageName: "Pages Viewed",
            Filters: filters,
            Url: url,
            Token: decryptData(localStorage.getItem("token"), cryptoKey),
            FileName: fileName,
            Email: decryptData(localStorage.getItem("email"), cryptoKey),
            Name: decryptData(localStorage.getItem("firstName"), cryptoKey),
            Count: 0,
            CountTitle: "",
            FilterContent: "",
            ClientName: "",
            TableHeaders: isTF ? VITableHeaders.PagesViewedTFTable : VITableHeaders.PagesViewedTable
        }

        requestPDF(`${process.env.REACT_APP_API_KEY}/CommonV2/GeneratePdf`,
            METHODS.POST,
            obj,
            {
                Authorization: `Bearer ${decryptData(localStorage.getItem("token"), cryptoKey)}`
            })
            .then((res) => {
                const contentType = res.headers['content-type'];
                if (res.status === 200) {
                    if (contentType?.includes('application/pdf')) {
                        const blob = new Blob([res.data], { type: 'application/pdf' });
                        const url = window.URL.createObjectURL(blob);
                        const a = document.createElement('a');
                        a.href = url;
                        a.download = fileName;
                        document.body.appendChild(a);
                        a.click();
                        window.URL.revokeObjectURL(url);
                    }
                    else {
                        setAlertVisible(true);
                        setTimeout(() => {
                            setAlertVisible(false);
                        }, 3000);
                    }
                    setShowLoader(false);
                }
                else {
                    setShowLoader(false);
                    console.error('Error generating PDF.');
                }
            })
            .catch((err) => {
                setShowLoader(false);
                console.error('Error generating PDF:', err);
            })
    };

    const downloadPageLevelPDF = async (pageName, fileName) => {
        setShowLoader(true);
        const content = document.createElement("div");
        content.innerHTML = document.querySelector('.tab-content .fade.tab-pane.active.show.' + pageName)?.innerHTML;
        const options = {
            filename: fileName,
            margin: [5, 2, 2, 2], //[top, right, bottom, left]
            image: { type: "jpeg", quality: 1 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: "mm", format: "a4", orientation: "landscape" }
        };
        const combinedNode = document.createElement("div");
        combinedNode.innerHTML = `<div class="row mt-3">
            <div class="col-md-12">
                <div class="ms-2 mb-0 pdf-download-title">${rowDetails.companyName}</div>
            </div>
        </div>`;
        combinedNode.appendChild(content.cloneNode(true));
        combinedNode.querySelectorAll('.pagination-content')?.forEach(btn => {
            btn.style.display = "none";
        });
        if (combinedNode.querySelector(".table-responsive-lg")) {
            combinedNode.querySelector(".table-responsive-lg")?.querySelectorAll("tr").forEach(tr => {
                tr.style.pageBreakInside = "avoid";
            });
        }
        combinedNode.querySelectorAll(".me-1")?.forEach(item => {
            item.style.display = "none";
        });
        combinedNode.querySelectorAll("a").forEach(link => {
            link.removeAttribute("href");
            link.style.pointerEvents = "none";
        });
        combinedNode.innerHTML = await covertSVGToIMG(combinedNode);
        await html2pdf().set(options).from(combinedNode).save().then(() => {
            setShowLoader(false);
        });
    }

    const loadImageAsync = (img) => {
        return new Promise((resolve, reject) => {
            img.onload = () => resolve(img);
            img.onerror = reject;
        });
    };

    const covertSVGToIMG = async (content) => {
        var dynamicSvgs = content.querySelectorAll('svg:not(.svg-inline--fa):not(.icon-nav)');

        for (let i = 0; i < dynamicSvgs.length; i++) {
            dynamicSvgs[i].querySelectorAll("line").forEach(x => {
                x.setAttribute("stroke", "#dddee2");
            });
            dynamicSvgs[i].querySelectorAll("path.domain[stroke='currentColor']").forEach(x => {
                x.style.display = "none";
            });

            var canvas = document.createElement('canvas');
            const scaleFactor = 2;
            canvas.width = dynamicSvgs[i].width.baseVal.value * scaleFactor;
            canvas.height = dynamicSvgs[i].height.baseVal.value * scaleFactor;

            var context = canvas.getContext('2d');
            context.scale(scaleFactor, scaleFactor);

            context.fillStyle = '#ffffff';
            context.fillRect(0, 0, canvas.width, canvas.height);

            var svgData = new XMLSerializer().serializeToString(dynamicSvgs[i]);
            var img = new window.Image();
            img.src = 'data:image/svg+xml,' + encodeURIComponent(svgData);

            await loadImageAsync(img);

            const scaleWidthFactor = 1;

            context.drawImage(img, 0, 0, img.width * scaleWidthFactor, img.height * scaleWidthFactor);

            var imgSrc = canvas.toDataURL('image/jpeg');
            var dynamicImg = document.createElement('img');
            dynamicImg.src = imgSrc;

            let parent = dynamicSvgs[i].parentNode;
            while (parent && parent !== content) {
                parent = parent.parentNode;
            }

            if (parent === content) {
                dynamicSvgs[i].parentNode.replaceChild(dynamicImg, dynamicSvgs[i]);
            }
        }
        return content.innerHTML;
    };

    return (
        <>
            {showLoader && <div id='blurdiv' className="blurbackground">
                <div className={`preloader loader-center-align`}>
                    <Image className="loader-element animate__animated animate__jackInTheBox" src={LoaderImage} height={40} data-seconds={30} />
                </div>
            </div>}
            {(successMessage || errorMessage) && <div className="fade modal-backdrop show"></div>}
            <Alert
                className="toast-custom"
                variant="success"
                show={successMessage ? true : false}
                onClose={() => onCloseSuccessMessage()}
            >
                <div className="d-flex justify-content-between">
                    <div>
                        <FontAwesomeIcon icon={faCheckCircle} className="me-2" />
                        {successMessage}
                    </div>
                    <Button variant="close" size="xs" onClick={() => onCloseSuccessMessage()} />
                </div>
            </Alert>

            {showQuestionModal && (
                <QuestionModal
                    onSuccessModelClose={onSuccessModelClose}
                    showModal={showQuestionModal}
                    onCancelClose={onCancelClose}
                    setShowModal={setShowQuestionModal}
                    setIsSuccess={setShowQuestionModal}
                    QuestionModalContent={questionModalContent}
                />
            )}
            {
                visitorAll.transactionStatus === DataStatusMessage.DataAvailable && <DataTableRenderer
                    header={visitorAll.header}
                    dataSet={visitorAll.data}
                    transactionStatus={visitorAll.transactionStatus}
                    customAttributes={
                        {
                            displayRowsPerPage: 15,
                            showPagination: true,
                            summaryText: t("header.visitorAllBesideText"),
                            tooltipText: t("header.VisitorsAllVisitorsCountHelpTooltipText"),
                            summaryCount: visitorAll.data.length, showCount: true
                        }}
                    rowClickEvent={rowClickEvent}
                    handleDeleteWatchlistButtonClick={handleDeleteWatchlistButtonClick}
                ></DataTableRenderer>
            }
            {visitorAll.transactionStatus !== DataStatusMessage.DataAvailable &&
                <Row>
                    <Col md={12}>
                        <CommonMessageComponent transactionStatus={visitorAll.transactionStatus}></CommonMessageComponent>
                    </Col>
                </Row>
            }
            <ModalComponent
                title={rowDetails.companyName}
                showCloseButton={true}
                open={showVisitorAllModal}
                handleClose={() => { handleModalClose() }}
                className="visitor-analytics-modal tnf-insights-modal">
                <Row>
                    <Col>
                        {showLoader && <div id='blurdiv' className="blurbackground">
                            <div className={`preloader loader-center-align`}>
                                <Image className="loader-element animate__animated animate__jackInTheBox" src={LoaderImage} height={40} data-seconds={30} />
                            </div>
                        </div>}
                        {alertVisible && <div className="fade modal-backdrop show"></div>}
                        <Alert
                            className="toast-custom"
                            variant="success"
                            show={alertVisible}
                        >
                            <div className="d-flex justify-content-between">
                                <div>
                                    <FontAwesomeIcon icon={faCheckCircle} className="me-2" />
                                    {t("header.PdfDeliveredToMailMessage")}
                                </div>
                            </div>
                        </Alert>
                        <Row className="position-relative hide-mobile"
                            hidden={tabVisitorModal == 2}>
                            <Col md={12}>
                                {/*Pages viewed download chart */}
                                <Row className="mb-4">
                                    <Col md={12} className={localStorage.getItem("isDemoClient") ? "custom-disable text-end" : "text-end"}>
                                        <div className="icon-nav-list svg-icon-nav-list float-end mb-2">
                                            <span className={(visitorAllPopupLinechart.transactionStatus === DataStatusMessage.DataAvailable || visitorPagesViewedTable.transactionStatus === DataStatusMessage.DataAvailable) ? "icon-nav-box ms-2 hide-mobile custom-select" : "icon-nav-box hide-mobile disabled"}
                                                onClick={() => downloadPageLevelPDF('visitorsContent', 'Insights_Pages_Viewed')}
                                            >
                                                <svg className="icon-nav">
                                                    <title>{t("header.Icon_Tooltip_DownloadPDF")}</title>
                                                    <use href={iconSprite + `#Download`} />
                                                </svg>
                                            </span>
                                        </div>
                                    </Col>
                                </Row>
                                {/* Pages viewed table search */}
                                <Row>
                                    <Col md={12} className={localStorage.getItem("isDemoClient") ? "custom-disable text-end" : "text-end"}>
                                        <div className="search-box-container modal-search-box">
                                            <Search
                                                setsearchData={setPopupSearchText}
                                                searchData={popupSearchText}
                                                searchWaterMark={t("header.visitorAllWatermark")}
                                                isAutoComplete={true}
                                                setAutoFillForCompany={setShowAutoFillForPopupSearch}
                                                showAutoFillForCompany={showAutoFillForPopupSearch}
                                                data={popupSearchDataList}
                                                type={"client"}
                                                emptyTextBox={toggle}
                                            ></Search>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row className="position-relative hide-mobile"
                            hidden={tabVisitorModal == 1}>
                            <Col md={12}>
                                {/* Traffic source download chart */}
                                <Row className="mb-4">
                                    <Col md={12} className={localStorage.getItem("isDemoClient") ? "custom-disable text-end" : "text-end"}>
                                        <div className="icon-nav-list svg-icon-nav-list float-end mb-2">
                                            <span className={(trafficSourceBarchart.transactionStatus === DataStatusMessage.DataAvailable || trafficSourceTable.transactionStatus === DataStatusMessage.DataAvailable) ? "icon-nav-box ms-2 hide-mobile custom-select" : "icon-nav-box hide-mobile disabled"}
                                                onClick={() => downloadPageLevelPDF('trafficContent', 'Insights_Traffic_Sources')}
                                            >
                                                <svg className="icon-nav">
                                                    <title>{t("header.Icon_Tooltip_DownloadPDF")}</title>
                                                    <use href={iconSprite + `#Download`} />
                                                </svg>
                                            </span>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <Tab.Container defaultActiveKey="ToolsViewed">
                            <Nav fill variant="pills" className="tab subtab ms-1 me-0">
                                <Nav.Item className="pe-0">
                                    <Nav.Link eventKey="ToolsViewed" onClick={() => { setTabVisitorModal(1) }} className="mb-sm-3 mb-md-0">
                                        {isTF ? t("header.TFVisitorsActivityPopPagesTabText") : t("header.VisitorsActivityPopPagesTabText")}
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="TrafficSources" onClick={() => { setTabVisitorModal(2) }} className="mb-sm-3 mb-md-0">
                                        {t("header.VisitorsActivityPopTrafficTabText")}
                                    </Nav.Link>
                                </Nav.Item>
                            </Nav>
                            <Tab.Content>
                                <Tab.Pane eventKey="ToolsViewed" className="mt-4 visitorsContent" >
                                    <Row className="mt-4">
                                        <Col md={12}>
                                            <p>{isTF ? t("header.TFvisitorAllMessageText1") : t("header.visitorAllMessageText1")} {rowDetails.companyName} {t("header.visitorAllMessageText2")} {moment(startDate).format("DD MMM YYYY")} {t("header.visitorAllMessageText3")} {moment(endDate).format("DD MMM YYYY")}</p>
                                        </Col>
                                    </Row>
                                    {tabVisitorModal === 1 &&
                                        <>
                                            {/* Pages viewed line chart */}
                                            <>
                                                {
                                                    visitorAllPopupLinechart.transactionStatus === DataStatusMessage.DataAvailable &&
                                                    <Row>
                                                        <Col md={12}>
                                                            <DataChartRenderer
                                                                data={
                                                                    visitorAllPopupLinechart.result
                                                                }
                                                                chartType="line" />
                                                        </Col>
                                                    </Row>
                                                }
                                                {
                                                    visitorAllPopupLinechart.transactionStatus !== DataStatusMessage.DataAvailable &&
                                                    <Row>
                                                        <Col md={12}>
                                                            <CommonMessageComponent transactionStatus={visitorAllPopupLinechart.transactionStatus}></CommonMessageComponent>
                                                        </Col>
                                                    </Row>
                                                }
                                            </>
                                            {/* table download */}
                                            <Row>
                                                <Col md={12} className={localStorage.getItem("isDemoClient") ? "custom-disable text-end" : "text-end"}>
                                                    <div className="icon-nav-list svg-icon-nav-list float-end mb-2">
                                                        <span className={visitorPagesViewedTable.transactionStatus === DataStatusMessage.DataAvailable ? "icon-nav-box ms-2 hide-mobile" : "icon-nav-box hide-mobile disabled"}>
                                                            <Dropdown as={ButtonGroup}>
                                                                <Dropdown.Toggle split className="card-dropdown-btn p-0 border-0 pdf-download-btn">
                                                                    <svg className="icon-nav">
                                                                        <title>{t("header.Icon_Tooltip_Download")}</title>
                                                                        <use href={iconSprite + `#Download`} />
                                                                    </svg>
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu>
                                                                    {setDowloadDropdownMenu()}
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </span>
                                                    </div>
                                                </Col>
                                            </Row>
                                            {/* Pages viewed table */}
                                            <>
                                                {
                                                    visitorPagesViewedTable.transactionStatus === DataStatusMessage.DataAvailable &&
                                                    <Row>
                                                        <Col md={12}>
                                                            {
                                                                visitorPagesViewedTable.transactionStatus === DataStatusMessage.DataAvailable && <DataTableRenderer
                                                                    header={visitorPagesViewedTable.header}
                                                                    dataSet={visitorPagesViewedTable.data}
                                                                    transactionStatus={visitorPagesViewedTable.transactionStatus}
                                                                    customAttributes={{ displayRowsPerPage: 10, showPagination: true }}
                                                                ></DataTableRenderer>
                                                            }
                                                        </Col>
                                                    </Row>
                                                }
                                                {
                                                    visitorAllPopupLinechart.transactionStatus !== DataStatusMessage.DataAvailable &&
                                                    <Row>
                                                        <Col md={12}>
                                                            <CommonMessageComponent transactionStatus={visitorAllPopupLinechart.transactionStatus}></CommonMessageComponent>
                                                        </Col>
                                                    </Row>
                                                }
                                            </>
                                        </>
                                    }
                                </Tab.Pane>

                                <Tab.Pane eventKey="TrafficSources" className="mt-4 trafficContent" >
                                    <Row className="mt-4">
                                        <Col md={12}>
                                            <p>{t("header.visitorAllTrafficMessageText1")} {rowDetails.companyName} {t("header.watchlistMessageText")} {moment(startDate).format("DD MMM YYYY")} {t("header.visitorAllTrafficMessageText2")} {moment(endDate).format("DD MMM YYYY")}</p>
                                        </Col>
                                    </Row>
                                    {tabVisitorModal === 2 &&
                                        <>
                                            {/* Traffic sources bar chart */}
                                            <>
                                                {trafficSourceBarchart.transactionStatus === DataStatusMessage.DataAvailable &&
                                                    <Row>
                                                        <Col md={12}>
                                                            <DataChartRenderer
                                                                data={
                                                                    trafficSourceBarchart
                                                                }
                                                                barChartClickEvent={function (d) { setTrafficSource(d.name); getTrafficSource(d.name); }}
                                                                chartType="bar" />
                                                        </Col>
                                                    </Row>
                                                }
                                                {
                                                    trafficSourceBarchart.transactionStatus !== DataStatusMessage.DataAvailable &&
                                                    <Row>
                                                        <Col md={12}>
                                                            <CommonMessageComponent transactionStatus={trafficSourceBarchart.transactionStatus}></CommonMessageComponent>
                                                        </Col>
                                                    </Row>
                                                }
                                            </>
                                            <Row className="mb-0">
                                                <Col md={12}>
                                                    {trafficSourceText &&
                                                        <div className="alert alert-gray-800" role="alert">
                                                            <p className="mb-0 text-body fw-bold"><i className="fa fa-info-circle lg"></i> {trafficSourceText}</p>
                                                        </div>
                                                    }
                                                </Col>
                                            </Row>
                                            {(trafficSourceBarchart.transactionStatus !== DataStatusMessage.Loading && trafficSourceTable.transactionStatus !== DataStatusMessage.Loading) &&
                                                <Row className="mb-0 mt-0">
                                                    <Col md={12} >
                                                        <p className="text-body fw-bold">{trafficSourceTotal} </p>
                                                    </Col>
                                                </Row>
                                            }

                                            {/* Traffic sources table */}
                                            {trafficSourceTable.transactionStatus === DataStatusMessage.DataAvailable &&
                                                <Row>
                                                    <Col md={12}>
                                                        {(trafficSource === "Organic" || trafficSource === "Referral" || trafficSource === "Social") &&
                                                            <DataTableRenderer
                                                                header={trafficSourceTable.header}
                                                                dataSet={trafficSourceTable.data}
                                                                transactionStatus={trafficSourceTable.transactionStatus}
                                                                customAttributes={{ displayRowsPerPage: 10, showPagination: true }}
                                                            ></DataTableRenderer>
                                                        }
                                                    </Col>
                                                </Row>
                                            }
                                            {
                                                trafficSourceTable.transactionStatus === DataStatusMessage.Loading &&
                                                <Row>
                                                    <Col md={12}>
                                                        <CommonMessageComponent transactionStatus={trafficSourceTable.transactionStatus}></CommonMessageComponent>
                                                    </Col>
                                                </Row>
                                            }
                                        </>
                                    }
                                </Tab.Pane>
                            </Tab.Content>
                        </Tab.Container>
                    </Col>
                </Row>
            </ModalComponent>
        </>
    );

});