import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from "moment";
import Datetime from "react-datetime";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt, faPlus } from "@fortawesome/free-solid-svg-icons";
import { Col, Row, Card, Form, InputGroup, FormCheck, Button, Modal } from '@themesberg/react-bootstrap';
import { isEmpty, BadgetsVarients, MAX_CHARACTERS_FOR_TEXT_AREA } from "../AppConfig";
import BadgesComponent from "../components/Badges";
import ButtonComponent from "../components/Buttons";
import { ReactComponent as TimeSvg } from '../assets/Icon/time.svg';

import AccordionComponent from "../components/AccordionComponent";
import { Tooltip, OverlayTrigger } from '@themesberg/react-bootstrap';
import Dropzone from "../components/Dropzone";
import ReactTagInput from "@pathofdev/react-tag-input";

import { Nav, Tab, Alert } from '@themesberg/react-bootstrap';
import { faCheckCircle, faExclamationCircle, faTimesCircle, faAngleDown, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { Dropdown, ButtonGroup } from '@themesberg/react-bootstrap';

//React text editor
import { Editor } from '@tinymce/tinymce-react';
import { DATE_FORMAT } from '../utils/constant';
// import Select, { components } from "react-select";
import { MultiSelect } from "react-multi-select-component";
import Autocompletee from './Autocomplete';

const FormsComponent = ({
    formData,
    setFormData,
    formError,
    validateFormData,
    isFormSubmit,
    hobbiesData,
    onSubmit,
    maxCharacters,
    setMaxCharacters
}) => {


    const onFormElementsChange = (event) => {
        const { name, value, id, checked } = event.target;
        let formVlue;
        if (name === "gender")
            formVlue = id;
        else if (name === "confirm" || name === "toggle")
            formVlue = checked;
        else
            formVlue = value;

        const forms = { ...formData };
        forms[name] = formVlue;
        setFormData && setFormData(forms);
        validateFormData && validateFormData(name, formVlue);

    };

    const onTextareaWithCharacterCountChange = (event) => {
        const { name, value } = event.target;
        const forms = { ...formData };
        const valueLength = value.length;
        forms[name] = value;
        setFormData && setFormData(forms);
        validateFormData && validateFormData(name, value);
        setMaxCharacters && setMaxCharacters(valueLength);


    };

    const onDatePickerChange = (date) => {
        const dta = moment(date).format("MM/DD/YYYY");
        const forms = { ...formData };
        forms.dob = dta;
        setFormData && setFormData(forms);
        validateFormData && validateFormData("dob", dta);
    };

    const validDOBDate = (current) => {
        // let currentDay = moment().format();
        let yesterday = moment().subtract(1, 'day');
        return current.isBefore(yesterday);
    };

    const onCheckBoxChange = (event, data) => {
        const { checked } = event.target;
        const forms = { ...formData };
        let hobList = [...formData.hobbies];
        if (checked) {
            hobList.push(data);
        }
        else {
            let hobbiesIndex = formData.hobbies.findIndex(dta => dta.key === data.key);
            if (hobbiesIndex > -1)
                hobList.splice(hobbiesIndex, 1);
        }
        forms.hobbies = hobList;
        setFormData && setFormData(forms);
        validateFormData && validateFormData("hobbies", hobList);
    }

    const [tags, setTags] = React.useState(["example tag"])

    const onFileUpload = (e) => {
        e.preventDefault();
        let file = e.target.files[0];
        let fileReader = new FileReader();
        // fileReader.onloadend = fileReader.result;

        let fileData = {};
        if (file) {
            fileData.name = file.name;
            fileData.size = file.size;// Sizes are in bytes 
            fileData.lastModifiedDate = file.lastModifiedDate;
            // fileData.append('File', file);
        }

        const forms = { ...formData };
        forms.upload = fileData;
        setFormData && setFormData(forms);
        validateFormData && validateFormData("upload", fileData);

        // reader.onloadend = () => {
        //     this.setState({
        //       file: file,
        //       imagePreviewUrl: reader.result
        //     });
        //   }


    }

    const renderHobbies = () => {
        if (hobbiesData && Array.isArray(hobbiesData) && hobbiesData.length > 0) {
            return hobbiesData.map((hobbiesDta, index) => {
                return (
                    <FormCheck type="checkbox" className="inbox-check me-2">
                        <FormCheck.Input id={hobbiesDta.key} className="me-2"
                            // checked={hobbiesDta.isChecked}
                            onChange={(e) => onCheckBoxChange(e, hobbiesDta)}
                        // value={row.isCheckBox} onChange={(e) => onCheckBoxChange(e, row, rowIndex)}
                        />
                        <FormCheck.Label> {hobbiesDta.name} </FormCheck.Label>
                    </FormCheck>
                );
            });
        }
    };

    //alert
    const [hiddenAlerts, setHiddenAlerts] = React.useState([]);

    const onClose = (alertId) => {
        const hiddenAlertsUpdated = [...hiddenAlerts, alertId];
        setHiddenAlerts(hiddenAlertsUpdated);
    };

    const shouldShowAlert = (alertId) => (
        hiddenAlerts.indexOf(alertId) === -1
    );

    //modal popup
    const [showDefault, setShowDefault] = useState(false);
    const handleClose = () => setShowDefault(false);

    const [showSignin, setShowSignin] = useState(false);
    const handleSessionClose = () => setShowSignin(false);

    const [showNotification, setShowNotification] = useState(false);
    const handleSessionExpiredClose = () => setShowNotification(false);


    const buttonClick = (e) => {
        e.preventDefault();
    }
    const options = [
        { value: 'chocolate', label: 'Chocolate' },
        { value: 'strawberry', label: 'Strawberry' },
        { value: 'vanilla', label: 'Vanilla' },
        { value: 'chocolate1', label: 'Chocolate1' },
        { value: 'strawberry1', label: 'Strawberry1' },
        { value: 'vanilla1', label: 'Vanilla1' },
        { value: 'chocolate2', label: 'Chocolate2' },
        { value: 'strawberry2', label: 'Strawberry2' },
        { value: 'vanilla2', label: 'Vanilla2' },
    ];
    const [selectedOption, setSelectedOption] = useState([]);
    const [value, setValue] = useState('');
    const API_URL = 'https://localhost:44311';
    function fetchSuggestions(text) {
        return fetch(`https://localhost:44311/Intelligence/SearchVisitorsByName?searchtext=test`)
            .then((response) => response.json());
    }

    return (

        <Card border="light" className="bg-white shadow-sm mb-4 ms-3" >
            <Card.Header>
                <Col>
                    <h4>Form Details</h4>
                </Col>

            </Card.Header>
            <Card.Body>

                <Form className="form-container" >
                    <Row>
                        <Col md={3} className="mb-2">
                            <Form.Label>Username</Form.Label>
                        </Col>
                        <Col md={6} className="mb-3">

                            {/* <Autocomplete
                                // value={value}
                                // onChange={(event, value) => setValue(value)}
                                // onSelect={(value) => setValue(value)}
                                getItemValue={(item) => item.value}
                                items={fetchSuggestions(value)}
                                renderItem={(item, isHighlighted) => (
                                    <div style={{ background: isHighlighted ? 'lightgray' : 'white' }}>
                                        {item.label}
                                    </div>
                                )}
                            /> */}
                            <Autocompletee apiurl="https://localhost:44311/Intelligence/SearchVisitorsByName?searchtext=inv"
                                placeholder="Company"></Autocompletee>

                            <MultiSelect
                                options={options}
                                value={selectedOption}
                                onChange={setSelectedOption}
                                labelledBy="Select"
                            />
                            {/* 
                            <Select
                                isMulti
                                value={selectedOption}
                                onChange={setSelectedOption}
                                options={options}
                            /> */}

                            {/* <Select
                            
                                defaultValue={[]}
                                isMulti
                                closeMenuOnSelect={false}
                                hideSelectedOptions={false}
                                onChange={(options) => {
                                    if (Array.isArray(options)) {
                                        setSelectedOption(options.map((opt) => opt.value));
                                    }
                                }}
                                options={options}
                                components={{
                                    Option: InputOption
                                }}
                            /> */}

                            <Form.Group className="mb-3">
                                {
                                    isFormSubmit ?
                                        (!isEmpty(formError.username) ?
                                            <Form.Control isInvalid type="text" name="username" value={formData.username} onChange={(e) => onFormElementsChange(e)} autoComplete="off" />
                                            : <Form.Control isValid type="text" name="username" value={formData.username} onChange={(e) => onFormElementsChange(e)} autoComplete="off" />
                                        )
                                        : <Form.Control type="text" name="username" value={formData.username} onChange={(e) => onFormElementsChange(e)} autoComplete="off" />
                                }
                                {(isFormSubmit && !isEmpty(formError.username)) &&
                                    <Form.Control.Feedback type="invalid">{formError.username} </Form.Control.Feedback>
                                }
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={3} className="mb-2">
                            <Form.Label>Email</Form.Label>
                        </Col>
                        <Col md={6} className="mb-3">
                            <Form.Group className="mb-3">
                                {
                                    isFormSubmit ?
                                        (!isEmpty(formError.email) ?
                                            <Form.Control isInvalid type="email" name="email" value={formData.email} onChange={(e) => onFormElementsChange(e)} />
                                            : <Form.Control isValid type="email" name="email" value={formData.email} onChange={(e) => onFormElementsChange(e)} />
                                        )
                                        : <Form.Control type="email" name="email" value={formData.email} onChange={(e) => onFormElementsChange(e)} />
                                }
                                {(isFormSubmit && !isEmpty(formError.email)) &&
                                    <Form.Control.Feedback type="invalid"> {formError.email} </Form.Control.Feedback>
                                }

                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={3} className="mb-2">
                            <Form.Label>Password</Form.Label>
                        </Col>
                        <Col md={6} className="mb-3">
                            <Form.Group className="mb-3">
                                {
                                    isFormSubmit ?
                                        (!isEmpty(formError.password) ?
                                            <Form.Control isInvalid type="password" name="password" value={formData.password} onChange={(e) => onFormElementsChange(e)} />
                                            : <Form.Control isValid type="password" name="password" value={formData.password} onChange={(e) => onFormElementsChange(e)} />
                                        )
                                        : <Form.Control type="password" name="password" value={formData.password} onChange={(e) => onFormElementsChange(e)} />
                                }
                                {(isFormSubmit && !isEmpty(formError.password)) &&
                                    <Form.Control.Feedback type="invalid"> {formError.password} </Form.Control.Feedback>
                                }
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={3} className="mb-2">
                            <Form.Label>Retype Password</Form.Label>
                        </Col>
                        <Col md={6} className="mb-3">
                            <Form.Group className="mb-3">
                                {
                                    isFormSubmit ?
                                        (!isEmpty(formError.confirmPassword) ?
                                            <Form.Control isInvalid type="password" name="confirmPassword" value={formData.confirmPassword} onChange={(e) => onFormElementsChange(e)} />
                                            : <Form.Control isValid type="password" name="confirmPassword" value={formData.confirmPassword} onChange={(e) => onFormElementsChange(e)} />
                                        )
                                        : <Form.Control type="password" name="confirmPassword" value={formData.confirmPassword} onChange={(e) => onFormElementsChange(e)} />
                                }
                                {(isFormSubmit && !isEmpty(formError.confirmPassword)) &&
                                    <Form.Control.Feedback type="invalid"> {formError.confirmPassword} </Form.Control.Feedback>
                                }
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={3} className="mb-2">
                            <Form.Label>Address</Form.Label>
                        </Col>
                        <Col md={6} className="mb-3">
                            <Form.Group className="mb-3">
                                {
                                    isFormSubmit ?
                                        (!isEmpty(formError.address) ?
                                            <Form.Control isInvalid as="textarea" rows="3" name="address" value={formData.address} onChange={(e) => onFormElementsChange(e)} />
                                            : <Form.Control isValid as="textarea" rows="3" name="address" value={formData.address} onChange={(e) => onFormElementsChange(e)} />
                                        )
                                        : <Form.Control as="textarea" rows="3" name="address" value={formData.address} onChange={(e) => onFormElementsChange(e)} />
                                }
                                {(isFormSubmit && !isEmpty(formError.address)) &&
                                    <Form.Control.Feedback type="invalid"> {formError.address} </Form.Control.Feedback>
                                }
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={3} className="mb-2">
                            <Form.Label>Address with character count</Form.Label>
                        </Col>
                        <Col md={6} className="mb-3">
                            <Form.Group className="mb-3">
                                {
                                    isFormSubmit ?
                                        (!isEmpty(formError.address1) ?
                                            <Form.Control isInvalid className="mb-1" as="textarea" rows="3" maxLength={MAX_CHARACTERS_FOR_TEXT_AREA} name="address1" value={formData.address1} onChange={(e) => onTextareaWithCharacterCountChange(e)} />
                                            : <Form.Control isValid className="mb-1" as="textarea" rows="3" maxLength={MAX_CHARACTERS_FOR_TEXT_AREA} name="address1" value={formData.address1} onChange={(e) => onTextareaWithCharacterCountChange(e)} />
                                        )
                                        : <Form.Control className="mb-1" as="textarea" rows="3" maxLength={MAX_CHARACTERS_FOR_TEXT_AREA} name="address1" value={formData.address1} onChange={(e) => onTextareaWithCharacterCountChange(e)} />
                                }
                                <span className="">{`(${maxCharacters} / ${MAX_CHARACTERS_FOR_TEXT_AREA})`}</span>
                                {(isFormSubmit && !isEmpty(formError.address1)) &&
                                    <Form.Control.Feedback type="invalid"> {formError.address1} </Form.Control.Feedback>
                                }
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={3} className="mb-2">
                            <Form.Label>Gender</Form.Label>
                        </Col>
                        <Col md={6} className="mb-3">
                            <Form.Group className="mb-3">
                                <FormCheck
                                    type="radio"
                                    value={formData.gender}
                                    label="Male"
                                    name="gender"
                                    id="male"
                                    onChange={(e) => onFormElementsChange(e)}
                                />
                                <FormCheck
                                    type="radio"
                                    value={formData.gender}
                                    label="Female"
                                    name="gender"
                                    id="female"
                                    onChange={(e) => onFormElementsChange(e)}
                                />
                                {(isFormSubmit && !isEmpty(formError.gender)) &&
                                    <span className="alert-msg-danger"> {formError.gender} </span>
                                }
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={3} className="mb-2">
                            <Form.Label>Hobbies</Form.Label>
                        </Col>
                        <Col md={6} className="mb-3">
                            <Form.Group className="mb-3">
                                {renderHobbies()}
                                {(isFormSubmit && !isEmpty(formError.hobbies)) &&
                                    <span className="alert-msg-danger"> {formError.hobbies} </span>
                                }
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={3} className="mb-2">
                            <Form.Label>test</Form.Label>
                        </Col>
                        <Col md={6} className="mb-3">
                            {/* <DropdownWithCheckbox
                                isDropdownDisabled={false}
                                dropdownData={[{ id: 1, text: "test1" }, { id: 1, text: "test2" }, { id: 1, text: "test3" }]}
                                arrayValue="text"
                                // selectedDropdown={}
                                // onChange={onComapnyDropdownChange}
                                showLabel={false}
                                showIconInDropdownItem={false}
                                isPrimaryImage={false}
                                customClassName="text-overflow"
                            /> */}

                            <Dropdown className="d-inline mx-2" autoClose={false}>
                                <Dropdown.Toggle id="dropdown-autoclose-false">
                                    Manual Close
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item href="#">Menu Item</Dropdown.Item>
                                    <Dropdown.Item href="#">Menu Item</Dropdown.Item>
                                    <Dropdown.Item href="#">Menu Item</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>

                        </Col>
                    </Row>
                    <Row>
                        <Col md={3} className="mb-2">
                            <Form.Label> Confirm ?</Form.Label>
                        </Col>
                        <Col md={6} className="mb-3">
                            <Form.Group className="mb-3">
                                <FormCheck type="checkbox" className="inbox-check me-2">
                                    <FormCheck.Input id="message-1" className="me-2" name="confirm"
                                        value={formData.confirm} onChange={(e) => onFormElementsChange(e)}
                                    />
                                    <FormCheck.Label>{formData.confirm ? "Yes" : "No"} </FormCheck.Label>
                                </FormCheck>
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={3} className="mb-2">
                            <Form.Label> Toggle </Form.Label>
                        </Col>
                        <Col md={6} className="mb-3">
                            <Form.Group className="mb-3">
                                <FormCheck type="switch">
                                    <FormCheck.Input
                                        type="checkbox" id="billingSwitch" className="me-2" name="toggle"
                                        value={formData.toggle} onChange={(e) => onFormElementsChange(e)}
                                    />
                                    <FormCheck.Label>{formData.toggle ? "On" : "Off"} </FormCheck.Label>
                                </FormCheck>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={3} className="mb-2">
                            <Form.Label> Date Of Birth </Form.Label>
                        </Col>
                        <Col md={6} className="mb-3">
                            <Form.Group className="mb-3">
                                <Datetime
                                    timeFormat={false}
                                    closeOnSelect={false}
                                    // onChange={setBirthday}
                                    onChange={onDatePickerChange}
                                    isValidDate={validDOBDate}
                                    renderInput={(props, openCalendar) => (
                                        <InputGroup >

                                            {
                                                isFormSubmit ?
                                                    (!isEmpty(formError.dob) ?
                                                        <>
                                                            <InputGroup.Text className="is-invalid"><FontAwesomeIcon icon={faCalendarAlt} /></InputGroup.Text>
                                                            <Form.Control
                                                                type="text"
                                                                autoComplete="off"
                                                                name="dob"
                                                                value={formData.dob ? moment(formData.dob).format(DATE_FORMAT) : ""}
                                                                placeholder="Select Date Of Birthday"
                                                                onFocus={openCalendar}
                                                                isInvalid
                                                                onChange={() => { }} />
                                                        </>
                                                        :
                                                        <>
                                                            <InputGroup.Text className="is-valid"><FontAwesomeIcon icon={faCalendarAlt} /></InputGroup.Text>
                                                            <Form.Control
                                                                type="text"
                                                                autoComplete="off"
                                                                name="dob"
                                                                value={formData.dob ? moment(formData.dob).format(DATE_FORMAT) : ""}
                                                                placeholder="Select Date Of Birthday"
                                                                onFocus={openCalendar}
                                                                isValid
                                                                onChange={() => { }} />
                                                        </>
                                                    )
                                                    :
                                                    <>
                                                        <InputGroup.Text><FontAwesomeIcon icon={faCalendarAlt} /></InputGroup.Text>
                                                        <Form.Control
                                                            type="text"
                                                            autoComplete="off"
                                                            name="dob"
                                                            value={formData.dob ? moment(formData.dob).format(DATE_FORMAT) : ""}
                                                            placeholder="Select Date Of Birthday"
                                                            onFocus={openCalendar}
                                                            onChange={() => { }} />
                                                    </>
                                            }

                                        </InputGroup>
                                    )} />
                                {(isFormSubmit && !isEmpty(formError.dob)) &&
                                    <small className="alert-msg-danger">{formError.dob}</small>
                                }

                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={3} className="mb-2">
                            <Form.Label> Upload </Form.Label>

                        </Col>
                        <Col md={6} className="mb-3">
                            {
                                isFormSubmit ?
                                    (!isEmpty(formError.upload) ?
                                        <Form.Control type="file" isInvalid onChange={(e) => onFileUpload(e)} />
                                        : <Form.Control type="file" isValid onChange={(e) => onFileUpload(e)} />
                                    )
                                    : <Form.Control type="file" onChange={(e) => onFileUpload(e)} />
                            }

                            {(isFormSubmit && !isEmpty(formError.upload)) &&
                                <small className="alert-msg-danger"> {formError.upload} </small>
                            }
                        </Col>
                    </Row>
                    <Row>
                        <Col md={3} className="mb-2"> <Form.Label>Button Examples</Form.Label>  </Col>
                        <Col md={2} className="mb-3">
                            <div>
                                <ButtonComponent
                                    isIcon={false}
                                    isPrimary={true}
                                    btnText="Button"
                                    btn_size="sm"
                                    onClick={(e) => buttonClick(e)}
                                />
                            </div>
                            <div>
                                <ButtonComponent
                                    isIcon={false}
                                    isPrimary={true}
                                    btnText="Button"
                                    onClick={(e) => buttonClick(e)}
                                />
                            </div>
                            <div>
                                <ButtonComponent
                                    isIcon={false}
                                    isPrimary={true}
                                    btnText="Button"
                                    btn_size="lg"
                                    onClick={(e) => buttonClick(e)}
                                />
                            </div>
                            <div>
                                <ButtonComponent
                                    isIcon={false}
                                    isPrimary={true}
                                    btnText="Button"
                                    dissable={true}
                                />
                            </div>

                        </Col>
                        <Col md={2} className="mb-3">
                            <div>
                                <ButtonComponent
                                    isIcon={false}
                                    isPrimary={false}
                                    btnText="Button"
                                    btn_size="sm"
                                    onClick={(e) => buttonClick(e)} />
                            </div>
                            <div>
                                <ButtonComponent
                                    isIcon={false}
                                    isPrimary={false}
                                    btnText="Button"
                                    onClick={(e) => buttonClick(e)} />
                            </div>
                            <div>
                                <ButtonComponent
                                    isIcon={false}
                                    isPrimary={false}
                                    btnText="Button"
                                    btn_size="lg"
                                    onClick={(e) => buttonClick(e)} />
                            </div>
                            <div>
                                <ButtonComponent
                                    isIcon={false}
                                    isPrimary={false}
                                    btnText="Button"
                                    dissable={true} />
                            </div>

                        </Col>
                        <Col md={2} className="mb-3">
                            <div>
                                <ButtonComponent
                                    isIcon={true}
                                    iconName={faPlus}
                                    isPrimary={true}
                                    btnText="Button"
                                    btn_size="sm"
                                    onClick={(e) => buttonClick(e)} />
                            </div>
                            <div>
                                <ButtonComponent
                                    isIcon={true}
                                    iconName={faPlus}
                                    isPrimary={true}
                                    btnText="Button"
                                    onClick={(e) => buttonClick(e)}
                                />
                            </div>
                            <div>
                                <ButtonComponent
                                    isIcon={true}
                                    iconName={faPlus}
                                    isPrimary={true}
                                    btnText="Button"
                                    btn_size="lg"
                                    onClick={(e) => buttonClick(e)} />
                            </div>
                            <div>
                                <ButtonComponent
                                    isIcon={true}
                                    iconName={faPlus}
                                    isPrimary={true}
                                    btnText="Button"
                                    dissable={true}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={3} className="mb-2"><Form.Label>Badge</Form.Label></Col>
                        <Col md={2} className="mb-3">
                            <BadgesComponent bgName={BadgetsVarients["success"]} message="Success" />
                        </Col>
                        <Col md={2} className="mb-3">
                            <BadgesComponent bgName={BadgetsVarients["warning"]} message="Warning" />
                        </Col>
                        <Col md={2} className="mb-3">
                            <BadgesComponent bgName={BadgetsVarients["error"]} message="Error" />
                        </Col>
                    </Row>

                    {/* modal popup */}
                    <Row className="mb-3">
                        <Col md={3}><Form.Label>Modal window</Form.Label></Col>
                        <Col md={9}>
                            <ButtonComponent
                                isIcon={false}
                                isPrimary={false}
                                btnText="Default modal"
                                btn_size="sm"
                                onClick={() => setShowDefault(true)} />

                            <Modal as={Modal.Dialog} size="xl" centered show={showDefault} onHide={handleClose}>
                                <Modal.Header>
                                    <Modal.Title className="h5">Modal window title</Modal.Title>
                                    <Button variant="close" aria-label="Close" onClick={handleClose} />
                                </Modal.Header>
                                <Modal.Body>
                                    <p>With less than a month to go before the European Union enacts new consumer privacy laws for its citizens, companies around the world are updating their terms of service agreements to comply.</p>
                                    <p>The European Union’s General Data Protection Regulation (G.D.P.R.) goes into effect on May 25 and is meant to ensure a common set of data rights in the European Union. It requires organizations to notify users as soon as possible of high-risk data breaches that could personally affect them.</p>
                                </Modal.Body>
                                <Modal.Footer>
                                    <ButtonComponent
                                        isIcon={false}
                                        isPrimary={true}
                                        btnText="Save"
                                        onClick={handleClose}
                                        btn_class="me-3" />
                                    <ButtonComponent
                                        isIcon={false}
                                        isPrimary={false}
                                        btnText="Cancel"
                                        onClick={handleClose}
                                        btn_class="mb-0" />
                                </Modal.Footer>
                            </Modal>
                        </Col>
                    </Row>

                    {/* session time out */}
                    <Row className="mb-3">
                        <Col md={3}><Form.Label>Session time out modal window</Form.Label></Col>
                        <Col md={9}>
                            <ButtonComponent
                                isIcon={false}
                                isPrimary={false}
                                btnText="Session time out"
                                btn_size="sm"
                                onClick={() => setShowSignin(true)} />

                            <Modal as={Modal.Dialog} size="lg" centered show={showSignin} onHide={handleSessionClose} className="modal-session-timeout">
                                <Modal.Header className="pt-0">
                                    <Modal.Title>Session time out</Modal.Title>
                                    {/* <Button variant="close" aria-label="Close" onClick={handleSessionClose} /> */}
                                </Modal.Header>
                                <Modal.Body>
                                    <p className="mb-4">Your session will expire in</p>
                                    <p className="session-time">
                                        {/* <FontAwesomeIcon icon={faClock} className="me-3" /> */}
                                        <span className="session-time-icon me-3">
                                            {/* <FontAwesomeIcon icon={faClock} className="me-3" /> */}
                                            <TimeSvg />
                                        </span>
                                        <span>1 min 54 secs</span>
                                    </p>
                                    <p className="note-msg">Please click 'Continue' to keep working, or click 'Log out' to end your session now.</p>
                                </Modal.Body>
                                <Modal.Footer className="pb-0">
                                    <ButtonComponent
                                        isIcon={false}
                                        isPrimary={true}
                                        btnText="Continue"
                                        onClick={handleSessionClose}
                                        btn_class="me-3" />
                                    <ButtonComponent
                                        isIcon={false}
                                        isPrimary={false}
                                        btnText="Logout"
                                        onClick={handleSessionClose}
                                        btn_class="mb-0" />
                                </Modal.Footer>
                            </Modal>
                        </Col>
                    </Row>


                    {/* session time out */}
                    <Row className="mb-3">
                        <Col md={3}><Form.Label>Session expired modal window</Form.Label></Col>
                        <Col md={9}>
                            <ButtonComponent
                                isIcon={false}
                                isPrimary={false}
                                btnText="Session expired"
                                btn_size="sm"
                                onClick={() => setShowNotification(true)} />

                            <Modal as={Modal.Dialog} size="md" centered show={showNotification} onHide={handleSessionExpiredClose} className="modal-session-timeout">
                                <Modal.Header className="pt-0">
                                    <Modal.Title>Session expired</Modal.Title>
                                    {/* <Button variant="close" aria-label="Close" onClick={handleSessionClose} /> */}
                                </Modal.Header>
                                <Modal.Body>
                                    <p className="session-time mt-4">
                                        <span className="session-time-icon me-3 session-expired-icon">
                                            {/* <FontAwesomeIcon icon={faClock} className="me-3" /> */}
                                            <TimeSvg />
                                        </span>
                                        <span className="session-time-txt body-text mt-3">Your session has expired.<br />Please sign in again.</span>
                                    </p>
                                </Modal.Body>
                                <Modal.Footer className="pb-0">
                                    <ButtonComponent
                                        isIcon={false}
                                        isPrimary={true}
                                        btnText="Ok"
                                        onClick={handleSessionExpiredClose}
                                        btn_class="me-3 fmw-100" />
                                </Modal.Footer>
                            </Modal>
                        </Col>
                    </Row>


                    {/* Tabs and subtabs */}
                    <Row className="mb-3">
                        <Col md={3}><Form.Label>Tabs</Form.Label></Col>
                        <Col md={9}>
                            <Tab.Container defaultActiveKey="home">
                                <Nav fill variant="pills" className="flex-column flex-sm-row tab">
                                    <Nav.Item className="pe-0">
                                        <Nav.Link eventKey="home" className="mb-sm-3 mb-md-0">
                                            Website Analytics
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="profile" className="mb-sm-3 mb-md-0">
                                            Visitor Analytics
                                        </Nav.Link>
                                    </Nav.Item>
                                </Nav>
                                <Tab.Content>
                                    <Tab.Pane eventKey="home" className="py-2">
                                        <p>Tab content one</p>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="profile" className="py-2">
                                        <p>Tab content two</p>
                                    </Tab.Pane>
                                </Tab.Content>
                            </Tab.Container>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col md={3}>
                            <Form.Label>Small tabs</Form.Label>
                        </Col>
                        <Col md={9}>
                            <Tab.Container defaultActiveKey="home">
                                <Nav fill variant="pills" className="flex-column flex-sm-row tab subtab">
                                    <Nav.Item>
                                        <Nav.Link eventKey="ac" className="mb-sm-3 mb-md-0">
                                            All categories
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="rns" className="mb-sm-3 mb-md-0">
                                            RNS
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="sec" className="mb-sm-3 mb-md-0">
                                            SEC
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="omx" className="mb-sm-3 mb-md-0">
                                            OMX
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="pr" className="mb-sm-3 mb-md-0">
                                            Press releases
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="Adhoc" className="mb-sm-3 mb-md-0">
                                            Adhoc
                                        </Nav.Link>
                                    </Nav.Item>
                                </Nav>
                                <Tab.Content>
                                    <Tab.Pane eventKey="ac" className="py-2">
                                        <p>Tab content one</p>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="rns" className="py-2">
                                        <p>Tab content two</p>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="sec" className="py-2">
                                        <p>Tab content three</p>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="omx" className="py-2">
                                        <p>Tab content four</p>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="pr" className="py-2">
                                        <p>Tab content five</p>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="Adhoc" className="py-2">
                                        <p>Tab content six</p>
                                    </Tab.Pane>
                                </Tab.Content>
                            </Tab.Container>
                        </Col>
                    </Row>

                    {/* Toast messages */}
                    <Row className="mb-3">
                        <Col md={3}><Form.Label>Toast messages</Form.Label></Col>
                        <Col md={9}>
                            <Alert
                                variant="success"
                                show={shouldShowAlert("success")}
                                onClose={() => onClose("success")}>

                                <div className="d-flex justify-content-between">
                                    <div>
                                        <FontAwesomeIcon icon={faCheckCircle} className="me-2" />
                                        New notification setup successfully
                                    </div>
                                    <Button variant="close" size="xs" onClick={() => onClose("success")} />
                                </div>
                            </Alert>

                            <Alert
                                variant="warning"
                                show={shouldShowAlert("warning")}
                                onClose={() => onClose("warning")}>

                                <div className="d-flex justify-content-between">
                                    <div>
                                        <FontAwesomeIcon icon={faExclamationCircle} className="me-2" />
                                        Generic warning message
                                    </div>
                                    <Button variant="close" size="xs" onClick={() => onClose("warning")} />
                                </div>
                            </Alert>

                            <Alert
                                variant="danger"
                                show={shouldShowAlert("danger")}
                                onClose={() => onClose("danger")}>

                                <div className="d-flex justify-content-between">
                                    <div>
                                        <FontAwesomeIcon icon={faTimesCircle} className="me-2" />
                                        Action failed
                                    </div>
                                    <Button variant="close" size="xs" onClick={() => onClose("danger")} />
                                </div>
                            </Alert>
                        </Col>
                    </Row>

                    {/* accordion */}
                    <Row>
                        <Col md={3}>
                            <Form.Label>Accordion</Form.Label>
                        </Col>
                        <Col md={6}>
                            <AccordionComponent
                                defaultKey="panel-1"
                                data={[
                                    {
                                        id: 1,
                                        eventKey: "panel-1",
                                        title: "Example one",
                                        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit."
                                    },
                                    {
                                        id: 2,
                                        eventKey: "panel-2",
                                        title: "Example two",
                                        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit."
                                    },
                                    {
                                        id: 3,
                                        eventKey: "panel-3",
                                        title: "Example three",
                                        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit."
                                    }
                                ]} />
                        </Col>
                    </Row>

                    {/* Help */}
                    <Row className="mt-2">
                        <Col md={3}>
                            <Form.Label>Help</Form.Label>
                        </Col>
                        <Col md={6}>
                            <OverlayTrigger
                                placement="right"
                                trigger={['hover', 'focus']}
                                overlay={
                                    <Tooltip>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam dignissim eget diam ut sagittis.</Tooltip>
                                }>
                                <Button variant="white" className="btn-help border-0 p-2">
                                    <i className="far fa-question-circle lg"></i>
                                </Button>
                            </OverlayTrigger>
                        </Col>
                    </Row>

                    {/* Drag anddrop to upload files */}
                    <Row className="mt-2">
                        <Col md={3}>
                            <Form.Label>Input slider</Form.Label>
                        </Col>
                        <Col md={6}>
                            <Dropdown as={ButtonGroup} className="slider-dropdown">
                                <Dropdown.Toggle split className="slider-dropdown-tigger border-0 p-0">
                                    <FontAwesomeIcon icon={faPlusCircle} size="lg" />
                                </Dropdown.Toggle>

                                <Dropdown.Menu className="border-0">
                                    <Dropdown.Item href="#" data-rb-event-key="#" className="mt-1">
                                        <Form.Control type="text" name="companyName" placeholder="Company name" value="" autoComplete="on" />
                                        {/* <Form.Control type="text" name="countryName" placeholder="Country name" value="" autoComplete="on" className="mt-2" /> */}
                                        <ButtonComponent
                                            isIcon={false}
                                            isPrimary={true}
                                            btnText="Add company"
                                            onClick=""
                                            btn_class="me-3 mt-3 w-100 border-0"
                                            btn_size="sm" />
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Col>
                    </Row>

                    {/* Drag anddrop to upload files */}
                    <Row className="mt-2">
                        <Col md={3}>
                            <Form.Label>Drag and drop to upload files</Form.Label>
                        </Col>
                        <Col md={6}>
                            <Dropzone />
                        </Col>
                    </Row>

                    {/* Drag anddrop to upload files */}
                    <Row className="mt-2">
                        <Col md={3}>
                            <Form.Label>Input chip</Form.Label>
                        </Col>
                        <Col md={6}>
                            <ReactTagInput
                                tags={tags}
                                onChange={(newTags) => setTags(newTags)}
                            />
                        </Col>
                    </Row>

                    {/* Rich text editor */}
                    <Row className="mt-2">
                        <Col md={3}>
                            <Form.Label>React text editor</Form.Label>
                        </Col>
                        <Col md={6}>
                            <Editor
                                apiKey='cusdapnwwhuz7xvb5putwhkp3f9735bnyfl244l8vii01iby'
                                plugins='lists code'
                                init={{ branding: false, keep_styles: true }}
                            />

                        </Col>
                    </Row>

                    {/* submit button */}
                    <Row className="mt-4">
                        <Col md={3} className="mb-2"> </Col>
                        <Col md={6} className="mb-3">
                            <ButtonComponent
                                onClick={(e) => onSubmit(e)}
                                isIcon={false}
                                iconName={faPlus}
                                isPrimary={true}
                                btnText="save" />
                        </Col>
                    </Row>

                </Form>
            </Card.Body>
        </Card>

    );

};

FormsComponent.propTypes = {
    formData: PropTypes.object,
    formError: PropTypes.object,
    setFormData: PropTypes.func,
    isFormSubmit: PropTypes.bool,
    hobbiesData: PropTypes.array,
    validateFormData: PropTypes.func,
    onSubmit: PropTypes.func,
    maxCharacters: PropTypes.number,
    setMaxCharacters: PropTypes.func

};
FormsComponent.defaultProps = {
    formData: {},
    formError: {},
    setFormData: null,
    isFormSubmit: false,
    hobbiesData: [],
    validateFormData: null,
    onSubmit: null,
    maxCharacters: 0,
    setMaxCharacters: null
}

export default FormsComponent;
