import React, { useContext, useEffect, useState } from 'react';
import RGL, { WidthProvider } from 'react-grid-layout';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import moment from "moment";
import { isMobile } from "react-device-detect";
import { useTranslation } from 'react-i18next';
import { Col, Row } from '@themesberg/react-bootstrap';

import { getSearchPage } from '../stubs/ComponentJson/search';
import { defaultLayout, defaultLayoutMobile } from "../utils/layoutConfig";

import AppContext from '../components/AppContext';
import DashboardItem from '../components/DashboardItem';
import ChartRendererTable from '../components/ChartRendererTable';
import Search from "../components/Search";

const ReactGridLayout = WidthProvider(RGL);

const SearchPageComponent = (props) => {
  const [t, i18n] = useTranslation();
  const contextData = useContext(AppContext);

  const [searchData, setSearchData] = useState([]); //data to show in table
  const [searchValue, setSearchValue] = useState(""); //search functionality

  const deserializeItem = (i) => ({
    ...i,
    layout: JSON.parse(i.layout) || {}
  });

  useEffect(() => {
    if (contextData.selectedProfile["WebsiteProfiles.ProfileId"]) {
      setSearchValue("")
      const selectedstartdate = moment(contextData.startDate).format("YYYY-MM-DD");
      const selectedenddate = moment(contextData.endDate).format("YYYY-MM-DD");
      setSearchData(getSearchPage(contextData.selectedProfile["WebsiteProfiles.ProfileId"], selectedstartdate, selectedenddate));
    }
    else {
      setSearchData(null);
    }
  }, [props.profileID, props.startDate, props.endDate]);

  const dashboardItem = (item) => (
    <div className="page-analytics-switch-toggle" key={item.id} data-grid={isMobile ? defaultLayoutMobile(item) : defaultLayout(item)}>
      <DashboardItem
        key={item.id}
        itemId={item.id}
        title={item.name}
        enableOption={item.enableOption}>
        <Row className="mb-4">
          <Col className={localStorage.getItem("isDemoClient") ? "custom-disable" : ""}>
            <div className="d-inline-block float-end page-search-box">
              <Search
                searchWaterMark={t("header.searchPlaceHolder")}
                key={item.name + "search"}
                setsearchData={setSearchValue}
                searchData={searchValue}
                type={'client'}
                isAutoComplete={false}>
              </Search>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <ChartRendererTable
              key={item.id + "table"}
              apiUrl={searchData[0].apiUrl}
              showPagination={true}
              showNumberOfRows={15}
              showCount={false}
              SearchText={searchValue}
              setSearchText={setSearchValue}
            />
          </Col>
        </Row>
      </DashboardItem>
    </div>
  );

  return (
    <>
      <Row>
        <Col lg={12} id="searchContent">
          <ReactGridLayout cols={12} rowHeight={50} isDraggable={false} isResizable={false}>
            {searchData && searchData.map(deserializeItem).map(dashboardItem)}
          </ReactGridLayout>
        </Col>
      </Row>
    </>
  );
};

export default SearchPageComponent;
