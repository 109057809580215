import React from "react";
import PropTypes from 'prop-types';
import { Badge } from '@themesberg/react-bootstrap';

import { isEmpty } from "../AppConfig";

const BadgesComponent = ({
    message,
    bgName,
    cls
}) => {

    return (
        <>
            {
                !isEmpty(bgName) &&
                <Badge bg={bgName} className={cls}>
                    {!isEmpty(message) && message}
                </Badge>
            }
        </>
    );

};

BadgesComponent.propTypes = {
    message: PropTypes.string,
    bgName: PropTypes.string
};

BadgesComponent.defaultProps = {
    message: "",
    bgName: ""
}

export default BadgesComponent;