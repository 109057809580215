import React, { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { faCheckCircle, faComment, faCookie } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert, Button } from "@themesberg/react-bootstrap";

import { getInitials } from '../utils/common';
import iconSprite from "../assets/Icon/svg-icn-sprite.svg";
import iconSprite2 from "../assets/Icon/icon-sprite.svg";

import AppContext from '../components/AppContext';
import Feedback from './Feedback';

function AvatarWithDropdown({ userName, logout }) {
    const navigate = useNavigate();
    const [t, i18n] = useTranslation();
    const contextData = useContext(AppContext);
    const dropdownRef = useRef(null);

    const [isDropdownOpen, setDropdownOpen] = useState(false);

    const [feedbackModal, setFeedbackModal] = useState(false);

    const [message, setMessage] = useState(undefined);

    useEffect(() => {
        if (isDropdownOpen) {
            document.addEventListener("click", handleClickOutside);
        }
        else {
            document.removeEventListener("click", handleClickOutside);
        }
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, [isDropdownOpen]);

    const handleClickOutside = (event) => {
        if (isDropdownOpen && dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setDropdownOpen(false);
        }
    };

    const toggleDropdown = () => {
        setDropdownOpen(!isDropdownOpen);
    };

    const handleTabClick = (tabName) => {
        localStorage.setItem("selectedTab", "Settings")
        contextData.changeSelectedTab("Settings")

        if (tabName === "myaccount") {
            navigate("/settings/myaccount-page");
        }
        if (tabName === "mydocuments") {
            navigate("/settings/mydocuments-page");
        }
        if (tabName === "digestsusbcriptions") {
            navigate("/settings/digestsubscriptions-page");
        }
        setDropdownOpen(false);
    }

    const handleFeedbackClick = () => {
        setDropdownOpen(false);
        setFeedbackModal(true)
    }

    const closeModal = () => {
        setFeedbackModal(false)
    }

    const onCloseSuccessMessage = () => {
        setMessage(undefined)
    };

    return (
        <div className="avatar" ref={dropdownRef}>
            <div className='avatar--container'>
                <div className='name-package'>
                    <span className="avatar--container__name">{userName}</span>
                </div>
                <div className="avatar--container__circle" onClick={() => toggleDropdown()}>
                    <span className="avatar--container__circle--image">{getInitials(userName)}</span>
                </div>
            </div>

            <div className={`avatar--dropdown-menu ${isDropdownOpen ? "open" : "closed"}`}>
                <ul>
                    <li onClick={() => handleTabClick("myaccount")}>
                        <svg>
                            <use href={iconSprite2 + `#account`} />
                        </svg>
                        {t("header.MyAccount")}
                    </li>
                    <li onClick={() => handleTabClick("mydocuments")}>
                        <svg>
                            <use href={iconSprite2 + `#file`} />
                        </svg>
                        {t("header.MyDocuments")}
                    </li>
                    <li onClick={() => handleTabClick("digestsusbcriptions")}>
                        <svg>
                            <use href={iconSprite2 + `#mail`} />
                        </svg>
                        {t("header.DigestSubscriptions")}
                    </li>
                    <li onClick={() => handleFeedbackClick()}>
                        <FontAwesomeIcon icon={faComment} className="fa-lg" />
                        {t("header.Feedback")}
                    </li>
                    <li id="cc-CookieSetting" onClick={() => setDropdownOpen(false)}>
                        <FontAwesomeIcon icon={faCookie} className="fa-lg" />
                        {t("header.CookieSettings")}
                    </li>
                    <li onClick={() => logout()}>
                        <svg>
                            <use href={iconSprite + `#Log out`} />
                        </svg>
                        {t("header.Logout")}
                    </li>
                </ul>
            </div>

            {message && <div className="fade modal-backdrop show"></div>}

            {message &&
                <Alert
                    className="toast-custom"
                    variant={message.status === "Success" ? "success" : "danger"}
                    show={message ? true : false}
                    onClose={() => onCloseSuccessMessage()}
                >
                    <div className="d-flex justify-content-between">
                        <div>
                            <FontAwesomeIcon icon={faCheckCircle} className="me-2" />
                            {message.message}
                        </div>
                        <Button variant="close" size="xs" onClick={() => onCloseSuccessMessage()} />
                    </div>
                </Alert>
            }

            {feedbackModal &&
                <Feedback cryptoKey={contextData.cryptoKey} setMessage={setMessage} onClose={closeModal} />
            }

        </div>
    );
}

export default AvatarWithDropdown;
