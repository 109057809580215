import React from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col } from '@themesberg/react-bootstrap';

function AddDocumentsPages() {
    const [t, i18n] = useTranslation();

    return (
        <div className="px-3">
            <Row className="mb-3">
                <Col lg={12}>
                    <h2 className="h2 mb-0">{t("header.AppAddDocTabTitle")}</h2>
                </Col>
            </Row>
        </div>
    )
}

export default AddDocumentsPages