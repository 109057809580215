import React from 'react';
import PropTypes from 'prop-types';
import { isMobile } from "react-device-detect";
import * as d3 from 'd3';
import { useTranslation } from 'react-i18next';
import '../i18n';

import { world } from '../topojson/world/world';
import {
    CHART_COLORS_SERIES,
    LINE_CHART_HEIGHT,
    MULTILINE_CHART_HEIGHT,
    chartMargin,
    getChartWidth,
    getChartHeight,
    chartMarginMobileMultiLineChart,
    chartMarginMobileBarChart,
    TOOLTIP_HIDE_TIMEOUT
} from "../utils/chartsConfig";
import { pageValuesByKey } from '../utils/constant';
import { countryCodes, convertTranslatinString } from '../utils/common'
import { convertToInternationalFormat } from '../AppConfig';

const DataChartsComponent = ({ isResFromAPI, data, isLegendShown = true, apiUrl, resultSetNew, resultSet, cardProperty, type, setd3, setD3Data, tableCaption, datafortooltip, barChartClickEvent, legendPercentage, ...props }) => {
    const [t, i18n] = useTranslation();

    const getTooltipHtml = (displayLabel, value, islabel, subjecttext) => {
        if (subjecttext !== undefined) {
            return "<Tooltip class='custom-tooltip'>" + displayLabel + "<br/>"
                + subjecttext + ": " + "<strong>" + convertToInternationalFormat(value) + "</strong>"
                + "</Tooltip>";
        }
        else if (islabel) {
            return "<Tooltip class='custom-tooltip'>" +
                t("header.map_country_" + convertTranslatinString(displayLabel)) + ": " + "<strong>" + convertToInternationalFormat(value) + "</strong>"
                + "</Tooltip>";
        }
        else {
            var name = displayLabel;
            return "<Tooltip class='custom-tooltip'>" +
                name + ": " + "<strong>" + convertToInternationalFormat(value) + "</strong>"
                + "</Tooltip>";
        }
    }


    const getTooltipHtmlForPieChart = (displayLabel, res) => {
        let value = res.find(x => x.title == displayLabel).toolTip;
        let toolTipTitle = res.find(x => x.title == displayLabel).ToolTipTitle;
        if (toolTipTitle !== undefined && value.indexOf(toolTipTitle) !== -1) {
            value = parseInt(value) + "% " + t("header.tooltip_suffix_" + convertTranslatinString(toolTipTitle), toolTipTitle);
        }
        return "<Tooltip class='custom-tooltip'>" + t("header.legend_" + convertTranslatinString(displayLabel), displayLabel) + ": " + "<strong>" + value + "</strong>" + "</Tooltip>";
    }

    const getTooltipHtmlForMultilineChart = (selectedData, allData, type) => {
        var formatLineDate = type != "monthly" ? d3.timeFormat("%d %b %Y") : d3.timeFormat("%b %Y")
        let displayDate = formatLineDate(selectedData.x);

        let displayObj = [];
        const showedValue = selectedData.value;
        allData && Array.isArray(allData) && allData.map((data) => {
            let key = pageValuesByKey.find(x => x?.key == data?.key)?.value || data.key;
            let values = data?.values?.filter((v) => { return formatLineDate(v.x) == formatLineDate(selectedData.x) });

            if (pageValuesByKey.find(x => x?.key == data?.key)?.isToolTip !== 0 && (key === selectedData.key || values[0].value === showedValue) && (datafortooltip && !datafortooltip.includes(data?.key))) {
                displayObj.push({
                    key,
                    value: convertToInternationalFormat(values[0].value)
                })
            }
        })

        var html = "<Tooltip class='custom-tooltip'>" +
            "<span><b>" + displayDate + "</b></span>" + "&nbsp" +
            displayObj.map((d) => (
                "<span>" + d.key + ": " + d.value + "</span>"
            ))
            + "</Tooltip>"
        return html;
    }

    const getTooltipHtmlForBar = (value, islabel) => {
        return "<Tooltip class='custom-tooltip'>" +
            "<strong>" + value + "</strong>"
            + "</Tooltip>";
    }

    const apiDrawPieChart = (node, resultSetNew, cardProperty, options) => {
        if (!resultSetNew || resultSetNew.length === 0) {
            return;
        }

        let sortedItems = [...resultSetNew];
        const remainingIndex = sortedItems.findIndex(item => item.title === "Remaining");

        if (remainingIndex !== -1) {
            const [remainingItem] = sortedItems.splice(remainingIndex, 1);
            sortedItems.sort((a, b) => b.TotalOpenCount - a.TotalOpenCount);
            sortedItems.push(remainingItem);
        } else {
            sortedItems.sort((a, b) => b.TotalOpenCount - a.TotalOpenCount);
        }

        let parentWidth = node.clientWidth;
        const pieWidth = Math.min(parentWidth / 2, 250);
        const pieHeight = pieWidth;
        const radius = pieWidth / 2 - 20;

        const values = sortedItems.map(s => s.TotalOpenCount);
        const data_ready = d3.pie().sort(null)(values);
        d3.select(node).html('');

        const displayLabel = sortedItems.map(s => s.title);

        const wrapper = d3.select(node)
            .append('div')
            .style('display', 'flex')
            .style('flex-direction', 'row')
            .style('align-items', 'center')
            .style('gap', '20px')
            .style('justify-content', 'center');

        const svg = wrapper.append('svg')
            .attr('viewBox', `0 0 ${pieWidth} ${pieHeight}`)
            .attr('width', pieWidth)
            .attr('height', pieHeight)
            .append('g')
            .attr('transform', `translate(${pieWidth / 2}, ${pieHeight / 2})`);

        let pieRadus = svg.append("g");

        let tooltip = d3.select("body")
            .append("div")
            .attr("class", "tooltip")
            .style("position", "absolute")
            .style("background", "#fff")
            .style("padding", "8px")
            .style("border", "1px solid #ccc")
            .style("border-radius", "5px")
            .style("box-shadow", "0px 2px 10px rgba(0,0,0,0.1)")
            .style("pointer-events", "none")
            .style("opacity", 0);

        pieRadus.selectAll('path')
            .data(data_ready)
            .enter()
            .append('path')
            .attr('d', d3.arc().innerRadius(0).outerRadius(radius))
            .attr('fill', (d, i) => CHART_COLORS_SERIES[i])
            .attr("stroke", "white")
            .style("stroke-width", "2px")
            .style("opacity", 1)
            .on("mousemove", function (event, d) {
                tooltip.html(getTooltipHtmlForPieChart(displayLabel[d.index], sortedItems))
                    .style("left", `${event.pageX + 10}px`)
                    .style("top", `${event.pageY - 20}px`)
                    .style("opacity", 1)
                    .style("display", "block")
                    .style("z-index", 9999);
            })
            .on("mouseout", () => tooltip.style("opacity", 0).style("display", "none"));

        const legendHolder = wrapper.append('div')
            .style('display', 'flex')
            .style('flex-direction', 'column')
            .style('align-items', 'flex-start')
            .style('gap', '5px')
            .style('min-width', '251px');

        const legend = legendHolder.selectAll(".legend")
            .data(displayLabel)
            .enter().append("div")
            .style('display', 'flex')
            .style('align-items', 'center');

        legend.append('div')
            .style('width', '10px')
            .style('height', '10px')
            .style('border-radius', '50%')
            .style('background-color', (d, i) => CHART_COLORS_SERIES[i])
            .style('margin-right', '5px');

        const totalCounts = data_ready.reduce((total, d) => total + d.value, 0);
        legend.append("span")
            .style("font-size", "12px")
            .style("font-weight", "400")
            .text(d => `${t("header.legend_" + convertTranslatinString(d), d)}`)
            .append("span")
            .style("font-weight", "700")
            .style("margin-left", "5px")
            .text((d, i) => `: ${legendPercentage ? Math.round((values[i] / totalCounts) * 100) + "%" : parseInt(values[i])}`);

        window.addEventListener('resize', () => {
            d3.select(node).html('');
            apiDrawPieChart(node, resultSetNew, cardProperty, options);
        });
    };

    const getCountryCode = (value) => {
        let countryCode = [{ code: "" }]
        let countryName = "";
        countryCode = countryCodes.filter((item) => {
            countryName = item.name;
            if (t("header.map_country_" + convertTranslatinString(countryName)).toLowerCase() === value.toLowerCase()) {
                return item
            }
        });
        if (countryCode.length && countryCode[0].code !== undefined) {
            return countryCode[0].code;
        } else {
            return ""
        }
    }

    const getRangeArray2 = (maxValue) => {
        const rateOfChange = [2, 7, 15, 30, 50, 80, 100];
        const rangeValues = [1];
        rateOfChange.forEach(element => {
            const val = Math.round((element / 100) * maxValue)
            rangeValues.push(val)
        });
        return rangeValues;
    }

    const Mapvalue = (node, resultSet) => {

        d3.select(node).html('');
        const svg = d3.select(node)
            .append('svg').attr("width", node.offsetWidth)
            .attr("height", 700),
            width = +svg.attr("width");

        // Map and projection
        const projection = d3.geoMercator()
            .center([0, 0])
            .rotate([-10, 0])
            .translate([width / 2, 1000 / 2]);

        // Data and color scale
        const rangeValues = [];
        let data = new Map()
        var colors = d3.scaleQuantize()
            .domain(rangeValues)
            .range(['#15a699', '#fd7c21', '#9ec62b', '#2e8ac6', '#af1f72', '#213540', '#505e79', '#c95e0e']);

        if (resultSet !== undefined && resultSet.length) {
            let countryCode = "";
            resultSet.map((element) => {
                countryCode = "";
                countryCode = getCountryCode(element["Country"]);
                if (countryCode !== "") {
                    data.set(countryCode, element["Count"])
                }
            })
        }

        let dta = world();
        let topo = JSON.parse(dta)
        setTimeout(() => {
            const allElements = topo.features.map((element) => element.total)
            const maxValue = Math.max(...allElements)

            const rangeArray = getRangeArray2(maxValue); // value increase at discrete range
            const colors = d3.scaleThreshold()
                .domain(rangeArray)
                .range(['#B1D0FE', '#98C1FE', '#7FB2FE', '#65A4FE', '#4C95FE', '#3386FF', '#1977FF', '#0068FF']); //shade of blue
            let mouseOver = function (event, d) {
                d3.selectAll(".Country")
                    .transition()
                    .duration(200)
                    .style("opacity", .5)
                d3.select(this)
                    .transition()
                    .duration(200)
                    .style("opacity", 1)
                    .style("stroke", "white");
                div.style("display", "none");
                div
                    .html(getTooltipHtml(d.properties.name, d.total, true))
                    .style("left", (event.pageX - 50) + "px")
                    .style("right", "auto")
                    .style("top", (event.pageY - 65) + "px")
                    .style("opacity", 1)
                    .style("display", "block");
            }

            let mouseLeave = function (d) {
                d3.selectAll(".Country")
                    .transition()
                    .duration(200)
                    .style("opacity", .8)
                d3.select(this)
                    .transition()
                    .duration(200)
                    .style("stroke", "transparent");
                div.html(" ").style("display", "none");
            }

            // Draw the map
            const g = svg.append("g");
            g.selectAll("path")
                .data(topo.features)
                .enter()
                .append("path")
                // draw each country
                .attr("d", d3.geoPath()
                    .projection(projection)
                )
                // set the color of each country
                .attr("fill", function (d) {
                    d.total = data.get(d.id) || 0;
                    return colors(d.total);
                })
                .style("stroke", "white")
                .attr("class", function (d) { return "Country" })
                .style("opacity", .8)
                .on("mouseover", mouseOver)
                .on("mouseleave", mouseLeave);
            let div = d3.select("body")
                .append("div")
                .attr("class", "tooltip")
                .style("opacity", 0);

            var zoom = d3.zoom()
                .scaleExtent([0, 8])
                .on('zoom', function (event) {
                    g.selectAll('path').attr('transform', event.transform);
                });

            svg.call(zoom);

            svg.on("wheel.zoom", null);

            var drag = d3.drag()
                .on("drag", function (event) {
                    g.attr("transform", `translate(${event.x}, ${event.y})`);
                });

            svg.call(drag);
            // Create a rectangle block to hold the buttons
            svg.append("rect")
                .attr("x", 10)
                .attr("y", 500)
                .attr("width", 170)
                .attr("height", 150)
                .style("fill", "#FDFDFD") //  background same as side bar 
                .style("stroke-width", "2px");

            // Zoom-in button
            svg.append("rect")
                .attr("x", 20)
                .attr("y", 510)
                .attr("width", 30)
                .attr("height", 30)
                .attr("class", "zoomButton")
                .style("fill", "#0068FF") // Background color for zoom-in button
                .style("cursor", "pointer")
                .style("rx", "5px") // Rounded corners
                .on("click", function () {
                    svg.transition().duration(500).call(zoom.scaleBy, 1.2); // Zoom-in by 20%
                });

            // Zoom-in text (+)
            svg.append("text")
                .attr("x", 35)
                .attr("y", 530)
                .attr("class", "zoomButton")
                .text("+")
                .style("font-size", "20px")
                .style("fill", "#fff") // Text color
                .style("pointer-events", "none") // Disable pointer events for text to allow clicking on the rectangle
                .style("text-anchor", "middle"); // Center text

            // Zoom-out button
            svg.append("rect")
                .attr("x", 60)
                .attr("y", 510)
                .attr("width", 30)
                .attr("height", 30)
                .attr("class", "zoomButton")
                .style("fill", "#0068FF") // Background color for zoom-out button
                .style("cursor", "pointer")
                .style("rx", "5px") // Rounded corners
                .on("click", function () {
                    svg.transition().duration(500).call(zoom.scaleBy, 0.8); // Zoom-out by 20%
                });

            // Zoom-out text (-)
            svg.append("text")
                .attr("x", 75)
                .attr("y", 530)
                .attr("class", "zoomButton")
                .text("-")
                .style("font-size", "20px")
                .style("fill", "#fff") // Text color
                .style("pointer-events", "none") // Disable pointer events for text to allow clicking on the rectangle
                .style("text-anchor", "middle"); // Center text


            // Add Title below buttons
            svg.append("text")
                .attr("x", 70)
                .attr("y", 570)
                .text(props.title ?? t("header.title_Events_by_country")) //text
                .style("font-size", "14px")
                .style("font-weight", "bold")
                .style("fill", "#333") // Text color
                .style("text-anchor", "middle"); // Center the text

            // Define the gradient
            var gradient = svg.append("defs")
                .append("linearGradient")
                .attr("id", "gradient1")
                .attr("x1", "0%")
                .attr("x2", "100%");

            gradient.append("stop")
                .attr("offset", "0%")
                .attr("stop-color", "#B1D0FE"); // Lightblue color (first color)

            gradient.append("stop")
                .attr("offset", "100%")
                .attr("stop-color", "#0068FF"); // Darkblue color (last color)

            // Add a rectangle with the gradient fill
            svg.append("rect")
                .attr("x", 20)  // Adjust x position
                .attr("y", 600) // Adjust y position
                .attr("width", 140) // Adjust width
                .attr("height", 20) // Adjust height
                .style("fill", "url(#gradient1)"); // Use the defined gradient

            // Add labels on both ends
            svg.append("text")
                .attr("x", 20) // Left position
                .attr("y", 635) // Just below the gradient bar
                .text("0") // Label for the minimum value
                .style("font-size", "12px")
                .style("fill", "#333"); // Text color

            svg.append("text")
                .attr("x", 150) // Right position (same as gradient width)
                .attr("y", 635) // Just below the gradient bar
                .text("Max.") // Label for the maximum value
                .style("font-size", "12px")
                .style("fill", "#333") // Text color
                .style("text-anchor", "end"); // Align the text to the right
        }, 1000)

        function createMap() {
            let mouseOver = function (event, d) {
                d3.selectAll(".Country")
                    .transition()
                    .duration(200)
                    .style("opacity", .5)
                d3.select(this)
                    .transition()
                    .duration(200)
                    .style("opacity", 1)
                    .style("stroke", "white");
                div.style("display", "none");
                div
                    .html(getTooltipHtml(d.properties.name, d.total, true))
                    .style("left", (event.pageX - 50) + "px")
                    .style("right", "auto")
                    .style("top", (event.pageY - 65) + "px")
                    .style("opacity", 1)
                    .style("display", "block");
            }

            let mouseLeave = function (d) {
                d3.selectAll(".Country")
                    .transition()
                    .duration(200)
                    .style("opacity", .8)
                d3.select(this)
                    .transition()
                    .duration(200)
                    .style("stroke", "transparent");
                div.html(" ").style("display", "none");
            }

            // Draw the map
            const g = svg.append("g");
            g.selectAll("path")
                .data(topo.features)
                .enter()
                .append("path")
                // draw each country
                .attr("d", d3.geoPath()
                    .projection(projection)
                )
                // set the color of each country
                .attr("fill", function (d) {
                    d.total = data.get(d.id) || 0;
                    return colors(d.total);
                })
                .style("stroke", "white")
                .attr("class", function (d) { return "Country" })
                .style("opacity", .8)
                .on("mouseover", mouseOver)
                .on("mouseleave", mouseLeave);
            let div = d3.select("body")
                .append("div")
                .attr("class", "tooltip")
                .style("opacity", 0);
            var zoom = d3.zoom()
                .scaleExtent([0, 8])
                .on('zoom', function (event) {
                    g.selectAll('path')
                        .attr('transform', event.transform);
                    g.selectAll("circle")
                        .attr('transform', event.transform);
                });




            g.selectAll("path").remove()

            svg.call(zoom);
        }
        createMap()
    }

    const apiDrawMultiLineChart = (resultSetNew, node, cardProperty, options) => {
        const type = resultSetNew && resultSetNew[0]?.type;
        const margin = isMobile ? chartMarginMobileMultiLineChart : chartMargin;

        const width = getChartWidth(node.clientWidth, margin);
        let height = getChartHeight(MULTILINE_CHART_HEIGHT, margin);

        let data = resultSetNew;

        let enableArrays = [];

        var formatLineDate = (type == "weekly" || type == "weeklystartingfrommonday" || type == "firstDayIsMonday" || type == "daily") ? d3.timeFormat("%d %b %Y") : d3.timeFormat("%b %Y")

        const parseDate = d3.timeParse("%Y-%m-%dT%H:%M:%S.%L");

        data.forEach((d, index) => {
            if (d.show) {
                enableArrays.push(d.key);
            }
            d.values.forEach(dd => {
                dd.x = typeof dd.x == 'string' ? parseDate(dd.x) : dd.x;
                dd.key = d.key;
                dd.value = +dd.value;
            });
            d.color = CHART_COLORS_SERIES[index];
        });

        d3.select(node).html('');
        let marginLeft = isMobile ? margin.left / 2 : margin.left;
        const svg = d3
            .select(node)
            .append('svg')
            .attr('class', "svgselector")
            .attr('width', width + margin.left + margin.right)
            .attr('height', height + margin.top + margin.bottom)
            .append('g')
            .attr('transform', 'translate(' + marginLeft + ',' + (margin.top) + ')');

        // Add X axis --> it is a date format
        const xExtent = data && data.length ? d3.extent(data[0].values, d => d.x) : [];
        const x = d3.scaleTime()
            .domain(xExtent)
            .range([0, width]);

        svg
            .append('g')
            .attr('transform', 'translate(0,' + height + ')')
            .style("font-size", "12px")
            .style("font-weight", "300")
            .style("color", "#3C3441")
            .call(d3.axisBottom(x).tickValues(
                data && data.length && data[0].values.map(function (d) {
                    return d.x;
                }
                ))
                .tickPadding(10)
                .tickSize(7)
                .tickFormat((d, i) => {
                    if (type !== "weekly" || i > 0) {
                        return formatLineDate(d);
                    }
                    else if (type === "weekly" && i === 0) {
                        return "";
                    }
                }))
        if (cardProperty.w <= 6 && !isMobile) {
            svg.selectAll("text")
                .attr('transform', "translate(0,1)rotate(-45)")
                .style('text-anchor', 'end');
        }
        else if (cardProperty.w > 6 && !isMobile) {
            svg.selectAll("text")
                .attr('transform', "translate(0,1)rotate(-45)")
                .style('text-anchor', 'end');
        }
        else if (isMobile) {
            svg.selectAll("text")
                .attr('transform', "translate(0,1)rotate(-45)")
                .style('text-anchor', 'end');
        }
        else {
            svg.selectAll("text")
                .attr('transform', "translate(0,1)rotate(-45)")
                .style('text-anchor', 'end');
        }

        // // Add Y axis
        let maxData = d3.max(data.map((s) => d3.max(s.values, (i) => i.value)));
        const y = d3.scaleLinear().domain([0, maxData]).nice().range([height, 0]);

        svg
            .append('g')
            .attr('transform', 'translate(' + -width / 100 + ',0)')
            .style("font-size", "12px")
            .style("font-weight", "300")
            .style("color", "#3C3441")

        if (maxData > 9) {
            svg.append("g")
                .attr("class", "grid")
                .call(d3.axisLeft(y)
                    .tickSize(-width)
                    .tickFormat(d3.format("~s"))
                    .tickPadding(10)
                );
        }
        else {
            svg.append("g")
                .attr("class", "grid")
                .call(d3.axisLeft(y)
                    .tickSize(-width)
                    .ticks(maxData)
                    .tickFormat(d3.format("d"))
                    .tickPadding(10)
                );
        }

        let line = d3.line()
            .x(d => x(d.x))
            .y(d => y(d.value))
            .curve(d3.curveMonotoneX);

        let div = d3.select("body")
            .append("div")
            .attr("class", "tooltip")
            .style("opacity", 0);

        svg.selectAll("myLines")
            .data(data)
            .enter()
            .append("path")
            .attr("class", function (d) { return d.key.replace(/\ /g, "") })
            .attr("d", (d) => line(d.values))
            .attr("stroke", (d) => d.color)
            .style("stroke-width", 3.5)
            .style("fill", "none")
            .style("opacity", function (d) { return d.show });
        // Add the points

        svg
            // First we need to enter in a group
            .selectAll("myDots")
            .data(data)
            .enter()
            .append('g')
            .attr("class", function (d) { return d.key.replace(/\ /g, "") })
            .style("fill", (d) => d.color)
            .style("opacity", function (d) { return d.show })
            // Second we need to enter in the 'values' part of this group
            .selectAll("myPoints").style("opacity", function (d) { return d.show })
            .attr("class", function (d) { return d.key.replace(/\ /g, "") })
            .data((d) => d.values)
            .enter()
            .append("circle")
            .attr("cx", (d) => x(d.x))
            .attr("cy", (d) => y(d.value))
            .attr("r", 5)
            .attr("stroke", "white")
            .style("opacity", function (d) { return d.show })
            .style("display", function (d) { return enableArrays.includes(d.key) ? "block" : "none" })
            .on("mousemove", function (event, dd) {
                d3.selectAll(".tooltip").style("display", "none");
                div.style("display", "none");
                div
                    .html(getTooltipHtmlForMultilineChart(dd, data, type)?.replace(/<\/span>,/g, "</span>"))
                    .style("left", (event.pageX - (event.offsetX > 200 ? 100 : 0)) + "px")
                    .style("right", "auto")
                    .style("top", (event.pageY + 10) + "px")
                    .style("opacity", 1)
                    .style("display", "block");
            })
            .on("mouseout", function () {
                div.html(" ").style("display", "none");
            });
        if (setd3) {
            setD3Data(d3);
        }
    };

    const apiLineChart = (data, node, cardProperty) => {
        let type = data.type ? data.type : "";
        const subjecttext = data.subjecttext;
        let copyData = data;
        data = [];
        if (copyData && copyData.values && Array.isArray(copyData.values) && copyData.values.length) {
            let d = {
                key: copyData.key,
                values: copyData.values
            }
            data.push(d)
        }

        const margin = isMobile ? chartMarginMobileMultiLineChart : chartMargin;
        const width = getChartWidth(node.clientWidth, margin),
            height = getChartHeight(LINE_CHART_HEIGHT, margin);

        d3.select(node).html('');
        const newMarginTop = isMobile ? margin.top + 30 : margin.top; // You can adjust the value as needed for mobile
        const svg = d3
            .select(node)
            .append('svg')
            .attr('width', width + margin.left + margin.right)
            .attr('height', height + newMarginTop + margin.bottom)
            .append('g')
            .attr('transform', 'translate(' + margin.left + ',' + newMarginTop + ')');
        const keys = data.map(s => s.key);
        let maxData, maxDataX;

        var formatLineDate = props.widjetName === "Viewersondemand" || (type == "weekly" || type == "weeklystartingfrommonday" || type == "firstDayIsMonday" || type == "daily") ? d3.timeFormat("%d %b %Y") :
            (props.widjetName === "Viewerslive" ? d3.timeFormat("%d %b %Y %H:%M") : d3.timeFormat("%b %Y"))

        const parseDate = d3.timeParse("%Y-%m-%dT%H:%M:%S.%L");

        if (data !== null && data !== undefined && data.length > 0) {
            data.forEach(function (d) {
                d.values.forEach(function (d) {
                    d.x = typeof d.x == 'string' ? parseDate(d.x) : d.x;
                    d.value = +d.value;
                });
            });
        }

        maxData = d3.max(data.map((s) => d3.max(s.values, (i) => i.value)));

        maxDataX = 6;

        let arrayDates = [];

        if (data.length && data[0].values !== undefined && data[0].values.length > 0) {
            data[0].values.forEach(function (e) {
                if (e.value > 0) {
                    arrayDates.push(formatLineDate(e.x));
                }
            });
        }

        const color = d3.scaleOrdinal().domain(keys).range(CHART_COLORS_SERIES);

        svg.select("#rotate")
            .append("svg")
            .attr("width", 1000)
            .attr("height", 300);

        let x;
        let xData = data;

        const xExtent = data.length && d3.extent(data[0].values, d => d.x);
        x = d3.scaleTime()
            .domain(xExtent)
            .range([0, width]);

        svg
            .append('g')
            .attr('transform', 'translate(0,' + height + ')')
            .style("font-size", "12px")
            .style("font-weight", "250")
            .style("color", "#3C3441")
            .call(d3.axisBottom(x)
                .tickValues(
                    xData && xData.length && xData[0].values.map(function (d) {
                        return d.x;
                    }
                    ))
                .tickPadding(10)
                .tickSize(7)
                .tickFormat((d, i) => {
                    if (type !== "weekly" || i > 0) {
                        return formatLineDate(d);
                    }
                    else if (type === "weekly" && i === 0) {
                        return "";
                    }
                }))

        if (cardProperty.w <= 6 && !isMobile && maxDataX > 4) {
            svg.selectAll("text")
                .attr('transform', "translate(0,1)rotate(-45)")
                .style('text-anchor', 'end');
        }
        else if (isMobile && maxDataX > 2) {
            svg.selectAll("text")
                .attr('transform', "translate(0,1)rotate(-40)")
                .style('text-anchor', 'end');
        }
        else {
            if (type === "weekly" || type === "weeklystartingfrommonday" || type === "monthly" || type === "daily" || type === "firstDayIsMonday") {
                svg.selectAll("text")
                    .attr('transform', "translate(-15,10)rotate(-45)")
                    .style('text-anchor', 'end');
            }
            else {
                svg.selectAll("text")
                    .attr('transform', "translate(20,1)")//rotate(-75)")
                    .style('text-anchor', 'end');
            }

        }
        const legendHolder = svg.append('g')
            // translate the holder to the right side of the graph
            .attr('transform', "translate(" + (-width / 2) + "," + (-margin.top) + ")")
            .attr('class', 'legendHolder');
        const legend = legendHolder.selectAll(".legend")
            .data(props.legends ?? [])
            .enter().append("g")
            .attr("class", "legend")
            .attr('transform', function (d, i) {
                if (isMobile) {
                    // Adjust the legend position slightly up for mobile
                    return "translate(" + -(height / 2) * i + "," + (-25) + ")";
                } else {
                    return "translate(" + -(height / 2) * i + "," + 0 + ")";
                }
            });

        legend
            .append('circle')
            .attr('cx', (d, i) => (width / 2) + (height * i))
            .attr('cy', height / 20)
            .attr("r", 5)
            .style('fill', (d) => color(d.key));

        legend.append("text")
            .attr("x", (d, i) => (width / 2) + (height * i) + 10)
            .attr("y", height / 20)
            .text((d) => t("header.Linechart_Legend_" + d.name, d.name))
            .attr("alignment-baseline", "middle");

        let y = d3.scaleLinear()
            .domain([0, maxData]).nice()
            .range([height, 0]);

        if (maxData < 10) {
            let counttobeadded = 10 - maxData;
            y = d3.scaleLinear()
                .domain([0, maxData + counttobeadded]).nice()
                .range([height, 0]);
        }

        svg
            .append('g')
            .attr('transform', 'translate(' + -width / 100 + ',0)')
            .style("font-size", "12px")
            .style("font-weight", "300")
            .style("color", "#3C3441");

        svg.append("g")
            .attr("class", "grid")
            .call(d3.axisLeft(y)
                .tickSize(-width)
                .tickFormat((d) => { return d })
                .tickPadding(10)
            );
        // create a tooltip
        var Tooltip = d3.select("#my_dataviz")
            .append("div")
            .style("opacity", 0)
            .attr("class", "tooltip")
            .style("background-color", "white")
            .style("border", "solid")
            .style("border-width", "2px")
            .style("border-radius", "5px")
            .style("padding", "5px")

        // Three function that change the tooltip when user hover / move / leave a cell

        let div = d3.select("body")
            .append("div")
            .attr("class", "tooltip")
            .style("opacity", 0);

        svg
            .append("path")
            .datum(data.length && data[0].values ? data[0].values : [])
            .attr('fill', 'none')
            .attr('stroke', (d) => color(d.key))
            .attr('stroke-width', 3.5)
            .attr("d", d3.line()
                .x(function (d) {
                    return x(d.x)
                })
                .y(function (d) {
                    return y(d.value)
                })
                .curve(d3.curveMonotoneX)
            );

        // Add the points
        svg
            .append("g")
            .selectAll("dot")
            .data(data.length && data[0].values ? data[0].values : [])
            .enter()
            .append("circle")
            .attr("cx", function (d) { return d.value > 0 ? x(d.x) : x(d.x) })
            .attr("cy", function (d) { return y(d.value) })
            .attr("r", 5)
            .attr("fill", function (d) { return d.value > 0 ? CHART_COLORS_SERIES[1] : "transparent" })
            .on("mousemove", function (event, d) {
                div.style("display", "none");
                div
                    .html(getTooltipHtml(formatLineDate(d.x), d.value, false, subjecttext))
                    .style("left", (event.pageX - (event.offsetX > 200 ? 120 : 0)) + "px")
                    .style("right", "auto")
                    .style("top", (event.pageY - 60) + "px")
                    .style("opacity", 1)
                    .style("display", "block")
                    .style("z-index", 9999999);
            })
            .on("mouseout", function () {
                div.html(" ").style("display", "none");
            })
            .on("click", function (event, d) {
                div.style("display", "none");
                div
                    .html(getTooltipHtml(formatLineDate(d.x), d.value, true, subjecttext))
                    .style("left", (event.pageX - (event.offsetX > 200 ? 120 : 0)) + "px")
                    .style("right", "auto")
                    .style("top", (event.pageY - 60) + "px")
                    .style("opacity", 1)
                    .style("display", "block")
                    .style("z-index", 999999);
            });
    }

    const apiBarChart = (data, node, cardProperty, options) => {
        let copyData = data;
        const margin = isMobile ? chartMarginMobileBarChart : chartMargin;
        const width = getChartWidth(node.clientWidth, margin),
            height = getChartHeight(LINE_CHART_HEIGHT, margin);

        d3.select(node).html('');

        const svg = d3
            .select(node)
            .append('svg')
            .attr('width', width + margin.left + margin.right)
            .attr('height', height + margin.top + margin.bottom)
            .append('g')
            .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');

        let e = data && data?.result.map((item, i) => Object.keys(item).length);
        const keys = data && data?.result.length ? Object.keys(data?.result[e.indexOf(Math.max(...e))]).map((key) => key) : [];

        let ispercentage = 0;

        const subgroup = keys.slice(3);
        if (subgroup.length > 0 && subgroup.includes("Percentage")) {
            ispercentage = 1;
        }

        let maxData, maxDataX;

        var formatLineDate = props.widjetName === "Viewersondemand" ? d3.timeFormat("%d %b %Y") : (props.widjetName === "Viewerslive" ? d3.timeFormat("%d %b %Y %H:%M") : d3.timeFormat("%b %Y"))

        data = data ? d3.stack().keys(subgroup)(data?.result) : [];

        const parseDate = d3.timeParse("%Y-%m-%dT%H:%M:%S.%L");
        data && data.forEach(function (d) {
            d.forEach(function (e) {
                if (typeof e.data.x !== 'object' && e.data.x !== null) {
                    e.data.x = parseDate(e.data.x) ? parseDate(e.data.x) : e.data.x;
                }
            });
        });

        maxData = data ? d3.max(data.map((s) => d3.max(s, (i) => i[1]))) : [];
        maxDataX = 6;// d3.max(data.map((s) => d3.max(s, (i) => i.data.x.length)));

        const totaltick = { "value": "", "total": (data !== undefined && data !== null && data.length > 0) ? data[0].values.length : 0 }

        const color = d3.scaleOrdinal().domain(subgroup).range(CHART_COLORS_SERIES);
        svg.select("#rotate")
            .append("svg")
            .attr("width", 1000)
            .attr("height", 300);

        let x;
        let xData = [];

        xData = copyData ? copyData.result : [];

        x = d3
            .scaleBand()
            .range([0, width])
            .domain(xData.map((c) => c.x))
            .padding(0.7);

        svg
            .append('g')
            .attr('transform', 'translate(0,' + height + ')')
            .style("font-size", "12px")
            .style("font-weight", "300")
            .style("color", "#3C3441")
            .call(d3.axisBottom(x).tickFormat(function (d) {
                let a = formatLineDate(d);
                return a != " 0NaN" ? formatLineDate(d) : t("header.barchart_Xaxis_" + convertTranslatinString(d), d)
            })
                .ticks(totaltick.total));

        if (cardProperty.w <= 6 && !isMobile && maxDataX > 4) {

            svg.selectAll("text")
                .attr('transform', "translate(0,1)rotate(-45)")
                .style('text-anchor', 'end');

        }
        else if (isMobile && maxDataX > 2) {
            svg.selectAll("text")
                .attr('transform', "translate(0,1)rotate(-45)")
                .style('text-anchor', 'end');
        }
        else {
            if (ispercentage == 0) {
                svg.selectAll("text")
                    .attr('transform', "translate(10,1)")//rotate(-75)")
                    .style('text-anchor', 'end');
            }
            else {
                svg.selectAll("text")
                    .attr('transform', "translate(-15,10)rotate(-90)")//rotate(-75)")
                    .style('text-anchor', 'end');
            }
        }

        if (ispercentage === 0) {
            for (var i = 0; i < data.length; i++) {
                data[i].key = subgroup[i];
            }
            const legendHolder = svg.append('g')
                // translate the holder to the right side of the graph
                .attr('transform', "translate(" + (-width / 2) + "," + (-margin.top) + ")")
                .attr('class', 'legendHolder');
            const legend = legendHolder.selectAll(".legend")
                .data(data)
                .enter().append("g")
                .attr("class", "legend")
                .attr('transform', function (d, i) { return "translate(" + i * 20 + "," + 0 + ")"; });

            legend
                .append('circle')
                .attr('cx', (d, i) => (width / 2) + (height * i))
                .attr('cy', height / 20)
                .attr("r", 5)
                .style('fill', (d) => color(d.key));

            legend.append("text")
                .attr("x", (d, i) => (width / 2) + (height * i) + 10)
                .attr("y", height / 20)
                .text((d) => t("header.legend_" + convertTranslatinString(d.key)))
                .attr("alignment-baseline", "middle")
        }

        let y = d3.scaleLinear()
            .domain([0, maxData]).nice()
            .range([height, 0]);

        if (ispercentage == 1) {
            y = d3.scaleLinear()
                .domain([0, 100]).nice()
                .range([height, 0]);
        }

        svg
            .append('g')
            .attr('transform', 'translate(' + -width / 100 + ',0)')
            .style("font-size", "12px")
            .style("font-weight", "300")
            .style("color", "#3C3441")

        if (maxData > 9) {
            svg.append("g")
                .attr("class", "grid")
                .call(d3.axisLeft(y)
                    .tickSize(-width)
                    .tickFormat(d3.format("~s"))
                    .tickPadding(10)
                );
        }
        else {
            svg.append("g")
                .attr("class", "grid")
                .call(d3.axisLeft(y)
                    .tickSize(-width)
                    .ticks(maxData)
                    .tickFormat(d3.format("d"))
                    .tickPadding(10)
                );
        }

        // create a tooltip
        var Tooltip = d3.select("#my_dataviz")
            .append("div")
            .style("opacity", 0)
            .attr("class", "tooltip")
            .style("background-color", "white")
            .style("border", "solid")
            .style("border-width", "2px")
            .style("border-radius", "5px")
            .style("padding", "5px")


        let xValue = 0;
        let yValue = 0;
        let clickObject = "";
        let barName = "";
        let currentTooltipLeft = 0;
        let currentTooltipTop = 0;

        let div = d3.select("body")
            .append("div")
            .attr("class", "tooltip")
            .style("opacity", 0);

        let divValue = d3.select(".modal-body");

        if (divValue != undefined) {

            divValue.on("scroll", function (event) {
                d3.selectAll(".tooltip").style("display", "block").style("opacity", 1).each(function (e, index) {
                    d3.select(this).style("display", "none").style("opacity", 0)
                })


                if (barName !== undefined) {


                    let elementObjectNew = document.getElementById(barName)
                    if (elementObjectNew !== null) {
                        let objectValues = elementObjectNew.getBoundingClientRect()
                        let tooltipDiv = document.getElementById(clickObject);
                        if (tooltipDiv !== null) {
                            tooltipDiv.style.display = 'block';
                            tooltipDiv.style.opacity = 1;
                            tooltipDiv.style.left = Math.round(objectValues.left) + 'px';
                            tooltipDiv.style.top = objectValues.top - document.body.getBoundingClientRect().top + 'px';
                        }
                    }

                }
            });
        }

        svg
            .append('g')
            .selectAll('mybar') // Enter in the stack data = loop key per key = group per group
            .data(data)
            .enter()
            .append('g')
            .attr('fill', (d) => color(d.key))
            .selectAll('rect') // enter a second time = loop subgroup per subgroup to add all rectangles
            .data((d) => d)
            .enter()
            .append('rect')
            .attr("id", (d) => d.data.category)
            .attr('x', (d) => x(d.data.x))
            .attr('y', (d) => {
                if (d.data.Percentage && Number(d.data.Percentage) > 0 && Number(d.data.Percentage) <= 2) {
                    return y(d[0]) - 3.5;
                }
                return y(d[1]);
            })
            .attr("width", Math.min(x.bandwidth() - 2, 100))
            .attr('height', (d) => {
                let barHeight = y(d[0]) - y(d[1]);
                if (d.data.Percentage && Number(d.data.Percentage) > 0 && Number(d.data.Percentage) <= 2) {
                    return 3.5;
                }
                return barHeight;
            })
            .on("mousemove", function (event, d) {
                var a = d[1] - d[0];
                let pertext = '';
                if (ispercentage == 1) {
                    pertext = " %";
                }
                d3.selectAll(".tooltip").style("display", "none");
                div.style("display", "none");
                div
                    .html(getTooltipHtmlForBar(a, true) + pertext)
                    .style("left", (event.pageX - (event.offsetX > 200 ? 40 : 0)) + "px")
                    .style("right", "auto")
                    .style("top", (event.pageY - 40) + "px")
                    .style("opacity", 1)
                    .style("display", "block")
                    .style("z-index", "9999999");
            })
            .on("mouseout", function () {
                div.html(" ").style("display", "none");
            })

            .on("click", function (event, d) {
                var a = d[1] - d[0];
                let pertext = '';
                if (ispercentage == 1) {
                    pertext = " %";
                }
                let charString = Math.random().toString(36).slice(2, 6);
                div.style("display", "none");
                div
                    .attr("id", charString)
                    .html(getTooltipHtmlForBar(a, true) + pertext)
                    .style("left", (event.pageX - (event.offsetX > 200 ? 40 : 0)) + "px")
                    .style("right", "auto")
                    .style("top", (event.pageY - 40) + "px")
                    .style("opacity", 1)
                    .style("display", "block")
                    .style("z-index", "9999999");
                barChartClickEvent({ name: d.data.category, value: d.data.Value });
                // Set a timeout to hide the tooltip after 3 seconds
                clickObject = charString;
                barName = d.data.category;
                xValue = event.offsetX;
                yValue = event.offsetY;

                setTimeout(function () {
                    div.html(" ").style("display", "none");
                }, TOOLTIP_HIDE_TIMEOUT); //hide tooltip after few seconds
            });
    }

    const apiAreaChart = (node) => {

        const margin = isMobile ? chartMarginMobileMultiLineChart : chartMargin;

        const width = getChartWidth(node.clientWidth, margin);
        let height = getChartHeight(MULTILINE_CHART_HEIGHT, margin);

        d3.select(node).html('');

        const svg = d3.select(node)
            .append("svg")
            .attr("width", width + margin.left + margin.right)
            .attr("height", height + margin.top + margin.bottom)
            .append("g")
            .attr("transform", `translate(${margin.left},${margin.top})`);

        const data = [
            { date: new Date(2020, 0, 1), series1: 30, series2: 40, series3: 50 },
            { date: new Date(2020, 1, 1), series1: 50, series2: 60, series3: 70 },
            { date: new Date(2020, 2, 1), series1: 80, series2: 50, series3: 60 },
            { date: new Date(2020, 3, 1), series1: 30, series2: 80, series3: 100 },
            { date: new Date(2020, 4, 1), series1: 60, series2: 70, series3: 80 },
            { date: new Date(2020, 5, 1), series1: 30, series2: 40, series3: 50 },
        ];
        const x = d3.scaleTime()
            .domain(d3.extent(data, d => d.date))
            .range([0, width]);

        const y = d3.scaleLinear()
            .domain([0, d3.max(data, d => Math.max(d.series1, d.series2, d.series3))])
            .range([height, 0]);

        // Define area generators
        const area1 = d3.area()
            .x(d => x(d.date))
            .y0(d => y(0))
            .y1(d => y(d.series1))
            .curve(d3.curveCatmullRom); // Smoothness

        const area2 = d3.area()
            .x(d => x(d.date))
            .y0(d => y(0))
            .y1(d => y(d.series2))
            .curve(d3.curveCatmullRom); // Smoothness

        const area3 = d3.area()
            .x(d => x(d.date))
            .y0(d => y(0))
            .y1(d => y(d.series3))
            .curve(d3.curveCatmullRom); // Smoothness

        let formatLineDate = d3.timeFormat("%b %Y")

        svg
            .append('g')
            .attr('transform', 'translate(0,' + height + ')')
            .style("font-size", "12px")
            .style("font-weight", "300")
            .style("color", "#3C3441")
            .call(d3.axisBottom(x).tickValues(
                data && data.length && data.map(function (d) {
                    return d.date;
                }
                ))
                .tickPadding(10)
                .tickSize(7)
                .tickFormat((d, i) => {
                    return formatLineDate(d);
                }))

        svg.append("path")
            .datum(data)
            .attr("fill", "rgba(255,0,111,.25)")
            .attr("d", area1);

        svg.append("path")
            .datum(data)
            .attr("fill", "rgba(179,0,73,.25)")
            .attr("d", area2);

        svg.append("path")
            .datum(data)
            .attr("fill", "rgba(167,174,0,.25)")
            .attr("d", area3);

        // Add strokes
        svg.append("path")
            .datum(data)
            .attr("fill", "none")
            .attr("stroke", "rgba(255,0,111,1)")
            .attr("stroke-width", 2)
            .attr("d", d3.line()
                .x(d => x(d.date))
                .y(d => y(d.series1))
                .curve(d3.curveCatmullRom));

        svg.append("path")
            .datum(data)
            .attr("fill", "none")
            .attr("stroke", "rgba(179,0,73,1)")
            .attr("stroke-width", 2)
            .attr("d", d3.line()
                .x(d => x(d.date))
                .y(d => y(d.series2))
                .curve(d3.curveCatmullRom));

        svg.append("path")
            .datum(data)
            .attr("fill", "none")
            .attr("stroke", "rgba(167,174,0,1)")
            .attr("stroke-width", 2)
            .attr("d", d3.line()
                .x(d => x(d.date))
                .y(d => y(d.series3))
                .curve(d3.curveCatmullRom));

        // Add markers
        svg.selectAll("circle.series1")
            .data(data)
            .enter()
            .append("circle")
            .attr("class", "series1")
            .attr("cx", d => x(d.date))
            .attr("cy", d => y(d.series1))
            .attr("r", 4)
            .attr("fill", "rgba(255,0,111,1)");

        svg.selectAll("rect.series2")
            .data(data)
            .enter()
            .append("rect")
            .attr("class", "series2")
            .attr("x", d => x(d.date) - 4)
            .attr("y", d => y(d.series2) - 4)
            .attr("width", 8)
            .attr("height", 8)
            .attr("fill", "rgba(179,0,73,1)");

        svg.selectAll("polygon.series3")
            .data(data)
            .enter()
            .append("polygon")
            .attr("class", "series3")
            .attr("points", d => {
                const cx = x(d.date);
                const cy = y(d.series3);
                const r = 4;
                return `${cx},${cy - r} ${cx + r},${cy + r} ${cx - r},${cy + r}`;
            })
            .attr("fill", "rgba(167,174,0,1)");

        svg.append("g")
            .attr("class", "y axis")
            .call(d3.axisLeft(y));
    }

    const drawChart = (data, node, chartType, cardProperty, options = {}) => {
        if (chartType === "line") {
            return apiLineChart(data, node, cardProperty);
        }
        else if (chartType === "pie") {
            return apiDrawPieChart(node, data, cardProperty, options);
        }
        else if (chartType === 'map') {
            return Mapvalue(node, resultSet);
        }
        else if (chartType === "multiline") {
            return apiDrawMultiLineChart(data, node, cardProperty, options);
        }
        else if (chartType === "bar") {
            return apiBarChart(data, node, cardProperty, options);
        }
        else if (chartType === "areachart") {
            return apiAreaChart(node);
        }

    };

    return !isResFromAPI ?
        (resultSet.series()[0] === undefined ?
            (<>{tableCaption !== undefined ? <h6 className="mt-4 ms-4 page-subtitle">{tableCaption}</h6> : ""}
                <div className="text-center no-data">{t("header.NoDataFound")}</div></>)
            :
            (<>{tableCaption !== undefined ? <h6 className="mt-4 ms-4 page-subtitle">{tableCaption}</h6> : ""} <div ref={(el) => el && drawChart(data, el, type, cardProperty, props)} /></>))
        : <>{tableCaption !== undefined ? <h6 className="mt-4 ms-4 page-subtitle">{tableCaption}</h6> : ""} <div ref={(el) => el && drawChart(data, el, type, cardProperty, props)} /></>;
};

DataChartsComponent.propTypes = {
    type: PropTypes.string,
    resultSet: PropTypes.object
};


DataChartsComponent.defaultProps = {
    type: "",
    resultSet: {}
};

export default React.memo(DataChartsComponent);
