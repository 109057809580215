import React from "react";
import HeaderDropdowns from "../components/HeaderDropdowns";

const UserSettingsPage = () => {

    return (
        <HeaderDropdowns />
    );

};

export default UserSettingsPage;