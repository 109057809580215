import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { MultiSelect } from "react-multi-select-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle, faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { Card, Col, Image, Row, Nav, Tab, Dropdown, ButtonGroup, Alert, Button } from '@themesberg/react-bootstrap'

import iconSprite from "../../assets/Icon/svg-icn-sprite.svg";
import LoaderImage from "../../assets/img/LoaderGraphic.svg";
import { DATE_FORMAT, DATE_FORMATNEW, VITableHeaders } from '../../utils/constant';
import { baseURL, METHODS } from '../../apis/utilities/constant.js';
import request, { requestPDF } from '../../apis/request.js';
import { decryptData } from '../../utils/crypto.js';
import { ERROR_TIMEOUT } from "../../AppConfig";
import { convertBase64 } from '../../utils/common.js';

import Autocomplete from "../Autocomplete";
import ButtonComponent from "../Buttons";
import Filters from '../Filters.js';
import Search from "../Search";
import { AllPages } from './AllPages.js';
import { Watchlist } from './Watchlist.js';
import { VisitorAll } from './VisitorAll.js';

export const Tabs = React.memo(({ cryptoKey, profileId, startDate, endDate, intelConfigId, companyId, userId, websiteData, profileData, companyName, tool, section, isTF }) => {
    const [t] = useTranslation();
    const { iswatchlist } = useParams();

    const [tabSection, setTabSection] = useState(1); //Visitors = 1, Pages = 2
    const [activeTab, setActiveTab] = useState(iswatchlist && iswatchlist === "watchlist" ? 2 : 1); // Visitors-All = 1, Visitors-Watchlist = 2 & Pages-All-Pages = 3
    const [searchData, setSearchData] = useState("");
    const [searchDataList, setSearchDataList] = useState([]);
    const [showAutoFillForSearch, setShowAutoFillForSearch] = useState(false);
    const [blurLoader, setBlurLoader] = useState(false);
    const [alertVisible, setAlertVisible] = useState(false);
    const [isPDFDownloadEnable, setIsPDFDownloadEnable] = useState(false);
    const [showAddWatchlist, setShowAddWatchlist] = useState(false);
    const [showAutoFillForCompany, setAutoFillForCompany] = useState(false);
    const [autocompleteSelection, setAutocompleteSelection] = useState({ id: 0 });
    const [listMultiselect, setListMultiselect] = useState([]);
    const [selectedOption, setSelectedOption] = useState([]);
    const [disableAddWatchlistButton, setDisableAddWatchlistButton] = useState(true);
    const [dataWatchlist, setDataWatchlist] = useState({});
    const [checkCompanyName, setCheckCompanyName] = useState(false);
    const [autoTickData, setAutoTickData] = useState([]);
    const [successMessage, setSuccessMessage] = useState(undefined);
    const [refreshWatchlist, setRefreshWatchlist] = useState(true);
    const [watchlistData, setWatchlistData] = useState(null);

    const [filterCountryList, setFilterCountryList] = useState([]);
    const [filterTempSelectedCountryList, setFilterTempSelectedCountryList] = useState({ countryId: 0, countryName: "All" });
    const [filterCategoryList, setFilterCategoryList] = useState([]);
    const [filterCompleteCategoryList, setFilterCompleteCategoryList] = useState([]);
    const [filterTempSelectedCategoryList, setFilterTempSelectedCategoryList] = useState({ categoryId: 0, categoryName: "All" });
    const [selectedFilterItem, setselectedFilterItem] = useState({});
    const [toggle, setToggle] = useState(true);

    const [showDownloadMenu, setShowDownloadMenu] = useState(false);

    useEffect(() => {
        if (profileId) {
            setSearchData("");
            setShowAutoFillForSearch(false);
            setIsPDFDownloadEnable(false);
            setselectedFilterItem({});
            setFilterCountryList([])
            setFilterTempSelectedCountryList({ countryId: 0, countryName: "All" });
            setFilterTempSelectedCategoryList({ categoryId: 0, categoryName: "All" });
            visitorCategoryForDrowdown();
            setToggle(!toggle);
        }
    }, [profileId, startDate, endDate, section, tool, activeTab]);

    useEffect(() => {
        setSelectedOption([]);
        setListMultiselect([]);
        setDisableAddWatchlistButton(true);
    }, [checkCompanyName]);

    useEffect(() => {
        if (autocompleteSelection.id > 0) {
            request(`${process.env.REACT_APP_API_KEY}/VisitorAnalytics/GetIntelligenceCountryByWatchedVisitor?companyid=` + autocompleteSelection.id, METHODS.GET, {},
                { Authorization: `Bearer ${decryptData(localStorage.getItem("token"), cryptoKey)}`, "content-type": "application/json" })
                .then((response) => {
                    if (dataWatchlist.some(item => item.companyId === autocompleteSelection.id) && response.length > 0) {
                        const selectedcompanywatchlistdata = dataWatchlist.filter(item => item.companyId === autocompleteSelection.id);
                        const commonRecords = findCommonRecords(selectedcompanywatchlistdata, response);
                        setSelectedOption(commonRecords);
                    }
                    setListMultiselect(response);
                })
                .catch(() => {
                })
        }
        else {
            setListMultiselect([])
            setSelectedOption([])
        }
    }, [autocompleteSelection]);

    useEffect(() => {
        if (showAddWatchlist) {
            document.addEventListener('click', onClickOutsideListenerFilters);
        }

        return () => {
            document.removeEventListener('click', onClickOutsideListenerFilters);
        };
    }, [showAddWatchlist]);


    useEffect(() => {
        if (watchlistData) {
            setCountryListForFilter(watchlistData)
        }
        else {
            setFilterCountryList([]);
            setDataWatchlist([]);
            setAutoTickData([]);
        }
    }, [watchlistData]);

    const setDowloadDropdownMenu = () => {
        let ddList = ["Excel", "PDF"];
        return ddList.map(item => {
            return (
                <Dropdown.Item className='dropdown-item-download' onClick={() => downloadGrid(item)}>
                    {item}
                </Dropdown.Item>
            );
        })
    }

    const downloadGrid = (item) => {
        if (item === "Excel") {
            downloadExcel()
        }
        else {
            downloadPDF();
        }
    }

    const downloadExcel = async () => {
        setBlurLoader(true);
        let type = "All visitors";
        let url;
        let config;
        let filename = "";
        if (activeTab === 1) {
            type = "All visitors";
            url = "/VisitorAnalytics/ExportVisitorExcelFileWithEPPlus"
            filename = 'Insights_All_Visitors.xlsx';
            config = convertBase64(JSON.stringify({
                type: type,
                websiteName: websiteData["WebsiteList.WebsiteName"],
                pagesectionid: isTF ? tool.toolID : section.sectionId,
                pageSectionName: isTF ? tool.title : section.title,
                packageid: 5,
                startDate: moment(startDate).format(DATE_FORMAT),
                endDate: moment(endDate).format(DATE_FORMAT),
                profileId: profileId,
                profileName: profileData["WebsiteProfiles.DisplayName"],
                intellConfigId: intelConfigId,
                userid: userId,
                companyid: companyId,
                CompanyName: companyName,
                culture: localStorage.getItem('i18nextLng') ? localStorage.getItem('i18nextLng') : 'en-US',
                isTF: isTF,
                toolid: isTF ? tool.toolID : 0
            }))
        } else if (activeTab === 2) {
            type = "watched visitors";
            url = "/VisitorAnalytics/ExportVisitorExcelFileWithEPPlus"
            filename = 'Insights_Watched_visitors.xlsx';
            config = convertBase64(JSON.stringify({
                type: type,
                websiteName: websiteData["WebsiteList.WebsiteName"],
                pagesectionid: isTF ? tool.toolID : section.sectionId,
                pageSectionName: isTF ? tool.title : section.title,
                packageid: 5,
                startDate: moment(startDate).format(DATE_FORMAT),
                endDate: moment(endDate).format(DATE_FORMAT),
                profileId: profileId,
                profileName: profileData["WebsiteProfiles.DisplayName"],
                intellConfigId: intelConfigId,
                userid: userId,
                companyid: companyId,
                CompanyName: companyName,
                culture: localStorage.getItem('i18nextLng') ? localStorage.getItem('i18nextLng') : 'en-US',
                isTF: isTF,
                toolid: isTF ? tool.toolID : 0
            }))
        }
        else if (activeTab === 3) {
            type = isTF ? "All Tools" : "All Pages";
            url = "/VisitorAnalytics/ExportPageExcelFileWithEPPLus"
            filename = 'Insights_All_Tools.xlsx';
            config = convertBase64(JSON.stringify({
                type: type,
                websitename: websiteData["WebsiteList.WebsiteName"],
                sectionid: isTF ? tool.toolID : section.sectionId,
                pageSectionName: isTF ? tool.title : section.title,
                startdate: moment(startDate).format(DATE_FORMATNEW),
                enddate: moment(endDate).format(DATE_FORMATNEW),
                profileid: profileId,
                profileName: profileData["WebsiteProfiles.DisplayName"],
                pagetype: 1,
                companyName: companyName,
                culture: localStorage.getItem('i18nextLng') ? localStorage.getItem('i18nextLng') : 'en-US',
                intelconfigid: intelConfigId,
                userid: userId,
                isTF: isTF,
                toolid: isTF ? tool.toolID : 0
            }))
        }

        request(`${process.env.REACT_APP_API_KEY}${url}?encodeStr=${config}`, METHODS.GET, {},
            { Authorization: `Bearer ${decryptData(localStorage.getItem("token"), cryptoKey)}` })
            .then((response) => {
                if (response) {
                    let fetchDataModified = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${response}`;
                    let a = document.createElement("a");
                    a.href = fetchDataModified;
                    a.download = filename
                    a.click();
                }
                setBlurLoader(false);
            })
            .catch(() => {
                setBlurLoader(false);
            })
    }

    const downloadPDF = async () => {
        setBlurLoader(true);
        let url = "";
        let pageName = "Visitor Insights";
        let countTitle = "";
        let fileName = "";
        let filters = "";
        let tableHeaders = [];
        const selectedStartDate = moment(startDate).format("YYYY-MM-DD");
        const selectedEndDate = moment(endDate).format("YYYY-MM-DD");
        if (activeTab === 1) {
            countTitle = "Total All Visitors: <span class='total-record-count'>{{count}}</span>";
            filters = (searchData !== "" && searchData !== null) ? "companyName|contains|" + searchData : "";
            url = `${process.env.REACT_APP_API_KEY}/WebsiteAnalytics/GetVisitorWithWatchlistByProfileId?profileid=${profileId}&intellConfigId=${intelConfigId}&startDate=${selectedStartDate}&endDate=${selectedEndDate}&companyid=${companyId}&packageid=5&isTF=${isTF}&toolid=${isTF ? tool.toolID : 0}`;
            if (isTF === 0 && section && section.sectionId !== 0) {
                url = `${process.env.REACT_APP_API_KEY}/VisitorAnalytics/GetVisitorWithWatchlistBySection?profileid=${profileId}&intellConfigId=${intelConfigId}&startDate=${selectedStartDate}&endDate=${selectedEndDate}&companyid=${companyId}&pagesectionid=${section.sectionId}&packageid=5`;
            }
            fileName = "Insights_All_Visitors.pdf";
            tableHeaders = isTF ? VITableHeaders.VisitorAllTFTable : VITableHeaders.VisitorAllTable;
        }
        else if (activeTab === 2) {
            countTitle = "Total Watchlist: <span class='total-record-count'>{{count}}</span>";
            filters = (searchData !== "" && searchData !== null) ? "companyName|contains|" + searchData : "";
            url = `${process.env.REACT_APP_API_KEY}/WebsiteAnalytics/GetWatchedVisitorByWebsite?endDate=${selectedEndDate}&startDate=${selectedStartDate}&profileId=${profileId}&intellConfigId=${intelConfigId}&companyid=${companyId}&packageid=5&isTF=${isTF}&toolid=${isTF ? tool.toolID : 0}${localStorage.getItem('refreshCache') ? "&refreshCache=" + localStorage.getItem('refreshCache') : ""}`;
            if (isTF === 0 && section && section.sectionId !== 0) {
                url = `${process.env.REACT_APP_API_KEY}/VisitorAnalytics/GetWatchedVisitorBySection?profileid=${profileId}&intellConfigId=${intelConfigId}&startDate=${selectedStartDate}&endDate=${selectedEndDate}&companyid=${companyId}&sectionid=${section.sectionId}&packageid=5`;
            }
            fileName = "Insights_Watchlist.pdf";
            tableHeaders = isTF ? VITableHeaders.WatchlistTFTable : VITableHeaders.WatchlistTable;
        }
        else if (activeTab === 3) {
            countTitle = `<span class='total-record-count'>{{count}}</span> Total ${isTF ? "Tools" : "Pages"}`;
            filters = (searchData !== "" && searchData !== null) ? "filterybykey|contains|" + searchData : "";
            url = `${process.env.REACT_APP_API_KEY}/VisitorAnalytics/GetPagesDataByProfileId?profileid=${profileId}&startDate=${selectedStartDate}&endDate=${selectedEndDate}&companyid=${companyId}&isTF=${isTF}&toolid=${isTF ? tool.toolID : 0}&sortexpression=PageViews&sortdirection=true&intelconfigid=${intelConfigId}`;
            if (isTF === 0 && section && section.sectionId !== 0) {
                url = `${process.env.REACT_APP_API_KEY}/VisitorAnalytics/GetPageBySection?profileid=${profileId}&startDate=${selectedStartDate}&endDate=${selectedEndDate}&companyid=${companyId}&pagesectionid=${section.sectionId}&packageid=5`;
            }
            fileName = "Insights_All_Tools.pdf";
            tableHeaders = isTF ? VITableHeaders.AllPagesTFTable : VITableHeaders.AllPagesTable;
        }

        let obj = {
            Title: document.getElementById("websiteWebsiteProfile").innerHTML,
            SubTitle: document.getElementById("dateRange").innerHTML,
            PageName: pageName,
            CountTitle: countTitle,
            Filters: filters,
            ClientName: companyName,
            Url: url,
            Token: decryptData(localStorage.getItem("token"), cryptoKey),
            FileName: fileName,
            Email: decryptData(localStorage.getItem("email"), cryptoKey),
            Name: decryptData(localStorage.getItem("firstName"), cryptoKey),
            Count: 0,
            FilterContent: "",
            TableHeaders: tableHeaders
        }

        requestPDF(`${process.env.REACT_APP_API_KEY}/CommonV2/GeneratePdf`,
            METHODS.POST,
            obj,
            {
                Authorization: `Bearer ${decryptData(localStorage.getItem("token"), cryptoKey)}`
            })
            .then((res) => {
                const contentType = res.headers['content-type'];
                if (res.status === 200) {
                    if (contentType?.includes('application/pdf')) {
                        const blob = new Blob([res.data], { type: 'application/pdf' });
                        const url = window.URL.createObjectURL(blob);
                        const a = document.createElement('a');
                        a.href = url;
                        a.download = fileName;
                        document.body.appendChild(a);
                        a.click();
                        window.URL.revokeObjectURL(url);
                    }
                    else {
                        setAlertVisible(true);
                        setTimeout(() => {
                            setAlertVisible(false);
                        }, 3000);
                    }
                    setBlurLoader(false);
                }
                else {
                    setBlurLoader(false);
                    console.error('Error generating PDF.');
                }
            })
            .catch((err) => {
                setBlurLoader(false);
                console.error('Error generating PDF:', err);
            })
    };

    const onClickOutsideListenerFilters = () => {
        setSelectedOption([]);
        setListMultiselect([]);
        setDisableAddWatchlistButton(true);
        setAutocompleteSelection({ id: 0 });
        setShowAddWatchlist(false)
        document.removeEventListener("click", onClickOutsideListenerFilters)
    }

    const addWatchlistCompany = event => {
        event.preventDefault();
        if (autocompleteSelection.id > 0) {
            let acountries = [];
            let rcountries = [];
            if (dataWatchlist && dataWatchlist.length > 0) {
                if (dataWatchlist.some(item => item.companyId === autocompleteSelection.id)) {
                    const selectedcompanywatchlistdata = dataWatchlist.filter(item => item.companyId === autocompleteSelection.id);
                    for (const item1 of selectedOption) {
                        if (!selectedcompanywatchlistdata.some(item => item.countryId === item1.value)) {
                            acountries.push({ CountryId: item1.value, CountryName: item1.label })
                        }
                    }
                    for (const item2 of selectedcompanywatchlistdata) {
                        if (!selectedOption.some(item => item.value === item2.countryId)) {
                            rcountries.push({ CountryId: item2.countryId, CountryName: item2.countryName })
                        }
                    }
                }
                else {
                    acountries = selectedOption.map(val => ({ CountryId: val.value, CountryName: val.label }));
                }
            }
            else {
                acountries = selectedOption.map(val => ({ CountryId: val.value, CountryName: val.label }));
            }
            let postobj = {
                CompanyId: autocompleteSelection.id, ProfileId: profileId
                , AddedCountries: acountries, RemovedCountries: rcountries, selectedcompanyid: companyId, packageid: 5,
                isTF: isTF
            }
            setBlurLoader(true);
            setShowAddWatchlist(false);
            setRefreshWatchlist(false);
            request(`${process.env.REACT_APP_API_KEY}/VisitorAnalytics/SaveWatchlistForUserMain`, METHODS.POST,
                postobj, { Authorization: `Bearer ${decryptData(localStorage.getItem("token"), cryptoKey)}`, "content-type": "application/json" })
                .then((response) => {
                    let msg = "";
                    if (acountries.length > 0 && rcountries.length > 0) {
                        msg = "Visitor updated in your watchlist successfully";
                        setRefreshWatchlist(true);
                    }
                    else if (acountries.length > 0) {
                        msg = t("header.Message_Visitor_added_to_your_watchlist_successfully");
                        setRefreshWatchlist(true);
                    }
                    else if (rcountries.length > 0) {
                        msg = t("header.Message_Visitor_removed_from_your_watchlist_successfully");
                    }

                    setTimeout(() => {
                        setSuccessMessage(msg);
                    }, 500);

                    setTimeout(() => {
                        setSuccessMessage(undefined);
                    }, ERROR_TIMEOUT);

                    setSelectedOption([]);
                    setListMultiselect([]);
                    setDisableAddWatchlistButton(true);
                    setAutocompleteSelection({ id: 0 });
                    setBlurLoader(false);
                    setRefreshWatchlist(true);
                    localStorage.setItem("refreshCache", 1);
                })
                .catch(() => {
                    setSelectedOption([]);
                    setListMultiselect([]);
                    setDisableAddWatchlistButton(true);
                    setAutocompleteSelection({ id: 0 });
                    setBlurLoader(false);
                })
        }
    }

    const onCloseSuccessMessage = () => {
        setSuccessMessage(undefined)
    };

    const sendErrorReport = () => {
        window.open(
            "mailto:" +
            "connect.id.admin@idx.inc" +
            "?subject=Tools Insights - Report an error" +
            "&body=" +
            ""
        );
    };

    const autoFillSelection = (obj) => {
        setAutocompleteSelection(obj);
        setListMultiselect([])
    }

    const setMultiselectOption = (data) => {
        let dbutton = true;
        if (dataWatchlist.some(item => item.companyId === autocompleteSelection.id)) {
            const selectedcompanywatchlistdata = dataWatchlist.filter(item => item.companyId === autocompleteSelection.id);
            let checked = false;
            for (const item1 of data) {
                if (!selectedcompanywatchlistdata.some(item => item.countryId === item1.value)) {
                    checked = true;
                }
            }
            for (const item2 of selectedcompanywatchlistdata) {
                if (!data.some(item => item.value === item2.countryId)) {
                    checked = true;
                }
            }
            if (checked == true) {
                dbutton = false;
            }
            else {
                dbutton = true;
            }
        }
        else if (data.length > 0) {
            dbutton = false;
        }
        else {
            dbutton = true;
        }
        setDisableAddWatchlistButton(dbutton);
        setSelectedOption(data)
    }

    const findCommonRecords = (array1, array2) => {
        const commonRecords = [];
        for (const item1 of array1) {
            const commonItem = array2.find(item2 => item2.value === item1.countryId);
            if (commonItem) {
                commonRecords.push(commonItem);
            }
        }
        return commonRecords;
    }

    const setCountryListForFilter = (data) => {
        if (data && data.data?.length > 0) {
            var list = [];
            list = data.country;
            if (list.length > 0) {
                list.splice(0, 0, { countryId: 0, countryName: "All" });
            }
            const uniqueCountries = [...new Map(list.map(item => [item.countryId, item])).values()];
            setFilterCountryList(uniqueCountries);
            setDataWatchlist(data.data);
            let autoticklist = []
            data.data.map(function (item) {
                autoticklist.push(item.companyName)
            });
            setAutoTickData(autoticklist);
        }
    }

    function onCountryDropDownChange(data) {
        if (data) {
            setFilterTempSelectedCountryList(data)
        }
    }

    const visitorCategoryForDrowdown = () => {
        request(baseURL + `/VisitorAnalytics/GetCategory`,
            METHODS.GET,
            {},
            {
                Authorization: `Bearer ${decryptData(localStorage.getItem("token"), cryptoKey)}`, "content-type": "application/json"
            })
            .then((res) => {
                let resultData = [];
                if (res.resultData !== null && res.resultData.length) {
                    setFilterCompleteCategoryList(res.resultData);
                    resultData = res.resultData.filter((item) => item.parentCategoryId == 0);
                    resultData.splice(0, 0, { categoryId: 0, categoryName: "All" });
                }
                setFilterCategoryList(resultData);
            })
            .catch((err) => {

            })
    }

    function onCategoryDropdownChange(data) {
        if (data) {
            let filteredRecords = filterCompleteCategoryList.filter((item) => item.parentCategoryId == data.categoryId);
            if (filteredRecords.length > 0 && data.categoryId > 0) {
                filteredRecords.splice(0, 0, { categoryId: 0, categoryName: "All" });
            }
            setFilterTempSelectedCategoryList(data)
        }
    }

    function onFilterClick() {
        setShowAutoFillForSearch(false);
        setShowDownloadMenu(false);
        setShowAddWatchlist(false);
    }

    function cancelfilter() {
        var filterfs = {};
        setselectedFilterItem(filterfs);
        setFilterTempSelectedCountryList({ countryId: 0, countryName: "All" });
        setFilterTempSelectedCategoryList({ categoryId: 0, categoryName: "All" });
    }

    function applyfilter() {
        if (activeTab === 1) {
            var filterfs = {};
            if (filterTempSelectedCountryList.countryId > 0) {
                filterfs["countryId"] = filterTempSelectedCountryList.countryId;
            }
            else {
                filterfs["countryId"] = 0;
            }

            if (filterTempSelectedCategoryList.categoryName != "All") {
                filterfs["categoryId"] = filterTempSelectedCategoryList.categoryId;
            }
            else {
                filterfs["categoryId"] = 0;
            }

            setselectedFilterItem(filterfs);
        }
        else if (activeTab === 2) {
            var filterfs = {};
            if (filterTempSelectedCountryList.countryId > 0) {
                filterfs["countryId"] = filterTempSelectedCountryList.countryId;
            }
            else {
                filterfs["countryId"] = 0;
            }
            if (filterTempSelectedCategoryList.categoryName != "All") {
                filterfs["categoryId"] = filterTempSelectedCategoryList.categoryId;
            }
            else {
                filterfs["categoryId"] = 0;
            }
            setselectedFilterItem(filterfs);
        }
    }

    const createFilter = useMemo(
        () => (
            <>
                <Filters
                    onIconClick={() => onFilterClick()}
                    iconText={t("header.Icon_Tooltip_Filter_Visitors")}
                    applyFilters={applyfilter}
                    cancelFilter={cancelfilter}
                    showHeader={false}
                    hideFilter={showAddWatchlist}
                    btnOneText={t("header.VisitorsIconFilterVisitorsButtonFilterText")}
                    btnTwoText={t("header.VisitorsIconFilterVisitorsButtonClearFilterText")}
                    dataSource={[
                        {
                            name: "Country",
                            data: [...new Set(filterCountryList)],
                            selectedItem: filterTempSelectedCountryList,
                            isVisible: true,
                            label: t("header.VisitorsIconFilterVisitorsLabelCountryText"),
                            callback: onCountryDropDownChange,
                            displayText: "countryName",
                        },
                        {
                            name: "Category",
                            data: filterCategoryList,
                            isVisible: true,
                            label: t("header.VisitorsIconFilterVisitorsLabelCategoryText"),
                            selectedItem: filterTempSelectedCategoryList,
                            callback: onCategoryDropdownChange,
                            displayText: "categoryName"
                        }
                    ]}
                />
            </>
        ),
        [filterCountryList, filterTempSelectedCountryList, filterCategoryList, filterTempSelectedCategoryList, showAddWatchlist]
    );


    return (
        <>
            {blurLoader && <div id='blurdiv' className="blurbackground">
                <div className={`preloader loader-center-align`}>
                    <Image className="loader-element animate__animated animate__jackInTheBox" src={LoaderImage} height={40} data-seconds={30} />
                </div>
            </div>}

            <Alert
                className="toast-custom"
                variant="success"
                show={successMessage ? true : false}
                onClose={() => onCloseSuccessMessage()}
            >
                <div className="d-flex justify-content-between">
                    <div>
                        <FontAwesomeIcon icon={faCheckCircle} className="me-2" />
                        {successMessage}
                    </div>
                    <Button variant="close" size="xs btn_close" onClick={() => onCloseSuccessMessage()} />
                </div>
            </Alert>
            {(alertVisible || successMessage) && (<div className="fade modal-backdrop show"></div>)}
            <Alert
                className="toast-custom"
                variant="success"
                show={alertVisible}
            >
                <div className="d-flex justify-content-between">
                    <div>
                        <FontAwesomeIcon icon={faCheckCircle} className="me-2" />
                        {t("header.PdfDeliveredToMailMessage")}
                    </div>
                </div>
            </Alert>
            <div className="d-inline-block p-3 pe-0 w-100 ">
                <div className="shadow-soft border rounded border-light w-100 nested-card-container">
                    <Row className="mt-3 mb-2">
                        <Col md={12}>
                            <div className="tools-dashboard-card-loader">
                                <Card border="light" className="bg-white shadow-sm">
                                    <Card.Body>
                                        <Tab.Container defaultActiveKey="Visitors" >
                                            <Nav fill variant="pills" className="flex-column main-tab flex-sm-row tab border-bottom-0 ms-1 mt-3 mb-2 justify-content-center">
                                                <Nav.Item className="pe-0">
                                                    <Nav.Link eventKey="Visitors" onClick={() => { setTabSection(1); setActiveTab(1) }} className="mb-sm-3 mb-md-0">
                                                        <span className="d-inline-block w-100 tools-tab-label-text">
                                                            {t("header.PagesDetailsPopVisitorsTabText")}
                                                        </span>
                                                    </Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="Pages" onClick={() => { setTabSection(2); setActiveTab(3) }} className="mb-sm-3 mb-md-0">
                                                        <span className="d-inline-block w-100 tools-tab-label-text">
                                                            {isTF ? t("header.InsightsTabTools") : t("header.InsightsTabPages")}
                                                        </span>
                                                    </Nav.Link>
                                                </Nav.Item>
                                            </Nav>
                                            <Tab.Content className="mt-4">
                                                <Tab.Pane eventKey="Visitors" className="py-2" >
                                                    {tabSection === 1 &&
                                                        <Row>
                                                            <Row className={localStorage.getItem("isDemoClient") ? "custom-disable mb-4" : "mb-4"}>
                                                                <Col xs={6}>
                                                                </Col>
                                                                <Col xs={6} className="text-end icon-nav-list svg-icon-nav-list">
                                                                    <div className="pe-3 d-inline-block pull-right">
                                                                        {
                                                                            filterCountryList.length > 0 ?
                                                                                createFilter
                                                                                :
                                                                                <div className="d-inline-block icon-nav icon-nav-filter ms-2">
                                                                                    <div className="slider-dropdown-tigger d-inline-block filter-box-tigger icon-nav-list svg-icon-nav-list">
                                                                                        <span className="icon-nav-box  ms-0 disabled">
                                                                                            <svg className="icon-nav">
                                                                                                <title>{t("header.Icon_Tooltip_Filter")}</title>
                                                                                                <use href={iconSprite + `#Filter`} />
                                                                                            </svg>
                                                                                        </span>
                                                                                    </div>
                                                                                </div>
                                                                        }
                                                                        {
                                                                            activeTab === 2 &&
                                                                            <div className="d-inline-block icon-nav icon-nav-filter ms-2 mt-0">
                                                                                {activeTab === 2 &&
                                                                                    <>
                                                                                        <div className="d-inline-block icon-nav icon-nav-filter me-2">
                                                                                            <div className="slider-dropdown-tigger d-inline-block filter-box-tigger icon-nav-list svg-icon-nav-list">
                                                                                                <span className="icon-nav-box  ms-0" onClick={(e) => {
                                                                                                    setAutoFillForCompany(false);
                                                                                                    setShowAddWatchlist(!showAddWatchlist);
                                                                                                    setShowDownloadMenu(false);
                                                                                                    e.stopPropagation();
                                                                                                    setListMultiselect([]);
                                                                                                    setSelectedOption([]);
                                                                                                }}>
                                                                                                    <FontAwesomeIcon title={t("header.Icon_Tooltip_AddCompaniesToWatchlist")} icon={faPlusCircle} size="lg" />
                                                                                                </span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </>}
                                                                                {
                                                                                    showAddWatchlist == true &&
                                                                                    <Row className="align-items-center mt-2 mb-3">
                                                                                        <Col>
                                                                                            <div className="date-range-container pt-4 mt-2 me-3"
                                                                                                onMouseLeave={() => {
                                                                                                    document.addEventListener("click", onClickOutsideListenerFilters)
                                                                                                }}
                                                                                                onMouseEnter={() => { document.removeEventListener("click", onClickOutsideListenerFilters) }}
                                                                                            >
                                                                                                <Autocomplete
                                                                                                    apiurl={`${process.env.REACT_APP_API_KEY}/VisitorAnalytics/SearchVisitorsByName`}
                                                                                                    placeholder={t("header.Add_Watchlist_Company_Placeholder")}
                                                                                                    OnValueSelection={autoFillSelection}
                                                                                                    autoTickEnabled={true}
                                                                                                    autoTickData={autoTickData}
                                                                                                    setCheckCompanyName={setCheckCompanyName}
                                                                                                >
                                                                                                </Autocomplete>

                                                                                                <MultiSelect
                                                                                                    className="mt-3 text-start dropdown-items"
                                                                                                    disabled={listMultiselect.length > 0 && checkCompanyName ? false : true}
                                                                                                    options={listMultiselect}
                                                                                                    value={checkCompanyName ? selectedOption : []}
                                                                                                    onChange={setMultiselectOption}
                                                                                                    labelledBy="Select"
                                                                                                    disableSearch={true}
                                                                                                />

                                                                                                <div className="d-block mt-4 text-start">
                                                                                                    <ButtonComponent
                                                                                                        dissable={disableAddWatchlistButton}
                                                                                                        onClick={(e) => addWatchlistCompany(e)}
                                                                                                        isIcon={false}
                                                                                                        isPrimary={true}
                                                                                                        btnText={t("header.VisitorsIconAddCompToWatchButtonAddCompanyText")}
                                                                                                        btn_class="btn-md mb-3 filter-btn"
                                                                                                    />
                                                                                                </div>
                                                                                            </div>
                                                                                        </Col>
                                                                                    </Row>
                                                                                }
                                                                            </div>
                                                                        }

                                                                        <span className={isPDFDownloadEnable ? "icon-nav-box ms-2 hide-mobile" : "icon-nav-box hide-mobile disabled"}>
                                                                            <Dropdown as={ButtonGroup} show={showDownloadMenu} onToggle={(isOpen) => setShowDownloadMenu(isOpen)}>
                                                                                <Dropdown.Toggle split className="card-dropdown-btn p-0 border-0 pdf-download-btn"
                                                                                    onClick={() => {
                                                                                        setShowDownloadMenu(!showDownloadMenu);
                                                                                    }}
                                                                                >
                                                                                    <svg className="icon-nav">
                                                                                        <title>{t("header.Icon_Tooltip_Download")}</title>
                                                                                        <use href={iconSprite + `#Download`} />
                                                                                    </svg>
                                                                                </Dropdown.Toggle>
                                                                                {showDownloadMenu && (
                                                                                    <Dropdown.Menu>
                                                                                        {setDowloadDropdownMenu()}
                                                                                    </Dropdown.Menu>
                                                                                )}
                                                                            </Dropdown>
                                                                        </span>
                                                                        <span className="icon-nav-box hide-mobile">
                                                                            <svg className="icon-nav" onClick={() => sendErrorReport()}>
                                                                                <title>{t("header.Icon_Tooltip_Report_Error")}</title>
                                                                                <use href={iconSprite + `#WarningTriangle`} />
                                                                            </svg>
                                                                        </span>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Row className="visitor-search-download-box position-relative">
                                                                <Col xs={12} className={localStorage.getItem("isDemoClient") ? "custom-disable text-end" : "text-end"} >
                                                                    <div className="search-box-container">
                                                                        <Search
                                                                            setsearchData={setSearchData}
                                                                            searchData={searchData}
                                                                            searchWaterMark={activeTab === 1 ? t("header.visitorAllWatermark") : t("header.searchwatchlistPlaceHolder")}
                                                                            isAutoComplete={true}
                                                                            setAutoFillForCompany={setShowAutoFillForSearch}
                                                                            showAutoFillForCompany={showAutoFillForSearch}
                                                                            data={searchDataList}
                                                                            type={"client"}
                                                                            emptyTextBox={toggle}
                                                                        ></Search>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col md={12}>
                                                                    <Tab.Container activeKey={activeTab === 1 ? "All" : "Watchlist"} >
                                                                        <Nav fill variant="pills" className="tab subtab ms-3 me-3">
                                                                            <Nav.Item className="pe-0">
                                                                                <Nav.Link eventKey="All" onClick={() => { setActiveTab(1) }} className="mb-sm-3 mb-md-0">
                                                                                    <span className="d-inline-block w-100 tools-tab-label-text">
                                                                                        {t("header.VisitorsAllVisitorsTabText")}
                                                                                    </span>
                                                                                </Nav.Link>
                                                                            </Nav.Item>
                                                                            <Nav.Item>
                                                                                <Nav.Link eventKey="Watchlist" onClick={() => { setActiveTab(2) }} className="mb-sm-3 mb-md-0">
                                                                                    <span className="d-inline-block w-100 tools-tab-label-text">
                                                                                        {t("header.VisitorsWatchlistTabText")}
                                                                                    </span>
                                                                                </Nav.Link>
                                                                            </Nav.Item>
                                                                        </Nav>
                                                                        <Tab.Content>
                                                                            <Tab.Pane eventKey="All" className="mt-4" >
                                                                                {activeTab === 1 &&
                                                                                    <VisitorAll
                                                                                        exportdata={setCountryListForFilter}
                                                                                        filterParams={selectedFilterItem}
                                                                                        userId={userId}
                                                                                        searchData={searchData}
                                                                                        cryptoKey={cryptoKey}
                                                                                        setSearchDataList={setSearchDataList}
                                                                                        profileId={profileId}
                                                                                        startDate={startDate}
                                                                                        endDate={endDate}
                                                                                        intelConfigId={intelConfigId}
                                                                                        companyId={companyId}
                                                                                        setDownloadEnable={setIsPDFDownloadEnable}
                                                                                        profileData={profileData}
                                                                                        companyName={companyName}
                                                                                        websiteData={websiteData}
                                                                                        isTF={isTF}
                                                                                        tool={tool}
                                                                                        section={section}
                                                                                    />}
                                                                            </Tab.Pane>
                                                                            <Tab.Pane eventKey="Watchlist" className="mt-4">
                                                                                {activeTab === 2 &&
                                                                                    <Watchlist
                                                                                        exportdata={setCountryListForFilter}
                                                                                        filterParams={selectedFilterItem}
                                                                                        refreshWatchlist={refreshWatchlist}
                                                                                        setRefreshWatchlist={setRefreshWatchlist}
                                                                                        userId={userId}
                                                                                        profileId={profileId}
                                                                                        startDate={startDate}
                                                                                        endDate={endDate}
                                                                                        intelConfigId={intelConfigId}
                                                                                        companyId={companyId}
                                                                                        searchData={searchData}
                                                                                        cryptoKey={cryptoKey}
                                                                                        setWatchlistData={setWatchlistData}
                                                                                        setSearchDataList={setSearchDataList}
                                                                                        setDownloadEnable={setIsPDFDownloadEnable}
                                                                                        profileData={profileData}
                                                                                        companyName={companyName}
                                                                                        websiteData={websiteData}
                                                                                        isTF={isTF}
                                                                                        tool={tool}
                                                                                        section={section}
                                                                                    />}
                                                                            </Tab.Pane>
                                                                        </Tab.Content>
                                                                    </Tab.Container>
                                                                </Col>
                                                            </Row>
                                                        </Row>}
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="Pages" className="py-2">
                                                    {tabSection === 2 && <>
                                                        <Row className={localStorage.getItem("isDemoClient") ? "custom-disable mb-4" : "mb-4"}>
                                                            <Col md={12} className="text-end">
                                                                <div className="icon-nav-list svg-icon-nav-list float-end mb-2 me-3 pe-1">
                                                                    <span className={isPDFDownloadEnable ? "icon-nav-box ms-2 hide-mobile" : "icon-nav-box hide-mobile disabled"}>
                                                                        <Dropdown as={ButtonGroup}>
                                                                            <Dropdown.Toggle split className="card-dropdown-btn p-0 border-0 pdf-download-btn">
                                                                                <svg className="icon-nav">
                                                                                    <title>{t("header.Icon_Tooltip_Download")}</title>
                                                                                    <use href={iconSprite + `#Download`} />
                                                                                </svg>
                                                                            </Dropdown.Toggle>
                                                                            <Dropdown.Menu>
                                                                                {setDowloadDropdownMenu()}
                                                                            </Dropdown.Menu>
                                                                        </Dropdown>
                                                                    </span>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row className="visitor-search-download-box position-relative">
                                                            <Col xs={12} className={localStorage.getItem("isDemoClient") ? "custom-disable text-end" : "text-end"}>
                                                                <div className="search-box-container">
                                                                    <Search
                                                                        setsearchData={setSearchData}
                                                                        searchData={searchData}
                                                                        isAutoComplete={false}
                                                                        type={"client"}
                                                                    ></Search>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col md={12}>
                                                                <Tab.Container defaultActiveKey="AllPages" >
                                                                    <Nav fill variant="pills" className="tab subtab ms-3 me-3">
                                                                        <Nav.Item className="pe-0">
                                                                            <Nav.Link eventKey="AllPages" className="mb-sm-3 mb-md-0">
                                                                                <span className="d-inline-block w-100 tools-tab-label-text">
                                                                                    {isTF ? t("header.ToolsAllToolsTabText") : t("header.PagesAllPagesTabText")}
                                                                                </span>
                                                                            </Nav.Link>
                                                                        </Nav.Item>
                                                                    </Nav>
                                                                    <Tab.Content>
                                                                        <Tab.Pane eventKey="AllPages" onClick={() => { setActiveTab(3) }} className="mt-4" >
                                                                            {activeTab === 3 && <AllPages
                                                                                userId={userId}
                                                                                profileId={profileId}
                                                                                startDate={startDate}
                                                                                endDate={endDate}
                                                                                intelConfigId={intelConfigId}
                                                                                companyId={companyId}
                                                                                searchData={searchData} cryptoKey={cryptoKey}
                                                                                setDownloadEnable={setIsPDFDownloadEnable}
                                                                                profileData={profileData}
                                                                                companyName={companyName}
                                                                                websiteData={websiteData}
                                                                                isTF={isTF}
                                                                                tool={tool}
                                                                                section={section}
                                                                            />
                                                                            }
                                                                        </Tab.Pane>
                                                                    </Tab.Content>
                                                                </Tab.Container>
                                                            </Col>
                                                        </Row>
                                                    </>}
                                                </Tab.Pane>
                                            </Tab.Content>
                                        </Tab.Container>
                                    </Card.Body>
                                </Card>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    )
});
