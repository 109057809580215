import React, { useState, useEffect, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import moment from "moment";
import html2pdf from "html2pdf.js";
import axios from "axios";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Row, Col, Image, Nav, Tab, Dropdown, ButtonGroup, Alert } from '@themesberg/react-bootstrap';

import request from '../apis/request';
import { baseURL, METHODS } from '../apis/utilities/constant';
import LoaderImage from "../assets/img/LoaderGraphic.svg";
import { apiChartsAlertService } from "../stubs/ComponentJson/alerts";
import iconSprite from "../assets/Icon/svg-icn-sprite.svg";
import { convertBase64 } from "../utils/common";
import { decryptData } from '../utils/crypto';

import CommonHeader from '../components/CommonHeader';
import ModalComponent from "../components/VoltModal";
import ApiChartRenderer from '../components/ApiChartRenderer';
import ChartRendererTable from "../components/ChartRendererTable";
import Search from "../components/Search";
import Filters from "../components/Filters";
import AppContext from '../components/AppContext';

const AlertStats = ({ children, selectedWebsiteId, setSelectedWebsiteId, setSelectedOtpclientid, showAlertStatisticsModel, setShowAlertStatisticsModel, showEmailAlertModel, setShowEmailAlertModel, messageDetails }) => {

  const [t, i18n] = useTranslation();
  const contextData = useContext(AppContext);

  const [showCustomDatePicker, setShowCustomDatePicker] = useState(false);
  const [autoLoad, setAutoLoad] = useState(false);
  const [selectedOtpclientidNew, setSelectedOtpclientidNew] = useState("");
  const [updateKey, setUpdateKey] = useState("");
  const [blurLoader, setBlurLoader] = useState(false);
  const [alertState, setAlertState] = useState([]);
  const [activeTab, setActiveTab] = useState("");
  const [searchData, setSearchData] = useState("");
  const [alertVisible, setAlertVisible] = useState(false);
  const [getTotalResults, setGetTotalResults] = useState(0);
  const [apiURLDevices, setApiURLDevices] = useState();
  const [apiURLCountry, setApiURLCountry] = useState();
  const [apiURLSubscribers, setApiURLSubscribers] = useState();
  const [filterDomains, setFilterDomains] = useState([]);
  const [selectedFilterDomain, setSelectFilterDomain] = useState({ Domain: "All Domains" });
  const [selectedFilterItem, setselectedFilterItem] = useState({});
  const culture = localStorage.getItem('i18nextLng') ? localStorage.getItem('i18nextLng') : 'en-US';

  useEffect(() => {
    if (messageDetails?.MessageId) {
      setApiURLDevices({
        url: `${process.env.REACT_APP_API_KEY}/Intelligence/GetTopDevicesByMessageTable?WebsiteId=${contextData.websiteId}&MessageId=${messageDetails.MessageId}&companyid=${contextData.companyId}&userid=${contextData.userId}&culture=${culture}`, type: 'get', count: 0
      });
      setApiURLCountry({
        url: `${process.env.REACT_APP_API_KEY}/Intelligence/GetTopCountriesByMessageTable?WebsiteId=${contextData.websiteId}&MessageId=${messageDetails.MessageId}&companyid=${contextData.companyId}&userid=${contextData.userId}&culture=${culture}`, type: 'get', count: 0
      });
      setApiURLSubscribers({
        url: `${process.env.REACT_APP_API_KEY}/Intelligence/GetSubscriberDetailByMessage?WebsiteId=${contextData.websiteId}&MessageId=${messageDetails?.MessageId}&companyid=${contextData.companyId}&userid=${contextData.userId}`, type: 'get', count: 0
      });
    }
  }, [messageDetails]);

  useEffect(() => {
    getWebsites();
  }, []);

  useEffect(() => {
    showAlertStatisticsModel ? setActiveTab("devices") : setActiveTab("");
  }, [showAlertStatisticsModel])

  const onWebsiteDropdownChange = (data) => {
    setSelectedOtpclientidNew({});
    setSelectedOtpclientidNew(data);
  };

  const getWebsites = () => {
    setBlurLoader(true);
    if (!localStorage.getItem("alertState")) {
      request(baseURL + `/Intelligence/GetAlertsChannelWebsiteByClientUser?CompanyId=${contextData.companyId}&UserId=${contextData.userId}`,
        METHODS.GET,
        {},
        {
          Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
        })
        .then((res) => {
          let resultData = [];
          if (res.result !== null && res.result.length) {
            resultData = res.result.map((e) => ({ ["WebsiteList.WebsiteName"]: e.WebsiteName, ["WebsiteList.WebsiteId"]: e.WebsiteId, ["WebsiteList.WebsiteUrl"]: e.WebsiteUrl, ["ClietConfiguration.otpclientid"]: e.OTPClientId, ["WebsiteList.AlertTrackingDate"]: e.AlertTrackingDate }))
          }
          rendersite({ resultSet: resultData })
        })
        .catch((err) => {
        })
    }
    else {
      rendersite({ resultSet: JSON.parse(localStorage.getItem("alertState")) })
    }

  }

  const rendersite = ({ resultSet, error, pivotConfig, request }) => {
    if (resultSet !== undefined && resultSet.length > 0) {
      if (selectedWebsiteId !== null && Object.keys(selectedOtpclientidNew).length <= 0) {
        if (JSON.parse(localStorage.getItem("alertWebsiteId")) !== null) {
          onWebsiteDropdownChange(JSON.parse(localStorage.getItem("alertWebsiteId")))
          setUpdateKey(Math.random());
        } else {
          onWebsiteDropdownChange(resultSet[0])
          setSelectedWebsiteId(resultSet[0])
        }
      }
      setAlertState(resultSet);
      localStorage.setItem("alertState", JSON.stringify(resultSet));
      setUpdateKey(Math.random());
      setAutoLoad(true);
    }
  };

  const applyFilters = () => {
    setBlurLoader(false);
    const websiteId = selectedOtpclientidNew["WebsiteList.WebsiteId"];
    setSelectedWebsiteId(websiteId);
    contextData.changeWebsiteId(websiteId);
    setSelectedOtpclientid(selectedOtpclientidNew);
    localStorage.setItem("alertWebsiteId", JSON.stringify(selectedOtpclientidNew));// Adding AlertStatsWebsiteId to local stoarge in case of Page Refresh
  }

  const cancelFilters = () => {
    onWebsiteDropdownChange(JSON.parse(localStorage.getItem("alertWebsiteId")))
  }

  //slider dropdown
  const onClickOutsideListener = () => {
    setShowCustomDatePicker(false);
    document.removeEventListener("click", onClickOutsideListener)
  }

  const commonHeader = useMemo(() => <CommonHeader cancelFilters={cancelFilters} channel="alert" autoLoad={autoLoad} dataSource={[
    { name: 'Dropdown1 - website', data: alertState, selectedItem: selectedOtpclientidNew, isVisible: true, label: t("header.alertSummaryFiltersLabelWebsite"), callback: onWebsiteDropdownChange, displayText: "WebsiteList.WebsiteName" },
  ]} title={t("header.AlertStatsHeading")} showDate={true} applyFilters={applyFilters} />, [selectedOtpclientidNew, autoLoad, updateKey])

  const sanitizedContent = (htmlContent) => {
    let _contnet = htmlContent.replace(/^<!\[CDATA\[/, '').replace(/\]\]>$/, '')
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = _contnet;

    const anchors = tempDiv.getElementsByTagName('a');
    for (let i = 0; i < anchors.length; i++) {
      if (!anchors[i].target) {
        anchors[i].target = '_blank';
      }
    }
    return { __html: tempDiv.innerHTML };
  }

  const onChangeTab = (event) => {
    setActiveTab(event);
    setGetTotalResults(0);
    setSearchData("");
    var filterfs = {};
    setselectedFilterItem(filterfs);
    setSelectFilterDomain({ Domain: "All Domains" });
  }

  const closeAlertStatistics = () => {
    setShowAlertStatisticsModel(false);
    setSearchData("");
    var filterfs = {};
    setselectedFilterItem(filterfs);
    setSelectFilterDomain({ Domain: "All Domains" });
  }

  const setDowloadDropdownMenu = () => {
    let ddList = ["Excel", "PDF"];
    return ddList.map(item => {
      return (
        <Dropdown.Item className='dropdown-item-download' onClick={() => downloadGrid(item)}>
          {item}
        </Dropdown.Item>
      );
    })
  }

  const downloadGrid = (item) => {
    if (item === "Excel") {
      downloadExcel()
    }
    else {
      downloadPDF();
    }
  }

  const downloadExcel = async () => {
    setBlurLoader(true)
    const selectedComapnyData = contextData.companies.find(dta => dta.companyId === contextData.companyId);
    let websiteDetail = localStorage.getItem("alertWebsiteId");
    let websiteName = "";
    if (websiteDetail && JSON.parse(websiteDetail) !== null) {
      websiteName = JSON.parse(websiteDetail)["WebsiteList.WebsiteName"]
    }
    let paramsList = {
      WebsiteId: contextData.websiteId,
      companyid: contextData.companyId,
      userid: contextData.userId,
      MessageId: messageDetails?.MessageId,
      companyName: selectedComapnyData.companyName,
      webSiteName: websiteName,
      alertCatName: contextData.alertCategoryId.CategoryName,
      alertName: messageDetails?.MessageSubject,
      alertDate: moment(messageDetails?.ProcessDate).format('MMM DD, YYYY hh:mm A'),
      culture: localStorage.getItem('i18nextLng') ? localStorage.getItem('i18nextLng') : 'en-US'
    }

    request(baseURL + `/Intelligence/ExportSubscriberDetailByMessageEPPlus?encodeStr=${convertBase64(JSON.stringify(paramsList))}`,
      METHODS.GET,
      {},
      {
        Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`,
      })
      .then((res) => {
        if (res) {
          let fetchDataModified = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${res}`;
          let a = document.createElement("a");
          a.href = fetchDataModified;
          a.download = `Alert_Statistics_Subscribers_${contextData.alertCategoryId.CategoryName.replace(" ", "_")}.xlsx`;
          a.click();
        }
        setBlurLoader(false);
      })
      .catch((err) => {
        setBlurLoader(false);
        console.error('Error generating Excel:', err);
      })
  };

  const downloadPDF = async () => {
    setBlurLoader(true);
    let url = `${process.env.REACT_APP_API_KEY}/Intelligence/GetSubscriberDetailByMessage?WebsiteId=${contextData.websiteId}&MessageId=${messageDetails.MessageId}
    &companyid=${contextData.companyId}&userid=${contextData.userId}`;
    let fileName = `Alert_Statistics_Subscribers_${contextData.alertCategoryId.CategoryName.replace(" ", "_")}.pdf`;
    let filters = (searchData !== "" && searchData !== null) ? "Email|contains|" + searchData : "";
    let obj = {
      Title: contextData.alertCategoryId.CategoryName + (contextData.alertCategoryId.CategoryName !== "All Categories" ? " Category" : ""),
      SubTitle: messageDetails?.MessageSubject + "|date|" + moment(messageDetails?.ProcessDate).format('MMM DD, YYYY hh:mm A'),
      CountTitle: t("header.AlertSubscribersTitle"),
      PageName: "Alert Subscribers",
      Filters: filters,
      Url: url,
      Token: decryptData(localStorage.getItem("token"), contextData.cryptoKey),
      FileName: fileName,
      Email: decryptData(localStorage.getItem("email"), contextData.cryptoKey),
      Name: decryptData(localStorage.getItem("firstName"), contextData.cryptoKey),
      Count: 0,
      FilterContent: "",
      ClientName: "",
      TableHeaders: []
    }

    await axios.post(`${process.env.REACT_APP_API_KEY}/CommonV2/GeneratePdf`, obj,
      {
        responseType: 'arraybuffer',
        headers: {
          'Authorization': `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`
        }
      })
      .then((res) => {
        const contentType = res.headers['content-type'];
        if (res.status === 200) {
          if (contentType?.includes('application/pdf')) {
            const blob = new Blob([res.data], { type: 'application/pdf' });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
          }
          else {
            setAlertVisible(true);
            setTimeout(() => {
              setAlertVisible(false);
            }, 3000);
          }
          setBlurLoader(false);
        }
        else {
          setBlurLoader(false);
          console.error('Error generating PDF.');
        }
      })
      .catch((err) => {
        setBlurLoader(false);
        console.error('Error generating PDF:', err);
      })
  };

  const downloadPageLevelPDF = async (activeTab) => {
    setBlurLoader(true);
    let pageName = activeTab === "devices" ? "devicesContent" : "countryContent"
    const content = document.createElement("div");
    content.innerHTML = document.querySelector('.tab-content .fade.tab-pane.active.show.' + pageName)?.innerHTML;

    const options = {
      filename: (activeTab === "devices" ? "Alert_Statistics_Devices_" : "Alert_Statistics_Country_") + contextData.alertCategoryId.CategoryName.replace(" ", "_"),
      margin: [5, 2, 2, 2],
      image: { type: "jpeg", quality: 1 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "mm", format: "a4", orientation: "landscape" }
    };
    const combinedNode = document.createElement("div");
    combinedNode.innerHTML = `<div class="row mt-3">
        <div class="col-md-12">
            <div class="ms-2 mb-0 pdf-download-title">${contextData.alertCategoryId.CategoryName + (contextData.alertCategoryId.CategoryName !== "All Categories" ? " Category" : "")}</div>
            <div class="ms-2 mb-0 pdf-download-subtitle">${messageDetails?.MessageSubject}</div>
            <div class="ms-2 mb-0 pdf-download-subtitle">${moment(messageDetails?.ProcessDate).format('MMM DD, YYYY hh:mm A')}</div>
        </div>
    </div>`;
    combinedNode.appendChild(content.cloneNode(true));
    combinedNode.querySelectorAll('.pagination-content')?.forEach(btn => {
      btn.style.display = "none";
    });
    if (combinedNode.querySelector(".table-responsive-lg")) {
      combinedNode.querySelector(".table-responsive-lg")?.querySelectorAll("tr").forEach(tr => {
        tr.style.pageBreakInside = "avoid";
      });
    }
    combinedNode.querySelectorAll(".me-1")?.forEach(item => {
      item.style.display = "none";
    });
    combinedNode.querySelectorAll("a").forEach(link => {
      link.removeAttribute("href");
      link.style.pointerEvents = "none";
    });
    combinedNode.innerHTML = await covertSVGToIMG(combinedNode);
    await html2pdf().set(options).from(combinedNode).save().then(() => {
      setBlurLoader(false);
    });
  }

  const loadImageAsync = (img) => {
    return new Promise((resolve, reject) => {
      img.onload = () => resolve(img);
      img.onerror = reject;
    });
  };

  const covertSVGToIMG = async (content) => {
    var dynamicSvgs = content.querySelectorAll('svg:not(.svg-inline--fa):not(.icon-nav)');

    for (let i = 0; i < dynamicSvgs.length; i++) {
      dynamicSvgs[i].querySelectorAll("line").forEach(x => {
        x.setAttribute("stroke", "#dddee2");
      });
      dynamicSvgs[i].querySelectorAll("path.domain[stroke='currentColor']").forEach(x => {
        x.style.display = "none";
      });

      var canvas = document.createElement('canvas');
      const scaleFactor = 2;
      canvas.width = dynamicSvgs[i].width.baseVal.value * scaleFactor;
      canvas.height = dynamicSvgs[i].height.baseVal.value * scaleFactor;

      var context = canvas.getContext('2d');
      context.scale(scaleFactor, scaleFactor);

      context.fillStyle = '#ffffff';
      context.fillRect(0, 0, canvas.width, canvas.height);

      var svgData = new XMLSerializer().serializeToString(dynamicSvgs[i]);
      var img = new window.Image();
      img.src = 'data:image/svg+xml,' + encodeURIComponent(svgData);

      await loadImageAsync(img);

      const scaleWidthFactor = 1;

      context.drawImage(img, 0, 0, img.width * scaleWidthFactor, img.height * scaleWidthFactor);

      var imgSrc = canvas.toDataURL('image/jpeg');
      var dynamicImg = document.createElement('img');
      dynamicImg.src = imgSrc;

      let parent = dynamicSvgs[i].parentNode;
      while (parent && parent !== content) {
        parent = parent.parentNode;
      }

      if (parent === content) {
        dynamicSvgs[i].parentNode.replaceChild(dynamicImg, dynamicSvgs[i]);
      }
    }
    return content.innerHTML;
  };

  const onDomainDropdownChange = (data) => {
    if (data) {
      setSelectFilterDomain(data)
    }
  };

  const applyDomainfilter = () => {
    var filterfs = {};
    if (selectedFilterDomain.Domain !== "All Domains")
      filterfs["Email"] = selectedFilterDomain.Domain;
    setselectedFilterItem(filterfs)
  }

  const cancelDomainfilter = () => {
    var filterfs = {};
    setselectedFilterItem(filterfs)
    setSelectFilterDomain({ Domain: "All Domains" });
  }

  const setFilterData = (data) => {
    if (data.domains && data.domains.length > 0) {
      let resultData = []
      resultData = [
        { Domain: "All Domains" },
        ...data.domains.map(Domain => ({ Domain: Domain }))
      ]
      setFilterDomains(resultData);
    }
  }

  const createFilter = useMemo(
    () => (
      <>
        <Filters
          iconText={"Filter"}
          applyFilters={applyDomainfilter}
          cancelFilter={cancelDomainfilter}
          showHeader={false}
          btnOneText={"Filter"}
          btnTwoText={"Clear Filter"}
          dataSource={[
            {
              name: "Domain",
              data: filterDomains,
              selectedItem: selectedFilterDomain,
              isVisible: true,
              label: "Domain",
              callback: onDomainDropdownChange,
              displayText: "Domain",
            }
          ]}
        ></Filters>
      </>
    ),
    [filterDomains, selectedFilterDomain]
  );

  return (
    <>
      <Row>
        <Col>
          <ModalComponent
            title={t("header.AlertEmailAlert")}
            showCloseButton={true}
            open={showEmailAlertModel}
            handleClose={() => setShowEmailAlertModel(false)}
          >
            {messageDetails &&
              <>
                <Row>
                  <Col sm={2} className="fw-semi-bold">{t("header.AlertDate")}</Col>
                  <Col sm={10}>{moment(messageDetails?.ProcessDate).format('MMM DD, YYYY hh:mm A')}</Col>
                </Row>
                <Row className="mt-2">
                  <Col sm={2} className="fw-semi-bold">{t("header.AlertSubject")}</Col>
                  <Col sm={10}>
                    {messageDetails?.MessageSubject}
                  </Col>
                </Row>
                <div className="border rounded border-light p-2 mt-3 email-description-box" dangerouslySetInnerHTML={sanitizedContent(messageDetails?.EmailDescriptionHTML)} />
              </>
            }
          </ModalComponent>
        </Col>
      </Row>
      <Row>
        <Col>
          <ModalComponent
            title={contextData.alertCategoryId.CategoryName + (contextData.alertCategoryId.CategoryName !== "All Categories" ? " Category" : "")}
            showCloseButton={true}
            open={showAlertStatisticsModel}
            handleClose={() => closeAlertStatistics()}
          >
            {messageDetails &&
              <>
                {blurLoader && <div id='blurdiv' className="blurbackground">
                  <div className={`preloader loader-center-align`}>
                    <Image className="loader-element animate__animated animate__jackInTheBox" src={LoaderImage} height={40} data-seconds={30} />
                  </div>
                </div>}
                <Alert
                  className="toast-custom"
                  variant="success"
                  show={alertVisible}
                >
                  <div className="d-flex justify-content-between">
                    <div>
                      <FontAwesomeIcon icon={faCheckCircle} className="me-2" />
                      {t("header.PdfDeliveredToMailMessage")}
                    </div>
                  </div>
                </Alert>
                <Row className="mt-2 fw-bold">
                  <Col sm={12}>{messageDetails?.MessageSubject}</Col>
                </Row>
                <Row className="mt-2">
                  <Col sm={12}>{moment(messageDetails?.ProcessDate).format('MMM DD, YYYY hh:mm A')}</Col>
                </Row>
                {(activeTab === "devices" || activeTab === "country") &&
                  <Row className="mb-4">
                    <Col md={12} className="text-end">
                      <div className="icon-nav-list svg-icon-nav-list float-end mb-2">
                        <span className={getTotalResults > 0 ? "icon-nav-box hide-mobile" : "icon-nav-box hide-mobile disabled"} onClick={() => downloadPageLevelPDF(activeTab)}>
                          <svg className="icon-nav">
                            <title>{t("header.AlertExport", "Export")}</title>
                            <use href={iconSprite + `#Download`} />
                          </svg>
                        </span>
                      </div>
                    </Col>
                  </Row>
                }
                {activeTab === "subscribers" &&
                  <>
                    <Row className="alert-modal-filter-nav">
                      <Col xs={12} className="text-end icon-nav-list svg-icon-nav-list">
                        <div className="d-inline-block pull-right form-filter-container">
                          {getTotalResults > 0 ?
                            createFilter
                            :
                            <div className="d-inline-block icon-nav icon-nav-filter ms-2 mt-0">
                              <div className="alert-categories-filter slider-dropdown-tigger d-inline-block filter-box-tigger icon-nav-list svg-icon-nav-list">
                                <span className="icon-nav-box ms-0 disabled">
                                  <svg className="icon-nav">
                                    <title>{t("header.Icon_Tooltip_Filter")}</title>
                                    <use href={iconSprite + `#Filter`} />
                                  </svg>
                                </span>
                              </div>
                            </div>
                          }
                        </div>
                        <div className="pe-2 d-inline-block pull-right form-filter-container">
                          <span className={getTotalResults > 0 ? "icon-nav-box ms-2 hide-mobile" : "icon-nav-box hide-mobile disabled"}>
                            <Dropdown title={t("header.AlertExport", "Export")} as={ButtonGroup} className='mt-2'>
                              <Dropdown.Toggle split className="card-dropdown-btn p-0 border-0 pdf-download-btn">
                                <svg className="icon-nav">
                                  <use href={iconSprite + `#Download`} />
                                </svg>
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                {setDowloadDropdownMenu()}
                              </Dropdown.Menu>
                            </Dropdown>
                          </span>
                        </div>
                      </Col>
                    </Row>
                    <Row className="mb-2 alert-modal-search-nav">
                      <Col xs={12} className="text-end service-filter">
                        <Search searchWaterMark={t("header.AlertSubscriberPlaceHolder")} setsearchData={setSearchData} searchData={searchData} isAutoComplete={false} type={'client'}></Search>
                      </Col>
                    </Row>
                  </>
                }
                <Row className="mt-2">
                  <Col md={12}>
                    <Tab.Container
                      defaultActiveKey="devices"
                      onSelect={onChangeTab}
                    >
                      <Nav fill variant="pills" className="tab subtab">
                        <Nav.Item>
                          <Nav.Link eventKey="devices" className="mb-sm-3 mb-md-0" >
                            {t("header.AlertDevicesTab")}
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="country" className="mb-sm-3 mb-md-0" >
                            {t("header.AlertCountryTab")}
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="subscribers" className="mb-sm-3 mb-md-0" >
                            {t("header.AlertSubscribersTab")}
                          </Nav.Link>
                        </Nav.Item>
                      </Nav>
                      <Tab.Content>
                        <Tab.Pane eventKey="devices" className="devicesContent">
                          {activeTab === "devices" && <>
                            <Row className="mb-0 api-chart">
                              <Col md={12} className="alerts-modal-chart-box">
                                <ApiChartRenderer isLegendShown={true}
                                  isSummeryShown={true}
                                  legendPercentage={true}
                                  cardProperty={{ x: 0, y: 24, w: 6, h: 7 }}
                                  alertMessageId={messageDetails.MessageId}
                                  services={apiChartsAlertService}
                                  chartType={"pie"}
                                  itemId={44}
                                />
                              </Col>
                            </Row>
                            <Row className="mb-0 mt-2">
                              <Col md={12}>
                                <div className="alert alert-gray-800" role="alert">
                                  <p className="mb-0 text-body fw-bold"><i className="fa fa-info-circle lg me-2"></i>{t("header.AlertDevicesTitle")}</p>
                                </div>
                              </Col>
                            </Row>
                            <Row className="mb-2">
                              <Col md={12} className="modal-loader-container">
                                <ChartRendererTable
                                  apiUrl={apiURLDevices}
                                  showCount={false}
                                  showNumberOfRows={10}
                                  showPagination={true}
                                  setGetTotalResults={setGetTotalResults}
                                />
                              </Col>
                            </Row>
                          </>}
                        </Tab.Pane>
                        <Tab.Pane eventKey="country" className="countryContent">
                          {activeTab === "country" && <>
                            <Row className="mb-0 api-chart">
                              <Col md={12} className="alerts-modal-chart-box">
                                <ApiChartRenderer isLegendShown={true}
                                  isSummeryShown={true}
                                  legendPercentage={true}
                                  cardProperty={{ x: 0, y: 24, w: 6, h: 7 }}
                                  alertMessageId={messageDetails.MessageId}
                                  services={apiChartsAlertService}
                                  chartType={"pie"}
                                  itemId={45}
                                />
                              </Col>
                            </Row>
                            <Row className="mb-0 mt-2">
                              <Col md={12}>
                                <div className="alert alert-gray-800" role="alert">
                                  <p className="mb-0 text-body fw-bold"><i className="fa fa-info-circle lg me-2"></i>{t("header.AlertCountryTitle")} </p>
                                </div>
                              </Col>
                            </Row>
                            <Row className="mb-2">
                              <Col md={12} className="modal-loader-container">
                                <ChartRendererTable
                                  apiUrl={apiURLCountry}
                                  showCount={false}
                                  showNumberOfRows={10}
                                  showPagination={true}
                                  setGetTotalResults={setGetTotalResults}
                                />
                              </Col>
                            </Row>
                          </>}
                        </Tab.Pane>
                        <Tab.Pane eventKey="subscribers" className="subscribersContent">
                          {activeTab === "subscribers" && <>
                            <Row className="mb-0 mt-2">
                              <Col md={12}>
                                <div className="alert alert-gray-800 mt-3" role="alert">
                                  <p className="mb-0 text-body fw-bold"><i className="fa fa-info-circle lg me-2"></i>{t("header.AlertSubscribersTitle")} </p>
                                </div>
                              </Col>
                            </Row>
                            <Row className="mb-2">
                              <Col md={12} className="modal-loader-container">
                                <ChartRendererTable
                                  apiUrl={apiURLSubscribers}
                                  showCount={false}
                                  showNumberOfRows={10}
                                  showPagination={true}
                                  SearchText={searchData}
                                  setSearchText={setSearchData}
                                  setGetTotalResults={setGetTotalResults}
                                  FilterParams={selectedFilterItem}
                                  IsFilterByInclude={true}
                                  Exportdata={setFilterData}
                                />
                              </Col>
                            </Row>
                          </>}
                        </Tab.Pane>
                      </Tab.Content>
                    </Tab.Container>
                  </Col>
                </Row>
              </>
            }
          </ModalComponent>
        </Col>
      </Row>
      {blurLoader && <div id='blurdiv' className="blurbackground">
        <div className={`preloader loader-center-align`}>
          <Image className="loader-element animate__animated animate__jackInTheBox" src={LoaderImage} height={40} data-seconds={30} />
        </div>
      </div>}
      <Row>
        <Col lg={12} sm={12} xl={12}>
          {commonHeader}
        </Col>
      </Row>
      <Row>
        <Col lg={12} className="pe-0 alert-analytics" id="alertStatistics">
          <div className="d-inline-block p-3 w-100">
            <Row>
              {children}
            </Row>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default AlertStats;
