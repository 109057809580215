import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import moment from 'moment';
import {
    Button,
    Card,
    Col,
    Row,
    Table,
    Tooltip,
    FormCheck,
    OverlayTrigger
} from "@themesberg/react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSort, faSortDown, faSortUp, faExternalLinkAlt, faArrowRight } from "@fortawesome/free-solid-svg-icons";

import { convertTranslatinString } from "../utils/common.js";
import { TABLE_TOTAL_ROWS, dispalyTableRow, formatCount, BadgetsVarients, convertToInternationalFormat } from "../AppConfig";
import { TnfTableHeaders, WebcastHeaders, DataStatusMessage, TooltipForHeaderLabels, TooltipForRowValues } from '../utils/constant';

import PaginationComponent from "../components/Pagination";
import BadgesComponent from "../components/Badges";
import CommonMessageComponent from '../components/CommonMessageComponent.js';
import DropdownWithIconsComponent from "../components/DropdownWithIcons";

export const DataTableRenderer = ({ header, dataSet, transactionStatus, headerSummaryData, customAttributes, ...props }) => {

    const [tableHeader, setTableHeader] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [currentPageNo, setCurrentPageNo] = useState();
    const [activePageData, setActivePageData] = useState([]);
    const [status, setStatus] = useState(DataStatusMessage.Loading);
    const [tooltipPosition, setTooltipPosition] = useState("right");
    const [t, i18n] = useTranslation();

    useEffect(() => {
        const headers = deepCopy(header);
        setTableHeader(headers);
        setStatus(transactionStatus);
        if (dataSet.length > 0) {
            let tableData = [...dataSet];
            let sortColumn = headers.find(x => x.isSortable && !x.isSortAscAndDesc);
            if (sortColumn) {
                if (sortColumn.type === "number") {
                    tableData.sort((a, b) => b[sortColumn.key] - a[sortColumn.key]);
                }
                else if (sortColumn.type === "string") {
                    tableData.sort((a, b) => b[sortColumn.key] !== null ? b[sortColumn.key].trim().localeCompare(a[sortColumn.key].trim()) : '');
                }
                else if (sortColumn.type === 'time' || sortColumn.type === 'date') {
                    tableData.sort((a, b) => new Date(b[sortColumn.key]) - new Date(a[sortColumn.key]));
                }
            }
            setTableData(tableData);
            setCurrentPageNo(1);
            localStorage.setItem("pageNo", 1);
        }
        else {
            setTableData([]);
            setActivePageData([]);
            setStatus(DataStatusMessage.DataUnAvailable);
        }
    }, [dataSet]);

    useEffect(() => {
        if (tableData.length > 0) {
            setProcessedActivePagesData(currentPageNo);
        }
    }, [currentPageNo, tableData]);

    const setProcessedActivePagesData = (currentPageNo) => {
        let activeData = tableData.slice(((currentPageNo - 1) * (customAttributes.displayRowsPerPage > 0 ? customAttributes.displayRowsPerPage : TABLE_TOTAL_ROWS)),
            ((currentPageNo - 1) * (customAttributes.displayRowsPerPage > 0 ? customAttributes.displayRowsPerPage : TABLE_TOTAL_ROWS)) + (customAttributes.displayRowsPerPage > 0 ? customAttributes.displayRowsPerPage : TABLE_TOTAL_ROWS));
        setActivePageData(activeData);
    }

    const renderTableHeader = (headerData) => (
        headerData ? <thead className="thead-light">
            <tr>
                {headerData.length > 0 ? (
                    headerData.map((c, index) => createHeader(c, index))
                ) : (
                    <th> {t("header.NoDataFound")} </th>
                )}
            </tr>
        </thead> : <></>
    );

    const createHeader = (c, index) => {
        let tooltipBesideTableHeader = "";
        TooltipForHeaderLabels.LableAndTooltip.forEach(function (element) {
            if (element.labelName === c.shortTitle && c.showTooltip) {
                tooltipBesideTableHeader = (
                    <OverlayTrigger
                        placement={tooltipPosition}
                        trigger={["hover", "focus"]}
                        overlay={
                            <Tooltip>
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: t(element.tooltipText, {
                                            interpolation: { escapeValue: false },
                                        }),
                                    }}
                                ></div>
                            </Tooltip>
                        }
                    >
                        <Button onMouseMove={(e) => e.clientX > (window.screen.width - 300) ? setTooltipPosition("left") : setTooltipPosition("right")} variant="white" className="btn-help border-0 p-1">
                            <i className="far fa-question-circle lg"></i>
                        </Button>
                    </OverlayTrigger>
                );
            }
        });
        return (<>
            <th key={c.key} >
                {c.isSortable &&
                    (c.isSortAscAndDesc ? (
                        <a
                            onClick={(event) => handleSorting(event, c, index)}
                            className="me-1"
                        >
                            <FontAwesomeIcon icon={faSort} />
                        </a>
                    ) : c.isSortDesc ? (
                        <a
                            onClick={(event) => handleSorting(event, c, index)}
                            className="me-1"
                        >
                            <FontAwesomeIcon icon={faSortUp} />
                        </a>
                    ) : (
                        <a
                            onClick={(event) => handleSorting(event, c, index)}
                            className="me-1"
                        >
                            <FontAwesomeIcon icon={faSortDown} />
                        </a>
                    ))}
                <span className="sorting-label">
                    {c.shortTitle ? t("header.table_colume_" + convertTranslatinString(c.shortTitle)) : ""}
                    {tooltipBesideTableHeader}
                </span>
                {customAttributes.showHeaderSummary && transactionStatus === DataStatusMessage.DataAvailable &&
                    <span className="d-inline-block w-100 table-column-count">
                        {createSummary(c.key)}
                    </span>
                }
            </th>
        </>
        )
    }

    const handleSorting = (event, data, indexOfData) => {
        event.preventDefault();
        const updateTableHeader = [...tableHeader];
        updateTableHeader.forEach((dta, idx) => {
            if (idx === indexOfData) {
                if (!dta.isSortAscAndDesc) dta.isSortDesc = !dta.isSortDesc;

                dta.isSortAscAndDesc = false;
                updateTableHeader[indexOfData] = data;
            }
            else {
                dta.isSortAscAndDesc = true;
                dta.isSortDesc = true;
            }
        });

        let sortKey = updateTableHeader[indexOfData].key;
        if (updateTableHeader[indexOfData].type === "time") {
            //Assumption is made over here that time will only be fetched in seconds with field fetching value from {FieldName}Seconds property.
            sortKey = sortKey + "Seconds"
        }
        const sortOrder = updateTableHeader[indexOfData].isSortDesc
            ? "desc"
            : "asc";
        setTableHeader(updateTableHeader);
        onSortingChange(sortKey, sortOrder, updateTableHeader[indexOfData].type);
    };

    const onSortingChange = (key, order, type) => {
        if (type === "number") {
            if (order === "asc") {
                tableData.sort((a, b) => b[key] - a[key])
            } else {
                tableData.sort((a, b) => a[key] - b[key])
            }
        }
        else if (type === "string") {
            if (order === "asc") {
                tableData.sort((a, b) => b[key] !== null ? b[key].trim().localeCompare(a[key].trim()) : '')
            } else {
                tableData.sort((a, b) => a[key] !== null ? a[key].trim().localeCompare(b[key].trim()) : '')
            }
        }
        else if (type === "time" || type === "date") {
            if (order === "asc") {
                tableData.sort((a, b) => new Date(b[key]) - new Date(a[key]))
            } else {
                tableData.sort((a, b) => new Date(a[key]) - new Date(b[key]))
            }
        }
        else if (type === "hours") {
            if (order === "asc") {
                tableData.sort((a, b) => b[key].localeCompare(a[key]))
            } else {
                tableData.sort((a, b) => a[key].localeCompare(b[key]))
            }
        }
        setProcessedActivePagesData(1);
        setCurrentPageNo(1);
        localStorage.setItem("pageNo", 1);
    }

    const createSummary = (key) => {
        if (headerSummaryData.hasOwnProperty(key)) {
            if (typeof (headerSummaryData[key]) === 'number') {
                return convertToInternationalFormat(headerSummaryData[key])
            }
            return headerSummaryData[key];
        }
        else {
            return "";
        }
    }

    const executeHTMLTags = (value) => {
        return (<>
            <div
                className="d-sm-inline-block truncate-text-container"
                dangerouslySetInnerHTML={{
                    __html: value,
                }}
            ></div>
        </>)
    }

    const renderTableBody = (headerData, bodyData) => (
        <tbody>
            {headerData.length > 0 && bodyData.length > 0 ? (
                bodyData.map((row, index) => {
                    return (
                        <>
                            <tr key={index}>
                                {headerData.map((c) => {
                                    return renderTableBodyContent(row, c.key, index, t, c.OtherType, c.notClickable);
                                })}
                            </tr>
                            {
                                row?.showChildData && <tr key={index + "-child"}>
                                    <td colSpan={headerData.length}>
                                        {renderChildData(props.childHeader, row.children)}
                                    </td>
                                </tr>
                            }
                        </>
                    );
                })
            ) : (
                <tr>
                    <td className="text-center" colspan={headerData.length}>
                        {" "}
                        {t("header.NoDataFound")}{" "}
                    </td>
                </tr>
            )}
        </tbody>
    );

    const toogleChildData = (row) => {
        let data = tableData.map(x => {
            x.showChildData = x.email === row.email ? !x.showChildData : false;
            return x;
        })
        setTableData(data);
    }

    const renderChildData = (header, data) => {
        return (
            <Table responsive="lg" className="table table-flush table-striped mb-3">
                <thead className="thead-light">
                    <tr>
                        {header.length > 0 ? (
                            header.map((c, index) => createHeader(c, index))
                        ) : (
                            <th> {t("header.NoDataFound")} </th>
                        )}
                    </tr>
                </thead>
                <tbody>
                    {header.length > 0 && data.length > 0 ? (
                        data.map((row, index) => {
                            return (
                                <tr key={index}>
                                    {header.map((c) => {
                                        return renderTableBodyContent(row, c.key, index, t, c.OtherType, c.notClickable);
                                    })}
                                </tr>
                            );
                        })
                    ) : (
                        <tr>
                            <td className="text-center" colspan={header.length}>
                                {" "}
                                {t("header.NoDataFound")}{" "}
                            </td>
                        </tr>
                    )}
                </tbody>
            </Table>);
    }

    const renderTableBodyContent = (row, rowKey, rowIndex, t, type, notClickable) => {
        if (type === "LargeData") {
            let lgData = row[rowKey].includes(" || ") ? row[rowKey].split(" || ") : row[rowKey].split(" | ");
            lgData = row[rowKey].includes("||") ? row[rowKey].split("||") : row[rowKey].split("|");
            return (
                <td key={rowKey} className="word-break table-truncate-text">
                    {
                        notClickable ?
                            <div className="ms-0 me-2">
                                <div className="table-row-title w-100 parentCell truncate-text-container" title={dispalyTableRow(lgData[0])}>
                                    {executeHTMLTags(dispalyTableRow(lgData[0]))}
                                </div>
                                {lgData.map((data, index) => {
                                    if (index !== 0) {
                                        return (
                                            <div className="table-row-subtitle w-100 parentCell truncate-text-container" title={dispalyTableRow(data)}>
                                                {dispalyTableRow(data)}
                                            </div>
                                        );
                                    }
                                })}
                            </div> :
                            <Card.Link onClick={(e) => props.rowClickEvent ? props.rowClickEvent({ data: row, type: "display" }) : {}} className="ms-0 me-2">
                                <div className="table-row-title w-100 parentCell truncate-text-container" title={dispalyTableRow(lgData[0])}>
                                    {executeHTMLTags(dispalyTableRow(lgData[0]))}
                                </div>
                                {lgData.map((data, index) => {
                                    if (index !== 0) {
                                        return (
                                            <div className="table-row-subtitle w-100 parentCell truncate-text-container" title={dispalyTableRow(data)}>
                                                {dispalyTableRow(data)}
                                            </div>
                                        );
                                    }
                                })}
                            </Card.Link>
                    }
                </td>
            );
        }
        else if (type === "DateOnly") {
            let dt = `${moment(row[rowKey]).format("DD MMM YYYY")}`;
            if (dt === "Invalid date Invalid date, Invalid date") {
                return (
                    <td key={rowKey}>
                        <FormCheck.Label> {null}</FormCheck.Label>
                    </td>
                );
            }
            return (
                <td key={rowKey}>
                    <FormCheck.Label> {dt}</FormCheck.Label>
                </td>
            );
        }
        else if (type === "Checkbox") {
            var isChecked = parseInt(row[rowKey]) === 0 ? false : true;
            return (
                <td key={rowKey}>
                    <FormCheck type="checkbox" className={localStorage.getItem("isDemoClient") ? "custom-disable inbox-check me-2" : "inbox-check me-2"} >
                        <FormCheck.Input
                            title={isChecked ? t("header.Icon_Tooltip_RemoveFromWatchlist") : t("header.Icon_Tooltip_AddToWatchlist")}
                            name={rowKey}
                            className="me-2"
                            checked={isChecked}
                            value={isChecked}
                            onChange={(e) =>
                                props.handleDeleteWatchlistButtonClick(e, row)
                            }
                        />
                        <FormCheck.Label>
                            {isChecked && rowKey === "watchlist.delete" ? "" : rowKey === "watchlist.delete" ? "Off" : ""}{" "}
                        </FormCheck.Label>
                    </FormCheck>
                </td>
            );
        }
        else if (type === "Details") {
            return (<td key={rowKey} className="word-break">
                <Card.Link onClick={(e) => props.rowClickEvent ? props.rowClickEvent({ data: row, type: "display" }) : {}} className="ms-0 me-2">
                    <FontAwesomeIcon icon={faExternalLinkAlt} title={t("header.Icon_Tooltip_ViewVisitorActivity")} />
                </Card.Link>
            </td>);
        }
        else if (type === "MoreDetails") {
            return (<td key={rowKey} className="word-break">
                <Card.Link onClick={(e) => toogleChildData(row)} className="ms-0 me-2">
                    More Details <FontAwesomeIcon icon={row.showChildData ? faSortUp : faSortDown} title={t("header.Icon_Tooltip_ViewVisitorActivity")} />
                </Card.Link>
            </td>);
        }
        else if (type === "ManageUserActions") {
            const label = row.eventAccessStatus === "approved" ? "Disable" : "Enable";
            return (
                <td key={rowKey} className={localStorage.getItem("isDemoClient") ? "custom-disable" : ""}>
                    <button className={label === "Disable" ? "btn btn-danger border-0 table-btn btn-sm" : "btn btn-primary border-0 table-btn  btn-sm btn-enable"} onClick={() => props.rowClickEvent(label, row)} >
                        {label}
                    </button>
                </td>
            );
        }
        else if (type === "Toggle") {
            var isToggle = row[rowKey] === true ? true : false;
            return (
                <td key={rowKey} className={row["IsBlocked"] == true ? "is-disabled action-not-allowed" : ""}>
                    <FormCheck type="switch" className={localStorage.getItem("isDemoClient") ? "custom-disable" : "inbox-check"}>
                        <FormCheck.Input
                            disabled={row["IsBlocked"] == true ? true : false}
                            type="checkbox"
                            id="billingSwitch"
                            className="me-2"
                            checked={isToggle}
                            value={isToggle}
                            onChange={(e) => rowKey === "notifications.Active" ? props.rowClickEvent(e, row, "status") : props.addNotificationWatchlist(e, row)}
                        />
                        <FormCheck.Label>{isToggle ? t("header.Notification_Toggle_On") : t("header.Notification_Toggle_Off")} </FormCheck.Label>
                    </FormCheck>
                </td>
            );
        }
        else if (type === 'Seconds') {
            return <td key={rowKey} title={row[rowKey]}>
                <Card.Link onClick={(e) => props.rowClickEvent ? props.rowClickEvent({ data: row, type: "display" }) : {}}>
                    <div className="table-row-title w-100 parentCell truncate-text-container">
                        {row[rowKey] + ' s'}
                    </div>
                </Card.Link>
            </td>
        }
        else if (type === 'ReplaceTextData') {
            return <td key={rowKey} title={row[rowKey]}>
                <div className="table-row-title w-100 parentCell truncate-text-container">
                    {row[rowKey].replace(replacementPattern, (matched) => TnfTableHeaders.customEventsContent[matched])}
                </div>
            </td>
        }
        else if (type === "DateTime") {
            return <td key={rowKey}>
                <FormCheck.Label> {formateDate(row[rowKey], { month: "short", day: "numeric", year: "numeric", }, ' GMT')}</FormCheck.Label>
            </td>
        }
        else if (type === "Dropdown") {
            let fieldName = "";
            let ddl = [];
            let selectedValue = null;
            if (rowKey === "QuestionApprovedFor") {
                ddl = WebcastHeaders.questionStatusDDL;
                fieldName = "statusName";
                selectedValue = ddl.find(x => x.statusName === "Pending");
                if (row["QuestionApprovedFor"] === "presenter") {
                    selectedValue = ddl.find(x => x.statusName === "Approved for Presenter");
                } else if (row["QuestionApprovedFor"] === "none") {
                    selectedValue = ddl.find(x => x.statusName === "Pending");
                }
                else {
                    selectedValue = ddl.find(x => x.statusName === "Approved for All");
                }
            }

            return <td>
                <DropdownWithIconsComponent
                    dropdownData={ddl}
                    arrayValue={fieldName}
                    onChange={(e) => props.rowClickEvent(e, row, rowIndex)}
                    showLabel={false}
                    showIconInDropdownItem={false}
                    selectedDropdown={selectedValue}
                    isPrimaryImage={false}
                    customClassName="text-overflow"
                />
            </td>
        }
        else if (type === "commaSeperatedInt") {
            return (
                <td key={rowKey} title={convertToInternationalFormat(row[rowKey])}>
                    <Card.Link onClick={(e) => props.rowClickEvent ? props.rowClickEvent({ data: row, type: "display" }) : {}}>
                        <div className="table-row-title w-100 parentCell truncate-text-container">
                            {convertToInternationalFormat(row[rowKey])}
                            {rowKey === "Count" && rowIndex === activePageData.length - 1 && activePageData.some((item) => item.Title == "Remaining") && customAttributes.showMoreButton && (
                                <Card.Link className="ms-0 me-2" onClick={(e) => props.rowClickEventShowMore ? props.rowClickEventShowMore({ data: row, type: "display" }) : {}}>
                                    <span className="show-more-icon" title={"Show more"}>
                                        <FontAwesomeIcon icon={faArrowRight} />
                                    </span>
                                </Card.Link>
                            )}
                        </div>
                    </Card.Link>
                </td>
            )
        }
        else if (rowKey === "notifications.History") {
            return (
                <td key={rowKey}>
                    <Card.Link
                        onClick={(e) => props.rowClickEvent(e, row, "history")}
                        className={localStorage.getItem("isDemoClient") ? "d-flex align-items-center custom-disable" : "d-flex align-items-center"}
                    >
                        <i className="fa fa-eye" aria-hidden="true" title={t("header.WONotificationsViewTT")}></i>
                    </Card.Link>
                </td>
            );
        }
        else if (rowKey === "metadata") {
            return (
                <td key={rowKey} className="word-break table-truncate-text">
                    <OverlayTrigger
                        placement={"right"}
                        trigger={["hover", "focus"]}
                        overlay={

                            <Tooltip className="manage-user-additional-info">
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: row[rowKey]
                                    }}
                                ></div>
                            </Tooltip>
                        }
                    >
                        <Button variant="white" className="btn-help border-0 p-0 text-left manage-user-additional-info-label">
                            {executeHTMLTags(row[rowKey])}
                        </Button>
                    </OverlayTrigger>
                </td>
            );
        }
        else if (rowKey === "notifications.Ismailsent") {
            let value = row[rowKey];
            return (
                <td key={rowKey}>
                    <BadgesComponent
                        bgName={value === 1 ? BadgetsVarients["success"] : BadgetsVarients["light"]}
                        message={value === 1 ? t("header.Notification_Sent") : t("header.Notification_Not_sent")}
                        cls={value === 1 ? "me-1 badge-active" : "me-1 badge-inactive"}
                    />
                </td>
            );
        }
        else if (rowKey === "notifications.Edit") {
            return (
                <td key={rowKey}>
                    <Card.Link
                        onClick={(e) => props.rowClickEvent(e, row, "edit")}
                        className={localStorage.getItem("isDemoClient") ? "d-flex align-items-center custom-disable" : "d-flex align-items-center"}
                    >
                        <i className="fas fa-edit" title={t("header.WONotificationsEditTT")}></i>
                    </Card.Link>
                </td>
            );
        }
        else if (rowKey === "notifications.Delete") {
            return <td key={rowKey}><Card.Link
                className={localStorage.getItem("isDemoClient") ? "d-flex align-items-center custom-disable" : "d-flex align-items-center"}
                onClick={(e) => props.rowClickEvent(e, row, "delete")}
            >
                <i className="fa fa-trash" aria-hidden="true" title={t("header.notificationsDeleteTT")}></i>
            </Card.Link></td>
        }
        else {
            if (props.rowClickEvent) {
                return notClickable ?
                    <td key={rowKey} title={row[rowKey]}>
                        <div className="table-row-title w-100 parentCell truncate-text-container">
                            {row[rowKey]}
                        </div>
                    </td>
                    : <td key={rowKey} title={row[rowKey]}>
                        <Card.Link onClick={(e) => props.rowClickEvent ? props.rowClickEvent({ data: row, type: "display" }) : {}}>
                            <div className="table-row-title w-100 parentCell truncate-text-container">
                                {row[rowKey]}
                            </div>
                        </Card.Link>
                    </td>
            } else {
                return (
                    <td key={rowKey} title={TooltipForRowValues.TitleAndTooltip.some((element) => dispalyTableRow(row[rowKey]) === element.key) ? '' : row[rowKey]}>
                        {row[rowKey]}{TooltipForRowValues.TitleAndTooltip.map((element) => dispalyTableRow(row[rowKey]) === element.key ? createTooltip(t, element.tooltip) : '')}
                    </td>
                )
            }
        }
    }

    const formateDate = (dateValue, format, appendString) => {
        let time = new Date(dateValue)
        return new Date(dateValue).toLocaleDateString('en-us', format) + " " + time.getHours() + ":" + time.getMinutes() + ":" + time.getSeconds() + "" + appendString
    }

    const createTooltip = (t, tooltipKey) => {
        if (tooltipKey !== "") {
            return (
                <OverlayTrigger
                    placement="right"
                    trigger={["hover", "focus"]}
                    overlay={
                        <Tooltip>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: t(tooltipKey, {
                                        interpolation: { escapeValue: false },
                                    }),
                                }}
                            ></div>
                        </Tooltip>
                    }
                >
                    <Button variant="white" className="btn-help border-0 p-2">
                        <i className="far fa-question-circle lg"></i>
                    </Button>
                </OverlayTrigger>
            );
        }
    }

    const deepCopy = (obj) => {
        if (obj === null || typeof obj !== 'object') {
            return obj;
        }

        if (Array.isArray(obj)) {
            return obj.map(deepCopy);
        }

        const copy = {};
        for (const key in obj) {
            if (Object.prototype.hasOwnProperty.call(obj, key)) {
                copy[key] = deepCopy(obj[key]);
            }
        }

        return copy;
    }

    const replacementPattern = new RegExp(Object.keys(TnfTableHeaders.customEventsContent).join("|"), "g");

    return (<>
        {status === DataStatusMessage.DataAvailable && activePageData.length > 0 ? (
            <>
                <Row>
                    <Col>
                        {customAttributes.summaryCount > 0 &&
                            <div className="card-merices-container">
                                <div className="mt-1 mb-2 card-merices">
                                    <span className="metrics">{customAttributes.showCount ? formatCount(customAttributes.summaryCount) : ""}</span> {customAttributes.summaryText}
                                    {customAttributes.tooltipText && createTooltip(t, customAttributes.tooltipText)}
                                </div>
                            </div>}
                        <Table
                            responsive="lg"
                            className="table table-flush table-striped table-hover mb-3"
                        >
                            {renderTableHeader(tableHeader)}
                            {renderTableBody(tableHeader, activePageData)}
                        </Table>
                    </Col>
                </Row>
            </>
        ) : (
            <>
                <Row>
                    <Col>
                        <div className="card-no-data">
                            <CommonMessageComponent transactionStatus={status}></CommonMessageComponent>
                        </div>
                    </Col>
                </Row>
            </>
        )}

        {customAttributes.showPagination &&
            status === DataStatusMessage.DataAvailable &&
            dataSet.length > 0 && (
                <Row className="pagination-content">
                    <Col className="px-4 border-0 d-lg-flex align-items-center justify-content-between">
                        <PaginationComponent
                            pageNo={currentPageNo}
                            minimumRows={customAttributes.displayRowsPerPage}
                            totalRecords={tableData.length}
                            onPageNoChange={setCurrentPageNo}
                            showPaginationSummary={true}
                        />
                    </Col>
                </Row>
            )}
    </>);
}
export default React.memo(DataTableRenderer);