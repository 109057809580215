import moment from "moment";


export const dashbordDateRangeList = [
  {
    compareOn: 7,
    value: "Last 7 Days",
    key: `${moment().add(-7, 'days').format("MMM DD,YYYY")} - ${moment(new Date()).subtract(1, 'day').format("MMM DD,YYYY")}`,
    showDatePicker: false
  },
  {
    compareOn: 30,
    value: "Last 30 Days",
    key: `${moment().add(-30, 'days').format("MMM DD,YYYY")} - ${moment(new Date()).subtract(1, 'day').format("MMM DD,YYYY")}`,
    showDatePicker: false
  },
  {
    compareOn: 90,
    value: "Last 90 Days",
    key: `${moment().add(-90, 'days').format("MMM DD,YYYY")} - ${moment(new Date()).subtract(1, 'day').format("MMM DD,YYYY")}`,
    showDatePicker: false
  },
  {
    compareOn: 180,
    value: "Last 180 Days",
    key: `${moment().add(-180, 'days').format("MMM DD,YYYY")} - ${moment(new Date()).subtract(1, 'day').format("MMM DD,YYYY")}`,
    showDatePicker: false
  }
  // {
  //   compareOn: 0,
  //   value: "Custom Range",
  //   key: "",
  //   showDatePicker: true
  // },
];