import React, { Suspense, useEffect } from 'react';
import TagManager from "react-gtm-module";
import { HashRouter as Router } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import "react-datetime/css/react-datetime.css";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import "@fortawesome/fontawesome-free/css/all.css";

// core styles
import "./scss/volt.scss";
import './body.css';
import AppRoutes from './routes/Routes';

import AppContextComponent from "./components/AppState";
import ScrollToTop from './components/scrollToTop';

const App = () => {

  useEffect(() => {
    if (process.env.REACT_APP_ENV === "PROD") {
      TagManager.initialize({ gtmId: "GTM-NZCMMT7Q" });
    }
  }, []);

  return (
    <Router>
      <ScrollToTop />
      <Suspense fallback="">
        <AppContextComponent>
          <HelmetProvider>
            <AppRoutes />
          </HelmetProvider>
        </AppContextComponent>
      </Suspense>
    </Router>
  );
};

export default App;