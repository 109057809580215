import React, { useState, useEffect, useContext, useMemo } from 'react';
import { Row, Col, Button, Image, Modal } from '@themesberg/react-bootstrap';
import AppContext from '../components/AppContext';
import request from '../apis/request';
import { baseURL, METHODS } from '../apis/utilities/constant';
import LoaderImage from "../assets/img/LoaderGraphic.svg";
import AddEventPages from '../pages/AddEventPages';
import { useTranslation } from 'react-i18next';
import { decryptData } from '../utils/crypto';
import { isMobile } from 'react-device-detect';
import DashboardItem from './DashboardItem';
import Search from './Search';
import ChartRendererTable from './ChartRendererTable';
import { eventCalender } from '../stubs/ComponentJson/tools';
import iconSprite from "../assets/Icon/svg-icn-sprite.svg";
import Filters from './Filters';

const EventCalendar = React.memo(({ selectedWebsiteId, selectedLanguage
  //setPageNo, setOffset, , setSelectedLanguage, history, location, offset, pageNo 
}) => {
  const [t, i18n] = useTranslation();
  const contextData = useContext(AppContext);
  // const [updateKey, setUpdateKey] = useState("");
  // const [isOpenEditModal, setIsOpenEditModal] = useState(false);


  const [filterShow, setFilterShow] = useState(false);
  const [searchData, setSearchData] = useState("");
  const [numberOfRecords, setNumberOfRecords] = useState(0);
  const [eventCalenderData, seteventCalenderData] = useState([]);
  const [selectedDropdownCategoryItem, setSelectedDropdownCategoryItem] = useState({ CategoryId: 0, CategoryName: "All" })
  const [selectedDropdownYearItem, setSelectedDropdownYearItem] = useState({ Year: 'All' });
  const [dropdownYearList, setDropdownYearList] = useState([]);
  const [dropdownCategoryList, setDropdownCategoryList] = useState([]);
  const [getTotalResults, setGetTotalResults] = useState(0);
  const [attachmentView, setAttachmentView] = useState(false);
  const [isPageLoad, setIsPageLoad] = useState(false);
  const [editData, setEditData] = useState(undefined);
  const [blurLoader, setBlurLoader] = useState(false);



  useEffect(() => {
    setSearchData("");
    const language = selectedLanguage && selectedLanguage["Languages.Languagecode"] ? selectedLanguage["Languages.Languagecode"] : null;
    let dta = eventCalender(contextData.companyId, contextData.userId, contextData.websiteId, language, 0, 0);
    seteventCalenderData(dta);
  }, [selectedWebsiteId, selectedLanguage]);

  useEffect(() => {
    if (isPageLoad) {
      setIsPageLoad(false);
      const language = selectedLanguage && selectedLanguage["Languages.Languagecode"] ? selectedLanguage["Languages.Languagecode"] : null;

      let dta = eventCalender(contextData.companyId, contextData.userId, contextData.websiteId, language, 0, 0);
      seteventCalenderData(dta);
      setSelectedDropdownCategoryItem({ CategoryId: 0, CategoryName: "All" });
      setSelectedDropdownYearItem({ Year: 'All' });
      setSearchData("")
      setFilterShow(false)
    }
  }, [isPageLoad]);

  useEffect(() => {
    if (!editData) {
      setAttachmentView(false);
    }
  }, [editData])



  useEffect(() => {
    let language = selectedLanguage && selectedLanguage["Languages.Languagecode"] ? selectedLanguage["Languages.Languagecode"] : null;
    let websiteId = selectedWebsiteId["WebsiteList.WebsiteId"];
    let companyId = contextData.companyId;
    let userId = contextData.userId;

    //getYearList for dropdown
    if (websiteId > 0 && language !== null) {

      request(baseURL + `/EventCalendar/GetEventYearList?CompanyId=${companyId}&UserId=${userId}&WebsiteId=${websiteId}&LanguageCode=${language}`,
        METHODS.GET,
        {},
        {
          Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
        })
        .then((res) => {
          let resultData = []
          if (res.result !== null && res.result?.length) {
            resultData = [
              { Year: "All" },
              ...res.result.map(Year => ({ Year: Year }))
            ]
          }
          setDropdownYearList(resultData)
        })
    }

    //getCategoryForDropdown
    if (websiteId > 0) {

      request(baseURL + `/EventCalendar/GetEventCategoryByClient?CompanyId=${companyId}&UserId=${userId}&WebsiteId=${websiteId}`,
        METHODS.GET,
        {},
        {
          Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
        })
        .then((res) => {
          let resultData = [];
          if (res.result !== null && res.result?.length) {
            resultData = [
              { CategoryId: 0, CategoryName: "All" },
              ...res.result.map(Category => ({ CategoryId: Category.CategoryId, CategoryName: Category.CategoryName }))
            ]
          }
          setDropdownCategoryList(resultData)
        })
    }

    setSelectedDropdownYearItem({ Year: 'All' })
    setSelectedDropdownCategoryItem({ CategoryId: 0, CategoryName: "All" })
    setSearchData("")
    setFilterShow(false)


  }, [selectedWebsiteId, selectedLanguage])

  function onYearDropdownChange(data) {
    if (data !== undefined && data !== null) {
      setSelectedDropdownYearItem(data)
    }
  }

  function onCategoryDropdownChange(data) {
    if (data !== undefined && data !== null) {
      setSelectedDropdownCategoryItem(data)
    }
  }

  const setFilterEnable = (data) => {
    if (data && data.data?.length > 0) {
      setFilterShow(true);
    }
  }

  function applyfilter1() {
    const language = selectedLanguage && selectedLanguage["Languages.Languagecode"] ? selectedLanguage["Languages.Languagecode"] : null;
    var selectedYear = selectedDropdownYearItem.Year === "All" ? 0 : selectedDropdownYearItem.Year;
    var selectedCategoryId = selectedDropdownCategoryItem.CategoryId;
    // setPageNo(1)
    let dta = eventCalender(contextData.companyId, contextData.userId, contextData.websiteId, language, selectedYear, selectedCategoryId);

    let x = searchData;

    seteventCalenderData(dta);
    if (x) {
      setSearchData(x);
    }
  }

  function cancelfilter1() {
    const language = selectedLanguage && selectedLanguage["Languages.Languagecode"] ? selectedLanguage["Languages.Languagecode"] : null;
    let dta = eventCalender(contextData.companyId, contextData.userId, contextData.websiteId, language, 0, 0);
    seteventCalenderData(dta);

    setSelectedDropdownYearItem({ Year: 'All' });
    setSelectedDropdownCategoryItem({ CategoryId: 0, CategoryName: "All" });

  }

  const onEditEvent = (e, row, rowIndex, type) => {
    e.preventDefault();

    let eventId = row["EventTable.EventId"];
    let language = selectedLanguage && selectedLanguage["Languages.Languagecode"] ? selectedLanguage["Languages.Languagecode"] : null;
    let websiteId = contextData.websiteId;
    let companyId = contextData.companyId;
    let userId = contextData.userId;

    if (eventId) {
      setBlurLoader(true);
      request(baseURL + `/EventCalendar/GetEventDetailByEventId?CompanyId=${companyId}&UserId=${userId}&eventId=${eventId}&WebsiteId=${websiteId}&LanguageCode=${language}`,
        METHODS.GET,
        {},
        {
          Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
        }
      )
        .then((res) => {

          if (type == "Attachments") {
            setAttachmentView(true);
          }
          setTimeout(() => {
            setEditData(res);
          }, 10);
          setBlurLoader(false);
        })
        .catch((err) => {
          setEditData(undefined);
          setBlurLoader(false);
        })
    }
  }

  const createFilter = useMemo(
    () => (
      <>
        <Filters
          iconText={"Filter"}
          applyFilters={applyfilter1}
          cancelFilter={cancelfilter1}
          showHeader={false}
          btnOneText={t("header.ToolsEventCalFilterButton")}
          btnTwoText={t("header.ToolsEventCalClearFilterButton")}
          dataSource={[
            {
              name: "Year",
              data: dropdownYearList,
              selectedItem: selectedDropdownYearItem,
              isVisible: true,
              label: t("header.ToolsEventCalFilterYear"),
              callback: onYearDropdownChange,
              displayText: "Year",
            },
            {
              name: "Category",
              data: dropdownCategoryList,
              selectedItem: selectedDropdownCategoryItem,
              isVisible: dropdownCategoryList.length ? true : false,
              label: t("header.ToolsEventCalFilterCategory"),
              callback: onCategoryDropdownChange,
              displayText: "CategoryName",
            }
          ]}
        ></Filters>
      </>
    ),
    [selectedDropdownYearItem, dropdownYearList, selectedDropdownCategoryItem, dropdownCategoryList]
  );

  const handleClose = () => {
    // setIsOpenEditModal(false);
    setEditData(undefined);
  }
  const deserializeItem = (i) => ({
    ...i,
    layout: i.layout ? JSON.parse(i.layout) : {},
    summary: i.summary ? JSON.parse(i.summary) : {},
    vizState: i.vizState ? JSON.parse(i.vizState) : {},
    apiUrl: i.apiUrl ? i.apiUrl : undefined
  });
  const defaultLayout = (i) => ({
    x: i.layout.x || 0,
    y: i.layout.y || 0,
    w: i.layout.w || 4,
    h: i.layout.h || 8,
    minW: 4,
    minH: 8,
  });

  const defaultLayoutMobile = (i) => ({
    x: i.layout.x || 0,
    y: i.layout.y || 0,
    w: 12,
    // w: i.layout.w || 4,
    h: i.layout.h + 2 || 12,
    minW: 4,
    minH: 8,
  });

  const dashboardItem = (item) => (
    <div key={item.id} data-grid={isMobile ? defaultLayoutMobile(item) : defaultLayout(item)}>
      {
        selectedLanguage && selectedLanguage["Languages.Languagecode"] ?
          <DashboardItem
            key={item.id}
            itemId={item.id}

            title={t("header.ToolsEventCalEventListDesktopInfo")}

            customTitle={"eventcalender"}
          >

            {<Row className="">
              <Col xs={12} className="text-end icon-nav-list svg-icon-nav-list">
                <div className="pe-4 d-inline-block pull-right">
                  {filterShow ? createFilter :
                    <div className="d-inline-block icon-nav icon-nav-filter ms-2">
                      <div className="slider-dropdown-tigger d-inline-block filter-box-tigger icon-nav-list svg-icon-nav-list">
                        <span className="icon-nav-box  ms-0 disabled"  >
                          <svg className="icon-nav">
                            <title>{t("header.Icon_Tooltip_Filter")}</title>
                            <use href={iconSprite + `#Filter`} />
                          </svg>
                        </span>
                      </div>
                    </div>
                  }
                </div>
              </Col>
            </Row>}
            {<Row>
              <Col xs={12} className="text-end position-relative">
                <div className="float-right">
                  <div className="mt-3">
                    <Search
                      setsearchData={setSearchData}
                      searchData={searchData}
                      isAutoComplete={false}
                      searchWaterMark={t("header.ToolsEventCalSearchPlaceholder")}
                      type={'client'}>
                    </Search>
                  </div>
                </div>
              </Col>
            </Row>}

            <ChartRendererTable
              totalRecords={numberOfRecords}
              setNumberOfRecords={setNumberOfRecords}
              onEditEvent={(e, row, index, type) => { onEditEvent(e, row, index, type) }}
              minimumRows={10}
              //summaryTooltip={showTooltips(item)}
              summaryBesideText={t("header.ToolsEventCalEventListTotalEvents")}
              showPagination={true}
              apiUrl={item.apiUrl}
              showNumberOfRows={10}
              SearchText={searchData}
              setSearchText={setSearchData}
              Exportdata={setFilterEnable}
              setGetTotalResults={(value) => setGetTotalResults(value)}
            />
          </DashboardItem> :
          <div className="d-inline-block p-3 pe-0 w-100">
            <div className="shadow-soft border rounded border-light w-100 nested-card-container box-height">
              <div className='text-center no-data position-relative mt-5'>
                {t("header.contentUnavailable")}
              </div>
            </div>
          </div>
      }

    </div>
  );

  return (
    <>

      {/* Edit Modal */}
      {editData &&
        <Modal as={Modal.Dialog} size="xl" centered show={true} onHide={handleClose}>
          <Modal.Header>
            <Modal.Title className="h5">
              <h5>{t("header.ToolsEventCalModEventHeading")}</h5>
            </Modal.Title>

            <Button variant="close" aria-label="Close" onClick={handleClose} />
          </Modal.Header>

          <Modal.Body>
            <AddEventPages
              setIsPageLoad={setIsPageLoad}
              setEditData={setEditData}
              attachmentView={attachmentView}
              isEditMode={true}
              editData={editData}
            />
          </Modal.Body>
        </Modal>
      }

      {blurLoader &&
        <div className={`preloader loader-center-align  ${blurLoader ? "" : "show fff"}`}>
          <Image className="loader-element animate__animated animate__jackInTheBox" src={LoaderImage} height={40} data-seconds={30} />
        </div>

      }

      <div id="websitestatistics" className="d-inline-block p-3 pe-0 website-analytics w-100" >
        <Row className='width-adjust'>
          {eventCalenderData.map(deserializeItem).map(dashboardItem)}
        </Row>

      </div>
    </>
  );
});

export default EventCalendar;