import React, { useState, useContext } from "react";
import { useTranslation } from 'react-i18next';
import { Row, Col, Form, Button } from "@themesberg/react-bootstrap";

import request from "../apis/request";
import { baseURL, METHODS } from "../apis/utilities/constant";
import { decryptData } from "../utils/crypto";

import AppContext from '../components/AppContext';
import ButtonComponent from "../components/Buttons";
import ModalComponent from "../components/VoltModal";

const ServiceCenterRequestMoreHours = ({ workorderCompany, setshowSuccess, setshowError, setShowBlurLoader }) => {

  const [t, i18n] = useTranslation();

  const contextData = useContext(AppContext);
  const [showServiceModal, setshowServiceModal] = useState(false);
  const [requestHours, setRequestHours] = useState();
  const [messagebody, setmessagebody] = useState();
  const [InvalidRequestHoursvalidationmessage, setInvalidRequestHoursvalidationmessage] = useState("");

  const handleModalOpenButtonClick = (e) => {
    e.preventDefault();
    setshowServiceModal(true);
    setInvalidRequestHoursvalidationmessage('');
  };

  const onModalClose = () => {
    setshowServiceModal(false);
    setInvalidRequestHoursvalidationmessage('');
    setRequestHours()
    setmessagebody()
  };

  const handleSubmitButtonClick = (e) => {
    e.preventDefault();
    setInvalidRequestHoursvalidationmessage(undefined);
    if (!requestHours) {
      setInvalidRequestHoursvalidationmessage(t("header.RequestMoreHoursRequiredMessage"));
    }
    else {
      setShowBlurLoader && setShowBlurLoader(true);
      const firstName = decryptData(localStorage.getItem("firstName"), contextData.cryptoKey);
      const lastName = decryptData(localStorage.getItem("lastName"), contextData.cryptoKey);
      const companyId = JSON.parse(decryptData(localStorage.getItem("selectedCompanyData"), contextData.cryptoKey))["companyId"];
      const capitalizedFirstName = firstName.charAt(0).toUpperCase() + firstName.slice(1);
      const capitalizedLastName = lastName.charAt(0).toUpperCase() + lastName.slice(1);
      const obj = {
        topUpHours: requestHours,
        message: messagebody,
        clientEmail: decryptData(localStorage.email, contextData.cryptoKey),
        woClientAccountName: workorderCompany,
        clientName: capitalizedFirstName + " " + capitalizedLastName
      };
      request(baseURL + `/ServiceCentre/RequestMoreHours_SendMail?&CompanyId=${companyId}`,
        METHODS.POST,
        obj,
        {
          Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`
        }
      )
        .then(response => {
          if (response?.mailSent) {
            setshowSuccess(true);
            setTimeout(() => {
              setshowSuccess(false);
            }, 3000);
          }
          else {
            setshowError(true);
            setTimeout(() => {
              setshowSuccess(false);
            }, 3000);
          }
          setRequestHours(undefined);
          setmessagebody(undefined);
          setshowServiceModal(false);
          setInvalidRequestHoursvalidationmessage(undefined);
          setShowBlurLoader && setShowBlurLoader(false);
        })
        .catch(error => {
          setshowError(true);
          setRequestHours(undefined);
          setmessagebody(undefined);
          setshowServiceModal(false);
          setInvalidRequestHoursvalidationmessage(undefined);
          setShowBlurLoader && setShowBlurLoader(false);
        });
    }
  }

  const onFormElementsChange = (event) => {
    const { value } = event.target;
    setRequestHours(value);
  };

  const onFormElementsChangeMSG = (event) => {
    const { value } = event.target;
    setmessagebody(value);
  };

  return (
    <>
      <Row>
        <Col md={12}>
          <Button variant="primary" onClick={(e) => handleModalOpenButtonClick(e)} className="ms-3">
            {t("header.serviceCenterRequestMoreHours")}
          </Button>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          {showServiceModal && <ModalComponent
            open={showServiceModal}
            title={t("header.serviceCenterRequestMoreHours")}
            handleClose={onModalClose}
            showCloseButton={true}>
            <Row className="mb-2">
              <Col md={4}>
                <Form.Label>{t("header.serviceCenterRequestMoreHoursLabel1")}<span className="mandatory">*</span></Form.Label>
              </Col>
              <Col md={1}>
                <Form.Control
                  type="numeric"
                  name="requestHours"
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                    else if (parseInt(requestHours + event.key) > 5000) {
                      event.preventDefault();
                    }
                  }}
                  maxlength="4" onChange={(e) => onFormElementsChange(e)} value={requestHours} />
              </Col>
              <Col md={6}>{t("header.serviceCenterRequestMoreHoursLabel2")}</Col>
              <Col className="invalid-feedback d-block offset-md-4">
                {InvalidRequestHoursvalidationmessage}
              </Col>
            </Row>
            <Row className="mb-2">
              <Col md={4}>
                <Form.Label>
                  {t("header.serviceCenterRequestMoreHoursLabel3")}
                </Form.Label>
              </Col>
              <Col md={6}>
                <Form.Control as="textarea" rows="3" maxlength="500" name="messagebody" onChange={(e) => onFormElementsChangeMSG(e)} value={messagebody} />
                <div className="font-small">{t("header.serviceCenterRequestMoreHoursLabel4")}</div>
              </Col>
            </Row>
            <Row className="mt-4 mb-2">
              <Col className="offset-md-4">
                <Button variant="primary" className="me-2" onClick={(e) => handleSubmitButtonClick(e)}>
                  {t("header.serviceCenterRequestMoreHoursSend")}
                </Button>
                <ButtonComponent
                  isIcon={false}
                  isPrimary={false}
                  btnText={t("header.serviceCenterRequestMoreHoursCancel")}
                  onClick={(e) => onModalClose(e)}
                  btn_class="mb-0" />
              </Col>
            </Row>
          </ModalComponent>
          }
        </Col>
      </Row>
    </>
  );

};

export default ServiceCenterRequestMoreHours;