export const urls = [
    "https://intelligence-api.connectid.dev/WebsiteAnalytics/GetCardDataFromGAV2?StartDate=2025-02-17&EndDate=2025-03-18&profileID=312109640&Matrix=sessions&Dimensions=date&Component=audiencetrend&isShort=1",
    "https://intelligence-api.connectid.dev/WebsiteAnalytics/GetCardDataFromGAV2?StartDate=2025-02-17&EndDate=2025-03-18&profileID=312109640&Matrix=sessions&Dimensions=date,channelGrouping&Component=trafficsource",
    "https://intelligence-api.connectid.dev/CookieManagerV2/GetCookiePieChartData?WebsiteURL=https://www.justgroupplc.co.uk/&StartDate=2025-02-17&EndDate=2025-03-18",
    "https://intelligence-api.connectid.dev/WebsiteAnalytics/GetCardDataFromGAV2?StartDate=2025-02-17&EndDate=2025-03-18&profileID=312109640&Matrix=users&Dimensions=date&Component=users&isShort=1",
    "https://intelligence-api.connectid.dev/UserV2/GetSubMenuByClientUser?companyId=9345&sectionid=1&websiteid=52181&aiconsent=1",
    "https://intelligence-api.connectid.dev/WebsiteAnalytics/GetVisitorWithWatchlistByProfileId?profileid=312109640&intellConfigId=31721&startDate=2025-02-17&endDate=2025-03-18&companyid=9345&packageid=5&isshort=1",
    "https://intelligence-api.connectid.dev/WebsiteAnalytics/GetWatchedVisitorByWebsite?profileid=312109640&intellConfigId=31721&startDate=2025-02-17&endDate=2025-03-18&companyid=9345&packageid=5&isshort=1",
    "https://intelligence-api.connectid.dev/WebsiteAnalytics/GetCardDataFromGAV2?StartDate=2025-02-17&EndDate=2025-03-18&profileID=312109640&Matrix=searchResultViews&Dimensions=searchKeyword&Component=table,search&isShort=1",
    "https://intelligence-api.connectid.dev/WebsiteAnalytics/GetCardDataFromGAV2?StartDate=2025-02-17&EndDate=2025-03-18&profileID=312109640&Matrix=users&Dimensions=country&Component=table,country&culture=en-US&isShort=1",
    "https://intelligence-api.connectid.dev/WebsiteAnalytics/GetCardDataFromGAV2?StartDate=2025-02-17&EndDate=2025-03-18&profileID=312109640&Matrix=screenPageViews,averageSessionDuration,bounceRate,totalUsers&Dimensions=pageTitle,pagePath&Component=table,contentinsights,website&isShort=1",
    "https://intelligence-api.connectid.dev/CookieManagerV2/GetCookieMultilineChartData?WebsiteURL=https://www.justgroupplc.co.uk/&StartDate=2024-12-19&EndDate=2025-03-18",
    "https://intelligence-api.connectid.dev/CookieManagerV2/GetCookieAdditionalData?WebsiteURL=https://www.justgroupplc.co.uk/&StartDate=2024-12-19&EndDate=2025-03-18",
    "https://intelligence-api.connectid.dev/WebsiteAnalytics/GetCardDataFromGAV2?StartDate=2024-12-19&EndDate=2025-03-18&profileID=312109640&Matrix=searchResultViews,pageviews&Dimensions=searchKeyword&Component=table,search",
    "https://intelligence-api.connectid.dev/WebsiteAnalytics/GetCardDataFromGAV2?StartDate=2024-12-19&EndDate=2025-03-18&profileID=312109640&Matrix=users&Dimensions=date&Component=users&isShort=0",
    "https://intelligence-api.connectid.dev/WebsiteAnalytics/GetCardDataFromGAV2?StartDate=2024-12-19&EndDate=2025-03-18&profileID=312109640&Matrix=users&Dimensions=country&Component=table,country&culture=en-US",
    "https://intelligence-api.connectid.dev/WebsiteAnalytics/GetCardDataFromGAV2?StartDate=2024-12-19&EndDate=2025-03-18&profileID=312109640&Matrix=users&Dimensions=country&Component=table,country&culture=en-US",
    "https://intelligence-api.connectid.dev/VisitorAnalytics/GetWebsiteInsightSummaryByProfileId?endDate=2025-03-18&isAnalyticsRequired=false&profileId=312109640&startDate=2024-09-20",
    "https://intelligence-api.connectid.dev/VisitorAnalytics/GetResolvedUnresolvedVisitorsCountByProfileId?startDate=2024-09-20&endDate=2025-03-18&profileId=312109640&companyid=9345&isTF=0&toolid=0&intelconfigid=31721",
    "https://intelligence-api.connectid.dev/VisitorAnalytics/GetIntelligencePageViewSeriesByProfileId?profileId=312109640&startDate=2024-09-20&endDate=2025-03-18&isTF=0&toolid=0&intelconfigid=31721&sectionid=0&packageid=5",
    "https://intelligence-api.connectid.dev/WebsiteAnalytics/GetVisitorWithWatchlistByProfileId?profileid=312109640&intellConfigId=31721&startDate=2024-09-20&endDate=2025-03-18&companyid=9345&packageid=5&isTF=0&toolid=0",
    "https://intelligence-api.connectid.dev/VisitorAnalytics/GetCategory",
    "https://intelligence-api.connectid.dev/VisitorAnalytics/GetCategoryWisePageViews?profileId=312109640&startDate=2024-09-20&endDate=2025-03-18&isTF=0&toolid=0&intelconfigid=31721&sectionId=0&packageid=5",
    "https://intelligence-api.connectid.dev/WebsiteAnalytics/GetWatchedVisitorByWebsite?endDate=2025-03-18&startDate=2024-09-20&profileId=312109640&packageid=5&intellConfigId=31721&companyid=9345&isTF=0&toolid=0&refreshCache=1",
    "https://intelligence-api.connectid.dev/VisitorAnalytics/GetPagesDataByProfileId?profileid=312109640&startDate=2024-09-20&endDate=2025-03-18&companyid=9345&isTF=0&toolid=0&sortexpression=PageViews&sortdirection=true&intelconfigid=31721&packageid=5",
    "https://intelligence-api.connectid.dev/VisitorAnalytics/GetCountryWisePageViews?profileid=312109640&startDate=2024-09-20&endDate=2025-03-18&isTF=0&toolid=0&culture=en-US&intelconfigid=31721&sectionId=0&packageid=5",
    "https://intelligence-api.connectid.dev/VisitorAnalytics/GetTrafficSourceByProfileId?profileid=312109640&startDate=2024-09-20&endDate=2025-03-18&toolid=0&isTF=0&sectionid=0&culture=en-US&intelconfigid=31721",
    "https://intelligence-api.connectid.dev/VisitorAnalytics/GetPageViewVisitByVisitor?profileid=312109640&startdate=2024-09-20&enddate=2025-03-18&visitorid=435076&countryid=404&isTF=0&toolid=0&intelconfigid=31721",
    "https://intelligence-api.connectid.dev/VisitorAnalytics/GetIntelligencePagesByVisitor?profileid=312109640&startDate=2024-09-20&endDate=2025-03-18&countryid=404&visitorid=435076&isTF=0&toolid=0&intelconfigid=31721",
    "https://intelligence-api.connectid.dev/VisitorAnalytics/GetIntelligenceTrafficSourceByVisitor?profileid=312109640&startdate=2024-09-20&enddate=2025-03-18&companyid=435076&countryid=404&isTF=0&toolid=0&intelconfigid=31721",
    "https://intelligence-api.connectid.dev/VisitorAnalytics/GetIntelligenceTrafficSourceByVisitor?profileid=312109640&startdate=2024-09-20&enddate=2025-03-18&companyid=435076&countryid=404&isTF=0&getvalues=1&toolid=0&intelconfigid=31721",
    "https://intelligence-api.connectid.dev/VisitorAnalytics/GetTopSourceMediumDomainsByTrafficSourceAndVisitor?profileid=312109640&startdate=2024-09-20&enddate=2025-03-18&countryid=404&companyid=435076&traffic=Organic&isTF=0&toolid=0&intelconfigid=31721",
    "https://intelligence-api.connectid.dev/VisitorAnalytics/GetPagesDataByProfileId?profileid=312109640&startDate=2024-09-20&endDate=2025-03-18&companyid=9345&isTF=0&toolid=0&sortexpression=PageViews&sortdirection=true&intelconfigid=31721&packageid=5",
    "https://intelligence-api.connectid.dev/VisitorAnalytics/GetPageViewVisitsWithPRByPage?profileid=312109640&startdate=2024-09-20&enddate=2025-03-18&pageid=97472667&companyid=9345&isTF=0&toolid=0&intelconfigid=31721",
    "https://intelligence-api.connectid.dev/VisitorAnalytics/GetVisitorByPage?profileid=312109640&startDate=2024-09-20&endDate=2025-03-18&companyid=9345&pageid=97472667&isTF=0&toolid=0&intelconfigid=31721",
    "https://intelligence-api.connectid.dev/VisitorAnalytics/GetTrafficSourceByPage?profileid=312109640&startdate=2024-09-20&enddate=2025-03-18&pageid=97472667&companyid=9345&isTF=0&toolid=0&intelconfigid=31721",
    "https://intelligence-api.connectid.dev/VisitorAnalytics/GetTrafficSourceByPage?profileid=312109640&startdate=2024-09-20&enddate=2025-03-18&pageid=97472667&companyid=9345&isTF=0&getvalues=1&toolid=0&intelconfigid=31721",
    "https://intelligence-api.connectid.dev/VisitorAnalytics/GetTopDomainByTrafficSourcePage?profileid=312109640&startDate=2024-09-20&endDate=2025-03-18&companyid=9345&pageid=97472667&traffic=Organic&packageid=5&sectionid=0&isTF=0&toolid=0&intelconfigid=31721",
    "https://intelligence-api.connectid.dev/WebsiteAnalytics/GetCardDataFromGAV2?StartDate=2024-09-20&EndDate=2025-03-18&profileID=312109640&Matrix=screenPageViews,averageSessionDuration,bounceRate,totalUsers&Dimensions=pageTitle,pagePath&Component=table,contentinsights",
    "https://intelligence-api.connectid.dev/WebsiteAnalytics/GetCardDataFromGAV2?StartDate=2024-09-20&EndDate=2025-03-18&profileID=312109640&Matrix=pageviews,users&Dimensions=date&Component=multilinechart",
    "https://intelligence-api.connectid.dev/WebsiteAnalytics/GetNotificationDuration?packageid=5",
    "https://intelligence-api.connectid.dev/WebsiteAnalytics/GetNotification?profileId=312109640&packageid=5&companyid=9345&intellconfigid=31721",
    "https://intelligence-api.connectid.dev/WebcastV2/GetWebcastVE?presentationId=5f69d4b0d3673f10000a071b&companyId=9345&eventId=5f69cb59fe4b5e0a000c315e&viewerType=&domain=&viewer=",
    "https://intelligence-api.connectid.dev/WebcastV2/GetWebcastConfiguration?companyId=9345",
    "https://intelligence-api.connectid.dev/Common/GetWebcastPresentationByClientUser?CompanyId=9345&UserId=116641",
    "https://intelligence-api.connectid.dev/Analytics/GetRegisteredUsers?eventId=5f69cb59fe4b5e0a000c315e",
    "https://intelligence-api.connectid.dev/TnfAnalytics/GetGA4DataTnfToolsOverview?StartDate=2024-12-19&EndDate=2025-03-18&profileID=426562393&Matrix=totalUsers&Dimensions=date,pagePath,pageTitle&Component=multilinechart&intelConfigId=40101",
    "https://intelligence-api.connectid.dev/TnfAnalytics/GetGA4DataTnfToolsOverview?StartDate=2024-12-19&EndDate=2025-03-18&profileID=426562393&Matrix=screenPageViews&Dimensions=pageTitle,pagePath&Component=piechart&intelConfigId=40101",
    "https://intelligence-api.connectid.dev/TnfAnalytics/GetGA4DataTnfToolsOverview?StartDate=2024-12-19&EndDate=2025-03-18&profileID=426562393&Matrix=eventCount&Dimensions=country,eventName,pagePath&Component=map&Culture=en-US&intelConfigId=40101",
    "https://intelligence-api.connectid.dev/TnfAnalytics/GetGA4DataTnfToolsOverview?StartDate=2024-12-19&EndDate=2025-03-18&profileID=426562393&Matrix=eventCount,screenPageViews,sessionsPerUser,totalUsers,userEngagementDuration&Dimensions=eventName,pagePath,pageTitle&Component=table&intelConfigId=40101",
    "https://intelligence-api.connectid.dev/TnfAnalytics/GetGA4DataTnfComponents?ToolId=1&StartDate=2024-12-19&EndDate=2025-03-18&profileID=426562393&Matrix=eventCount,screenPageViews,totalUsers,userEngagementDuration&Dimensions=country,date,eventName,pagePath&intelConfigId=40101",
    "https://intelligence-api.connectid.dev/TnfAnalytics/GetGA4DataTnfComponents?ToolId=5&StartDate=2024-09-20&EndDate=2025-03-18&profileID=426562393&Matrix=eventCount,screenPageViews,totalUsers,userEngagementDuration&Dimensions=country,date,eventName,pagePath&intelConfigId=40101",
    "https://intelligence-api.connectid.dev/WebsiteAnalytics/GetNotification?profileId=426562393&packageid=5&companyid=12421&intellconfigid=40101&isTF=1",
    "https://intelligence-api.connectid.dev/WebsiteAnalytics/GetNotification?profileId=426562393&packageid=5&companyid=12421&intellconfigid=40101&isTF=1",
    "https://intelligence-api.connectid.dev/VisitorAnalytics/GetResolvedUnresolvedVisitorsCountByProfileId?startDate=2024-09-20&endDate=2025-03-18&profileId=426562393&companyid=12421&isTF=1&toolid=0&intelconfigid=40101",
    "https://intelligence-api.connectid.dev/VisitorAnalytics/GetIntelligencePageViewSeriesByProfileId?profileId=426562393&startDate=2024-09-20&endDate=2025-03-18&isTF=1&toolid=0&intelconfigid=40101&sectionid=0&packageid=5",
    "https://intelligence-api.connectid.dev/WebsiteAnalytics/GetVisitorWithWatchlistByProfileId?profileid=426562393&intellConfigId=40101&startDate=2024-09-20&endDate=2025-03-18&companyid=12421&packageid=5&isTF=1&toolid=0",
    "https://intelligence-api.connectid.dev/VisitorAnalytics/GetCategoryWisePageViews?profileId=426562393&startDate=2024-09-20&endDate=2025-03-18&isTF=1&toolid=0&intelconfigid=40101&sectionId=0&packageid=5",
    "https://intelligence-api.connectid.dev/VisitorAnalytics/GetCountryWisePageViews?profileid=426562393&startDate=2024-09-20&endDate=2025-03-18&isTF=1&toolid=0&culture=en-US&intelconfigid=40101&sectionId=0&packageid=5",
    "https://intelligence-api.connectid.dev/VisitorAnalytics/GetTrafficSourceByProfileId?profileid=426562393&startDate=2024-09-20&endDate=2025-03-18&toolid=0&isTF=1&sectionid=0&culture=en-US&intelconfigid=40101",
    "https://intelligence-api.connectid.dev/WebsiteAnalytics/GetWatchedVisitorByWebsite?endDate=2025-03-18&startDate=2024-09-20&profileId=426562393&packageid=5&intellConfigId=40101&companyid=12421&isTF=1&toolid=0&refreshCache=1",
    "https://intelligence-api.connectid.dev/VisitorAnalytics/GetPagesDataByProfileId?profileid=426562393&startDate=2024-09-20&endDate=2025-03-18&companyid=12421&isTF=1&toolid=0&sortexpression=PageViews&sortdirection=true&intelconfigid=40101&packageid=5",
    "https://intelligence-api.connectid.dev/VisitorAnalytics/GetPageViewVisitsWithPRByPage?profileid=426562393&startdate=2024-09-20&enddate=2025-03-18&pageid=97631489&companyid=12421&isTF=1&toolid=0&intelconfigid=40101",
    "https://intelligence-api.connectid.dev/VisitorAnalytics/GetVisitorByPage?profileid=426562393&startDate=2024-09-20&endDate=2025-03-18&companyid=12421&pageid=97631489&isTF=1&toolid=0&intelconfigid=40101",
    "https://intelligence-api.connectid.dev/VisitorAnalytics/GetTrafficSourceByPage?profileid=426562393&startdate=2024-09-20&enddate=2025-03-18&pageid=97631489&companyid=12421&isTF=1&toolid=0&intelconfigid=40101",
    "https://intelligence-api.connectid.dev/VisitorAnalytics/GetTrafficSourceByPage?profileid=426562393&startdate=2024-09-20&enddate=2025-03-18&pageid=97631489&companyid=12421&isTF=1&getvalues=1&toolid=0&intelconfigid=40101",
    "https://intelligence-api.connectid.dev/VisitorAnalytics/GetTopDomainByTrafficSourcePage?profileid=426562393&startDate=2024-09-20&endDate=2025-03-18&companyid=12421&pageid=97631489&traffic=Organic&packageid=5&sectionid=0&isTF=1&toolid=0&intelconfigid=40101",
    "https://intelligence-api.connectid.dev/WebsiteAnalytics/GetVisitorWithWatchlistByProfileId?profileid=426562393&intellConfigId=40101&startDate=2024-09-20&endDate=2025-03-18&companyid=12421&packageid=5&isTF=1&toolid=0",
    "https://intelligence-api.connectid.dev/VisitorAnalytics/GetPageViewVisitByVisitor?profileid=426562393&startdate=2024-09-20&enddate=2025-03-18&visitorid=653890&countryid=352&isTF=1&toolid=0&intelconfigid=40101",
    "https://intelligence-api.connectid.dev/VisitorAnalytics/GetIntelligencePagesByVisitor?profileid=426562393&startDate=2024-09-20&endDate=2025-03-18&countryid=352&visitorid=653890&isTF=1&toolid=0&intelconfigid=40101",
    "https://intelligence-api.connectid.dev/VisitorAnalytics/GetIntelligenceTrafficSourceByVisitor?profileid=426562393&startdate=2024-09-20&enddate=2025-03-18&companyid=653890&countryid=352&isTF=1&toolid=0&intelconfigid=40101",
    "https://intelligence-api.connectid.dev/VisitorAnalytics/GetIntelligenceTrafficSourceByVisitor?profileid=426562393&startdate=2024-09-20&enddate=2025-03-18&companyid=653890&countryid=352&isTF=1&getvalues=1&toolid=0&intelconfigid=40101",
    "https://intelligence-api.connectid.dev/Analytics/GetInvestisLiveQuetions?clientSlug=investis-digital&presentationId=5f69d4b0d3673f10000a071b&presentationSlug=think-future&skip=0&providerName=InvestisLive&searchString=&clienttype=VE&approvedFor=",
    "https://intelligence-api.connectid.dev/Analytics/GetPollDetail?presentationId=5f69d4b0d3673f10000a071b",
    "https://intelligence-api.connectid.dev/TnfAnalytics/GetTnFWebsites?CompanyId=27181",
    "https://intelligence-api.connectid.dev/TnfAnalytics/GetTnFChannelWebsiteByClientUser?CompanyId=27181",
    "https://intelligence-api.connectid.dev/VisitorAnalytics/GetTopSourceMediumDomainsByTrafficSourceAndVisitor?profileid=426562393&startdate=2024-09-21&enddate=2025-03-19&countryid=352&companyid=653890&traffic=Organic&isTF=1&toolid=0&intelconfigid=40521"
];

