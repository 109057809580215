import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from "react-i18next";
import { Tooltip, Button, OverlayTrigger } from '@themesberg/react-bootstrap';

import { roundNumber } from "../utils/common"
import {
  summaryDetails,
  primarySummaryPositiveOnTitle,
  primarySummaryPercentageOnTitle,
  secoundarySummaryPositiveOnTitle,
  secoundarySummaryPercentageOnTitle,
  summaryFromCubeDetails,
  isEmpty,
  EMPTY_VALUE_MESSAGE_STRING
} from "../AppConfig";

import ChartSummary from '../components/CardSummary';

const getWatchlistDetailsData = (resultSet, pivotConfig, queryArray) => {
  let watchListDatatable = [...resultSet.tablePivot(pivotConfig)];
  let tempFinalWatchlist = [];
  if (watchListDatatable.length === 0) {
    return watchListDatatable.length;
  }
  let visitorids = [];
  let visitorcontryids = [];
  watchListDatatable.forEach((element) => {
    visitorids.push('"' + element["Watchlist.Visitorid"] + '"');
  });
  watchListDatatable.forEach((element) => {
    visitorcontryids.push('"' + element["Watchlist.Visitorcountryid"] + '"');
  });
  return tempFinalWatchlist.length;
};

const renderStaticSummary = (cardSummary) => {
  return (
    <ChartSummary
      summaryPrimary={cardSummary.summaryPrimary}
      summarySecondary={cardSummary.summarySecondary}
      layoutType={cardSummary.layoutType}
      showPercentageForPrimary={cardSummary.showPercentageForPrimary}
      showPositiveOrNegativeForPrimary={cardSummary.showPositiveOrNegativeForPrimary}
      showPercentageForSecoundary={cardSummary.showPercentageForSecoundary}
      showPositiveOrNegativeForSecoundary={cardSummary.showPositiveOrNegativeForSecoundary}
      carousalMainSummary={cardSummary.carousalMainSummary}
    />
  );
}

const TypeToChartComponent = {
  number: ({ resultSet, title, pivotConfig, widjetName, queryArray }) => {
    let visitorsCount = 0;
    if (widjetName === "WatchlistCount") {
      visitorsCount = getWatchlistDetailsData(resultSet, pivotConfig, queryArray);
    }
    var columnName = "Googleanalyticsdata.sessions";
    var isComparisonRequired = false;
    if (title === "Audience trends") {
      columnName = "Googleanalyticsdata.sessions";
      isComparisonRequired = true;
    }
    else if (title === "User") {
      columnName = "Googleanalyticsusersdata.users";
      isComparisonRequired = true;
    }
    else if (title === "Traffic source") {
      columnName = "Googleanalyticsdata.pageviews";
      isComparisonRequired = true;
    }
    else if (title === "Content Insights") {
      columnName = "Googleanalyticsdata.pageviews";
    }

    var allData;
    if (isComparisonRequired === true) {
      allData = resultSet.loadResponse.results.map(s => s.data[0][columnName]);
    }
    else {
      allData = resultSet.seriesNames().map(s => resultSet.totalRow()[s.key]);
    }
    var prevYearData = allData[0] === undefined ? 0 : allData[0];
    var currentYearData = allData.length === 0 ? 0 : (allData[1] === undefined ? resultSet.seriesNames().map(s => resultSet.totalRow()[s.key]) : (allData[1] == null ? 0 : allData[1]));
    const increase = currentYearData - prevYearData;
    var percent = 0;
    if (title === "Cookies") {
      percent = currentYearData;
    }
    else {
      if (prevYearData !== 0 && prevYearData != null) {
        percent = (increase / prevYearData) * 100;
      }
      else {
        percent = currentYearData;
      }
    }
    var summaryDetailsFromCube = 0; //percent;
    percent = roundNumber(percent);
    currentYearData = roundNumber(currentYearData);
    if (isComparisonRequired === true) {
      summaryDetailsFromCube = percent;
    }
    else {
      if (title === "Cookies") {
        summaryDetailsFromCube = "of visitors decilined analytics cookies";
      }
      else {
        summaryDetailsFromCube = resultSet.seriesNames().map(s => resultSet.totalRow()[s.key]);
      }
    }
    const summaryData = summaryDetails.find(data => data.title === title);
    const showPrimaryPositive = primarySummaryPositiveOnTitle.some(data => data === title);
    const showPrimaryPercentge = primarySummaryPercentageOnTitle.some(data => data === title);
    const showSecoundaryPositive = secoundarySummaryPositiveOnTitle.some(data => data === title) && percent > 0;
    const showSecoundaryPercentge = secoundarySummaryPercentageOnTitle.some(data => data === title);
    return (
      <ChartSummary
        summaryPrimary={isEmpty(summaryData.primarySummary) ? currentYearData : summaryData.primarySummary}
        showPercentageForPrimary={showPrimaryPercentge}
        showPositiveOrNegativeForPrimary={showPrimaryPositive}
        summarySecondary={isEmpty(summaryData.secondarySummary) ? ((Array.isArray(summaryDetailsFromCube) && summaryDetailsFromCube.length > 1) ? summaryDetailsFromCube[1] : (summaryDetailsFromCube[0] === undefined ? summaryDetailsFromCube : summaryDetailsFromCube[0])) : summaryData.secondarySummary}
        showPercentageForSecoundary={showSecoundaryPercentge}
        showPositiveOrNegativeForSecoundary={showSecoundaryPositive}
        layoutType={summaryData.layoutType}
        carousalMainSummary={(Array.isArray(summaryDetailsFromCube) && summaryDetailsFromCube.length > 0 && summaryDetailsFromCube[2]) ? summaryDetailsFromCube[2] : EMPTY_VALUE_MESSAGE_STRING}
      />
    );
  },
  table: ({ resultSet, title, pivotConfig, widjetName, queryArray }) => {
    let visitorsCount = 0;
    if (widjetName === "WatchlistCount") {
      if (resultSet !== null && resultSet !== undefined && resultSet.loadResponses[0].data.length > 0 && resultSet.loadResponses[0].data[0]["Watchlist.Watchlistid"] !== undefined) {
        visitorsCount = getWatchlistDetailsData(resultSet, pivotConfig, queryArray);

      }
    }

    var summaryDetailsFromCube = 0; //percent;
    var currentYearData = roundNumber(visitorsCount);
    summaryDetailsFromCube = resultSet.seriesNames().map(s => resultSet.totalRow()[s.key]);
    const summaryData = summaryDetails.find(data => data.title === title);

    return (
      <ChartSummary
        summaryPrimary={isEmpty(summaryData.primarySummary) ? currentYearData : summaryData.primarySummary}
        showPercentageForPrimary={false}
        showPositiveOrNegativeForPrimary={false}
        summarySecondary={isEmpty(summaryData.secondarySummary) ? ((Array.isArray(summaryDetailsFromCube) && summaryDetailsFromCube.length > 1) ? summaryDetailsFromCube[1] : (summaryDetailsFromCube[0] === undefined ? summaryDetailsFromCube : summaryDetailsFromCube[0])) : summaryData.secondarySummary}
        showPercentageForSecoundary={false}
        showPositiveOrNegativeForSecoundary={false}
        layoutType={summaryData.layoutType}
        carousalMainSummary={(Array.isArray(summaryDetailsFromCube) && summaryDetailsFromCube.length > 0 && summaryDetailsFromCube[2]) ? summaryDetailsFromCube[2] : EMPTY_VALUE_MESSAGE_STRING}
      />
    );
  }
};

const createTooltip = (t, tooltipKey) => {
  if (tooltipKey !== "") {
    return (
      <OverlayTrigger
        placement="right"
        trigger={["hover", "focus"]}
        overlay={
          <Tooltip>
            <div
              dangerouslySetInnerHTML={{
                __html: t(tooltipKey, {
                  interpolation: { escapeValue: false },
                }),
              }}
            ></div>
          </Tooltip>
        }
      >
        <Button variant="white" className="btn-help border-0 p-2">
          <i className="far fa-question-circle lg"></i>
        </Button>
      </OverlayTrigger>
    );
  }
}

const TypeToMemoChartComponent = Object.keys(TypeToChartComponent)
  .map((key) => ({
    [key]: React.memo(TypeToChartComponent[key]),
  }))
  .reduce((a, b) => ({ ...a, ...b }));


const renderChart = (Component) => ({ resultSet, title, legends, pivotConfig, widjetName, queryArray, summaryTooltip }) => {
  const [t, i18n] = useTranslation();
  return ((resultSet && (
    <><Component resultSet={resultSet} title={title} legends={legends} pivotConfig={pivotConfig} widjetName={widjetName} queryArray={queryArray} /> {createTooltip(t, summaryTooltip)}</>
  ))
  );
};

const ChartRenderer = ({ vizState, title, apiUrl, numberOfRecords, summaryTooltip }) => {
  const { query, chartType, pivotConfig, widjetName } = vizState;
  let queryArray = [];
  if (Array.isArray(query)) {
    queryArray = query;
  } else {
    queryArray.push(query);
  }
  const shouldFetchSummaryFromCube = summaryFromCubeDetails.some(data => data === title);
  if (shouldFetchSummaryFromCube) {
    const component = TypeToMemoChartComponent[chartType];
    if (apiUrl !== undefined && (numberOfRecords !== "" || numberOfRecords !== undefined)) {
      return component && renderChart(component)({ ...title, apiUrl, numberOfRecords, pivotConfig, widjetName, queryArray, summaryTooltip });
    } else {
      return component && renderChart(component)({ ...title, apiUrl, numberOfRecords, pivotConfig, widjetName, queryArray, summaryTooltip });
    }
  }
  else {
    return renderStaticSummary(vizState);
  }
};

ChartRenderer.propTypes = {
  vizState: PropTypes.object,
  title: PropTypes.string,
  summaryTooltip: PropTypes.string,
  numberOfRecords: PropTypes.number,
};

ChartRenderer.defaultProps = {
  vizState: {},
  title: '',
  summaryTooltip: '',
  numberOfRecords: 0

};
export default ChartRenderer;