import React, { useState, useEffect, useContext, useMemo } from 'react';
import RGL, { WidthProvider } from 'react-grid-layout';
import { useTranslation } from "react-i18next";
import { Row, Col, Image } from '@themesberg/react-bootstrap';

import request from '../apis/request';
import { baseURL, METHODS } from '../apis/utilities/constant';
import LoaderImage from "../assets/img/LoaderGraphic.svg";
import { decryptData } from '../utils/crypto';

import CommonHeader from '../components/CommonHeader';
import AppContext from '../components/AppContext';

const ReactGridLayout = WidthProvider(RGL);

const ArchiveAlerts = ({ children, setSelectedWebsiteId, setSelectedOtpclientid }) => {

  const [t, i18n] = useTranslation();
  const contextData = useContext(AppContext);

  const [selectedWebsiteIdNew, setSelectedWebsiteIdNew] = useState("");
  const [autoLoad, setAutoLoad] = useState(false);
  const [blurLoader, setBlurLoader] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("IsPageRefresh") == "1") {
      setSelectedWebsiteIdNew(JSON.parse(localStorage.getItem("alertWebsiteId")));
      setSelectedOtpclientid(JSON.parse(localStorage.getItem("alertWebsiteId")));
    }
  }, []);

  useEffect(() => {
    getWebsites();
  }, [contextData.userId, contextData.companyId]);

  const onWebsiteDropdownChange = (data) => {
    setSelectedWebsiteIdNew({});
    setSelectedWebsiteIdNew(data)
  };

  const applyFilters = () => {
    setBlurLoader(false);
    const websiteId = selectedWebsiteIdNew["WebsiteList.WebsiteId"];
    setSelectedWebsiteId(websiteId);
    contextData.changeWebsiteId(websiteId);
    setSelectedWebsiteId(websiteId);
    setSelectedOtpclientid(selectedWebsiteIdNew);
    localStorage.setItem("alertWebsiteId", JSON.stringify(selectedWebsiteIdNew)); // Adding ArchiveAlertWebsiteId to local stoarge in case of Page Refresh
  }

  const cancelFilters = () => {
    onWebsiteDropdownChange(JSON.parse(localStorage.getItem("alertWebsiteId")))
  }

  const getWebsites = () => {
    setBlurLoader(true);
    request(baseURL + `/Intelligence/GetAlertsChannelWebsiteByClientUser?CompanyId=${contextData.companyId}&UserId=${contextData.userId}`,
      METHODS.GET,
      {},
      {
        Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
      })
      .then((res) => {
        let resultData = [];
        if (res.result !== null && res.result.length) {
          resultData = res.result.map((e) => ({ ["WebsiteList.WebsiteName"]: e.WebsiteName, ["WebsiteList.WebsiteId"]: e.WebsiteId, ["WebsiteList.WebsiteUrl"]: e.WebsiteUrl, ["ClietConfiguration.otpclientid"]: e.OTPClientId, ["WebsiteList.AlertTrackingDate"]: e.AlertTrackingDate }))
        }
        rendersite({ resultSet: resultData })
      })
      .catch((err) => {

      })
  }

  const rendersite = ({ resultSet, error, pivotConfig, request }) => {
    if (resultSet !== undefined && resultSet.length) {
      if (selectedWebsiteIdNew !== undefined && Object.keys(selectedWebsiteIdNew).length <= 0) {
        if (localStorage.getItem("alertWebsiteId")) {
          onWebsiteDropdownChange(JSON.parse(localStorage.getItem("alertWebsiteId")))
        } else {
          onWebsiteDropdownChange(resultSet[0])
        }
      }
      localStorage.setItem("archivedAlertsData", JSON.stringify(resultSet))
      setAutoLoad(true)
    }
  };

  const commonHeader = useMemo(() => <CommonHeader cancelFilters={cancelFilters} channel="alert" autoLoad={autoLoad} dataSource={[
    { name: 'Dropdown1 - website', data: JSON.parse(localStorage.getItem("archivedAlertsData")), selectedItem: selectedWebsiteIdNew, isVisible: true, label: t("header.alertSummaryFiltersLabelWebsite"), callback: onWebsiteDropdownChange, displayText: "WebsiteList.WebsiteName" },
  ]} title={t("header.archivedAlertTitle")} showDate={false} applyFilters={applyFilters} />, [selectedWebsiteIdNew, autoLoad]);

  return (
    <>
      {blurLoader && <div id='blurdiv' className="blurbackground">
        <div className={`preloader loader-center-align`}>
          <Image className="loader-element animate__animated animate__jackInTheBox" src={LoaderImage} height={40} data-seconds={30} />
        </div>
      </div>}
      <Row>
        <Col sm={12}>
          {commonHeader}
        </Col>
      </Row>
      <Row className="ps-3">
        <Col sm={8}>
        </Col>
        <Col sm={4} className="mb-2">
        </Col>
      </Row>

      {/* Change css when react grid item order will be changed or rearranged */}
      <Row>
        <Col xs={12}>
          <ReactGridLayout cols={12} rowHeight={50} isDraggable={false} isResizable={false} className="rgl-archive-alerts">
            {children}
          </ReactGridLayout>
        </Col>
      </Row>
    </>
  );
};

export default ArchiveAlerts;
