
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import '../i18n';
import { Image } from '@themesberg/react-bootstrap';

import LoaderImage from "../assets/img/LoaderGraphic.svg";

const VoltLoader = (props) => {
  const [t, i18n] = useTranslation();

  const { show } = props;
  const [seconds, setSeconds] = useState(30);

  useEffect(() => {
    if (seconds > 0) {
      setTimeout(() => setSeconds(seconds - 1), 1000);
    } else {
      setSeconds(0);
    }
  });

  if (show) {
    return seconds != 0 ? (
      <div className={`preloader loader-center-align  ${show ? "" : "show"} ${props.className ? props.className : ""}`}>
        <Image className="loader-element animate__animated animate__jackInTheBox" src={LoaderImage} height={40} data-seconds={seconds} />
      </div>
    )
      :
      (
        <div className="no-data w-100">{t("header.voltLoaderMessageText")}</div>
      );
  }
  else {
    return (
      <></>
    );
  }

};

export default VoltLoader; 
