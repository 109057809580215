import React from 'react';
import { HashLink } from 'react-router-hash-link';
import { Nav, Navbar, Container, Image } from '@themesberg/react-bootstrap';

import ConnectIDLogo from "../assets/img/ConnectIDIntelligence.svg";

import HeaderDropdowns from "../components/HeaderDropdowns";
import IdleTimerContainer from './IdleTimerContainer';

const Header = (props) => {

  return (
    <Navbar expanded id="mainnav">
      <Container fluid className="px-0">
        <div className="d-flex justify-content-between w-100">
          <Navbar.Brand as={HashLink} to="/website" className="me-lg-3 d-flex align-items-center" id="logo">
            <Image src={ConnectIDLogo} className="logo-avtar large-avatar" />
          </Navbar.Brand>
          <Nav className="align-items-center">
            <HeaderDropdowns setValid={props.setValid} />
          </Nav>
        </div>
        <IdleTimerContainer></IdleTimerContainer> {/* IdleTimerContainer is for Session time out Popup*/}
      </Container>
    </Navbar>
  );
};

export default Header;
