import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import PropTypes from "prop-types";
import { isMobile } from "react-device-detect";
import { Col, Form, Image, Row, Tooltip, OverlayTrigger, Button } from "@themesberg/react-bootstrap";

import LoaderImage from "../assets/img/LoaderGraphic.svg";
import { pageValuesByKey } from "../utils/constant";
import { formatCount } from "../AppConfig";
import { convertTranslatinString, getWebsiteURL } from "../utils/common";

import AppContext from "../components/AppContext";
import ChartsComponent from "../components/Charts";

const ApiChartRenderer = (props) => {

    let {
        vizState,
        cardProperty,
        legends,
        setd3,
        cardName,
        chartType,
        itemId,
        services,
        isSummeryShown,
        year,
        summaryBesideText,
        selectedCategoryId,
        tooltipText,
        name,
        barChartClickEvent,
        pageId,
        cardDetails,
        visitorid,
        countryid,
        showOthers,
        showOthersColume,
        showOthersColumeName,
        legendPercentage,
        loadedCardId,
        setAlertVisibleMessage,
        alertMessageId,
        showLoader,
    } = props;

    const contextData = useContext(AppContext);
    const [t, i18n] = useTranslation();
    const [summaryBesideTextValue, setSummaryBesideTextValue] = React.useState("");
    const [apiRes, setApiRes] = React.useState(undefined);
    const [summeryData, setSummeryData] = React.useState([]);
    const [noDataContent, setNoDataContent] = React.useState(false);
    const [isLoaderShown, setIsLoaderShown] = React.useState(true);
    const [apiMessage, setApiMessage] = React.useState("");
    const [tooltipelementremoval, setToolTipElementRemoval] = React.useState([]);
    const [cookieCardTitle, setCookieCardTitle] = useState(undefined);

    const [showChart, setShowChart] = useState(undefined);
    const [showTogglesForCookies, setShowTogglesForCookies] = useState(true)

    const { widjetName, pivotConfig, tableCaption } = vizState;

    const renderProps = props.data;
    var show = false;

    if (isMobile && chartType === "pie") {
        cardProperty.h = 15
    }

    useEffect(() => {
        if (!isLoaderShown) {
            setSummaryBesideTextValue(summaryBesideText)
        }
        if (!isLoaderShown) {
            setNoDataContent(true);
        }
        else {
            setNoDataContent(false);
        }
    }, [isLoaderShown])

    useEffect(() => {
        setShowChart(true)
        setApiRes(undefined);
        setIsLoaderShown(true);
        localStorage.setItem("loaderId" + itemId, 0)
        setApiMessage("")
        let fn = services.find(x => x.id == itemId);
        let profile = JSON.parse(localStorage.getItem("cookieProfileData"));
        let websiteUrl = undefined;
        if (profile) {
            let website = getWebsiteURL(profile);
            websiteUrl = website?.WebsiteUrl;
        }
        let payload = {
            id: itemId,
            startDate: moment(contextData.startDate).format("YYYY-MM-DD"),
            endDate: moment(contextData.endDate).format("YYYY-MM-DD"),
            profileId: contextData.selectedProfile["WebsiteProfiles.ProfileId"],
            websiteId: contextData.websiteId,
            userId: contextData.userId,
            companyId: contextData.companyId,
            presentationId: contextData.presentationId,
            companyId: contextData.companyId,
            clientSlug: contextData.presentation["Presentations.clientslug"],
            presentationSlug: contextData.presentation["Presentations.slug"],
            year,
            selectedCategoryId: selectedCategoryId !== undefined ? selectedCategoryId.sectionId : 0,
            pageId: pageId,
            websiteUrl: websiteUrl,
            visitorid: visitorid,
            countryid: countryid,
            alertCategoryId: contextData.alertCategoryId?.CategoryId ? contextData.alertCategoryId?.CategoryId : 0,
            alertCategoryName: contextData.alertCategoryId?.CategoryName === undefined || contextData.alertCategoryId?.CategoryName === "All Categories" ? "" : contextData.alertCategoryId?.CategoryName === "Adhoc" ? "adhoc" : "alert",
            alertMessageId: alertMessageId,
            culture: localStorage.getItem('i18nextLng') ? localStorage.getItem('i18nextLng') : 'en-US'
        }
        fn.service(contextData.cryptoKey, payload, (res, id) => {
            //this block will handle card on website analytics page(audience trends, traffic source, user)
            if (res?.currentYear?.count == 0 && res?.previousYear?.count == 0) {
                setShowChart(false)
            }
            setApiMessage("")
            setIsLoaderShown(false);

            if (loadedCardId) {
                if (chartType === "line" || chartType === "bar") {
                    if (res.currentYear.count || res.previousYear.count) {
                        loadedCardId(parseInt(itemId))
                    }
                } else if (chartType === "pie") {
                    if (res && res.length) {
                        loadedCardId(parseInt(itemId))
                    }
                } else {
                    loadedCardId(0)
                }
            }
            if (id) {
                if (res !== undefined) {
                    if (res.message !== undefined) {
                        setApiMessage(res.message);
                    }
                }
                if (chartType == "multiline") {
                    setToolTipElementRemoval([])
                    //Below written condition is for showing content unavailable text when respective count is 0 in multiline charts.
                    if ((props.cardName === "visitorCompanies" || props.cardName === "PageviewsandUsers" || props.cardDetails === "Cookies") && (res?.pageviewsCount > 0 || res?.usersCount > 0 || res?.pageViewscount > 0 || res?.visitorsCount > 0 || res?.acceptAllCount > 0 || res?.customPrefCount > 0 || res?.disableAllCount > 0 || res?.screenPageViewsCount > 0 || res?.totalUsersCount > 0)) {
                        hideLinesInGrap(parseData(res));
                        setShowTogglesForCookies(true);
                    } else if (props.cardName === "alertAnalyticsMulitline") {
                        hideLinesInGrap(parseData(res));
                    } else {
                        hideLinesInGrap(undefined);
                        setShowTogglesForCookies(false);
                    }
                }
                else if (chartType == "pie") {
                    setCookieCardTitle("");
                    //below given condition is added so that, if there are less than 4 records, in that case remaining country/domains legend is not required for pie chart *** for alert analytics page
                    if (showOthers && res?.length > 4) { // for alert analytics page country, domains card
                        let dataObject = [];
                        let addingTooltipToDataObject = (res && res.length > 0) ? [...res] : [];
                        if (addingTooltipToDataObject.length > 0) {
                            let othersCount = 0;
                            addingTooltipToDataObject.forEach((item, index) => {
                                if (index < 4) {
                                    dataObject.push(item);
                                } else {
                                    othersCount += item[showOthersColume]
                                }
                            });
                            let othersObject = { ...dataObject[0] };
                            if (showOthersColumeName !== undefined) {
                                othersObject["title"] = t("header." + showOthersColumeName)
                            }
                            othersObject[showOthersColume] = othersCount
                            othersObject["TotalClickCount"] = 0
                            dataObject.push(othersObject);
                        }

                        setApiRes(addingTooltipToData(dataObject));
                    } else {
                        setApiRes(addingTooltipToData(res));
                    }
                } else if (chartType == "line2") { //line2 = linechart
                    if (res?.count > 0) {
                        setApiRes(res);
                    }
                }
                else if (chartType == "bar2") { //bar2 = barchart
                    if (res.message) {
                        setShowChart(false)
                    }
                    setApiRes(res);
                }
                else {
                    if ((chartType == "bar" || chartType == "line")) { // line & bar = single line and single bar chart converted to multiline and multibar (website analytics)
                        setApiRes(res);
                    } else if (chartType == "map") {
                        //this includes map
                        setApiRes(res);
                    }
                }
            }
            else {
                setApiRes(undefined);
                setIsLoaderShown(false);
            }
        })
    }, [selectedCategoryId, year, contextData.selectedProfile["WebsiteProfiles.ProfileId"], contextData.startDate, contextData.endDate, contextData.presentationId, contextData.websiteId, contextData.alertCategoryId?.CategoryId])

    const parseData = (res) => {
        let data = [];
        let sData = [];
        let type = res?.type;
        res && Object.keys(res).map((item, i) => {
            if (typeof res[item] == 'object') {
                let s = {
                    type: type,
                    key: item,
                    values: [],
                    copyValues: [],
                    show: 1
                }
                res[item].map((i) => {
                    s.values.push({ "value": i.Count, "category": i.ProcessDate, "xvalue": i.ProcessDate, "x": i.ProcessDate })
                })

                data.push(s);
            }
            else {
                let formatSummery = {
                    "shortTitle": item,
                    "value": res[item],
                }

                typeof res[item] != "string" && sData.push(formatSummery);
            }
        })
        setSummeryData(sData);
        return data;
    }

    const addingTooltipToData = (data) => {
        let newResult = [];
        let itemToSum = Array.isArray(data) ? data.map(x => x.TotalOpenCount) : [];
        let total = itemToSum.reduce((a, b) => a + b, 0)

        data && Array.isArray(data) && data.map((item) => {
            let toolTip = item?.ToolTipTitle ? item?.ToolTipTitle : "";
            let obj = {
                ...item,
                toolTip: Math.round(item.TotalOpenCount * 100 / total) + "% " + toolTip
            }

            if (cardDetails == "Cookies" && obj.title == "Accepted all") {
                setCookieCardTitle(obj.toolTip);
            }

            newResult.push(obj);
        })
        return newResult;
    }

    const hideLinesInGrap = (data) => {
        let apiDataUpdated = [];
        let array = [];
        setToolTipElementRemoval([]);
        if (data?.length > 0) {
            if (chartType === "multiline") {
                data.map((e) => {
                    if (e?.key) {
                        pageValuesByKey.find((element) => {
                            if (e.key === element.key) {

                                if (element.defaultCheck === 1) {
                                    e.show = 1
                                    apiDataUpdated.push(e);
                                } else {
                                    e.show = 0
                                    apiDataUpdated.push(e);
                                    array.push(element.key)
                                }
                            }
                        }
                        )
                    }
                })
            }
        }
        setApiRes(apiDataUpdated);
        setToolTipElementRemoval(array);
    }

    const onCheckBoxChange = (e, legend) => {
        let updatedData = [];
        let labels = [...tooltipelementremoval]
        let data = [...apiRes]
        let toggleState = e.target.checked;
        if (data?.length > 0) {
            data.map((e) => {
                if (e.key === legend) {
                    if (toggleState) {
                        e.show = 1
                        let index = labels.findIndex((e) => e === legend)
                        if (index > (-1)) {
                            labels.splice(index, 1)
                        }

                    } else {
                        e.show = 0
                        labels.push(legend);
                    }
                }
                e.isDisable = false;
                if (data?.length == 3) {
                    if (data.filter(x => x.show === 1).length === 2) {
                        data.find(x => x.show === 1).isDisable = true;
                        setAlertVisibleMessage(t("header.LastPendingToggleMessage", "At least one option needs to be enabled."));
                        setTimeout(() => { setAlertVisibleMessage(null) }, 3000)
                    } else {
                        data.find(x => x.show === 1).isDisable = false;
                        setAlertVisibleMessage(null)
                    }
                } else {
                    if (data.filter(x => x.show === 1).length === 1) {
                        data.find(x => x.show === 1).isDisable = true;
                        setAlertVisibleMessage(t("header.LastPendingToggleMessage", "At least one option needs to be enabled."));
                        setTimeout(() => { setAlertVisibleMessage(null) }, 3000)
                    } else {
                        data.find(x => x.show === 1).isDisable = false;
                        setAlertVisibleMessage(null)
                    }
                }
                updatedData.push(e);
            })
        }
        setToolTipElementRemoval(labels)
        setApiRes(updatedData)
    };

    const TypeToChartComponent = {
        line: (props) => {
            return <ChartsComponent type="line" {...props} />;
        },
        line2: (props) => {
            return <ChartsComponent type="line2" {...props} />;
        },
        bar: (props) => {
            return <ChartsComponent type="bar" barChartClickEvent={barChartClickEvent} {...props} />;
        },
        bar2: (props) => {
            return <ChartsComponent type="bar2" barChartClickEvent={barChartClickEvent} {...props} />;
        },
        pie: (props) => {
            return <ChartsComponent type="pie" legendPercentage={legendPercentage} {...props} />;
        },
        multiline: (props) => {
            return <ChartsComponent type="multiline" datafortooltip={tooltipelementremoval} {...props} />;
        },
        map: (props) => {
            return <ChartsComponent type="map" datafortooltip={tooltipelementremoval} {...props} />;
        },
    };

    const TypeToMemoChartComponent = Object.keys(TypeToChartComponent)
        .map((key) => ({
            [key]: React.memo(TypeToChartComponent[key]),
        }))
        .reduce((a, b) => ({ ...a, ...b }));

    const createTooltip = (t, tooltipKey, placement) => {
        if (tooltipKey !== "") {
            return (
                <OverlayTrigger
                    placement={placement ? placement : "right"}
                    trigger={["hover", "focus"]}
                    overlay={
                        <Tooltip>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: t(tooltipKey, {
                                        interpolation: { escapeValue: false },
                                    }),
                                }}
                            ></div>
                        </Tooltip>
                    }
                >
                    <Button variant="white" className="btn-help border-0 p-1">
                        <i className="far fa-question-circle lg"></i>
                    </Button>
                </OverlayTrigger>
            );
        }
    }

    const component = TypeToMemoChartComponent[chartType];

    const renderChart =
        (Component) =>
            ({
                cardProperty,
                legends,
                pivotConfig,
                widjetName,
                tableCaption,
            }) => {
                return (
                    <>
                        {apiRes && showChart ? (
                            <>
                                {cardDetails == "Cookies" && cookieCardTitle ?
                                    <>
                                        <div className="card-merices-container">
                                            <div className="mt-1 mb-2 card-merices">
                                                {isSummeryShown && <span className="metrics">{cookieCardTitle}</span>} {summaryBesideTextValue}
                                                <span className="metrics-positive text-success ms-2"></span>
                                            </div>
                                        </div>
                                    </> :
                                    <>
                                        {apiRes?.currentYear?.count > 0 &&
                                            <div className="card-merices-container">
                                                <div className="mt-1 mb-2 card-merices">
                                                    {isSummeryShown && <span className="metrics">{apiRes.count ? formatCount(apiRes.count) : apiRes.currentYear?.count ? formatCount(apiRes.currentYear?.count) : ""}</span>}
                                                    <span className={`metrics-positive ${apiRes.previousYear?.count === 0 || ((apiRes.currentYear?.count - apiRes.previousYear?.count) / apiRes.previousYear?.count) >= 0 ? 'text-success' : 'text-danger'} ms-2`}>
                                                        {apiRes && apiRes.previousYear?.count !== undefined ? (
                                                            apiRes.previousYear?.count === 0
                                                                ? "+100"
                                                                : `${((apiRes.currentYear?.count - apiRes.previousYear?.count) / apiRes.previousYear?.count * 100).toFixed(2)}%`
                                                        ) : ""}
                                                    </span>
                                                    {summaryBesideTextValue}
                                                    <span className="metrics-positive text-success ms-2"></span>
                                                </div>
                                            </div>}
                                    </>
                                }
                                {(apiRes?.length > 0 || apiRes?.values?.length > 0 || apiRes?.count > 0 || (apiRes?.currentYear || apiRes?.previousYear)) &&
                                    <Component
                                        data={apiRes}
                                        resultSet={apiRes}
                                        isResFromAPI={true}
                                        cardProperty={cardProperty}
                                        legends={legends}
                                        pivotConfig={pivotConfig}
                                        widjetName={widjetName}
                                        tableCaption={tableCaption}
                                    />}
                                {apiRes?.length > 0 && name && <div className="row"><div className="col-12"><h5 className="mt-4"><div style={{ textAlign: "center" }}>  {t("header.title_" + convertTranslatinString(name), name)}
                                    {tooltipText && createTooltip(t, tooltipText)}
                                </div></h5></div></div>}
                                {isSummeryShown && chartType == "multiline" && cardDetails == "Cookies" && showTogglesForCookies &&
                                    <Row className={cardDetails == "Cookies" ? "cookies-additional" : ""}>
                                        {
                                            summeryData.map((summery, index) => (
                                                <Col sm={4} lg={4} className={(index == 0 || index == 1) ? "br-dddbdb mb-3" : "mb-3"}>
                                                    <div className="switch-toggle-component">
                                                        <div className="d-inline-block switch-toggle-component-item">
                                                            <div className="toggle-label-container">
                                                                <label className={'widget-summary-metrics-label d-inline-block label-' + (index + 1)}>
                                                                    {t("header.legend_" + convertTranslatinString(pageValuesByKey.find(x => x?.key == apiRes[index]?.key)?.value, pageValuesByKey.find(x => x?.key == apiRes[index]?.key)?.value))}
                                                                </label>
                                                            </div>
                                                            <div className={"widget-summary-metrics metrics-" + (index + 1)}>{summery.value ? formatCount(summery.value) : 0}</div>
                                                        </div>
                                                    </div>
                                                </Col>
                                            ))
                                        }
                                    </Row>
                                }
                                {isSummeryShown && chartType == "multiline" && cardDetails != "Cookies" &&
                                    <div className="switch-toggle-component w-100">
                                        {apiRes?.length > 0 &&
                                            summeryData.map((summery, index) => (
                                                <div className="d-inline-block switch-toggle-component-item">
                                                    <div className="metrics-label-container">
                                                        <label className={'widget-summary-metrics-label d-inline-block label-' + (index + 1)}>{t("header.legend_" + convertTranslatinString(pageValuesByKey.find(x => x?.key == apiRes[index]?.key)?.value, pageValuesByKey.find(x => x?.key == apiRes[index]?.key)?.value))}</label>
                                                        {pageValuesByKey.find(x => x?.key == apiRes[index]?.key)?.tooltip &&
                                                            <span>
                                                                {createTooltip(t, pageValuesByKey.find(x => x?.key == apiRes[index]?.key)?.tooltip, index == 0 ? "right" : index == 6 ? "left" : "top")}
                                                            </span>
                                                        }
                                                        <div className={'widget-summary-metrics metrics-' + (index + 1)}>{formatCount(summery.value) ?? 0}</div>
                                                    </div>
                                                    <div className="toggle-switch-container">
                                                        {pageValuesByKey.find(x => x?.key == apiRes[index]?.key)?.IsSwitch !== 0 && <Form className="d-inline-block ms-2">
                                                            <Form.Check type="switch"
                                                                className={apiRes[index]?.show === 1 ? "switch-checked" : "switch-uncheck"}
                                                                defaultChecked={pageValuesByKey.find(x => x?.key == apiRes[index]?.key)?.defaultCheck === 1 ? true : false}
                                                                onChange={(e, title) => onCheckBoxChange(e, apiRes[index].key)}
                                                                label=""
                                                                id="switch1"
                                                                htmlFor="switch1"
                                                                disabled={apiRes[index]?.isDisable}
                                                            />
                                                        </Form>}
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                }
                            </>
                        ) : (
                            showLoader == false ? (<></>) : (<div className={`preloader loader-center-align alert-analytics ${isLoaderShown ? "" : "show fff"}`}>
                                <Image className="loader-element animate__animated animate__jackInTheBox" src={LoaderImage} height={40} data-seconds={30} />
                            </div>))
                        }
                        {
                            (props.cardDetails && props.cardDetails === "Cookies") ?
                                noDataContent && (!apiRes || apiRes.length <= 0 || !showChart) && <div className="text-center no-data">{t("header.NoDataFound_Cookies")}</div> :
                                noDataContent && (!apiRes || apiRes.length <= 0 || !showChart) && <div className="text-center no-data">{apiMessage != "" ? t("header." + apiMessage) : t("header.NoDataFound")}</div>
                        }
                    </>
                );
            };

    return (
        component &&
        renderChart(component)({
            itemId,
            ...renderProps,
            cardProperty,
            legends,
            pivotConfig,
            show,
            widjetName,
            setd3,
            tableCaption,
            cardName,
        })
    );
};

ApiChartRenderer.defaultProps = {
    vizState: {},
    cardProperty: {},
    legends: {},
    summaryBesideText: "",
    loadedCardId: 0
}

ApiChartRenderer.propTypes = {
    vizState: PropTypes.object,
    cardProperty: PropTypes.object,
    legends: PropTypes.object,
    summaryBesideText: PropTypes.func,
    loadedCardId: PropTypes.number
};

export default ApiChartRenderer;