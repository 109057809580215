import axios from "axios";
import React, { useState } from "react";
import { baseURL } from "../apis/utilities/constant";
import { decryptData } from "../utils/crypto";
import { useTranslation } from "react-i18next";


const Feedback = ({ onClose, setMessage, cryptoKey }) => {

    const [t, i18n] = useTranslation();

    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [file, setFile] = useState(null);


    const [fieldValidationMessage, setFieldValidationMessage] = useState({ title: undefined, description: undefined });
    const [fileValidationMessage, setFileValidationMessage] = useState({ fileSize: undefined, fileType: undefined });
    const [isFormSubmitting, setIsFormSubmitting] = useState(false)

    const allowedTypes = ['image/jpeg', 'image/png', 'application/msword','application/vnd.openxmlformats-officedocument.wordprocessingml.document']

    const handleFileChange = (e) => {
        setIsFormSubmitting(false)
        const uploadedFile = e.target.files[0];

        if (uploadedFile) {
            const maxSizeInMB = 10; // maximum allowed size in MB
            const maxSizeInBytes = maxSizeInMB * 1024 * 1024; // Convert MB to bytes

            const validationErrors = { fileSize: null, fileType: null };

            // Check file size
            if (uploadedFile.size > maxSizeInBytes) {
                validationErrors.fileSize = `File size exceeds ${maxSizeInMB}MB.`;
                setIsFormSubmitting(true)
            }

            // Check file type
            if (!allowedTypes.includes(uploadedFile.type)) {
                validationErrors.fileType = 'Invalid file type. Valid formats: Image and word.';
                setIsFormSubmitting(true)
            }

            setFileValidationMessage(validationErrors);

            // If no errors, set the file
            if (!validationErrors.fileSize && !validationErrors.fileType) {
                setFile(uploadedFile);
            }

        }
        else {
            setFileValidationMessage({ fileSize: null, fileType: null });
        }
    };

    const validateFields = () => {
        const errors = {};
        if (!title || title.trim() === "") {
            errors.title = "Title is required";
        }
        if (!description || description.trim() === "") {
            errors.description = "Description is required";
        }
        return Object.keys(errors).length > 0 ? errors : null; // Return errors or null if no validation errors
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const validationMessages = validateFields();
        setFieldValidationMessage(validationMessages || {});

        if (validationMessages) {
            return;
        }


        const formData = new FormData();

        // Append form fields to FormData
        formData.append('title', title);
        formData.append('description', description);
        if (file) {
            formData.append('file', file);  // Append the file as well
        }
        try {
            setIsFormSubmitting(true)
            const response = await axios.post(baseURL + '/CommonV2/SendFeedback', formData, {
                headers: {
                    Authorization: `Bearer ${decryptData(localStorage.getItem("token"), cryptoKey)}`,
                    'Content-Type': 'multipart/form-data',
                },
            });
            if (response.data) {
                if (response.data.status === "Success") {
                    setMessage({ status: "Success", message: response.data.message })
                    onClose();
                } else {
                    setMessage({ status: "Failed", message: response.data.message })
                }
            }
        } catch (error) {
            setMessage({ status: "Failed", message: "Something went wrong!" })
        }
    };

    return (
        <>
            <div className="modal-content-feedback">
                <div className="modal-overlay ">
                    <div className="modal-content-feedback">
                        <div className="modal-header">
                            <h2 class="h5 modal-title h4">{t("header.FeedbackFormTitle")}</h2>
                            <button aria-label="Close" type="button" class="btn btn-close" onClick={onClose}>
                            </button>
                        </div>
                        <form onSubmit={handleSubmit} className="feedback-form">
                            <div className="form-group">
                                <label className="form-label">{t("header.FeedbackInputLabelTitle")} <span className="mandatory">*</span></label>
                                <input
                                    type="text "
                                    value={title}
                                    onChange={(e) => setTitle(e.target.value)}
                                    placeholder={t("header.FeedbackInputPlaceholderTitle")}
                                    className="form-control"
                                />

                                {fieldValidationMessage.title && (
                                    <div className="invalid-feedback d-block">
                                        {fieldValidationMessage.title}
                                    </div>
                                )}
                            </div>

                            <div className="form-group">
                                <label className="form-label">{t("header.FeedbackInputLabelDescription")} <span className="mandatory">*</span></label>
                                <textarea
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                    placeholder={t("header.FeedbackInputPlaceholderDescription")}
                                    rows="4"
                                    className="form-control"
                                />

                                {fieldValidationMessage.description && (
                                    <div className="invalid-feedback d-block">
                                        {fieldValidationMessage.description}
                                    </div>
                                )}
                            </div>

                            <div className="form-group">
                                <label className="form-label">{t("header.FeedbackInputLableUploadFile")}</label>
                                <input
                                    type="file"
                                    onChange={handleFileChange}
                                    className="form-control"
                                />

                                {(fileValidationMessage.fileSize || fileValidationMessage.fileType) && (
                                    <div className="invalid-feedback d-block">
                                        {fileValidationMessage.fileSize || fileValidationMessage.fileType}
                                    </div>
                                )}
                            </div>

                            <button disabled={isFormSubmitting} type="submit" className="mb-2 btn btn-primary btn-md mt-1">{t("header.FeedbackSubmitButton")}</button>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Feedback;
