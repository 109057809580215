import React, { useEffect, useState, useContext, useMemo } from 'react';
import { isMobile } from "react-device-detect";
import DashboardItem from '../components/DashboardItem';
import { getNotifications } from "../stubs/ComponentJson/Visitoranalytics";
import NotificationNew from '../components/NotificationNew';
import ChartRendererTable from '../components/ChartRendererTable'
import { Tooltip, Button, OverlayTrigger, Col, Row, FormCheck, Form, InputGroup, Card, Alert, Popover, Image } from "@themesberg/react-bootstrap";
import { useTranslation } from "react-i18next";
import Search from "../components/Search";
import ModalComponent from "../components/VoltModal";
import request from "../apis/request";
import { baseURL, METHODS } from "../apis/utilities/constant";
import Datetime from "react-datetime";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt, faPlusCircle, faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { isEmpty } from "../AppConfig";
import DropdownWithIconsComponent from "../components/DropdownWithIcons";
import AppContext from "../components/AppContext";
import { ERROR_TIMEOUT } from "../AppConfig"
import ButtonComponent from "../components/Buttons";
import CommonHeader from "../components/CommonHeader";
import { object_equals } from "../AppConfig";
import { ValidateTextInputs } from "../utils/common"
import LoaderImage from "../assets/img/LoaderGraphic.svg";
import WebsiteMarketingPages from '../pages/MarketingPages/WebsiteMarketing';
import { convertTranslatinString } from '../utils/common';
import { decryptData } from '../utils/crypto';

export const NotificationPages = () => {
    const contextData = useContext(AppContext);
    const [notificationData, setNotificationData] = useState();
    const [t, i18n] = useTranslation();
    const [searchData, setsearchData] = useState("");
    const [showAutoFillForCompanyPopup, setAutoFillForCompanyPopup] = useState(false);
    const [apiUrlPopup, setapiUrlPopup] = useState({});
    const [showPopup, setshowPopup] = useState(false);
    const [notificationObjDetails, setNotificationObjDetails] = useState({});
    const [showaddeditPopup, setshowaddeditshowPopup] = useState(false);
    const [duration, setDuration] = useState([]);
    const [errorMessage, setErrorMessage] = useState("");
    const [getTotalResults, setGetTotalResults] = useState(0);
    const [notificationPopupObj, setNotificationPopupObj] = useState({
        operation: '', companyid: 0, companyname: '', notificationname: '', threshold: '', country: '', countrydata: '', companyurl: '', moreurls: [], startdate: null, enddate: null, durationId: 0,
        companynameisvalid: true, notificationnameisvalid: true, thresholdisvalid: true, startdateisvalid: true, enddateisvalid: true, durationidisvalid: true
    });
    const [temList, settemList] = useState([]);
    const [dListCountry, setDListCountry] = useState([]);
    const [showAutoFillForCompany, setAutoFillForCompany] = useState(false);
    const [successMessage, setSuccessMessage] = useState(undefined);
    const [errorMessageTransaction, setErrorMessageTransaction] = useState(undefined);
    const [isRefresh, setIsRefresh] = useState(false);
    const [autoLoad, setAutoLoad] = useState(false);
    const [selectedWebsiteIdNew, setSelectedWebsiteIdNew] = useState("");
    const [selectedProfileIdNew, setSelectedProfileIdNew] = useState("");
    const [profilesList, setProfilesList] = useState([]);
    const [tabdatahide, setTabDataHide] = useState(0);
    const [websiteList, setWebsiteList] = useState([]);
    const [allNotificationData, setAllNotificationData] = useState([]);
    const deserializeItem = (i) => ({
        ...i,
        layout: JSON.parse(i.layout) || {},
        summary: i.summary ? JSON.parse(i.summary) : {},
        vizState: i.vizState ? JSON.parse(i.vizState) : {},
        apiUrl: i.apiUrl ? i.apiUrl : undefined
    });

    const defaultLayout = (i) => ({
        x: i.layout.x || 0,
        y: i.layout.y || 0,
        w: i.layout.w || 4,
        h: i.layout.h || 8,
        minW: 4,
        minH: 8,
    });

    const defaultLayoutMobile = (i) => ({
        x: i.layout.x || 0,
        y: i.layout.y || 0,
        w: 12,
        // w: i.layout.w || 4,
        h: i.layout.h + 2 || 12,
        minW: 4,
        minH: 8,
    });

    useEffect(() => {
        if (contextData.companyId > 0) {

            if (localStorage.getItem("websiteData") && JSON.parse(localStorage.getItem("websiteData")).length > 0
                && localStorage.getItem("AllprofileData") && JSON.parse(localStorage.getItem("AllprofileData")).length > 0) {

                let websiteList = JSON.parse(localStorage.getItem("websiteData"));
                let selectedWebsite = JSON.parse(localStorage.getItem("WebsiteListWebsiteId"));

                renderWebsite(websiteList, selectedWebsite)
            }
            else if (!localStorage.getItem("websiteData") || !localStorage.getItem("AllprofileData")) {
                if (!localStorage.getItem("websiteData")) {
                    getWebsites();
                }
                else if (!localStorage.getItem("AllprofileData")) {
                    getProfiles(JSON.parse(localStorage.getItem("websiteData")));
                }
            }
        }
    }, [contextData.companyId]);

    useEffect(() => {
        setsearchData("");
        if (isRefresh && contextData.selectedTab == "Website" && contextData.selectedProfile["WebsiteProfiles.ProfileId"]) {

            let dta = getNotifications(contextData.selectedProfile["WebsiteProfiles.ProfileId"], contextData.companyId,
                contextData.selectedProfile["WebsiteProfiles.IntelConfigId"]);
            setNotificationData(dta);
            if (duration.length == 0) {
                request(
                    baseURL + `/WebsiteAnalytics/GetNotificationDuration?packageid=5`,
                    METHODS.GET,
                    {},
                    {
                        Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
                    }
                ).then((response) => {
                    if (response?.resultData) {
                        setDuration(response.resultData)
                    }
                });
            }

            setIsRefresh(false);
        }
    }, [isRefresh, contextData.selectedProfile, contextData.selectedTab]);

    const renderWebsite = (resultSet, selectedWebsiteValue) => {
        setWebsiteList(resultSet)
        setSelectedWebsiteIdNew(selectedWebsiteValue);
        renderProfileList(selectedWebsiteValue, 0);
    };

    const renderProfileList = (selectedWebsiteValue, isTempUpdate) => {

        if (localStorage.getItem("AllprofileData") && JSON.parse(localStorage.getItem("AllprofileData")).length > 0) {

            let profileList = JSON.parse(localStorage.getItem("AllprofileData"));
            let selectedWebsiteProfiles = profileList.filter((e) => { return e.WebsiteId === selectedWebsiteValue["WebsiteList.WebsiteId"] });

            if (selectedWebsiteProfiles.length > 0) {
                localStorage.setItem("cookieProfileData", JSON.stringify(selectedWebsiteProfiles));
                let profileData = selectedWebsiteProfiles.map((e) => ({ ["WebsiteProfiles.ProfileId"]: e.ProfileId, ["WebsiteProfiles.DisplayName"]: e.DisplayName, ["WebsiteProfiles.IntelConfigId"]: e.IntelConfigId }));

                setProfilesList(profileData)

                let selectedProfile = profileData[0];

                if (isTempUpdate === 0) {
                    let storedProfile = JSON.parse(localStorage.getItem("WebsiteProfilesIntelConfigId"));
                    if (storedProfile && Object.keys(storedProfile).length > 0
                        && profileData.some(obj => obj["WebsiteProfiles.ProfileId"] === storedProfile["WebsiteProfiles.ProfileId"])) {
                        selectedProfile = storedProfile;
                    };
                    localStorage.setItem("profileData", JSON.stringify(profileData))
                }
                setSelectedProfileIdNew(selectedProfile);

            }
            else {
                setProfilesList([])
                setSelectedProfileIdNew({});
                if (isTempUpdate === 0) {
                    localStorage.setItem("profileData", JSON.stringify([]))
                }
            }
        }
        setAutoLoad(true);
    };

    const getWebsites = () => {
        request(baseURL + `/CommonV2/GetWebsiteChannelWebsiteByClientUser?CompanyId=${contextData.companyId}&UserId=${contextData.userId}`,
            METHODS.GET,
            {},
            {
                Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
            })
            .then((res) => {
                let resultData = [];

                if (res.result && res.result.length > 0) {
                    resultData = res.result.map((e) => ({ cmsplatformid: res.platformid, ["WebsiteList.WebsiteName"]: e.WebsiteName, ["WebsiteList.WebsiteId"]: e.WebsiteId, ["WebsiteList.WebsiteUrl"]: e.WebsiteUrl }))
                }

                if (resultData.length > 0) {
                    localStorage.setItem("websiteData", JSON.stringify(resultData))
                    getProfiles(resultData);
                }
                else {
                    localStorage.setItem("websiteData", JSON.stringify([]))
                    localStorage.setItem("AllprofileData", JSON.stringify([]))
                }

            })
            .catch((err) => {

            })
    };

    const getProfiles = (resultData) => {
        request(baseURL + `/WebsiteAnalytics/GetWebsites?CompanyId=${contextData.companyId}&UserId=${contextData.userId}`,
            METHODS.GET,
            {},
            {
                Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
            })
            .then((res) => {

                if (res.result && res.result.length > 0) {

                    let result = removeDuplicateObjects(res.result, 'IntelConfigId')
                    localStorage.setItem("AllprofileData", JSON.stringify(result))

                }
                else {
                    localStorage.setItem("profileData", JSON.stringify([]))
                }

                if (!localStorage.getItem("WebsiteListWebsiteId")) {
                    renderWebsite(resultData, resultData[0])
                }
                else {
                    renderWebsite(resultData, JSON.parse(localStorage.getItem("WebsiteListWebsiteId")))
                }

                if (res.result.length == 0) {
                    setAutoLoad(true);
                }
            })
            .catch((err) => {
            })
    };

    function removeDuplicateObjects(array, key) {
        const seen = {};
        return array.filter(item => {
            const itemKey = key ? item[key] : item;
            return seen.hasOwnProperty(itemKey) ? false : (seen[itemKey] = true);
        });
    };

    const onWebsiteDropdownChange = (data) => {
        if (!object_equals(selectedWebsiteIdNew, data)) {
            setSelectedProfileIdNew({});
        }
        setSelectedWebsiteIdNew(data);
        renderProfileList(data, 1)
    };

    const onProfileDropdownChange = (data) => {
        const intellConfigId = data["WebsiteProfiles.IntelConfigId"];
        contextData.changeIntelConfigId(intellConfigId);
        setSelectedProfileIdNew(data)
    };

    const cancelFilters = () => {
        onWebsiteDropdownChange(JSON.parse(localStorage.getItem("WebsiteListWebsiteId")))
        onProfileDropdownChange(JSON.parse(localStorage.getItem("WebsiteProfilesIntelConfigId")))
    };

    const ViewHistory = (row) => {
        setNotificationObjDetails(row.data);
        if (row.data?.ruleId !== undefined) {
            if (row.type === "history") {
                setapiUrlPopup
                    (
                        {
                            url: `${process.env.REACT_APP_API_KEY}/WebsiteAnalytics/GetNotificationAuditTrail?intellconfigid=` + row.data.intellConfigId + `&ruleid=` + row.data.ruleId + `&packageid=5`, type: 'get', count: 0
                        }
                    )
                setshowPopup(!showPopup)
            }
            else if (row.type === "editnotification") {
                let curl = "";
                let moreurls = [];
                request(
                    baseURL + `/WebsiteAnalytics/GetIntelligenceDomainByVisitor?visitorid=` + row.data.companyId + `&countryid=0`,
                    METHODS.GET,
                    {},
                    {
                        Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
                    }
                ).then((response) => {
                    if (response?.resultData?.length > 0) {
                        for (let i = 0; i < response.resultData.length; i++) {
                            if (response.resultData[i].domainUrl != "" && curl == "") {

                                curl = response.resultData[i].domainUrl;
                            }
                            else if (response.resultData[i].domainUrl != "") {

                                moreurls.push(response.resultData[i].domainUrl)
                            }
                            else if (i == 0 && response.resultData.length == 1) {

                                curl = "-"
                            }
                        }
                    }
                    setNotificationPopupObj(prevState => ({
                        ...prevState,
                        notificationname: row.data.notificationName,
                        companyurl: curl,
                        moreurls: moreurls,
                        threshold: row.data.pageViewsCount > 0 ? row.data.pageViewsCount + t("header.Notification_Setting_or_more") : "",
                        duration: row.data.timeframe,
                        companyname: row.data.visitorName,
                        country: row.data.visitorCountryName,
                        operation: 'edit',
                        thresholdisvalid: true,
                        notificationnameisvalid: true,
                        startdateisvalid: true,
                        enddateisvalid: true,
                        startdate: row.data.durationId == 3 ? row.data.fromDate : null,
                        enddate: row.data.durationId == 3 ? row.data.toDate : null
                    }));
                    setNotificationPopupObj(prevState => ({
                        ...prevState,
                        durationId: row.data.durationId
                    }))
                    setshowaddeditshowPopup(!showPopup)
                    setErrorMessage("");
                });
            }
        }
    };

    const OpenAddNotification = () => {
        setNotificationPopupObj({
            operation: 'add', companyid: 0, companyname: '', country: '', notificationname: '', threshold: '', companyurl: '', moreurls: [], startdate: null, enddate: null, durationId: 4,
            companynameisvalid: true, notificationnameisvalid: true, thresholdisvalid: true, startdateisvalid: true, enddateisvalid: true, durationisvalid: true
        });
        setErrorMessage("")
        setshowaddeditshowPopup(!showPopup)
        setDListCountry([]);
    };

    const CloseHistoryPopup = () => {
        setshowPopup(!showPopup)
    };

    const CloseAddeditPopup = () => {
        setshowaddeditshowPopup(!showaddeditPopup)
    };

    const validStartDate = (current) => {
        let yesterday = moment().subtract(1, 'day');;
        return current.isAfter(yesterday);
    };

    const validEndDate = (current) => {
        let yesterday = moment().subtract(1, 'day');;
        return current.isAfter(yesterday);
    };

    const changeStartDate = (current) => {
        if (errorMessage == t("header.pleaseSelectDateRange") || (errorMessage == t("header.dateValidationErrorNew") && moment(notificationPopupObj.enddate) > moment(current))) {
            setErrorMessage("");
        }
        setNotificationPopupObj(prevState => ({
            ...prevState,
            startdate: current
        }));
    };

    const changeEndDate = (current) => {
        if (errorMessage == t("header.pleaseSelectDateRange") || (errorMessage == t("header.dateValidationErrorNew") && moment(current) > moment(notificationPopupObj.startdate))) {
            setErrorMessage("");
        }
        setNotificationPopupObj(prevState => ({
            ...prevState,
            enddate: current
        }));
    };

    const onFormElementsChange = (event) => {
        const { name, value, id, checked } = event.target;
        const forms = { ...notificationPopupObj };
        let formVlue;
        if (name == "durationId") {
            formVlue = id;
        }
        else if (name === "confirm" || name === "toggle")
            formVlue = checked;
        else if (name === "threshold") {
            formVlue = value.replace(/\D/g, "");

            if (formVlue == "0" || formVlue.trim().length == 0) {
                forms['thresholdisvalid'] = false;
            }
            else {
                forms['thresholdisvalid'] = true;
            }
        }
        else if (name === "companyname") {
            formVlue = value;
            onTextChanged(value);
        }
        else if (name == "notificationname") {
            formVlue = value.replace(/[&+()$~%'":;<>`]/g, '');
        }
        else
            formVlue = value;

        forms[name] = formVlue;
        if (formVlue.trim().length > 0 && name !== "threshold") {
            forms[name + 'isvalid'] = true;
        }
        else if (name !== "threshold") {
            forms[name + 'isvalid'] = false;
        }
        setNotificationPopupObj && setNotificationPopupObj(forms);
    };

    const validateFormElement = (e) => {
        e.preventDefault();
        let iserror = 0;
        if (notificationPopupObj.companyname.trim().length == 0) {
            setNotificationPopupObj(prevState => ({
                ...prevState,
                companynameisvalid: false
            }));
            iserror = 1;
        }
        if (notificationPopupObj.notificationname.trim().length == 0) {
            setNotificationPopupObj(prevState => ({
                ...prevState,
                notificationnameisvalid: false
            }));
            iserror = 1;
        }
        if (notificationPopupObj.threshold.trim().length == 0 || notificationPopupObj.threshold.trim() == "0" + t("header.Notification_Setting_or_more")) {
            setNotificationPopupObj(prevState => ({
                ...prevState,
                thresholdisvalid: false
            }));
            iserror = 1;
        }
        if (notificationPopupObj.durationId == 3) {

            if (notificationPopupObj.startdate === null) {
                setErrorMessage(t("header.pleaseSelectDateRange"))
                setNotificationPopupObj(prevState => ({
                    ...prevState,
                    startdateisvalid: false
                }));
                iserror = 1;
            }
            if (notificationPopupObj.enddate === null) {
                setErrorMessage(t("header.pleaseSelectDateRange"))
                setNotificationPopupObj(prevState => ({
                    ...prevState,
                    enddateisvalid: false
                }));
                iserror = 1;
            }
            if (moment(notificationPopupObj.enddate) < moment(notificationPopupObj.startdate)) {
                setErrorMessage(t("header.dateValidationErrorNew"))
                setNotificationPopupObj(prevState => ({
                    ...prevState,
                    enddateisvalid: false
                }));
                iserror = 1;
            }
        }
        if (iserror == 0) {
            onAddEditSave();
        }
    };

    const onTextChanged = (value) => {
        const showAutoFill = value.trim().length > 2 ? true : false;
        setNotificationPopupObj(prevState => ({
            ...prevState,
            companyname: value
        }));
        if (showAutoFill) {
            request(
                baseURL + `/WebsiteAnalytics/SearchCompanyListByName?searchtext=` + value.trim(),
                METHODS.GET,
                {},
                {
                    Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
                }
            ).then((response) => {
                if (response.resultData !== null) {
                    settemList(response.resultData)
                }
                else {
                    settemList([])
                }
                setAutoFillForCompany(showAutoFill);
            });
        }
        else {
            setAutoFillForCompany(showAutoFill);
        }
    };

    const suggestionSelectedValue = (value) => {

        setNotificationPopupObj(prevState => ({
            ...prevState,
            companyname: value.companyName,
            companyid: value.companyId,
            notificationname: value.companyName,
            notificationnameisvalid: true
        }));

        request(
            baseURL + `/WebsiteAnalytics/GetCountryByVisitor?visitorid=` + value.companyId,
            METHODS.GET,
            {},
            {
                Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
            }
        ).then((response) => {
            if (response.resultData.length > 0) {
                setDListCountry(response.resultData)
                setNotificationPopupObj(prevState => ({
                    ...prevState,
                    countrydata: response.resultData[0]
                }));
            }
        });
        let curl = "";
        let moreurls = [];
        request(
            baseURL + `/WebsiteAnalytics/GetIntelligenceDomainByVisitor?visitorid=` + value.companyId + `&countryid=` + 0,
            METHODS.GET,
            {},
            {
                Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
            }
        ).then((response) => {
            if (response?.resultData?.length > 0) {

                for (let i = 0; i < response.resultData.length; i++) {
                    if (response.resultData[i].domainUrl != "" && curl == "") {

                        curl = response.resultData[i].domainUrl;
                    }
                    else if (response.resultData[i].domainUrl != "") {

                        moreurls.push(response.resultData[i].domainUrl)
                    }
                    else if (i == 0 && response.resultData.length == 1) {

                        curl = "-"
                    }
                }
            }
            setNotificationPopupObj(prevState => ({
                ...prevState,
                companyurl: curl,
                moreurls: moreurls
            }));
        });
        setAutoFillForCompany(false);
    };

    const onCountryChange = (data) => {
        setNotificationPopupObj(prevState => ({
            ...prevState,
            countrydata: data
        }));

        let curl = "";
        let moreurls = [];
        request(
            baseURL + `/WebsiteAnalytics/GetIntelligenceDomainByVisitor?visitorid=` + notificationPopupObj.companyid + `&countryid=` + data.countryId,
            METHODS.GET,
            {},
            {
                Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
            }
        ).then((response) => {
            if (response?.resultData?.length > 0) {
                for (let i = 0; i < response.resultData.length; i++) {
                    if (response.resultData[i].domainUrl != "" && curl == "") {

                        curl = response.resultData[i].domainUrl;
                    }
                    else if (response.resultData[i].domainUrl != "") {

                        moreurls.push(response.resultData[i].domainUrl)
                    }
                    else if (i == 0 && response.resultData.length == 1) {

                        curl = "-"
                    }
                }
            }
            setNotificationPopupObj(prevState => ({
                ...prevState,
                companyurl: curl,
                moreurls: moreurls
            }));
        });
    };

    const onAddEditSave = () => {
        let notificationd = {}
        if (notificationPopupObj.operation == "edit") {
            notificationd = {
                CompanyId: notificationObjDetails.companyId,
                CountryId: notificationObjDetails.countryId,
                DurationId: notificationPopupObj.durationId,
                EmailAlert: 1,
                FromDate: notificationPopupObj.durationId == 3 ? notificationPopupObj.startdate : "0001-01-01T00:00:00",
                ToDate: notificationPopupObj.durationId == 3 ? notificationPopupObj.enddate : "0001-01-01T00:00:00",
                IntellConfigId: notificationObjDetails.intellConfigId,
                IsActive: notificationObjDetails['notifications.Active'],
                NotificationName: notificationPopupObj.notificationname,
                PageViewsCount: notificationPopupObj.threshold.replace(t("header.Notification_Setting_or_more"), ""),
                RuleId: notificationObjDetails.ruleId,
                SMSAlert: 0
            }
        }
        else {
            notificationd = {
                CompanyId: notificationPopupObj.companyid,
                CountryId: notificationPopupObj.countrydata?.countryId,
                DurationId: notificationPopupObj.durationId,
                EmailAlert: 1,
                FromDate: notificationPopupObj.durationId == 3 ? notificationPopupObj.startdate : "0001-01-01T00:00:00",
                ToDate: notificationPopupObj.durationId == 3 ? notificationPopupObj.enddate : "0001-01-01T00:00:00",
                IntellConfigId: JSON.parse(localStorage.getItem("WebsiteProfilesIntelConfigId"))["WebsiteProfiles.IntelConfigId"],
                IsActive: 1,
                NotificationName: notificationPopupObj.notificationname,
                PageViewsCount: notificationPopupObj.threshold.replace(t("header.Notification_Setting_or_more"), ""),
                RuleId: 0,
                SMSAlert: 0
            }
        }

        request(
            baseURL + `/WebsiteAnalytics/SetNotification?companyid=` + contextData.companyId + `&packageid=` + 5,
            METHODS.POST,
            notificationd,
            {
                Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
            }
        ).then((response) => {

            if (response?.ResultData && response.ResultData?.ruleId > 0) {
                setSuccessMessage(response.resultData.message);
                setTimeout(() => {
                    setSuccessMessage(undefined);
                }, ERROR_TIMEOUT);
            }
            else {
                setSuccessMessage(response.resultData.message.length > 0 ? response.resultData.message : "Something went wrong");

                setTimeout(() => {
                    setSuccessMessage(undefined);
                }, ERROR_TIMEOUT);
            }
            setIsRefresh(true);
            setshowaddeditshowPopup(false);
            setsearchData("");
        }).catch((error) => {

            setTimeout(() => {
                setErrorMessageTransaction("Something went wrong");
            }, 500);
            setTimeout(() => {
                setErrorMessageTransaction(undefined);
            }, ERROR_TIMEOUT);
        });
    };

    const dashboardItem = (item) => (
        <div key={item.id} data-grid={isMobile ? defaultLayoutMobile(item) : defaultLayout(item)}>
            <DashboardItem
                key={item.id}
                itemId={item.id}
                title={item.name}
                hideTitle={true}
            >
                <Row className="mt-5">
                    <Col md={12}>
                        <Row className={localStorage.getItem("isDemoClient") ? "custom-disable" : ""}>
                            <Col md={12} className="position-relative">

                                {<div className="search-box-container notification-table-search">
                                    <Search
                                        setsearchData={setsearchData}
                                        searchData={searchData}
                                        searchWaterMark={t("header.WONotificationsSearchTT")}
                                        isAutoComplete={false}
                                        setAutoFillForCompany={setAutoFillForCompanyPopup}
                                        showAutoFillForCompany={showAutoFillForCompanyPopup}
                                        type={"client"}
                                    ></Search>
                                    {allNotificationData.count > 0 && (
                                        <Card.Link onClick={(e) => OpenAddNotification()} className="mt-2 float-start" >
                                            <FontAwesomeIcon
                                                title={t("header.Icon_Tooltip_AddaNotification")}
                                                icon={faPlusCircle}
                                                size="lg"
                                            />
                                        </Card.Link>
                                    )}

                                </div>}
                            </Col>
                        </Row>

                        <Row>
                            <Col md={12}>
                                <ChartRendererTable
                                    SearchText={searchData}
                                    setSearchText={setsearchData}
                                    showPagination={true}
                                    apiUrl={item.apiUrl}
                                    summaryTooltip={t("header.NotificationSummaryTooltip")}
                                    summaryBesideText={t("header.WONotificationsTotNotifications")}
                                    showNumberOfRows={10}
                                    openPopupEvent={ViewHistory}
                                    setGetTotalResults={setGetTotalResults}
                                    setIsRefresh={setIsRefresh}
                                    Exportdata={setAllNotificationData}
                                />
                                {allNotificationData.count === 0 && (
                                    <div className='add-notification-text'>
                                        <Card.Link
                                            onClick={(e) => OpenAddNotification()} >
                                            <FontAwesomeIcon title={t("header.Icon_Tooltip_AddaNotification")} icon={faPlusCircle} size="lg" />
                                        </Card.Link>
                                        <span className='d-inline-block ms-1'> Add Notification</span>
                                    </div>
                                )}
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </DashboardItem>
        </div>
    );

    const onCloseSuccessMessage = () => {
        setSuccessMessage(undefined)
    };

    const onCloseErrorMessage = () => {
        setErrorMessageTransaction(undefined)
    };

    const companyUrlClick = (event, url) => {
        event.preventDefault();
        window.open(url, "_blank");
    };

    const applyFilters = () => {
        if (JSON.stringify(selectedProfileIdNew).length == 2) {
            setTabDataHide(-1);
        }
        else {
            setTabDataHide(1);
        }
        setIsRefresh(true);
        const websiteId = selectedWebsiteIdNew["WebsiteList.WebsiteId"];
        contextData.changeWebsiteId(websiteId);
        contextData.changeSelectedWebsite(selectedWebsiteIdNew);
        contextData.changeSelectedProfile(selectedProfileIdNew);
        if (profilesList.length > 0) {
            localStorage.setItem("profileData", JSON.stringify(profilesList))
        }
        else {
            localStorage.setItem("profileData", JSON.stringify([]))
        }
        localStorage.setItem("WebsiteListWebsiteId", JSON.stringify(selectedWebsiteIdNew));
        localStorage.setItem("WebsiteProfilesIntelConfigId", JSON.stringify(selectedProfileIdNew));
    };

    const commonHeader = useMemo(
        () => (
            <CommonHeader
                cancelFilters={cancelFilters}
                channel="website"
                autoLoad={autoLoad}
                dataSource={[
                    {
                        name: "Dropdown1 - Website",
                        data: websiteList,
                        selectedItem: selectedWebsiteIdNew,
                        isVisible: true,
                        label: t("header.filterLabelWebsite"),
                        callback: onWebsiteDropdownChange,
                        displayText: "WebsiteList.WebsiteName",
                    },
                    {
                        name: "Dropdown2 - website profile",
                        data: profilesList,
                        selectedItem: selectedProfileIdNew,
                        isVisible: true,
                        label: t("header.filterLabelProfile"),
                        callback: onProfileDropdownChange,
                        displayText: "WebsiteProfiles.DisplayName",
                    },
                ]}
                title={t("header.WONotificationsTitle")}
                showDate={false}
                applyFilters={applyFilters}
            />
        ),
        [selectedWebsiteIdNew, selectedProfileIdNew, autoLoad]
    );

    return (<>
        <ModalComponent
            title={t("header.WONotificationsHistoryTitle")}
            showCloseButton={true}
            open={showPopup}
            subtitle={notificationObjDetails.visitorName}
            subtitle2={notificationObjDetails.visitorCountryName}
            handleClose={CloseHistoryPopup}
            className={"notification-table"}
        >
            <Row>
                <Col md={12}>
                    <ChartRendererTable
                        apiUrl={apiUrlPopup}
                        showCount={false}
                        showNumberOfRows={10}
                        showPagination={true}
                    />
                </Col>
            </Row>
        </ModalComponent>
        <ModalComponent title={t("header.notificationSetting")}
            showCloseButton={true}
            open={showaddeditPopup}
            handleClose={CloseAddeditPopup}
        >
            <Row>
                <Col md={12}>
                    <p className='fw-bold'>{t("header.WONotificationnotifiedText")}</p>
                </Col>
            </Row>
            <Row className="mt-4">
                <Col md={3}>
                    {t("header.WONotificationSetupCName")}   {notificationPopupObj.operation == "add" && <span style={{ color: "red" }}>*</span>}
                </Col>
                <Col md={4}>
                    {notificationPopupObj.operation == "edit" && <>{notificationPopupObj.companyname}</>}
                    {notificationPopupObj.operation == "add" && <><div className="pe-1">

                        <InputGroup>
                            {
                                (notificationPopupObj.companynameisvalid ? <Form.Control maxLength={100} type="text" value={notificationPopupObj.companyname} className='input-right-border' onChange={(e) => onFormElementsChange(e)} name="companyname" autoComplete="off" /> :
                                    <Form.Control isInvalid maxLength={100} value={notificationPopupObj.companyname} type="text" onChange={(e) => onFormElementsChange(e)} name="companyname" autoComplete="off" />)

                            }
                            {showAutoFillForCompany && <div className="auto-dropdown">
                                <ul className="list-unstyled auto-dropdown-menu">
                                    {temList.length > 0 && temList.map((value, index) =>
                                        <li className="auto-dropdown-menu-item mt-1 mb-1" key={index} onClick={() => suggestionSelectedValue(value)}>{value.companyName}</li>
                                    )}
                                    {
                                        temList.length === 0 && <li style={{ color: '#cc493f' }} className="mt-1 mb-1">{t("header.contentUnavailable")}</li>
                                    }
                                </ul>
                            </div>}
                        </InputGroup>
                    </div></>}
                </Col>
            </Row>
            <Row className="mt-4">
                <Col md={3}>
                    {t("header.WONotificationSetupCountry")}
                </Col>
                <Col md={4}>
                    {notificationPopupObj.operation == "edit" && <>{notificationPopupObj.country}</>}
                    {notificationPopupObj.operation == "add" && <>
                        <DropdownWithIconsComponent
                            isDropdownDisabled={false}
                            dropdownData={dListCountry}
                            arrayValue="countryName"
                            selectedDropdown={notificationPopupObj.countrydata}
                            onChange={onCountryChange}
                            showLabel={false}
                            showIconInDropdownItem={false}
                            isPrimaryImage={false}
                            customClassName="text-overflow full-width"
                        />
                    </>}
                </Col>
            </Row>
            <Row className="mt-4">
                <Col md={3}>
                    {t("header.WONotificationSetupCompURL")}
                </Col>
                <Col md={9}>
                    <a className='link-primary' onClick={(e) => companyUrlClick(e, notificationPopupObj.companyurl)} >{notificationPopupObj.companyurl}</a>

                    {notificationPopupObj.moreurls.length > 0 && <>
                        <OverlayTrigger
                            placement="bottom"
                            trigger="click"
                            overlay={
                                <Popover className="notification-url-popover">
                                    <Popover.Content>
                                        <ul className="list-unstyled auto-dropdown-menu mb-0">
                                            {notificationPopupObj.moreurls.map((item) =>

                                                <li className="auto-dropdown-menu-item mt-1 mb-1 link-primary" onClick={(e) => companyUrlClick(e, item)} >
                                                    {item}
                                                </li>
                                            )
                                            }
                                        </ul>
                                    </Popover.Content>
                                </Popover>
                            }>
                            <Button variant="white" size="sm" className="m-0 p-0 ms-3 text-bold">{t("header.WONotificationSetupMore")}</Button>
                        </OverlayTrigger></>}
                </Col>
            </Row>
            <Row className="mt-4">
                <Col md={3}>
                    {t("header.WONotificationSetupName")} <span style={{ color: "red" }}>*</span>
                </Col>
                <Col md={4}>
                    {
                        (notificationPopupObj.notificationnameisvalid ? <Form.Control maxLength={100} type="text" value={notificationPopupObj.notificationname} onChange={(e) => onFormElementsChange(e)} name="notificationname" autoComplete="off" /> :
                            <Form.Control isInvalid maxLength={100} value={notificationPopupObj.notificationname} type="text" onChange={(e) => onFormElementsChange(e)} name="notificationname" autoComplete="off" />)
                    }

                </Col>
            </Row>
            <Row className="mt-4">
                <Col md={12}>
                    <b>
                        {t("header.notificationNofifyMessage")}
                    </b><br />
                    {t("header.notificationsProcessingMessage")}
                </Col>
            </Row>
            <Row className="mt-4">
                <Col md={6}>
                    <Form.Group>
                        {duration.map((type, i) => (
                            <FormCheck
                                checked={type.durationId == notificationPopupObj.durationId ? true : false}
                                type="radio"
                                value={notificationPopupObj.durationId}
                                label={t("header." + convertTranslatinString(type.description))}
                                name="durationId"
                                id={type.durationId}
                                onChange={(e) => onFormElementsChange(e)}
                            />
                        ))}
                    </Form.Group>
                </Col>
                {notificationPopupObj.durationId == 3 && <>
                    <Col md={6}>
                        <Row>
                            <Col md={5}>
                                <Datetime
                                    closeOnClickOutside={false}
                                    timeFormat={false}
                                    closeOnSelect={true}
                                    onChange={changeStartDate}
                                    className="mt-2"
                                    isValidDate={validStartDate}
                                    renderInput={(props, openCalendar) => (
                                        <InputGroup>
                                            <Form.Control
                                                required
                                                type="text"
                                                value={notificationPopupObj.startdate ? moment(notificationPopupObj.startdate).format("MMM DD,YYYY") : ""}
                                                placeholder={t("header.notificationsFrom")}
                                                onFocus={openCalendar}
                                            />
                                            <InputGroup.Text><FontAwesomeIcon icon={faCalendarAlt} /></InputGroup.Text>
                                        </InputGroup>
                                    )} />
                            </Col>
                            <Col md={5}>
                                <Datetime
                                    closeOnClickOutside={false}
                                    timeFormat={false}
                                    closeOnSelect={true}
                                    onChange={changeEndDate}
                                    isValidDate={validEndDate}
                                    className="mt-2"
                                    renderInput={(props, openCalendar) => (
                                        <InputGroup>
                                            <Form.Control
                                                required
                                                type="text"
                                                value={notificationPopupObj.enddate ? moment(notificationPopupObj.enddate).format("MMM DD,YYYY") : ""}
                                                placeholder={t("header.notificationsTo")}
                                                onFocus={openCalendar}
                                                onChange={() => { }} />
                                            <InputGroup.Text><FontAwesomeIcon icon={faCalendarAlt} /></InputGroup.Text>
                                        </InputGroup>
                                    )} />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={8}>
                                <div className="invalid-feedback d-block" style={{ textAlign: "left" }}>{errorMessage ? errorMessage : ''}</div>
                            </Col>
                        </Row>
                    </Col>
                </>}
            </Row>
            <Row className="mt-4">
                <Col md={3}>
                    {t("header.WONotificationSetupPVThreshold")}<span style={{ color: "red" }}>*</span>
                </Col>
                <Col md={4}>
                    <Form.Group className="mb-3">
                        {
                            (notificationPopupObj.thresholdisvalid ? <Form.Control maxLength={8} onBlur={() => {
                                if (notificationPopupObj.threshold.length > 0) {
                                    setNotificationPopupObj(prevState => ({
                                        ...prevState,
                                        threshold: notificationPopupObj.threshold + t("header.Notification_Setting_or_more")
                                    }));
                                }
                            }} onFocus={() => {
                                if (notificationPopupObj.threshold.length > 0) {
                                    setNotificationPopupObj(prevState => ({
                                        ...prevState,
                                        threshold: notificationPopupObj.threshold.replace(t("header.Notification_Setting_or_more"), "")
                                    }))
                                }
                            }} type="text" value={notificationPopupObj.threshold} onChange={(e) => onFormElementsChange(e)} name="threshold" autoComplete="off" /> :
                                <Form.Control isInvalid maxLength={8} onBlur={() => {
                                    if (notificationPopupObj.threshold.length > 0) {
                                        setNotificationPopupObj(prevState => ({
                                            ...prevState,
                                            threshold: notificationPopupObj.threshold + t("header.Notification_Setting_or_more")
                                        }));
                                    }
                                }} onFocus={() => {
                                    if (notificationPopupObj.threshold.length > 0) {
                                        setNotificationPopupObj(prevState => ({
                                            ...prevState,
                                            threshold: notificationPopupObj.threshold.replace(t("header.Notification_Setting_or_more"), "")
                                        }))
                                    }
                                }}
                                    value={notificationPopupObj.threshold} type="text" onChange={(e) => onFormElementsChange(e)} name="threshold" autoComplete="off" />)
                        }
                    </Form.Group>
                    {notificationPopupObj.threshold == "0" + t("header.Notification_Setting_or_more")
                        && <div className="invalid-feedback d-block">{t("header.WONotificationSetupPageViewsThreshold")}</div>
                    }
                </Col>
            </Row>
            <Row className="mt-3">
                <Col md={3}>
                </Col>
                <Col md={9}>
                    <ButtonComponent variant="secondary" btnText={t("header.WONotificationSetupSave")} isIcon={false} isPrimary={true} btn_class="me-3" onClick={(e) => validateFormElement(e)}>
                    </ButtonComponent>

                    <ButtonComponent variant="btn-outline-primary" btnText={t("header.WONotificationSetupCancel")} isIcon={false} isPrimary={false} btn_class="mb-0" onClick={(e) => CloseAddeditPopup(e)} >
                    </ButtonComponent>
                </Col>
            </Row>
        </ModalComponent>
        <Row>
            <Col>{commonHeader}</Col>
        </Row>
        <Row>
            <Col>
                {(successMessage || errorMessageTransaction) && <div className="fade modal-backdrop show"></div>}

                <Alert
                    className="toast-custom"
                    variant="success"
                    show={successMessage ? true : false}
                    onClose={() => onCloseSuccessMessage()}
                >
                    <div className="d-flex justify-content-between">
                        <div>
                            <FontAwesomeIcon icon={faCheckCircle} className="me-2" />
                            {successMessage}
                        </div>
                        <Button variant="close" size="xs" onClick={() => onCloseSuccessMessage()} />
                    </div>
                </Alert>
                <Alert
                    className="toast-custom"
                    variant="danger"
                    show={errorMessageTransaction ? true : false}
                    onClose={() => onCloseErrorMessage()}
                >
                    <div className="d-flex justify-content-between">
                        <div>
                            <FontAwesomeIcon icon={faCheckCircle} className="me-2" />
                            {errorMessageTransaction}
                        </div>
                        <Button variant="close" size="xs" onClick={() => onCloseErrorMessage()} />
                    </div>
                </Alert>
                {
                    notificationData &&
                    <div className="d-inline-block p-3 pe-0 w-100">
                        <div className="shadow-soft border rounded border-light w-100 nested-card-container Notification-container">
                            {notificationData && notificationData.length > 0
                                && notificationData.map(deserializeItem).map(dashboardItem)}
                        </div>
                    </div>
                }
            </Col>
        </Row>
    </>);
}
export default NotificationPages;